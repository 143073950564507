<div [formGroup]="booksAndRecordsFormGroup" class="content-section" id="books-and-records">
    <div class="row">
        <span class="title-section">Books And Records</span>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('linkFinalEngagement')"
        [class.edited-externally]="editedExternally('linkFinalEngagement')"
        [class.conflict-on-merge]="hasMergeConflict('linkFinalEngagement')"
        >Link to final engagement project folder</span>
        <span *ngIf="showAsterisk('linkFinalEngagement')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
          <input
              formControlName="linkFinalEngagement"
              class="input-data-text full-width section-input"
              type="text"
              autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 margin-top-5">
        <span>Have the following final documents been saved to the final engagement project folder?</span>
      </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
          <span class="identation-span">
              <p [class.highlight-warning]="highlightRequiredField('copyFinalBriefingPaper')"
              [class.edited-externally]="editedExternally('copyFinalBriefingPaper')"
        [class.conflict-on-merge]="hasMergeConflict('copyFinalBriefingPaper')">Copy of the final Briefing Paper</p>
              <span *ngIf="showAsterisk('copyFinalBriefingPaper')" class="red-asterik"></span>
          </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoCopyFinalBriefingPaper
              [value]="booksAndRecordsFormGroup.get('copyFinalBriefingPaper')?.value"
              [readonly]="isReadOnly('copyFinalBriefingPaper')"
              (handleToggleYesNo)="onCopyFinalBriefingPaperSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('executedEngagementLetter')"
                [class.edited-externally]="editedExternally('executedEngagementLetter')"
        [class.conflict-on-merge]="hasMergeConflict('executedEngagementLetter')">Fully executed Engagement Letter</p>
                <span *ngIf="showAsterisk('executedEngagementLetter')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoExecutedEngagementLetter
              [value]="booksAndRecordsFormGroup.get('executedEngagementLetter')?.value"
              [readonly]="isReadOnly('executedEngagementLetter')"
              (handleToggleYesNo)="onExecutedEngagementLetterSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span customWith">
                <p [class.highlight-warning]="highlightRequiredField('amendmentsEngagementLetter')"
                [class.edited-externally]="editedExternally('amendmentsEngagementLetter')"
        [class.conflict-on-merge]="hasMergeConflict('amendmentsEngagementLetter')">All amendments to Engagement Letter</p>
                <span *ngIf="showAsterisk('amendmentsEngagementLetter')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoAmendmentsEngagementLetter
              [value]="booksAndRecordsFormGroup.get('amendmentsEngagementLetter')?.value"
              [readonly]="isReadOnly('amendmentsEngagementLetter')"
              (handleToggleYesNo)="onAmendmentsEngagementLetterSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('fullyExecutedIFA')"
                [class.edited-externally]="editedExternally('fullyExecutedIFA')"
        [class.conflict-on-merge]="hasMergeConflict('fullyExecutedIFA')">Fully executed IFA</p>
                <span *ngIf="showAsterisk('fullyExecutedIFA')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoFullyExecutedIFA
              [value]="booksAndRecordsFormGroup.get('fullyExecutedIFA')?.value"
              [readonly]="isReadOnly('fullyExecutedIFA')"
              (handleToggleYesNo)="onFullyExecutedIFASelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('pitchProposal')"
                [class.edited-externally]="editedExternally('pitchProposal')"
        [class.conflict-on-merge]="hasMergeConflict('pitchProposal')">Pitch / Proposal</p>
                <span *ngIf="showAsterisk('pitchProposal')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoPitchProposal
              [value]="booksAndRecordsFormGroup.get('pitchProposal')?.value"
              [readonly]="isReadOnly('pitchProposal')"
              (handleToggleYesNo)="onPitchProposalSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('teaserBooks')"
                [class.edited-externally]="editedExternally('teaserBooks')"
        [class.conflict-on-merge]="hasMergeConflict('teaserBooks')">Teaser</p>
                <span *ngIf="showAsterisk('teaserBooks')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoTeaserBooks
              [value]="booksAndRecordsFormGroup.get('teaserBooks')?.value"
              [readonly]="isReadOnly('teaserBooks')"
              (handleToggleYesNo)="onTeaserBooksSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('cimOfferingMemorandum')"
                [class.edited-externally]="editedExternally('cimOfferingMemorandum')"
        [class.conflict-on-merge]="hasMergeConflict('cimOfferingMemorandum')">CIM / Offering Memorandum</p>
                <span *ngIf="showAsterisk('cimOfferingMemorandum')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoCimOfferingMemorandum
              [value]="booksAndRecordsFormGroup.get('cimOfferingMemorandum')?.value"
              [readonly]="isReadOnly('cimOfferingMemorandum')"
              (handleToggleYesNo)="onCimOfferingMemorandumSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('managementPresentation')"
                [class.edited-externally]="editedExternally('managementPresentation')"
        [class.conflict-on-merge]="hasMergeConflict('managementPresentation')">Management Presentation</p>
                <span *ngIf="showAsterisk('managementPresentation')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoManagementPresentation
              [value]="booksAndRecordsFormGroup.get('managementPresentation')?.value"
              [readonly]="isReadOnly('managementPresentation')"
              (handleToggleYesNo)="onManagementPresentationSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('clientLetterMarketingPress')"
                [class.edited-externally]="editedExternally('clientLetterMarketingPress')"
        [class.conflict-on-merge]="hasMergeConflict('clientLetterMarketingPress')">Client Letter for Marketing Materials/Press Release</p>
                <span *ngIf="showAsterisk('clientLetterMarketingPress')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoClientLetterMarketingPress
              [value]="booksAndRecordsFormGroup.get('clientLetterMarketingPress')?.value"
              [readonly]="isReadOnly('clientLetterMarketingPress')"
              (handleToggleYesNo)="onClientLetterMarketingPressSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('tombstoneBooks')"
                [class.edited-externally]="editedExternally('tombstoneBooks')"
        [class.conflict-on-merge]="hasMergeConflict('tombstoneBooks')">Tombstone</p>
                <span *ngIf="showAsterisk('tombstoneBooks')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoTombstoneBooks
              [value]="booksAndRecordsFormGroup.get('tombstoneBooks')?.value"
              [readonly]="isReadOnly('tombstoneBooks')"
              (handleToggleYesNo)="onTombstoneBooksSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('pressReleaseBooks')"
                [class.edited-externally]="editedExternally('pressReleaseBooks')"
        [class.conflict-on-merge]="hasMergeConflict('pressReleaseBooks')">Press Release</p>
                <span *ngIf="showAsterisk('pressReleaseBooks')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoPressReleaseBooks
              [value]="booksAndRecordsFormGroup.get('pressReleaseBooks')?.value"
              [readonly]="isReadOnly('pressReleaseBooks')"
              (handleToggleYesNo)="onPressReleaseBooksSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('caseStudiesBooks')"
                [class.edited-externally]="editedExternally('caseStudiesBooks')"
        [class.conflict-on-merge]="hasMergeConflict('caseStudiesBooks')">Case Studies</p>
                <span *ngIf="showAsterisk('caseStudiesBooks')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoCaseStudiesBooks
              [value]="booksAndRecordsFormGroup.get('caseStudiesBooks')?.value"
              [readonly]="isReadOnly('caseStudiesBooks')"
              (handleToggleYesNo)="onCaseStudiesBooksSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('leagueTables')"
                [class.edited-externally]="editedExternally('leagueTables')"
        [class.conflict-on-merge]="hasMergeConflict('leagueTables')">League Tables</p>
                <span *ngIf="showAsterisk('leagueTables')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoLeagueTables
              [value]="booksAndRecordsFormGroup.get('leagueTables')?.value"
              [readonly]="isReadOnly('leagueTables')"
              (handleToggleYesNo)="onLeagueTablesSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('listProspectiveInvestorsBuyers')"
                [class.edited-externally]="editedExternally('listProspectiveInvestorsBuyers')"
        [class.conflict-on-merge]="hasMergeConflict('listProspectiveInvestorsBuyers')">Complete list of prospective investors/buyers contacted</p>
                <span *ngIf="showAsterisk('listProspectiveInvestorsBuyers')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoListProspectiveInvestorsBuyers
              [value]="booksAndRecordsFormGroup.get('listProspectiveInvestorsBuyers')?.value"
              [readonly]="isReadOnly('listProspectiveInvestorsBuyers')"
              (handleToggleYesNo)="onListProspectiveInvestorsBuyersSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('listActualInvestorsBuyers')"
                [class.edited-externally]="editedExternally('listActualInvestorsBuyers')"
        [class.conflict-on-merge]="hasMergeConflict('listActualInvestorsBuyers')">Complete list of actual investors/buyers in this transaction</p>
                <span *ngIf="showAsterisk('listActualInvestorsBuyers')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoListActualInvestorsBuyers
              [value]="booksAndRecordsFormGroup.get('listActualInvestorsBuyers')?.value"
              [readonly]="isReadOnly('listActualInvestorsBuyers')"
              (handleToggleYesNo)="onListActualInvestorsBuyersSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('expressionOfInterestLettersInt')"
                [class.edited-externally]="editedExternally('expressionOfInterestLettersInt')"
        [class.conflict-on-merge]="hasMergeConflict('expressionOfInterestLettersInt')">Expression of Interest/Signed Letters of Intent (winning buyer/investor)</p>
                <span *ngIf="showAsterisk('expressionOfInterestLettersInt')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoExpressionOfInterestLettersOfIntent
              [value]="booksAndRecordsFormGroup.get('expressionOfInterestLettersInt')?.value"
              [readonly]="isReadOnly('expressionOfInterestLettersInt')"
              (handleToggleYesNo)="onExpressionOfInterestLettersOfIntentSelection($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('containsAllTransactionDocuments')"
                [class.edited-externally]="editedExternally('containsAllTransactionDocuments')"
        [class.conflict-on-merge]="hasMergeConflict('containsAllTransactionDocuments')">Does the final project folder contain all final, fully executed transaction documents?</p>
                <span *ngIf="showAsterisk('containsAllTransactionDocuments')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoContainsAllTransactionDocuments
              [value]="booksAndRecordsFormGroup.get('containsAllTransactionDocuments')?.value"
              [readonly]="isReadOnly('containsAllTransactionDocuments')"
              (handleToggleYesNo)="onContainsAllTransactionDocumentsSelection($event)"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('didProjectGoIC')"
            [class.edited-externally]="editedExternally('didProjectGoIC')"
        [class.conflict-on-merge]="hasMergeConflict('didProjectGoIC')">Did the project go through the Investment Committee (“IC”)?</span>
            <span *ngIf="showAsterisk('didProjectGoIC')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no   #yesNoDidProjectGoIC
              [value]="booksAndRecordsFormGroup.get('didProjectGoIC')?.value"
              [readonly]="isReadOnly('didProjectGoIC')"
              (handleToggleYesNo)="onDidProjectGoICSelection($event)"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row margin-top-5">
      <div class="col-xs-4 margin-top-5">
          <span class="identation-span">
              <p [class.highlight-warning]="highlightRequiredField('dateICReviewApproval')"
              [class.edited-externally]="editedExternally('dateICReviewApproval')"
        [class.conflict-on-merge]="hasMergeConflict('dateICReviewApproval')">
                Date of IC Review and Approval
              </p>
              <span *ngIf="showAsterisk('dateICReviewApproval')" class="red-asterik"></span>
          </span>
      </div>
      <div class="col-xs-7">
        <app-date-picker #pickerDateICReviewApproval
          [selectedDate]="booksAndRecordsFormGroup.get('dateICReviewApproval')?.value"
          [readonly]="isReadOnly('dateICReviewApproval')"
          [disabled]="booksAndRecordsFormGroup.get('dateICReviewApproval')?.disabled"
          (dateSelected)="onDateICReviewApprovalSelection($event)">
        </app-date-picker>
      </div>
    </div>  
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span class="identation-span">
            <p [class.highlight-warning]="highlightRequiredField('ifNoExplain')"
            [class.edited-externally]="editedExternally('ifNoExplain')"
        [class.conflict-on-merge]="hasMergeConflict('ifNoExplain')">If no, please explain:</p>
            <span *ngIf="showAsterisk('ifNoExplain')" class="red-asterik"></span>
        </span>
      </div>
      <div class="col-xs-7">
        <input formControlName="ifNoExplain"
               class="input-data-text full-width section-input"
               type="text"
               autocomplete="off"
               [placeholder]="booksAndRecordsFormGroup.get('ifNoExplain')?.disabled ? 'N/A' : ''"
               [disabled]="booksAndRecordsFormGroup.get('ifNoExplain')?.disabled">
      
      </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('zipFileSent')"
            [class.edited-externally]="editedExternally('zipFileSent')"
        [class.conflict-on-merge]="hasMergeConflict('zipFileSent')">
              Has a zip file of all final engagement records been sent to 
              <a href="mailto:usdcfclosingengagementreviewgroup@deloitte.com">
                US DCF Closing Engagement Review Group?
              </a>
            </span>
            <span *ngIf="showAsterisk('zipFileSent')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoZipFileSent
              [value]="booksAndRecordsFormGroup.get('zipFileSent')?.value"
              [readonly]="isReadOnly('zipFileSent')"
              (handleToggleYesNo)="onZipFileSentSelection($event)"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>  

    

    <div class="row" *ngIf="showSubmitUser">
        <div class="col-xs-4 margin-top-5">
            <div class="">
                <i>Initial Submission Completed By</i>
                <div><b><span class="full-width signature-color">{{SubmissionUser}}</span></b></div>
                
            </div>
        </div>
        <div class="col-xs-7">
            <div class="">
                <i>Completed On</i>
                <div><b><span  class="full-width signature-color" userid="dealTeamSignOff.userId">{{SubmissionDate}}</span></b></div>
                
            </div>
        </div>
    </div> 
    <div class="row" *ngIf="showSeries24User">
        <div class="col-xs-4 margin-top-5">
            <div class="">
                <i>Supervisory Signoff Completed By</i>
                <div><b><span class="full-width signature-color">{{SubmissionSeries24User}}</span></b></div>
                
            </div>
        </div>
        <div class="col-xs-7">
            <div class="">
                <i>Completed On</i>
                <div><b><span  class="full-width signature-color" userid="dealTeamSignOff.userId">{{SubmissionSeries24Date}}</span></b></div>
                
            </div>
        </div>
    </div> 
    

</div>
