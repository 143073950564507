import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplianceData } from 'src/app/engagement-health-object/models/compliance-data';
import { AppSettings } from 'src/shared/app-settings';
import { ComplianceStatusColors } from 'src/app/engagementDashboard/models/compliance-status-colors';
import { ComplianceStatusValues } from 'src/app/engagementDashboard/models/compliance-status-values';
import { EngagementHealthObjectItem } from 'src/app/engagement-health-object/models/engagement-health-object-item';
import { AvailableFormItem } from 'src/shared/models/available-form-item';

@Component({
  selector: 'app-eho-notify-popup',
  templateUrl: './eho-notify-popup.component.html',
  styleUrls: ['./eho-notify-popup.component.scss']
})
export class EhoNotifyPopupComponent implements OnInit {

  displayStyle:any = "none";
  isUserDelete:any=false;
  MessageHeader:any="";
  headerMsg:any=""
  Msg:any;messageType:any;
  modalType:any="";
  cancelText:any=""
  UserDeleteCancelText ="No";
  UserDeleteOkText ="Yes";

  currentRadioButtonSelected: string;
  currentComments: any;
  EHOCompliance:any="";
  isEHOCompliance:boolean=false;
  @Input() modalAvailableFormsList:any=[];
  @Input() availableForms:any;
  @Input() engagementObject: any={};
  @Input() additionaldocumentTypesToAdd: any=[];
  @Input() maxAllowedFileNameLength: number;
  @Input() invalidFileNameCharactersList: Array<string> = [];
  @Output()  addEHOFormDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  addNotifyDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  deleteConfirmDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  addAdditionalDocumentClicked:EventEmitter<any>=new EventEmitter<any>();  
  @Output()  engagementHealthUpdateValue: EventEmitter<ComplianceData> = new EventEmitter<ComplianceData>();
  constructor() { }

  ngOnInit(): void {
    
  }
openEHOCompliancePopup(complianceType:any,radioSelected:any){
  document.body.style.overflow="hidden"
  this.isEHOCompliance=true;
this.EHOCompliance=complianceType;
this.currentRadioButtonSelected=radioSelected;
this.displayStyle="block";
}

openAddNewFormPopup(ModalType:any){
  this.modalAvailableFormsList=this.modalAvailableFormsList;
  document.body.style.overflow="hidden"
  this.isEHOCompliance=false;
  this.modalType=ModalType;
  this.displayStyle="block";
}
  openPopup(msg:any,msgType:any) { 
    this.isEHOCompliance=false;
    this.MessageHeader=msg;
    this.headerMsg = "";
    this.messageType=msgType;
    this.isUserDelete=false;   
    this.UserDeleteCancelText ="No";
    this.UserDeleteOkText ="Yes";  
    this.cancelText="OK";
    this.EHOCompliance=true;
    if (msg == "Admin") {
      if (msgType == "Success") {
        this.Msg = "User added successfully!";
      }
      else if (msgType == "Info") {
        this.Msg = "Please select the User!";
      }
      else if (msgType == "Warning") {
        this.Msg = "User Already Exist!";
      }
      else if (msgType == "PermissionError") {
        this.Msg = "Error while getting User Permissions!";
      }
      else if (msgType == "PermissionInfo") {
        this.Msg = "Please select the User!";
      }
    }
   else if (msg == "Content") {
      if (msgType == "SaveSuccess") {
        this.Msg = "Record saved successfully!";
      }
      if (msgType == "UpdateSuccess") {
        this.Msg = "Record updated successfully!";
      }
      else if (msgType == "ITSAdminRequest") {
        this.Msg = "Request sent to ITSAdmin for Verification!";
      }
      else if (msgType == "Info") {
        this.Msg = "Please pass the Mandatory Details!";
      }
      else if (msgType == "AlreadyExisted") {
        this.Msg = "Record Already Existed!";
      }
      else if (msgType == "FormGroupValidation") {
        this.Msg = "Group Mapped with existed Records, Not allowed to Delete!";
      }
      else if (msgType == "DeleteSuccess") {
        this.Msg = "Record deleted Successfully";
      }
      else if (msgType == "DeleteError") {
        this.Msg = "Error occured while deleting the Record!";
      }
      else if (msgType == "SuccessError") {
        this.Msg = "Error occured while saving the Record!";
      }
      else if (msgType == "AuditError") {
        this.Msg = "Error occured while saving the Audit!";
      }
      else if (msgType == "ApprovedSuccess") {
        this.Msg = "ITS Admin Approved!";
      }
      else if (msgType == "RejectedSuccess") {
        this.Msg = "ITS Admin Rejected!";
      }
      else if (msgType == "ITSAdminNotVerified") {
        this.Msg = "No Action Performed, Till Previous changes approved by ITSAdmin!";
      }
      else if (msgType == "UpdateError") {
        this.Msg = "Error occured while updating the Record!";
      }
      else if (msgType == "PresignedURL") {
        this.Msg = "Error while uploading to the Pre-signed environment!";
      }
      else if (msgType == "isNotUpdated") {
        this.Msg = "Record not updated!";
      }
      else if (msgType == "maxFileName") {
        this.Msg = "The filename must be shorter than 128 characters.";
      }
      else if (msgType == "maxFileSize") {
        this.Msg = "The upload of a file with the invalid size";
      }
      else if (msgType == "fileEmpty") {
        this.Msg = "The file cannot be empty.";
      }
      else if (msgType == "fileNotSelected") {
        this.Msg = "The file must contain name";
      }
      else if (msgType == "fileDuplicate") {
        this.Msg = "A file with that name already exists.";
      }
      else if (msgType == "fileEncrypted") {
        this.Msg = "File can not be Encrypted.";
      }
    }
    else if(msg=="Dashboard"){
      this.Msg = "You are not allowed to access this form";
    }
    else if(msg=="DashboardRedirect"){
      this.Msg = "You are not allowed to access this form";
    }   
    else if(msg=="DeleteOperation") {
      if(msgType == "Info"){
        this.isUserDelete=true;
        this.Msg="Are you sure, you want to delete the Record?"
      }
     else if (msgType == "Success") {
        this.Msg = " Record has been Deleted Successfully!";
      }
      else {
        this.Msg = "Error in Deletion!";
      }
    }
    this.displayStyle = "block";
   }
   closePopup() { 
    this.ApplyBrowserScroll();  
    this.displayStyle = "none";
    const data = {
      MessageHeader:this.MessageHeader,
      isOkayClicked: true,
      modalType: this.modalType
    };
    if(this.isEHOCompliance){
    this.addNotifyDetails.emit(data);
    }
   }
   ConfirmToDelete(){ //OK
    this.ApplyBrowserScroll();
    this.displayStyle = "none";
    const data = {
      isOkayClicked: true,
      modalType: this.modalType,
    };
    this.deleteConfirmDetails.emit(data);
   }
   ConfirmToCancel(){ // Cancel
    this.ApplyBrowserScroll();
    const data = {
      isOkayClicked: false,
      modalType: this.modalType,
      isCancelClicked: true,
    };
    this.deleteConfirmDetails.emit(data);
    this.displayStyle = "none";
   }
   ApplyBrowserScroll(){
    document.body.style.overflow="auto";
  }

   getModalStyles()
   {  
    if(this.displayStyle=="block") document.body.style.overflow="hidden"
    if(this.modalAvailableFormsList.length==0){
      var width='45%'; //defualt width
      var height='30%'; //defualt height  
    }
    else if(this.modalAvailableFormsList.length<=2){
      var width='45%'; //defualt width
      var height='35%'; //defualt height  
    }
    else if(this.modalAvailableFormsList.length>2){
      var width='45%'; //defualt width
      var height='41%'; //defualt height  
    }
    else{
      var width='45%'; 
      var height='30%';  
    }    
    return {'width':width,'height':height};
   }

   //Compliance EHO Logic
   get complianceStatusValues() {
    return ComplianceStatusValues;
  }

  get complianceStatusColors() {
    return ComplianceStatusColors;
  }

  isChecked(complianceStatus: string): boolean {
    const result = this.currentRadioButtonSelected === complianceStatus;
    return result;
  }
  getComplianceStatusColor(complianceStatus: string) {
    let color;
    switch (complianceStatus) {
      case ComplianceStatusValues.Issues:
        color = ComplianceStatusColors.Issues;
        break;
      case ComplianceStatusValues.NoIssues:
        color = ComplianceStatusColors.NoIssues;
        break;
      case ComplianceStatusValues.NA:
        color = ComplianceStatusColors.NA;
        break;
    }
    return color;
  }
  updateCurrentRadioSelected(value: string) {
    this.currentRadioButtonSelected = value;
  }

  saveComplianceData() {
    this.engagementObject.complianceStatus = this.currentRadioButtonSelected;
    this.engagementObject.complianceComments = this.currentComments;
    const complianceData = this._createObjectToSend(this.engagementObject.complianceStatus, this.engagementObject.complianceComments);
    this.closePopup();
    this.engagementHealthUpdateValue.emit(complianceData);
  }

  openNewTab(url: string) {
    window.open(url);
  }

  getDpnProfileLink(loginName: string): string {
    return AppSettings.dpnProfileLink.replace('{userId}', loginName);
  }

  private _createObjectToSend(complianceStatus: string, complianceComments: string): ComplianceData {
    const complianceData: ComplianceData = {
      complianceStatus,
      complianceComments,
      dealTeamItemId: this.engagementObject.engagementItemId
    };
    return complianceData;
  }
  addFormToEngagement(availableForm: AvailableFormItem) {
    this.ApplyBrowserScroll();
    this.displayStyle = "none";
    const data = {
      isOkayClicked: true,
      modalType: this.modalType,
      result:availableForm
    };
    this.addEHOFormDetails.emit(data);
  }
}
