import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'termination-form-signature-table',
  templateUrl: './termination-form-signature-table.component.html',
  styleUrls: ['./termination-form-signature-table.component.scss']
})

export class TerminationFormSignatureTable {
    
    @Input() signatureData:any;

    toggleClass:any = false;

}
