import { DropdownItem } from './dropdown-item';

export class SelectDynamicTableItem {
  competitorItem: DropdownItem = new DropdownItem();
  index: number;
  itemId: number;

  constructor() { }

  setCompetitorItem(item: DropdownItem) {
      this.competitorItem = item;
  }

  getCompetitorItem(): DropdownItem {
    return this.competitorItem;
  }

  setIndex(index:any) {
    this.index = index;
  }

  getIndex() {
    return this.index;
  }

  setItemId(itemId:any) {
    this.itemId = itemId;
  }

  getItemId() {
    return this.itemId;
  }

  isComplete(): boolean {
    if (this.competitorItem.value) {
        return true;
      }
    return false;
  }
}
