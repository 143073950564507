import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms"; 
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { SecurityService } from "../../../shared/services/security.service";
import { TooltipHelperService } from "../../../shared/services/tooltip-helper.service";
import { FormValidations } from "../../../shared/utils/form-validations";
import { FormListFields } from "../shared/models/form-list-fields";
import { TooltipObject } from "../../../shared/models/tooltip-object";

@Component({
    selector: 'app-dates-timeline',
    templateUrl: './dates-timeline.component.html',
    styleUrls: ['./dates-timeline.component.scss']
  })
export class DatesTimelineComponent implements OnInit {
    @Input() formSubmitted: boolean;
    @Input() formGroups: Array<FormGroup>;
    @Input() notApplicableEnabled:boolean = true;
    timelineContentFormGroup:any;
    sharePointMapper = new ComplianceSharepointMapper();
    constructor(private securityService: SecurityService,private tooltipHelperService: TooltipHelperService,){}
    ngOnInit(): void {
      this.timelineContentFormGroup = this.formGroups[0];
    }
    // Public Standart Functions

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }


  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }
   // Private Standart Functions

   private _setAsDisabled(formControlName: string) {
    const controlName = this.timelineContentFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  // getFormControlColumnName(sharepointColumnName: string): string {
  //   const elem = this.timelineContentFormGroup.find(el => el.columnSharepointName === sharepointColumnName);

  //   if (elem) {
  //     return elem.columnFormControlName;
  //   } else {
  //     return null;
  //   }
  // }

  onDateICReviewApprovalSelection(event: any) {
    this.timelineContentFormGroup.controls.dateICReviewApproval.setValue(event);
  }
  onPitchedDateSelection(event: any){
    this.timelineContentFormGroup.controls.pitchedDate.setValue(event);
  }
    
  onEngagementLetterSelection(event: any){
    this.timelineContentFormGroup.controls.signedEngagementLetter.setValue(event);
  }

  onEnteredMarketSelection(event: any){
    this.timelineContentFormGroup.controls.enteredMarket.setValue(event);
  }

  onIoiTermSheetsSelection(event: any){
    this.timelineContentFormGroup.controls.receivedIoiTermSheets.setValue(event);
  }

  onReceivedLoiSelection(event: any){
    this.timelineContentFormGroup.controls.receivedLOIs.setValue(event);
  }

  onSignedLoiSelection(event: any){
    this.timelineContentFormGroup.controls.signedLOI.setValue(event);
  }

  onTransactionCloseSelection(event: any){
    this.timelineContentFormGroup.controls.transactionClose.setValue(event);
  }

  isSimultaneousSignClose(event: any){
    this.timelineContentFormGroup.controls.simultaneousSignClose.setValue(event);
    this.evaluateSimultaneousSignClose(event);
  }

  evaluateSimultaneousSignClose(value: any) {
    if(value === "NO"){
      this.timelineContentFormGroup.controls.signDate.enable();
    }
    else{
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.signDate));
    }
  }

  onSignDateSelection(event: any){
    this.timelineContentFormGroup.controls.signDate.setValue(event);
  }
  getTooltip(tooltipTitle: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
  }

  getTooltipText(tooltipTitle:string):string{
    const tooltip = this.getTooltip(tooltipTitle);
    return tooltip ? tooltip.getTooltipText() : '';
  }

}
