import { HttpClient,HttpHeaders,HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs"; 
import { environment } from "src/environments/environment"; 
import { SearchState } from "../models/auditManagement-search";
import { AuditManagementItem } from "../models/auditManagementItem";
import { AuditManagementSort } from "../models/auditManagement-sort";
import { AuditManagementTableFilterItem } from "../models/auditManagement-table-filter-item";
import * as moment from 'moment';
import { AppSettings } from "src/shared/app-settings";

@Injectable({
  providedIn: 'root'
})
export class AuditService {
    private header = new HttpHeaders({ 'content-type': 'application/json' });
    constructor(private myhttp:HttpClient){}
    
    
    GetAuditDetails(contentName:any,tableName:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)
        const url = envURL +'/api/CrossBorderAuditDetails/GetList?contentType='+contentName+'&tableName='+ tableName;
        return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    }
    SaveAuditDetails(data:any):Observable<any>{        
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)    
      const url = envURL +'/api/CrossBorderAuditDetails/Add';
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
      ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })})
  }
  SaveAuditDetailsInForm(data:any):Observable<any>{   
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)         
    const url = envURL +'/api/CrossBorderAuditDetails/AddAuditsToForm';
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })})
  }
  GetAttachmentAuditDetails(formID:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorder)    
    const url = envURL +'/api/CrossBorderAttachments/GetAttachmentAudit?formID='+formID;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
}
   
}
