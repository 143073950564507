import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges
} from '@angular/core';
import { TypeaheadItem } from '../../models/typeahead-item';
import { Subject, Observable, merge } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map
} from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadService } from '../../services/typeahead.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  providers: [TypeaheadService]
})
export class TypeaheadComponent implements OnInit {

  @ViewChild('instance') instance: NgbTypeahead;

  @Input()
  keySelected: string;

  @Input()
  valueSelected:string="";

  @Input()
  listName: string;

  @Input()
  openOnFocus = true;

  @Input()
  maxResults: any = null;

  @Input()
  disabled = false;

  @Input()
  readonly: boolean;

  @Output()
  selectedTypeaheadItem: EventEmitter<TypeaheadItem> = new EventEmitter<TypeaheadItem>();

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  typeaheadItemList: Array<TypeaheadItem> = new Array<TypeaheadItem>();
  editable = true;
  term: string;
  currentPage: string;
  showArrowIcons: boolean;

  constructor(private typeaheadService: TypeaheadService,public globalHelperService:GlobalHelperService) { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    this.onKeySelection(event);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.onKeySelection(event);
  }

  ngOnInit() {
    // this.showArrowIcons = localStorage.getItem("CurrentAccessPage") !== 'DealWBSForm';
    this.showArrowIcons = this.globalHelperService.currentAccessPage !== 'DealWBSForm';
    this.typeaheadService.getItems(this.listName).then(
      (response:any) => {          
        this.typeaheadItemList = response.sort((a:any,b:any)=>(a.Value>b.Value?1:-1));
        if (response) {
          //const selectedValue = this.typeaheadItemList.find(item => item.Key === this.keySelected.toString());
          const selectedValue = this.typeaheadItemList.find(item => item.Value === this.valueSelected.toString());
          this.term =
            selectedValue && selectedValue.Value
              ? selectedValue.Value
              : '';
        }
      }        

    ).catch((err: any) => {
      console.log('Error fetching dropdown data:', err);        
    });  

  
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );

    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        const results = this.typeaheadItemList.filter(
          v => v.Value.toLowerCase().indexOf(term.toLocaleLowerCase()) > -1
        );

        return this.maxResults ? results.slice(0, this.maxResults) : results;
      })
    );
  }

  onSelectItem(event: any) {
    //this.keySelected = event.item.Key;
    this.valueSelected = event.item.Value;
    const objectToEmit: TypeaheadItem = {
      Key: event.item.Key,
      Value: event.item.Value
    };
    this.selectedTypeaheadItem.emit(objectToEmit);
    this.editable = false;
  }

  formatter = (x: { Value: string }) => x.Value;

  openClickPopup(event:any) {
    this.click$.next(event.target.value);
  }

  openFocusPopup(event:any) {
    event.stopPropagation();
  }

  onKeySelection(event:any) {
    if (!this.editable) {
      if (event.keyCode !== 8 && event.keyCode !== 46) {
        event.preventDefault();
      } else {
        this.editable = true;
      }
    }
  }

  onChangeItem(event:any) {
    if (event.target.value === '') {
      this.selectedTypeaheadItem.emit(new TypeaheadItem());
    }
  }

  get selectedValue(): string | any {
    if (this.keySelected && this.typeaheadItemList && this.typeaheadItemList.length) {
      return this.typeaheadItemList.find(option => option.Key === this.keySelected.toString())?.Value;
    }
    if (this.valueSelected && this.typeaheadItemList && this.typeaheadItemList.length) {
      return this.valueSelected;
    }
    return '';
  }

}
