<div [formGroup]="marketingAndCommunicationsFormGroup" class="content-section" id="marketing-and-communications">
    <div class="row">
        <span class="title-section">Marketing and Communications</span>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('pitchBook')"
            [class.edited-externally]="editedExternally('pitchBook')"
          [class.conflict-on-merge]="hasMergeConflict('pitchBook')">Was a Pitch Book used?</span>
            <span *ngIf="showAsterisk('pitchBook')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoPitchBook
              [value]="marketingAndCommunicationsFormGroup.get('pitchBook').value"
              [readonly]="isReadOnly('pitchBook')"
              (handleToggleYesNo)="onPitchBookSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('pitchBookSeries24')"
            [class.edited-externally]="editedExternally('pitchBookSeries24')"
          [class.conflict-on-merge]="hasMergeConflict('pitchBookSeries24')">Is evidence of the Series 24 approval saved to final project folder?</span>
            <span *ngIf="showAsterisk('pitchBookSeries24')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoPitchBookSeries24
              [value]="marketingAndCommunicationsFormGroup.get('pitchBookSeries24').value"
              [readonly]="isReadOnly('pitchBookSeries24')"
              [disabled]="marketingAndCommunicationsFormGroup.get('pitchBookSeries24').disabled"
              (handleToggleYesNo)="onPitchBookSeries24Selection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('teaser')"
            [class.edited-externally]="editedExternally('teaser')"
          [class.conflict-on-merge]="hasMergeConflict('teaser')">Was a Teaser used?</span>
            <span *ngIf="showAsterisk('teaser')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoTeaser
              [value]="marketingAndCommunicationsFormGroup.get('teaser').value"
              [readonly]="isReadOnly('teaser')"
              (handleToggleYesNo)="onTeaserSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('teaserSeries24')"
            [class.edited-externally]="editedExternally('teaserSeries24')"
          [class.conflict-on-merge]="hasMergeConflict('teaserSeries24')">Is evidence of the Series 24 approval saved to final project folder?</span>
            <span *ngIf="showAsterisk('teaserSeries24')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoTeaserSeries24
              [value]="marketingAndCommunicationsFormGroup.get('teaserSeries24').value"
              [readonly]="isReadOnly('teaserSeries24')"
              [disabled]="marketingAndCommunicationsFormGroup.get('teaserSeries24').disabled"
              (handleToggleYesNo)="onTeaserSeries24Selection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('cimMemorandumPPM')"
            [class.edited-externally]="editedExternally('cimMemorandumPPM')"
          [class.conflict-on-merge]="hasMergeConflict('cimMemorandumPPM')">Was a CIM / Memorandum / PPM used?</span>
            <span *ngIf="showAsterisk('cimMemorandumPPM')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoCimMemorandumPPM
              [value]="marketingAndCommunicationsFormGroup.get('cimMemorandumPPM').value"
              [readonly]="isReadOnly('cimMemorandumPPM')"
              (handleToggleYesNo)="onCimMemorandumPPMSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('cimMemorandumPPMSeries24')"
            [class.edited-externally]="editedExternally('cimMemorandumPPMSeries24')"
          [class.conflict-on-merge]="hasMergeConflict('cimMemorandumPPMSeries24')">Is evidence of the Series 24 approval saved to final project folder?</span>
            <span *ngIf="showAsterisk('cimMemorandumPPMSeries24')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoCimMemorandumPPMSeries24
              [value]="marketingAndCommunicationsFormGroup.get('cimMemorandumPPMSeries24').value"
              [readonly]="isReadOnly('cimMemorandumPPMSeries24')"
              [disabled]="marketingAndCommunicationsFormGroup.get('cimMemorandumPPMSeries24').disabled"
              (handleToggleYesNo)="onCimMemorandumPPMSeries24Selection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('tombstone')"
            [class.edited-externally]="editedExternally('tombstone')"
          [class.conflict-on-merge]="hasMergeConflict('tombstone')">Was a Tombstone Issued?</span>
            <span *ngIf="showAsterisk('tombstone')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoTombstone
              [value]="marketingAndCommunicationsFormGroup.get('tombstone').value"
              [readonly]="isReadOnly('tombstone')"
              (handleToggleYesNo)="onTombstoneSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('tombstoneSeries24')"
            [class.edited-externally]="editedExternally('tombstoneSeries24')"
          [class.conflict-on-merge]="hasMergeConflict('tombstoneSeries24')">Is evidence of the Series 24 approval saved to final project folder?</span>
            <span *ngIf="showAsterisk('tombstoneSeries24')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoTombstoneSeries24
              [value]="marketingAndCommunicationsFormGroup.get('tombstoneSeries24').value"
              [readonly]="isReadOnly('tombstoneSeries24')"
              [disabled]="marketingAndCommunicationsFormGroup.get('tombstoneSeries24').disabled"
              (handleToggleYesNo)="onTombstoneSeries24Selection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('caseStudies')"
            [class.edited-externally]="editedExternally('caseStudies')"
          [class.conflict-on-merge]="hasMergeConflict('caseStudies')">Were Case Studies used in the engagement?</span>
            <span *ngIf="showAsterisk('caseStudies')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoCaseStudies
              [value]="marketingAndCommunicationsFormGroup.get('caseStudies').value"
              [readonly]="isReadOnly('caseStudies')"
              (handleToggleYesNo)="onCaseStudiesSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('caseStudiesSeries24')"
            [class.edited-externally]="editedExternally('caseStudiesSeries24')"
          [class.conflict-on-merge]="hasMergeConflict('caseStudiesSeries24')">Is evidence of the Series 24 approval saved to final project folder?</span>
            <span *ngIf="showAsterisk('caseStudiesSeries24')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoCaseStudiesSeries24
              [value]="marketingAndCommunicationsFormGroup.get('caseStudiesSeries24').value"
              [readonly]="isReadOnly('caseStudiesSeries24')"
              [disabled]="marketingAndCommunicationsFormGroup.get('caseStudiesSeries24').disabled"
              (handleToggleYesNo)="onCaseStudiesSeries24Selection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('pressRelease')"
            [class.edited-externally]="editedExternally('pressRelease')"
          [class.conflict-on-merge]="hasMergeConflict('pressRelease')">Was a Press Release issued for the engagement?</span>
            <span *ngIf="showAsterisk('pressRelease')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoPressRelease
              [value]="marketingAndCommunicationsFormGroup.get('pressRelease').value"
              [readonly]="isReadOnly('pressRelease')"
              (handleToggleYesNo)="onPressReleaseSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('pressReleaseSeries24')"
            [class.edited-externally]="editedExternally('pressReleaseSeries24')"
          [class.conflict-on-merge]="hasMergeConflict('pressReleaseSeries24')">Is evidence of the Series 24 approval saved to final project folder?</span>
            <span *ngIf="showAsterisk('pressReleaseSeries24')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoPressReleaseSeries24
              [value]="marketingAndCommunicationsFormGroup.get('pressReleaseSeries24').value"
              [readonly]="isReadOnly('pressReleaseSeries24')"
              [disabled]="marketingAndCommunicationsFormGroup.get('pressReleaseSeries24').disabled"
              (handleToggleYesNo)="onPressReleaseSeries24Selection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('leagueTablesMarketing')"
            [class.edited-externally]="editedExternally('leagueTablesMarketing')"
          [class.conflict-on-merge]="hasMergeConflict('leagueTablesMarketing')">League Tables – Has Marketing been submitted to Deal Exchange?</span>
            <span *ngIf="showAsterisk('leagueTablesMarketing')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoLeagueTablesMarketing
              [value]="marketingAndCommunicationsFormGroup.get('leagueTablesMarketing').value"
              [readonly]="isReadOnly('leagueTablesMarketing')"
              (handleToggleYesNo)="onLeagueTablesMarketingSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
  </div>
  