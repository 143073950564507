<div
  id="admin-review"
  [formGroup]="formGroup"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
      <span>Admin Review</span>
  </div>
  <div id="admin-review-section" class="content-section">
      <div class="row">
          <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('wbsCode')"
              [class.edited-externally]="editedExternally('wbsCode')"
              [class.conflict-on-merge]="hasMergeConflict('wbsCode')">
                WBS Code
            </span>
              <span *ngIf="showAsterisk('wbsCode')" class="red-asterik"></span>
          </div>
          <div class="col-xs-8">
              <input
              formControlName="wbsCode"
              [class.readonly]="isReadOnly('wbsCode')"
              class="input-data-text full-width section-input"
              type="text"
              autocomplete="off">
          </div>
      </div>
      <!-- <div class="row">
        <div class="col-xs-4 section-label">
          <span [class.highlight-warning]="highlightRequiredField('prdCode')"
            [class.edited-externally]="editedExternally('prdCode')"
            [class.conflict-on-merge]="hasMergeConflict('prdCode')">
              PRD Code
          </span>
            <span *ngIf="showAsterisk('prdCode')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <input
            formControlName="prdCode"
            [class.readonly]="isReadOnly('prdCode')"
            class="input-data-text full-width section-input"
            type="text"
            autocomplete="off">
        </div>
    </div> -->
      <div *ngIf="!adminApprovalName && editable" class="row"style="float: right;">
        <div class="col-xs-12 submit-buttons">
          <button
            class="btn btn-primary btn-approve approval-button"
            [disabled]="saveInProgress"
            (click)="adminApproval()">
            Approve
          </button>
          <button *ngIf="saveButtonVisible"
            class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
            [disabled]="saveInProgress"
            (click)="adminSave()">
            Save
          </button>
          <button
            class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
            [disabled]="saveInProgress"
            (click)="adminReturnForRework()">
            Return for rework
          </button>
          <button
            class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
            [disabled]="saveInProgress"
            (click)="adminDiscontinue()">
            Discontinue
          </button>
        </div>
      </div>
      <div *ngIf="adminApprovalName" class="row">
        <div class="border-admin-review-section">
          <div class="approval-signature-admin-review-section">
            <i>Admin</i>
            <div>
              <span class="signature-color name-admin-review-section"><b>{{adminApprovalName}}</b></span>
            </div>
            <hr class="hr-admin-review-section">
          </div>
          <div class="approval-date-admin-review-section">
            <span class="fa fa-check-circle reviewed-icon-color reviewed-icon-admin-review-section"></span>
            <span class="date-admin-review-section"><b>{{adminApprovalDate}}</b></span>
          </div>
        </div>
      </div>
  </div>
</div>
