import { Injectable } from '@angular/core';
import { Spcrud } from 'src/shared/services/spcrud';
import { AppSettings } from 'src/shared/app-settings';


import { BaseService } from 'src/shared/services/base.service';
import { RootSiteSpcrud } from 'src/shared/services/root-site-spcrud';

import { MainService } from 'src/shared/services/main.service';
import { CurrentUser } from 'src/shared/models/current-user-data';
import { ReportDashboardItem } from '../../models/reportDashboardItem';
import { CustomSiteSpcrud } from 'src/shared/services/custom-site-spcrud';
import { ReportDashboardServiceHelper } from './report-dashboard-helper';
import { catchError, forkJoin, from, map, Observable, throwError } from 'rxjs';
import { EngagementDashboardService } from 'src/app/engagementDashboard/shared/services/engagement-dashboard.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';

@Injectable({
  providedIn: 'root'
})
export class ReportDashboardService extends BaseService {
  constructor(
    spcrud: Spcrud,
    rootSiteSpcrud: RootSiteSpcrud,
    customSiteSpcrud: CustomSiteSpcrud,
    private reportDashboardServiceHelper: ReportDashboardServiceHelper,private mainService:MainService,private engagementdashboardService: EngagementDashboardService,
    private newdealService: NewdealService,private closingChecklistService: ClosingCheckListService) {
      super(spcrud, rootSiteSpcrud, customSiteSpcrud);
    }

    searchEngagements(searchTerm: string): Promise<any> {
      const listName = AppSettings.engagementHealthList;
    const closingCheckListName = AppSettings.closingCheckList;
    const spcrud = this.getSpcrud(listName);
    const customSpcrud = this.getSpcrud(closingCheckListName);
    
    const options = {
      top: 4000,
      select: '*, ' + AppSettings.myDealSiteColumn
    };
    const closingCheckListOptions = {
      top: 4000,
      select: '*'// + AppSettings.myDealSiteColumn
    };
      const myDealFilter = 'substringof(\'' + searchTerm + '\', EngagementName) or substringof(\'' +
                            searchTerm + '\', ClientName)';
                            return Promise.all([spcrud.read(listName, options),
                              this.retrieveMyDealItems(myDealFilter),
                               customSpcrud.read(closingCheckListName, closingCheckListOptions),
                               this.retrieveNewDealItems()])
                           .then( ([response, responseMyDeal, closingCheckListResponse,newDealResponse]) => {
                             return this._setEngagementObject(response.d.results, responseMyDeal.d.results, closingCheckListResponse.d.results,newDealResponse.d.results);
                           }).catch(error => {
                           });
    }
  
    searchEngagements_Observable(searchTerm: string): Observable<any> {
      const listName = AppSettings.engagementHealthList;
      const closingCheckListName = AppSettings.closingCheckList;
      const spcrud = this.getSpcrud(listName);
      const customSpcrud = this.getSpcrud(closingCheckListName);     
      const options = {
        top: 4000,
        select: '*, ' + AppSettings.myDealSiteColumn
      };
      const closingCheckListOptions = {
        top: 4000,
        select: '*' // + AppSettings.myDealSiteColumn
      };
      const myDealFilter = `substringof('${searchTerm}', EngagementName) or substringof('${searchTerm}', ClientName)`;
     
      // Use forkJoin to combine multiple observables
      return forkJoin({
        response: from(spcrud.read(listName, options)),
        responseMyDeal: this.retrieveMyDealItems_Observable(myDealFilter),
        closingCheckListResponse: from(customSpcrud.read(closingCheckListName, closingCheckListOptions)),
        newDealResponse: this.retrieveNewDealItems_Observable()
      }).pipe(
        map(({ response, responseMyDeal, closingCheckListResponse, newDealResponse }) => {
          return this._setEngagementObject(
            (response as any).d.results,
            responseMyDeal.d.results,
            (closingCheckListResponse as any).d.results,
            newDealResponse.d.results
          );
        })
      );
    }

    retrieveReportDashboard_CCL(): Observable<any> {

    return forkJoin([this.engagementdashboardService.getEngagementDetails(), this.newdealService.GetMyDealDetails(), this.closingChecklistService.GetClosingCheckListFormDetails(),
      this.newdealService.GetNewDealDetails()]).pipe(
        map(([engagementDetails, mydealDetails, closingCheckListDetails, newdealDetails]) => {
          return this._setEngagementObject(engagementDetails, mydealDetails, closingCheckListDetails, newdealDetails);
        })
      );
    }
  
  retrieveReportDashboard(): Promise<any> {
    const listName = AppSettings.engagementHealthList;
    const closingCheckListName = AppSettings.closingCheckList;
   
    const spcrud = this.getSpcrud(listName);
    const customSpcrud = this.getSpcrud(closingCheckListName);
    const options = {
      top: 4000,
      select: '*, ' + AppSettings.myDealSiteColumn
    };
    const closingCheckListOptions = {
      top: 4000,
      select: '*'// + AppSettings.myDealSiteColumn
    };
    
    return Promise.all([spcrud.read(listName, options),
       this.retrieveMyDealItems(),
        customSpcrud.read(closingCheckListName, closingCheckListOptions),this.retrieveNewDealItems()])
    .then( ([response, responseMyDeal, closingCheckListResponse,newDealResponse]) => {
      return this._setEngagementObject(response.d.results, responseMyDeal.d.results, closingCheckListResponse.d.results,newDealResponse.d.results);
    }).catch(error => {
    });
  }

  retrieveReportDashboard_Observable(): Observable<any> {
    const listName = AppSettings.engagementHealthList;  
    const closingCheckListName = AppSettings.closingCheckList;   
    const spcrud = this.getSpcrud(listName);  
    const customSpcrud = this.getSpcrud(closingCheckListName);      
    const options = {  
      top: 4000,
      select: '*, ' + AppSettings.myDealSiteColumn  
    };  
    const closingCheckListOptions = {  
      top: 4000,  
      select: '*'  
    };   
    return forkJoin({  
      response: from(spcrud.read(listName, options)),  
      responseMyDeal: from(this.retrieveMyDealItems_Observable()),  
      closingCheckListResponse: from(customSpcrud.read(closingCheckListName, closingCheckListOptions)),  
      newDealResponse: from(this.retrieveNewDealItems_Observable())  
    }).pipe(
     map(({ response, responseMyDeal, closingCheckListResponse, newDealResponse }) => {  
        return this._setEngagementObject(  
          (response as any).d.results,  
          (responseMyDeal as any).d.results,  
          (closingCheckListResponse as any).d.results,  
          (newDealResponse as any).d.results  
        );  
      })  
    );
  
  }
  
   
  retrieveNewDealItems(filter?: string): Promise<any> {
    const newDealListName = AppSettings.newDealSetupFormList;
    const customNewSpcrud = this.getSpcrud(newDealListName);
    const options = {
      top: 4000,
      select: '*, ' + AppSettings.myDealSiteColumn
    };
    return customNewSpcrud.read(newDealListName, options);
  }

  retrieveNewDealItems_Observable(filter?: string): Observable<any> {
    const newDealListName = AppSettings.newDealSetupFormList;
    const customNewSpcrud = this.getSpcrud(newDealListName);
    const options = {
      top: 4000,
      select: '*, ' + AppSettings.myDealSiteColumn
    };
    return from(customNewSpcrud.read(newDealListName, options));
  }
  private _setEngagementObject(engagementResponse: any, myDealResponse: any, closingCheckListResponse: any,newDealResponse: any): Array<ReportDashboardItem> {
   // removing engagements without valid mydeal
    let validEngagementsList = engagementResponse.filter((item:any) => {
      return item[AppSettings.myDealSiteColumn] && this._findMyDealObject(myDealResponse, item[AppSettings.myDealSiteColumn]);
    });
    // removing engagements without valid newdeal
    validEngagementsList = validEngagementsList.filter((item:any) => {
      return this._findclosingCheckObject(newDealResponse, item[AppSettings.myDealSiteColumn]);
    });
      const ReportList = validEngagementsList.map((eng:any) => {
      const myDealId = eng[AppSettings.myDealSiteColumn];
      const itemNewDealData = this._findNewDealObject(newDealResponse, myDealId);
      const itemData = this._findclosingCheckObject(closingCheckListResponse, myDealId);
      const myDeal = this._findMyDealObject(myDealResponse, myDealId);
      if(itemData!=undefined && itemData.Status!='Draft')
      {
      // const engObject = this.reportDashboardServiceHelper.createReportDashboardObject(
      //   itemNewDealData.ID, myDeal.EngagementName,myDeal.Series24.Title,itemData.Series24ApprovalDate,false,itemData.Status,itemData.ID);
      const engObject = this.reportDashboardServiceHelper.createReportDashboardObject(
        itemNewDealData.ID, myDeal.EngagementName,myDeal.Series24DisplayNames,itemData.Series24ApprovalDate,false,itemData.Status,itemData.ID);
      return engObject;
      }
      return null;
    });
     return ReportList;
  }

  private _findNewDealObject( newDealResponse: any, myDealId: number) {
    const newDealObject = newDealResponse.find((item:any) => {
      return item[AppSettings.myDealSiteColumn] === myDealId;
    });
    return newDealObject;
  }
  
  private _findMyDealObject( myDealResponse: any, myDealId: number) {
    const myDealObject = myDealResponse.find((item:any) => {
      // return item.Id === myDealId;
      return item.ID === myDealId;
    });
    return myDealObject;
  }
  private _findclosingCheckObject(closingCheckListResponse: any, myDealId: number) {
    const newDealObject = closingCheckListResponse.find((item:any) => {
      return item[AppSettings.myDealSiteColumn] === myDealId;
    });
    return newDealObject;
  }
  retrieveMyDealItems(filter?: string): Promise<any> {
    const listName = AppSettings.myDealFormList;
    const spcrud = this.getSpcrud(listName);
    const options:any = {
      select: `EngagementName,EngagementId,Series24/Id, Series24/Title,EngagementType, StartDate, EndDate, ClientName, ID`,
      expand: 'Series24',
      top: 4000
    };
    if (filter) {
      // tslint:disable-next-line: no-string-literal
      options['filter'] = filter;
    }
    return spcrud.read(listName, options);
  }
  
  retrieveMyDealItems_Observable(filter?: string): Observable<any> {
    const listName = AppSettings.myDealFormList;
    const spcrud = this.getSpcrud(listName);
    const options: any = {
      select: `EngagementName,EngagementId,Series24/Id, Series24/Title,EngagementType, StartDate, EndDate, ClientName, ID`,
      expand: 'Series24',
      top: 4000
    };
    if (filter) {
      options['filter'] = filter;
    }
    return from(spcrud.read(listName, options));
  }

  getPaginationValues(): Promise<any> {
    const listName = AppSettings.paginationValuesList;
    const spcrud = this.getSpcrud(listName);
    return spcrud.read(listName)
    .then((response:any) => {
      return this.mapPageSizeValues(response.d.results);
    });
  }
  getPaginationValues_Observable(): Observable<any> {
    const listName = AppSettings.paginationValuesList;
    const spcrud = this.getSpcrud(listName);
    return from(spcrud.read(listName)).pipe(
      map((response: any) => {
        return this.mapPageSizeValues(response.d.results);
      })
    );
  }
  mapPageSizeValues(response:any): Array<number> {
    let valueList = response.map((value:any) => {
      return value.Value;
    });
    valueList = this._addPageSizeDefaultValue(valueList);
    valueList = this._sortPageSizeList(valueList);
    return valueList;
  }
  private _addPageSizeDefaultValue(valueList: Array<number>): Array<number> {
    if (!valueList.find(v => v === AppSettings.pageSizeDefault)) {
      valueList.push(AppSettings.pageSizeDefault);
    }
    return valueList;
  }
  private _sortPageSizeList(valueList: Array<number>): Array<number> {
    return valueList.sort((a, b) => a - b);
  }

}
