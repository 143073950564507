import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { DropdownItem } from "../../../shared/models/dropdown-item";
import { DropdownService } from "../../../shared/services/dropwdown.service";
import { TooltipHelperService } from "../../../shared/services/tooltip-helper.service";
import { SecurityService } from "../../../shared/services/security.service";
import { AppSettings } from "../../../shared/app-settings";
import { FormListFields } from "../shared/models/form-list-fields";
import { FormValidations } from "../../../shared/utils/form-validations";
import { TooltipObject } from "../../../shared/models/tooltip-object";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-client-overview',
    templateUrl: './client-overview.component.html',
    styleUrls: ['./client-overview.component.scss']
  })
export class ClientOverviewComponent implements OnInit {

    @Input() formSubmitted: boolean;
    @Input() formGroups: Array<FormGroup>;
  clientOverviewFormGroup: any;
    sharePointMapper = new ComplianceSharepointMapper();
  myDealFormGroup: any;
  countryList: Array<DropdownItem> = [];
  locationList: Array<DropdownItem> = [];
  industryOptions: Array<DropdownItem> = [];
  clientTypeOptions: Array<DropdownItem> = AppSettings.clientTypeOptions;
  clientRelationshipOptions: Array<DropdownItem> = AppSettings.clientRelationshipOptions;
  referralSourceOptions: Array<DropdownItem> = AppSettings.referralSourceOptions;
  

    constructor(private dropdownService: DropdownService,private tooltipHelperService: TooltipHelperService,
      private securityService: SecurityService,private cdr: ChangeDetectorRef){}

    ngOnInit(): void {
      this.clientOverviewFormGroup = this.formGroups[0];
      this.myDealFormGroup = this.formGroups[1];
            
      this.dropdownService.fillLookupOptions_CCF(AppSettings.closingCheckListLocations,'Location_State',environment.ClosingChecklistForm)
      .subscribe(response => {
        this.locationList = response;
      });

      this.dropdownService.fillLookupOptions_CCF(AppSettings.closingCheckListCountries,'Title',environment.ClosingChecklistForm)
      .subscribe(response => {
        this.dropdownService.countriesList=response;
        this.countryList = response;
      });

      this.dropdownService.fillLookupOptions_CCF(AppSettings.closingCheckListIndustries,'Industry',environment.ClosingChecklistForm)
      .subscribe(response => {
        this.industryOptions = response;
      });
      this.cdr.detectChanges();

    }

    
  // Standard functions

  getControlName(formControlName: string) {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return formGroup.get(formControlName);
    }
    return null;
  }

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    // Check if formControlName is 'clientCountry'
    if (formControlName === 'clientCountry') {
      // Access the clientCountry form control
      const clientCountryControl = this.clientOverviewFormGroup.get('clientCountry');
      // Return true if clientCountry is enabled, false otherwise
      return clientCountryControl ? !clientCountryControl.disabled : false;
    }
  
    // For other form controls, use the existing logic
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if(formControlName=="clientCountry"&&formGroup!=null){
      if(formGroup.get(formControlName)?.disabled==false && formGroup.get(formControlName)?.value == undefined){
      formGroup.get(formControlName)?.setErrors({required:true});
      }
    }
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }
  
  hasPermission(action: string): boolean {
    return !!this.securityService.hasPermission(action);
  }

  private _getFormGroupControl(formControlName: string): any {
    const formGroup = this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
    return formGroup.get(formControlName);
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
  evaluateClientType(value:any) {
    if(value === AppSettings.pegBacked) {
        this.clientOverviewFormGroup.controls.sponsorName.enable();
         this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.subsidiaryBeingSold));
        
      } else if (value === AppSettings.familyEntrepreneurOwned || value == null){
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.sponsorName));
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.subsidiaryBeingSold));
      }
      else if(value== AppSettings.corporatePrivate || value == AppSettings.corporatePublic){
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.sponsorName));
        this.clientOverviewFormGroup.controls.subsidiaryBeingSold.enable();
      }
    }

    evaluateReferralSource(value:any){
    if(value === AppSettings.dCFMROC || value == null ){
      //this.clientOverviewFormGroup.controls.referralSourceSubType.disable();
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.referralSourceSubType));
    }
    else{
      this.clientOverviewFormGroup.controls.referralSourceSubType.enable();
    }
  }

  evaluateClientLocation(value:any){
    if(value === 'International'){
      this.clientOverviewFormGroup.controls.clientCountry.enable();
    }
    else{
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.clientCountry));
    }
  }
    private _setAsDisabled(formControlName: string) {
      const controlName = this.clientOverviewFormGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }

    // field functions
    onLocationSelection(event:any) {
      this.clientOverviewFormGroup.controls.clientLocation.setValue(event.key);
      this.evaluateClientLocation(event.key);
    }

  onCountrySelection(event: any) {
      this.clientOverviewFormGroup.controls.clientCountry.setValue(event.key);
    }

  onClientTypeSelection(event: any) {
      this.clientOverviewFormGroup.controls.clientType.setValue(event.key);
      this.evaluateClientType(event.key);
    }

  onClientRelationshipSelection(event: any) {
      this.clientOverviewFormGroup.controls.clientRelationship.setValue(event.key);
    }

  onReferralSourceSelection(event: any){
      this.clientOverviewFormGroup.controls.referralSource.setValue(event.key);
      this.evaluateReferralSource(event.key);
    }
  onindustrySelection(event: any){
      this.myDealFormGroup.controls.industry.setValue(event.key);
    }
    getTooltip(tooltipTitle: string): TooltipObject {
      return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
    }
  
    getTooltipText(tooltipTitle:string):string{
      const tooltip = this.getTooltip(tooltipTitle);
      return tooltip ? tooltip.getTooltipText() : '';
    }
}
