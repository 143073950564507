
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { Injectable } from '@angular/core';
import { CurrentUser } from '../models/current-user-data';
import { ReactivateFormObject } from '../models/reactivate-form-object';
import { FormStatusValue } from '../models/form-status-value';
import { DocumentTypeItem } from '../models/document-type-item';
import { PeoplepickerDynamicTableItem } from '../models/peoplepicker-dynamic-table-item';
import { PeoplePickerUser, EntityData } from 'src/shared/models/people-picker-user';
import { SelectDynamicTableItem } from '../models/select-dynamic-table-item';
import * as moment from 'moment';
import { FreeTextItem } from '../models/free-text-item';
import { FreeTextTableItem } from '../models/free-text-table-item';




@Injectable({
  providedIn: 'root'
})

export class MainServiceHelper {
  

  

  createDropdownItem(id: number, title: string): DropdownItem {
    const dropdownItem = new DropdownItem();
    dropdownItem.key = id.toString();
    dropdownItem.value = title;
    return dropdownItem;
  }

  createPeoplepickerDynamicTableItem(id: number, peoplePickerUser: PeoplePickerUser, item:any): PeoplepickerDynamicTableItem {
    const peoplepickerDynamicTableItem = new PeoplepickerDynamicTableItem();
    peoplepickerDynamicTableItem.usersSelected = [peoplePickerUser];
    peoplepickerDynamicTableItem.idUsersSelected = [id];
    peoplepickerDynamicTableItem.optionsSelectedFirstColumn = this.createDropdownItem(item.Role.Id, item.Role.Role);
    peoplepickerDynamicTableItem.optionsSelectedSecondColumn = this.createDropdownItem(item.PursuitRole.Id, item.PursuitRole.Role);
    peoplepickerDynamicTableItem.optionsSelectedThirdColumn = this.createDropdownItem(item.SwiftRole.Id, item.SwiftRole.Role);
    peoplepickerDynamicTableItem.itemId = item.Id;
    return peoplepickerDynamicTableItem;
  }
  

  createCurrentUserObject(userData:any): any {    
    var currentUser = {
      email: localStorage.getItem("userProfileId")?.toString(),
      id: 0,
      loginName: "",
      title: "",
      Name: "",
      groups:Array<string>()
    };
    const userGroups = this.getCurrentUserGroups(userData);
    if(userGroups.length!=0)
    {
      currentUser.email = userData[0].Email;
      currentUser.id= userData[0].ID;
      currentUser.loginName=userData[0].Title,
      currentUser.title= userData[0].FirstName+", "+userData[0].LastName,
      currentUser.Name= userData[0].LastName+", "+userData[0].FirstName,
      currentUser.groups=userGroups;                      
    }  
    return currentUser;
  }

  createSelectDynamicTableItem(competitorSelector: DropdownItem, id: number): SelectDynamicTableItem {
    const competitorTableItem = new SelectDynamicTableItem();
    competitorTableItem.competitorItem = competitorSelector;
    competitorTableItem.itemId = id;
    return competitorTableItem;
  }

  createPeoplePickerUser(loginName: string, title: string, email: string): PeoplePickerUser {
    const peoplePickerUser = new PeoplePickerUser();
    peoplePickerUser.Key = loginName;
    peoplePickerUser.DisplayText = title;
    peoplePickerUser.EntityData = new EntityData();
    peoplePickerUser.EntityData.Email = email;
    return peoplePickerUser;
  }

  createReactivateFormObject(userTitle: any): any {
    const reactivateObject = {
      ReactivatedOn: moment().format("MM/DD/YYYY hh:mm:ss A"),
      ReactivatedBy: userTitle,
      FormStatus: FormStatusValue.reactivated
    };
    return reactivateObject;
    }

    createCurrentUserObjectTF(userData:any): CurrentUser {
      const userGroups = this.getCurrentUserGroups(userData);
      const currentUser = {
        email: userData[0].Email,
        id: userData[0].ID,
        loginName: userData[0].Title,
        title: userData[0].FirstName+", "+userData[0].LastName,
        groups: userGroups,
        Name:userData[0].LastName+", "+userData[0].FirstName,
      };
      return currentUser;
    }

  //getCurrentUserGroups(dataUserGroups:any): Array<string> {
  //  let groups = new Array<string>();
  //  groups = dataUserGroups.map(group => {
  //    return group.Title;
  //  });
  //  return groups;
    //}

    getCurrentUserGroups(dataUserGroups: any): Array<string> {
        let groups = new Array<string>();
        groups = dataUserGroups.map((group: any) => {
            return group.Role;
        });
        return groups;
    }


  createDocumentTypeObject(
    documentTypeId: number,
    documentTypeItemId: number,
    documentTypeTitle: string,
    documentTypeRequired: any,
    documentTypeAdditional: boolean): DocumentTypeItem {

    const fileItem = new DocumentTypeItem();
    fileItem.documentTypeId = documentTypeId;
    fileItem.documentTypeItemId = documentTypeItemId;
    fileItem.documentTypeTitle = documentTypeTitle;
    fileItem.documentTypeRequired = documentTypeRequired=="TRUE"?true:false;
    fileItem.documentTypeAdditional = documentTypeAdditional;
    fileItem.documentTypeAttachments = [];
    return fileItem;
  }

  createFreeTextItem(id: number, title: string): FreeTextItem {
    const freeTextItem = new FreeTextItem();
    const key:any = 'DTTLInvolved';
    freeTextItem.key = id.toString();
    freeTextItem.name = title[key];
    return freeTextItem;
  }

  createDTTLFreeTextTableItem(id: number, freeTextItem: FreeTextItem): FreeTextTableItem {
    const freeTextTableItem = new FreeTextTableItem();
    freeTextTableItem.freeTextOptionOne = freeTextItem;
    freeTextTableItem.itemId = id;

    return freeTextTableItem;
  }

  createCounterpartiesItem(id: number, title: string) {
    const freeTextItemOne = new FreeTextItem();
    const freeTextItemTwo = new FreeTextItem();
    const keyOne:any = 'Name';
    const keyTwo:any = 'TickerSymbol';

    freeTextItemOne.key = id.toString();
    freeTextItemOne.name = title[keyOne];

    freeTextItemTwo.key = id.toString();
    freeTextItemTwo.name = title[keyTwo];

    const arr = [freeTextItemOne, freeTextItemTwo];

    return arr;
  }


  createCounterpartiesFreeTextTableItem(id: number, freeTextItem:any, item:any): FreeTextTableItem {
    const freeTextTableItem = new FreeTextTableItem();
    freeTextTableItem.freeTextOptionOne = freeTextItem[0];
    freeTextTableItem.freeTextOptionTwo = freeTextItem[1];
    freeTextTableItem.optionsSelectedFirstColumn = this.createDropdownItem(id, item.PubliclyTradedEntity);
    freeTextTableItem.optionsSelectedSecondColumn = this.createDropdownItem(id, item.RestrictedEntity);
    freeTextTableItem.itemId = id;

    return freeTextTableItem;
  }
}
