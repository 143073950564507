export class AvailableFormItem {
    availableFormItemId: number;
    availableFormTitle: string;
    availableFormListName: string;
    availableFormSiteUrl: string;
    availableFormPageUrl: string;
    availableAltFormPageUrl: string;
    availableFormCreated: boolean;
    availableFormSidebar: boolean;
    availableFormModal: any;
    itemId: number;
    formStatus: string;
}
