import { Injectable } from '@angular/core';
import { Spcrud } from './spcrud';
import { PeoplePickerQuery } from 'src/shared/models/peoplepicker-query';
import { of, Observable } from 'rxjs';
import { PeoplePickerUser, EntityData, LcsprUser } from 'src/shared/models/people-picker-user';
import { RootSiteSpcrud } from './root-site-spcrud';
import { BaseService } from './base.service';
import { AppSettings } from '../app-settings';
import { MainService } from '../services/main.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';


@Injectable({
  providedIn: 'root'
})
export class PeoplepickerService extends BaseService {
  peoplePickerQuery: PeoplePickerQuery;
  formId: number;

  constructor(spcrud: Spcrud, private mainService: MainService, public globalHelperService:GlobalHelperService,
              rootSiteSpcud: RootSiteSpcrud) {
                super(spcrud, rootSiteSpcud);
  }

  getPeoplePickerUsers(queryString: string, sharePointGroupId: number = 0,dealTeamTable:boolean): Promise<LcsprUser[]>|any {
    const spcrud = this.getSpcrud();
    this.peoplePickerQuery = {
      queryParams: {
        QueryString: queryString,
        MaximumEntitySuggestions: 10,
        AllowEmailAddresses: true,
        PrincipalSource: 15,
        PrincipalType: 1,
        SharePointGroupID: sharePointGroupId
      }
    };
    const dealTealUserSearchLog: object = {
      SerachTerm: this.peoplePickerQuery.queryParams.QueryString,
      UserName: this.mainService.currentUser.title,
      FormId: this.globalHelperService.formID//localStorage.getItem("formId")
    };
    // return of(mockUserList);
    if (this.peoplePickerQuery.queryParams.QueryString === '') {
      return null;
    }

    return spcrud.getPeoplePickerUserSuggestions(this.peoplePickerQuery,dealTeamTable)
    .then((response:any) => {
      spcrud.create(AppSettings.dealTealUserSearchLog, dealTealUserSearchLog);
      return response;
    });
  }
  getsharepointPeoplePickerUsers(queryString: string,sharePointGroupId: number = 0): Promise<PeoplePickerUser[]> | any{
    this.peoplePickerQuery = {
      queryParams: {
        QueryString: queryString,
        MaximumEntitySuggestions: 10,
        AllowEmailAddresses: true,
        PrincipalSource: 15,
        PrincipalType: 1,
        SharePointGroupID: sharePointGroupId
      }
    };
    const spcrud = this.getSpcrud();
    return spcrud.getsharepointPeoplePickerUserSuggestions(this.peoplePickerQuery)
    .then((response:any) => {
      return response;
    });
  
  }

  getUserId(email: string,  account: string) {
    const spcrud = this.getSpcrud();
    return spcrud.ensureUser(account)
      .then((response:any) => {
        return spcrud.getUserIdByEmail(email)
        .then((userResponse:any) => {
          return userResponse.d.Id;
        });
      });
  }
  BindParticipationUserDetails(userDetails:any){
    const entityData = new EntityData();
    entityData.Email = userDetails.mail;
    const peoplePickerUser = new PeoplePickerUser();
    peoplePickerUser.Key = userDetails.mail;
    peoplePickerUser.DisplayText = userDetails.DisplayText;
    peoplePickerUser.displayName = userDetails.displayName;
    peoplePickerUser.EntityData = entityData;
    peoplePickerUser.mail=userDetails.mail;
  
    return peoplePickerUser;
  }
BindUserDetails(userDetails:any){
  const entityData = new EntityData();
  entityData.Email = userDetails.key;
  const peoplePickerUser = new PeoplePickerUser();
  peoplePickerUser.Key = userDetails.key;
  peoplePickerUser.DisplayText = userDetails.value;
  peoplePickerUser.displayName = userDetails.value;
  peoplePickerUser.EntityData = entityData;
  peoplePickerUser.mail=userDetails.key;

  return peoplePickerUser;
}
  getUserDetails(userId: number): Promise<any> {
    const spcrud = this.getSpcrud();
    return spcrud.getUserInfo(userId).then((response:any) => {
      const results = response.d;
      const entityData = new EntityData();
      entityData.Email = results.Email;
      const peoplePickerUser = new PeoplePickerUser();
      peoplePickerUser.Key = results.LoginName;
      peoplePickerUser.DisplayText = results.Title;
      peoplePickerUser.EntityData = entityData;

      return peoplePickerUser;
    });
  }

  getUserEmail(userId: number): Promise<string> {
    const spcrud = this.getSpcrud();
    return spcrud.getUserInfo(userId).then((response:any) => {
      return response.d.Email;
    });
  }
}
