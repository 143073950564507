<div class="peoplepicker-container">  
    <input
    type="text"
    #input
    class="people-picker-input form-control"
    [placeholder]="placeholder"
    [(ngModel)]="searchText" 
    (keyup)="onChange($event)"
    autocomplete="off"/>    
    
      <div class="modal" #rt tabindex="-1" role="dialog" [ngStyle]="{'display': searchData.length>0||!isUserExist?'contents':'none'}" clickElsewhere (clickElsewhere)="close()">
        <div class="modal-dialogs" style="margin: 0px 0px;position: absolute;">
          <div class="modal-content" style="border-radius: 1px;">
            <div class=" customBody">
              <div *ngFor="let data of searchData;let i = index">
                <div id="usersearch" class="list-data" (click)="onSelectUser(data)">
                  <input  [checked]="data.isSelected" type="checkbox" (change)="onSelectUserCheckBox($event)" />  
                   <img  style="margin-left:10px;" src="{{data.profilepicture}}"/>
                  <span>{{data.displayName}}</span>
                </div>              
              </div> 
              <div *ngIf="!isUserExist">
                <span>No Results Found..</span>
              </div>                          
            </div>              
          </div>
        </div>
      </div> 
</div>
