<div class="col-xs-12">
  <app-free-text-table #freeTextTable
                       *ngIf="dttlValues && restrictedEntityColumn && publicTradedEntityColumn"
                       [firsSelectColumnItemList]="firstColumnVisible ? publicTradedEntityColumn : null"
                       [secondSelectColumnItemList]="secondColumnVisible ? restrictedEntityColumn : null"
                       [valuesList]="dttlValues"
                       [readonly]="readonly"
                       (selectedRow)="onDTTLRowSelection($event)"
                       class="counterparties-table table-disable">

  </app-free-text-table>
</div>
