import { Injectable } from '@angular/core';
import { Spcrud } from './spcrud';
import { Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class RootSiteSpcrud extends Spcrud {

  constructor(http: Http) {
   super(http);

   // Change to Root Site
   this.setBaseUrl(true);
  }
}
