import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  ShowSignoffSection: boolean; 
  showReactivateSection: boolean; 
  ShowDealTeamSignoffSection:boolean; 
  ShowSeries24ApprovalSection:boolean; 
  ShowSubmitForReviewSection:boolean; 
  @Input() isFinanceForm: boolean=true; 
  isFixed: any;
  stoppingPoint: number = 100;
  @Input() isAutoSaveEnabled: boolean=false;
  @Input() saveInProgress: boolean=false;
  @Output()  SaveCCLFormDetails:EventEmitter<any>=new EventEmitter<any>();
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const offset = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (offset >= this.stoppingPoint) {
      this.isFixed = "width: 200px;line-height: initial;position: fixed;margin-left: -9px;margin-top: -100px;"
    } else {
      this.isFixed = "position: fixed; width: 200px;line-height: initial;margin-left: -9px;"
    }
  }
  constructor() { }
  
  ngOnInit() {
  }

  onclick(target:any) {
    const selector = document.querySelector(target);
    if (selector) {
      selector.scrollIntoView();
    }
  }
scrollTop(){
  const selector = document.querySelector('#project-overview');
  if (selector) {
    //selector.animate({scrollTop:0}, 300)
    selector.scrollIntoView();
  }
}
  // showSignoff() {
  //   this.ShowSignoffSection = true;
  // }
  showDealTeamSignoffSideMenu(){
    this.ShowDealTeamSignoffSection = true;
  }
  showSeries24ApprovalSideMenu(){
    this.ShowSeries24ApprovalSection = true;
  }
  showReactivateFormSideMenu() {
    this.showReactivateSection = true;
  }
  showSubmitForReviewSideMenu(){
    this.ShowSubmitForReviewSection = true;
  }
  //Added below to perform save without clicking top to bottom
  onSaveForm(){
   this.SaveCCLFormDetails.emit(true);
  }
}
