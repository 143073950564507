import { Injectable } from '@angular/core';
import { Spcrud } from './spcrud';
import { FileServiceHelper } from './files-service-helper';
import { BaseService } from './base.service';
import { RootSiteSpcrud } from './root-site-spcrud';
import { NewdealService } from 'src/app/services/newdeal.service';
import { AppSettings } from '../app-settings';
import { Observable, catchError, map, switchMap } from 'rxjs';
import { FileItem } from '../models/file-item';
import { EngagementHealthObjectServiceService } from 'src/app/services/engagement-health-object-service.service';
import { AttachmentItem } from '../models/attachment-item';
import { SharepointBreakpointMultipleObject } from 'src/app/closing-checklist-form/shared/models/sharepoint-breakpoint-multiple-object';


@Injectable({
    providedIn: 'root'
})

export class FilesService extends BaseService {

    constructor(spcrud: Spcrud,
                rootSiteSpcud: RootSiteSpcrud,
                private fileServiceHelper: FileServiceHelper,
                private newDealService : NewdealService,
                private EHOService:EngagementHealthObjectServiceService
                ) {
                    super(spcrud, rootSiteSpcud);
    }

    createFolder(libraryName: string, folderName: string) {
        const spcrud = this.getSpcrud();
        return spcrud.createFolder(libraryName, folderName)
        .then((response:any) => {
            return response.d.Id;
        })
        .catch((error:any) => {});
    }

    updateFolder(folderUrl: string, formId: number, documentTypeId: number, title: string) {
        const spcrud = this.getSpcrud();
        const objectToSend = this.fileServiceHelper.createFileMetadataObject(formId, documentTypeId, title);
        return spcrud.updateFolder(folderUrl, objectToSend)
        .then((response:any) => {
            return response;
        })
        .catch((error:any) => {});
    }

    uploadFile(uploadRequestObj:any):any {          
        return this.newDealService.uploadFile(uploadRequestObj).pipe(
          map((result: any) => result),
          catchError((err: any) => {
            console.log('Error Occured while uploading the file', err);
            throw err; // Re-throw the error to propagate it through the Observable stream
          })
        );

    }


    uploadFile_CCF(folderUrl: string, fileName: string, file: any) {
      const spcrud = this.getSpcrud();
      return spcrud.uploadFile(folderUrl, fileName, file)
      .then((response: any) => {
          return response;
      })
      .catch((error : any) => {});
  }

    addAttachement(uploadRequestObj :any,formType:any) {          
      return this.newDealService.AddAtachment(uploadRequestObj,formType).pipe(
        map((result: any) => result),
        catchError((err: any) => {
          console.log('Error Occured while uploading the file', err);
          throw err; // Re-throw the error to propagate it through the Observable stream
        })
      );

  }

  AddAdditionalDocumentTypeAtachment(uploadRequestObj :any) {          
    return this.newDealService.AddAdditionalDocumentTypeAtachment(uploadRequestObj).pipe(
      map((result: any) => result),
      catchError((err: any) => {
        console.log('Error Occured while adding additional document type.', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );

}

    validateEncryptedFile(file: FileItem): Promise<any>{
        const spcrud = this.getSpcrud();
        return spcrud.validateFileEncrypted(file)
        .then((response:any) => {
            return response;
        })
        .catch((error:any) => {
            console.error(error);
        });

    }

    updateFile(folderUrl: string, fileName: string, formId: number, documentTypeItemId: number) {
        const spcrud = this.getSpcrud();
        const objectToSend = this.fileServiceHelper.createFileMetadataObject(formId, documentTypeItemId, fileName);
        return spcrud.updateFile(folderUrl, fileName, objectToSend)
        .then((response:any) => {
            return response;
        })
        .catch((error:any) => {});
    }

    getFiles(formId: any,formType:any): Observable<any> {
      let appendedURL = '';
      if(formType == AppSettings.FormTypes.NewDealSetupForm)
      {
        appendedURL = this.newDealService.getAppendURL(AppSettings.newdealAttachmentsList);
      }
      else if(formType == AppSettings.FormTypes.ClosingCheckListForm)
      {
          appendedURL = this.newDealService.getAppendURL(AppSettings.closingCheckListAttachmentsList);
      }
      else
      {
        appendedURL = this.newDealService.getAppendURL(AppSettings.dealWbslAttachmentsList);
      }
      return this.newDealService.GetFormAttachments(appendedURL, formId,formType).pipe(
        map((result: any) =>{return result;}),
        catchError((err: any) => {
          console.log('Error Occured while getting Data', err);
          throw err; // Re-throw the error to propagate it through the Observable stream
        })
      );
    }
      getFilesDynamic(formId: string,BaseUrl: string, listName?: any):Observable<any> {
        const appendedURL = this.EHOService.getAppendURL(listName);   
        return this.EHOService.GetFormAttachments(appendedURL, formId,listName).pipe(
          map((result: any) => result),
          catchError((err: any) => {
            console.log('Error Occured while getting Data', err);
            throw err; // Re-throw the error to propagate it through the Observable stream
          })
        );
    }
    getFiles_CCF(folderUrl: string) {
      const spcrud = this.getSpcrud();
      return spcrud.getFiles(folderUrl)
      .then((response : any) => {
          return response.d.results;
      })
      .catch((error : any) => {});
  }

    createFileObject(attachmentName: string, attachmentUrl: string): AttachmentItem {
      const fileItem = new AttachmentItem();
      fileItem.attachmentName = attachmentName;
      fileItem.attachmentUrl = attachmentUrl;
      return fileItem;
    }
    generateFileUrlEHO(formid:any,fileName: string, folderUrl: string):any {
      this.newDealService.downloadAttachments(formid,fileName,folderUrl,'NewDeal').subscribe
      ({
        next: (response: any) => {
          const file = this.createFileObject(fileName, response.FileUrl);
          return file;                  
        },
        error: (err: any) => {         
          console.log("Error" + err)
        }
      }
      );   
    }
    generateFileUrl(fileName: string, folderUrl: string) {   
        const spcrud = this.getSpcrud();
        const parsedFileName = fileName;// encodeURIComponent(fileName);
        const fileUrl = spcrud.baseUrl + '/' + folderUrl + '/' + parsedFileName;
        return fileUrl;
    }

    deleteFile(formID: any, filename: string,formType:string) 
    {
        let pageSource = '',attachEnvironment = '';
        switch(formType)
        {
          case AppSettings.FormTypes.NewDealSetupForm:
            pageSource = AppSettings.newDealPageSource;
            attachEnvironment = AppSettings.newDealAttachementFolder;
            break;
          case AppSettings.FormTypes.DealWbsSetupForm:
            pageSource = AppSettings.dealWbsPageSource;
            attachEnvironment = AppSettings.dealWbsAttachementFolder;
            break;
          case AppSettings.FormTypes.ClosingCheckListForm:
            pageSource = AppSettings.closingCheckListPageSource;
            attachEnvironment = AppSettings.closingCheckListAttachmentsList;
            break;
        }
        
        return this.newDealService.DeleteFile(attachEnvironment, formID, filename,pageSource).pipe(
            switchMap((deleteResult: any) => {             
              return this.newDealService.DeleteAttachment(formID,filename,formType).pipe(
                map((newApiResult: any) => {                
                    return newApiResult;
                }),
                catchError((newApiError: any) => {
                  console.log('Error occurred while deleting attachment', newApiError);
                  throw newApiError; 
                })
              );
            }),
            catchError((deleteError: any) => {
              console.log('Error occurred while deleting File', deleteError);
              throw deleteError; 
            })
          );
    }
  

}
