<div [formGroup]="capitalRaisedFormGroup" class="content-section" id="capital-raised">
    <div class="row">
        <span class="title-section">Capital Raise</span>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('capitalRaised')"
            [class.edited-externally]="editedExternally('capitalRaised')"
            [class.conflict-on-merge]="hasMergeConflict('capitalRaised')">Total dollar value of capital raised, committed, refinanced or restructured in connection with this transaction.</span>
            <span *ngIf="showAsterisk('capitalRaised')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-numeric class="complianceNumeric"
                [type]="'decimal2'"
                [format]="'currency'"
                [value]="capitalRaisedFormGroup.get('capitalRaised')?.value"
                [readonly]="readOnly"
                (selectedValue)="onCapitalRaisedSelection($event)">
            </app-numeric>
        </div>
    </div>
</div>
