import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup} from '@angular/forms';

import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';
import { SecurityService } from '../../../shared/services/security.service';
import { FormValidations } from '../../../shared/utils/form-validations';
import { FormListFields } from '../shared/models/form-list-fields';

@Component({
  selector: 'app-capital-raised',
  templateUrl: './capital-raised.component.html',
  styleUrls: ['./capital-raised.component.scss']
})
export class CapitalRaisedComponent implements OnInit {
  sharePointMapper = new ComplianceSharepointMapper();
  capitalRaisedFormGroup: FormGroup;
  dealTypeDependencyValues: Array<any> = ['Capital Raise'];

  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;
  @Input() readOnly: boolean;
  constructor(private securityService: SecurityService) { }

  ngOnInit() {
    this.capitalRaisedFormGroup = this.formGroups[0];
  }

  // Public Standart Functions

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }


  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  // Custom Form Functions

  onCapitalRaisedSelection(event:any) {
    this.capitalRaisedFormGroup.controls.capitalRaised.setValue(event);
  }

  evaluateDealTypeDependency(value:any) {
    if (this.dealTypeDependencyValues.find( element => element === value)) {
      this.capitalRaisedFormGroup.controls.capitalRaised.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.capitalRaised));
    }
  }

  // Private Standart Functions

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.capitalRaisedFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }
}
