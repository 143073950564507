import { NgModule } from '@angular/core';
import { CommonModule, DatePipe} from '@angular/common';
import { SharedModule } from 'src/shared/SharedModule';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { EngagementDashboardComponent } from './engagement-dashboard/engagement-dashboard.component';
import { EngagementTableComponent } from './engagement-table/engagement-table.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
   // NgbModule.forRoot(),
   NgbModule,
    ReactiveFormsModule,
  ],
  declarations: [
    EngagementDashboardComponent,
    EngagementTableComponent
  ],
  exports: [
    EngagementDashboardComponent,
    EngagementTableComponent
  ],
  providers: [
    DatePipe,
    DateFormatterService
  ]
})

export class EngagementDashboardModule { }
