import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FileItem } from '../../models/file-item';
import { FilesService } from '../../services/files.service';
import { TooltipObject } from '../../models/tooltip-object';
import { TooltipHelperService } from '../../services/tooltip-helper.service';
import { NotifyPopupComponent } from '../alerts/notify-popup/notify-popup.component';
import { NewDealNotifyPopupComponent } from '../alerts/newdeal-notify-popup/newdeal-notify-popup.component';
import { DealWbsNotifyPopupComponent } from '../alerts/deal-wbs-notify-popup/deal-wbs-notify-popup.component';
import { ClosingChecklistNotifyPopupComponent } from '../alerts/closing-checklist-notify-popup/closing-checklist-notify-popup.component';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';

const modalTypes  = {
  fileSizeLimitExceeded: 'file-size-exceeded',
  fileNameInvalidCharacters: 'file-name-invalid',
  fileNameLongerThan128: 'file-name-long',
  fileTypeNotAllowed: 'file-type-invalid',
  fileEncrypted: 'file-encrypted',
  fileEmpty: 'file-empty'
};

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  maxFileSize = 104857600; // 100MB
  maxAllowedFileNameLength = 128;
  modalType: string;
  copy: any;

  @Input()
  buttonTitle: string;

  @Input()
  filesAssociated: boolean;

  @Input() readonly?:boolean; //Added for closing checklist form

  @Input()
  blockedFileTypes: Array<string> = [];

  @Input()
  invalidFileNameCharactersList: Array<string> = [];

  @Input()
  disabled: boolean;

  @Output()
  fileSelectedEvent = new EventEmitter<FileItem>();
  @Input() showDealWBSpopup: boolean;
  @Input() showNewDealpopup: boolean;
  @Input() showCCLListpopup: boolean;
  
  @ViewChild(NewDealNotifyPopupComponent, { static: true }) notifypopupComponent: NewDealNotifyPopupComponent;
  @ViewChild(DealWbsNotifyPopupComponent, { static: true }) DealWbsNotifyPopupComponent: DealWbsNotifyPopupComponent;
  @ViewChild(ClosingChecklistNotifyPopupComponent, { static: true }) cclpopupComponent: ClosingChecklistNotifyPopupComponent;


  @ViewChild('inputFilePlaceHolder')
  inputFilePlaceHolder: ElementRef

  fileInputElement: any;
  fileInputListenCallBack: any;
  fileSelected: File | any = null;

  constructor(private renderer: Renderer2,
    private fileService: FilesService,private tooltipHelperService: TooltipHelperService,private commonHelperService:CommonHelperService
  ) { }

  ngOnInit() {
  }
  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }
  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldName(fieldName);
  }

  toggleFileExplorer() {
    const parent = this.renderer.selectRootElement(this.inputFilePlaceHolder.nativeElement);

    this.fileInputElement = this.renderer.createElement('input');
    this.renderer.setAttribute(this.fileInputElement, 'type', 'file');
    this.renderer.setAttribute(this.fileInputElement, 'autocomplete', 'off');
    if (this.disabled) {
      this.renderer.setAttribute(this.fileInputElement, 'disabled', 'disabled');
    }
    this.renderer.addClass(this.fileInputElement, 'file-input');
    this.fileInputListenCallBack = this.renderer.listen(this.fileInputElement, 'change', (event) => {
      this.processFile();
    });

    this.renderer.appendChild(parent, this.fileInputElement);

    this.renderer.selectRootElement(this.fileInputElement).click();
  }

  processFile() {
    const file : File = this.fileInputElement.files[0];
    const fileName = file.name;
    const encodedFileName = this._replaceSingleQuote(fileName);
    const fileReader = new FileReader();

    if (!file) {
      return true;
    }
   // Step 1: Declare a variable to store the file name
    let fileTypeName = fileName;
   // Step 2: Split the file name based on the dot (.)
    let fileNameContents = fileTypeName.split('.');
    let fileExtention=fileNameContents[fileNameContents.length-1].toString();
    let isValidFileType=this.commonHelperService.ValidateFileType(fileExtention);
    if(isValidFileType){ 
    if (this._isValidUpload(fileName, encodedFileName, file.size, file.type)) {
      fileReader.addEventListener('load', (event: any) => {
        const result = event.target.result;
        const fileItem = new FileItem();
        fileItem.file = result;
        fileItem.name = fileName;
        fileItem.fileData = file;
        this.fileSelectedEvent.emit(fileItem);
      });

      if (file) {
        fileReader.readAsArrayBuffer(file);
      }    
    }
  }
  else{
    this._openModalDocumentType(modalTypes.fileTypeNotAllowed);
}

    this.fileInputListenCallBack;
    return
  }
  
  private _openModalDocumentType(modalType: string) {
    this.notifypopupComponent.openPopup('NewDeal',modalType);
  
  }
  private getFileSizeInMB(fileSize: number) {
    return fileSize / 1024 / 1024;
  }

  private _openModal(modalType: string) {
    this.modalType = modalType;        
    if(this.showDealWBSpopup)
      { 
        this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalType);
      }
    else if(this.showNewDealpopup)  {                                                                                                    
      this.notifypopupComponent.openPopup('NewDeal',modalType);
    }
    else if(this.cclpopupComponent)  {                                                                                                    
      this.cclpopupComponent.openPopup('ClosingChecklist',modalType);
    }
  }

  private _isFileTypeAllowed(fileType: string) {
    if (this.blockedFileTypes.length === 0) {
      return true;
    }

    const blockedFileType = this.blockedFileTypes.find(type => {
      return fileType === type;
    });

    return blockedFileType ? false : true;
  }

  private _isFileNameValid(fileName: string) {
    let isValid = true;
    this.invalidFileNameCharactersList.forEach(character => {
      if (fileName.indexOf(character) !== -1) {
        isValid = false;
      }
    });
    return isValid;
  }

  private _isFileLengthValid(fileName: string) {
    return (fileName.length <= this.maxAllowedFileNameLength);
  }

  private _isFileSizeValid(fileSize: number) {
    return fileSize <= this.maxFileSize;
  }

  private _isFileEmpty(fileSize: number) {
    return fileSize <= 0;
  }

  private _isValidUpload(fileName: string, encodedFileName: string, fileSize: number, fileType: string) {
    switch (false) {
      case this._isFileNameValid(fileName):
        this._openModal(modalTypes.fileNameInvalidCharacters);
        return false;
      case this._isFileLengthValid(encodedFileName):
        this._openModal(modalTypes.fileNameLongerThan128);
        return false;
      case this._isFileSizeValid(fileSize):
        this._openModal(modalTypes.fileSizeLimitExceeded);
        return false;
      case (!this._isFileEmpty(fileSize)):
        this._openModal(modalTypes.fileEmpty);
        return false;
      case this._isFileTypeAllowed(fileType):
        this._openModal(modalTypes.fileTypeNotAllowed);
        return false;
      default:
        return true;
    }
   
  }
  private _replaceSingleQuote(fileName: string) {
    return fileName.replace(/'/g, '\'\'');
  }
}
