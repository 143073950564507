import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SecurityService } from 'src/shared/services/security.service';
import { FormValidations } from 'src/shared/utils/form-validations';
import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';
import { FormListFields } from "../shared/models/form-list-fields";
@Component({

  selector: 'app-supervisory-signoff',
  templateUrl: './supervisory-signoff.component.html',
  styleUrls: ['./supervisory-signoff.component.scss']
})

export class SupervisorySignoffComponent implements OnInit {
  sharePointMapper:any = new ComplianceSharepointMapper();
  supervisorySignoffFormGroup: any;

  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;

  constructor(private securityService: SecurityService) { }

  ngOnInit() {
    this.supervisorySignoffFormGroup = this.formGroups[0];
    this._setAsDisabled('series24WBSCodeComments');
    this._setAsDisabled('series24TransactionComments');
    //Added below to apply Mandatory fields
    this.supervisorySignoffFormGroup.controls.series24ReviewedPersonnelHours.enable();
    this.supervisorySignoffFormGroup.controls.series24ReviewedTransaction.enable();

    
  }

  // Public Standart Functions

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  // Custom functions for section

  onSeries24ReviewedTransactionSelection(event:any) {
    if(event=="NO")
    {
      this.supervisorySignoffFormGroup.controls.series24TransactionComments.enable();
      this.supervisorySignoffFormGroup.controls.series24ReviewedTransaction.setValue(event);
    }
    else if(event=="YES"){
      this._setAsDisabled('series24TransactionComments');
      this.supervisorySignoffFormGroup.controls.series24ReviewedTransaction.setValue(event);
    }
    else{
      this._setAsDisabled('series24TransactionComments');
      this.supervisorySignoffFormGroup.controls.series24ReviewedTransaction.setValue("");
    }
   
  }

  

  onSeries24ReviewedPersonnelHoursSelection(event:any) {
    if(event=="NO")
    {
      this.supervisorySignoffFormGroup.controls.series24ReviewedPersonnelHours.setValue(event);
      this.supervisorySignoffFormGroup.controls.series24WBSCodeComments.enable();
    }
    else if(event=="YES"){
      this.supervisorySignoffFormGroup.controls.series24ReviewedPersonnelHours.setValue(event);
      this._setAsDisabled('series24WBSCodeComments');
    }
    else{
      this._setAsDisabled('series24WBSCodeComments');
      this.supervisorySignoffFormGroup.controls.series24ReviewedPersonnelHours.setValue("");
    }
    
  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.supervisorySignoffFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }
  // Private Standart Functions

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
