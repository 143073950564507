import { NumeralPipe } from 'ngx-numeral';

export class NumericValidation {
    format: string;
    currentValue: NumeralPipe;

    constructor(number: number, format: string) {
        this.format = format;
        if (number) {
            this.currentValue = new NumeralPipe(number);
        }
    }

    formatNumber(): any {
        if (this.currentValue) {
            return this.currentValue.format(this.format);
        }
    }

    validateNumber(): void {

    }
}
