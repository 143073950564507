<div id="dealTeamTable" name="DealTeamTable">
  <table>
    <thead>
      <tr>
        <th scope="col" class="deal-team-header">Name</th>
        <th scope="col" class="deal-team-header">Role</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let dealTeam of dealTeamList; let i = index">
        <td class="deal-team-col">
          <a (click)="openNewTab(getDpnProfileLink(dealTeam.dealTeamLoginName))">
            <span class="deal-team-name">{{dealTeam.Name}}</span>
          </a>
        </td>
        <td class="deal-team-col">
          <span>{{dealTeam.SwiftRole}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
