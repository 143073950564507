import { Injectable } from '@angular/core';
import { Spcrud } from 'src/shared/services/spcrud';
import { AppSettings } from 'src/shared/app-settings';
import { EngagementDashboardServiceHelper } from './engagement-dashboard-helper';
import { AvailableFormItem, EngagementDashboardtItem, FormStatusObjectItem } from '../../models/engagement-dashboard-item';
import { BaseService } from 'src/shared/services/base.service';
import { RootSiteSpcrud } from 'src/shared/services/root-site-spcrud';
import { CustomSiteSpcrud } from 'src/shared/services/custom-site-spcrud';
import { MainService } from 'src/shared/services/main.service';
import { CurrentUser } from 'src/shared/models/current-user-data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NewdealService } from 'src/app/services/newdeal.service';
import { Observable, forkJoin } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EngagementDashboardService extends BaseService {
  
  constructor(
    spcrud: Spcrud,
    rootSiteSpcrud: RootSiteSpcrud,
    customSiteSpcrud: CustomSiteSpcrud,
    private engagementDashboardServiceHelper: EngagementDashboardServiceHelper,private mainService:MainService, private newdealService: NewdealService,
    private myhttp: HttpClient) {
      super(spcrud, rootSiteSpcrud, customSiteSpcrud);
    }

  retrieveEngagements(): Promise<any> {
    
    return new Promise((resolve, reject) => {
      forkJoin([this.getEngagementDetails(),this.newdealService.GetMyDealDetails(),this.newdealService.GetNewDealDetails()]).subscribe({

        next:([engagementDetails, newdealDetails,dealteamDetails ])=>{
          resolve(
          this._setEngagementObject(engagementDetails,newdealDetails,dealteamDetails));
        },
        error: error => {
           reject(console.error('Error:', error));
         }
      })
    })
}
  
  getEngagementDetails():Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.EngagementDashboard)
    const appendedURL = this.newdealService.getAppendURL('EngagementHealthObject');
   const url = envURL + '/'+appendedURL+'/GetList';
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  getAvailableForms(): Promise<any> {
    const listName = AppSettings.engagementAvailableForms;
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: 'Visible eq ' + 1
    };
    return spcrud.read(listName, options)
      .then( (response:any) => {
        const results = response.d.results;
        const availableForms = this.createAvailableFormItemsObject(results);
        return availableForms;
      }).catch((error:any) => {
      });
  }
  createAvailableFormItemsObject(serviceResponse:any): Array<AvailableFormItem> {
    const availableFormsList = serviceResponse.map((item:any) => {
      const availableFormItem = new AvailableFormItem();
      availableFormItem.availableFormTitle = item.Title;
      availableFormItem.availableFormListName = item.ListName;
      availableFormItem.availableFormSiteUrl = item.SiteUrl;
      availableFormItem.availableFormPageUrl = item.PageUrl;
      availableFormItem.availableAltFormPageUrl = item.AltPageUrl;
      availableFormItem.availableFormCreated = false;
      availableFormItem.availableFormSidebar = item.Sidebar;
      availableFormItem.availableFormModal = item.Modal;
      availableFormItem.itemId = item.ID;
      return availableFormItem;
    });
    return availableFormsList;
  }
  retrieveFormStatusObject(listName: string, myDealId: number, customBaseUrl: string): Promise<any> {
    let spcrud:any; //this.getSpcrudCustom(listName, customBaseUrl);
    const options = {
      filter: `${AppSettings.myDealFormList} eq ${myDealId}`,
      select: `*, ID, Status`
    };

    return spcrud.read(listName, options)
    .then( (response:any) => {
      if (response.d.results.length === 0) {
        return null;
      }
      const results = response.d.results[0];
      const engObject = this.createFormStatusItem(results);
      return engObject;
    }).catch((error:any) => {
    });
  }
  createFormStatusItem(results:any): FormStatusObjectItem {
    const formStatusObjectItem: FormStatusObjectItem = {
      status:         results.Status,
      Id:  results.ID
   };
    return formStatusObjectItem;
  }
  searchEngagements(searchTerm: string): Promise<any> {
    const listName = AppSettings.engagementHealthList;
    const newDealListName = AppSettings.newDealSetupFormList;
    const spcrud = this.getSpcrud(listName);
    const customSpcrud = this.getSpcrud(newDealListName);
    const options = {
      top: 4000,
      select: '*, ' + AppSettings.myDealSiteColumn
    };
    const newDealOptions = {
      top: 4000,
      select: `ID, Status, SubmissionDate, ` + AppSettings.myDealSiteColumn
    };
    const myDealFilter = 'substringof(\'' + searchTerm + '\', EngagementName) or substringof(\'' +
                          searchTerm + '\', ClientName)';
    return Promise.all([spcrud.read(listName, options),
                        this.retrieveMyDealItems(myDealFilter),
                        customSpcrud.read(newDealListName, newDealOptions)])
    .then(([response, responseMyDeal, newDealResponse]) => {
      return this._setEngagementObject(response.d.results, responseMyDeal.d.results, newDealResponse.d.results);
    });
  }

  retrieveMyDealItems(filter?: string): Promise<any> {
    const listName = AppSettings.myDealFormList;
    const spcrud = this.getSpcrud(listName);
    const options:any = {
      select: `EngagementName, EngagementType, StartDate, EndDate, ClientName, ID, AdminAssigned/Title, AdminAssigned/UserName`,
      expand: 'AdminAssigned',
      top: 4000
    };
    if (filter) {
      // tslint:disable-next-line: no-string-literal
      options["filter"] = filter;
    }
    return spcrud.read(listName, options);
  }

  getPaginationValues(): Promise<any> {
    const listName = AppSettings.paginationValuesList;
    const spcrud = this.getSpcrud(listName);
    return spcrud.read(listName)
    .then((response:any)=> {
      return this.engagementDashboardServiceHelper.mapPageSizeValues(response.d.results);
    });
  }

  private _setEngagementObject(engagementResponse: any, myDealResponse: any, newDealResponse: any): Array<EngagementDashboardtItem> {
    // removing engagements without valid mydeal
    let validEngagementsList = engagementResponse.filter((item:any) => {
      return item[AppSettings.myDealSiteColumn] && this._findMyDealObject(myDealResponse, item[AppSettings.myDealSiteColumn]);
    });
    // removing engagements without valid newdeal
    validEngagementsList = validEngagementsList.filter((item:any) => {
      return this._findNewDealObject(newDealResponse, item[AppSettings.myDealSiteColumn]);
    });

      const engagementsList = validEngagementsList.map((eng:any) => {
      const myDealId = eng[AppSettings.myDealSiteColumn];
      const itemData = this._findNewDealObject(newDealResponse, myDealId);
      const myDeal = this._findMyDealObject(myDealResponse, myDealId);
      const adminName = myDeal.AdminAssignedDisplayName ? myDeal.AdminAssignedDisplayName : '';
      const adminUserName = myDeal.AdminAssignedDisplayName ? myDeal.AdminAssignedDisplayName : '';
      const engObject = this.engagementDashboardServiceHelper.createEngagementDashboardObject(
        itemData.ID, myDeal.EngagementName, itemData.Status, itemData.SubmissionDate, myDeal.ClientName, eng.ID, eng.ComplianceStatus,adminName,adminUserName,myDealId, itemData.FormStatus);
      return engObject;
    });
    return engagementsList;
  }

  private _findMyDealObject( myDealResponse: any, myDealId: number) {
    const myDealObject = myDealResponse.find((item:any) => {
      return item.ID === myDealId;
    });
    return myDealObject;
  }

  private _findNewDealObject( newDealResponse: any, myDealId: number) {
    const newDealObject = newDealResponse.find((item:any) => {
      return item[AppSettings.myDealSiteColumn] === myDealId;
    });
    return newDealObject;
  }

}
