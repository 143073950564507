import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SearchState } from '../models/reportDashboard-search';
import { debounceTime, tap, switchMap, delay } from 'rxjs/operators';
import { ReportDashboardSort } from '../models/reportDashboard-sort';
import { ReportDashboardTableFilterItem } from '../models/reportDashboard-table-filter-item';
import { AppSettings } from 'src/shared/app-settings';
import { ReportDashboardItem } from '../models/reportDashboardItem';
import { EscapeCharacters } from 'src/shared/utils/escape-characters';
import { MainService } from 'src/shared/services/main.service';
import { ReportDashboardService } from './services/report-dashboard.service';

import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportTabletDashboardService {
  loading$ = new BehaviorSubject<boolean>(false);
  search$ = new Subject<void>();
  reportDashboardList$ = new BehaviorSubject<ReportDashboardItem[]>([]);
  total$ = new BehaviorSubject<number>(0);
  state: SearchState = {
    page: 1,
    pageSize: AppSettings.pageSizeDefault,
    searchTerm: '',
    sortData: {sortColumn: 'engagementId', sortDirection: 'desc'},
    filterDataList: []
  };
ExportData:any=[];
  constructor(
    private reportDashboardService:ReportDashboardService,
       private escapeCharacters: EscapeCharacters,
       private mainService: MainService
      ) {
    this.search$.pipe(
      tap(() => this.loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this.loading$.next(false))
    ).subscribe(result => {
      this.reportDashboardList$.next(result);
    });
  }

  get searchTerm() { return this.state.searchTerm; }
  set searchTerm(searchTerm: string) {this._set({searchTerm}); }
  set sortData(sortData: ReportDashboardSort) { this._set({sortData}); }
  set filterDataList(filterDataList: Array<ReportDashboardTableFilterItem>) { this._set({filterDataList}); }

  sort(column: string, direction: string, reportDashboardList: Array<ReportDashboardItem>): Array<ReportDashboardItem> {
    if (direction !== '') {
      reportDashboardList = [...reportDashboardList].sort((a:any, b:any) => {
        const res = this.compare(a[column], b[column],column);
        return direction === 'asc' ? res : -res;
       
        
      });
    }
    return reportDashboardList;
  }

  compare(v1:any, v2:any,column:any) {
    if(column=="engagementName")
    {
    return v1.toUpperCase() < v2.toUpperCase() ? -1 : v1.toUpperCase() > v2.toUpperCase() ? 1 : 0;
    }
    
    else if(column=="s24ApprovalDate")
    {
      let v1Date = v1 != null && v1 != "" && v1 != "-" ? moment(v1) : moment().add(360, 'days');
	    let v2Date = v2 != null && v2 != "" && v2 != "-" ? moment(v2) : moment().add(360, 'days') ;
      return v1Date < v2Date ? -1 :  v1Date >  v2Date ? 1 : 0;
	 // return v1Date !=null && v2Date !=null && v1Date < v2Date ? -1 : v1Date !=null && v2Date !=null && v1Date >  v2Date ? 1 : 0;
    }
    else{
      v1=v1=="-"?'Z':v1==undefined?'z':v1;
      v2=v2=="-"?'Z':v2==undefined?'z':v2;
      return v1 < v2 ? -1 : v1> v2 ? 1 : 0;
    }


  }

  private _set(patch: Partial<SearchState>) {
    Object.assign(this.state, patch);
    if (typeof(patch.searchTerm) !== 'undefined') {
      this.search$.next();
    }
  }

  private _search(): Promise<any> {
    const {pageSize, page, searchTerm, sortData, filterDataList} = this.state;
    if ((searchTerm.trim().length >= 3)) {
      const searchTermParsed = this.escapeCharacters.replaceCharacteres(searchTerm);
      return this.reportDashboardService.searchEngagements(searchTermParsed).then(response => {
        return response;
      });
    }
    else{
      return new Promise((resolve, reject) => {
        this.reportDashboardService.retrieveReportDashboard_CCL().subscribe({
          next: (response) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          }
      })})
    }
  }

  // private _isDCFAdmin(): boolean {
  //   const newDealAdminGroup = AppSettings.adminsGroup;
  //   const usergroups = this.mainService.currentUser.groups;
  //   return !!usergroups.find(group => group === newDealAdminGroup);
  // }

  private _getAssignedEngagements(responseEngagements: any, assignedMyDealIds: number[]): ReportDashboardItem[] {
    return responseEngagements.filter((eng:any) => assignedMyDealIds.includes(eng.myDealId));
  }

}
