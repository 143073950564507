import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { Injectable } from '@angular/core';
import { IRolesValidator } from '../interfaces/compliance-roles-validator';
import { ComplianceService } from '../services/compliance-service';
import { Observable, catchError, forkJoin, from, map, of } from 'rxjs';
import { DealWbsSetupService } from '../../../deal-wbs-setup/shared/services/deal-wbs-setup.service';

@Injectable({
    providedIn: 'root'
})
export class RolesValidatorService implements IRolesValidator {
    constructor(
        private mainService: MainService,
        private ComplianceService: ComplianceService,
        private dealWbsSetupService: DealWbsSetupService
      ) { }

    validateFormRoles(roleList: Array<any>, userId: number,email:string, formId?: number,myDealFormId?: any): Promise<Array<string>> {
        return new Promise<Array<string>>((resolve, reject) => {
            const formGroups = [AppSettings.series24Group, AppSettings.adminsGroup, AppSettings.businessAdminsGroup];
            let roles = roleList.filter(item => {
                return (formGroups.find(x => x === item) !== undefined) ? true : false;
            });

            if (formId) {
                Promise.all([
                    this.mainService.isDealTeamMember(AppSettings.dealTeamTableList, myDealFormId ? myDealFormId : formId, userId),
                    this.ComplianceService.isAssignedSeries24(AppSettings.myDealFormList, myDealFormId, userId ),
                    this.mainService.getGroupUsers(AppSettings.closingChecklistVisitorsGroup)])
                    .then(([dealTeamResponse, series24Response,closingChecklistVisitorsGroups]) => {
                    if (dealTeamResponse) {
                        roles.push(AppSettings.dealTeamMembersGroup);
                    }
                    if (!series24Response) {
                        roles = roles.filter(item => {
                            return item !== AppSettings.series24Group;
                        });
                    }

                    if(closingChecklistVisitorsGroups.length > 0)
                    {
                        let isFromGroup = false;
                        isFromGroup = closingChecklistVisitorsGroups.some(function (objVal:any) {
                            return objVal.Email === email;
                        });
                        
                        if(isFromGroup)
                            roles.push(AppSettings.closingChecklistVisitorsGroup);
                    }
                    resolve(roles);
                }).catch(error => {
                    reject(error);
                });
            } else {
                roles = roles.filter(item => {
                    return item !== AppSettings.series24Group;
                });
                resolve(roles);
            }
        });
    }
    validateFormRoles_ClosingCheckList(roleList: Array<any>, userId: string, formId?: number, myDealformId?: number): Observable<any> {
      const formGroups = [AppSettings.series24Group, AppSettings.closingCheckListAdminGroup, 
                            AppSettings.closingCheckListBusinessAdminGroup,AppSettings.closingChecklistVisitorsGroup];
        let roles = roleList.filter(item => {
            return (formGroups.find(x => x === item) !== undefined) ? true : false;
        });
       //Adding Below Logicto add CCL S24 if the user existed in MyDeal_S24 Group to check the accessabilities.
       roles.forEach(item => {
        if(item==AppSettings.series24Group){
            roles.push(AppSettings.closingCheckListseries24Group);
        }
      });
        if (formId) {
            return forkJoin(
                this.mainService.isDealTeamMember_DealWbs( Number(myDealformId), userId),
                this.dealWbsSetupService.isAssignedSeries24_DealWbs(Number(myDealformId), userId)
            ).pipe(
                map(([dealTeamResponse, series24Response]) => {
                    if (dealTeamResponse) {
                        roles.push(AppSettings.dealTeamMembersGroup);
                    }
                    if (!series24Response) {
                        roles = roles.filter(item => {
                            //Removing CCL S24 aswell because, we are considering above.
                            return item !== AppSettings.series24Group&&item!==AppSettings.closingCheckListseries24Group;
                            
                        });
                    }
                    return roles;
                }),
                catchError(error => {
                    throw error;
                })
            );
        } else {
            roles = roles.filter(item => {
                //Removing CCL S24 aswell because, we are considering above.
                return item !== AppSettings.series24Group&&item!==AppSettings.closingCheckListseries24Group;
            });
            return from(roles);
        }
    }
}
