<div id="engagement-information"
  class="container-fluid border container-border-color container-section"
  [formGroup]="engagementInformationFormGroup">
  <div class="row bg-title">
    <span>Engagement Information</span>
  </div>
  <div class="content-section">
    <div class="row">
        <div class="col-xs-4 section-label">
          <span [class.highlight-warning]="highlightRequiredField('engagementName')"
            [class.edited-externally]="editedExternally('engagementName')"
            [class.conflict-on-merge]="hasMergeConflict('engagementName')">
              Engagement Name
          </span>
          <span *ngIf="showAsterisk('engagementName')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
          <input
          [formControl]="myDealFormGroup.get('engagementName')"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('engagementName')"
          type="text"
          autocomplete="off">
        </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('engagementTypeName')"
          [class.edited-externally]="editedExternally('engagementTypeName')"
          [class.conflict-on-merge]="hasMergeConflict('engagementTypeName')">
            Engagement Type
        </span>
        <span *ngIf="showAsterisk('engagementTypeName')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('EngagementTypeName')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <!-- Modified below HTML
          <input
        disabled
        [formControl]="getControlName('engagementTypeName')"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('engagementTypeName')"
        type="text"
        autocomplete="off"> -->
        <input
        [formControl]="getControlName('engagementTypeName')"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('engagementTypeName')"
        type="text"
        autocomplete="off" [disabled]="isDisabled"
        style="background-color: #e0e0e0;color: #ababab;">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('startDate')"
            [class.edited-externally]="editedExternally('startDate')"
            [class.conflict-on-merge]="hasMergeConflict('startDate')">
              Start Date
          </span>
          <span *ngIf="showAsterisk('startDate')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
          <app-date-picker
            [selectedDate]="getControlName('startDate')?.value"
            [readonly]="isReadOnly('startDate')"
            (dateSelected)="eventDateSelected($event,'startDate')">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('endDate')"
            [class.edited-externally]="editedExternally('endDate')"
            [class.conflict-on-merge]="hasMergeConflict('endDate')">
              End Date
          </span>
          <span *ngIf="showAsterisk('endDate')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
          <app-date-picker
            [selectedDate]="getControlName('endDate')?.value"
            [readonly]="isReadOnly('endDate')"
            (dateSelected)="eventDateSelected($event,'endDate')">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('modelAfterPriorEngagement')"
          [class.edited-externally]="editedExternally('modelAfterPriorEngagement')"
          [class.conflict-on-merge]="hasMergeConflict('modelAfterPriorEngagement')">
            Model after Prior Engagement
        </span>
        <span *ngIf="showAsterisk('modelAfterPriorEngagement')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('modelAfterPriorEngagement')?.value"
          [readonly]="isReadOnly('modelAfterPriorEngagement')"
          [disabledYes]="getControlName('modelAfterPriorEngagement')?.disabled"
          (handleToggleYesNo)="onModelAfterPriorEngagementSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span
          class="dependent-field-input"
          [class.highlight-warning]="highlightRequiredField('priorEngagement')"
          [class.edited-externally]="editedExternally('priorEngagement')"
          [class.conflict-on-merge]="hasMergeConflict('priorEngagement')">
          Prior Engagement
        </span>
        <span *ngIf="showAsterisk('priorEngagement')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
        formControlName="priorEngagement"
        class="input-data-text full-width section-input dependent-field-input"
        [class.readonly]="isReadOnly('priorEngagement')"
        type="text"
        autocomplete="off"
        [placeholder]="getControlName('modelAfterPriorEngagement')?.value === 'YES' ? '' : 'N/A'">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isRecurringWBS')"
          [class.edited-externally]="editedExternally('isRecurringWBS')"
          [class.conflict-on-merge]="hasMergeConflict('isRecurringWBS')">
            Is this a Recurring WBS?
        </span>
        <span *ngIf="showAsterisk('isRecurringWBS')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('isRecurringWBS')?.value"
          [readonly]="isReadOnly('isRecurringWBS')"
          [disabledYes]="getControlName('isRecurringWBS')?.disabled"
          (handleToggleYesNo)="onIsRecurringWBSSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row" style=" margin-top: -1%;">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('deloitteUSEntity')"
          [class.edited-externally]="editedExternally('deloitteUSEntity')"
          [class.conflict-on-merge]="hasMergeConflict('deloitteUSEntity')">
            Deloitte US Entity
        </span>
        <span *ngIf="showAsterisk('deloitteUSEntity')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8 typeahead-input">
        <app-typeahead
          [listName]="deloitteUSEntityCustomList"
          [keySelected]="getControlName('deloitteUSEntity').value"
          [readonly]="isReadOnly('deloitteUSEntity')"
          [disabled]="getControlName('deloitteUSEntity').disabled"
          (selectedTypeaheadItem)="onDeloitteUSEntitySelection($event)">
        </app-typeahead>
      </div>
    </div>
    <div class="row" style=" margin-top: -2%;">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('integratedMarketOffering')"
          [class.edited-externally]="editedExternally('integratedMarketOffering')"
          [class.conflict-on-merge]="hasMergeConflict('integratedMarketOffering')">
            Integrated Market Offering
        </span>
        <span *ngIf="showAsterisk('integratedMarketOffering')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
        [formControl]="myDealFormGroup.get('integratedMarketOffering')"  
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('integratedMarketOffering')"
        type="text"
        autocomplete="off">
      </div>
    </div>
    <div class="row" style=" margin-top: -1%;">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('salesOffice')"
          [class.edited-externally]="editedExternally('salesOffice')"
          [class.conflict-on-merge]="hasMergeConflict('salesOffice')">
            Sales Office
        </span>
        <span *ngIf="showAsterisk('salesOffice')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8 typeahead-input">
        <app-typeahead
          [listName]="salesOfficeCustomList"
          [valueSelected]="getControlName('salesOffice')?.value"
          [readonly]="isReadOnly('salesOffice')"
          (selectedTypeaheadItem)="onSalesOfficeSelection($event)">
        </app-typeahead>
      </div>
    </div>
    <div class="row" style=" margin-top: -2%;">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('engagementIndustrySICCode')"
          [class.edited-externally]="editedExternally('engagementIndustrySICCode')"
          [class.conflict-on-merge]="hasMergeConflict('engagementIndustrySICCode')">
            Engagement Industry (SIC Code)
        </span>
        <span *ngIf="showAsterisk('engagementIndustrySICCode')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('EngagementIndustrySICCode')"
          [keepOpen]="true">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'number'"
          [readonly]="isReadOnly('engagementIndustrySICCode')"
          [value]="getControlName('engagementIndustrySICCode')?.value"
          (selectedValue)="onEngagementIndustrySICCodeChange($event)">
        </app-numeric>
      </div>
    </div>
  </div>
</div>

