<ng-template  #rt let-r="result" let-t="term">
  <div>{{ r.displayName }}</div>
</ng-template>

<div class="form-control peoplepicker-container"
[ngClass]="disabled ? 'container-disabled' : ''">
  <div class="user-info" *ngFor="let user of peoplePickerUserList">
      <span *ngIf="user.displayName !== '-'"
        class="user-name colorStyle"
        [ngClass]="userLeftDCF ? 'user-left-dcf-span' : ''"
        [ngStyle]="{'background-image': userLeftDCF ? getUnderlinedImage() : 'url()'}">
        {{ user.displayName }}
      </span>
      <span *ngIf="!disabled && !readonly && user.displayName !== '-'"
        class="remove"
        (click)="removeItem(user)">x
      </span><br>
      <span *ngIf="userLeftDCF"
        class="error-message-user-left-dcf" style="color: red;">
        User is no longer with DCF
      </span>
  </div>
  <input *ngIf="(currentaccessPage && (disabled || readonly) && peoplePickerUserList!=null && peoplePickerUserList!=undefined &&peoplePickerUserList.length === 0)"
    class="people-picker-input people-picker-disabled"
    type="text"
    disabled    
    [placeholder]="placeholder"/>

  <input *ngIf="(allowAddUsers && !disabled && !readonly)" style="border: none;"
    class="people-picker-input"
    id="typeahead-template"
    type="text"
    [ngbTypeahead]="search"
    [resultTemplate]="rt"
    [inputFormatter]="formatter"
    [placeholder]="(peoplePickerUserList?.length === 0) ? placeholder : ''"
    [class.readonly]="true"
    (selectItem)="onSelectItem($event)">
</div>
