<div id="invoice-details-and-addresses"
  [formGroup]="invoiceDetailsFormGroup"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
    <span>Invoice Details & Addresses</span>
  </div>
  <div id="invoice-details-and-addresses-section" class="content-section">
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span
          [class.highlight-warning]="highlightRequiredField('billingAddressSameClientAddress') ||
          highlightRequiredField('billingAddress')"
          [class.edited-externally]="editedExternally('billingAddressSameClientAddress') ||
          editedExternally('billingAddress')"
          [class.conflict-on-merge]="hasMergeConflict('billingAddressSameClientAddress') ||
          hasMergeConflict('billingAddress')">
            Is Billing Address same as Client Address?
        </span>
        <span *ngIf="showAsterisk('billingAddressSameClientAddress')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('billingAddressSameClientAddress')?.value"
          [readonly]="isReadOnly('billingAddressSameClientAddress')"
          (handleToggleYesNo)="onBillingAddressSameClientAddressSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label"></div>
      <div class="col-xs-8">
        <input
          [formControl]="myDealFormGroup.get('billingAddress')"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('billingAddress')"
          type="text"
          autocomplete="off"
          [placeholder]='getControlName("billingAddressSameClientAddress")?.value === "NO" ? "Please enter &#39;Billing Address&#39;" : "N/A"'>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('memberFirmIdBillingAddress')"
          [class.edited-externally]="editedExternally('memberFirmIdBillingAddress')"
          [class.conflict-on-merge]="hasMergeConflict('memberFirmIdBillingAddress')">
            Member Firm ID for Billing Address
        </span>
        <span *ngIf="showAsterisk('memberFirmIdBillingAddress')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('MemberFirmIdBillingAddress')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="memberFirmIdBillingAddress"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('memberFirmIdBillingAddress')"
          type="text"
          autocomplete="off">
      </div>
    </div> -->
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('billingContact')"
          [class.edited-externally]="editedExternally('billingContact')"
          [class.conflict-on-merge]="hasMergeConflict('billingContact')">
            Billing Contact
        </span>
        <span *ngIf="showAsterisk('billingContact')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="billingContact"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('billingContact')"
          type="text"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('emailAddress')"
          [class.edited-externally]="editedExternally('emailAddress')"
          [class.conflict-on-merge]="hasMergeConflict('emailAddress')">
            Email Address
        </span>
        <span *ngIf="showAsterisk('emailAddress')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('EmailAddress')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="emailAddress"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('emailAddress')"
          type="text"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isSubcontractingArrangement')"
          [class.edited-externally]="editedExternally('isSubcontractingArrangement')"
          [class.conflict-on-merge]="hasMergeConflict('isSubcontractingArrangement')">
            Is this a Subcontracting Arrangement?
        </span>
        <span *ngIf="showAsterisk('isSubcontractingArrangement')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('IsSubcontractingArrangement')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('isSubcontractingArrangement')?.value"
          [disabledNo]="getControlName('isSubcontractingArrangement')?.disabled"
          [readonly]="isReadOnly('isSubcontractingArrangement')"
          (handleToggleYesNo)="onIsSubcontractingArrangementSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('printSecondBillingAddress')"
          [class.edited-externally]="editedExternally('printSecondBillingAddress')"
          [class.conflict-on-merge]="hasMergeConflict('printSecondBillingAddress')">
            Do you want to print second Billing address on invoice?
        </span>
        <span *ngIf="showAsterisk('printSecondBillingAddress')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('printSecondBillingAddress')?.value"
          [readonly]="isReadOnly('printSecondBillingAddress')"
          (handleToggleYesNo)="onPrintSecondBillingAddressSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span
          [class.highlight-warning]="highlightRequiredField('payerAddressSameClientAddress') ||
          highlightRequiredField('payerAddress')"
          [class.edited-externally]="editedExternally('payerAddressSameClientAddress') ||
          editedExternally('payerAddress')"
          [class.conflict-on-merge]="hasMergeConflict('payerAddressSameClientAddress') ||
          hasMergeConflict('payerAddress')">
          Is Payer Address same as Client Address?</span>
        <span *ngIf="showAsterisk('payerAddressSameClientAddress')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('payerAddressSameClientAddress')?.value"
          [readonly]="isReadOnly('payerAddressSameClientAddress')"
          (handleToggleYesNo)="onPayerAddressSameClientAddressSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label"></div>
      <div class="col-xs-8">
        <input
          [formControl]="myDealFormGroup.get('payerAddress')"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('payerAddress')"
          type="text"
          autocomplete="off"
          [placeholder]='getControlName("payerAddressSameClientAddress")?.value === "NO" ? "Please enter &#39;Payer Address&#39;" : "N/A"'>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('invoiceMedium')"
          [class.edited-externally]="editedExternally('invoiceMedium')"
          [class.conflict-on-merge]="hasMergeConflict('invoiceMedium')">
            Invoice Medium
        </span>
        <span *ngIf="showAsterisk('invoiceMedium')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="invoiceMediumOptions"
          [keySelected]="getControlName('invoiceMedium')?.value"
          [disabled]="getControlName('invoiceMedium')?.disabled"
          [readonly]="isReadOnly('invoiceMedium')"
          (optionSelected)="onInvoiceMediumSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('printClientReferenceNumber')"
          [class.edited-externally]="editedExternally('printClientReferenceNumber')"
          [class.conflict-on-merge]="hasMergeConflict('printClientReferenceNumber')">
            Print Client Reference Number
        </span>
        <span *ngIf="showAsterisk('printClientReferenceNumber')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('printClientReferenceNumber')?.value"
          [readonly]="isReadOnly('printClientReferenceNumber')"
          (handleToggleYesNo)="onPrintClientReferenceNumberSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('allowInvoiceConsolidation')"
          [class.edited-externally]="editedExternally('allowInvoiceConsolidation')"
          [class.conflict-on-merge]="hasMergeConflict('allowInvoiceConsolidation')">
            Allow Invoice Consolidation
        </span>
        <span *ngIf="showAsterisk('allowInvoiceConsolidation')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('allowInvoiceConsolidation')?.value"
          [disabledNo]="getControlName('allowInvoiceConsolidation')?.disabled"
          [readonly]="isReadOnly('allowInvoiceConsolidation')"
          (handleToggleYesNo)="onAllowInvoiceConsolidationSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('billingCurrency')"
          [class.edited-externally]="editedExternally('billingCurrency')"
          [class.conflict-on-merge]="hasMergeConflict('billingCurrency')">
            Billing Currency
        </span>
        <span *ngIf="showAsterisk('billingCurrency')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="billingCurrency"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('billingCurrency')"
          type="text"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('invoiceLayout')"
          [class.edited-externally]="editedExternally('invoiceLayout')"
          [class.conflict-on-merge]="hasMergeConflict('invoiceLayout')">
            Invoice Layout
        </span>
        <span *ngIf="showAsterisk('invoiceLayout')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="invoiceLayoutOptions"
          [keySelected]="getControlName('invoiceLayout').value"
          [readonly]="isReadOnly('invoiceLayout')"
          (optionSelected)="onInvoiceLayoutSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('printEngagementNumber')"
          [class.edited-externally]="editedExternally('printEngagementNumber')"
          [class.conflict-on-merge]="hasMergeConflict('printEngagementNumber')">
            Print Engagement Number
        </span>
        <span *ngIf="showAsterisk('printEngagementNumber')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('printEngagementNumber')?.value"
          [readonly]="isReadOnly('printEngagementNumber')"
          (handleToggleYesNo)="onPrintEngagementNumberSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('paymentTerms')"
          [class.edited-externally]="editedExternally('paymentTerms')"
          [class.conflict-on-merge]="hasMergeConflict('paymentTerms')">
            Payment Terms
        </span>
        <span *ngIf="showAsterisk('paymentTerms')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="paymentTermsOptions"
          [keySelected]="getControlName('paymentTerms')?.value"
          [disabled]="getControlName('paymentTerms')?.disabled"
          [readonly]="isReadOnly('paymentTerms')"
          (optionSelected)="onPaymentTermsSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('fxRate')"
          [class.edited-externally]="editedExternally('fxRate')"
          [class.conflict-on-merge]="hasMergeConflict('fxRate')">
            FX Rate
        </span>
        <span *ngIf="showAsterisk('fxRate')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="fxRateOptions"
          [keySelected]="getControlName('fxRate')?.value"
          [readonly]="isReadOnly('fxRate')"
          (optionSelected)="onFxRateSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('fxRiskSharing')"
          [class.edited-externally]="editedExternally('fxRiskSharing')"
          [class.conflict-on-merge]="hasMergeConflict('fxRiskSharing')">
            FX Risk Sharing
        </span>
        <span *ngIf="showAsterisk('fxRiskSharing')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('FXRiskSharing')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('fxRiskSharing')?.value"
          [readonly]="isReadOnly('fxRiskSharing')"
          (handleToggleYesNo)="onFxRiskSharingSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('baselineFXRate')"
          [class.edited-externally]="editedExternally('baselineFXRate')"
          [class.conflict-on-merge]="hasMergeConflict('baselineFXRate')">
            Baseline FX Rate
        </span>
        <span *ngIf="showAsterisk('baselineFXRate')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('BaselineFXRate')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="baselineFXRate"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('baselineFXRate')"
          type="text"
          autocomplete="off">
      </div>
    </div>
  </div>
</div>
