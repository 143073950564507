import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectDynamicTableItem } from 'src/shared/models/select-dynamic-table-item';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { Spcrud } from 'src/shared/services/spcrud';
import { AppSettings } from 'src/shared/app-settings';
import { MainServiceHelper } from 'src/shared/services/main-service-helper';
import { MainService } from 'src/shared/services/main.service';
import { ComplianceServiceHelper } from './compliance-service-helper';
import { AppContext } from '../models/app-context';
import { FreeTextTableItem } from 'src/shared/models/free-text-table-item';
import { BaseService } from 'src/shared/services/base.service';
import { RootSiteSpcrud } from 'src/shared/services/root-site-spcrud';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';
import { Observable, catchError, from, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ComplianceService extends BaseService{

  constructor(
    private myhttp:HttpClient,
    spcrud: Spcrud,
    rootSiteSpcud: RootSiteSpcrud,
    private mainService: MainService,
    private mainServiceHelper: MainServiceHelper,
    private ComplianceServiceHelper: ComplianceServiceHelper,
    private CCLService:ClosingCheckListService) { 
      super(spcrud, rootSiteSpcud);
    }

  retrieveMyDeal(listName:string,myDealId: number): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const optionsMyDealForm = {
      filter: 'Id eq ' + myDealId,
      select: `Series24/Id, Series24/Title,*`,
      expand: 'Series24'
    };
    return spcrud.read(listName, optionsMyDealForm)
    .then( (response:any) => {
      return response.d.results[0];
    });
    //return this.mainService.getListItem(AppSettings.myDealFormList, myDealId);
  }
  retrieveMyDeal_CCL(listName:string,ccFormId:number,lambdaUrl:any): Observable<any> {
    return from(this.CCLService.getDynamoTableList(ccFormId,listName,lambdaUrl))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error fetching  data:', err);
      throw err;
    })
    )
  }

  retrieveComplianceForm(listName:string,ccFormId:number): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const optionsComplianceForm = {
      filter: 'Form/Id eq ' + ccFormId,
      select: `CurrentSeries24/Title,CurrentSeries24/Id,*`,
      expand: 'CurrentSeries24'
    };
    return spcrud.read(listName, optionsComplianceForm)
    .then( (response:any) => {
      return response.d.results[0];
    });
  }
  retrieveTableDetails(listName:string,ccFormId:number,lambdaUrl:any): Observable<any> {
    return from(this.CCLService.getDynamoTableList(ccFormId,listName,lambdaUrl))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error fetching  data:', err);
      throw err;
    })
    )
  }

  getDealTeamUsers(listName: string, formId: number): Promise<Array<PeoplepickerDynamicTableItem>> {
    const spcrud = this.getSpcrud(listName);
    const optionsDealTeamTable = {
      filter: 'Form/Id eq ' + formId,
      select: `Id, FormId, Name/EMail, Name/Id, Name/Title, Name/Name`,
      expand: 'Name'};
    return spcrud.read(listName, optionsDealTeamTable)
    .then( (response:any) => {
      const results = response.d.results;
      const dealTeamUsers = results.map((item:any) => {
        const peoplePickerUser = this.mainServiceHelper.createPeoplePickerUser(item.Name.Name, item.Name.Title, item.Name.EMail);
        return this.mainServiceHelper.createPeoplepickerDynamicTableItem(item.Name.Id, peoplePickerUser, item);
      });
      return dealTeamUsers;
    }).catch((error:any) => {
    });
  }
  getDealTeamUser(listName: string, formId: number):Observable<any> { 
    if (formId) {
      return from(this.CCLService.getDynamoTableList(formId,listName,environment.Newdealform))
      .pipe(
        map((results) => {
        return results;
      })
      ,catchError((err: any) => {
        console.log('Error getting form data:', err);
        throw err;
      })
      ) 
    } else {
      // return null;
      return of(null);
    }
}
  getDealTeam(listName: string, formId: number): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    if (formId) {
      const optionsDealTeamTable = {
        filter: 'Form/Id eq ' + formId,
        select: `Id, FormId, Role/Role, Role/Id, PursuitRole/Role, PursuitRole/Id, SwiftRole/Role, SwiftRole/Id, Name/EMail, Name/Id, Name/Title, Name/Name`,
        expand: 'Role, PursuitRole,SwiftRole, Name'};
      return spcrud.read(listName, optionsDealTeamTable)
      .then( (response:any) => {
        const results = response.d.results;
        const dealTeamUsers = results.map((item:any) => {
          const peoplePickerUser = this.mainServiceHelper.createPeoplePickerUser(item.Name.Name, item.Name.Title, item.Name.EMail);
          return this.mainServiceHelper.createPeoplepickerDynamicTableItem(item.Name.Id, peoplePickerUser, item);
        });
        return dealTeamUsers;

      }).catch((error:any) => {
      });
    } else {
      // return null;
      return Promise.resolve(null);
    }
  }

  // getDTTLList(listName: string, formId: number) {
  //   const spcrud = this.getSpcrud(listName);
  //   const options = {
  //     filter: 'Form/Id eq ' + formId,
  //     select: 'Id, FormId, DTTLInvolved',
  //   };
  //   return spcrud.read(listName, options)
  //   .then( (response:any) => {
  //     const results = response.d.results;
  //     const dttlList = results.map((item:any) => {
  //       const dttl = this.mainServiceHelper.createFreeTextItem(item.Id, item);
  //       return this.mainServiceHelper.createDTTLFreeTextTableItem(item.Id, dttl);
  //     });
  //     return dttlList;
  //   }).catch((error:any) => {
  //   });
  // }

getDTTLList(listName: string, formId: number) { 
  return this.retrieveTableDetails(listName,formId,environment.ClosingChecklistForm).pipe(
    map((response: any) => {
      const results = response;
      const dttlList = results.map((item: any) => {
        const dttl = this.mainServiceHelper.createFreeTextItem(item.ID, item);
        return this.mainServiceHelper.createDTTLFreeTextTableItem(item.ID, dttl);
      });
      return dttlList;
    }),
    catchError((error: any) => {
      // Handle the error here
      console.error(error);
      return of([]);
    })
  );
}
getDealTeamUserByEmail_CCL(formId:any, email:any):Observable<any>{
  return from(this.CCLService.GetDealTeamUserByEmail_CCL(formId,email))
    .pipe(
      map((results: any) => {
      return results;
    }),
    catchError((err: any) => {
      console.log('Error getting form data:', err);
      throw err;
    })
  )  
}
getDealTeamUserByEmailDynamic_CCL(formId:any, email:any,list:any):Observable<any>{
  let appendURL=this.CCLService.getAppendURL(list);
  return from(this.CCLService.GetDealTeamUserByEmail_Compliance(formId,email,appendURL))
    .pipe(
      map((results: any) => {
      return results;
    }),
    catchError((err: any) => {
      console.log('Error getting form data:', err);
      throw err;
    })
  )  
}
  getCounterpartiesList(listName: string, formId: number) {
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: 'Form/Id eq ' + formId,
      select: 'Id, FormId, Name, TickerSymbol, RestrictedEntity, PubliclyTradedEntity',
    };
    return spcrud.read(listName, options)
    .then( (response:any) => {
      const results = response.d.results;
      const counterpartiesList = results.map((item:any) => {
        const counterparties = this.mainServiceHelper.createCounterpartiesItem(item.Id, item);
        return this.mainServiceHelper.createCounterpartiesFreeTextTableItem(item.Id, counterparties, item);
      });
      return counterpartiesList;
    }).catch((error:any) => {
    });
  }
  

  getDealTeamUserByEmail( formId: number, email: string, listName: string): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: `Form/Id eq ${formId} and Name/EMail eq '${email}'`,
      select: 'Id, Name/EMail',
      expand: 'Name'
    };
    return spcrud.read(listName, options)
    .then( (response:any) => {
      return response.d.results[0];
    }).catch((error:any) => {
    });
  }

  isAssignedSeries24(listName: string, formId: number, userId: number): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: 'Id eq ' + formId + ' and Series24/Id eq ' + userId
     };
    return spcrud.read(listName, options)
    .then( (response:any) => {
      if (response.d.results.length > 0) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error:any) => {
    });
  }

  getAppContext(): Promise<any> {
    return Promise.all([
      this.getAutoSaveInterval(),
      this.getAutoSaveEnabled(),
      this.getAutoSaveSpinningWheelInterval()]).then(([autoSaveInterval, autoSaveEnabled,autoSaveSpinningWheelInterval]) => {
      return this.ComplianceServiceHelper.createAppContext(autoSaveInterval, autoSaveEnabled,autoSaveSpinningWheelInterval);
      });
  }

  getAutoSaveInterval(): Promise<any> {
    return this.mainService.getAppConfigurationValue('AutoSaveIntervalInMilliseconds').then((autoSaveIntervalInMilliseconds) => {
        let value = parseFloat(autoSaveIntervalInMilliseconds);
        if (isNaN(value)) {
          value = AppSettings.autoSaveIntervalDefault;
        }
        return value;
    });
  }

  getAutoSaveEnabled(): Promise<any> {
    return this.mainService.getAppConfigurationValue('EnableAutoSave').then((enableAutoSave) => {
      return (enableAutoSave === 'true');
    });
  }
  getAutoSaveSpinningWheelInterval():Promise<any>{
    return this.mainService.getAppConfigurationValue('AutoSaveSpinningWheelInterval').then((autoSaveSpinningWheelInterval)=>{
      let value=parseFloat(autoSaveSpinningWheelInterval);
      if(isNaN(value))
      {
        value=AppSettings.autoSaveSpinningWheelIntervalDefault;
      }
      return value;
    });
  }
  getDCFGroupMembers() {
    return this.mainService.getGroupUsers(AppSettings.dcfComplianceGroup);
  }

  getSeries24GroupMembers() {
    return this.mainService.getGroupUsers(AppSettings.series24Group);
  }

  getListClosingChecklistVisitors() {
    return this.mainService.getGroupUsers(AppSettings.closingChecklistVisitorsGroup);
  }

  updateFormOnSubmit(submissionDate: Date,formId:number,newWorkflow:boolean,rangekey:any ) {
    const objectToSend = this.ComplianceServiceHelper.createSubmitObject(submissionDate, newWorkflow);
    this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, formId, objectToSend,rangekey,true).subscribe((response)=>{
      return response;
    });
  }

  updateFormOnDiscontinue(formStatus: string,formActionGroup:string,formId: any, discontinueReason: string,rangeKey:any,initiateWorkflow:boolean=false,sendCCLFormEmail:boolean=false,dealTeamUSerEmail:any="",dealTeamEmail:any="") {
    const objectToSend = this.ComplianceServiceHelper.createAdminDiscontinueObject(formStatus,discontinueReason);
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.mainService.cleanJsonObject(objectToSend),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendCCLFormEmail:sendCCLFormEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return this.mainService.updateListItem_CCForm(AppSettings.closingChecklistFormList,formId, data);
  }
 
  getListItems(listName:any,formId:any): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const options = {filter: 'Form/Id eq ' + formId};
    return spcrud.read(listName, options)
    .then( (response:any) => {
      return response.d.results;
    }).catch((error:any) => {
    });
  }
  getListItems_CCL(listName: string, formId: number): Observable<any> {
    return from(this.CCLService.getDynamoTableList(formId,listName,environment.ClosingChecklistForm))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error fetching  data:', err);
      throw err;
    })
    )
  }
  getDocumentComments(listName: string, formId: number): Observable<any> {
    return from(this.CCLService.GetDocumentComments_CCL(listName,formId))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error fetching  data:', err);
      throw err;
    })
    )
  }

  getBreakPointValues(listName:string,formId:number):Promise<any>
  {
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: "Form/Id eq " + formId,
      select: "FormId,Id,BreakpointValue"
    };
    return spcrud.read(listName, options)
    .then( (response:any) => {
      return response.d.results;
    }).catch((error:any) => {
    });
  }
  getBreakPointValuesCCL(listName: string, formId: number): Observable<any> {
    return from(this.CCLService.GetBreakPointValuesCCL(listName,formId))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error fetching  data:', err);
      throw err;
    })
    )
  }

  deleteListItem(listName: string, itemId: number): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    let requestObject = JSON.stringify({ID:itemId});
    return from(this.CCLService.DeleteListItem(appendURL,requestObject))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error deleting list data:', err);
      throw err;
    })
  )  
  }
  createListItem(listName: string, sharepointObject: any): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    return spcrud.create(listName, sharepointObject)
    .then( (response:any) => {
        return response.d.Id;
    }).catch((error:any) => {
      //return reject(error);
    });
  }

  createForm(listName: string, sharepointObject: any): Promise<any> {
    return this.createListItem(listName, sharepointObject)
    .then( response => {
    
      return  response;
    }).catch(error => {
    //  return reject(error);
    });
  }

  updateListItem(listName: string, itemId: number, sharepointObject: any, refreshOnError?: boolean): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    return spcrud.update(listName, itemId, sharepointObject, refreshOnError)
    .then((response:any) => {
          return true;
    })
    .catch((error:any) => {
     // return reject(error);
    });
  }

  updateListItem_Breakpoint(itemId:number,listName: string, formId: number, objectToSend: any,rangeKey:any,initiateWorkflow:boolean=false,sendCCLEmail:boolean=false,dealTeamUSerEmail:any=""): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({ID:itemId,formId:formId.toString(),updateData:this.mainService.cleanJsonObject(objectToSend),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendCCLFormEmail:sendCCLEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 

    return from(this.CCLService.BreakpointUpdate(appendURL,data))
    .pipe( map((results: any) => {
      return results;
    })
    ,
    catchError((err: any) => {
      console.log('Error Updating form data:', err);
      throw err;
    })
  );
  }
  
  createListItem_CCF(listName: string, sharepointObject: any): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    return from(this.CCLService.SaveFormDetails(appendURL,sharepointObject))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error saving form data:', err);
      throw err;
    })
    ) 
  }
  UpdateFormDetails_CCForm(appendedURL:any,data:any, listName:any):Observable<any>{
    var envURL;
    if(listName=="MyDeal" || listName=="MyDealDealTeam"){
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    else if(listName=="ClosingChecklistForm"){
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    }
    const url = envURL+'/'+appendedURL+'/Update'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
  }

  GetGroupData(groupName:any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)        
     const url = envURL+'/api/UserGroups/GetUserGroupsByRole?role='+groupName; 
      return this.myhttp.get(url, {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("idToken")
          })
      })
  }
  getAsteriskList() : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL +'/api/ComplianceValidationFields/GetList';
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
 
}
