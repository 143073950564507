import { NgModule } from '@angular/core';
import { CommonModule, DatePipe} from '@angular/common';
import { SharedModule } from 'src/shared/SharedModule';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EngagementHealthObjectComponent } from './engagement-health-object-setup/engagement-health-object.component';
import { HeaderComponent } from './header/header.component';
import { ThirdPartyCodesComponent } from './third-party-codes/third-party-codes.component';
import { DealTeamDetailsComponent } from './deal-team-details/deal-team-details.component';
import { EngagementHealthDetailsComponent } from './engagement-health-details/engagement-health-details.component';
import { DateFormatterService } from '../../shared/utils/utils-date';
import { SidebarEngagementContentBreakdownComponent } from './sidebar-engagement-content-breakdown/sidebar-engagement-content-breakdown.component';
import { MainService } from '../../shared/services/main.service';
import { EngagementHealthObjectService } from './shared/services/engagement-health-object.service';
import { DropdownService } from '../../shared/services/dropwdown.service';
import { FilesService } from '../../shared/services/files.service';
import { PeoplepickerService } from '../../shared/services/peoplepicker.service';
import { SecurityService } from '../../shared/services/security.service';
import { DealTeamAuditlogComponent } from './deal-team-auditlog/deal-team-auditlog.component';
import { DealTeamTableComponent } from './deal-team-table/deal-team-table.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
  EngagementHealthObjectComponent,
  HeaderComponent,
  ThirdPartyCodesComponent,
  DealTeamDetailsComponent,
  EngagementHealthDetailsComponent,
  SidebarEngagementContentBreakdownComponent,
  DealTeamTableComponent,
  DealTeamAuditlogComponent],
  exports: [
    EngagementHealthObjectComponent,
   // HeaderComponent,Commented due to application confusing with the header component main and this
    ThirdPartyCodesComponent,
    DealTeamDetailsComponent,
    EngagementHealthDetailsComponent,
    SidebarEngagementContentBreakdownComponent,
    //DealTeamTableComponent,Commented due to app confusing with the header component main and this 
    DealTeamAuditlogComponent],
  providers: [
    DatePipe,
    DateFormatterService,
    MainService,
    EngagementHealthObjectService,
    DropdownService,
    FilesService,
    PeoplepickerService,
    SecurityService
  ]
})

export class EngagementHealthObjectModule { }
