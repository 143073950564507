export class SharepointObjectMyDeal {
  ClientName: string;
  ClientID: string;
  EngagementName: string;
  EngagementType: string;
  IsRecurringWBS: string;
  DeloitteUSEntityId: string;
  IntegratedMarketOffering: string;
  //SalesOfficeId: string;
  SalesOffice: string;
  EngagementIndustrySICCode: string;
  IsInternationalEngagement: string;
  AreResourcesWorkOutside: string;
  MemberFirmTaxablePresence: string;
  OtherMemberFirmsParticipating: string;
  WhereWorkMemberFirms: string;
  BillingAddressSameClientAddress: string;
  BillingAddress: string;
  PayerAddressSameClientAddress: string;
  PayerAddress: string;
  IsServiceSameAsClientAddress: string;
  ServiceAtAddress: string;
  MarketOffering: string;
  Material: string;
  Series24Id: string;
  TransactionType: string;
  StartDate: string;
  EndDate: string;
  StreetAddress: string;
  City: string;
  State: string;
  PostalCode: string;
  Telephone: string;
  Industry: string;
  ClientType: string;
  ReferralSource: string; // formName : source referal
  ReferralSourceName: string; // formName :  referralSourceName & SourceName is different ReferralSourceName
  Subsector: string;
  IsCompetitivePitch: string;
  EBITDA: string;
  FeeType: string;
  //BuyerName: string;
  PreviousAuditor: string;
  CurrentAuditor: string;
  TickerCode: string;
  IsPubliclyTradedEquity: string;
  OpportunityID: string; //Mercury Opportunity Code from Admin Review Section //This is diffent from OriginalOpportunityID
}
