import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showAdminSection: boolean;
  showReactivateSection: boolean;
  showActiveWBSSection :boolean;
  isFixed:any;
  stoppingPoint:number=100;
  @Input() isAutoSaveEnabled: boolean=false;
  @Input() isAdminReviewEditable: boolean=false;
  @Input() saveInProgress: boolean=false;
  @Input() saveButtonVisible: boolean=false;
  @Output()  SaveDealWbsFormDetails:EventEmitter<any>=new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const offset = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (offset >= this.stoppingPoint) {
      this.isFixed = "margin-top: -100px; position:fixed; max-width: fit-content;"
    } else {
      this.isFixed = "position: relative; max-width: fit-content; padding: 0; min-height: 26px;"
    }
  }
  onclick(target:any) {
    const selector = document.querySelector(target);
    if (selector) {
      selector.scrollIntoView();
    }
  }

  showAdminReview() {
    this.showAdminSection = true;
  }

  showReactivateFormSection() {
    this.showReactivateSection = true;
  }

  showActiveDealWBSSection(){
    this.showActiveWBSSection = true;
  }
//Added below to perform save without clicking top to bottom
onSaveForm(){
  this.SaveDealWbsFormDetails.emit(true);
 }
}
