import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { DropdownItem } from "../../../shared/models/dropdown-item";
import { DropdownService } from "../../../shared/services/dropwdown.service";
import { SecurityService } from "../../../shared/services/security.service";
import { TooltipHelperService } from "../../../shared/services/tooltip-helper.service";
import { AppSettings } from "../../../shared/app-settings";
import { FormListFields } from "../shared/models/form-list-fields";
import { FormValidations } from "../../../shared/utils/form-validations";
import { TooltipObject } from "../../../shared/models/tooltip-object";

@Component({
    selector: 'app-deal-overview',
    templateUrl: './deal-overview.component.html',
    styleUrls: ['./deal-overview.component.scss']
  })
export class DealOverviewComponent implements OnInit {

    @Input() formSubmitted: boolean;
    @Input() formGroups: Array<FormGroup>;
  @Output() dealTypeValue = new EventEmitter<string>();
  dealTypeOptions: Array<DropdownItem> = AppSettings.dealTypeOptions;
  numberOfParticipantsOptions: Array<DropdownItem> = AppSettings.numberOfParticipantsOptions;
    dealOverviewFormGroup : FormGroup;
    myDealFormGroup:FormGroup;
    sharePointMapper = new ComplianceSharepointMapper();
 
    
    constructor(private dropdownService: DropdownService,private tooltipHelperService: TooltipHelperService,
      private securityService: SecurityService){}
      
    ngOnInit(): void {
      this.dealOverviewFormGroup = this.formGroups[0];
      this.myDealFormGroup = this.formGroups[1];

      // this.dropdownService.fillDropdownOptions_ClosingCheckList(AppSettings.closingChecklistForm, FormListFields.dealType)
      // .subscribe(response =>{
      //   this.dealTypeOptions = response;
      // })

      // this.dropdownService.fillDropdownOptions_ClosingCheckList(AppSettings.closingChecklistForm, FormListFields.numberOfParticipants)
      // .subscribe(response =>{
      //   this.numberOfParticipantsOptions = response;
      // })
    }
    private _setAsDisabled(formControlName: string) {
      const controlName = this.dealOverviewFormGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }

  evaluateIsCompetitivePitch(value: any){
      if(value === 'YES'){
        this.dealOverviewFormGroup.controls.numberOfParticipants.enable();
      }
      else{
         this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.numberOfParticipants));
      }

    }
  onNumberOfParticipantsSelection(event: any){
      this.dealOverviewFormGroup.controls.numberOfParticipants.setValue(event.key);
    }
    
  onDealTypeSelection(event: any){
      this.dealOverviewFormGroup.controls.dealType.setValue(event.key);
      //this.financeComponent.evaluateDealType(event);
      this.dealTypeValue.emit(event.key);
    }

  onIsClientPublicEntity(event: any){
      this.dealOverviewFormGroup.controls.isClientPublicEntity.setValue(event);
    }

  onIsCompetitivePitch(event: any){
      this.myDealFormGroup.controls.isCompetitivePitch.setValue(event);
      this.evaluateIsCompetitivePitch(event);
    }
  onIsFirstProcessRun(event: any){
      this.dealOverviewFormGroup.controls.firstProcessRun.setValue(event);
    }
  onIsFirstInstitutionalCapital(event: any){
      this.dealOverviewFormGroup.controls.firstInstitutionalCapital.setValue(event);
    }
  onCrossBorderEngagement(event: any){
      this.dealOverviewFormGroup.controls.crossBorderEngagement.setValue(event);
    }

    setSharepointMapper(mapper: ComplianceSharepointMapper) {
      this.sharePointMapper = mapper;
    }
  
    isReadOnly(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.isReadOnly(formGroup, formControlName);
      }
      return false;
    }
  
    showAsterisk(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.showAsterisk(formGroup, formControlName);
      }
      return false;
    }
  
    highlightRequiredField(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
      }
      return false;
    }
  
    editedExternally(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.editedExternally(formGroup, formControlName);
      }
      return false;
    }
  
    hasMergeConflict(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.hasMergeConflict(formGroup, formControlName);
      }
      return false;
    }
  
    hasPermission(action: string): boolean {
      return !!this.securityService.hasPermission(action);
    }
  
    private _getFormGroup(formControlName: string): FormGroup {
      return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
    }
    getTooltip(tooltipTitle: string): TooltipObject {
      return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
    }
  
    getTooltipText(tooltipTitle:string):string{
      const tooltip = this.getTooltip(tooltipTitle);
      return tooltip ? tooltip.getTooltipText() : '';
    }
}
