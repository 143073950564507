import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
export class NAFormatterService{
constructor(){}
formatNA(value:any):string{
return value=='N/A'?'0':value;
}
avgHandlingNA(values:any[]):number{
  var total = 0;
  var count = 0;       
  for(var i = 0; i < values.length; i++){
      total = total + Number(this.formatNA(values[i])); 
      count = count + this.valueOrDefault(values[i],1,0);            
  }         
  return (count == 0) ? 0 : total/count; 
}
valueOrDefault(elementValue:any,value:any, defaultValue:any):number{
  return elementValue=='N/A'? defaultValue :value;
}
}