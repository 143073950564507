import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { environment } from 'src/environments/environment';
import { AppSettings } from 'src/shared/app-settings';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';

@Injectable({
  providedIn: 'root'
})
export class DealwbsService {

  constructor(private myhttp:HttpClient) { }
  getAppendURL(dropdownName: string) {
    let url: any = "";
    switch (dropdownName) {
      case "MyDealRoles":
        return url = "api/MyDealRoles";
        break;
      case "MyDealPursuitRoles":
        return url = "api/MyDealPursuitRoles";
        break;
      case "MyDealSwiftRoles":
        return url = "api/MyDealSwiftRoles";
        break;
      case "MyDealDeloitteUSEntity":
        return url = "api/MyDealDeloitteUSEntity";
        break;
      case "MyDealSalesOffice":
        return url = "api/MyDealSalesOffice";
        break;
      case "DocumentManager":
        return url = "api/DocumentManager";
        break;
      case "MyDeal":
        return url = "api/MyDeal";
        break;
        case "DealWbsSetupForm":
        return url = "api/DealWbsSetupForm";
        break;
      case "MyDealDealTeam":
        return url = "api/MyDealDealTeam";
        break;
      case "DealTeamAuditLog":
        return url = "api/DealTeamAuditLog";
        break;
      case "EngagementHealthObject":
        return url = "api/EngagementHealthObject";
        break;
      case "CrossBorderForm":
        return url = "api/CrossBorderForm";
        break;
      case "DealTeamAuditLog":
        return url = "api/DealTeamAuditLog";
        break;
      case "ClosingCheckListForm":
          return url = "api/ClosingCheckListForm";
          break;
      default:
        return "";
        break;
    }
  }
  
  GetDropDownData(appendedURL: any,listName:any): Observable<any> {
    var envURL="";
    if(listName=="MyDeal"){
       envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    else{
       envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
    }
    const url = envURL + '/' + appendedURL + '/GetList';   
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }

  getAsteriskList() : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
    const url = envURL +'/api/DealWbsValidationFields/GetList';
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  

  getTooltipsList() : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
    const url = envURL +'/api/DealWbsSetupTooltips/GetList';
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }

  GetUserRoleGroups(userId:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
    const url = envURL +'/api/UserGroups/GetUserGroupsByEmail?email='+userId;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }

  GetDealTeamUserByEmail(formId:any,name:any):Promise<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL +'/api/MyDealDealTeam/GetList?formId='+formId+'&Name='+name;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})}).toPromise();
    }
  GetDealTeamUserByEmail_DealWbs(formId:any,name:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL +'/api/MyDealDealTeam/GetList?formId='+formId+'&Email='+name;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})});
    }

  getMyDealTooltipsList() : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.EngagementDashboard)
    const url = envURL +'/api/MyDealTooltips/GetList';
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }

  DeleteListItem(appendedURL:any,data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL+ '/' + appendedURL + '/Delete';          
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})
  })
  }

  SaveFormDetails(appendedURL:any,data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL+'/'+appendedURL+'/Add'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
  }

  GetDealADGroupDetails(): Observable<any> {
    const url = AppSettings.GetDealADGroupDetails();
    return this.myhttp.get(url, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem("accessToken")
        })
    })
}

UpdateDealTeamDetails(appendedURL:any,itemID:any,data:any):Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL+'/'+appendedURL+'/Update'; 
  return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
  ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })}).toPromise();
}

UpdateFormDetails_DealWbs(appendedURL:any,data:any, listName:any):Observable<any>{
  var envURL;
  if(listName=="MyDeal" || listName=="MyDealDealTeam"){
    envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  }
  else if(listName=="DealWbsSetupForm"){
    envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
  }
  const url = envURL+'/'+appendedURL+'/Update'; 
  return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
  ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
}

GetAppConfigurationDetails():Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
  const url = envURL +'/api/DealWbsAppConfiguration/GetList';
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})}).toPromise();
}

  GetDealWbsDetails(formID:any="",listName:any=""):Observable<any>{
    var url="";
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
    if(formID==""){
      url =  envURL+'/api/DealWbsSetupForm/GetList'; 
    }
    else{
      url =  envURL+'/api/DealWbsSetupForm/GetList?formID='+formID; 
    }
    
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  GetDealWbsListDetails(formID:any,listName:any,appendedURL:any):Observable<any>{
    if(listName=="DealWbsSetupForm"){
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
    }
    else{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    const url = envURL+'/'+appendedURL+'/GetList?formID='+formID; 
    
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }

  getDealTeamTableList(formID:any) : Observable<any> {    

    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL +'/api/MyDealDealTeam/GetList?formId='+formID;;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})

  }
  
  GetMyDealDetails(formID:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL+'/api/MyDeal/GetList?formID='+formID; 
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    }

    GetGroupData(groupName:any): Observable<any> {
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)        
       const url = envURL+'/api/UserGroups/GetUserGroupsByRole?role='+groupName; 
        return this.myhttp.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("idToken")
            })
        })
    }

    getDealTeamAuditLogData(formName: any, formId: any): Observable<any> {
      const appendedURL = this.getAppendURL(formName);
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType, environment.Newdealform)
      const url = envURL + '/' + appendedURL + '/GetList?formId=' + formId;
      return this.myhttp.get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("idToken")
        })
      });
    }
    UpdateDealWBSFormDetails(appendedURL:any,list:any,data:any):Observable<any>{
      if(list=="DealWbsSetupForm"){
        var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
      }
      else{
        var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      }
      const url = envURL+'/'+appendedURL+'/Update'; 
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
      ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
    }
    SaveEHODetailsToForm(appendedURL: any, data: any,list:any): Observable<any> {
      if(list=="DealWbsSetupForm"){
        var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
      }
      else{
        var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
      }        
        const url = envURL + '/' + appendedURL + '/Add';
        return this.myhttp.post(url, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
                , 'Authorization': "Bearer " + localStorage.getItem("idToken")
            })
        });
    }
    UpdateForm(formName:any,data:any):Observable<any>{
      if(formName=="DealWbsSetupForm"){
        var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
      }
      else{
        var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      }
      const appendedURL = this.getAppendURL(formName);      
      const url = envURL + '/' + appendedURL + '/Update';												
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    });
    }
}
