<div [formGroup]="financeFormGroup" id="finance" class="container-fluid border container-border-color container-section"
[class.hide-section]="!isFinanceForm">
    <!-- *ngIf="isFinanceForm"  ng-show="isFinanceForm"-->
    <div class="row bg-title" >
        <span>Finance</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>Please complete the Finance section prior to all other sections due to requirements for final revenue
                recognition. Please respond to the Finance team closing email once this section is complete. </p>
        </span>
    </div>
    <div id="finance-section" class="content-section">
        <input type="hidden" id="hdnfinance" name="hdnfinance" value="0">
        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('financeFileUpload')"
                    [class.edited-externally]="editedExternally('financeFileUpload')"
                     [class.conflict-on-merge]="hasMergeConflict('financeFileUpload')">Please send the final fee calculation in Excel</span>
                     <span *ngIf="showAsterisk('financeFileUpload')" class="red-asterik"></span>
                    <!-- <span class="red-asterik visibility-hidden document-span" name="document-section"></span> -->
                </div>

                <div class="attachments-rows attachment-section col-xs-8">
                    <div *ngFor="let documentType of documentTypesToShow; let i = index" class="col-xs-12 display-inline-flex">
                        <div class="row align-width">
                            <div class="col-xs-3 align-center align-btn-width">
                                <app-file-uploader
                                    [invalidFileNameCharactersList]="invalidFileNameCharactersList"
                                    [blockedFileTypes]="blockedFileTypes"
                                    [buttonTitle]="documentType.documentTypeTitle"
                                    [filesAssociated]="documentType.documentTypeAttachments.length > 0"
                                    [disabled]="disabled"
                                    [readonly]="readOnly"
                                    [showNewDealpopup]="showNewDealpopup"
                                    [showDealWBSpopup]="showDealWBSpopup"
                                    [showCCLListpopup]="showCCLListpopup"
                                    (fileSelectedEvent)="onFileSelected($event, documentType.documentTypeItemId, documentType.documentTypeId,documentType.documentTypeTitle)">
                                </app-file-uploader>
                            </div>
                            <div class="col-xs-9 align-upload" style="margin-left: -10%;">
                                <div *ngFor="let attachment of documentType.documentTypeAttachments; let attachmentIndex = index" class="col-xs-12">
                                    <div class="col-xs-11">
                                        <div class="display-inline-flex file-row" style="display: flex; align-items: center;">
                                            <a (click)="downloadAttachment(attachment.attachmentName)" target="_blank" download="{{attachment.attachmentName}}" class="word-break-all" style="cursor: pointer; flex-grow: 1;">
                                                <span class="search-name break-filename">{{attachment.attachmentName}}</span>
                                            </a>
                                            <i *ngIf="attachment.attachmentUploading" class="fa fa-spinner uploading fa-spin font-size-24" style="margin-left: 10px;"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-1">
                                        <!-- *ngIf="removeDocuments && !attachment.attachmentUploading" -->
                                        <span  class="delete-uploaded-file-style glyphicon glyphicon-remove float-right"
                                            (click)="deleteAttachment(attachment.attachmentName, documentType.documentTypeId, attachmentIndex)">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- <div class="attachments-rows col-xs-8">
                    <div class="row no-padding-left-right">
                        <div class="col-xs-12"> -->
<!-- 
                            <div class="col-xs-4 align-center">
                                <button type="button"
                                    class="btn btn-sm btn-primary tooltip-dcf  open-selector-file document-type-button width-50 height-20p FinanceFileUpload btn-margin"
                                    document-internal-id="1" business-review-applies="true" qrm-review-applies="true"
                                    compliance-review-applies="true" additional-document-type="false">
                                    Final Fee Calculation Letter</button>
                                <input type="file" class="hidden" document-required="true" id="document-type-1"
                                    document-type="1">
                                <input type="text" class="name-file hidden" disabled="true">
                            </div>
                            <div class="col-xs-8 doc-name-container">
                                <div data-redline="false" id="attachment-section-document-type-1">
                                    <ul style="list-style-type: none;"
                                        class="file-list ul-no-style document-name-padding"></ul>
                                </div>
                                <div class="redline-section redline-attachment-section-1">
                                    <ul class="file-list ul-no-style document-name-padding"></ul>
                                </div>
                            </div> -->
                            <!-- <app-file-uploader
                            [invalidFileNameCharactersList]="invalidFileNameCharactersList"
                            [blockedFileTypes]="blockedFileTypes"
                            [buttonTitle]="documentType.documentTypeTitle"
                            [filesAssociated]="documentType.documentTypeAttachments.length > 0"
                            [disabled]="disabled"
                            (fileSelectedEvent)="onFileSelected($event, documentType.documentTypeItemId, documentType.documentTypeId)">
                            </app-file-uploader> -->

                            <!-- <div *ngFor="let attachment of documentType.documentTypeAttachments; let attachmentIndex = index" class="col-xs-12">
                                <div class="col-xs-11">
                                    <div class="display-inline-flex file-row">
                                        <a href="{{attachment.attachmentUrl}}" target="_blank" download="{{attachment.attachmentName}}" class="word-break-all">
                                            <span class="search-name break-filename">{{attachment.attachmentName}}</span>
                                        </a>
                                        <i *ngIf="attachment.attachmentUploading" class="fa fa-spinner uploading fa-spin font-size-24"></i>
                                    </div>
                                </div>
                                <div class="col-xs-1">
                                    <span *ngIf="removeDocuments && !attachment.attachmentUploading" class="delete-uploaded-file-style glyphicon glyphicon-remove float-right"
                                        (click)="deleteAttachment(attachment.attachmentName, documentType.documentTypeId, attachmentIndex)">
                                    </span>
                                </div>
                            </div> -->

                        <!-- </div>
                        <div class="hidden" id="comment-section-1"></div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('financeEnterpriseValue')"
                    [class.edited-externally]="editedExternally('financeEnterpriseValue')"
                    [class.conflict-on-merge]="hasMergeConflict('financeEnterpriseValue')">What was enterprise value?</span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeEnterpriseValue')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8 enterprise">
                    <!-- <input type="text" id="FinanceEnterpriseValue" name="FinanceEnterpriseValue"
                        class="input-data-number input-number non-decimal-input">
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'currency'"
                    [readonly]="isReadOnly('financeEnterpriseValue')"
                    [value]="financeFormGroup.get('financeEnterpriseValue').value"
                    (selectedValue)="onSelectedValue($event)">
                    </app-numeric>
                </div>
            </div>
        </div>

        <div class="row adjustPad-row" >
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label sec-lable">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('financeDeloitteEntity')"
                    [class.edited-externally]="editedExternally('financeDeloitteEntity')"
                    [class.conflict-on-merge]="hasMergeConflict('financeDeloitteEntity')">Do we owe money to another Deloitte entity (MATS, Tax, member firm,
                        etc.)? </span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeDeloitteEntity')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8 custom-button">
                    <!-- <div name="FinanceDeloitteEntity" id="FinanceDeloitteEntity"
                        class="btn-group btn-group-xs input-data-toggle-group input-toggle-group div-two-toggle-input"
                        role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="NO">NO</button>
                    </div> -->
                    <app-toggle-yes-no #FinanceDeloitteEntity
                        [value]="financeFormGroup.get('financeDeloitteEntity').value"
                        [readonly]="isReadOnly('financeDeloitteEntity')"
                        (handleToggleYesNo)="isFinanceDeloitteEntity($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight dependent-field"
                    [class.highlight-warning]="highlightRequiredField('financeDeloitteEntityDescription')"
                    [class.edited-externally]="editedExternally('financeDeloitteEntityDescription')"
                    [class.conflict-on-merge]="hasMergeConflict('financeDeloitteEntityDescription')">If Yes ,what?</span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeDeloitteEntityDescription')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <input class="input-data-text full-width section-input dependent-field-input"
                    formControlName="financeDeloitteEntityDescription" 
                    id="financeDeloitteEntityDescription" 
                    type="text"
                    [placeholder]="financeFormGroup.get('financeDeloitteEntityDescription').disabled ? 'N/A' : ''"
                    [disabled]="financeFormGroup.get('financeDeloitteEntityDescription').disabled">
                </div>
            </div>
        </div>
        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label sec-lable">
                    <span class="span-to-highlight" style="margin-left:3px"
                    [class.highlight-warning]="highlightRequiredField('financeFutureExpenses')"
                    [class.edited-externally]="editedExternally('financeFutureExpenses')"
                    [class.conflict-on-merge]="hasMergeConflict('financeFutureExpenses')">Do you need to holdback revenue to cover future expenses (closing
                        dinner, tombstones, etc.)? </span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeFutureExpenses')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8 custom-button">
                    <!-- <div name="FinanceFutureExpenses" id="FinanceFutureExpenses"
                        class="btn-group btn-group-xs input-data-toggle-group input-toggle-group div-two-toggle-input"
                        role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="NO">NO</button>
                    </div> -->
                    <app-toggle-yes-no #FinanceFutureExpenses
                        [value]="financeFormGroup.get('financeFutureExpenses').value"
                        [readonly]="isReadOnly('financeFutureExpenses')"
                        (handleToggleYesNo)="IsFinanceFutureExpenses($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row adjustPad-row">
            <div class="col-xs-12 ">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight dependent-field"
                    [class.highlight-warning]="highlightRequiredField('financeFutureExpensesAmount')"
                    [class.edited-externally]="editedExternally('financeFutureExpensesAmount')"
                    [class.conflict-on-merge]="hasMergeConflict('financeFutureExpensesAmount')">Amount</span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeFutureExpensesAmount')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'currency'"
                    [placeholder]="financeFormGroup.get('financeFutureExpensesAmount').disabled ? 'N/A':''"
                    [disabled]="financeFormGroup.get('financeFutureExpensesAmount').disabled"
                    [value]="financeFormGroup.get('financeFutureExpensesAmount').value"
                    (selectedValue)="onSelectedAmountValue($event)"
                    [placeholder]="financeFormGroup.get('financeFutureExpensesAmount').disabled ? 'N/A' : ''"
                    >
                    </app-numeric>
                </div>
            </div>
        </div>

        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label sec-lable">
                    <span class="span-to-highlight" style="margin-left:3px"
                    [class.highlight-warning]="highlightRequiredField('financeFuturePayments')"
                    [class.edited-externally]="editedExternally('financeFuturePayments')"
                    [class.conflict-on-merge]="hasMergeConflict('financeFuturePayments')">Will there be any future payments (escrows, earn outs, etc.)?
                    </span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeFuturePayments')" class="red-asterik"></span>

                    <app-popover
                    [tooltipText]="getTooltipText('financeFuturePayments')">
                  </app-popover>
                </div>
                <div class="col-xs-8 custom-button">
                    <!-- <div name="FinanceFuturePayments" id="FinanceFuturePayments"
                        class="btn-group btn-group-xs input-data-toggle-group input-toggle-group div-two-toggle-input"
                        role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button " value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="NO">NO</button>
                    </div> -->
                    <app-toggle-yes-no #FinanceFuturePayments
                        [value]="financeFormGroup.get('financeFuturePayments').value"
                        [readonly]="isReadOnly('financeFuturePayments')"
                        (handleToggleYesNo)="isFinanceFuturePayments($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight dependent-field"
                    [class.highlight-warning]="highlightRequiredField('financeFuturePaymentsType')"
                    [class.edited-externally]="editedExternally('financeFuturePaymentsType')"
                    [class.conflict-on-merge]="hasMergeConflict('financeFuturePaymentsType')">If yes?</span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeFuturePaymentsType')" class="red-asterik"></span>

                </div>
                <div class="col-xs-8">
                    <app-dropdown
                    [optionsList]="financeFuturePaymentsTypeOptions"
                    [keySelected]="financeFormGroup.get('financeFuturePaymentsType').value"
                    [readonly]="isReadOnly('financeFuturePaymentsType')"
                    [disabled]="financeFormGroup.get('financeFuturePaymentsType').disabled"
                    (optionSelected)="onFuturePaymentTypeSelection($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>


        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('financeAssetSaleDeal')"
                    [class.edited-externally]="editedExternally('financeAssetSaleDeal')"
                    [class.conflict-on-merge]="hasMergeConflict('financeAssetSaleDeal')">Was this an asset sale deal? </span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeAssetSaleDeal')" class="red-asterik"></span>

                    <app-popover
                    [tooltipText]="getTooltipText('financeAssetSaleDeal')">
                  </app-popover>
                </div>
                <div class="col-xs-8 custom-button">
                    <!-- <div name="FinanceAssetSaleDeal" id="FinanceAssetSaleDeal"
                        class="btn-group btn-group-xs input-data-toggle-group input-toggle-group div-two-toggle-input"
                        role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="NO">NO</button>
                    </div> -->
                    <app-toggle-yes-no #FinanceAssetSaleDeal
                        [value]="financeFormGroup.get('financeAssetSaleDeal').value"
                        [readonly]="isReadOnly('financeAssetSaleDeal')"
                        (handleToggleYesNo)="isFinanceAssetSaleDeal($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>


        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('financeCrossBorderEngagement')"
                    [class.edited-externally]="editedExternally('financeCrossBorderEngagement')"
                    [class.conflict-on-merge]="hasMergeConflict('financeCrossBorderEngagement')">Cross-Border Engagement?</span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeCrossBorderEngagement')" class="red-asterik"></span>

                    <app-popover
                    [tooltipText]="getTooltipText('financeCrossBorderEngagement')">
                  </app-popover>
                    
                </div>
                <div class="col-xs-8">
                    <!-- <div name="FinanceCrossBorderEngagement" id="FinanceCrossBorderEngagement"
                        class="btn-group btn-group-xs input-data-toggle-group input-toggle-group btn-group-two-options"
                        role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="NO">NO</button>
                    </div> -->
                    <app-toggle-yes-no #FinanceCrossBorderEngagement
                        [value]="financeFormGroup.get('financeCrossBorderEngagement').value"
                        [readonly]="isReadOnly('financeCrossBorderEngagement')"
                        (handleToggleYesNo)="isCrossBorderEngagement($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>


        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('financeCrossBorderCountry')"
                    [class.edited-externally]="editedExternally('financeCrossBorderCountry')"
                    [class.conflict-on-merge]="hasMergeConflict('financeCrossBorderCountry')">What is the cross-border element (i.e., what country is the buyer based in)?</span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeCrossBorderCountry')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input class="input-data-text full-width section-input" id="FinanceCrossBorderCountry" name="FinanceCrossBorderCountry" type="text" placeholder="N/A"> -->
                    <!-- <select name="FinanceCrossBorderCountryId" id="FinanceCrossBorderCountry"
                        class="chosen input-data-select-lookup section-select lookup">
                        <option value="0" disabled selected>Select your option</option>
                    </select> -->
                    <app-dropdown
                    [optionsList]="countryOptions"
                    [keySelected]="financeFormGroup.get('financeCrossBorderCountry').value"
                    [readonly]="isReadOnly('financeCrossBorderCountry')"
                    [disabled]="financeFormGroup.get('financeCrossBorderCountry').disabled"
                    (optionSelected)="onCrossBorderCountrySelection($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="row adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('financeProviderName')"
                    [class.edited-externally]="editedExternally('financeProviderName')"
                    [class.conflict-on-merge]="hasMergeConflict('financeProviderName')">Please provide Acquiror/Target/Capital Provider Name</span>
                    <!-- <span class="red-asterik visibility-hidden"></span> -->
                    <span *ngIf="showAsterisk('financeProviderName')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <input class="input-data-text full-width section-input"
                    formControlName="financeProviderName" 
                    id="financeProviderName" 
                    type="text"
                    [disabled]="financeFormGroup.get('financeProviderName').disabled"
                    >
                </div>
            </div>
        </div>
        <div class="row adjustPad-row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                [class.highlight-warning]="highlightRequiredField('financeProviderNameType')"
                    [class.edited-externally]="editedExternally('financeProviderNameType')"
                    [class.conflict-on-merge]="hasMergeConflict('financeProviderNameType')">Please provide Acquiror/Target/Capital Provider Type </span>
                <!-- <span class="red-asterik visibility-hidden"></span> -->
                <span *ngIf="showAsterisk('financeProviderNameType')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <!-- <select name="FinanceProviderNameType" id="FinanceProviderNameType"
                    class="input-data-select input-select section-select enableDependencyInput required"
                    disable-target-input="StockConsideration"
                    disable-target-value-for-stockconsideration="Private Equity Group,Bank Lender,Non-Bank Lender"
                    value-when-input-disabled-for-stockconsideration="">
                    <option value="0" disabled selected>Select your option</option>
                </select> -->
                <app-dropdown
                    [optionsList]="financeProviderNameTypeOptions"
                    [keySelected]="financeFormGroup.get('financeProviderNameType')?.value"
                    [readonly]="isReadOnly('financeProviderNameType')"
                    [disabled]="financeFormGroup.get('financeProviderNameType').disabled"
                    (optionSelected)="onFinanceProviderNameTypeSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row adjustPad-row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                >Is this a confidential deal or do we have the client's permission to
                    publish the following information:</span>
                    <!-- <span *ngIf="showAsterisk('pitchedDate')" class="red-asterik"></span> -->
                <!-- <span class="red-asterik visibility-hidden"></span> -->
            </div>
            <div class="col-xs-8" style="margin-top: 5px">
            </div>
        </div>

        <div class="row required-div adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-3 required-div">
                    <span class="span-to-highlight spandependentfield "
                    [class.highlight-warning]="highlightRequiredField('financeDealLeagueTable')"
                    [class.edited-externally]="editedExternally('financeDealLeagueTable')"
                    [class.conflict-on-merge]="hasMergeConflict('financeDealLeagueTable')">League Tables</span>
                    <span *ngIf="showAsterisk('financeDealLeagueTable')" class="red-asterik"></span>
                </div>
                <div class="col-xs-9 bidder-valuation-labels">
                    <div class="col-xs-2 ">
                        <app-toggle-yes-no #FinanceDealLeagueTable
                        [value]="financeFormGroup.get('financeDealLeagueTable').value"
                        [readonly]="isReadOnly('financeDealLeagueTable')"
                        [isCompressWidth]="true"
                        (handleToggleYesNo)="isFinanceDealLeagueTable($event)">
                        </app-toggle-yes-no>

                    </div>
                    <div class="col-xs-8">
                        <input  ng-disabled="true"
                            class="input-data-text full-width section-input input-data-text no-margin required-when-checked width-20 readonly-input txt-width"
                            formControlName="financeDealLeagueTableDesc" 
                            id="financeDealLeagueTableDesc"
                            type="text">
                    </div>


                </div>
            </div>
        </div>



        <div class="row required-div adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-3 required-div">
                    <span class="span-to-highlight spandependentfield "
                    [class.highlight-warning]="highlightRequiredField('financeDealGlobalDeloitteSite')"
                    [class.edited-externally]="editedExternally('financeDealGlobalDeloitteSite')"
                    [class.conflict-on-merge]="hasMergeConflict('financeDealGlobalDeloitteSite')">Global Deloitte Site</span>
                    <span *ngIf="showAsterisk('financeDealGlobalDeloitteSite')" class="red-asterik"></span>
                </div>
                <div class="col-xs-9 bidder-valuation-labels">
                    <div class="col-xs-2">
                        <!-- <div name="FinanceDealGlobalDeloitteSite" id="FinanceDealGlobalDeloitteSite"
                            class="btn-group btn-group-xs input-data-toggle-group financeradio input-toggle-group div-two-toggle-input required permission"
                            role="group">
                            <button type="button" class="btn btn-default btn-xs two-toggle-button financeradio"
                                value="YES">YES</button>
                            <button type="button" class="btn btn-default btn-xs two-toggle-button financeradio"
                                value="NO">NO</button>
                             <span class="required-span-when-checked red-asterik "></span> 
                        </div><br> -->
                        <app-toggle-yes-no #FinanceDealGlobalDeloitteSite
                        [value]="financeFormGroup.get('financeDealGlobalDeloitteSite').value"
                        [readonly]="isReadOnly('financeDealGlobalDeloitteSite')"
                        [isCompressWidth]="true"
                        (handleToggleYesNo)="isfinanceDealGlobalDeloitteSite($event)">
                        </app-toggle-yes-no>
                    </div>
                    <div class="col-xs-8">
                        <input
                            class="input-data-text full-width section-input input-data-text no-margin required-when-checked width-20 readonly-input txt-width"
                            formControlName="financeDealGlobalDelSiteDesc"
                            id="financeDealGlobalDelSiteDesc"
                             type="text">
                    </div>
                </div>
            </div>
        </div>

        <div class="row required-div adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-3 required-div">
                    <span class="span-to-highlight spandependentfield "
                    [class.highlight-warning]="highlightRequiredField('financeDealAnnouncement')"
                    [class.edited-externally]="editedExternally('financeDealAnnouncement')"
                    [class.conflict-on-merge]="hasMergeConflict('financeDealAnnouncement')">Deal Announcement </span>
                    <span *ngIf="showAsterisk('financeDealAnnouncement')" class="red-asterik"></span>
                </div>
                <div class="col-xs-9 bidder-valuation-labels">
                    <div class="col-xs-2">
                        <!-- <div name="FinanceDealAnnouncement" id="FinanceDealAnnouncement"
                            class="btn-group btn-group-xs input-data-toggle-group input-toggle-group financeradio div-two-toggle-input required permission"
                            role="group">
                            <button type="button" class="btn btn-default btn-xs two-toggle-button financeradio"
                                value="YES">YES</button>
                            <button type="button" class="btn btn-default btn-xs two-toggle-button financeradio"
                                value="NO">NO</button>
                             <span class="required-span-when-checked red-asterik "></span> 
                        </div><br> -->
                        <app-toggle-yes-no #FinanceDealAnnouncement
                        [value]="financeFormGroup.get('financeDealAnnouncement').value"
                        [readonly]="isReadOnly('financeDealAnnouncement')"
                        [isCompressWidth]="true"
                        (handleToggleYesNo)="isFinanceDealAnnouncement($event)">
                        </app-toggle-yes-no>

                    </div>
                    <div class="col-xs-8">
                        <input
                            class="input-data-text full-width section-input input-data-text no-margin required-when-checked width-20 readonly-input txt-width"
                            formControlName="financeDealAnnouncementDesc" 
                            id="financeDealAnnouncementDesc"
                            type="text">
                    </div>


                </div>
            </div>
        </div>
        <div class="row required-div adjustPad-row">
            <div class="col-xs-12">
                <div class="col-xs-3 required-div" style="text-align: left !important;">
                    <div class="span-to-highlight spandependentfield lblpresentations"
                    [class.highlight-warning]="highlightRequiredField('financeDealCaseStudy')"
                    [class.edited-externally]="editedExternally('financeDealCaseStudy')"
                    [class.conflict-on-merge]="hasMergeConflict('financeDealCaseStudy')" style="margin-left: -1px;padding-right: -1px; width: 54%;text-align: left !important;display: inline-block;">Case Study for use
                        in presentations </div>
                    <span *ngIf="showAsterisk('financeDealCaseStudy')" class="red-asterik" style="margin: -24px;"></span>
                </div>
           
                <div class="col-xs-9 bidder-valuation-labels">
                    <div class="col-xs-2">
                        <!-- <div name="FinanceDealCaseStudy" id="FinanceDealCaseStudy"
                            class="btn-group btn-group-xs input-data-toggle-group input-toggle-group financeradio div-two-toggle-input required permission"
                            role="group">
                            <button type="button" class="btn btn-default btn-xs two-toggle-button financeradio"
                                value="YES">YES</button>
                            <button type="button" class="btn btn-default btn-xs two-toggle-button financeradio"
                                value="NO">NO</button>

                        </div><br> -->
                        <app-toggle-yes-no #FinanceDealCaseStudy
                        [value]="financeFormGroup.get('financeDealCaseStudy').value"
                        [readonly]="isReadOnly('financeDealCaseStudy')"
                        [isCompressWidth]="true"
                        (handleToggleYesNo)="isFinanceDealCaseStudy($event)">
                        </app-toggle-yes-no>

                    </div>
                    <div class="col-xs-8">
                        <input
                            class="input-data-text full-width section-input input-data-text no-margin required-when-checked width-20 readonly-input txt-width"
                            formControlName="financeDealCaseStudyDesc"
                            id="financeDealCaseStudyDesc" 
                            type="text">
                    </div>


                </div>
            </div>
        </div>



    </div>
</div>
  <closing-checklist-app-notify-popup 
  [additionalDocumentTypesAvailable]="additionalDocumentTypesAvailable"
  [additionaldocumentTypesToAdd]="additionaldocumentTypesToAdd"
  (deleteConfirmDetails)="ConfirmDetails($event)">
  </closing-checklist-app-notify-popup>
