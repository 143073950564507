import { Component, OnInit, ViewChild, ViewChildren, QueryList, Input } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AuditService } from 'src/app/services/audit.service';
import { ContentService } from 'src/app/services/content.service';
import { AddContentPopupComponent } from '../../contentManagement/add-content-popup/add-content-popup.component';
import { AppSettings } from 'src/shared/app-settings';
import { NgbdSortableHeaderDirective, SortDirection, SortEvent  } from 'src/shared/directives/sorteable.directive';
import { PaginationComponent } from 'src/shared/components/pagination/pagination.component';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PaginationValues } from 'src/shared/models/pagination-values';
import { ContentManagementSort } from '../../models/contentManagement-sort';
import { ContentManagementTableFilterItem } from '../../models/contentManagement-table-filter-item';
import { ContentManagementItem } from '../../models/contentManagementItem';
import { ContentTableFilterItem } from '../../models/content-table-filter-item';
import { environment } from 'src/environments/environment';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuditTrailComponent } from '../audit-trail/audit-trail.component';
import { DateService } from 'src/app/services/date.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { catchError, from, map, Observable } from 'rxjs';
import {Guid} from 'guid-typescript';

declare const require: any;
const xml2js = require("xml2js");

@Component({
  selector: 'app-content-management-old',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.scss']
})
export class ContentManagementComponent implements OnInit {

  isITSAdmin:Boolean = false;
  isContentManagementAdmin:Boolean = false;

  canUpdateAndDelete:Boolean = false;

  formTypes: any;
  AdminRole: any = "";
  isDisableNonITAdmin:any=true;
  isContentManagementUser:boolean = false;
  isFilterApplied:any=false;
  isSortApplied:any=false;
  isDisableUserAdd:any=true;
  isDisableUserAudit:any=true;
  isFilterRecordDeleted:any=false;
  isFilterRecordUpdated:any=false;
  contentTypes: any=[];
  TablesList: any = [];
  selectedTableName: any = "";
  loadinitialDropdownData: any;
  isAdminImg:any=false;
  isContentDisabled: any = true;
  isTableDisabled: any = true;
  UserCurrentAction:any="";
  deleteRecord: any;
  activateRecord:any;
  isButtonsDisplay:any=false;
  duplicateUserRestrictFlag: any = false;
  selectedFormType: any = "";
  selectedFormTypeId:any="";
  selectedContentType: any = "";
  selectedPageSize: any;
  isAddingUser: any = false;
  formGroupDDLDisabled: boolean = true;
  showTable: boolean = false;
  pageDropdownDisable: boolean = false;
  captureDeleteAuditHistoryDocType:boolean = true;
  showDynamicTable:boolean = false;

  tabledefinitions:any;
  tableTemplate:any;
  columnHeaders:Array<any>=[];
  isGridEditAllowed:boolean = false;
  isGridDeleteAllowed:boolean = false;
  isWorkFlowRequired:boolean = false;
  editTemplate:any;

  
  @ViewChild(AuditTrailComponent, { static: true }) auditpopupComponent: AuditTrailComponent;
  @ViewChild(AddContentPopupComponent, { static: true }) addcontentpopupComponent: AddContentPopupComponent;
  @ViewChild(NotifyPopupComponent, { static: true }) notifypopupComponent: NotifyPopupComponent;

  contentManagements: Array<ContentManagementItem>;
  contentManagementPerPage: Array<ContentManagementItem>;
  filterList: Array<ContentManagementTableFilterItem> = [];
  targetField: string;
  targetFilter: any;
  fieldOptionValues: Array<string> = [];
  initialContentManagementList: Array<ContentManagementItem> = [];
  initialCompareContentManagementList: Array<ContentManagementItem> = [];
  pageSizeListValues: Array<any> = [];
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;

  @ViewChild('pagination', { static: false })
  pagination: PaginationComponent;
  contentManagementExportToExcel: Array<any> = [];
  contentManagementPageDetails: Array<any> = [];
  showDisable: boolean;
 // @Input() ContentData: any={};
  constructor(public contentService: ContentService, public auditService: AuditService,private _http: HttpClient,public dateService:DateService,
    public adminService:AdminService,public commonHelperService:CommonHelperService,private spinnerService:SpinnerService,
    public globalHelperService:GlobalHelperService,private newDealService:NewdealService ) { }

    ngOnInit(): void {
      let jsonUrl = '/assets/table-definitions.json';
      
      this._http.get(jsonUrl,{
          headers: new HttpHeaders()
            .set('accept', 'application/json')
            .append('Access-Control-Allow-Methods', 'GET')
            .append('Access-Control-Allow-Origin', '*')
            .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
          responseType: 'text'
        }).subscribe(res => {
        if(res !=undefined)
        {
          this.tabledefinitions = JSON.parse(res);
        }
        });
      
      localStorage.setItem("CurrentAccessPage", "ContentManagement");
      this.globalHelperService.currentAccessPage = "ContentManagement";
      this.commonHelperService.state = this.commonHelperService.getSearchState();
      this.isValidAccess();      
  }
  isValidAccess(){
    let isContentManagementUser = localStorage.getItem("isContentManagementUser");
    if (isContentManagementUser != "true")
    {
      this.VerifyContentManagementAccess();
    }
    else{
      this.OnLoadActivities();
    } 
  }
  OnLoadActivities(){
    this.ApplyBrowserScroll();
     this.spinnerService.showAutoSaveSpinner();
      this.CheckAdminUser();
      //this.loadXML();
      this.GetFormTypes();
  }
  ApplyBrowserScroll() {
    document.body.style.overflow = "auto";
  }
  
  GetUsersListsInGroups() {
    let userId = localStorage.getItem("userProfileId")?.toString();
    this.contentService.GetUserRoleGroups(userId).subscribe(
      {
        next: (result: any) => {
          result = result.filter((s: any) => s.Title != null)
          this.isInAdminGroup(result);
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err)
        }
      })
  }
  CheckAdminUser() {
    this.GetUsersListsInGroups();
  }
  isInAdminGroup(userGroups: any) {
    userGroups.forEach((u: any) => {
      if (u.Role == "CrossBorder_ContentManagement_Business_Administrator") {
        this.isContentManagementAdmin = true;
      }
      if (u.Role == "ITS_Administrator") {
        this.isITSAdmin = true;
      }
    });
    this.spinnerService.hideAutoSaveSpinner();
    //this.EnableDisableActions();
  }
  isITSVerified(event:any){
    this.spinnerService.showAutoSaveSpinner();
    this.isAddingUser = true;
    this.addcontentpopupComponent.openVerificationPopup("Verify",event);
  }
 
  EnableDisableActions(){
    this.canUpdateAndDelete = false;
    this.isDisableUserAdd = true;
    this.isDisableUserAudit = true;

    if(this.isITSAdmin || this.isContentManagementAdmin)
    {
      this.isDisableUserAudit = false;
    }
    if(this.selectedTableName == "CrossBorderAttachments")
    {
      this.isDisableUserAudit = true;
    }
    if(this.selectedTableName == "FormType" ||
      this.selectedTableName == "ValidationFieldsCrossBorder")
    {
      if(this.isITSAdmin)
      { 
        this.canUpdateAndDelete = true;
        if(this.selectedTableName == "FormType")
           this.isDisableUserAdd = false;
      }   
    }
    else
    {
      if(this.isITSAdmin || this.isContentManagementAdmin)
      {
        this.canUpdateAndDelete = true;
        this.isDisableUserAdd = false;
      }
    }
  }

  GetFormGroups() {
    var formID = this.selectedFormType;
    this.contentService.GetFormGroups(formID).subscribe(
      {
        next: (result: any) => {
          this.formGroupDDLDisabled = false;
          this.contentTypes = result;
          this.spinnerService.hideAutoSaveSpinner();
        },
        error: (err: any) => {
          console.log("Error" + err)
        }
      })
  }
//Added due to delay in API execution
VerifyContentManagementAccess(){
  let userId=localStorage.getItem("userProfileId")?.toString();
  this.adminService.GetUserRoleGroups(userId).subscribe(
    {
      next: (result: any) => {
        result=result.filter((s:any)=>s.Title!=null)
        this.isContentManagementUser = this.isInContentManagementGroup(result);
        if(this.isContentManagementUser)
        {
          localStorage.setItem("isContentManagementUser","true");
          this.OnLoadActivities();
        } 
        else{
          localStorage.setItem("isContentManagementUser","false");
          window.location.href =AppSettings.DashboardURL;
          return;
        }  
      },
      error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if (err.status == 403 && err.error.stringKey == "tokenexpired") { this.commonHelperService.isTokenExpired(); }
        if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
        console.log("Error" + err)
      }
    })
}

isInContentManagementGroup(userGroups:any):boolean {
  let isValidUser=false;
  userGroups.forEach((u:any)=>{   
    if(u.Role=="CrossBorder_ContentManagement_Business_Administrator" || u.Role == "ITS_Administrator"){
      isValidUser=true;
    }
    });
  return isValidUser;
}
  onFormTypeChange() {
    this.selectedFormTypeId = this.formTypes.find((item:any)=>item.Name == this.selectedFormType)?.ID;
    this.loadXML(this.selectedFormType);
    this.contentTypes=[];
    this.isTableDisabled = true;
    this.isContentDisabled = false;
    this.showTable = false;
    if (this.selectedFormType == "Cross Border") {
      this.spinnerService.showAutoSaveSpinner();
      this.contentTypes.push({
        ID: 1,
        Name: 'Master Data'
      },
      {
        ID: 2,
        Name: 'Transactional Data'
      }
    );
    //Prod release change else part
      this.spinnerService.hideAutoSaveSpinner();
      this.selectedContentType = "";
      this.selectedTableName="";
    }
    else if (this.selectedFormType == "My Deal" && this.isITSAdmin) {
      this.spinnerService.showAutoSaveSpinner();
      this.contentTypes.push({
        ID: 1,
        Name: 'Master Data'
      },
      {
        ID: 2,
        Name: 'Transactional Data'
      }
    );
      this.spinnerService.hideAutoSaveSpinner();
      this.selectedContentType = "";
      this.selectedTableName="";
    }
    else if (this.selectedFormType == "New Deal" && this.isITSAdmin) {
      this.spinnerService.showAutoSaveSpinner();
      this.contentTypes.push({
        ID: 1,
        Name: 'App Configurations'
      },
      {
        ID: 2,
        Name: 'Transactional Data'
          },
      {
        ID: 3,
        Name: 'Master Data'
      }
    );
      this.spinnerService.hideAutoSaveSpinner();
      this.selectedContentType = "";
      this.selectedTableName="";
    }
    else if ((this.selectedFormType == "Deal WBS"||this.selectedFormType=="Closing Checklist") && this.isITSAdmin) {
      this.spinnerService.showAutoSaveSpinner();
      this.contentTypes.push({
        ID: 1,
        Name: 'App Configurations'
      },
      {
        ID: 2,
        Name: 'Master Data'
      }
    );
      this.spinnerService.hideAutoSaveSpinner();
      this.selectedContentType = "";
      this.selectedTableName="";
    }  
    else {
      this.showTable = false;
      this.selectedContentType = "";
      this.contentTypes=[];
      this.isTableDisabled = true;
    }
    this.spinnerService.hideAutoSaveSpinner();
  }
  onContentTypeChange() {
    
    this.isTableDisabled = false;
    this.showTable = false;
    this.selectedTableName = "";
    this.TablesList=[];
    if (this.selectedContentType != "") {
      this.spinnerService.showAutoSaveSpinner();
      if (this.selectedContentType == "Master Data") {
       // Prod release changes
        if(this.isITSAdmin){
          this.TablesList = this.loadinitialDropdownData.xmlMasterTable;
        }
        else if(this.isContentManagementAdmin){
         this.TablesList.push({
          ID: 1,
          TableName: 'CrossBorderCountries'
        });
         this.TablesList.push({
          ID: 2,
          TableName: 'CrossBorderTemplates'
        });
        }
        else{
          this.showTable = false;
          this.selectedTableName = "";
          this.TablesList=[];
        }
      }
      else if (this.selectedContentType == "Transactional Data"&&(this.selectedFormType=="Cross Border"||this.selectedFormType=="New Deal"||this.selectedFormType=="My Deal")) {
        this.TablesList = this.loadinitialDropdownData.xmlTransactionalTable;
        this.selectedTableName="";
      }
      else {
        if(this.isContentManagementAdmin||this.isITSAdmin)
        this.TablesList = this.loadinitialDropdownData.xmlConfigTable;
      }
    } else {
      this.showTable = false;
      this.selectedTableName = "";
    }
    this.spinnerService.hideAutoSaveSpinner();

}

  GetFormTypes() {
    this.contentService.GetContentDetails("api/FormType").subscribe(
      {
        next: (result: any) => {
          result=this.commonHelperService.getActiveRecords(result);
          this.formTypes = result;
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();    
          console.log("Error" + err)
        }
      })
  }
  getActualStatus(formObj:any){
    let resStatus:any="";
        if (formObj.FormActionGroup == "MemberFirmTeam") {
          if (formObj.FormStatus == "Draft") {
            resStatus = "Draft";
          }
          else if (formObj.FormStatus == "Pending") {
            resStatus = "Returned for Rework";
          }
          else if (formObj.FormStatus == "Reactivated") {
            resStatus = "Reactivated";
          }
          else if (formObj.FormStatus == "Rejected") {
            resStatus = "Rejected";
          }
          else {
            if (formObj.FormStatus == "OnHold") {
              resStatus = "On Hold";
            }
            else {
              resStatus = formObj.FormStatus;
            }
          }
    
        }
        else if (formObj.FormActionGroup == "SecondSubmissionTeam") {
          if (formObj.FormStatus == "Pending") {
            resStatus = "Pending for submission";
          }
          else if (formObj.FormStatus == "Approved") {
            resStatus = "Approved";
          }
          else {
            if (formObj.FormStatus == "OnHold") {
              resStatus = "On Hold";
            }
            else {
              resStatus = formObj.FormStatus;
            }
          }
        }
        else if (formObj.FormActionGroup == "Series24") {
          if (formObj.FormStatus == "Completed") {
            resStatus = "Completed";
          }
          else if (formObj.FormStatus == "Pending") {
            resStatus = "Pending for Series 24 approval";
          }
          else if (formObj.FormStatus == "Submitted") {
            resStatus = "Submitted";
          }
          else {
            if (formObj.FormStatus == "OnHold") {
              resStatus = "On Hold";
            }
            else {
              resStatus = formObj.FormStatus;
            }
          }
        }
        else if (formObj.FormActionGroup == "BusinessQRMCompliance") {
          if (formObj.FormStatus == "Pending") {
            resStatus = "Review in progress";
          }
          else {
            if (formObj.FormStatus == "OnHold") {
              resStatus = "On Hold";
            }
            else {
              resStatus = formObj.FormStatus;
            }
          }
        }
        else if (formObj.FormActionGroup == "IndustryRepresentative") {
          if (formObj.FormStatus == "Pending") {
            resStatus = "Pending for Industry Representative approval";
          }
          else {
            if (formObj.FormStatus == "OnHold") {
              resStatus = "On Hold";
            }
            else {
              resStatus = formObj.FormStatus;
            }
          }
        }
        else {
          if (formObj.FormStatus == "OnHold") {
            resStatus = "On Hold";
          }
          else {
            resStatus = formObj.FormStatus;
          }
        }
        return resStatus;
      }
  appendFormTypes(res:any){
    res.forEach((v: any) =>{ 
    if(v.Name=="Cross Border")
     v.Name = v.Name + " form"
    });
     return res;
  }
  openAddContentPopup(event: any) {
    this.isAddingUser = true;
    this.addcontentpopupComponent.openPopup("Save");
  }
  openAuditPopup(event:any){
    this.spinnerService.showAutoSaveSpinner();
    this.isAddingUser = true;
    this.auditpopupComponent.openPopup("Fetch");
  }
  showMsgs(msg: any, msgType: any,validationMsg?:any) {
    this.notifypopupComponent.openPopup(msg, msgType,validationMsg)
  }
  isDelete(record:any){
    this.UserCurrentAction="Delete";
    this.isFilterRecordDeleted=true;
    this.isAddingUser = true;
    this.deleteRecord = record;
    if(this.selectedTableName == "CrossBorderAdditionalDocumentTypes" 
    || this.selectedTableName == "CrossBorderApprovalReviewStatus")
    {
      this.showMsgs("DeleteOperation", "HardDelete");
    }
    else{
      this.showMsgs("DeleteOperation", "Info");
    }
    
  }
  ActivateForm(record:any){
    this.isFilterRecordDeleted=true;
    this.isAddingUser = true;
    this.activateRecord = record;
    this.UserCurrentAction="Activate";
    this.showMsgs("Content", "ActivateInfo");
  }
  DeleteUser(record: any) {
    //For updating grid after deleting the filtered records
    if(this.selectedTableName!="CrossBorderDocumentTypes"){
      if(this.selectedTableName=="FormGroups"){
        this.ValidateGroupHasIndustryOrUsers(record);
      }
      else
      {
      this.isDelete(record);       
      }
    }
    else{
      if(record.isApproved=="Yes"||record.isRejected=="Yes"||record.isApproved=="-"||record.isRejected=="-"){
        this.isDelete(record);
      }
      else{
        this.isAddingUser = true;
        this.showMsgs("Content","ITSAdminNotVerified");
      }
    }
  }
  ValidateGroupHasIndustryOrUsers(record:any){
    this.spinnerService.showAutoSaveSpinner();
    this.getUserGroupsAndIndustries(record); 
  }
  getIndustryRepresentativeGroups(record:any){
    let isGroupMappedIndustry=false;
    this.adminService.GetIndustryRepresentativeGroups().subscribe(
      {
        next: (result: any) => {          
          if(result.length!=0){
            result.forEach((x:any)=>{
              if(x.Representative==record.Name&&x.isActive!="No"){isGroupMappedIndustry=true;}
            })
            if(isGroupMappedIndustry){
              this.spinnerService.hideAutoSaveSpinner();
            this.showMsgs("Content","FormGroupValidation");
            return;
            }
            else{
              this.spinnerService.hideAutoSaveSpinner();
              this.isDelete(record);
            }
          }
          else{
            this.spinnerService.hideAutoSaveSpinner();
            this.isDelete(record);
          }
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err)
        }
      })
  }
  getUserGroupsAndIndustries(record:any) {
    this.spinnerService.showAutoSaveSpinner();
    this.adminService.GetUserGroups(record.ID,true).subscribe(
      {
        next: (result: any) => {          
        if(result.length!=0){
          this.spinnerService.hideAutoSaveSpinner();
          this.showMsgs("Content","FormGroupValidation");
          return;
        }
        else{
           this.getIndustryRepresentativeGroups(record);  
        }
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          this.duplicateUserRestrictFlag=false;
          console.log("Error" + err)
        }
      })
  } 
  prepareUpdateRequest(oldData:any,event:any,operation:string){
    const userEmail = localStorage.getItem("userProfileId");

    switch (this.selectedTableName) {
      case "MyDeal": {
        let RequestBody = {
          EngagementName: event.EngagementName,
          Series24: event.Series24,
          Series24DisplayNames: event.Series24DisplayNames,
          WbsCode: event.WbsCode,
          ModifiedBy: userEmail,
          Modified: event.Modified,
        };

        const data = {
          formId: event.ID,
          updateData: this.cleanJsonObject(RequestBody),
          rangeKey: event.rangeKey
        };
        return data;
        break;

      }
      case "NewDealSetupForm": {
        let RequestBody = {
          LeadClientServicePartner: event.LeadClientServicePartner,
          LeadClientServicePartnerDisplayNames: event.LeadClientServicePartnerDisplayNames,
          SwiftCode: event.SwiftCode,
          ModifiedBy: userEmail,
          Modified: event.Modified,
          formId: event.ID,
          rangeKey: event.rangeKey
          
        };

        const data = {
          formId: event.ID,
          updateData: this.cleanJsonObject(RequestBody),
          rangeKey: event.rangeKey
        };
        return data;
        break;

      }
      default: {
        //statements; 
        return null;
        break;
      }
    }
  }
  prepareDynamicUpdateRequest(oldData:any,event:any,operation:string,auditRequest:any){
    const userEmail = localStorage.getItem("userProfileId");
    let RequestBody:any = {};
    var data:any  ={};
    if(this.editTemplate!=undefined && this.editTemplate!=null)
      {
        //Add fields to be updated
        if(operation==="Update"||operation=="Save")
        {
        this.editTemplate.Fields.forEach((element:any) => {
         
         if(operation=="Save")
         {
            if(element.controltype == "checkbox")
              {
                RequestBody[element.field] = (event[element.field] == true ? "true":"false");
              }
              else
              {
                RequestBody[element.field] = event[element.field];
              } 
         }
         else
         {
          if(!element.disabled)
          {
            if(element.field!=this.editTemplate.partitionKey && element.field!=this.editTemplate.rangeKey)
            {
              if(element.controltype == "checkbox")
                {
                  RequestBody[element.field] = (event[element.field] == true ? "true":"false");
                }
                else
                {
                  RequestBody[element.field] = event[element.field];
                } 
            }
                       
          }
        }
          RequestBody["isActive"] = "Yes";
        });

        //check if workflow is required
        if(this.isWorkFlowRequired)
        {
          RequestBody["isApproved"] = "No";
          RequestBody["isRejected"] = "No";
          RequestBody["VerificationDescription"] = auditRequest.Description;
        }
      }
      else if (operation=="Delete")
      {
        RequestBody["isActive"] = "No";
      }
  
        //Add Modified user details
        RequestBody["Modified"] = new Date();
        RequestBody["ModifiedBy"] = userEmail;
        
        //For update add partition and rangekey
        if(operation.toUpperCase()!='SAVE')
        {
        data[this.editTemplate.partitionKey] = event[this.editTemplate.partitionKey];
        if(this.editTemplate.rangeKey!="")
        {
          data[this.editTemplate.rangeKey] = event[this.editTemplate.rangeKey];
        }
        data.updateData = this.cleanJsonObject(RequestBody);
       }
       else
       {
        data = this.cleanJsonObject(RequestBody);
       }
      }
    return data;
  }
  prepareRequest(oldData:any,event: any, operation: string) {
    const userEmail = localStorage.getItem("userProfileId");
    let RequestBody = {};
    switch (this.selectedTableName) {
      case "CrossBorderCountries": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Title: event.Title,
        };
        break;
      }
      case "MyDeal": {      
              let data = {
                EngagementName: event.EngagementName,
                Series24: event.Series24,
                Series24DisplayNames: event.Series24DisplayNames,
                WbsCode: event.WbsCode,
                ModifiedBy: userEmail,
                Modified: event.Modified,
                formId: event.ID,
                rangeKey: event.rangeKey
              };
              
              return data;

            }
            case "NewDealSetupForm": {      
              let data = {
                MyDealEngagementName: event.MyDealEngagementName,
                LeadClientServicePartner: event.LeadClientServicePartner,
                LeadClientServicePartnerDisplayNames: event.LeadClientServicePartnerDisplayNames,
                SwiftCode: event.SwiftCode,
                ModifiedBy: userEmail,
                Modified: event.Modified,
                formId: event.ID,
                rangeKey: event.rangeKey
              };
              
              return data;

            }
      case "CrossBorderDocumentTypes": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          DocumentTypeOrder: event.DocumentTypeOrder.toString(),
          DocumentTypeId: event.DocumentTypeOrder.toString(),
          DocumentTypeTitle: event.DocumentTypeTitle,
          Required: (event.Required == true ? "TRUE" : "FALSE"),
          Guidance: event.Guidance,
          BusinessReviewApplies: (event.BusinessReviewApplies == true ? "TRUE" : "FALSE"),
          QRMReviewApplies: (event.QRMReviewApplies == true ? "TRUE" : "FALSE"),
          ComplianceReviewApplies: (event.ComplianceReviewApplies == true ? "TRUE" : "FALSE"),
          RequiresFinalVersion: (event.RequiresFinalVersion == true ? "TRUE" : "FALSE"),
          DependentDocumentTypes: event.DependentDocumentTypes,
          isApproved:operation == "Approved" ? "Yes" : "No",
          isRejected:operation == "Rejected" ? "Yes" : "No",
          VerificationDescription: event.VerificationDescription
        };
        break;
      }
      case "CrossBorderApprovalReviewStatus":{
        return RequestBody = {
            ID:event.ID,
            Modified: userEmail,
            BusinessReviewDate: "",
            QRMReviewDate: "",
            ComplianceReviewDate: "",
            BusinessReviewer: this.checkAndGetReveiwerName(operation,oldData,event,1),
            QRMReviewer: this.checkAndGetReveiwerName(operation,oldData,event,2),
            ComplianceReviewer : this.checkAndGetReveiwerName(operation,oldData,event,3),
            AdditionalReviewNeeded: "",
            AutomaticReviewDate:"",
            CrossBorderReviewProcessWorkflow0916:"",
            ComplianceReviewStatus: event.ComplianceReviewStatus=='-'?"":event.ComplianceReviewStatus,
          BusinessReviewStatus: event.BusinessReviewStatus == '-' ?"":event.BusinessReviewStatus,
          QRMReviewStatus: event.QRMReviewStatus == '-' ?"":event.QRMReviewStatus,
            DocumentType : event.DocumentType,
            FormID: event.FormID,
            CrossBorderReviewProcessWorkflow: "",
            CreatedBy: event.CreatedBy,
            Created: event.CreatedBy,
            ModifiedBy: userEmail,
            isActive : event.isActive            
                     
        };
        break;
      }
      case "CrossBorderIndustries": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Industry: event.IndustryTitle,
          Representative: event.Representative,
          Title: event.IndustryTitle
        };
        break;
      }
      case "CrossBorderTemplates": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Name: event.Name,
          Title: event.Title,
          DocumentType: event.DocumentType
        };
        break;
      }
      case "ValidationFieldsCrossBorder": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Title: event.Title,
          RequiredFields: Array.isArray(event.RequiredFields)?event.RequiredFields.join(";#"):event.RequiredFields.split(",").join(";#"),
          RequiredGroupFields: event.RequiredGroupFields
        };
        break;
      }
      case "FormGroups":
      case "FormType": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Name: event.Name
        };
        break;
      }
      case "AppConfigurations": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          Title: event.Title,
          FormTypeID:this.selectedFormTypeId,
          Value:this.BindConfigValue(event.Value)
        };
        break;
      }
      case "CrossBorderForm":{
        return RequestBody = {
        ID: event.ID,
        FormID: event.ID,
        ModifiedBy: userEmail,
        CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
        isActive: operation == "Delete" ? "No" : "Yes",
        ProjectName:event.ProjectName,
        GeographConcentrationComments:event.GeographConcentrationComments,
        USCounterpartiesContactComments:event.USCounterpartiesContactComments,
        ShareholderObjectivesComments:event.ShareholderObjectivesComments,
        CompanySubsectorComments:event.CompanySubsectorComments,    
        EnterpriseValue: event.EnterpriseValue,
        ExpectedCompanyValueComments:event.ExpectedCompanyValueComments
        };
        break;
      }
      default: {
        //statements; 
        return null;
        break;
      }

    }
  }
  prepareDynamicRequest(oldData:any,event: any, operation: string) {
    const userEmail = localStorage.getItem("userProfileId");
    let RequestBody:any = {};
    
    if(this.editTemplate!=undefined && this.editTemplate!=null)
    {
      //For update add partition and rangekey
      RequestBody[this.editTemplate.partitionKey] = event[this.editTemplate.partitionKey];
      if(this.editTemplate.rangeKey!="")
      {
        RequestBody[this.editTemplate.rangeKey] = event[this.editTemplate.rangeKey];
      }

        //Add other update fields
        if (operation == "Update" || operation == "Save") {
            this.editTemplate.Fields.forEach((element: any) => {
                if (!element.disabled) {
                    if (element.controltype == "checkbox") {
                        RequestBody[element.field] = (event[element.field] == true ? "true" : "false");
                    }
                    else {
                        RequestBody[element.field] = event[element.field];
                    }
                }
            });
        }
        else if (operation == "Delete") {
            RequestBody["isActive"] = "No";
        }

      //Add Modified user details
      RequestBody["Modified"] = new Date();
      RequestBody["ModifiedBy"] = userEmail;
    }
    return RequestBody;
  }

  cleanJsonObject(obj:any) {
    for (const key in obj) {
      if (obj[key] === null||obj[key] === undefined) {
        obj[key] = '';
      } else if (typeof obj[key] === 'object') {
        this.cleanJsonObject(obj[key]);
      }
    }
    return obj;
  }
  prepareActivateRequest(event: any) {
    const userEmail = localStorage.getItem("userProfileId");
    let RequestBody = {};
      return RequestBody = {
          ID: event.ID,
          FormID: event.ID,
          isActive : "Yes",
          Created : event.Created,
          ProjectName: event.ProjectName,
          ModifiedBy: userEmail     
        }
  
    }
  prepareDeleteRequest(event: any, operation: string) {
    const userEmail = localStorage.getItem("userProfileId");
    let RequestBody = {};
    switch (this.selectedTableName) {
      case "CrossBorderForm": {
        return RequestBody = {
          ID: event.ID,
          FormID: event.ID,
          isActive : "No",
          Created : event.Created,
          ProjectName: event.ProjectName          
        };
        break;
      }
      case "CrossBorderAdditionalDocumentTypes": {
        return RequestBody = {
          ID: event.ID,
          Created : event.Created,
          DocumentType: event.DocumentType,
          FormID:event.FormID
        };
        break;
      }
      case "CrossBorderCountries": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Title: event.Title
        };
        break;
      }
      case "CrossBorderApprovalReviewStatus":{
        return RequestBody = {
            ID:event.ID,
            Modified: userEmail,
            BusinessReviewDate: "",
            QRMReviewDate: "",
            ComplianceReviewDate: "",
            BusinessReviewer: event.BusinessReviewer,
            QRMReviewer: event.QRMReviewer,
            ComplianceReviewer : event.ComplianceReviewer,
            AdditionalReviewNeeded: "",
            AutomaticReviewDate:"",
            CrossBorderReviewProcessWorkflow0916:"",
            ComplianceReviewStatus: event.ComplianceReviewStatus,
            BusinessReviewStatus : event.BusinessReviewStatus,
            QRMReviewStatus : event.QRMReviewStatus,
            DocumentType : event.DocumentType,
            FormID: event.FormID,
            CrossBorderReviewProcessWorkflow: "",
            CreatedBy: event.CreatedBy,
            Created: event.CreatedBy,
            ModifiedBy: event.ModifiedBy,
            isActive : event.isActive
                     
        };
        break;
      }
      case "CrossBorderDocumentTypes": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          DocumentTypeOrder: event.DocumentTypeOrder.toString(),
          DocumentTypeId: event.DocumentTypeOrder.toString(),
          DocumentTypeTitle: event.DocumentTypeTitle,
          Required: event.Required,
          Guidance: event.Guidance,
          BusinessReviewApplies: event.BusinessReviewApplies,
          QRMReviewApplies: event.QRMReviewApplies,
          ComplianceReviewApplies: event.ComplianceReviewApplies,
          RequiresFinalVersion: event.RequiresFinalVersion,
          DependentDocumentTypes: event.DependentDocumentTypes,
          isApproved:operation == "Approved" ? "Yes" : "No",
          isRejected:operation == "Rejected" ? "Yes" : "No",
          VerificationDescription: event.VerificationDescription
        };
        break;
      }
      case "CrossBorderIndustries": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Industry: event.Industry,
          Representative: event.Representative,
          Title: event.Title
        };
        break;
      }
      case "CrossBorderTemplates": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Name: event.Name,
          Title: event.Title,
          DocumentType: event.DocumentType
        };
        break;
      }
      case "ValidationFieldsCrossBorder": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Title: event.Title,
          RequiredFields: event.RequiredFields,
          RequiredGroupFields: event.RequiredGroupFields
        };
        break;
      }
      case "FormGroups":
      case "FormType": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Name: event.Name          
        };
        break;
      }
      case "AppConfigurations": {
        return RequestBody = {
          ID: event.ID,
          ModifiedBy: userEmail,
          CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
          isActive: operation == "Delete" ? "No" : "Yes",
          Title: event.Title,
          Value:this.BindConfigValue(event.Value)
        };
        break;
      }
      default: {
        //statements; 
        return null;
        break;
      }

    }
  }
  BindConfigValue(Value:any){
    let res="";
  if(Value==false){
    res="FALSE";
  }
  else if(Value==true){
    res="TRUE";
  }
  else{
    res=Value;
  }
  return res;
  }
 AuditDetails(auditRequestBody:any){
  this.SaveAuditDetails(auditRequestBody);

 }
 AuditInForm(auditRequestBody:any){
  this.SaveAuditDetailsInForm(auditRequestBody);

 }

SaveAuditDetailsInForm(requestBody:any){
  this.auditService.SaveAuditDetailsInForm(requestBody).subscribe(
    {
      next: (result: any) => {
        //this.showMsgs("Content","AuditSuccess");
      },
      error: (err: any) => {
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        this.duplicateUserRestrictFlag = false;
        console.log("Error" + err)
       // this.showMsgs("Content","AuditError");
      }
    })
 }

 SaveAuditDetails(requestBody:any){
  this.auditService.SaveAuditDetails(requestBody).subscribe(
    {
      next: (result: any) => {
        //this.showMsgs("Content","AuditSuccess");
      },
      error: (err: any) => {
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        this.duplicateUserRestrictFlag = false;
        console.log("Error" + err)
       // this.showMsgs("Content","AuditError");
      }
    })
 }
 PrepareAuditRequest(oldData:any,newData:any,UserActionOn:any){
  let userEmail = localStorage.getItem("AuditorFullName")?.toString();
  if(userEmail==undefined){userEmail=localStorage.getItem("userProfileName")?.toString();}
  let AuditRequest={};
  switch(UserActionOn){
    case"Get":
    return AuditRequest = {
      ID: "",
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description: this.selectedTableName+" table has been fetched by the User"
    };
    break;
    case"Save":
    return AuditRequest = {
      ID: "",
      FormID:(newData.FormID!=undefined ? newData.FormID : ""),
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description:this.GetSaveAndDeleteAuditDescription(newData,UserActionOn)
    };
    break;
    case"Update":
    return AuditRequest = {
      ID: "",
      FormID:(newData.FormID!=undefined ? newData.FormID : ""),
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description:this.GetUpdateAuditDescription(oldData,newData)
    };
    break;
    case"Delete":
    return AuditRequest = {
      ID: "",
      FormID:(newData.FormID!=undefined ? newData.FormID : ""),
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description:this.GetSaveAndDeleteAuditDescription(newData,UserActionOn)
    };
    break;
    case"ActivateForm":
    return AuditRequest = {
      ID: "",
      FormID:(newData.FormID!=undefined ? newData.FormID : ""),
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description:"Form #"+newData.FormID+" has been activated by the User!"
    };
    break;
    default:
      return null;
    break;
  }
 }
 PrepareDynamicAuditRequest(oldData:any,newData:any,UserActionOn:any){
  let userEmail = localStorage.getItem("AuditorFullName")?.toString();
  if(userEmail==undefined){userEmail=localStorage.getItem("userProfileName")?.toString();}
  let AuditRequest={};
  switch(UserActionOn){
    case"Get":
    return AuditRequest = {
      ID: "",
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description: this.selectedTableName+" table has been fetched by the User"
    };
    break;
    case"Save":
    return AuditRequest = {
      ID: "",
      FormID:(newData.FormID!=undefined ? newData.FormID : ""),
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description: this.GetDynamicSaveAndDeleteAuditDescription(newData,UserActionOn)
    };
    break;
    case"Update":
    return AuditRequest = {
      ID: "",
      FormID:(newData.FormID!=undefined ? newData.FormID : ""),
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description:this.GetDynamicUpdateAuditDescription(oldData,newData)
    };
    break;
    case"Delete":
    return AuditRequest = {
      ID: "",
      FormID:(newData.FormID!=undefined ? newData.FormID : ""),
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description:this.GetDynamicSaveAndDeleteAuditDescription(newData,UserActionOn) //"The following item has been deleted" // need to make this dynamic according to the Delete operation.
    };
    break;
    case"ActivateForm":
    return AuditRequest = {
      ID: "",
      FormID:(newData.FormID!=undefined ? newData.FormID : ""),
      ModifiedBy: userEmail,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.selectedTableName,
      ContentType:this.selectedContentType,
      Description:"Form #"+newData.FormID+" has been activated by the User!"
    };
    break;
    default:
      return null;
    break;
  }
 }

 GetSaveAndDeleteAuditDescription(data:any,Operation:any){
  let comments="";
  Operation=Operation=="Save"?"added":"deleted";
  // old logic 
      switch(this.selectedTableName){
        case "CrossBorderForm": { 
          return comments="Form: "+ data.ProjectName +" has been "+Operation;
          break; 
        } 
        case "CrossBorderAdditionalDocumentTypes": { 
          return comments="'"+data.DocumentType+"' has been "+Operation;
          break; 
        } 
        case "CrossBorderCountries": { 
            return comments="'"+data.Title+"' has been "+Operation;
          break; 
        } 
        case "CrossBorderDocumentTypes": { 
          return comments="'"+data.DocumentTypeTitle+"' has been "+Operation;
          break; 
        } 
      case "CrossBorderIndustries": { 
        return comments="'"+data.Industry+"' has been "+Operation;
      break;  
      } 
      case "CrossBorderApprovalReviewStatus":{
        return comments="'"+data.DocumentType+"' has been "+Operation;
      }
      case "CrossBorderTemplates": { 
        let templateOperation=Operation=="added"?"added to ":"deleted from ";
        return comments="'"+data.Name+"' has been "+templateOperation+"'"+data.DocumentType+"'";
      break; 
      } 
      case "ValidationFieldsCrossBorder": { 
        return comments="'"+data.RequiredFields+"' has been "+Operation;
        break; 
      } 
      case "FormGroups":
      case "FormType": { 
        return comments="'"+data.Name+"' has been "+Operation;
        break; 
      }
      case "DealWbsSetupTooltips": 
      case "DealWbsValidationFields": { 
        return comments="'"+data.FieldName+"' has been "+Operation;
        break; 
      }  
      default: { 
            //statements; 
            return comments;
            break; 
      } 
    }  
 }

 GetDynamicSaveAndDeleteAuditDescription(data:any,Operation:any){
  let comments="";
  Operation=Operation=="Save"?"added":"deleted";
  comments = this.editTemplate.partitionKey +":"+data[this.editTemplate.partitionKey] + " "+Operation;
  return comments;
 }

 GetUpdateAuditDescription(oldData:any,newData:any){
  let description="";
  try{
    for (let Array1 in oldData) {
      for(let Array2 in newData){
        if(Array1!="isActive"&&Array1!="ModifiedBy"&&Array1!="ID"&&Array1!="CreatedBy"&&Array1!="isApproved"&&Array1!="isRejected"&&Array1!="VerificationDescription"&&Array1==Array2&&Array1!="rangeKey"&&Array1!="Modified")
        {
          description = this.BindAuditUpdateDescription(oldData,newData,Array1,Array2,description);
        }
      }
    }
  }
  catch(e){
    return description;
  }
 return description=description.slice(0,-2);;
 }

 GetDynamicUpdateAuditDescription(oldData:any,newData:any){
  let description="";
  let excludeColumns = ["isActive","Modified","ModifiedBy","ID","CreatedBy","isApproved","isRejected","VerificationDescription"];
  try{
    for (let Array1 in oldData) 
      {
      for(let Array2 in newData)
      {
        if((!excludeColumns.includes(Array1)) &&Array1==Array2)
        {
          description = this.BindDynamicAuditUpdateDescription(oldData,newData,Array1,Array2,description);
        }
      }      
    }
    if(description!="")
    {
      let partitionKeyColDetails = "";
      partitionKeyColDetails = this.editTemplate.partitionKey +":"+oldData[this.editTemplate.partitionKey];
      description = partitionKeyColDetails + " "+description;
    }
  }
  catch(e){
    return description;
  }
 return description=description.slice(0,-2);;
 }

 BindAuditUpdateDescription(oldData:any,newData:any,Array1:any,Array2:any,description:any){
  if(oldData[Array1]!=newData[Array2]&&this.selectedTableName=="CrossBorderTemplates"){
    if(oldData["Name"]!=newData["Name"]&&oldData["DocumentType"]==newData["DocumentType"]){ description=description+Array1+" : '"+oldData[Array1] +"' To '"+newData[Array2]+"'"+" has been changed for '"+newData["DocumentType"]+"'#;";}
    else if(oldData["Name"]!=newData["Name"]&&oldData["DocumentType"]!=newData["DocumentType"]){description=description+Array1+" '"+newData[Array2]+"'"+" has been added for '"+newData["DocumentType"]+"'#;";}
    else if(oldData["Name"]==newData["Name"]&&oldData["DocumentType"]!=newData["DocumentType"]){description=description+Array1+" '"+newData[Array2]+"'"+" has been added for '"+newData["DocumentType"]+"'#;";}
    else if(oldData[Array1]!=newData[Array2]){description=description+Array1+" : '"+oldData[Array1] +"' To '"+newData[Array2]+"'"+" has been changed for '"+newData["DocumentType"]+"'#;";}
  }
  else if(oldData[Array1]!=newData[Array2]&&this.selectedTableName=="CrossBorderIndustries"){description=description+Array1+" : '"+oldData[Array1] +"' To '"+newData[Array2]+"'"+" has been changed for '"+newData["Industry"]+"'#;";}
  else if(oldData[Array1]!=newData[Array2]&&this.selectedTableName=="ValidationFieldsCrossBorder")
  {
    description=description+Array1+" : '"+oldData[Array1] +"' To '"+newData[Array2]+"'"+" has been changed.#;";
  }

  else if(oldData[Array1]!=newData[Array2]&&this.selectedTableName=="CrossBorderApprovalReviewStatus")
  {
    description=description+" "+Array1+" : '"+(oldData[Array1]=='-'?' ':oldData[Array1]) 
    +"' To '"+(newData[Array2]=='-'?' ':newData[Array2])+"' has been changed for "+newData['DocumentType']+".#;";
  }
  else if(oldData[Array1]!=newData[Array2])
  {
    description=description+Array1+" : '"+oldData[Array1] +"' To '"+newData[Array2]+"'#;";
  }
  return description;
 }

 BindDynamicAuditUpdateDescription(oldData:any,newData:any,Array1:any,Array2:any,description:any){
  if(oldData[Array1]!=newData[Array2])
  {
    description=description+Array1+" : '"+oldData[Array1] +"' To '"+newData[Array2]+"'#;";
  }
  return description;
 }

  SaveAndUpdateContentDetails(event: any) 
  {
    this.spinnerService.showAutoSaveSpinner();
    this.duplicateUserRestrictFlag = true;
    if (event != null) 
    {
      if(this.showDynamicTable)
      {
        //dynamic implementation
        var appendURL = this.contentService.getAppendURL(this.selectedTableName);
        if (event[2].toString().toUpperCase()=="SAVE") 
        {
          //add new record
          let NewRequestBody = this.prepareDynamicRequest(null,event[1], "Save");
          let auditRequestBody=this.PrepareDynamicAuditRequest("",NewRequestBody,"Save");
          let UpdateRequestBody= this.prepareDynamicUpdateRequest(null,event[1],"Save",auditRequestBody);
          if(event[3].toString()=="RecordAlreadyExist"){
            let UpdateRequestBody= this.prepareDynamicUpdateRequest(null,event[1],"Update",auditRequestBody);
            this.UpdateContentDetails(appendURL,UpdateRequestBody,"UpdateSuccess");
          }
          else
          {
            this.SaveContentDetails(appendURL,UpdateRequestBody);
          }
          this.AuditDetails(auditRequestBody);
        }
        else
        {
          //update record
          this.isFilterRecordUpdated=true;
          let oldRequestBody = this.prepareDynamicRequest(null,event[0], "Update");
          let NewRequestBody = this.prepareDynamicRequest(oldRequestBody,event[1], "Update"); 
          let auditRequestBody=this.PrepareDynamicAuditRequest(oldRequestBody,NewRequestBody,"Update");
          let UpdateRequestBody= this.prepareDynamicUpdateRequest(null,event[1],"Update",auditRequestBody);
          this.UpdateContentDetails(appendURL,UpdateRequestBody,"UpdateSuccess");
          this.AuditDetails(auditRequestBody);
        } 
      }
      else
      {
        //existing implementation
        var appendURL = this.contentService.getAppendURL(this.selectedTableName);
        if (event[2].toString().toUpperCase()=="SAVE") {
          let NewRequestBody = this.prepareRequest(null,event[1], "Save");
          let auditRequestBody=this.PrepareAuditRequest("",NewRequestBody,"Save");
          if(this.selectedTableName=="CrossBorderDocumentTypes"){NewRequestBody=this.loadApprovalRequest(event[1], "Save",auditRequestBody);}
          if(event[3].toString()=="RecordAlreadyExist"){
            this.UpdateContentDetails(appendURL,NewRequestBody,event[2]+"Success");
          }
          else{
          if(this.selectedTableName=="CrossBorderTemplates"){
            this.UploadDocumentDetails(appendURL,NewRequestBody,event);
          }
          else{
          this.SaveContentDetails(appendURL,NewRequestBody);
          }
          }
          this.AuditDetails(auditRequestBody);
        }
        else {
          this.isFilterRecordUpdated=true;//Added to update grid, after edit filtered records
          let oldRequestBody = this.prepareRequest(null,event[0], "Delete");
          let NewRequestBody = this.prepareRequest(oldRequestBody,event[1], "Update"); 
          let auditRequestBody=this.PrepareAuditRequest(oldRequestBody,NewRequestBody,"Update");
          let UpdateRequestBody= this.prepareUpdateRequest(oldRequestBody,event[1],"Update");
          if(this.selectedTableName=="CrossBorderDocumentTypes"){
            NewRequestBody=this.loadApprovalRequest(event[1], "Update",auditRequestBody);
          }
          else if(this.selectedTableName=="MyDeal"||this.selectedTableName=="NewDealSetupForm"){
            this.UpdateContentDetails(appendURL,UpdateRequestBody,"UpdateSuccess");
          }
        
          else{
          this.UpdateContentDetails(appendURL,NewRequestBody,"UpdateSuccess");
          }
          //add audit in content management page
          this.AuditDetails(auditRequestBody);
          if(this.selectedTableName=="CrossBorderApprovalReviewStatus")
          {// add audit in crossborder form
          let auditRequestBodyForForm=this.PrepareAuditRequestForForm(oldRequestBody,NewRequestBody,"Update");
          this.AuditInForm(auditRequestBodyForForm);
          }
        }   
      }
      
    }
    else 
    {
      this.duplicateUserRestrictFlag = false;
      this.spinnerService.hideAutoSaveSpinner();
      this.showMsgs('Content', 'Info');
    }
  }
  PrepareAuditRequestForForm(oldData:any,newData:any,operation:string) 
  {
    let RequestBodyList=[];
    const userName = localStorage.getItem("AuditorFullName");
    const emailId = localStorage.getItem("userProfileId");
    if(operation=="Delete")
    {
          //for delete operation          
          let RequestBody =
          {
            ProjectName : "",
            FormID : newData.FormID,
            CreatedOn : new Date().toLocaleString(), // need to format this string.
            DocumentType : newData.DocumentType,
            Action : "Deleted Review Statuses",
            ActionDetails : "",
            UserName : userName,
            Email : (newData.ModifiedBy==null||newData.ModifiedBy=="")?emailId:newData.ModifiedBy  
          }
          RequestBodyList.push(RequestBody);          
          return RequestBodyList;
    }
    else
    {
      // for edit operation
      //check if the reviewer statuses are changed and add them for the request body for auditing      
      if (oldData.BusinessReviewStatus!=newData.BusinessReviewStatus)
      {
        
        let RequestBody =
        {
          ProjectName : "",
          FormID : newData.FormID,
          CreatedOn : new Date().toLocaleString(), // need to format this string.
          DocumentType : newData.DocumentType,
          Action : newData.BusinessReviewStatus + " : " + "Business",
          ActionDetails : "",
          UserName : newData.BusinessReviewer,
          Email : newData.ModifiedBy  
        }
        RequestBodyList.push(RequestBody);
      } 
      if (oldData.QRMReviewStatus!=newData.QRMReviewStatus)
      {
        
        let RequestBody =
        {
          ProjectName : "",
          FormID : newData.FormID,
          CreatedOn : new Date().toLocaleString(), // need to format this string.
          DocumentType : newData.DocumentType,
          Action : newData.QRMReviewStatus + " : " + "QRM",
          ActionDetails : "",
          UserName : newData.QRMReviewer,
          Email : newData.ModifiedBy  
        }
        RequestBodyList.push(RequestBody);
      } 
      if (oldData.ComplianceReviewStatus!=newData.ComplianceReviewStatus)
      {
        
        let RequestBody =
        {
          ProjectName : "",
          FormID : newData.FormID,
          CreatedOn : new Date().toLocaleString(), // need to format this string.
          DocumentType : newData.DocumentType,
          Action : newData.ComplianceReviewStatus + " : " + "Compliance",
          ActionDetails : "",
          UserName : newData.ComplianceReviewer,
          Email : newData.ModifiedBy  
        }
        RequestBodyList.push(RequestBody);
      } 
    return RequestBodyList;

    }

  }
  loadApprovalRequest(event:any,operation:any,auditRequest:any){
     let RequestBody={};
     const userEmail = localStorage.getItem("userProfileId");
     if(operation=="Delete"){
      return RequestBody = {
        ID: event.ID,
        ModifiedBy: userEmail,
        CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
        isActive: operation == "Delete" ? "No" : "Yes",
        DocumentTypeOrder: event.DocumentTypeOrder.toString(),
        DocumentTypeId: event.DocumentTypeOrder.toString(),
        DocumentTypeTitle: event.DocumentTypeTitle,
        Required: event.Required,
        Guidance: event.Guidance,
        BusinessReviewApplies: event.BusinessReviewApplies,
        QRMReviewApplies: event.QRMReviewApplies,
        ComplianceReviewApplies: event.ComplianceReviewApplies,
        RequiresFinalVersion: event.RequiresFinalVersion,
        DependentDocumentTypes: event.DependentDocumentTypes,
        isApproved:operation == "Approved" ? "Yes" : "No",
        isRejected:operation == "Rejected" ? "Yes" : "No",
        VerificationDescription: auditRequest.Description
      };
     }
     else{
    return  RequestBody = {
      ID: event.ID,
      ModifiedBy: userEmail,
      CreatedBy: event.CreatedBy == "" ? userEmail : event.CreatedBy,
      isActive: operation == "Delete" ? "No" : "Yes",
      DocumentTypeOrder: event.DocumentTypeOrder.toString(),
      DocumentTypeId: event.DocumentTypeOrder.toString(),
      DocumentTypeTitle: event.DocumentTypeTitle,
      Required: (event.Required == true ? "TRUE" : "FALSE"),
      Guidance: event.Guidance,
      BusinessReviewApplies: (event.BusinessReviewApplies == true ? "TRUE" : "FALSE"),
      QRMReviewApplies: (event.QRMReviewApplies == true ? "TRUE" : "FALSE"),
      ComplianceReviewApplies: (event.ComplianceReviewApplies == true ? "TRUE" : "FALSE"),
      RequiresFinalVersion: (event.RequiresFinalVersion == true ? "TRUE" : "FALSE"),
      DependentDocumentTypes: event.DependentDocumentTypes,
      isApproved:operation == "Approved" ? "Yes" : "No",
      isRejected:operation == "Rejected" ? "Yes" : "No",
      VerificationDescription: auditRequest.Description,
      EngagementName: event.EngagementName,
      Series24: event.Series24,
      WbsCode:event.WbsCode,
      SwiftCode:event.SwiftCode,
      LeadClientServicePartner:event.LeadClientServicePartner,
    };
  }
  }
  UploadDocumentDetails(appendURL:any,ResultRequestBody:any,event:any){
      //The below API call only for CrossBorderTemplates to generate ID for Upload
       ResultRequestBody.ID==""?this.SaveContentDetailsForUpload(appendURL,ResultRequestBody,event):this.UploadDocumentData(appendURL,ResultRequestBody,event);  
  }
  UploadDocumentData(appendURL:any,ResultRequestBody:any,event:any){
    if(event[4]!=undefined){
    let uploadRequest=this.uploadRequest(ResultRequestBody,event[4]);
    this.contentService.ContentUploadDetails(uploadRequest).subscribe(
      {
        next: (result: any) => {
          this.SavePreSignedUrlData(appendURL,ResultRequestBody,event,result);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          this.duplicateUserRestrictFlag = false;
          console.log("Error" + err)
        }
      })
    }
    else{
      if(ResultRequestBody.ID!=""&&ResultRequestBody.ID!=null&&ResultRequestBody.ID!=undefined)
      this.UpdateContentDetails(appendURL,ResultRequestBody,event[2]+"Success");
      else
      this.SaveContentDetails(appendURL,ResultRequestBody);
    }
  }
  SavePreSignedUrlData(appendURL:any,ResultRequestBody:any,event:any,result:any){
    this.contentService.ContentUploadPresignedURLDetails(result,event[4]).subscribe(
      {
        next: (result: any) => {
          if(ResultRequestBody.ID!=""&&ResultRequestBody.ID!=null&&ResultRequestBody.ID!=undefined)
          this.UpdateContentDetails(appendURL,ResultRequestBody,event[2]+"Success");
          else
          this.SaveContentDetails(appendURL,ResultRequestBody);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          this.duplicateUserRestrictFlag = false;
          console.log("Error" + err)
          this.showMsgs("Content", "PresignedURL");
        }
      })
  }
  DeleteContentDetails(appendURL:any,partitionKeyValue: any,msg:string,auditRequestBodyForForm:any)
  {
    this.contentService.DeleteContentDetails(appendURL, partitionKeyValue).subscribe(
      {
        next: (result: any) => {        
          this.getSelectedTableData(this.selectedTableName);
          setTimeout(() => {
            this.showMsgs("Content", msg);
            this.AuditInForm(auditRequestBodyForForm);
          }, 700);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          this.duplicateUserRestrictFlag = false;
          console.log("Error" + err);
          msg=="UpdateSuccess"?this.showMsgs("Content","UpdateError"):this.showMsgs("Content","DeleteError");
        }
      });
  }
  DeleteAdditionalDocType(appendURL:any,partitionKeyValue: any,documentType:string,formID:string,msg:string,auditRequestBody:any)
  {
    this.contentService.DeleteAdditionalDocType(appendURL, partitionKeyValue,documentType,formID).subscribe(
      {
        next: (result: any) => {        
          this.getSelectedTableData(this.selectedTableName);
          setTimeout(() => {
            this.showMsgs("Content", msg);
            this.AuditDetails(auditRequestBody);
          }, 700);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          this.duplicateUserRestrictFlag = false;
          if (err.status == 202)
          {
            this.captureDeleteAuditHistoryDocType = false;
            this.showMsgs("Content", "DeleteError",err.error.text);
          }
          else
          {
            console.log("Error" + err);
            msg=="UpdateSuccess"?this.showMsgs("Content","UpdateError"):this.showMsgs("Content","DeleteError");
          }
        }
      });
  }
    UpdateContentDetails(appendURL:any,ResultRequestBody:any,msg:string){
    this.contentService.UpdateContentDetails(appendURL, ResultRequestBody).subscribe(
      {
        next: (result: any) => {        
          this.getSelectedTableData(this.selectedTableName);
          setTimeout(() => {
            this.showMsgs("Content", msg);
          }, 700);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          this.duplicateUserRestrictFlag = false;
          console.log("Error" + err);
          msg=="UpdateSuccess"?this.showMsgs("Content","UpdateError"):this.showMsgs("Content","DeleteError");
        }
      })
  }

  SaveContentDetails(appendURL:any,NewRequestBody:any){
    this.contentService.AddContentDetails(appendURL, NewRequestBody).subscribe(
      {
        next: (result: any) => {        
          this.getSelectedTableData(this.selectedTableName);
          setTimeout(() => {
            this.showMsgs("Content", "SaveSuccess");
          }, 700);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          this.duplicateUserRestrictFlag = false;
          console.log("Error" + err)
          this.showMsgs("Content","SuccessError");
        }
      })
  }
  SaveContentDetailsForUpload(appendURL:any,NewRequestBody:any,event:any){
    this.contentService.AddContentDetails(appendURL, NewRequestBody).subscribe(
      {
        next: (result: any) => {
          NewRequestBody.ID=result.ID;
         this.UploadDocumentData(appendURL,NewRequestBody,event);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          this.duplicateUserRestrictFlag = false;
          console.log("Error" + err)
        }
      })
  }
  CloseContentDetails(event: any) {
    this.isAddingUser = false;
    this.duplicateUserRestrictFlag = false;
  }
  CloseAuditDetails(event: any) {
    this.isAddingUser = false;
    this.duplicateUserRestrictFlag = false;
  }
  addNotifyDetails(event: any) {
    this.isAddingUser = false;
  }
  uploadRequest(event:any,file:any){
   let RequestBody={
    FormType:"CrossBorder",
    FormID:"",
    FileName:file.name,
    CurrentAttachmentEnviroment:"CrossBorderTemplates"      
   };
    return RequestBody; 
  }
  activateConfirmDetails(event: any) {
    this.isAddingUser = false;
    if (event) {
      let appendedURL=this.contentService.getAppendURL(this.selectedTableName);
      let activateData=this.prepareActivateRequest(this.activateRecord);
      let auditRequestBody=this.PrepareAuditRequest("",activateData,"ActivateForm");       
      this.spinnerService.showAutoSaveSpinner();
      this.UpdateContentDetails(appendedURL,activateData,"ActivateFormSuccess");  
      this.AuditDetails(auditRequestBody);
      
    }
  }
  deleteConfirmDetails(event: any) {
    this.isAddingUser = false;
    if (event.isOkayClicked) {
      if(this.showDynamicTable)
      {
        let appendedURL=this.contentService.getAppendURL(this.selectedTableName);
        let deleteData=this.prepareDynamicRequest("",this.deleteRecord,"Delete");
        let auditRequestBody=this.PrepareDynamicAuditRequest("",deleteData,"Delete");
        this.spinnerService.showAutoSaveSpinner();
        let UpdateRequestBody= this.prepareDynamicUpdateRequest(null,deleteData,"Delete",auditRequestBody);
        this.UpdateContentDetails(appendedURL,UpdateRequestBody,"DeleteSuccess");
        this.AuditDetails(auditRequestBody);
        //need to add this on need basis
        //let auditRequestBodyForForm=this.PrepareAuditRequestForForm(null,deleteData,"Delete");  
      }
      else
      {   
        //old logic following static approach    
      let appendedURL=this.contentService.getAppendURL(this.selectedTableName);
      let deleteData=this.prepareDeleteRequest(this.deleteRecord,"Delete");
      let auditRequestBody=this.PrepareAuditRequest("",deleteData,"Delete");
      let auditRequestBodyForForm=this.PrepareAuditRequestForForm(null,deleteData,"Delete");        
      if(this.selectedTableName=="CrossBorderDocumentTypes"){deleteData=this.loadApprovalRequest(this.deleteRecord, "Delete",auditRequestBody);}
      this.spinnerService.showAutoSaveSpinner();

      if(this.selectedTableName=="CrossBorderTemplates"){
        this.isDocumentUploadDeleted(appendedURL,deleteData,"Delete");       
      }
      else if(this.selectedTableName=="CrossBorderApprovalReviewStatus"){
        this.DeleteContentDetails(appendedURL,deleteData?.ID,"DeleteSuccess",auditRequestBodyForForm);        
      }
      else if (this.selectedTableName=="CrossBorderAdditionalDocumentTypes")
      {
        this.DeleteAdditionalDocType(appendedURL,deleteData?.ID,deleteData?.DocumentType,deleteData?.FormID,"DeleteSuccess",auditRequestBody);
      }
      else{
        this.UpdateContentDetails(appendedURL,deleteData,"DeleteSuccess");
      }
      if(this.selectedTableName!="CrossBorderAdditionalDocumentTypes")
      {
        this.AuditDetails(auditRequestBody);
      }
      }
   }
  }
  isDocumentUploadDeleted(appendedURL:any,deleteData:any,msg:any){
this.contentService.ContentDeleteUploadDetails(deleteData).subscribe(
  {
    next: (result: any) => {
      msg=="Delete"?this.deleteUploadedFile(appendedURL,deleteData):null;
    },
    error: (err: any) => {
      if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
      this.spinnerService.hideAutoSaveSpinner();
      console.log("Error" + err)
      this.showMsgs("DeleteOperation", "Error");
    }
  });
  
  }
  deleteUploadedFile(appendedURL:any,deleteData:any){
    this.contentService.UpdateContentDetails(appendedURL, deleteData).subscribe(
      {
        next: (result: any) => {
          this.getSelectedTableData(this.selectedTableName);
          setTimeout(() => {
            this.showMsgs("Content", "DeleteSuccess");
          }, 700);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err)
          this.showMsgs("DeleteOperation", "Error");
        }
      })
  }
  //custom logic
  onChangePaginationValues(paginationValues: PaginationValues) {
    this.commonHelperService.state.page = paginationValues.page;
    this.commonHelperService.state.pageSize = paginationValues.pageSize;
    this._setUsersToCurrentPage();
    this.setMultiSelectedCheckBoxPages(paginationValues.page, paginationValues.pageSize);
    //Venu Commented
    //this.setMultiSelectedCheckBoxPages(paginationValues.page,paginationValues.pageSize);
    // When selected the multiple selected text box,
    //  only the forms specific to the particular page must be selected,
    //   even if the items per page increased, 
    //   it should select only the forms in that relevant page.
    //    If the user navigates to the other page , 
    //    no forms should have to be selected until that page multi select is clicked .
    //     Until the relevant page is not deselected , 
    //     it should remain selected only.
    //      The following selected files should be able to exported in the excel sheet

  }
  private setMultiSelectedCheckBoxPages(pageNumber: any, pageSize: any) {

    if (this.contentManagementPageDetails.length > 0) {
      if (this.contentManagementPageDetails.find(s => s.PageSize != pageSize && s.IsMultiSelect == true)) {
        this.contentManagementExportToExcel.length = 0;
      }
      this.contentManagementPageDetails.forEach(s => {
        if (s.IsMultiSelect == true && s.PageSize != pageSize) {
          s.PageSize = pageSize
          const page = s.PageNumber;
          const pageSize1 = s.PageSize;
          this.contentManagementPerPage =
            this.contentManagements.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
          this.contentManagementPerPage.forEach(Item => {
            if (!this.contentManagementExportToExcel.find(e => e.ID == Item.ID)) {
              let contentItem = {
                ID: Item.ID,
                Representative: Item.Representative,
                Modified: Item.Modified,
                ModifiedBy: Item.ModifiedBy


              };
              this.contentManagementExportToExcel.push(contentItem);
            }
          });
        }

      });
      this._setUsersToCurrentPage();
    }
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement | null;
    // checkbox.checked = false;
    if (this.contentManagements.find(e => e.Status == true)) {
      this.contentManagementPageDetails.forEach(v => {
        if (v.IsMultiSelect == true && v.PageNumber == pageNumber) {
          //checkbox.checked = true;
        }
      });
    }

  }
  private _setUsersToCurrentPage() {
    const page = this.commonHelperService.state.page;
    const pageSize = this.commonHelperService.state.pageSize;
    this.contentManagementPerPage =
      this.contentManagements.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
      this.contentManagementPerPage.forEach(item => {
        if (item.Value === 'true') {
          item.Value = item.Value.toUpperCase();
        } else if (item.Value === 'false') {
          item.Value = item.Value.toUpperCase();
        }
      });
  }
  setContentManagement(
    contentDashboardList: Array<ContentManagementItem>,
    pageSizeListValues: Array<any>) {
    pageSizeListValues = [{ id: 0, value: "All" },{ id: 1, value: 10 }, { id: 2, value: 25 }, { id: 3, value: 50 }, { id: 4, value: 100 }];
    let filteredContentsList = this._filterClosedDeals(contentDashboardList);
    this.clearAllFilters(true);
    // this.initialContentDashboardList = reportDashboardList;
    this.setSortBasedOnTables();
    this.commonHelperService.state.page = this.commonHelperService.state.page!=1?this.commonHelperService.state.page:1;
    this.commonHelperService.state.pageSize = this.commonHelperService.state.pageSize!=25?this.commonHelperService.state.pageSize:25;
    const sortData = this.commonHelperService.state.sortData;
    const filterData = this.commonHelperService.state.filterDataList;
    filteredContentsList = this._getEngagementsMatchsFilterCriteria(filteredContentsList, filterData);
    this.contentManagements = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, filteredContentsList.length!=contentDashboardList.length?filteredContentsList:contentDashboardList);
    this._updateSortArrow(sortData.sortColumn, sortData.sortDirection);
    this._setUsersToCurrentPage();
    this._setPaginationValues(pageSizeListValues);
    this.duplicateUserRestrictFlag = false;
    this.pageDropdownDisable = (filteredContentsList.length!=contentDashboardList.length?filteredContentsList.length:contentDashboardList.length) > 0 ? false : true;
    this.spinnerService.hideAutoSaveSpinner();
  }
  setSortBasedOnTables(){
    if( this.commonHelperService.state.sortData["sortColumn"]==""&& this.commonHelperService.state.sortData["sortDirection"]==""){
      if(this.selectedTableName=="CrossBorderIndustries"){
        this.commonHelperService.state.sortData = { sortColumn: 'Industry', sortDirection: 'asc' };
      }
      else if(this.selectedTableName=="CrossBorderCountries"){
        this.commonHelperService.state.sortData = { sortColumn: 'Title', sortDirection: 'asc' };
      }
      else if(this.selectedTableName=="CrossBorderAdditionalDocumentTypes"){
        this.commonHelperService.state.sortData = { sortColumn: 'CreatedDate', sortDirection: 'desc' };
      }
      else if(this.selectedTableName=="CrossBorderApprovalReviewStatus"){
        this.commonHelperService.state.sortData = { sortColumn: 'ModifiedDate', sortDirection: 'desc' };
      }
      else{
        this.commonHelperService.state.sortData = { sortColumn: 'Modified', sortDirection: 'desc' };
     }
  }
  }
  private _getEngagementsMatchsFilterCriteria(
    ContentDashboard: Array<ContentManagementItem>,
    filterList: Array<ContentTableFilterItem>): Array<ContentManagementItem> {
    let newContentDashboardList: Array<ContentManagementItem> = [];
    if (filterList.length > 0) {
      ContentDashboard.forEach(eng => {
        if (this._isEngagementMatchFilterCriteria(eng, filterList)) {
          newContentDashboardList.push(eng);
        }
      });
    } else {
      newContentDashboardList = ContentDashboard;
    }
    return newContentDashboardList;
  }
  private _isEngagementMatchFilterCriteria(
    ContentDashboard: any,
    filterList: Array<ContentTableFilterItem>): boolean {
    let match = true;
    filterList.forEach(filter => {
      const field = filter.field;
      const values = filter.valueList;
      if (values.length && !values.find((v: any) => v === ContentDashboard[field].toString())) {

        match = false;
        // return false;
      }
    });
    return match;
  }
  private _filterClosedDeals(contentsList: Array<ContentManagementItem>): Array<ContentManagementItem> {
    let filteredcontentsList;
    filteredcontentsList = contentsList;

    return filteredcontentsList;
  }
  private _updateSortArrow(sortColumn: string, sortDirection: SortDirection) {
    this._resetSortIcons(sortColumn);
    //this._showSortDirectionArrow(sortColumn, sortDirection);
  }
  private _setPaginationValues(values: any) {

    this.pageSizeListValues = values.map((element: any) => {
      const parseValue = element.value.toString();
      const dropdownItem: DropdownItem = {
        key:'',
        value:''
      };
      if(parseValue=="All"){
         dropdownItem.key=parseValue,
         dropdownItem.value=parseValue+' Items'
      
      }
      else{
        dropdownItem.key=parseValue,
        dropdownItem.value=parseValue+' items per page'
    }
      return dropdownItem;
    });
    this.selectedPageSize =  this.commonHelperService.state.pageSize!=CommonHelperService.pageSizeDefault?this.commonHelperService.state.pageSize:CommonHelperService.pageSizeDefault;

  }
  onPageSizeValueChange(event: any) {
    if(this.selectedPageSize=="All")
    {
    const values: PaginationValues = {
      page: this.commonHelperService.state.page,
      pageSize: parseInt(this.initialContentManagementList.length.toString())
    };
    this.onChangePaginationValues(values);
  }
  else{
    const values: PaginationValues = {
      page: this.commonHelperService.state.page,
      pageSize: parseInt(this.selectedPageSize)
    };
    this.onChangePaginationValues(values);
  }
   
  }
  sortTable({ column }: SortEvent) {
    this.contentManagementPageDetails.length = 0;
    const newDirection = this._getSortDirection(column);
    this._resetSortIcons(column);
    this.contentManagements = this.commonHelperService.sort(column, newDirection, this.contentManagements);
    this._setUsersToCurrentPage();
    this._setSortOptions(column, newDirection);
    this.showDisable = true;
    this.contentManagementExportToExcel.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement | null;
    //checkbox.checked = false;

  }
  private _getSortDirection(column: string): SortDirection {
    const header = this._getHeader(column);
    return header.direction === 'asc' ? 'desc' : 'asc';
  }
  isFilterApply(field: string) {
    let filterApply = false;
    this.filterList.forEach(filter => {
      if (filter.field === field && filter.valueList.length) {
        filterApply = true;
      }
    });
    return filterApply;
  }
  clearFilters() {
  // this.getSelectedTableData(this.selectedTableName);
   this.clearFiltersForGridUpdate();
  }
  clearAllFilters(FromSearchForm: any) {
    this._resetSortIcons();
    this.filterList = [];       
    this.targetField = "";
    this.contentManagementExportToExcel.length = 0;
    this.contentManagementPageDetails.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement | null;
    // checkbox.checked = false;
    this.showDisable = true;

    if (FromSearchForm == true) {
      this.commonHelperService.searchTerm = '';
    }
   this.setSortBasedOnTables();

    this.commonHelperService.filterDataList = [];
    this.commonHelperService.state.page = 1;    
    this.commonHelperService.state.pageSize = 25;
    this.selectedPageSize = CommonHelperService.pageSizeDefault;
    // this.showDealClosedState.emit(false);
    // this.currentEngagementList = this._filterClosedDeals(false, this.initialEngagementsList);
    this.contentManagements = this._filterClosedDeals(this.initialContentManagementList);
    this._updateSortArrow(this.commonHelperService.state.sortData.sortColumn, this.commonHelperService.state.sortData.sortDirection);
    this._setUsersToCurrentPage();
    // Sort the contentManagements array based on the Modified property
    if(this.selectedFormType != "Cross Border")
    this.contentManagements.sort((a, b) => new Date(b.Modified).getTime() - new Date(a.Modified).getTime());
  
  }
  private _updateUsersList() {
    this.contentManagements = this._isSomeFilterApply() ?
      this._getUserMatchsFilterCriteria(this.initialContentManagementList, this.filterList) :
      this.initialContentManagementList;
    const sortData = this.commonHelperService.state.sortData;
    this.contentManagements = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, this.contentManagements);
    this._setUsersToCurrentPage();
  }
  openFilterPopover(field: string) {
    this.targetField = field;
    this.targetFilter = this._getFilter(field);
    this.fieldOptionValues = this._mapUserListWithFilterList(this.initialContentManagementList, field);
  }
  report: any;
  private _getFilter(field: string): any {

    // if(this.filterList.length){
    //   this.report=this.filterList.filter(f => f.field === field)[0];
    // }
    // return this.report;
    return this.filterList.length ? this.filterList.filter(f => f.field === field)[0] : null;
  }
  private _mapUserListWithFilterList(
    listContents: Array<ContentManagementItem>,
    field: string): Array<string> {
    const sortData = this.commonHelperService.state.sortData;
    const arrayOptionsList: any = [];
    listContents.forEach((content: any) => {
      const value = content[field];
      if (this._isValidValue(arrayOptionsList, value, field, listContents)) {
        arrayOptionsList.push(content[field]);
      }
    });

    if(field==="ID" || field==="FormID"){
      if(sortData.sortColumn===field && sortData.sortDirection==="asc")
        return arrayOptionsList.sort((a: number ,b: number) =>  a-b);
      return arrayOptionsList.sort((a: number ,b: number) =>  b-a);
    }

    if(field==="Modified" || field==="Created"){
      if(sortData.sortColumn===field && sortData.sortDirection==='asc')
        return arrayOptionsList.sort((a: string ,b: string) => new Date(a).getTime()-new Date(b).getTime());
      return arrayOptionsList.sort((a: string ,b: string) => new Date(b).getTime()-new Date(a).getTime());
    }

    if(sortData.sortColumn===field && sortData.sortDirection==="desc")
      return arrayOptionsList.sort((a : string, b : string) => b.toUpperCase().localeCompare(a.toUpperCase()));;
    return arrayOptionsList.sort((a : string, b : string) => a.toUpperCase().localeCompare(b.toUpperCase()));
  }
  private _isValidValue(
    arrayOptionsList: Array<string>,
    value: string,
    field: string,
    listcontents: Array<ContentManagementItem>): boolean {
    return (value !== '' && value !== null &&
      !this._inArray(arrayOptionsList, value) &&
      this._existUserForValue(listcontents, field, value));
  }
  private _inArray(optionFields: Array<string>, option: string): boolean {
    return Boolean(optionFields.find(o => o === option));
  }
  private _existUserForValue(
    listContents: Array<ContentManagementItem>,
    field: string,
    value: string): boolean {
    const filterArrayClone = [...this.filterList];
    const filterRemovedCurrentTarget = filterArrayClone.filter(f => f.field !== field);
    const filteredValues = this._getUserMatchsFilterCriteria(listContents, filterRemovedCurrentTarget);
    const searchValue = filteredValues.find((v: any) => v[field] === value) ? true : false;
    return searchValue;
  }
  private _getUserMatchsFilterCriteria(
    contents: Array<ContentManagementItem>,
    filterList: Array<ContentManagementTableFilterItem>): Array<ContentManagementItem> {
    let newContentList: Array<ContentManagementItem> = [];
    if (filterList.length > 0) {
      contents.forEach(rep => {
        if (this._isUserMatchFilterCriteria(rep, filterList)) {
          newContentList.push(rep);

        }
      });
    } else {
      newContentList = contents;
    }
    return newContentList;
  }
  private _isUserMatchFilterCriteria(
    contents: any,
    filterList: Array<ContentManagementTableFilterItem>): boolean {
    let match = true;
    filterList.forEach((filter: any) => {
      const field = filter.field;
      const values = filter.valueList;
      if (values.length && !values.find((v: any) => v === contents[field].toString())) {

        match = false;
        // return false;
      }
    });
    return match;
  }
  sortTableOnFilterModal(column: string, direction: SortDirection) {
    this._resetSortIcons(column);
    this._showSortDirectionArrow(column, direction);
    this._setUsersToCurrentPage();
    this.contentManagements = this.commonHelperService.sort(column, direction, this.contentManagements);
    this._setSortOptions(column, direction);
  }
  private _setSortOptions(column: string, direction: SortDirection) {
    const sortItem: ContentManagementSort = {
      sortColumn: column,
      sortDirection: direction
    };
    this.commonHelperService.state.sortData = sortItem;
  }
  private _resetSortIcons(column?: string) {
    this.headers.forEach(header => {
      if ((column && header.sortable !== column) || !column) {
        header.direction = '';
      }
    });
  }
  private _showSortDirectionArrow(sortColumn: string, sortDirection: SortDirection) {
    const header = this._getHeader(sortColumn);
    header.direction = sortDirection;
    header.rotate();
  }
  isChecked(value: string): boolean {
    let checked = false;
    if (this.targetFilter) {
      this.targetFilter.valueList.forEach((v: any) => {
        if (v === value.toString()) {
          checked = true;
        }
      });
    }
    return checked;
  }
  private _getHeader(column: string): NgbdSortableHeaderDirective {
    return this.headers.filter(h => h.sortable === column)[0];
  }
  onClickFilterCheckbox(event: any) {
    const checkbox = event.currentTarget;
    const valueSelected = checkbox.id;
    const isChecked = checkbox.checked;

    this._updateFilterList(valueSelected, isChecked);
    this._updateUserList();
  }
  private _updateUserList() {
    this.contentManagements = this._isSomeFilterApply() ?
      this._getUserMatchsFilterCriteria(this.initialContentManagementList, this.filterList) :
      this.initialContentManagementList;
     
    const sortData = this.commonHelperService.state.sortData;
    this.contentManagements = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, this.contentManagements);
    this._setUsersToCurrentPage();
    if(this.contentManagements.length==0&&(this.isFilterRecordDeleted||this.isFilterRecordUpdated)){this.clearFiltersForGridUpdate();}//Added for updating grid, deletion after applying filter
  }
  private _isSomeFilterApply() {
    let filterApply = false;
    this.filterList.forEach(filter => {
      if (filter.valueList.length) {
        filterApply = true;
      }
    });
    return filterApply;
  }

  private _updateFilterList(valueSelected: string, isChecked: string) {
    const existFilter = this._getFilter(this.targetField);
    if (isChecked) {
      this._addFilterItem(existFilter, valueSelected);
    } else {
      this._removeFilterItem(existFilter, valueSelected);
    }
    this.showDisable = true;
    this.contentManagementExportToExcel.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement | null;
    //checkbox.checked = false;

    this.contentManagementPageDetails.length = 0
    this._setTargetFilter(existFilter);
  }

  private _setTargetFilter(existFilter: ContentManagementTableFilterItem) {
    this.targetFilter = existFilter;
    this.commonHelperService.filterDataList = this.filterList;

  }
  private _addFilterItem(existFilter: ContentManagementTableFilterItem, valueSelected: string) {
    if (existFilter) {
      existFilter.valueList.push(valueSelected);
    } else {
      const newFilter = this._createFilter(valueSelected);
      this.filterList.push(newFilter);
      existFilter = newFilter;
    }
  }

  private _removeFilterItem(existFilter: ContentManagementTableFilterItem, valueSelected: string) {
    if (existFilter) {
      existFilter.valueList = this._removeFilter(existFilter.valueList, valueSelected);
    }
  }
  private _removeFilter(valueList: Array<string>, valueSelected: string): Array<string> {
    return valueList.filter(v => v !== valueSelected);
  }
  private _createFilter(valueSelected: string): ContentManagementTableFilterItem {
    const newFilter = {
      field: this.targetField,
      valueList: [valueSelected]
    };
    return newFilter;
  }
  loadXML(formType:any) {
    this._http.get('/assets/XML/ContentManagement_DDL_Data.xml',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'text/xml')
          .append('Access-Control-Allow-Methods', 'GET')
          .append('Access-Control-Allow-Origin', '*')
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
        responseType: 'text'
      })
      .subscribe((data) => {
        this.parseXML(data,formType)
          .then((dt) => {
            this.loadDDLValues(dt);
          });
      });
  }
  loadDDLValues(dt: any) {
    this.loadinitialDropdownData = dt;
   // this.formTypes = dt.xmlFormType;
  }
  parseXML(data: any,formType:any) {
    return new Promise(resolve => {
      var item: string | number,
        masterArray: any = {
          xmlFormType: [],
          xmlContentType: [],
          xmlMasterTable: [],
          xmlTransactionalTable: [],
          xmlConfigTable: []
        },
        parser = new xml2js.Parser(
          {
            trim: true,
            explicitArray: true
          });
      parser.parseString(data, function (err: any, result: any) {
        let obj;
        switch (formType) {
          case "Cross Border":
            obj = result.DCF_Modernization.CrossBorder[0];
            break;
          case "Deal WBS":
            obj = result.DCF_Modernization.DealWBSForm[0];
            break;
          case "My Deal":
            obj=result.DCF_Modernization.MyDealForm[0];
            break;
          case "Closing Checklist":
            obj=result.DCF_Modernization.ClosingCheckListForm[0];
            break;
          default:
            obj = result.DCF_Modernization.NewDealForm[0];
            break;
        }
        var k: number = 0;
        obj.FormType[0].Type.forEach((item: any) => {
          masterArray.xmlFormType.push({
            ID: k,
            Name: item
          });
          k++;
        });
        k = 0;
        obj.ContentType[0].Type.forEach((item: any) => {
          masterArray.xmlContentType.push({
            ID: k,
            Name: item
          });
          k++;
        });
        k = 0;
        obj.Tables[0].MasterTables[0].Table.forEach((item: any) => {
          masterArray.xmlMasterTable.push({
            ID: k,
            TableName: item
          });
          k++;
        });
        k = 0;
        if('TransactionalTables' in obj.Tables[0])
        {
          obj.Tables[0].TransactionalTables[0].Table.forEach((item: any) => {
            masterArray.xmlTransactionalTable.push({
              ID: k,
              TableName: item
            });
            k++;
          });
        }        
        k = 0;
        if('Config' in obj.Tables[0])
        {
          obj.Tables[0].Config[0].Table.forEach((item: any) => {
            masterArray.xmlConfigTable.push({
              ID: k,
              TableName: item
            });
            k++;
          });
        }       
        resolve(masterArray);
      });
    });
  }
 
  handleEmptyData(data: any) {
    let resData:any=[];
    data.forEach((items:any)=>{
   if(items.isActive!="No"&&this.selectedTableName!="CrossBorderForm"){
    items.Modified=items.Modified==null?"-":this.dateService._FormatDateCCLForContent(items.Modified);
    items.Created=items.Created==null?"-":this.dateService._FormatDate(items.Created);
     for(let item in items){
     if((items[item]==""||items[item]==undefined||items[item]==null)&&(items[item]!=true&&items[item]!=false)){
       items[item]="-";
     }
     }
     
     if(this.selectedTableName == "NewDealSetupForm")
     {
        if(items.Status != "-"){
          resData.push(items);
        };
     }
     else
     {
       resData.push(items);
     }     
    }
    else{
        items.Modified=items.Modified==null?"-":this.dateService._FormatDate(items.Modified);
        items.Created=items.Created==null?"-":this.dateService._FormatDate(items.Created);
         for(let item in items){
         if((items[item]==""||items[item]==undefined||items[item]==null)&&(items[item]!=true&&items[item]!=false)){
           items[item]="-";
         }
         }
         resData.push(items);
        }
    
    });
    return resData;
  }
  
  clearFiltersForGridUpdate(){
    if (this.targetFilter) {
      this.targetFilter.valueList = [];
      this._updateUsersList();
      this.showDisable = true;
      this.contentManagementExportToExcel.length = 0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
      //checkbox.checked = false;
      this._resetSortIcons();
      this.isFilterRecordDeleted=false;
      this.isFilterRecordUpdated=false;
      this.contentManagementPageDetails.length = 0
    }
  }
  getSelectedTableData(data: any) {
      let appendeURL = this.contentService.getAppendURL(data);
      let queryStringParam = '';
      if(data == "AppConfigurations")
      {
        queryStringParam = "?formType="+this.selectedFormType;
      }
      this.contentService.GetContentDetails(appendeURL,queryStringParam).subscribe(
        {
          next: (result: any) => {
            if(this.selectedTableName=="FormType"){this.formTypes=this.commonHelperService.getActiveRecords(result);}
            this.initialCompareContentManagementList=result;
            result.forEach((item: { rangeKey: any; Created: any; }) => {
              item.rangeKey = item.Created;
            });
            this.initialContentManagementList = this.handleEmptyData(result);
            if(this.selectedTableName=="CrossBorderForm")
            {
              this.initialContentManagementList.forEach((element)=>{
                element.Status = this.getActualStatus(element);
              });
            }
            this.setContentManagement(this.initialContentManagementList, this.pageSizeListValues);
          },
          error: (err: any) => {
            if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
            this.initialContentManagementList = [];
            this.setContentManagement(this.initialContentManagementList, this.pageSizeListValues); 
            this.spinnerService.hideAutoSaveSpinner();
            console.log("Error" + err)
          }
        })
      
   

  }
  EditUser(data: any) {
    if(this.selectedTableName!="CrossBorderDocumentTypes"){
      this.isAddingUser = true;
      this.addcontentpopupComponent.openPopup("Update",data);
    }
    else{
      if(data.isApproved=="Yes"||data.isRejected=="Yes"||data.isApproved=="-"||data.isRejected=="-"){
        this.isAddingUser = true;
        this.addcontentpopupComponent.openPopup("Update",data);
      }
      else{
          this.isAddingUser = true;
          this.showMsgs("Content","ITSAdminNotVerified");
      }
    }
  }
  onTableChange() {
    var filteredTables = this.tabledefinitions.Tables.filter((x:any)=>x.Name == this.selectedTableName);
    if(filteredTables!=undefined && filteredTables.length > 0)
    {
      let selectedTableDefinition = filteredTables[0];
      //list template
      this.columnHeaders = selectedTableDefinition.gridColumnHeaders;
      this.isGridEditAllowed = selectedTableDefinition.isEditAllowed;
      this.isGridDeleteAllowed = selectedTableDefinition.isDeleteAllowed;
      this.isWorkFlowRequired = selectedTableDefinition?.isWorkflowRequired;

      this.showDynamicTable = true;
      
      //edit template
      this.editTemplate = selectedTableDefinition.EditTemplate;
    }
    else
    {
      this.showDynamicTable = false;
    }
    
    this.clearFiltersForGridUpdate();
    if (this.selectedTableName != ""&&(this.selectedFormType=="Cross Border"||this.selectedFormType=="New Deal" ||
    this.selectedFormType=="Deal WBS"|| this.selectedFormType == "Closing Checklist"||this.selectedFormType=="My Deal")) {
      this.spinnerService.showAutoSaveSpinner();
      this.showTable = true;
      this.isButtonsDisplay=true;
      this.commonHelperService.state.sortData = { sortColumn: '', sortDirection: '' };//Added to apply onload dynamic sorts
      this.EnableDisableActions();
      this.getSelectedTableData(this.selectedTableName);
      //this.AuditDetails("","","Get");
    }
    else {
      this.showTable = false;
    }
  }
  checkAndGetReveiwerName(operation: string, oldData: any, newData: any, reviewerType: number) {
    
    let reviewerName:any
    const userName = localStorage.getItem("AuditorFullName");
    if(reviewerType==1)
    {
      reviewerName = operation=='Update'? (oldData.BusinessReviewStatus==newData.BusinessReviewStatus?newData.BusinessReviewer:userName):newData.BusinessReviewer ;
    }
    if(reviewerType==2)
    {
      reviewerName = operation=='Update'?(oldData.QRMReviewStatus==newData.QRMReviewStatus?newData.QRMReviewer:userName):newData.QRMReviewer;
    }
    if(reviewerType==3)
    {
      reviewerName = operation=='Update'?(oldData.ComplianceReviewStatus==newData.ComplianceReviewStatus?newData.ComplianceReviewer:userName):newData.ComplianceReviewer;      
    }    
    return reviewerName=='-'?"":reviewerName;
  }

}

