

<div
id="Notes" *ngIf="isShowComments"

class="container-fluid border container-border-color container-section">

<div class="row bg-title">
  <span  class="align-width">Notes</span>
  <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
  
</div>
<div id="notes-section" class="content-section">
      
    <div class="row rownotes" *ngIf="isShowComments">
      <div class="possition" id="comment-section" >
        <!-- <button id="arrow-comments-3" class='glyphicon comments-down-arrow comments-right-arrow  size' (click)="toggleClass = !toggleClass" [ngClass]="{' glyphicon-chevron-up ': !toggleClass,'glyphicon-chevron-down':toggleClass }"  ></button> -->
        <div><b>Comments</b></div>
        <div class="width-95 display-inline-block comment-section-3">
          <ul class="ul-no-style no-margin-no-padding review-comments-document-type-3" style="padding-top: 39px;">
            
            <li *ngFor="let item of usercomments;let i = index" class="margin-top-8 height-70 external-comment" [ngClass]="{'hidden': i>=3 && !toggleClass}" >

              <div class="display-inline-block margin-left-14 external-comments-bar-color" >
              </div>
              <div class="width-95 bottom-24 display-inline-block div-comments margin-left-8 font-size-12">
                <span class="margin-left-4"><b style="float: left;">{{item.ReturnedByUser}} {{item.Created}} </b></span>
                <div class="float-right"></div><br><span readonly="" class="textarea-comments">{{item.Comment}}</span></div>
              </li>
              
              </ul>
              <div  *ngIf="isShowComments" style="float: right;"><button id="arrow-comments-3" class='glyphicon comments-down-arrow comments-right-arrow  size' (click)="toggleClass = !toggleClass" [ngClass]="{' glyphicon-chevron-up ': !toggleClass,'glyphicon-chevron-down':toggleClass }"  ></button></div>
            </div>
            
          </div>
    </div>
     
</div>
</div> 












