import {
    SharepointMapper,
    SharepointValidatorName,
    SharepointValidator,
    SharepointMappingElement} from 'src/shared/utils/sharepoint-mapper';
import { ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from 'src/shared/custom/custom-validators';

export class ComplianceSharepointMapper extends SharepointMapper {

constructor(elements: SharepointMappingElement[] = []) {
    super(elements);
    this.elementsFormGroup = elements;
}

    // protected getValidator(sharepointValidator: SharepointValidator, formControlName: string): ValidatorFn {
    protected override getValidator(sharepointValidator: SharepointValidator, formControlName: string): ValidatorFn {
        let validator: any;

        switch (sharepointValidator.Validator) {
        case SharepointValidatorName.required:
            switch (formControlName) {
                case 'currentPPMDLead':
                validator = CustomValidators.PeoplePickerRequired;
                break;
                case 'previousSeries24':
                case 'previousPPMDLead':
                case 'counterparties':
                validator = CustomValidators.PeoplePickerDynamicTableRequired;
                break;
                case 'financeFileUpload':
                validator = CustomValidators.DocumentTypesAttachmentsRequired;
            break;
            default:
                // validator = Validators.required;
                validator = CustomValidators.InputWhithoutWhiteSpaces;
                break;
            }
            break;

        default:
            break;
        }

        return validator;
    }


}
