import { Component, OnInit, ViewChildren, QueryList, Output, EventEmitter, ViewChild  } from '@angular/core';
import { AvailableFormItem, EngagementDashboardtItem } from '../models/engagement-dashboard-item';
import { SortEvent, NgbdSortableHeaderDirective, SortDirection } from 'src/shared/directives/sorteable.directive';
import { EngagementTableService } from '../shared/services/engagement-table.service';
import { EngagementTableFilterItem } from '../models/engagement-table-filter-item';
import { EngagementStatusValues } from '../models/engagement-status-values';
import { EngagementDashboardSort } from '../models/engagement-sort';
import { PaginationValues } from 'src/shared/models/pagination-values';
import { AppSettings } from 'src/shared/app-settings';
import { PaginationComponent } from 'src/shared/components/pagination/pagination.component';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { EngagementDashboardService } from '../shared/services/engagement-dashboard.service';
import { AdminService } from 'src/app/services/admin.service';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { EngagementHealthObjectService } from 'src/app/engagement-health-object/shared/services/engagement-health-object.service';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';

@Component({
  selector: 'app-engagement-table',
  templateUrl: './engagement-table.component.html',
  styleUrls: ['./engagement-table.component.scss']
})
export class EngagementTableComponent implements OnInit {
  currentEngagementListPerPage: Array<EngagementDashboardtItem> = []
  currentEngagementList: Array<EngagementDashboardtItem> = []
  initialEngagementsList: Array<EngagementDashboardtItem> = []
  engagementHealthObjectUrl: string;
  targetField: any;
  filterApply: boolean;
  targetFilter: EngagementTableFilterItem;
  fieldOptionValues: Array<string> = [];
  filterList: Array<EngagementTableFilterItem> = [];
  pageSizeListValues: Array<any> = [];
  newDealBaseUrl:string;
  dealwbsBaseUrl:string;
  onSearchApplyOnFilteredData:Array<EngagementDashboardtItem> = [];
  SearchingResults:Array<EngagementDashboardtItem> = [];
  closingChecklistBaseUrl:string;
  pageDropdownDisable: boolean;
  selectedPageSize: any;
  searchTerm: any="";
  showClosedDeals: any;
  closingCheckListData: Array<EngagementDashboardtItem> = [];
  DealWbsData: Array<EngagementDashboardtItem> = [];
  @Output() showDealClosedState = new EventEmitter<boolean>();

  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChild('pagination', {static: false})
   pagination: PaginationComponent;
   showDisable:boolean;
  SearchingValue: any="";

  constructor(
      public engagementTableService: EngagementTableService,
      private engagementDashboardServices:EngagementDashboardService, 
      private adminService: AdminService,
      private spinnerService: SpinnerService,
      private engagementHealthObjectService: EngagementHealthObjectService,
      private dealWbsService:DealwbsService,
      private closingCheckListService:ClosingCheckListService) { }

  ngOnInit() {
    // this.engagementDashboardServices.getAvailableForms().then(availableForms => {
    //   this.newDealBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.newDealSetupFormList);
    //     this.dealwbsBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.dealWbsSetupFormList);
    //     this.closingChecklistBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.closingChecklistFormList);
    //   });
  }
  onPageSizeValueChange(event: any) {
    if(this.selectedPageSize=="All")
    {
    const values: PaginationValues = {
      page: this.engagementTableService.state.page,
      pageSize: parseInt(this.initialEngagementsList.length.toString())
    };
    this.onChangePaginationValues(values);
  }
  else{
    const values: PaginationValues = {
      page: this.engagementTableService.state.page,
      pageSize: parseInt(this.selectedPageSize)
    };
    this.onChangePaginationValues(values);
  }
  }
  // onPageSizeValueChange(event:any){
  //   const values: PaginationValues = {
  //     page: this.engagementTableService.state.page,
  //     pageSize: parseInt(this.selectedPageSize)
  //   };
  // this.onChangePaginationValues(values);
  // }


  setEngagements(
    engagementsList: Array<EngagementDashboardtItem>,
    engagementHealthObjectUrl: string,
    showClosedDeals: boolean,
    pageSizeListValues: Array<any>) {
      pageSizeListValues = [{ id: 0, value: "All" },{ id: 1, value: 10 }, { id: 2, value: 25 }, { id: 3, value: 50 }, { id: 4, value: 100 }];
      this.engagementHealthObjectUrl = engagementHealthObjectUrl;
      let filteredEngagementsList = this._filterClosedDeals(showClosedDeals, engagementsList);
    this.engagementTableService.state.page = 1;
    this.engagementTableService.state.pageSize = 25;
      //this.initialEngagementsList = filteredEngagementsList;
    const sortData = this.engagementTableService.state.sortData;
    const filterData = this.engagementTableService.state.filterDataList;

      filteredEngagementsList = this._getEngagementsMatchsFilterCriteria(filteredEngagementsList, filterData);
      this.currentEngagementList = this.engagementTableService.sort(
          sortData.sortColumn, sortData.sortDirection, filteredEngagementsList);
      this._updateSortArrow(sortData.sortColumn, sortData.sortDirection);
      this.currentEngagementList = this.updateStatuses(this.currentEngagementList);
      this._setEngagementsToCurrentPage();     
      this._setPaginationValues(pageSizeListValues);
      this.pageDropdownDisable = this.currentEngagementList.length > 0 ? false : true;
      this.spinnerService.hideAutoSaveSpinner();
  }

  openNewTab(url: string) {
    window.open(url);
  }

  openNewDealForm(id: number) {
   // this.openNewTab(this.getNewDealUrlById(id));
    this.openNewTab(environment.redirectUrl+ 'engagementHealthObject?item_id=' + id);
  }
  openNewDealForm_ED(id: number) {
    // this.openNewTab(this.getNewDealUrlById(id));
     this.openNewTab(environment.redirectUrl+ 'newdeal?id=' + id);
   }

  openDealWbsForm(id: number) {
      this.openNewTab(environment.redirectUrl+ 'dealWbsForm?id=' + id);
   }

   openCCFForm(id: number) {
      this.openNewTab(environment.redirectUrl+ 'closingCheckListForm?id=' + id);
   }

  getNewDealUrlById(id: number): string {
    return this.engagementHealthObjectUrl + '#/main?item_id=' + id;
  }

  sortTable({column}: SortEvent) {
    const newDirection = this._getSortDirection(column);
    this._resetSortIcons(column);
    this.currentEngagementList = this.engagementTableService.sort(column, newDirection, this.currentEngagementList);
    this._setEngagementsToCurrentPage();
    this._setSortOptions(column, newDirection);
  }

  sortTableOnFilterModal(column: string, direction: SortDirection) {
    this._resetSortIcons(column);
    this._showSortDirectionArrow(column, direction);
    this._setEngagementsToCurrentPage();
    this.currentEngagementList = this.engagementTableService.sort(column, direction, this.currentEngagementList);
    this._setSortOptions(column, direction);
  }

  openFilterPopover(field: string) {
    this.targetField = field;
    this.targetFilter = this._getFilter(field);
    var fieldOptionRecords = this._filterClosedDeals(this.showClosedDeals,this.initialEngagementsList)
    if(this.searchTerm.trim()=="" || this.searchTerm==undefined || this.searchTerm=="ALL"){
      if(this.targetField=="engagementId")
        this.fieldOptionValues = this._mapEngagementListWithFilterList(fieldOptionRecords, field).sort((a:any,b:any)=>b-a);
      else
        this.fieldOptionValues = this._mapEngagementListWithFilterList(fieldOptionRecords, field).sort();
    }
    else{
      var fieldoptions= fieldOptionRecords.filter((x:any)=>x.engagementName.toLowerCase().includes(this.searchTerm.toLowerCase()));
      if(this.targetField=="engagementId")
        this.fieldOptionValues = this._mapEngagementListWithFilterList(fieldoptions, field).sort((a:any,b:any)=>b-a);
      else
        this.fieldOptionValues = this._mapEngagementListWithFilterList(fieldoptions, field).sort();

    }
  }

  updateStatuses(currentEngagementList:EngagementDashboardtItem[]):EngagementDashboardtItem[]{   
    currentEngagementList.forEach((currentEngagement, index)=>{
      //Check for the dealwbs forms
      this.DealWbsData.forEach((dealwbsDetail:any)=>{
        if(dealwbsDetail.MyDealID!=null&&currentEngagement.myDealId!=null){
        if(dealwbsDetail.MyDealID.toString()==currentEngagement.myDealId.toString()){
          currentEngagementList[index].dealWBSStatus = dealwbsDetail.Status;
          currentEngagementList[index].dealWBSId = dealwbsDetail.ID;
        }
      }
      });
      //Check for the closing checklist forms
      this.closingCheckListData.forEach((closingCheckListDetail:any)=>{
        if(closingCheckListDetail.MyDealID!=null&&currentEngagement.myDealId!=null){
        if(closingCheckListDetail.MyDealID.toString()==currentEngagement.myDealId.toString()){
          currentEngagementList[index].closingChecklistStatus=closingCheckListDetail.Status;
          currentEngagementList[index].closingChecklistId = closingCheckListDetail.ID;
        }
      }
      });
      if(!currentEngagementList[index].closingChecklistStatus)
        currentEngagementList[index].closingChecklistStatus = "-";
      if(!currentEngagementList[index].dealWBSStatus)
        currentEngagementList[index].dealWBSStatus = "-";
  });
    return currentEngagementList;
  }
  getFormTitle(currentEngagementListPerPages:EngagementDashboardtItem[]):EngagementDashboardtItem[]{   
    currentEngagementListPerPages.forEach((currentEngagementListPerPage, index)=>{
      //Check for the dealwbs forms
      this.DealWbsData.forEach((dealwbsDetail:any)=>{
        if(dealwbsDetail.MyDealID!=null&&currentEngagementListPerPage.myDealId!=null){
        if(dealwbsDetail.MyDealID.toString()==currentEngagementListPerPage.myDealId.toString()){
          if(dealwbsDetail != null && dealwbsDetail.Status != AppSettings.initialFormStatus){
            if(!currentEngagementListPerPages[index].formTitle.includes(AppSettings.dealWbsSetupForm))
            currentEngagementListPerPages[index].formTitle.push(AppSettings.dealWbsSetupForm);
          }
       }
      }
      });
      //Check for the closing checklist forms
      this.closingCheckListData.forEach((closingCheckListDetail:any)=>{
        if(closingCheckListDetail.MyDealID!=null&&currentEngagementListPerPage.myDealId!=null){
        if(closingCheckListDetail.MyDealID.toString()==currentEngagementListPerPage.myDealId.toString()){
          if(closingCheckListDetail != null && closingCheckListDetail.Status != AppSettings.initialFormStatus){
            if(!currentEngagementListPerPages[index].formTitle.includes(AppSettings.closingChecklistForm))
            currentEngagementListPerPages[index].formTitle.push(AppSettings.closingChecklistForm);
          }
         }
      }
      });
  });
    return currentEngagementListPerPages;
  }
  clearFilters() {
    if (this.targetFilter) {
      this.targetFilter.valueList = [];
      this._updateEngagementListBasedOnSearch();
    }
  }

  onClickFilterCheckbox(event:any) {
    const checkbox = event.currentTarget;
    const valueSelected = checkbox.id;
    const isChecked = checkbox.checked;

    this._updateFilterList(valueSelected, isChecked);
    this._updateEngagementListBasedOnSearch();
  }

  isChecked(value: string): boolean {
    let checked = false;
    if (this.targetFilter) {
      this.targetFilter.valueList.forEach(v => {
        if (v === value.toString()) {
          checked = true;
        }
      });
    }
    return checked;
  }

  isFilterApply(field: string) {
     this.filterApply = false;
    this.filterList.forEach(filter => {
      if (filter.field === field && filter.valueList.length) {
        this.filterApply = true;
      }
    });
    return this.filterApply;
  }

  clearAllFilters() {
    this._resetSortIcons();
    this.filterList = [];
    this.targetField = null;
    this.engagementTableService.searchTerm = '';
    this.searchTerm="";
    this.engagementTableService.state.sortData = {sortColumn: 'engagementId', sortDirection: 'desc'};
    this.engagementTableService.filterDataList = [];
    this.engagementTableService.state.page = 1;
    this.selectedPageSize = this.engagementTableService.state.pageSize = AppSettings.EngagementDefaultPageSize;
    this.showDealClosedState.emit(false);
    this.currentEngagementList = this._filterClosedDeals(false, this.initialEngagementsList);
    this._updateSortArrow(this.engagementTableService.state.sortData.sortColumn, this.engagementTableService.state.sortData.sortDirection);
    this._setEngagementsToCurrentPage();
  }

  onChangePaginationValues(paginationValues: PaginationValues) {
    this.engagementTableService.state.page = paginationValues.page;
    this.engagementTableService.state.pageSize = paginationValues.pageSize;
    this._setEngagementsToCurrentPage();
  }

  getDpnProfileLink(loginName: string): string {
    return AppSettings.dpnProfileLink.replace('{userId}', loginName);
  }

  private _setPaginationValues(values:any) {
    this.pageSizeListValues = values.map((element: any) => {
      const parseValue = element.value.toString();
      const dropdownItem: DropdownItem = {
        key:'',
        value:''
      };
      if(parseValue=="All"){
         dropdownItem.key=parseValue,
         dropdownItem.value=parseValue+' Items'
      
      }
      else{
        dropdownItem.key=parseValue,
        dropdownItem.value=parseValue+' items per page'
    }
      return dropdownItem;
    });
    this.selectedPageSize = this.selectedPageSize == undefined ? AppSettings.EngagementDefaultPageSize : this.selectedPageSize;

  }

  private _setEngagementsToCurrentPage() {
    const page = this.engagementTableService.state.page;
    const pageSize = this.engagementTableService.state.pageSize;  
    this.currentEngagementListPerPage = this.currentEngagementList.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this.currentEngagementListPerPage = this.getFormTitle(this.currentEngagementList.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize));
  }
  private _getFormBaseUrl(availableForms: any, listName: string) {
    return availableForms?.find((form:any) => form.availableFormListName === listName).availableFormSiteUrl;
  }
  private _updateSortArrow(sortColumn: string, sortDirection: SortDirection) {
    this._resetSortIcons(sortColumn);
    this._showSortDirectionArrow(sortColumn, sortDirection);
  }

  private _showSortDirectionArrow(sortColumn: string, sortDirection: SortDirection) {
    const header = this._getHeader(sortColumn);
    header.direction = sortDirection;
    header.rotate();
  }

  private _updateEngagementList() {

    const filteredEngagementList = this._filterClosedDeals(this.showClosedDeals,this.initialEngagementsList);
    this.currentEngagementList = this._isSomeFilterApply() ?
      this._getEngagementsMatchsFilterCriteria(filteredEngagementList, this.filterList) : filteredEngagementList
      

    const sortData = this.engagementTableService.state.sortData;
    this.currentEngagementList = this.engagementTableService.sort(
      sortData.sortColumn, sortData.sortDirection, this.currentEngagementList);
    this._setEngagementsToCurrentPage();
  }

  private _updateEngagementListBasedOnSearch() {
      // Assign the current search term to SearchingValue
      this.SearchingValue = this.searchTerm;
  
      // Check if SearchingValue is neither "ALL", undefined, nor an empty string
      if (this.SearchingValue.trim() !== "ALL" && this.SearchingValue.trim() !== undefined && this.SearchingValue.trim() !== "") {
          // Filter the engagement list based on SearchingResults
          const filteredEngagementList = this._filterClosedDeals(this.showClosedDeals, this.SearchingResults);
          // Apply additional filters if any, otherwise use the filtered engagement list
          this.currentEngagementList = this._isSomeFilterApply() ?
              this._getEngagementsMatchsFilterCriteria(filteredEngagementList, this.filterList) : filteredEngagementList;
      } else {
          // Handle the case where SearchingValue is "ALL", undefined, or an empty string
          const filteredEngagementList = this._filterClosedDeals(this.showClosedDeals, this.initialEngagementsList);
          // Apply additional filters if any, otherwise use the filtered engagement list
          this.currentEngagementList = this._isSomeFilterApply() ?
              this._getEngagementsMatchsFilterCriteria(filteredEngagementList, this.filterList) : filteredEngagementList;
      }
  
      // Sort the current engagement list based on the sort data
      const sortData = this.engagementTableService.state.sortData;
      this.currentEngagementList = this.engagementTableService.sort(
          sortData.sortColumn, sortData.sortDirection, this.currentEngagementList);
  
      // Set the engagements to the current page
      this._setEngagementsToCurrentPage();
  }

  private _isSomeFilterApply() {
    let filterApply = false;
    this.filterList.forEach(filter => {
      if (filter.valueList.length) {
        filterApply = true;
      }
    });
    return filterApply;
  }

  private _updateFilterList(valueSelected: string, isChecked: string) {
    const existFilter = this._getFilter(this.targetField);
    if (isChecked) {
      this._addFilterItem(existFilter, valueSelected);
    } else {
      this._removeFilterItem(existFilter, valueSelected);
    }
    this._setTargetFilter(existFilter);
  }

  private _setTargetFilter(existFilter: EngagementTableFilterItem) {
    this.targetFilter = existFilter;
    this.engagementTableService.filterDataList = this.filterList;

  }

  private _addFilterItem(existFilter: EngagementTableFilterItem, valueSelected: string) {
    if (existFilter) {
      existFilter.valueList.push(valueSelected);
    } else {
      const newFilter = this._createFilter(valueSelected);
      this.filterList.push(newFilter);
      existFilter = newFilter;
    }
  }

  private _removeFilterItem(existFilter: EngagementTableFilterItem, valueSelected: string) {
    if (existFilter) {
      existFilter.valueList = this._removeFilter(existFilter.valueList, valueSelected);
    }
  }

  private _createFilter(valueSelected: string): EngagementTableFilterItem {
    const newFilter = {
      field: this.targetField,
      valueList: [valueSelected]
    };
    return newFilter;
  }

  private _getEngagementsMatchsFilterCriteria(
    engagements: Array<EngagementDashboardtItem>,
    filterList: Array<EngagementTableFilterItem>): Array<EngagementDashboardtItem> {
    let newEngagementList: Array<EngagementDashboardtItem> = [];
    if (filterList.length > 0) {
      engagements.forEach(eng => {
        if (this._isEngagementMatchFilterCriteria(eng, filterList)) {
          newEngagementList.push(eng);
        }
      });
    } else {
      newEngagementList = engagements;
    }
    return newEngagementList;
  }

  private _isEngagementMatchFilterCriteria(
    engagement: any,
    filterList: Array<EngagementTableFilterItem>): boolean {
    let match = true;
    filterList.forEach(filter => {
      const field = filter.field;
      const values = filter.valueList;
      if (values.length && !values.find(v => v === engagement[field].toString())) {
        match = false;
        return false;
      }
      return match;
    });
    return match;
  }

  private _mapEngagementListWithFilterList(
    listEngagements: Array<EngagementDashboardtItem>,
    field: string): Array<string> {
    const arrayOptionsList:any = [];
    listEngagements.forEach((engagement:any) => {
      const value = engagement[field];
      if (this._isValidValue(arrayOptionsList, value, field, listEngagements)) {
        arrayOptionsList.push(engagement[field]);
      }
    });
    return arrayOptionsList;
  }

  private _isValidValue(
    arrayOptionsList: Array<string>,
    value: string,
    field: string,
    listEngagements: Array<EngagementDashboardtItem>): boolean {
      return (value !== '' && value !== null &&
            !this._inArray(arrayOptionsList, value) &&
            this._existEngagementForValue(listEngagements, field, value));
  }

  private _existEngagementForValue(
    listEngagements: Array<EngagementDashboardtItem>,
    field: string,
    value: string): boolean {
    const filterArrayClone = [...this.filterList];
    const filterRemovedCurrentTarget = filterArrayClone.filter(f => f.field !== field);
    const filteredValues = this._getEngagementsMatchsFilterCriteria(listEngagements, filterRemovedCurrentTarget);
    const searchValue = filteredValues.find((v:any) => v[field] === value) ? true : false;
    return searchValue;
  }

  private _resetSortIcons(column?: string) {
    this.headers?.forEach(header => {
      if ((column && header.sortable !== column) || !column) {
        header.direction = '';
      }
    });
  }

  private _getHeader(column: string): NgbdSortableHeaderDirective {
    return this.headers?.filter(h => h.sortable === column)[0];
  }

  private _getSortDirection(column: string): SortDirection {
    const header = this._getHeader(column);
    return header.direction === 'asc' ? 'desc' : 'asc';
  }

  private _removeFilter(valueList: Array<string>, valueSelected: string): Array<string> {
    return valueList.filter(v => v !== valueSelected);
  }

  private _getFilter(field: string): any {
    return this.filterList.length ? this.filterList.filter(f => f.field === field)[0] : null;
  }

  private _inArray(optionFields: Array<string>, option: string): boolean {
    return Boolean(optionFields.find(o => o === option));
  }

  private _setSortOptions(column: string, direction: SortDirection) {
    const sortItem: EngagementDashboardSort = {
      sortColumn: column,
      sortDirection: direction
    };
    this.engagementTableService.sortData = sortItem;
  }

  private _filterClosedDeals(showClosedDeals: boolean, engagementsList: Array<EngagementDashboardtItem>): Array<EngagementDashboardtItem> {
    let filteredEngagementsList;
    this.showClosedDeals = showClosedDeals;
    if (showClosedDeals) {
      filteredEngagementsList = engagementsList;
    } else {
      filteredEngagementsList = engagementsList.filter(item => {
        return item.engagementStatus !== EngagementStatusValues.DealClosed && item.engagementStatus !== EngagementStatusValues.DealLost;
      });
    }
    return filteredEngagementsList;
  }


  public onSearchResult(result:any){
    if(!this._isSomeFilterApply())
        this.onSearchApplyOnFilteredData = [];
        this.SearchingResults=[];
        
  // Customised search Functionality
   this.getSearchResults(result == "" ? "ALL" : result);
  }


  getSearchResults(searchValue:any){
    this.searchTerm = searchValue
    let searchArray:any=[];
    if(searchValue=="ALL"&&!this.filterApply){
      searchArray=this.initialEngagementsList
    }
    else{
      if(this.onSearchApplyOnFilteredData.length>0){
        if(searchValue=="ALL"){
         searchArray=this.onSearchApplyOnFilteredData;
        }
        else{
        // searchArray=this.onSearchApplyOnFilteredData.filter(x=>x.projectName.toLowerCase().includes(searchValue.toLowerCase()));
        searchArray = this.onSearchApplyOnFilteredData.filter((item) => {
          // Apply filter conditions on each column
          return (
            item.engagementName.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        }
      }
      else{
        // searchArray=this.initialReportDashboardListForSearch.filter(x=>x.projectName.toLowerCase().includes(searchValue.toLowerCase()));
        searchArray = this.initialEngagementsList.filter((item) => {
          // Apply filter conditions on each column
          return (
            item.engagementName.toString().toLowerCase().includes(searchValue.toLowerCase())
          );
        });
      }
      this.SearchingResults=searchArray;
      
    }
   
    this.setEngagements(searchArray,"",this.showClosedDeals, this.pageSizeListValues);
  }
  getModalStyles()
   {  
    var width= "96%";
    var marginleft= "3%";
    var margintop= "1%"; 
    if(this.currentEngagementList.length==0){
      var height= "40vh";
    }
    else{
      var height= "58vh";
    }   
    return {'width':width,'height':height,"margin-left":marginleft,"margin-top":margintop};
   }
}
