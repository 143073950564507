import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppSettings } from "src/shared/app-settings";
import { DropdownItem } from "src/shared/models/dropdown-item";
import { TooltipObject } from "src/shared/models/tooltip-object";
import { DropdownService } from "src/shared/services/dropwdown.service";
import { TooltipHelperService } from "src/shared/services/tooltip-helper.service";
import { FormValidations } from "src/shared/utils/form-validations";
import { FormListFields } from "../shared/models/form-list-fields";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { NAFormatterService } from "../shared/utils/utils-handlingNA";

@Component({
  selector: "app-valuation-metrics",
  templateUrl: "./valuation-metrics.component.html",
  styleUrls: ["./valuation-metrics.component.scss"],
})
export class ValuationMetricsComponent implements OnInit {
  sharePointMapper:any = new ComplianceSharepointMapper();
  valuationMetricsFormGroup: any;
  finalEvPitchRangeOptions: Array<DropdownItem> = [];
  ebitdaPeriodOptions: Array<DropdownItem> = [];
  ifBelowWhyOptions: Array<DropdownItem> = [];
  tooltipMessage = 'Enter "N/A" if not relevant.';
  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;
  @Output()
  valuationMetricsStore: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  constructor(private dropdownService: DropdownService,private tooltipHelperService: TooltipHelperService,private naFormatService:NAFormatterService) {}
  ngOnInit() {
    this.valuationMetricsFormGroup = this.formGroups[0];
    // this.dropdownService
    //   .fillDropdownOptions(
    //     AppSettings.closingChecklistForm,
    //     FormListFields.finalEvPitchRange
    //   )
    //   .then((response) => {
    //     this.finalEvPitchRangeOptions = response;
    //   })
    //   .catch((error) => {});
    // this.dropdownService
    //   .fillDropdownOptions(
    //     AppSettings.closingChecklistForm,
    //     FormListFields.ebitdaPeriod
    //   )
    //   .then((response) => {
    //     this.ebitdaPeriodOptions = response;
    //   })
    //   .catch((error) => {});
    // this.dropdownService
    //   .fillDropdownOptions(
    //     AppSettings.closingChecklistForm,
    //     FormListFields.ifBelowWhy
    //   )
    //   .then((response) => {
    //     this.ifBelowWhyOptions = response;
    //   })
    //   .catch((error) => {});
    // this.formDataBidder();
    // this.formDataBidder2();
    this.ebitdaPeriodOptions = AppSettings.EBITDAPeriodListArray;
    this.finalEvPitchRangeOptions = AppSettings.FinalEvPitchRangeListArray;
    this.ifBelowWhyOptions = AppSettings.IfBelowWhyListArray;
  }
  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }
  onSelectedValue(event:any, columnName:any) {
    this.valuationMetricsFormGroup.controls[columnName].setValue(event);
    this.formDataBidder2();
  }
  onFinalEvPitchRangeDropDownSelection(event:any, columnName:any) {
    this.valuationMetricsFormGroup.controls[columnName].setValue(event.key);
    this.evaluateIfBelow(event.key);
  }
  onIfBelowWhyDropDownSelection(event:any, columnName:any) {
    this.valuationMetricsFormGroup.controls[columnName].setValue(event.key);
    this.evaluateIfBelowWhy(event.key);
  }
  evaluateIfBelow(value:any) {
    if (value === "Below") {
      this.valuationMetricsFormGroup.controls.ifBelowWhy.enable();
    } else {
      this._setAsDisabled("ifBelowWhy");
      this._setAsDisabled("ifBelowWhyOtherReason");
    }
  }
  evaluateIfBelowWhy(value:any) {
    if (value === "Other") {
      this.valuationMetricsFormGroup.controls.ifBelowWhyOtherReason.enable();
    } else {
      this._setAsDisabled("ifBelowWhyOtherReason");
    }
  }
  private _setAsDisabled(formControlName: string) {
    const controlName = this.valuationMetricsFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }
  onEbitdaPeriodDropDownSelection(event:any, columnName:any) {
    this.valuationMetricsFormGroup.controls[columnName].setValue(event.key);
  }
  onhitBreakpointEL(event:any) {
    this.valuationMetricsFormGroup.controls.hitBreakpointEL.setValue(event);
  }
  // onEbitda(event,columnName){
  //   this.valuationMetricsFormGroup.controls[columnName].setValue(event);
  //   this.formDataBidder2();
  // }

  formDataBidder2() {
    let totalConsideration = 0;
    totalConsideration =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("closingConsideration").value)) +
      Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("contingentConsideration").value)
      );
    this.valuationMetricsFormGroup.controls.totalConsideration.setValue(
      isFinite(totalConsideration) ? totalConsideration.toString() : ""
    );

    let ebitdaMultipleTotal = 0;
    ebitdaMultipleTotal =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration").value)) /
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("ebitda").value));
    this.valuationMetricsFormGroup.controls.ebitdaMultipleTotal.setValue(
      isFinite(ebitdaMultipleTotal) ? ebitdaMultipleTotal.toString() : ""
    );

    let ebitdaMultipleClosing = 0;
    ebitdaMultipleClosing =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("closingConsideration").value)) /
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("ebitda").value));
    this.valuationMetricsFormGroup.controls.ebitdaMultipleClosing.setValue(
      isFinite(ebitdaMultipleClosing) ? ebitdaMultipleClosing.toString() : ""
    );

    let multipleRangeMidpoint = 0;
    multipleRangeMidpoint =this.naFormatService.avgHandlingNA([this.valuationMetricsFormGroup.get("pitchEBITDAMultipleRangeFirst").value,this.valuationMetricsFormGroup.get("pitchEBITDAMultipleRangeSecond").value]);
      // (Number(
      //   this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("pitchEBITDAMultipleRangeFirst").value)
      // ) +
      //   Number(
      //     this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("pitchEBITDAMultipleRangeSecond").value)
      //   )) /
      // 2;
    this.valuationMetricsFormGroup.controls.multipleRangeMidpoint.setValue(
      isFinite(multipleRangeMidpoint) ? multipleRangeMidpoint.toString() : ""
    );

    let impliedValuationPremium = 0;
    impliedValuationPremium =
      ((Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration").value)) -
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("multipleRangeMidpoint").value)
        ) *
          Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("ebitda").value))) /
        (Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("multipleRangeMidpoint").value)
        ) *
          Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("ebitda").value)))) *
      100;

    this.valuationMetricsFormGroup.controls.impliedValuationPremium.setValue(
      isFinite(impliedValuationPremium)
        ? impliedValuationPremium.toString()
        : ""
    );

    let valuationPremiumOverBp = 0;
    valuationPremiumOverBp =
      ((Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration").value)) -
        Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("elBreakpoint").value))) /
        Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("elBreakpoint").value))) *
      100;

    this.valuationMetricsFormGroup.controls.valuationPremiumOverBp.setValue(
      isFinite(valuationPremiumOverBp) ? valuationPremiumOverBp.toString() : ""
    );

    let loiTotal = 0;
    loiTotal =
      Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("loiClosedConsideration").value)
      ) +
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("loiClosedContingent").value));
    this.valuationMetricsFormGroup.controls.loiTotal.setValue(
      isFinite(loiTotal) ? loiTotal.toString() : ""
    );

    let valNumericalClosedConsideration = 0;
    valNumericalClosedConsideration =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("closingConsideration").value)) -
      Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("loiClosedConsideration").value)
      );
    this.valuationMetricsFormGroup.controls.valNumericalClosedConsideration.setValue(
      isFinite(valNumericalClosedConsideration)
        ? valNumericalClosedConsideration.toString()
        : ""
    );
    let valNumericalTotal = 0;
    valNumericalTotal =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration").value)) -
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("loiTotal").value));
    this.valuationMetricsFormGroup.controls.valNumericalTotal.setValue(
      isFinite(valNumericalTotal) ? valNumericalTotal.toString() : ""
    );
    let valPercentageClosedConsideration = 0;
    valPercentageClosedConsideration =
      (Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("valNumericalClosedConsideration").value)
      ) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("loiClosedConsideration").value)
        )) *
      100;
    this.valuationMetricsFormGroup.controls.valPercentageClosedConsideration.setValue(
      isFinite(valPercentageClosedConsideration)
        ? valPercentageClosedConsideration.toString()
        : ""
    );
    let valPercentageTotal = 0;
    valPercentageTotal =
      (Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("valNumericalTotal").value)) /
        Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("loiTotal").value))) *
      100;
    this.valuationMetricsFormGroup.controls.valPercentageTotal.setValue(
      isFinite(valPercentageTotal) ? valPercentageTotal.toString() : ""
    );
    this.valuationMetricsStore.emit(this.valuationMetricsFormGroup);
  }
  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
  onBidder1(event:any, columnName:any) {
    this.valuationMetricsFormGroup.controls[columnName].setValue(event);
    this.formDataBidder();
  }
  onBidder2(event:any, columnName:any) {
    this.valuationMetricsFormGroup.controls[columnName].setValue(event);
    this.formDataBidder2();
  }
  formDataBidder() {
    let iOIValueTotalHigh = 0;
    iOIValueTotalHigh =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingHigh").value)) +
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueContingent").value));
    this.valuationMetricsFormGroup.controls.iOIValueTotalHigh.setValue(
      isFinite(iOIValueTotalHigh) ? iOIValueTotalHigh.toString() : ""
    );

    let iOIValueClosingAvg = 0;
    iOIValueClosingAvg = this.naFormatService.avgHandlingNA([this.valuationMetricsFormGroup.get("iOIValueClosingLow").value,this.valuationMetricsFormGroup.get("iOIValueClosingHigh").value]);
      // (Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingLow").value)) +
      //   Number(
      //     this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingHigh").value)
      //   )) /
      // 2;
    this.valuationMetricsFormGroup.controls.iOIValueClosingAvg.setValue(
      isFinite(iOIValueClosingAvg) ? iOIValueClosingAvg.toString() : ""
    );

    let iOIValueTotalAvg = 0;
    iOIValueTotalAvg =this.naFormatService.avgHandlingNA([this.valuationMetricsFormGroup.get("iOIValueClosingLow").value,this.valuationMetricsFormGroup.get("iOIValueTotalHigh").value]);
      // (Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingLow").value)) +
      //   Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueTotalHigh").value))) /
      // 2;
    this.valuationMetricsFormGroup.controls.iOIValueTotalAvg.setValue(
      isFinite(iOIValueTotalAvg) ? iOIValueTotalAvg.toString() : ""
    );

    let lOIValueTotalHigh = 0;
    lOIValueTotalHigh =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueClosingHigh").value)) +
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueContingent").value));
    this.valuationMetricsFormGroup.controls.lOIValueTotalHigh.setValue(
      isFinite(lOIValueTotalHigh) ? lOIValueTotalHigh.toString() : ""
    );

    let lOIValueClosingAvg = 0;
    lOIValueClosingAvg = this.naFormatService.avgHandlingNA([this.valuationMetricsFormGroup.get("lOIValueClosingLow").value,this.valuationMetricsFormGroup.get("lOIValueClosingHigh").value]);
      // (Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueClosingLow").value)) +
      //   Number(
      //     this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueClosingHigh").value)
      //   )) /
      // 2;
    this.valuationMetricsFormGroup.controls.lOIValueClosingAvg.setValue(
      isFinite(lOIValueClosingAvg) ? lOIValueClosingAvg.toString() : ""
    );

    let lOIValueTotalAvg = 0;
    lOIValueTotalAvg = this.naFormatService.avgHandlingNA([this.valuationMetricsFormGroup.get("lOIValueClosingLow").value,this.valuationMetricsFormGroup.get("lOIValueTotalHigh").value]);
      // (Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueClosingLow").value)) +
      //   Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueTotalHigh").value))) /
      // 2;
    this.valuationMetricsFormGroup.controls.lOIValueTotalAvg.setValue(
      isFinite(lOIValueTotalAvg) ? lOIValueTotalAvg.toString() : ""
    );

    let numericalPriceIncClosingLow = 0;
    numericalPriceIncClosingLow =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueClosingLow").value)) -
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingLow").value));
    this.valuationMetricsFormGroup.controls.numericalPriceIncClosingLow.setValue(
      isFinite(numericalPriceIncClosingLow)
        ? numericalPriceIncClosingLow.toString()
        : ""
    );

    let numericalPriceIncClosingHigh = 0;
    numericalPriceIncClosingHigh =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueClosingHigh").value)) -
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingHigh").value));
    this.valuationMetricsFormGroup.controls.numericalPriceIncClosingHigh.setValue(
      isFinite(numericalPriceIncClosingHigh)
        ? numericalPriceIncClosingHigh.toString()
        : ""
    );

    let numericalPriceIncContingent = 0;
    numericalPriceIncContingent =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueContingent").value)) -
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueContingent").value));
    this.valuationMetricsFormGroup.controls.numericalPriceIncContingent.setValue(
      isFinite(numericalPriceIncContingent)
        ? numericalPriceIncContingent.toString()
        : ""
    );

    let numericalPriceIncTotalHigh = 0;
    numericalPriceIncTotalHigh =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueTotalHigh").value)) -
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueTotalHigh").value));
    this.valuationMetricsFormGroup.controls.numericalPriceIncTotalHigh.setValue(
      isFinite(numericalPriceIncTotalHigh)
        ? numericalPriceIncTotalHigh.toString()
        : ""
    );

    let numericalPriceIncClosingAvg = 0;
    numericalPriceIncClosingAvg =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueClosingAvg").value)) -
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingAvg").value));
    this.valuationMetricsFormGroup.controls.numericalPriceIncClosingAvg.setValue(
      isFinite(numericalPriceIncClosingAvg)
        ? numericalPriceIncClosingAvg.toString()
        : ""
    );

    let numericalPriceIncTotalAvg = 0;
    numericalPriceIncTotalAvg =
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("lOIValueTotalAvg").value)) -
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueTotalAvg").value));
    this.valuationMetricsFormGroup.controls.numericalPriceIncTotalAvg.setValue(
      isFinite(numericalPriceIncTotalAvg)
        ? numericalPriceIncTotalAvg.toString()
        : ""
    );

    let percentagePriceIncClosingLow = 0;
    percentagePriceIncClosingLow =
      (Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("numericalPriceIncClosingLow").value)
      ) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingLow").value)
        )) *
      100;
    this.valuationMetricsFormGroup.controls.percentagePriceIncClosingLow.setValue(
      isFinite(percentagePriceIncClosingLow)
        ? percentagePriceIncClosingLow.toString()
        : ""
    );

    let percentagePriceIncClosingHigh = 0;
    percentagePriceIncClosingHigh =
      (Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("numericalPriceIncClosingHigh").value)
      ) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingHigh").value)
        )) *
      100;
    this.valuationMetricsFormGroup.controls.percentagePriceIncClosingHigh.setValue(
      isFinite(percentagePriceIncClosingHigh)
        ? percentagePriceIncClosingHigh.toString()
        : ""
    );

    let percentagePriceIncContingent = 0;
    percentagePriceIncContingent =
      (Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("numericalPriceIncContingent").value)
      ) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueContingent").value)
        )) *
      100;
    this.valuationMetricsFormGroup.controls.percentagePriceIncContingent.setValue(
      isFinite(percentagePriceIncContingent)
        ? percentagePriceIncContingent.toString()
        : ""
    );

    let percentagePriceIncTotalHigh = 0;
    percentagePriceIncTotalHigh =
      (Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("numericalPriceIncTotalHigh").value)
      ) /
        Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueTotalHigh").value))) *
      100;
    this.valuationMetricsFormGroup.controls.percentagePriceIncTotalHigh.setValue(
      isFinite(percentagePriceIncTotalHigh)
        ? percentagePriceIncTotalHigh.toString()
        : ""
    );

    let percentagePriceIncClosingAvg = 0;
    percentagePriceIncClosingAvg =
      (Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("numericalPriceIncClosingAvg").value)
      ) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueClosingAvg").value)
        )) *
      100;
    this.valuationMetricsFormGroup.controls.percentagePriceIncClosingAvg.setValue(
      isFinite(percentagePriceIncClosingAvg)
        ? percentagePriceIncClosingAvg.toString()
        : ""
    );

    let percentagePriceIncTotalAvg = 0;
    percentagePriceIncTotalAvg =
      (Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("numericalPriceIncTotalAvg").value)
      ) /
        Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("iOIValueTotalAvg").value))) *
      100;
    this.valuationMetricsFormGroup.controls.percentagePriceIncTotalAvg.setValue(
      isFinite(percentagePriceIncTotalAvg)
        ? percentagePriceIncTotalAvg.toString()
        : ""
    );
  }
  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }
  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(
        this.formSubmitted,
        formGroup,
        formControlName
      );
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }
  getTooltip(tooltipTitle: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
  }

  getTooltipText(tooltipTitle:string):string{
    const tooltip = this.getTooltip(tooltipTitle);
    return tooltip ? tooltip.getTooltipText() : '';
  }

}
