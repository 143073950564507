export class FormStatusValue {
    static discontinued = 'Discontinued';
    static pending = 'Pending';
    static submitted = 'Submitted';
    static draft = 'Draft';
    static approved = 'Approved';
    static completed = 'Completed';
    static rejected = 'Rejected';
    static reactivated = 'Reactivated';
}
export class StatusValue {
    static discontinued = 'Discontinued';
    static AdminReviewpending = 'Pending for Admin Review';
    static returnedToRework = 'Returned for Rework';
    static submitted = 'Submitted';
    static draft = 'Pending for Deal Team Submission';
    static approved = 'Approved';
    static completed = 'Completed';
    static rejected = 'Rejected';
    static reactivated = 'Reactivated';
}