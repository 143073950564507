import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { DateFormatterService } from '../../utils/utils-date';
import { DatePipe } from '@angular/common';
import { DateService } from 'src/app/services/date.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Component({
  selector: 'app-reactivate-form',
  templateUrl: './reactivate-form.component.html',
  styleUrls: ['./reactivate-form.component.scss']
})
export class ReactivateFormComponent implements OnInit {

  @Output() reactivateForm = new EventEmitter<boolean>();
@Input()  canceledBy:string;
@Input()  canceledOn:string;

  discontinuedOn: string;
  discontinuedBy: string;

  constructor(private dateFormatterService: DateFormatterService,private datePipe: DatePipe,
    private dateService: DateService,public globalHelperService:GlobalHelperService) { }

  ngOnInit() 
  {
    this.setReactivateSection();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.canceledBy && changes.canceledOn) {
      this.discontinuedBy = changes.canceledBy.currentValue;
      this.discontinuedOn = this._formatReactivationDate(changes.canceledOn.currentValue);
    }
  }

  onReactivate() {
    this.reactivateForm.emit(true);
  }

 public setReactivateSection() {
    this.discontinuedOn = this._formatReactivationDate(this.canceledOn);
    this.discontinuedBy = this.canceledBy;
  }
  
  private _formatReactivationDate(reactivateDate: string): string | any{
    if (reactivateDate) {
      if(this.globalHelperService.currentAccessPage?.toString() == "DealWBSForm")
      {
        const dateObject = new Date(reactivateDate);
        return `${this.dateFormatterService.formatDateToCDT(dateObject)} CST`;       
      }
      else
      {
        const dateObject = this.dateFormatterService.formatDateOnRetrieve(reactivateDate);
        return `${this.dateFormatterService.formatDateToCDT(dateObject)} CST`;
      }
      // const dateObject = this.dateFormatterService.formatDateOnRetrieve(reactivateDate);
      // return `${this.dateFormatterService.formatDateToCDT(dateObject)} CST`;
      // const dateObject = this.dateFormatterService.formatDateOnRetrieve(reactivateDate);
      // return `${this.dateService._FormatDateCCL(dateObject.toString())}`;
    }
    return '';
  }

}
