<div id="submit-for-review" class="container-fluid border container-border-color container-section hidden">
    <div class="row bg-title">
        <span>Submit Form for Review</span>
    </div>
    <div id="submit-for-review-section" class="content-section">
        <div class="row">
            <div class="float-right" id="">
                <!-- data-submittype="Submitted" data-actiongroup=""
                data-submittype="Saved" data-actiongroup=""
                data-submittype="Discontinued" data-actiongroup="" -->
                <button type="button" class="btn btn-primary formsub submit submit-btn actions-buttons margin-left-6"  id="submitButton"> Submit and Approve</button>
                <button type="button" class="btn btn-outline-primary save-rework-cancel-btn draftsub hidden margin-right-10" id="saveButton">Save</button>
                <button type="button" class="btn btn-outline-primary discontinue-button save-rework-cancel-btn hidden"  id="discontinueButton">Discontinue</button>
            </div>
        </div>
    </div>
</div>