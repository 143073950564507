import { SecurityMatrix } from 'src/shared/utils/security-matrix';

export class ClosingChecklistSecurityMatrix extends SecurityMatrix {
    Access: boolean = false;
    Edit: boolean = false;
    AutoSave: boolean = false;
    AddSeries24: boolean = false;
    AddDealTeamMember: boolean = false;
    Submit: boolean = false;
    Approve: boolean = false;
    ReturnForReWork: boolean = false;
    Cancel: boolean = false;
    Discontinue: boolean = false;
    Reactivate: boolean = false;
    Review: boolean = false;
    ReadOnly: boolean = false;
    ShowSignoffSection: boolean = false;
    EditSignoffSection: boolean = false;
    AttachDocuments: boolean = false;
    RemoveDocuments: boolean = false;
    AddAdditionalDocumentTypes: boolean = false;
    EditDealTeamSignoff:boolean = false;
    ShowDealTeamSignoff:boolean = false;
    EditSeries24FinalApproval:boolean = false;
    ShowSubmitForReviewSection:boolean = false;
}
