<div ngbDropdown [style]="selectorWidth">
  <button ngbDropdownToggle type="button"
    class="btn btn-outline-primary btn-dropdown"
    [disabled]="disabled || readonly"
    [class.readonly]="readonly">
    <div class="container-default-message">
      <span
        class="dropdown-default-message"
        [ngClass]="userLeftDCF ? 'user-left-dcf-span' : ''"
        [ngStyle]="{'background-image': userLeftDCF? getUnderlinedImage():'url()'}">
        {{selectedValue}}
      </span>
    </div>
  </button>
  <span *ngIf="userLeftDCF"
    class="error-message-user-left-dcf" style=" color: red;">
    User is no longer with DCF
  </span>
  <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdown-button" [style]="dropdownWidth">
    <button type="button" class="dropdown-item select-option" [value]="0" disabled ngbDropdownItem>Select your option</button>
    <button type="button" ngbDropdownItem *ngFor="let optionValue of optionsList"
      class="dropdown-item"
      [ngClass]="keySelected == optionValue.key ? activeClass : ''"
      [value]="optionValue.key"
      (click)="changeSelectedValue(optionValue)" >{{optionValue.value}}
    </button>
  </div>
</div>
