import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/shared/app-settings';

import { ReportDashboardItem } from '../../models/reportDashboardItem';

@Injectable({
  providedIn: 'root'
})

export class ReportDashboardServiceHelper {

  constructor(private datePipe: DatePipe) {}

  createReportDashboardObject(
    
    engagementId: number,
    engagementName: string,
    nameOfS24:string,
    s24ApprovalDate:string,
    isSelected:boolean=false,
    IsS24ApprovalComplete:string,
    Id:string): ReportDashboardItem {

      const ReportdashboardItem: ReportDashboardItem = {
        engagementId: +engagementId + +AppSettings.engagementIdInitialValue,
        engagementName: engagementName ? engagementName : '',
        nameOfS24: IsS24ApprovalComplete == 'Completed' ? nameOfS24 : IsS24ApprovalComplete == "Pending for Series 24 approval (Series 24 added)" ? nameOfS24 : "-",
        isSelected:false,
        isS24ApprovalComplete: IsS24ApprovalComplete == 'Completed' ? 'Completed ' : IsS24ApprovalComplete == "Pending for Series 24 approval (Series 24 added)" ? 'Pending for Series 24 approval' :'Not submitted for S24 Approval',
        s24ApprovalDate:IsS24ApprovalComplete == 'Completed' ? this._formatAdminReviewDate(s24ApprovalDate):'-',
            form_id:Id
      };
      return ReportdashboardItem;
  }
  private _formatAdminReviewDate(adminApprovalDate: string): string {
    
    const dateObject = new Date(adminApprovalDate);
  
    return this.datePipe.transform(dateObject, "dd MMM y hh:mm a",'CDT') + " CST";
   // return `${this.dateFormatterService.formatDateToCDT(dateObject)} CST`;
  
  }

}
