<div class="col-xs-12">
    <app-peoplepicker-dynamic-table #DealTeam
      *ngIf="dealTeamRoleColumn.itemList && dealTeamPursuitRoleColumn.itemList && dealTeamSwiftRoleColumn.itemList"
      [header]="dealTeamNameHeader"
      [firstColumnItemList]="firstColumnVisible ? dealTeamRoleColumn : null"
      [secondColumnItemList]="secondColumnVisible ? dealTeamPursuitRoleColumn : null"
      [thirdColumnItemList]="thirdColumnVisible ? dealTeamSwiftRoleColumn : null"
      [valuesList]="dealTeamValues"
      [readonly]="readonly"
      [editable]="editable"
      [dealTeamTable]="true"
      [allowToDuplicateUser]="allowToDuplicateUser"
      (selectedRow)="onDealTeamRowSelection($event)">
    </app-peoplepicker-dynamic-table>
  </div>
  
  <app-modal #Modal>
    <ng-container body>
      <div>
        <h3 class="text-primary text-center text-bigger">
          By adding new Deal Team members, an email
          <br>
          notification will be sent to them.
        </h3>
      </div>
    </ng-container>
    <ng-container footer>
      <div>
          <button class="btn btn-secondary btn-sm bg-modal-button-cancel" (click)="cancelModal()">Cancel</button>
          <button class="btn btn-primary btn-ok" (click)="confirmModal()">OK</button>
      </div>
    </ng-container>
  </app-modal>
  <deal-wbs-setup-app-notify-popup  (deleteConfirmDetails)="deleteConfirmDetails($event)"></deal-wbs-setup-app-notify-popup >
