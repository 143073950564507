import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormValidations } from 'src/shared/utils/form-validations';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { FormListFields } from '../models/form-list-fields';
import { TypeaheadItem } from 'src/shared/models/typeahead-item';
import { AppSettings } from 'src/shared/app-settings';

@Component({
  selector: 'app-engagement-information',
  templateUrl: './engagement-information.component.html',
  styleUrls: ['./engagement-information.component.scss']
})
export class EngagementInformationComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();
  deloitteUSEntityCustomList: string;
  salesOfficeCustomList: string;

  @Input() formGroups: any;
  @Input() formSubmitted: boolean;

  engagementInformationFormGroup: FormGroup;
  myDealFormGroup: any;
  isDisabled:boolean=true;
  constructor(private tooltipHelperService: TooltipHelperService) {
    this.deloitteUSEntityCustomList = AppSettings.deloitteUSEntityList;
    this.salesOfficeCustomList = AppSettings.salesOfficeList;
  }

  ngOnInit() {
    this.engagementInformationFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
  }

  eventDateSelected(event:any, controlName: string) {
    const formGroup:any = this._getFormGroup(controlName);
    if (formGroup) {
      formGroup.get(controlName).setValue(event);
    }
  }

  onModelAfterPriorEngagementSelection(event:any) {
    this.engagementInformationFormGroup.controls.modelAfterPriorEngagement.setValue(event);
    this.evaluateModelAfterPriorEngagementDependency(event);
  }

  evaluateModelAfterPriorEngagementDependency(value:any) {
    if (value === 'YES') {
      this.engagementInformationFormGroup.controls.priorEngagement.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.priorEngagement));
    }
  }

  onIsRecurringWBSSelection(event:any) {
    this.myDealFormGroup.controls.isRecurringWBS.setValue(event);
  }

  onDeloitteUSEntitySelection(event: TypeaheadItem) {
    this.myDealFormGroup.controls.deloitteUSEntity.setValue(event.Key);
  }

  onSalesOfficeSelection(event: TypeaheadItem) {
    this.myDealFormGroup.controls.salesOffice.setValue(event.Value);
  }

  onEngagementIndustrySICCodeChange(event:any) {
    this.myDealFormGroup.controls.engagementIndustrySICCode.setValue(event);
  }

  getControlName(formControlName: string):any {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return formGroup.get(formControlName);
    }
    return null;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameDealWbs(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.engagementInformationFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
