import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { DynamicTableHeader } from 'src/shared/models/dynamic-table-header';
import { DynamicTableDropdownItemList } from 'src/shared/models/dynamic-table-dropdown-item-list';
import { AppSettings } from 'src/shared/app-settings';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
// tslint:disable-next-line:max-line-length
import { PeoplepickerDynamicTableComponent } from 'src/shared/components/peoplepicker-dynamic-table/peoplepicker-dynamic-table.component';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
import { ComplianceService } from '../shared/services/compliance-service';
import { MainService } from 'src/shared/services/main.service';
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action';
import { Observable, catchError, from, map, observable, of, throwError } from 'rxjs';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';

@Component({
  selector: 'app-previous-ppmd-table',
  templateUrl: './previous-ppmd-table.component.html',
  styleUrls: ['./previous-ppmd-table.component.scss']
})
export class PreviousPpmdTableComponent implements OnInit {
  ppmdNameHeader: DynamicTableHeader = new DynamicTableHeader();
  ppmdRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  ppmdPursuitRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  ppmdSwiftRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  sicCodesTooltip: string;
  rowToAdd: PeoplePickerSelectedRow;
  placeholderPreviousPPMD = AppSettings.placeholderPreviousPPMD;

  @Input()
  readonlyPPMD = false;
  @Input()
  readonly = false;

  @Input()
  firstColumnVisible = true;

  @Input()
  secondColumnVisible = true;

  @Input()
  thirdColumnVisible = true;

  @Input()
  ppmdValues: Array<PeoplepickerDynamicTableItem> = [];

  @Input()
  ppmdTableFormGroup: any;

  @Input()
  placeHolderForLastTable: any;

  @Output()
  ppmdRowToStore: EventEmitter<PeoplePickerSelectedRow> = new EventEmitter<PeoplePickerSelectedRow>();

  @ViewChild('Modal', {static: true})
  modal: ModalComponent;

  @ViewChild('DealTeam', {static: true})
  dealTeam: PeoplepickerDynamicTableComponent;

  constructor(private peoplepickerService: PeoplepickerService,
              private ComplianceService: ComplianceService,
              private mainService: MainService,
            private CCLService:ClosingCheckListService) { }

  ngOnInit() {
  }

  setItemId(itemId: number, index: number) {
    this.dealTeam.setItemId(itemId, index);
  }

  onRowSelection(dealTeamRow: PeoplePickerSelectedRow) {
    if (dealTeamRow.actionType === DynamicTableAction.Add) {
      this._handlerNewRow(dealTeamRow);
    } else {
      const dealTeamUsers: PeoplepickerDynamicTableItem[] = this.ppmdTableFormGroup.value;
      const arrayWithoutRowToDelete = dealTeamUsers.filter(user => user.itemId !== dealTeamRow.item.itemId);
      this.ppmdTableFormGroup.setValue([...arrayWithoutRowToDelete]);
      this.ppmdRowToStore.emit(dealTeamRow);
    }
  }

  confirmModal() {
    this._setNewRow();
    this.modal.close();
  }

  cancelModal() {
    if (!this.rowToAdd.item.itemId) {
      this.dealTeam.deleteRow(this.rowToAdd.item.index);
    } else {
      const index = this.dealTeam.rowIndex(this.rowToAdd.item.usersSelected[0]);
      this.dealTeam.removeUserFromRow(
        this.rowToAdd.item.usersSelected[0],
        index
      );
    }
    this.modal.close();
  }

  updateExcludedUsersPPMD(peoplepickerItem:any, index:any) {
    this.dealTeam.deleteExcludeUser(peoplepickerItem, index);
  }

  private _handlerNewRow(dealTeamRow: PeoplePickerSelectedRow) {
    this.rowToAdd = dealTeamRow;
    // this.peoplepickerService.getUserId(this.rowToAdd.item.usersSelected[0].EntityData.Email)
    this.CCLService.GetUserRoleGroups(this.rowToAdd.item.usersSelected[0].mail) //TODO: Change to the commented line when the service is ready
      .subscribe((idUser:any) => {
        const itemIdInTable = this._isUserInTable(AppSettings.compliancePreviousPPMD);
        itemIdInTable.subscribe(idInTable => {
          if (idInTable > 0) {
            const itemId = this.rowToAdd.item.itemId;
            const sameRow = idInTable === itemId;
            this.rowToAdd.item.idUsersSelected = [idUser];

            if (sameRow) {
              this._setNewRow();
            } else {
              const index = this.dealTeam.rowIndex(this.rowToAdd.item.usersSelected[0]);
              this.dealTeam.removeUserFromRow(
                this.rowToAdd.item.usersSelected[0],
                index
              );
              this.dealTeam.showError();
            }
          } else {
            this.rowToAdd.item.idUsersSelected = [idUser];
            this._setNewRow();
          }
        });
      });
  }

  private _setNewRow() {
    const itemIdToInsert = this.rowToAdd.item.itemId;
    const dealTeamRows: PeoplepickerDynamicTableItem[] = this.ppmdTableFormGroup.value;
    if (!itemIdToInsert) {
      this.ppmdTableFormGroup.setValue([...dealTeamRows, this.rowToAdd.item]);
    } else {
      const arrayWithoutRowToUpdate = dealTeamRows.filter(user => user.itemId !== this.rowToAdd.item.itemId);
      this.ppmdTableFormGroup.setValue([...arrayWithoutRowToUpdate, this.rowToAdd.item]);
    }
    this.ppmdRowToStore.emit(this.rowToAdd);
  }

  private _isUserInTable(List:any): Observable<any> {
      let defaultValue:any=0;
      const email = this.rowToAdd.item.usersSelected[0].mail;
      const formId = this.mainService.complianceID;
      if (formId) {
        return from(this.ComplianceService.getDealTeamUserByEmailDynamic_CCL(formId, email, List))
        .pipe(
          map(response => {
          if (response.length) {
            return (response[0].ID);
          } else {
            return (defaultValue);
          }
        })
      )   
      } else {
       return from(defaultValue);
      }
  }
}
