<div id="engagement-detail"
  class="container-fluid border container-border-color container-section"
  [formGroup]="formGroup">
  <div class="row bg-title">
    <span>Master Engagement Details</span>
  </div>
  <div class="content-section">
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('masterEngagementID')"
          [class.edited-externally]="editedExternally('masterEngagementID')"
          [class.conflict-on-merge]="hasMergeConflict('masterEngagementID')">
            Master Engagement ID
        </span>
        <span *ngIf="showAsterisk('masterEngagementID')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
        formControlName="masterEngagementID"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('masterEngagementID')"
        type="text"
        autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('financialPlanID')"
          [class.edited-externally]="editedExternally('financialPlanID')"
          [class.conflict-on-merge]="hasMergeConflict('financialPlanID')">
            Financial Plan ID
        </span>
        <span *ngIf="showAsterisk('financialPlanID')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
        formControlName="financialPlanID"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('financialPlanID')"
        type="text"
        autocomplete="off">
      </div>
    </div>
  </div>
</div>
