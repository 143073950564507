export class SharepointDealMarketingObject{
         ApproachedIntlStrategic: string;
         ApproachedPEG: string;
         ApproachedPEGNoPortCo: string;
         ApproachedTotal: string;
         ApproachedUSStrategic: string;
         AttendedMPIntlStrategic: string;
         AttendedMPPEG: string;
         AttendedMPPEGNoPortCo: string;
         AttendedMPTotal: string;
         AttendedMPUSStrategic: string;
         SentCIMIntlStrategic: string;
         SentCIMPEG: string;
         SentCIMPEGNoPortCo: string;
         SentCIMTotal: string;
         SentCIMUSStrategic: string;
         SubmittedIOIIntlStrategic: string;
         SubmittedIOIPEG: string;
         SubmittedIOIPEGNoPortCo: string;
         SubmittedIOITotal: string;
         SubmittedIOIUSStrategic: string;
         SubmittedLOIIntlStrategic: string;
         SubmittedLOIPEG: string;
         SubmittedLOIPEGNoPortCo: string;
         SubmittedLOITotal: string;
         SubmittedLOIUSStrategic: string;
}