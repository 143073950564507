import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from './custom-date-parser-formatter';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    }
  ]
})

export class DatePickerComponent {

  @Input()
  selectedDate: any = null;

  @Input()
  minDate: Date = new Date(1900, 0, 1);

  @Input()
  maxDate: Date = new Date(8900, 11, 31);

  @Input()
  disabled:any = false;

  @Input()
  readonly: boolean;

  @Input()
  placement = 'bottom-left';

  @Output()
  dateSelected: EventEmitter<Date> = new EventEmitter<Date>();

  invalidDateMsg = '';
  msgFormatError = 'Date format must be MM/DD/YYYY';
  msgMinYearError = 'Valid date must be within the range of 1/1/1900 and 12/31/8900';
  regex = new RegExp(/^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/g);

  constructor(
    private ngbDateParserFormatter: NgbDateParserFormatter) { }

  fromModel(date: Date): NgbDateStruct | any {
    return date ? {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    } : null;
  }

  toModel(date: NgbDateStruct): Date | any {
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }

  getSelectedDate(event:any) {
    this.invalidDateMsg = '';
    let dateToSend: any = null;
    if (typeof(event.type) !== 'undefined' && event.type === 'change') {
      const selectedDate = event.currentTarget.value;
      if (!this._dateMatchRegex(selectedDate)) {
        this._showErrorMessage(event, this.msgFormatError);
      } else {
        const selectedDateObject = this._getSelectedDateObject(selectedDate);
        if (!this._isDateBetweenMinDateAndMaxDate(selectedDateObject)) {
          this._showErrorMessage(event, this.msgMinYearError);
        } else {
          dateToSend = this.ngbDateParserFormatter.parse(selectedDate);
        }
      }
    } else {
      dateToSend = event;
    }
    this.dateSelected.emit(this.toModel(dateToSend));
  }

  protected _dateMatchRegex(selectedDate: string): boolean {
    return !!selectedDate.match(this.regex);
  }

  protected _getSelectedDateObject(selectedDate: string): Date {
    return new Date(selectedDate);
  }

  private _showErrorMessage(event:any, errorMessage: string) {
    event.currentTarget.value = '';
    this.invalidDateMsg = errorMessage;
  }

  private _isDateBetweenMinDateAndMaxDate(selectedDateObject: Date) {
    return selectedDateObject >= this.minDate && selectedDateObject <= this.maxDate;
  }
}
