<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div *ngIf="formRetrieved" id="form-body">
  <div id="form-sections">
    <app-header></app-header>
    <div class="col-xs-2 engagement-sidebar">
      <app-sidebar-engagement-content-breakdown></app-sidebar-engagement-content-breakdown>
    </div>
    <div class="col-xs-10 engagement-body">
      <div class="row engagement-health-details">
        <div class="col-xs-5">
          <span class="engagement-object-title-section">Engagement Health</span>
        </div>
        <div class="col-xs-6">
          <span class="engagement-object-title-section">Deal Team Details</span>
        </div>
        <br>
        <hr style="width: 100%;
        margin-left: 0% !important;">
        <div class="col-xs-5">
          <app-engagement-health-details (engagementHealthUpdate)="updateEngagementHealthData($event)">
          </app-engagement-health-details>
        </div>
        <div class="col-xs-7">          
          <app-deal-team-details *ngIf="!isCurrentUserAdmin">
        </app-deal-team-details>
        <app-deal-team-table  #dealTeam *ngIf="isCurrentUserAdmin"
            [dealTeamTableFormGroup]="appFormGroup.get('dealteamTable')"
            [dealTeamValues]="dealTeamValues"
            (dealTeamRowToStore)="onDealTeamRowSelection($event)">
        </app-deal-team-table>
        </div>
      </div>
      <app-third-party-codes></app-third-party-codes>
      
      <app-deal-team-auditlog></app-deal-team-auditlog>
    </div>
  </div>
</div>

<!-- <app-modal #EngagementHealth>
  <ng-container header>
      <ng-container
          *ngTemplateOutlet="modalHeader">
      </ng-container>
  </ng-container>
  <ng-container body>
      <ng-container
          *ngTemplateOutlet="modalBody">
      </ng-container>
  </ng-container>
  <ng-container footer>
      <ng-container
          *ngTemplateOutlet="modalFooter">
      </ng-container>
  </ng-container>
</app-modal>

<ng-template #modalHeader>
  
</ng-template>
<ng-template #modalBody>
  <h3 class="text-primary text-center text-bigger">You are not allowed to View/edit this form.</h3>
</ng-template>
<ng-template #modalFooter>
  <button class="btn btn-primary btn-ok" (click)="redirectEngagementHealthList(); modal.close()">Ok</button>
</ng-template> -->

<newdeal-app-notify-popup
(addNotifyDetails)="ConfirmDetails($event)"
  (deleteConfirmDetails)="ConfirmDetails($event)"></newdeal-app-notify-popup>

