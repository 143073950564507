import { Component, OnInit, Input } from '@angular/core';
// import { AppSettings } from 'src/app/shared/app-settings';
// import { FormListFields } from 'src/app/new-deal-setup/models/form-list-fields';
import { FormGroup } from '@angular/forms';
 import { DropdownService } from 'src/shared/services/dropwdown.service';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
 import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
 import { FormValidations } from 'src/shared/utils/form-validations';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
 import { TooltipObject } from 'src/shared/models/tooltip-object';
import { AppSettings } from 'src/shared/app-settings';
 import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';

@Component({
  selector: 'app-client-overview',
  templateUrl: './client-overview.component.html',
})
export class ClientOverviewComponent implements OnInit {
  // Dropdown lists
  clientTypeOptions: Array<DropdownItem> = []
  industryOptions: Array<DropdownItem> = [];

  leadClientServicePartner: Array<PeoplePickerUser> = [];
  leadBusinessPartner: Array<PeoplePickerUser> = [];
  globalLCSP: Array<PeoplePickerUser> = [];

   sharePointMapper = new NewDealSetupSharepointMapper();

  @Input() formGroups: Array<FormGroup>;
  @Input() formSubmitted: boolean;

  clientOverviewFormGroup: FormGroup;
  myDealFormGroup: FormGroup;

  constructor(
     private dropdownService: DropdownService,
     private peoplepickerService: PeoplepickerService,
    private tooltipHelperService: TooltipHelperService) {
  }

  ngOnInit() {
    this.clientOverviewFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
    this.clientTypeOptions=AppSettings.ClientTypeListArray;
  }

  setSharepointMapper(mapper: NewDealSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  onTelephoneSelection(event:any) {
    this.myDealFormGroup.controls.telephone.setValue(event);
  }

  onPostalCodeSelection(event:any) {
    this.myDealFormGroup.controls.postalCode.setValue(event);
  }

  onClientTypeSelection(event:any) {
    this.myDealFormGroup.controls.clientType.setValue(event.value);
  }

  // // onIndustrySelection(event) {
  // //   this.myDealFormGroup.controls.industry.setValue(event.value);
  // // }

  onSelectedPeoplePickerUser(user: any, inputName: string) {
    const peoplepickerObject = this._getFormGroupControl(inputName);
    const values = peoplepickerObject.value;
    if(values!=undefined)
    {
      peoplepickerObject.setValue({ results: [...values.results, user] });
    }
    else
    {
      peoplepickerObject.setValue({ results: [user] });
    }
    //Commented below, Because these are Sharepoint Calls to get User ID
    // this.peoplepickerService.getUserId(user.EntityData.Email, user.EntityData.AccountName)
    //   .then((response:any) => {
    //     const values = peoplepickerObject.value;
    //     peoplepickerObject.setValue({ results: [...values.results, response] });
    // });
    return false;
  }

  onRemovedPeoplePickerUser(user: any, inputName: string) {

    const peoplepickerObject = this._getFormGroupControl(inputName);
    const values = peoplepickerObject.value;
    //Commented below for the LCSP Update issue
   // peoplepickerObject.setValue({ results: values.results.filter((x:any) => x.id !== user.id) });
   peoplepickerObject.setValue({ results: values.results.filter((x:any) => x.displayName!== user.displayName) });
 //Commented below, Because these are Sharepoint Calls to delete that User ID
    // this.peoplepickerService.getUserId(user.EntityData.Email, user.Key)
    //   .then((response:any) => {
    //     const values = peoplepickerObject.value;
    //     peoplepickerObject.setValue({ results: values.results.filter((x:any) => x !== response) });
    // }); 
    return false;
  }

  setLeadClientServicePartner(leadClientServicePartner:any) {
    this.leadClientServicePartner = leadClientServicePartner;
  }

  setLeadBusinessPartner(leadBusinessPartner:any) {
    this.leadBusinessPartner = leadBusinessPartner;
  }

  setGlobalLCSP(globalLCSP:any) {
    this.globalLCSP = globalLCSP;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  getTooltip(fieldName: any): any {
  return this.tooltipHelperService.getTooltipByFieldName(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  private _getFormGroupControl(formControlName: string): any {
    const formGroup = this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
    return formGroup.get(formControlName);
  }

}
