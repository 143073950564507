<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<!-- <div id="form-body"> -->
  <!-- Fade HTML Logic -->
  <div id="loadingfade" style="display: none;">
    <div id="fadeloader" style="display: none;"></div>
    <app-notify-popup (addNotifyDetails)="addNotifyDetails($event)"></app-notify-popup>
  </div>
  <div class="container" style="width: 100%;">
    <div class="row">
      <div class="col-xs-6"><div class="headerManagement">Engagement Dashboard</div> </div>
      </div>
      <div class="row">
        <div class="col-xs-3">
          <div id="search-container">
            <app-search-box
              (reportListToEmit)="onSearchResult($event)">
            </app-search-box>
          </div>
        </div>
        <div class="col-xs-4">
          <div id="show-deals-container">
            <!-- [ngStyle]="!showClosedDeals && {'opacity': '0.5'}" -->
            <button
              type="button"
              class="engagement-dashboard-button"
              [ngStyle]="{'opacity': !showClosedDeals?'0.5':'1'}"
              (click)="toggleClosedDeals()">
              Show Lost / Closed deals
            </button>
            <button
              type="button"
              class="clear-filters-button"
              (click)="clearFilters()">
              Clear Filters
            </button>
          </div>
        </div>
        <div class="col-xs-2">
          <div id="add-new-deal-container">
            <div class="engagement-dashboard-button"
                 (click)="addNewDeal()">
              + Add New Deal
            </div>
          </div>
        </div>
        <!-- <div class="col-xs-2" *ngIf="showReportDashhboard" style="float:right; width: fit-content;">
          <div id="add-new-deal-container">
            <div class="engagement-dashboard-button"
                 (click)="openCrossBorder()">
                 CrossBorder Dashboard
            </div>
          </div>
        </div> -->
        <!-- <div class="col-xs-2" *ngIf="showReportDashhboard" style="float: right;width: fit-content;">
          <div id="add-new-deal-container">
            <div class="engagement-dashboard-button">
              S24 Report Dashboard
            </div>
          </div>
        </div> -->
    </div>
    <div id="engagement-table-container" class="row">
      <app-engagement-table
        (showDealClosedState)="onShowDealClosedState($event)"
      ></app-engagement-table>
    </div>
  </div>
<!-- </div> -->