import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { FormGroup } from '@angular/forms';
import { AppSettings } from 'src/shared/app-settings';
import { FormListFields } from 'src/app//models/form-list-fields';
import { ToggleYesNoComponent } from 'src/shared/components/toggle-yes-no/toggle-yes-no.component';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { TypeaheadItem } from 'src/shared/models/typeahead-item';
import { FormValidations } from '../../../shared/utils/form-validations';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { NewDealSetupSharepointMapper } from '../../../shared/utils/new-deal-setup-sharepoint';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';

@Component({
  selector: 'app-prd',
  templateUrl: './prd.component.html',
})
export class PrdComponent implements OnInit {

  employessWorkingPlacesList: Array<DropdownItem> = [];
  // deloitteUSEntityCustomList: string;
  salesOfficeCustomList: string;
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];
  sharePointMapper = new NewDealSetupSharepointMapper();
  myDealFormGroup: FormGroup;

  @ViewChild ('yesNoRecurringWBS')
  yesNoRecurringWBS: ToggleYesNoComponent;

  @Input() formGroup: FormGroup;
  @Input() formSubmitted: boolean;

  @Output() deloitteUSEntityChange = new EventEmitter<TypeaheadItem>();
  @Output() salesOfficeChange = new EventEmitter<TypeaheadItem>();

  constructor(
      private dropdownService: DropdownService,
      private tooltipHelperService: TooltipHelperService) {
      // this.deloitteUSEntityCustomList = AppSettings.deloitteUSEntityCustomList;
      this.salesOfficeCustomList = AppSettings.salesOfficeCustomList;
  }

  private recurringWBSSetManually: boolean;

  ngOnInit() {
    this.myDealFormGroup = this.formGroup;
    this.employessWorkingPlacesList = AppSettings.WhereWorkMemberFirmsListArray;
  }

  setSharepointMapper(mapper: NewDealSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  setDealTeamValues(dealTeamValues: PeoplepickerDynamicTableItem[]) {
    this.dealTeamValues = dealTeamValues;
  }
 
  showDealTeamRowsComplete(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.dealTeamValues) {
      this.dealTeamValues.forEach(row => {
       // if (row && row.isComplete()) {
          if (row&&row.itemId>0) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }

  setMarketOffering(event:any) {
    this.myDealFormGroup.controls.marketOffering.setValue(event);
  }

  // onClientIDSelection(event) {
  //   this.myDealFormGroup.controls.clientID.setValue(event);
  // }

  onIsRecurringWBSSelection(event:any) {
    this.myDealFormGroup.controls.isRecurringWBS.setValue(event);
    this.recurringWBSSetManually = true;
  }

  onAreResourcesWorkOutsideSelection(event:any) {
    this.myDealFormGroup.controls.areResourcesWorkOutside.setValue(event);
  }

  onEmployessWorkingPlacesSelection(event:any) {
    this.myDealFormGroup.controls.whereWorkMemberFirms.setValue(event.value);
  }

  onOtherMemberFirmsParticipatingSelection(event:any) {
    this.myDealFormGroup.controls.otherMemberFirmsParticipating.setValue(event);
  }

  onMemberFirmTaxablePresenceSelection(event:any) {
    this.myDealFormGroup.controls.memberFirmTaxablePresence.setValue(event);
  }

  onInternationalEngagementSelection(event:any) {
    this.myDealFormGroup.controls.isInternationalEngagement.setValue(event);
    this.evaluateIsThisAInternationalEngagementDependency(event);
  }

  // onDeloitteUSEntitySelection(event: TypeaheadItem) {
  //   this.myDealFormGroup.controls.deloitteUSEntity.setValue(event.Key);
  //   this.deloitteUSEntityChange.emit(event);
  // }

  onSalesOfficeSelection(event: TypeaheadItem) {
    //this.myDealFormGroup.controls.salesOffice.setValue(event.Key);
    this.myDealFormGroup.controls.salesOffice.setValue(event.Value);
    this.salesOfficeChange.emit(event);
  }

  getRecurringWBSSetManually(): boolean {
    return this.recurringWBSSetManually;
  }

  setRecurringWBS(value: string) {
    this.myDealFormGroup.controls.isRecurringWBS.setValue(value);
    this.yesNoRecurringWBS.toggleButtonActive(value, false);
  }

  evaluateIsThisAInternationalEngagementDependency(value: string) {
    if (value === 'YES') {
      this.myDealFormGroup.controls.areResourcesWorkOutside.enable();
      this.myDealFormGroup.controls.memberFirmTaxablePresence.enable();
      this.myDealFormGroup.controls.otherMemberFirmsParticipating.enable();
      this.myDealFormGroup.controls.whereWorkMemberFirms.enable();
      if((this.myDealFormGroup.get('isInternationalEngagement')as CustomFormControl).isReadOnly()){
        (this.myDealFormGroup.controls.areResourcesWorkOutside as CustomFormControl).setReadOnly();
        (this.myDealFormGroup.controls.memberFirmTaxablePresence as CustomFormControl).setReadOnly();
        (this.myDealFormGroup.controls.otherMemberFirmsParticipating as CustomFormControl).setReadOnly();
        (this.myDealFormGroup.controls.whereWorkMemberFirms as CustomFormControl).setReadOnly();
      }
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.areResourcesWorkOutside));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.memberFirmTaxablePresence));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.ohterMemberFirmsParticipating));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.whereWorkMemberFirms));
    }
  }

  showAsterisk(formControlName: string): boolean {
      return FormValidations.showAsterisk(this.myDealFormGroup, formControlName);
  }

  highlightRequiredField(formControlName: string): boolean {
      return FormValidations.highlightRequiredField(this.formSubmitted, this.myDealFormGroup, formControlName);
  }

  editedExternally(formControlName: string): boolean {
      return FormValidations.editedExternally(this.myDealFormGroup, formControlName);
    }

  hasMergeConflict(formControlName: string): boolean {
      return FormValidations.hasMergeConflict(this.myDealFormGroup, formControlName);
  }

  isReadOnly(formControlName: string): boolean {
      return FormValidations.isReadOnly(this.myDealFormGroup, formControlName);
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldName(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _setAsDisabled(formControlName: string) {
      const controlName = this.myDealFormGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
  }
}
