import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormValidations } from 'src/shared/utils/form-validations';
import { FormListFields } from '../models/form-list-fields';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { NumericComponent } from 'src/shared/components/numeric/numeric.component';

@Component({
  selector: 'app-wbs-element-classification',
  templateUrl: './wbs-element-classification.component.html',
  styleUrls: ['./wbs-element-classification.component.scss']
})
export class WBSElementClassificationComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();

  @Input() formGroups: any;
  @Input() formSubmitted: boolean;

  wbsElementFormGroup: FormGroup;
  myDealFormGroup: any;
  isDisabled:boolean=true;
  constructor(private tooltipHelperService: TooltipHelperService) { }

  ngOnInit() {
    this.wbsElementFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
  }

  getControlName(formControlName: string):any {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return formGroup.get(formControlName);
    }
    return null;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  onOpportunityIDChange(event:any) {
    this.myDealFormGroup.controls.opportunityID.setValue(event);
  }

  evaluateIsServiceSameAsClientAddressDependency(value:any) {
      if (value === 'NO') {
        this.myDealFormGroup.controls.serviceAtAddress.enable();
      } else {
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.serviceAtAddress));
      }
      return false;
  }

  onIsServiceSameAsClientAddressSelection(event:any) {
    this.myDealFormGroup.controls.isServiceSameAsClientAddress.setValue(event);
    this.evaluateIsServiceSameAsClientAddressDependency(event);
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameDealWbs(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.myDealFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

}
