import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core"; 
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppSettings } from "src/shared/app-settings";
@Injectable()

@Injectable({
    providedIn: 'root'
  })
  
export class TerminationFormService{

  constructor(private myhttp: HttpClient){

  }

  GetAuditDetails(formId:string):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Terminationform)
    const url = envURL + '/api/TerminationFormAuditDetails/GetList?formID=' + formId;   
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }

  GetS24Users(): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
       const url = envURL+'/api/UserGroups/GetUserGroupsByRole?role=MyDeal_S24'; 
      return this.myhttp.get(url, {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("idToken")
          })
      })
  }

  SaveFormDetails(data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Terminationform)
    const url = envURL +'/api/TerminationForm/Add'
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});    
  }
  UpdateFormDetails(data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Terminationform)
    const url = envURL +'/api/TerminationForm/Update'
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});    
  }

  GetFormDetails(formId?:string):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Terminationform)
    var url;
    if(formId==undefined){
      url = envURL + '/api/TerminationForm/GetList'; 
    }
    else{
      url = envURL + '/api/TerminationForm/GetList?formID=' + formId;   
    }
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }

  GetUserRoleGroups(userId: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType, environment.RoleManagement)
    const url = envURL + '/api/UserGroups/GetUserGroupsByEmail?email=' + userId;
    return this.myhttp.get(url, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem("idToken")
        })
    })
  }
  
  GetDealADGroupDetails(): Observable<any> {
    const url = AppSettings.GetDealADGroupDetails();
    return this.myhttp.get(url, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem("accessToken")
        })
    })
  }

}
