import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { NAFormatterService } from "../shared/utils/utils-handlingNA";
import { DropdownItem } from "../../../shared/models/dropdown-item";
import { DropdownService } from "../../../shared/services/dropwdown.service";
import { TooltipHelperService } from "../../../shared/services/tooltip-helper.service";
import { FormListFields } from "../shared/models/form-list-fields";
import { AppSettings } from "../../../shared/app-settings";
import { FormValidations } from "../../../shared/utils/form-validations";
import { TooltipObject } from "../../../shared/models/tooltip-object";

@Component({
  selector: "app-deal-structure",
  templateUrl: "./deal-structure.component.html",
  styleUrls: ["./deal-structure.component.scss"],
})
export class DealStructureComponent implements OnInit {
  sharePointMapper = new ComplianceSharepointMapper();
  dealStructureFormGroup: any;
  valuationMetricsFormGroup: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;
  legalStructureOptions: Array<DropdownItem> = AppSettings.legalStructureOptions;
  managementFeeOptions: Array<DropdownItem> = AppSettings.managementFeeOptions;
  constructor(private dropdownService: DropdownService,private tooltipHelperService: TooltipHelperService,private naFormatService:NAFormatterService) {}
  ngOnInit() {
    this.dealStructureFormGroup = this.formGroups[0];
    this.valuationMetricsFormGroup = this.formGroups[1];
    // this.dropdownService
    //   .fillDropdownOptions_ClosingCheckList(
    //     AppSettings.closingChecklistForm,
    //     FormListFields.legalStructure
    //   )
    //   .subscribe((response) => {
    //     this.legalStructureOptions = response;
    //   })
      
    // this.dropdownService
    //   .fillDropdownOptions_ClosingCheckList(
    //     AppSettings.closingChecklistForm,
    //     FormListFields.managementFee
    //   )
    //   .subscribe((response) => {
    //     this.managementFeeOptions = response;
    //   })
     
    this.contingentTotalEVPctCalculate();
    this.evaluateTriggertType();
  }
  contingentTotalEVPctCalculate() {
    let contingentTotalEVPct = 0;
    contingentTotalEVPct =
      Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("contingentConsideration")?.value)
      ) /
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration")?.value));
    this.dealStructureFormGroup.controls.contingentTotalEVPct.setValue(
      isFinite(contingentTotalEVPct) ? contingentTotalEVPct.toString() : ""
    );
  }
  setFormGroup(formGroup: FormGroup) {
    this.valuationMetricsFormGroup = formGroup;
    this.contingentTotalEVPctCalculate();
    this.stockConsiderationTotalEVPctCalculate();
    this.eqiutyContributionCalculate();
    this.evaluateTriggertType();
  }
  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }
  onSelectedValue(event: any, columnName: any) {
    this.dealStructureFormGroup.controls[columnName].setValue(event);
  }
  stockConsiderationTotalEVPctCalculate() {
    let stockConsiderationTotalEVPct = 0;
    stockConsiderationTotalEVPct =
      (Number(this.naFormatService.formatNA(this.dealStructureFormGroup.get("stockConsideration")?.value)) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration")?.value)
        )) *
      1000;
    this.dealStructureFormGroup.controls.stockConsiderationTotalEVPct.setValue(
      isFinite(stockConsiderationTotalEVPct)
        ? stockConsiderationTotalEVPct.toString()
        : ""
    );
  }
  onStockConsideration(event: any, columnName: any) {
    this.dealStructureFormGroup.controls[columnName].setValue(event);
    this.stockConsiderationTotalEVPctCalculate();
  }
  eqiutyContributionCalculate() {
    let equityContributionTotalPct = 0;
    equityContributionTotalPct =
      ((Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration")?.value)) -
        Number(this.naFormatService.formatNA(this.dealStructureFormGroup.get("totalLeverage")?.value))) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration")?.value)
        )) *
      100;
    this.dealStructureFormGroup.controls.equityContributionTotalPct.setValue(
      isFinite(equityContributionTotalPct)
        ? equityContributionTotalPct.toString()
        : ""
    );

    let equityContributionClosingPct = 0;
    equityContributionClosingPct =
      ((Number(
        this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("closingConsideration")?.value)
      ) -
        Number(this.naFormatService.formatNA(this.dealStructureFormGroup.get("totalLeverage")?.value))) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("closingConsideration")?.value)
        )) *
      100;
    this.dealStructureFormGroup.controls.equityContributionClosingPct.setValue(
      isFinite(equityContributionClosingPct)
        ?equityContributionClosingPct.toString()
        : ""
    );
  }
  onTotalLeverage(event: any, columnName: any) {
    this.dealStructureFormGroup.controls[columnName].setValue(event);
    this.eqiutyContributionCalculate();
  }
  onLegalStructureeSelection(event: any) {
    this.dealStructureFormGroup.controls.legalStructure.setValue(event.key);
  }
  onManagementFeeSelection(event: any) {
    this.dealStructureFormGroup.controls.managementFee.setValue(event.key);
    this.evaluateManagementFee(event.key);
  }
  evaluateManagementFee(value: any) {
    if (value === "Yes - %") {
      this.dealStructureFormGroup.controls.managementFeePct.enable();
      this._setAsDisabled("managementFeeQty");
    } else if (value === "Yes - $") {
      this.dealStructureFormGroup.controls.managementFeeQty.enable();
      this._setAsDisabled("managementFeePct");
    } else {
      this._setAsDisabled("managementFeePct");
      this._setAsDisabled("managementFeeQty");
    }
  }
  evaluateTriggertType(){
    if(!Number(this.valuationMetricsFormGroup.get("totalConsideration")?.value)){
    this._setAsDisabled("triggerType");
    this._setAsDisabled("timeUntilContingentPayment");
    }
    else{
      this.dealStructureFormGroup.controls.triggerType.enable();
      this.dealStructureFormGroup.controls.timeUntilContingentPayment.enable();
    }
  }
  evaluateproviderNameType(value: any){
      if(value === null || value === AppSettings.nonBankLender|| value=== AppSettings.bankLender||value === AppSettings.privateEquityGroup){
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.stockConsideration));
    }
      else{
       this.dealStructureFormGroup.controls.stockConsideration.enable();
      }
  }
  evaluateBuyerType(value: any){
    if(value === null || value === AppSettings.nonBankLender|| value=== AppSettings.bankLender||value === AppSettings.privateEquityGroup){
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.stockConsideration));
  }
    else{
     this.dealStructureFormGroup.controls.stockConsideration.enable();
    }
}
  private _setAsDisabled(formControlName: string) {
    const controlName = this.dealStructureFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }
  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }
  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(
        this.formSubmitted,
        formGroup,
        formControlName
      );
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }
  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
  getTooltip(tooltipTitle: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
  }

  getTooltipText(tooltipTitle:string):string{
    const tooltip = this.getTooltip(tooltipTitle);
    return tooltip ? tooltip.getTooltipText() : '';
  }
}
