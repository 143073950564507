import {
  SharepointMapper,
  SharepointValidatorName,
  SharepointValidator,
  SharepointMappingElement
} from 'src/shared/utils/sharepoint-mapper';
import { ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from 'src/shared/custom/custom-validators';

export class DealWbsSetupSharepointMapper extends SharepointMapper {

  constructor(elements: SharepointMappingElement[] = []) {
    super(elements);
    this.elementsFormGroup = elements;
  }

  protected override getValidator(sharepointValidator: SharepointValidator, formControlName: string):any {
    let validator: any;

    switch (sharepointValidator.Validator) {
      case SharepointValidatorName.required:
        switch (formControlName) {
          case 'participatingManager':
            validator = CustomValidators.PeoplePickerRequired;
            break;
          case 'dealteamTable':
            validator = CustomValidators.PeoplePickerDynamicTableRequired;
            break;
          case 'documentTypesAttachments':
            validator = CustomValidators.DocumentTypesAttachmentsRequired;
            break;
          default:
            // validator = Validators.required;
            validator = CustomValidators.InputWhithoutWhiteSpaces;
            break;
        }
        break;

      default:
        break;
    }

    return validator;
  }
}
