export class SharepointValuationObject{
        IOIValueClosingLow:string;
        IOIValueClosingHigh:string;
        IOIValueContingent:string;
        IOIValueTotalHigh:string;
        IOIValueClosingAvg:string;
        IOIValueTotalAvg:string;
        LOIValueClosingLow:string;
        LOIValueClosingHigh:string;
        LOIValueContingent:string;
        LOIValueTotalHigh:string;
        LOIValueClosingAvg:string;
        LOIValueTotalAvg:string;
        NumericalPriceIncClosingLow:string;
        NumericalPriceIncClosingHigh:string;
        NumericalPriceIncContingent:string;
        NumericalPriceIncTotalHigh:string;
        NumericalPriceIncClosingAvg:string;
        NumericalPriceIncTotalAvg:string;
        PercentagePriceIncClosingLow:string;
        PercentagePriceIncClosingHigh:string;
        PercentagePriceIncContingent:string;
        PercentagePriceIncTotalHigh:string;
        PercentagePriceIncClosingAvg:string;
        PercentagePriceIncTotalAvg:string
}