import { Component, OnInit, ViewChild } from '@angular/core';
import { EngagementHealthObjectService } from '../shared/services/engagement-health-object.service';
import { EngagementHealthObjectItem } from '../models/engagement-health-object-item';
import { AvailableFormItem } from 'src/shared/models/available-form-item';
import { AppSettings } from 'src/shared/app-settings';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { DatePipe } from '@angular/common';
import { MainService } from 'src/shared/services/main.service';
//import { t } from '@angular/core/src/render3';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

 
  showAdminSection:boolean=false;
  engagementId: number;
  projectName: string;
  availableFormItem: AvailableFormItem;
  availableFormsList: Array<AvailableFormItem> = [];
  engagementHealthObject: EngagementHealthObjectItem;

  @ViewChild('EngagementHealth')
  modal: ModalComponent;

  modalType: string;

  constructor(
    private engagementHealthObjectService: EngagementHealthObjectService,private datePipe: DatePipe,private mainService: MainService) { }

  ngOnInit() {
    //this._changeDflowLogoHref();
  }

  onclick(target:any) {
    const selector = document.querySelector(target);
    if (selector) {
      selector.scrollIntoView({behavior:'smooth'});
    }
  }
  private _openModal(modalType: string) {
    this.modalType = modalType;
    this.modal.open();
  }
  setProperties(engagementHealthObject: EngagementHealthObjectItem,isAdmin:boolean) {
    this.engagementHealthObject = engagementHealthObject;
    this.showAdminSection=isAdmin;
  }

  reloadPage() {
    window.location.href = document.URL;
    window.location.reload();
  }

  setEngagementTitle(header: EngagementHealthObjectItem) {
    this.engagementId = header.newDealId;
    this.projectName = header.engagementName;
    const engagementTitle:any = document.querySelector('.engagement-title a');
    engagementTitle.setAttribute('onclick', 'window.location.href = document.URL;location.reload();');
  }

  openNewTab(formItemId: number, pageUrl: string) {
    window.open(this.getFormUrl(formItemId, pageUrl));
  }

  getFormUrl(formItemId: number, pageUrl: string): string {
    if (formItemId) {
      const baseUrl = this.engagementHealthObjectService.getBaseUrl(AppSettings.myDealFormList);
      return `${baseUrl}/Pages/${pageUrl}`.replace('{formId}', formItemId.toString());
    } else {
      return '';
    }
  }

  openMyDeal() {
    
    this.openNewTab(this.engagementHealthObject.myDealId, AppSettings.myDealUrl);
  }

  private _changeDflowLogoHref() {
    const dcfLogo:any = document.querySelector('#DeltaSiteLogo a');
    dcfLogo.removeAttribute('href');
    dcfLogo.setAttribute('onclick', 'window.location.href = document.URL;location.reload();');
  }
}
