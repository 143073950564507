
<div id="footer" style="height:50%;width:60%;margin:auto;justify-content:center;display:flex;">
  <br/><br/>
    <p style="font-size:small">
      Copyright ©
      {{year}}
      Deloitte Financial Advisory Services LLP. All rights reserved.<br>
      Member of Deloitte Touche Tohmatsu Limited&nbsp;|&nbsp;<a href="https://deloittenet.deloitte.com/system/Pages/DeloitteNet_Web_Privacy_statement.aspx">Deloitte Web Privacy Notice</a>
      &nbsp;|&nbsp;<a class="Header1" target="_blank" href="https://cookienotice.deloitte.com">Cookies</a>
      &nbsp;|&nbsp;
      <a class="optanon-toggle-display" style="cursor:pointer">Cookie Settings</a>
    </p>
  </div>
  