<div id="indemnification" [formGroup]="indemnificationFormGroup" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span>Indemnification</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
        <p>These should all come from the Purchase Agreement. If you are unsure of any, consult with the deal team.</p>
        <p>
                <span class="tooltiptext-dcf-bold">Cap</span>
                - Total $ amount that seller is liable for.
            <br>
                <span class="tooltiptext-dcf-bold">Survival</span>
                - How long post-closing can the buyer recover losses from the seller for breach of the reps/warranties.
            <br>
                <span class="tooltiptext-dcf-bold">Escrow</span>
                - There is generally always an escrow. This should just be the basic escrow amount, any reconciliation or second escrow is captured below.
            <br>
                <span class="tooltiptext-dcf-bold">Reconciliation Escrow</span>
                - This is a second escrow for things such as post-closing working capital adjustments or for a special indemnity.
            <br>
                <span class="tooltiptext-dcf-bold">Deductible/Threshold</span>
                - The dollar amount of post-closing claims from the buyer which must be exceeded before the buyer may pursue a refund for the claim from the seller.
            <br>
                <span class="tooltiptext-dcf-bold">Basket Type -</span>
                <br>
                <span class="tooltiptext-dcf-underline">True Deductible</span>
                : Seller must indemnify the buyer only for claims <span class="tooltiptext-dcf-underline">above</span> the threshold amount.
            <br>
                <span class="tooltiptext-dcf-underline">Tipping/Dollar-One</span>
                : Once claims from the buyer reach the basket amount defined, repayment by the seller will include the <span class="tooltiptext-dcf-underline">total</span>
                of all claims (not just the amount above the threshold).
            <br>
                <span class="tooltiptext-dcf-underline">Combination</span>
                : Much less common. Deals that have both a deductible and a threshold. Once claims reach the threshold amount (which is higher than deductible), 
            sellers are responsible for the losses in excess of the deductible.
            <br>
                <span class="tooltiptext-dcf-bold">Special Indemnification</span>
                - Standalone indemnity that covers a specific topic separate and apart from the general indemnities not subject to all of the above terms and generally not subject to breach. 
            Usually a high-risk item or known issue the buyer does not want to take responsibility for post-close. Examples include taxes or environmental issues.
            
        </p>
        </span>
    </div>
    <div id="indemnification-section" class="content-section">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('capNumerical')"
                    [class.edited-externally]="editedExternally('capNumerical')"
                    [class.conflict-on-merge]="hasMergeConflict('capNumerical')">Cap ($000s)</span>
                    <span *ngIf="showAsterisk('capNumerical')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="CapNumerical" name="CapNumerical" class="input-data-number input-number one-decimal-input" operations="CapPercentageEV,CapPercentageClosing"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'currencyWithDecimal'"
                    [readonly]="isReadOnly('capNumerical')"
                    [value]="indemnificationFormGroup.get('capNumerical').value"
                    (selectedValue)="onCapNumerical($event,'capNumerical')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('capPercentageEV')"
                    [class.edited-externally]="editedExternally('capPercentageEV')"
                    [class.conflict-on-merge]="hasMergeConflict('capPercentageEV')">Cap (% of Total EV)</span>
                    <span *ngIf="showAsterisk('capPercentageEV')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="CapPercentageEV" name="CapPercentageEV" class="input-data-number input-number percentage-input" formula="( CapNumerical * 100 ) / ( TotalConsideration )" format="percentage" disabled><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'percentage'"
                    [placeholder]="'N/A'"
                    [readonly]="isReadOnly('capPercentageEV')"
                    [value]="indemnificationFormGroup.get('capPercentageEV').value"
                    [disabled]="indemnificationFormGroup.get('capPercentageEV').disabled"
                    >
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('capPercentageClosing')"
                    [class.edited-externally]="editedExternally('capPercentageClosing')"
                    [class.conflict-on-merge]="hasMergeConflict('capPercentageClosing')">Cap (% of Closing)</span>
                    <span *ngIf="showAsterisk('capPercentageClosing')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input
                        type="text"
                        id="CapPercentageClosing"
                        name="CapPercentageClosing"
                        class="input-data-number input-number percentage-input"
                        formula="( CapNumerical ) / ( ClosingConsideration ) * ( 100 )"
                        format="percentage"
                        disabled>
                    <br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'percentage'"
                    [readonly]="isReadOnly('capPercentageClosing')"
                    [placeholder]="'N/A'"
                    [value]="indemnificationFormGroup.get('capPercentageClosing').value"
                    [disabled]="indemnificationFormGroup.get('capPercentageClosing').disabled"
                    >
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('survivalMonths')"
                    [class.edited-externally]="editedExternally('survivalMonths')"
                    [class.conflict-on-merge]="hasMergeConflict('survivalMonths')">Survival (Months)</span>
                    <span *ngIf="showAsterisk('survivalMonths')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="SurvivalMonths" name="SurvivalMonths" class="input-data-number input-number positive-integer-input"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'number'"
                    [readonly]="isReadOnly('survivalMonths')"
                    [value]="indemnificationFormGroup.get('survivalMonths').value"
                    (selectedValue)="onSelectedValue($event,'survivalMonths')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('escrowNumerical')"
                    [class.edited-externally]="editedExternally('escrowNumerical')"
                    [class.conflict-on-merge]="hasMergeConflict('escrowNumerical')">Escrow ($000s)</span>
                    <span *ngIf="showAsterisk('escrowNumerical')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="EscrowNumerical" name="EscrowNumerical" class="input-data-number input-number non-decimal-input" operations="EscrowTotalEV,EscrowClosing"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'currency'"
                    [readonly]="isReadOnly('escrowNumerical')"
                    [value]="indemnificationFormGroup.get('escrowNumerical').value"
                    (selectedValue)="onEscrowNumerical($event,'escrowNumerical')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('escrowTotalEV')"
                    [class.edited-externally]="editedExternally('escrowTotalEV')"
                    [class.conflict-on-merge]="hasMergeConflict('escrowTotalEV')">Escrow (% Total EV)</span>
                    <span *ngIf="showAsterisk('escrowTotalEV')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="EscrowTotalEV" name="EscrowTotalEV" class="input-data-number input-number percentage-input" formula="( EscrowNumerical ) * ( 100 ) / ( TotalConsideration )" format="percentage" disabled><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'percentage'"
                    [placeholder]="'N/A'"
                    [readonly]="isReadOnly('escrowTotalEV')"
                    [value]="indemnificationFormGroup.get('escrowTotalEV').value"
                    [disabled]="indemnificationFormGroup.get('escrowTotalEV').disabled"
                    >
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('escrowClosing')"
                    [class.edited-externally]="editedExternally('escrowClosing')"
                    [class.conflict-on-merge]="hasMergeConflict('escrowClosing')">Escrow (% Closing)</span>
                    <span *ngIf="showAsterisk('escrowClosing')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="EscrowClosing" name="EscrowClosing" class="input-data-number input-number non-decimal-input" formula="( EscrowNumerical ) * ( 100 ) / ( ClosingConsideration )" format="percentage" disabled><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'percentage'"
                    [placeholder]="'N/A'"
                    [readonly]="isReadOnly('escrowClosing')"
                    [value]="indemnificationFormGroup.get('escrowClosing').value"
                    [disabled]="indemnificationFormGroup.get('escrowClosing').disabled"
                    >
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('releaseMonths')"
                    [class.edited-externally]="editedExternally('releaseMonths')"
                    [class.conflict-on-merge]="hasMergeConflict('releaseMonths')">Release (Months)</span>
                    <span *ngIf="showAsterisk('releaseMonths')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="ReleaseMonths" name="ReleaseMonths" class="input-data-number input-number positive-integer-input"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'number'"
                    [readonly]="isReadOnly('releaseMonths')"
                    [value]="indemnificationFormGroup.get('releaseMonths').value"
                    (selectedValue)="onSelectedValue($event,'releaseMonths')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('reconciliationEscrow')"
                    [class.edited-externally]="editedExternally('reconciliationEscrow')"
                    [class.conflict-on-merge]="hasMergeConflict('reconciliationEscrow')">Reconciliation Escrow</span>
                    <span *ngIf="showAsterisk('reconciliationEscrow')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <select name="ReconciliationEscrow" id="ReconciliationEscrow" class="input-data-select input-select section-select enableDependencyInput" disable-target-input="IfOtherWhat" disable-target-value-for-ifotherwhat="Special Indemnification,Post-Closing Adjustment,None" value-when-input-disabled-for-ifotherwhat="N/A">
                        <option value="0" disabled selected>Select your option</option>
                    </select> -->
                    <app-dropdown
                    [optionsList]="reconciliationEscrowOptions"
                    [keySelected]="indemnificationFormGroup.get('reconciliationEscrow').value"
                    [readonly]="isReadOnly('reconciliationEscrow')"
                    (optionSelected)="onReconciliationEscrowSelection($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('ifOtherWhat')"
                    [class.edited-externally]="editedExternally('ifOtherWhat')"
                    [class.conflict-on-merge]="hasMergeConflict('ifOtherWhat')">If other, what?</span>
                    <span *ngIf="showAsterisk('ifOtherWhat')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <input class="input-data-text full-width section-input" id="ifOtherWhat" 
                    [formControl]="indemnificationFormGroup.get('ifOtherWhat')" type="text"  
                    [placeholder]="indemnificationFormGroup.get('ifOtherWhat').disabled ? 'N/A' : ''"
                    [class.readonly]="isReadOnly('ifOtherWhat')" 
                    [disabled]="indemnificationFormGroup.get('ifOtherWhat').disabled">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('deductibleNumerical')"
                    [class.edited-externally]="editedExternally('deductibleNumerical')"
                    [class.conflict-on-merge]="hasMergeConflict('deductibleNumerical')">Deductible/Threshold ($000s)</span>
                    <span *ngIf="showAsterisk('deductibleNumerical')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="DeductibleNumerical" name="DeductibleNumerical" class="input-data-number input-number non-decimal-input" operations="DeductibleEV,DeductibleClosing"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'currency'"
                    [readonly]="isReadOnly('deductibleNumerical')"
                    [value]="indemnificationFormGroup.get('deductibleNumerical').value"
                    (selectedValue)="onDeductibleNumerical($event,'deductibleNumerical')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('deductibleEV')"
                    [class.edited-externally]="editedExternally('deductibleEV')"
                    [class.conflict-on-merge]="hasMergeConflict('deductibleEV')">Deductible/Threshold (% Total EV)</span>
                    <span *ngIf="showAsterisk('deductibleEV')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input
                        type="text"
                        id="DeductibleEV"
                        name="DeductibleEV"
                        class="input-data-number input-number percantage-input"
                        formula="( DeductibleNumerical ) / ( TotalConsideration ) * ( 100 )"
                        format="percentage"
                        disabled>
                    <br>
                    <span class="numeric-validation-msg"></span> -->
                        <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'percentage'"
                    [placeholder]="'N/A'"
                    [readonly]="isReadOnly('deductibleEV')"
                    [value]="indemnificationFormGroup.get('deductibleEV').value"
                    [disabled]="indemnificationFormGroup.get('deductibleEV').disabled"
                    >
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('deductibleClosing')"
                    [class.edited-externally]="editedExternally('deductibleClosing')"
                    [class.conflict-on-merge]="hasMergeConflict('deductibleClosing')">Deductible/Threshold (% Closing)</span>
                    <span *ngIf="showAsterisk('deductibleClosing')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input
                        type="text"
                        id="DeductibleClosing"
                        name="DeductibleClosing"
                        class="input-data-number input-number percantage-input"
                        formula="( DeductibleNumerical ) / ( ClosingConsideration ) * ( 100 )"
                        format="percentage"
                        disabled>
                    <br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                    [type]="'decimal2'"
                    [format]="'percentage'"
                    [placeholder]="'N/A'"
                    [readonly]="isReadOnly('deductibleClosing')"
                    [value]="indemnificationFormGroup.get('deductibleClosing').value"
                    [disabled]="indemnificationFormGroup.get('deductibleClosing').disabled">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('basketType')"
                    [class.edited-externally]="editedExternally('basketType')"
                    [class.conflict-on-merge]="hasMergeConflict('basketType')" id="basket-type">Basket Type (True Deductible/ Tipping)</span>
                    <span *ngIf="showAsterisk('basketType')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <select name="BasketType" id="BasketType" class="input-data-select input-select section-select">
                        <option value="0" disabled selected>Select your option</option>
                    </select> -->
                    <app-dropdown
                    [optionsList]="basketTypeOptions"
                    [keySelected]="indemnificationFormGroup.get('basketType').value"
                    [readonly]="isReadOnly('basketType')"
                    (optionSelected)="onBasketTypeSelection($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div margin-top-5">
                    <span [class.highlight-warning]="highlightRequiredField('specialIndemnification')"
                    [class.edited-externally]="editedExternally('specialIndemnification')"
                    [class.conflict-on-merge]="hasMergeConflict('specialIndemnification')">Special Indemnification?</span>
                    <span *ngIf="showAsterisk('specialIndemnification')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <div name="SpecialIndemnification" id="SpecialIndemnification" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group btn-group-two-options" role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button enableDependencyInputButtonGroup" value="YES" disable-target-input="SpecialIndemnificationTopic" disable-target-value-for-specialindemnificationtopic="NO" value-when-input-disabled-for-specialindemnificationtopic="N/A">YES</button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button enableDependencyInputButtonGroup" value="NO" disable-target-input="SpecialIndemnificationTopic" disable-target-value-for-specialindemnificationtopic="NO" value-when-input-disabled-for-specialindemnificationtopic="N/A">NO</button>
                    </div> -->
                    <app-toggle-yes-no  #specialIndemnification
                    [value]="indemnificationFormGroup.get('specialIndemnification').value"
                    [readonly]="isReadOnly('specialIndemnification')"
                    (handleToggleYesNo)="onSpecialIndemnification($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('specialIndemnificationTopic')"
                    [class.edited-externally]="editedExternally('specialIndemnificationTopic')"
                    [class.conflict-on-merge]="hasMergeConflict('specialIndemnificationTopic')">Special Indemnification Topic</span>
                    <span *ngIf="showAsterisk('specialIndemnificationTopic')" class="red-asterik"></span>

                    <app-popover
                    [tooltipText]="getTooltipText('specialIndemnificationTopic')">
                  </app-popover>
                </div>
                <div class="col-xs-8">
                    <input class="input-data-text full-width section-input"
                     id="SpecialIndemnificationTopic" 
                     [class.readonly]="isReadOnly('specialIndemnificationTopic')"
                     [formControl]="indemnificationFormGroup.get('specialIndemnificationTopic')" type="text" 
                     [placeholder]="indemnificationFormGroup.get('specialIndemnificationTopic').disabled ? 'N/A' : ''" 
                     [disabled]="indemnificationFormGroup.get('specialIndemnificationTopic').disabled">
                </div>
            </div>
        </div>
    </div>
</div>