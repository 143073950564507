import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppSettings } from "src/shared/app-settings";
import { DropdownItem } from "src/shared/models/dropdown-item";
import { TooltipObject } from "src/shared/models/tooltip-object";
import { DropdownService } from "src/shared/services/dropwdown.service"; 
import { TooltipHelperService } from "src/shared/services/tooltip-helper.service";
import { FormValidations } from "src/shared/utils/form-validations";
import { FormListFields } from "../shared/models/form-list-fields";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { NAFormatterService } from "../shared/utils/utils-handlingNA";

@Component({
  selector: "app-indemnification",
  templateUrl: "./indemnification.component.html",
  styleUrls: ["./indemnification.component.scss"],
})
export class IndemnificationComponent implements OnInit {
  sharePointMapper:any = new ComplianceSharepointMapper();
  indemnificationFormGroup: any;
  valuationMetricsFormGroup: any;
  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;
  reconciliationEscrowOptions: Array<DropdownItem> = [];
  basketTypeOptions: Array<DropdownItem> = [];
  constructor(private dropdownService: DropdownService, private tooltipHelperService: TooltipHelperService,private naFormatService:NAFormatterService) {}
  ngOnInit() {
    this.indemnificationFormGroup = this.formGroups[0];
    this.valuationMetricsFormGroup = this.formGroups[1];
    // this.dropdownService
    //   .fillDropdownOptions(
    //     AppSettings.closingChecklistForm,
    //     FormListFields.reconciliationEscrow
    //   )
    //   .then((response) => {
    //     this.reconciliationEscrowOptions = response;
    //   })
    //   .catch((error) => {});
    // this.dropdownService
    //   .fillDropdownOptions(
    //     AppSettings.closingChecklistForm,
    //     FormListFields.basketType
    //   )
    //   .then((response) => {
    //     this.basketTypeOptions = response;
    //   })
    //   .catch((error) => {});
    this.reconciliationEscrowOptions = AppSettings.ReconciliationEscrowArrayList;
    this.basketTypeOptions = AppSettings.BasketTypeArrayList;
  }
  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }
  onSelectedValue(event:any, columnName:any) {
    this.indemnificationFormGroup.controls[columnName].setValue(event);
  }
  onSpecialIndemnification(event:any) {
    this.indemnificationFormGroup.controls.specialIndemnification.setValue(
      event
    );
    this.evaluateSpecialIndemnificationTopic(event);
  }
  evaluateSpecialIndemnificationTopic(value:any) {
    if (value === "YES") {
      this.indemnificationFormGroup.controls.specialIndemnificationTopic.enable();
    } else {
      this._setAsDisabled("specialIndemnificationTopic");
    }
  }
  private _setAsDisabled(formControlName: string) {
    const controlName = this.indemnificationFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }
  setFormGroup(formGroup: FormGroup) {
    this.valuationMetricsFormGroup = formGroup;
    this.capPercentageCalculate();
    this.escrowNumericalCalculate();
    this.deductibleNumericalCalculate();
  }
  capPercentageCalculate() {
    let capPercentageEV = 0;
    capPercentageEV =
      (Number(this.naFormatService.formatNA(this.indemnificationFormGroup.get("capNumerical").value)) * 100) /
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration").value));
    this.indemnificationFormGroup.controls.capPercentageEV.setValue(
      isFinite(capPercentageEV) ? capPercentageEV.toString() : ""
    );

    let capPercentageClosing = 0;
    capPercentageClosing =
      (Number(this.naFormatService.formatNA(this.indemnificationFormGroup.get("capNumerical").value)) *
        100) /
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("closingConsideration").value));
    this.indemnificationFormGroup.controls.capPercentageClosing.setValue(
      isFinite(capPercentageClosing) ? capPercentageClosing.toString() : ""
    );
  }
  onCapNumerical(event:any, columnName:any) {
    this.indemnificationFormGroup.controls[columnName].setValue(event);
    this.capPercentageCalculate();
  }
  escrowNumericalCalculate() {
    let escrowTotalEV = 0;
    escrowTotalEV =
      (Number(this.naFormatService.formatNA(this.indemnificationFormGroup.get("escrowNumerical").value)) *
        100) /
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration").value));
    this.indemnificationFormGroup.controls.escrowTotalEV.setValue(
      isFinite(escrowTotalEV) ? escrowTotalEV.toString() : ""
    );

    let escrowClosing = 0;
    escrowClosing =
      (Number(this.naFormatService.formatNA(this.indemnificationFormGroup.get("escrowNumerical").value)) *
        100) /
      Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("closingConsideration").value));
    this.indemnificationFormGroup.controls.escrowClosing.setValue(
      isFinite(escrowClosing) ? escrowClosing.toString() : ""
    );
  }
  onEscrowNumerical(event:any, columnName:any) {
    this.indemnificationFormGroup.controls[columnName].setValue(event);
    this.escrowNumericalCalculate();
  }
  deductibleNumericalCalculate() {
    let deductibleEV = 0;
    deductibleEV =
      (Number(this.naFormatService.formatNA(this.indemnificationFormGroup.get("deductibleNumerical").value)) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("totalConsideration").value)
        )) *
      100;
    this.indemnificationFormGroup.controls.deductibleEV.setValue(
      isFinite(deductibleEV) ? deductibleEV.toString() : ""
    );

    let deductibleClosing = 0;
    deductibleClosing =
      (Number(this.naFormatService.formatNA(this.indemnificationFormGroup.get("deductibleNumerical").value)) /
        Number(
          this.naFormatService.formatNA(this.valuationMetricsFormGroup.get("closingConsideration").value)
        )) *
      100;
    this.indemnificationFormGroup.controls.deductibleClosing.setValue(
      isFinite(deductibleClosing) ? deductibleClosing.toString() : ""
    );
  }
  onDeductibleNumerical(event:any, columnName:any) {
    this.indemnificationFormGroup.controls[columnName].setValue(event);
    this.deductibleNumericalCalculate();
  }

  onReconciliationEscrowSelection(event:any) {
    this.indemnificationFormGroup.controls.reconciliationEscrow.setValue(
      event.key
    );
    this.evaluateIfOtherWhat(event.key);
  }
  evaluateIfOtherWhat(value:any) {
    if (value === "Other") {
      this.indemnificationFormGroup.controls.ifOtherWhat.enable();
    } else {
      this._setAsDisabled("ifOtherWhat");
    }
  }
  onBasketTypeSelection(event:any) {
    this.indemnificationFormGroup.controls.basketType.setValue(event.key);
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }
  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(
        this.formSubmitted,
        formGroup,
        formControlName
      );
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }
  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
  getTooltip(tooltipTitle: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
  }

  getTooltipText(tooltipTitle:string):string{
    const tooltip = this.getTooltip(tooltipTitle);
    return tooltip ? tooltip.getTooltipText() : '';
  }
}
