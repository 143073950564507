export class FormListFields {

    //Project Overview
    static dealTeamTable = 'DealTeamTable';
    static engagementName = 'EngagementName';

    static buyerName = 'BuyerName';
    //Valuation Metrics
    static finalEvPitchRange = 'FinalEvPitchRange';
    static ebitdaPeriod = 'EbitdaPeriod';
    static ifBelowWhy = 'IfBelowWhy';
    static IOIValueClosingLow='IOIValueClosingLow';
    static IOIValueClosingHigh='IOIValueClosingHigh';
    static IOIValueContingent='IOIValueContingent';
    static IOIValueTotalHigh='IOIValueTotalHigh';
    static IOIValueClosingAvg='IOIValueClosingAvg';
    static IOIValueTotalAvg='IOIValueTotalAvg';
    static LOIValueClosingLow='LOIValueClosingLow';
    static LOIValueClosingHigh='LOIValueClosingHigh';
    static LOIValueContingent='LOIValueContingent';
    static LOIValueTotalHigh='LOIValueTotalHigh';
    static LOIValueClosingAvg='LOIValueClosingAvg';
    static LOIValueTotalAvg='LOIValueTotalAvg';
    static NumericalPriceIncClosingLow='NumericalPriceIncClosingLow';
    static NumericalPriceIncClosingHigh='NumericalPriceIncClosingHigh';
    static NumericalPriceIncContingent='NumericalPriceIncContingent';
    static NumericalPriceIncTotalHigh='NumericalPriceIncTotalHigh';
    static NumericalPriceIncClosingAvg='NumericalPriceIncClosingAvg';
    static NumericalPriceIncTotalAvg='NumericalPriceIncTotalAvg';
    static PercentagePriceIncClosingLow='PercentagePriceIncClosingLow';
    static PercentagePriceIncClosingHigh='PercentagePriceIncClosingHigh';
    static PercentagePriceIncContingent='PercentagePriceIncContingent';
    static PercentagePriceIncTotalHigh='PercentagePriceIncTotalHigh';
    static PercentagePriceIncClosingAvg='PercentagePriceIncClosingAvg';
    static PercentagePriceIncTotalAvg='PercentagePriceIncTotalAvg';

    //Client Overview
    static clientType ="ClientType";
    static clientRelationship ="ClientRelationship";
    static sponsorName ="SponsorName";
    static subsidiaryBeingSold = "SubsidiaryBeingSold";
    static referralSourceSubType = "ReferralSourceSubType";
    static clientLocationId= "ClientLocationId";
    static clientCountry ="ClientCountry";
    static referralSource ="ReferralSource";
    static industry="Industry";
    static subsector="Subsector";

    //Deal overview section
    static numberOfParticipants = "NumberOfParticipants";
    static dealType = 'DealType';


    //Finance Section
    static financeFileUpload =  "FinanceFileUpload";
    static financeEnterpriseValue= "FinanceEnterpriseValue";
    static financeDeloitteEntity="FinanceDeloitteEntity"
    static financeDeloitteEntityDescription="FinanceDeloitteEntityDescription";
    static financeFutureExpenses="FinanceFutureExpenses";
    static financeFutureExpensesAmount="FinanceFutureExpensesAmount";
    static financeFuturePayments="FinanceFuturePayments";
    static financeFuturePaymentsType="FinanceFuturePaymentsType";
    static financeAssetSaleDeal="FinanceAssetSaleDeal";
    static financeCrossBorderEngagement="FinanceCrossBorderEngagement";
    static financeCrossBorderCountry="FinanceCrossBorderCountry";
    static financeProviderName="FinanceProviderName";
    static financeProviderNameType="FinanceProviderNameType";
    static financeDealLeagueTable="FinanceDealLeagueTable";
    static financeDealLeagueTableDesc="FinanceDealLeagueTableDesc";
    static financeDealGlobalDeloitteSite="FinanceDealGlobalDeloitteSite";
    static financeDealGlobalDelSiteDesc="FinanceDealGlobalDelSiteDesc";
    static financeDealAnnouncement="FinanceDealAnnouncement";
    static financeDealAnnouncementDesc="FinanceDealAnnouncementDesc";
    static financeDealCaseStudy= "FinanceDealCaseStudy";
    static financeDealCaseStudyDesc="FinanceDealCaseStudyDesc";


    // Dates/Timeline Section

   
    static pitchedDate ="PitchedDate";
    static signedEngagementLetter  ="SignedEngagementLetter";
    static enteredMarket = "EnteredMarket";
    static receivedIoiTermSheets="ReceivedIoiTermSheets";
    static receivedLOIs ="ReceivedLOIs";
    static signedLOI ="SignedLOI";
    static transactionClose="TransactionClose";
    static simultaneousSignClose ="SimultaneousSignClose";
    static signDate ="SignDate";
    static commentAffectingTimeline="CommentAffectingTimeline";


    // Quality of Earning

    static sellsideQofEPerformed ="SellsideQofEPerformed";
    static  qofEStage = "QofEStage";
    static whatFirmPerformed = "WhatFirmPerformed"
    static  mainContact= "MainContact";
    static  qofEDeloitteBranded="QofEDeloitteBranded";
    
    // Engagement Overview
    static wbsCode = 'WBSCode';
    static complianceDealType = 'ComplianceDealType';
    static isClientPublicEntity = 'IsClientPublicEntity';
    static symbol = 'Symbol';
    static removeFromBlackList = 'RemoveFromBlackList';
    static counterparties = 'Counterparties';
    static branchOffice = 'BranchOffice';
    static werePreviousSeries24 = 'WerePreviousSeries24';
    static pPMDDCFUser = 'PPMDDCFUser';
    static pPMDNonDCFUser = 'PPMDNonDCFUser';
    static werePreviousPPMD = 'WerePreviousPPMD';
    static series24 = 'Series24';
    static currentPPMDLead = 'CurrentPPMDLead';
    static currentPPMDLeadDisplayNames = 'CurrentPPMDLeadDisplayNames';
    static previousSeries24 = 'PreviousSeries24';
    static previousPPMDLead = 'PreviousPPMDLead';
    static isDTTLInvolved = 'IsDTTLInvolved';
    static dttlInvolved = 'DTTLInvolved';
    static isXBEngagementAn = 'IsXBEngagementAn';
    static capitalRaised = 'CapitalRaised';

    // Indemnification
    static basketType='BasketType';
    static reconciliationEscrow='ReconciliationEscrow';

    //Deal Structure
    static legalStructure='LegalStructure';
    static managementFee='ManagementFee';
    static stockConsideration='StockConsideration';
    // Marketing and Communications
    static pitchBook = 'PitchBook';
    static pitchBookSeries24 = 'PitchBookSeries24';
    static teaser = 'Teaser';
    static teaserSeries24 = 'TeaserSeries24';
    static cimMemorandumPPM = 'CIMMemorandumPPM';
    static cimMemorandumPPMSeries24 = 'CIMMemorandumPPMSeries24';
    static tombstone = 'Tombstone';
    static tombstoneSeries24 = 'TombstoneSeries24';
    static caseStudies = 'CaseStudies';
    static caseStudiesSeries24 = 'CaseStudiesSeries24';
    static pressRelease = 'PressRelease';
    static pressReleaseSeries24 = 'PressReleaseSeries24';
    static leagueTablesMarketing = 'LeagueTablesMarketing';

    // Books and Records
    static linkFinalEngagement = 'LinkFinalEngagement';
    static copyFinalBriefingPaper = 'CopyFinalBriefingPaper';
    static executedEngagementLetter = 'ExecutedEngagementLetter';
    static amendmentsEngagementLetter = 'AmendmentsEngagementLetter';
    static fullyExecutedIFA = 'FullyExecutedIFA';
    static pitchProposal = 'PitchProposal';
    static teaserBooks = 'TeaserBooks';
    static cimOfferingMemorandum = 'CIMOfferingMemorandum';
    static managementPresentation = 'ManagementPresentation';
    static clientLetterMarketingPress = 'ClientLetterMarketingPress';
    static tombstoneBooks = 'TombstoneBooks';
    static pressReleaseBooks = 'PressReleaseBooks';
    static caseStudiesBooks = 'CaseStudiesBooks';
    static leagueTables = 'LeagueTables';
    static listProspectiveInvestorsBuyers = 'ListProspectiveInvestorsBuyers';
    static listActualInvestorsBuyers = 'ListActualInvestorsBuyers';
    static expressionOfInterestLettersInt = 'ExpressionOfInterestLettersInt';
    static containsAllTransactionDocuments = 'ContainsAllTransactionDocuments';
    static didProjectGoIC = 'DidProjectGoIC';
    static ifNoExplain = 'IfNoExplain';
    static dateICReviewApproval = 'DateICReviewApproval';
    static zipFileSent = 'ZipFileSent';

    // Supervisory Signoff
    static series24ReviewedTransaction = 'Series24ReviewedTransaction';
    static series24ReviewedPersonnelHours = 'Series24ReviewedPersonnelHours';
    static series24TransactionComments= 'Series24TransactionComments';
    static series24WBSCodeComments= 'Series24WBSCodeComments';

    // Fees
    static totalAmountsPaid = 'TotalAmountsPaid';
    static retainerReceived = 'RetainerReceived';
    static periodicOrFlat = 'PeriodicOrFlat';
    static amountRetainer = 'AmountRetainer';
    static periodRetainer = 'PeriodRetainer';
    static retainerCredited = 'RetainerCredited';
    static engagementFeeBased = 'EngagementFeeBased';
    static successFeePaidFull = 'SuccessFeePaidFull';
    static amountSuccessPaid = 'AmountSuccessPaid';
    static potentialAmountOutstanding = 'PotentialAmountOutstanding';
    static dateSuccessFeeReceived = 'DateSuccessFeeReceived';
    static expensesBilled = 'ExpensesBilled';
    static finderReferralFeeOwed = 'FinderReferralFeeOwed';
    static amountFinderOwed = 'AmountFinderOwed';
    static recipientFinderFee = 'RecipientFinderFee';
    static datePaidFinderFee = 'DatePaidFinderFee';



    //Buyer Deal Marketing fields
    static buyerCountry = 'BuyerCountry';
    static preemptiveFinalPurchaser = 'PreemptiveFinalPurchaser';
    static preemptiveOffer = 'PreemptiveOffer';
    static buyerType = 'BuyerType';
    static buyerRelationship = 'BuyerRelationship';
    static marketingProcessType = 'MarketingProcessType'; 
    static buyerParentCompany='BuyerParentCompany';
    static internationalBuyer='InternationalBuyer';
    static identifiedTierPitch='IdentifiedTierPitch';
    static approachedUSStrategic='ApproachedUSStrategic';
    static deloitteLCSP ='DeloitteLCSP';
    static preemptiveBidder='PreemptiveBidder';
    static approachedIntlStrategic='ApproachedIntlStrategic';
    static premiumOverInitialOffer='PremiumOverInitialOffer';
    static approachedPEGNoPortCo='ApproachedPEGNoPortCo';
    static describeDealMarketing='DescribeDealMarketing';
    static approachedPEG='ApproachedPEG';
    static approachedTotal='ApproachedTotal';
    static sentCIMUSStrategic='SentCIMUSStrategic';
    static sentCIMIntlStrategic='SentCIMIntlStrategic';
    static sentCIMPEGNoPortCo='SentCIMPEGNoPortCo';
    static sentCIMPEG='SentCIMPEG';
    static sentCIMTotal='SentCIMTotal';
    static submittedIOIUSStrategic='SubmittedIOIUSStrategic';
    static submittedIOIIntlStrategic='SubmittedIOIIntlStrategic';
    static submittedIOIPEGNoPortCo='SubmittedIOIPEGNoPortCo';
    static submittedIOIPEG='SubmittedIOIPEG';
    static submittedIOITotal='SubmittedIOITotal';
    static attendedMPUSStrategic='AttendedMPUSStrategic';
    static attendedMPIntlStrategic='AttendedMPIntlStrategic';
    static attendedMPPEGNoPortCo='AttendedMPPEGNoPortCo';
    static attendedMPPEG='AttendedMPPEG';
    static attendedMPTotal='AttendedMPTotal';
    static submittedLOIUSStrategic='SubmittedLOIUSStrategic';
    static submittedLOIIntlStrategic='SubmittedLOIIntlStrategic';
    static submittedLOIPEGNoPortCo='SubmittedLOIPEGNoPortCo';
    static submittedLOIPEG='SubmittedLOIPEG';
    static submittedLOITotal='SubmittedLOITotal';
    
    //dcf fee structure
    static retainer = 'Retainer';
    static minimumSucessFee='MinimumSucessFee';
    static feeType='FeeType';
    static minimumFeeApply='MinimumFeeApply';
    static describeFeeStructure:'DescribeFeeStructure';
    static breakpointValue='BreakpointValue';
   static breakpointQuestion='BreakpointQuestion';
   static baseFeeNumerical='BaseFeeNumerical';
   static baseFeePercentage='BaseFeePercentage';
   static baseFeeRadio='BaseFeeRadio';
   static incentiveFeeRadio='IncentiveFeeRadio';
   static incentiveFeePercentage='IncentiveFeePercentage';
   static incentiveFeeNumerical='IncentiveFeeNumerical';

    //comment section
    static additionalCommenatry = 'AdditionalComments';

}
