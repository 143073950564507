import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {

  @Input() tooltipText: any;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipTitle: string;
  @Input() keepOpen = false;
  @Input() openOnFocus = true;
  @Input() tooltipIcon = 'glyphicon glyphicon-question-sign section-question-sign';
  @Input() placement = 'bottom';
  @Input() triggers: string;
  @Input() autoClose:any;
  @Input() active:any= false;
  @Input() isComments:any= false;
  isNewDealPageAccessed:any=false;
  @Input() isToolTip:any = false;
  constructor(public globalHelperService:GlobalHelperService) { }

  ngOnInit() {
      this.getUserAccessedPage();
      if (this.isToolTip) this.tooltipIcon = this.tooltipIcon + " toolTipIcon";
  }
  getUserAccessedPage(){
  //   if(localStorage.getItem("CurrentAccessPage")?.toString()=="NewDealForm"||localStorage.getItem("CurrentAccessPage")?.toString()=="EHO"
  // ||localStorage.getItem("CurrentAccessPage")?.toString()=="DealWBSForm"||localStorage.getItem("CurrentAccessPage")?.toString()=="ClosingChecklistForm"){
    if(this.globalHelperService.currentAccessPage?.toString()=="NewDealForm"||this.globalHelperService.currentAccessPage?.toString()=="EHO"
    ||this.globalHelperService.currentAccessPage?.toString()=="DealWBSForm"||this.globalHelperService.currentAccessPage?.toString()=="ClosingChecklistForm"){
      this.isNewDealPageAccessed=true;
      }
      else{
        this.isNewDealPageAccessed=false;
      }
    }
}
