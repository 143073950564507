<input type="text" #element
  numeric
  class="input-data-text full-width section-input customTextRight"
  autocomplete="off"
  maxlength="255"
  [numericType]="type"
  [(ngModel)]="inputValue"
  [disabled]="disabled || readonly"
  [placeholder]="placeholder"
  [class.readonly]="readonly"
  (change)="onChange(element.value)"
  (focus) = "onFocus($event)"
  (blur) = "onBlur()"
>
