import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { DynamicTableHeader } from '../../models/dynamic-table-header';
import { DynamicTableDropdownItemList } from '../../models/dynamic-table-dropdown-item-list';
import { PeoplepickerDynamicTableItem } from '../../models/peoplepicker-dynamic-table-item';
import { PeoplePickerUser } from '../../models/people-picker-user';
import { DynamicTableAction } from '../../models/dynamic-table-action';
import { PeoplePickerSelectedRow } from './people-picker-selected-row.interface';
import { PeoplepickerComponent } from '../peoplepicker/peoplepicker.component';
// import { PeoplepickerService } from '../../../app/services/peoplepicker.service';
import { AppSettings } from '../../app-settings';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
import { NotifyPopupComponent } from '../alerts/notify-popup/notify-popup.component';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { NewDealNotifyPopupComponent } from '../alerts/newdeal-notify-popup/newdeal-notify-popup.component';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Component({
  selector: 'app-peoplepicker-dynamic-table',
  templateUrl: './peoplepicker-dynamic-table.component.html',
  styleUrls: ['./peoplepicker-dynamic-table.component.scss']
})
export class PeoplepickerDynamicTableComponent implements OnChanges {

  @Input()
  sharePointGroupId: '0';

  @Input()
  header: DynamicTableHeader = new DynamicTableHeader();

  @Input()
  valuesList: Array<PeoplepickerDynamicTableItem> = [];

  @Input()
  firstColumnItemList: any = new DynamicTableDropdownItemList();

  @Input()
  secondColumnItemList: any= new DynamicTableDropdownItemList();

  @Input()
  thirdColumnItemList: any = new DynamicTableDropdownItemList();

  @Input()
  readonly: boolean;
  
  @Input()
  allowToDuplicateUser = false;

  @Input()
  editable = false;
  @Input()
  dealTeamTable:boolean;

  @Input()
  placeHolderForLastTable?: any; // Added for closing checklist form

  @Input()
  ignoreUserLeftDCF?: boolean = false; //Added for closing checklist form

  @Output()
  selectedRow: EventEmitter<PeoplePickerSelectedRow> = new EventEmitter<PeoplePickerSelectedRow>();

  @Output()
  validateSwiftRole = new EventEmitter<boolean>();
  
  @Output()
  validateDealTeamCount = new EventEmitter<boolean>();

  @ViewChild('PeoplePicker')
  peoplepickerComponent: PeoplepickerComponent;

  @ViewChild(NewDealNotifyPopupComponent, { static: true }) notifypopupComponent: NewDealNotifyPopupComponent;

  excludedUsers: Array<PeoplePickerUser> = [];

  hasError: boolean;

  constructor(private peoplepickerService: PeoplepickerService,private spinnerService: SpinnerService,public globalHelperService:GlobalHelperService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.valuesList) {
      if (this.valuesList) {
        this.valuesList.forEach(row => {
          const usersToExclude: Array<PeoplePickerUser> = row ? row.getUsersSelected() : [];
          this._addUsersToExclude(usersToExclude);
          this.checkUsersWhoLeftDCFperRow(row.usersSelected);
        });
      }
    }
  }
  emitValue(item:any, actionType:any) {
    const objectToSend = {
      item,
      actionType
    };
    this.selectedRow.emit(objectToSend);
  }

  onSelectedOptionFirstColumn(event:any, index:any) {
    this.valuesList[index].setOptionFirstColumn(event);
    this._emitValueWhenRowIsCompleted(index);
  }

  onSelectedOptionSecondColumn(event:any, index:any) {
    this.valuesList[index].setOptionSecondColumn(event);
    this._emitValueWhenRowIsCompleted(index);
  }
  updatedExcludedUsers(currentUsers: Array<PeoplePickerUser>) {
    this.excludedUsers = currentUsers;
  }
  onSelectedOptionThirdColumn(event:any, index:any) {
    this.valuesList[index].setOptionThirdColumn(event);
    this._emitValueWhenRowIsCompleted(index);
  }

  onValidateSwiftRole(event :any)
  {
    this.validateSwiftRole.emit(true);
  }

  addRow() {
    if (!this.readonly) {
      this.valuesList.push(new PeoplepickerDynamicTableItem());
      const index = this.valuesList.length - 1;
      this.valuesList[index].setIndex(index);
    }
  }

  get extractSwiftRolesFromValues()
  {
     return this.valuesList.map(item=>item.optionsSelectedThirdColumn);
  }

  deleteExcludeUser(userToDelete: PeoplePickerUser, index: number) {
    this.valuesList[index].deleteUserSelected(userToDelete);
    //this._removeUserFromExcludeList(userToDelete);
  }

  validateDealTeamItemId(userToDelete:any){
    if(typeof userToDelete.itemId==='object') return userToDelete.itemId.ID
    else return userToDelete.itemId;
  }

  deleteRow(index:any) {
    if (!this.readonly) {
      const dealTeamCount = this.valuesList.filter(user => user.index !== index).length;
      // if (dealTeamCount > 0|| localStorage.getItem("CurrentAccessPage") === "DealWBSForm" || localStorage.getItem("CurrentAccessPage") ==="ClosingChecklistForm") {
        if (dealTeamCount > 0|| this.globalHelperService.currentAccessPage === "DealWBSForm" || this.globalHelperService.currentAccessPage ==="ClosingChecklistForm") {
        this.valuesList[index].itemId = this.validateDealTeamItemId(this.valuesList[index]);
        if (this.valuesList[index].itemId) {
          this.emitValue(this.valuesList[index], DynamicTableAction.Delete);
        }
        const users: Array<PeoplePickerUser> = this.valuesList[index].getUsersSelectedList();
        //TODO : remove if not used
        users.forEach(user => {
          this._removeUserFromExcludeList(user);
        });
        this.valuesList.splice(index, 1);

        this._updateTableIndex();
        if (dealTeamCount === 0)
         this.valuesList = [];
      } else{
         this.notifypopupComponent.openPopup("NewDeal","DealTeamTable");
        //this.validateDealTeamCount.emit(true);
      }
    }
  }

  onSelectedPeoplePickerUser(user: PeoplePickerUser, index:any) {
    if (!this._inArray(this.excludedUsers, user)) {
      this.excludedUsers.push(user);
    }
    this._emitValueWhenRowIsCompleted(index);
  }

  setItemId(itemId: number, index: number) {
    this.valuesList[index].setItemId(itemId);
  }

  addSeries24Row(series24User:any)
  {
    // return this.peoplepickerService.getUserDetails(series24User.key).then((series24User:any) => {
    //   if (!this.readonly && !this.valuesList.map(r=>r.optionsSelectedThirdColumn).some(rl=>rl.value == AppSettings.dealTeamSwiftRoleValue)) {
    //     //Add selected series24 in dealteam table
    //     // this._setDealTeamRow(series24User,AppSettings.dealTeamRoleValue,AppSettings.dealTeamPursuitRoleValue,AppSettings.dealTeamSwiftRoleValue);
    //     this._setDealTeamRow(series24User,AppSettings.dealTeamRoleValue,"",AppSettings.dealTeamSwiftRoleValue);
    //   }
    // });
    series24User= this.peoplepickerService.BindUserDetails(series24User);
      if (!this.readonly && !this.valuesList.map(r=>r.optionsSelectedThirdColumn).some(rl=>rl.value == AppSettings.dealTeamSwiftRoleValue)) {
        //Add selected series24 in dealteam table
        // this._setDealTeamRow(series24User,AppSettings.dealTeamRoleValue,AppSettings.dealTeamPursuitRoleValue,AppSettings.dealTeamSwiftRoleValue);
        this._setDealTeamRow(series24User,AppSettings.dealTeamRoleValue,"",AppSettings.dealTeamSwiftRoleValue);
      }
  
    
    
  }

  addUserDealTeamRow(currentUser:any)
  {
    let userDetails= this.peoplepickerService.BindUserDetails(currentUser);
      if (!this.readonly) {
        //Add current user in dealteam table
        this._setDealTeamRow(userDetails,AppSettings.defaultfirstColumnValue,AppSettings.defaultSecondColumnValue,AppSettings.defaultThirdColumnValue);
      }
    //});
  }

  checkUsersWhoLeftDCFperRow(usersSelected: Array<PeoplePickerUser>): boolean {
    if (this.ignoreUserLeftDCF) { //Added condition for closing checklist form
      return false;
    }
    return usersSelected[0] && usersSelected[0].LeftDCF ? true : false;
  }

  removeUserFromRow(userToDelete: PeoplePickerUser, index: number) {
    this.peoplepickerComponent.removeItem(userToDelete,index);
    this.deleteExcludeUser(userToDelete, index);
  }

  showError() {
    this.hasError = true;
  }

  rowIndex(user: PeoplePickerUser): number {
    return this.valuesList.findIndex(row => row.usersSelected[0] && row.usersSelected[0].Key === user.Key);
  }
  
  findIndexByItem(dealTeamItem: PeoplepickerDynamicTableItem) : number {
      return this.valuesList.findIndex(row => row.usersSelected[0] && row.usersSelected[0].Key === dealTeamItem.usersSelected[0].Key && row.itemId == dealTeamItem.itemId);
  }

  checkDuplicateDealTeam(selectedDealTeam: PeoplepickerDynamicTableItem) {
    if (this.valuesList.length > 0) {
      let counter = 0;

      this.valuesList.forEach(duser => {
        if (duser.usersSelected[0].Key === selectedDealTeam.usersSelected[0].Key &&
          duser.optionsSelectedFirstColumn.key == selectedDealTeam.optionsSelectedFirstColumn.key &&
          duser.optionsSelectedSecondColumn.key == selectedDealTeam.optionsSelectedSecondColumn.key &&
          duser.optionsSelectedThirdColumn.key == selectedDealTeam.optionsSelectedThirdColumn.key) {
          counter += 1;
        }
      });
      return counter > 1;
    } else {
      return false;
    }
  }

  isUserExistInDealTeam(user: PeoplePickerUser) {
    if (this.valuesList.length > 0) {
      let counter = 0;

      this.valuesList.forEach(duser => {
        if (duser.usersSelected[0].Key === user.Key)
          counter += 1;
      });
      return counter > 1;
    }
    else {
      return false;
    }
  }

  private _setDealTeamRow(user:any, firstColumnVal:any, secondColumnVal:any, thirdColumnVal:any) {
    this.valuesList.push(new PeoplepickerDynamicTableItem());
    const index = this.valuesList.length - 1;
    this.valuesList[index].setIndex(index);

    //select peoplpickeruser 
    let users = new Array<PeoplePickerUser>();
    users.push(user);
    this.valuesList[index].usersSelected = users;
    this.onSelectedPeoplePickerUser(user, index);
    
    // select first column i.e role
    let firstColumnObject: any = {};
    firstColumnObject.value = firstColumnVal;
    firstColumnObject.key = this.firstColumnItemList.itemList.find((i:any) => i.value == firstColumnVal)?.key;
    this.onSelectedOptionFirstColumn(firstColumnObject, index);

    // select second column i.e pursuit role
    if (secondColumnVal) {
      let secondColumnObject: any = {};
      secondColumnObject.value = secondColumnVal;
      secondColumnObject.key = this.secondColumnItemList.itemList.find((i:any) => i.value == secondColumnVal)?.key;
      this.onSelectedOptionSecondColumn(secondColumnObject, index);
    }

    //select thrid column i.e Swift role
    let thirdColumnObject: any = {};
    thirdColumnObject.value = thirdColumnVal;
    thirdColumnObject.key = this.thirdColumnItemList.itemList.find((i:any) => i.value == thirdColumnVal)?.key;
    this.onSelectedOptionThirdColumn(thirdColumnObject, index);
  }

  private _addUsersToExclude(usersToExclude:any) {
    for (const user of usersToExclude) {
      if (!this._inArray(this.excludedUsers, user)) {
        this.excludedUsers.push(user);
      }
    }
  }

  private _removeUserFromExcludeList(userToDelete: PeoplePickerUser) {
    this.excludedUsers = this.excludedUsers.filter(user => user.Key !== userToDelete.Key);
  }

  private _inArray(users: Array<PeoplePickerUser>, user: PeoplePickerUser): boolean {
    return Boolean(users.find(u => u.mail === user.mail));
  }

  private _isRowComplete(index:any): boolean {
    return this._isUserSelected(index) && this._isFirstColumnSelected(index) &&
    this._isSecondColumnSelected(index) &&
    this._isThirdColumnSelected(index);
  }

  private _isUserSelected(index:any): boolean {
    if (this.valuesList[index].usersSelected.length > 0) {
      return true;
    }
    return false;
  }

  private _isFirstColumnSelected(index:any): boolean {
    if (this.firstColumnItemList && !this.valuesList[index].optionsSelectedFirstColumn.value) {
        return false;
    }
    return true;
  }

  private _isSecondColumnSelected(index:any): boolean {
    if (this.secondColumnItemList && !this.valuesList[index].optionsSelectedSecondColumn.value) {
        return false;
    }
    return true;
  }

  private _isThirdColumnSelected(index:any): boolean {
    if (this.thirdColumnItemList && !this.valuesList[index].optionsSelectedThirdColumn.value) {
        return false;
    }
    return true;
  }

  private _emitValueWhenRowIsCompleted(index:any) {
    // hide error
    this.hasError = false;

    if (this._isRowComplete(index)) {
      this.emitValue(this.valuesList[index], DynamicTableAction.Add);
    }
  }

  private _updateTableIndex() {
    this.valuesList.forEach((row, i) => {
      row.index = i;
    });
  }
}
