import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { Injectable } from '@angular/core';
import { IRolesValidator } from 'src/shared/interfaces/new-deal-setup-roles-validator';
import { DealWbsSetupService } from '../services/deal-wbs-setup.service';
import { Observable, catchError, forkJoin, from, map, of } from 'rxjs';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Injectable({
    providedIn: 'root'
})
export class RolesValidatorService implements IRolesValidator {
    constructor(
        private mainService: MainService,
        private dealWbsSetupService: DealWbsSetupService,
        public globalHelperService:GlobalHelperService
      ) { }

    validateFormRoles(roleList: Array<any>, userId: number, formId?: number, myDealformId?: number): Promise<Array<string>> {
        return new Promise<Array<string>>((resolve, reject) => {
            const formGroups = [AppSettings.series24Group, AppSettings.adminsGroup(this.globalHelperService.currentAccessPage?.toString()), AppSettings.associatesAnalystsGroup(this.globalHelperService.currentAccessPage?.toString())];
            let roles = roleList.filter(item => {
                return (formGroups.find(x => x === item) !== undefined) ? true : false;
            });

            if (formId) {
                // Promise.all([
                //     this.mainService.isDealTeamMember(AppSettings.dealTeamTableList, myDealformId, userId),
                //     this.dealWbsSetupService.isAssignedSeries24(AppSettings.myDealFormList, myDealformId, userId )])
                Promise.all([
                    this.mainService.isDealTeamMember(AppSettings.dealTeamTableList, Number(myDealformId), userId),
                    this.dealWbsSetupService.isAssignedSeries24(AppSettings.myDealFormList, Number(myDealformId), userId )])
                    .then(([dealTeamResponse, series24Response]) => {
                    if (dealTeamResponse) {
                        roles.push(AppSettings.dealTeamMembersGroup);
                    }
                    if (!series24Response) {
                        roles = roles.filter(item => {
                            return item !== AppSettings.series24Group;
                        });
                    }
                    resolve(roles);
                }).catch(error => {
                    reject(error);
                });
            } else {
                roles = roles.filter(item => {
                    return item !== AppSettings.series24Group;
                });
                resolve(roles);
            }
        });
    }

    validateFormRoles_DealWbs(roleList: Array<any>, userId: string, formId?: number, myDealformId?: number): Observable<any> {
        const formGroups = [AppSettings.series24Group, AppSettings.adminsGroup(this.globalHelperService.currentAccessPage?.toString()), AppSettings.associatesAnalystsGroup(this.globalHelperService.currentAccessPage?.toString())];
        let roles = roleList.filter(item => {
            return (formGroups.find(x => x === item) !== undefined) ? true : false;
        });
     
        if (formId) {
            return forkJoin(
                this.mainService.isDealTeamMember_DealWbs( Number(myDealformId), userId),
                this.dealWbsSetupService.isAssignedSeries24_DealWbs(Number(myDealformId), userId)
            ).pipe(
                map(([dealTeamResponse, series24Response]) => {
                    if (dealTeamResponse) {
                        roles.push(AppSettings.dealTeamMembersGroup);
                    }
                    if (!series24Response) {
                        roles = roles.filter(item => {
                            return item !== AppSettings.series24Group;
                        });
                    }
                    return roles;
                }),
                catchError(error => {
                    throw error;
                })
            );
        } else {
            roles = roles.filter(item => {
                return item !== AppSettings.series24Group;
            });
            return from(roles);
        }
    }
}
