import { Injectable, Output, EventEmitter } from '@angular/core';
import { Spcrud } from './spcrud';
import { SecurityMatrix } from '../utils/security-matrix';
import { AppSettings } from '../app-settings';
import { FormStatusValue } from '../models/form-status-value';
import { RootSiteSpcrud } from './root-site-spcrud';
import { BaseService } from './base.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { AdminService } from 'src/app/services/admin.service';
import { SpinnerService } from './spinner.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { ClosingChecklistSecurityMatrix } from 'src/app/closing-checklist-form/shared/utils/closing-checklist-security-matrix';

@Injectable({
  providedIn: 'root'
})
export class SecurityService extends BaseService {

 @Output()
  securityMatrixEvent: EventEmitter<SecurityMatrix> = new EventEmitter<SecurityMatrix>();

  securityMatrix: SecurityMatrix| any;
 

  constructor(spcrud: Spcrud, rootSiteSpcrud: RootSiteSpcrud,
             public newdealService:NewdealService,
             public adminService:AdminService,private commonHelperService:CommonHelperService,private spinnerService:SpinnerService) {
    super(spcrud, rootSiteSpcrud);
   }

  // tslint:disable-next-line: max-line-length
  getPermissions<T extends SecurityMatrix>(currentUserEmail:string,formStatus: string, formActionGroup: string, roleList: Array<string>, SecurityMatrixType: new () => T) {
    let filterUrl:any = '';
    if (SecurityMatrixType.name==="ClosingChecklistSecurityMatrix")  // for closing checklist form
      {
      filterUrl = 'formstatus='+formStatus+'&&formactiongroup='+formActionGroup;
    }
    else // for all other forms
    {
        if (formStatus === FormStatusValue.discontinued || formStatus === FormStatusValue.completed) {
          filterUrl = 'formstatus='+formStatus;
        }
        else{
          filterUrl = 'formstatus='+formStatus+'&&formactiongroup='+formActionGroup;
        }
      }
    this.newdealService.GetRoleAccessDetails(filterUrl).subscribe(
      {
        next: (result: any) => {
          const permissionList = result.filter((item:any) => {
            return (roleList.find(x => x === item.Role) !== undefined) ? true : false;
          });
    
          this.securityMatrix = new SecurityMatrixType();
          const properties = Object.keys(this.securityMatrix);
    
          properties.forEach(property => {
            this.securityMatrix[property] = permissionList.find((x:any) => (x.Action === property && x.Value.toLowerCase() === "true")) !== undefined ? true : false;
          });
          this.securityMatrixEvent.emit(this.securityMatrix);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hide();
          this.spinnerService.hideAutoSaveSpinner
          console.log("Error" + err);
        }
    });
  }

  getPermissions_Bck<T extends SecurityMatrix>(formStatus: string, formActionGroup: string, roleList: Array<string>, SecurityMatrixType: new () => T) {
    const spcrud = this.getSpcrud();
    const options = {
      filter: 'FormStatus eq \'' + formStatus + '\' and FormActionGroup eq \'' + formActionGroup + '\'',
      select: 'FormStatus, FormActionGroup, Role, Action, Value'
    };
    if (formStatus === FormStatusValue.discontinued || formStatus === FormStatusValue.completed) {
      options.filter = 'FormStatus eq \'' + formStatus + '\'';
    }
    spcrud.read(AppSettings.securityList, options).then((response:any) => {
      const permissionList = response.d.results.filter((item:any) => {
        return (roleList.find(x => x === item.Role) !== undefined) ? true : false;
      });

      this.securityMatrix = new SecurityMatrixType();
      const properties = Object.keys(this.securityMatrix);

      properties.forEach(property => {
        // tslint:disable-next-line: max-line-length
        this.securityMatrix[property] = permissionList.find((x:any) => (x.Action === property && x.Value === true)) !== undefined ? true : false;
      });

      this.securityMatrixEvent.emit(this.securityMatrix);
    })
    .catch((error:any) => {
    });
  }

  hasPermission(action: string): boolean {
    return this.securityMatrix && this.securityMatrix[action];
  }
}
