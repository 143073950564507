<div id="deal-team-table-container">
  <div *ngIf="firstColumnItemList && secondColumnItemList && thirdColumnItemList">
    <table id="deal-team-table">
    <thead>
      <tr>
        <th scope="col">
          <span>
            {{ header.header }}
          </span>
        </th>
        <th scope="col">
          <span>
            {{ firstColumnItemList.header.header }}
          </span>
        </th>
        <th scope="col">
          <span>
            {{ secondColumnItemList.header.header }}
          </span>
          <app-popover
            [tooltipText]="secondColumnItemList.header.toolTip">
          </app-popover>
        </th>
        <th scope="col">
          <span>
            {{ thirdColumnItemList.header.header }}
          </span>
        </th>
        <th scope="col" class="delete-container">

        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let option of valuesList; let i = index">
        <td>
          <app-peoplepicker #PeoplePicker
            [excludedUsers]="excludedUsers"
            [allowMultiple]="false"
            [allowToDuplicateUser]="allowToDuplicateUser"
            [readonly]="readonly"
            [dealTeamTable]="dealTeamTable"
            [peoplePickerUserList]="option.usersSelected"
            [userLeftDCF]="checkUsersWhoLeftDCFperRow(option.usersSelected)"
            (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, i)"
            (removedPeoplePickerUser)="deleteExcludeUser($event, i)">
          </app-peoplepicker>
        </td>
        <td>
          <app-dropdown 
            [selectorWidth]="'width:100%'"
            [optionsList]="firstColumnItemList.itemList"
            [keySelected]="option.optionsSelectedFirstColumn.key"
            [readonly]="readonly"
            (optionSelected)="onSelectedOptionFirstColumn($event, i)">
          </app-dropdown>
        </td>
        <td>
          <app-dropdown
          [selectorWidth]="'width:100%'"
            [optionsList]="secondColumnItemList.itemList"
            [keySelected]="option.optionsSelectedSecondColumn.key"
            [readonly]="readonly"
            (optionSelected)="onSelectedOptionSecondColumn($event, i)">
          </app-dropdown>
        </td>
        <td>
          <app-dropdown
          [selectorWidth]="'width:100%'"
            [optionsList]="thirdColumnItemList.itemList"
            [keySelected]="option.optionsSelectedThirdColumn.key"
            [readonly]="readonly"
            [swiftRoleValues]="extractSwiftRolesFromValues"
            (optionSelected)="onSelectedOptionThirdColumn($event, i)"
            (validateSwiftRole)="onValidateSwiftRole($event)">
          </app-dropdown>
        </td>
        <td>
          <span
            class="glyphicon glyphicon-remove delete-deal-team-icon delete-deal-team"
            [class.readonly]="readonly"
            (click)="deleteRow(i)">
          </span>
        </td>
      </tr>
      <tr class="last-row">
        <td colspan="5">
          <span
            class="deal-team-watermark"
            [class.readonly]="readonly"
            (click)="addRow()">
            Add new Deal Team members here...
          </span>
        </td>
      </tr>
      <tr *ngIf="hasError">
        <td>
          <div class="text-danger">
            The same Deal Team member cannot be added several times
          </div>
        </td>
      </tr>
    </tbody>
    </table>
  </div>
  <div *ngIf="!firstColumnItemList && !secondColumnItemList && thirdColumnItemList">
    <table id="deal-team-table">
      <thead>
        <tr>
          <th scope="col">
            <span>
              {{ header.header }}
            </span>
          </th>
          <th scope="col">
            <span>
              {{ thirdColumnItemList.header.header }}
            </span>
          </th>
          <th *ngIf="editable"
            scope="col" class="delete-container">
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let option of valuesList; let i = index">
          <td>
            <app-peoplepicker #PeoplePicker *ngIf="editable"
              [excludedUsers]="excludedUsers"
              [allowMultiple]="false"
              [readonly]="readonly"
              [dealTeamTable]="dealTeamTable"
              [peoplePickerUserList]="option.usersSelected"
              [userLeftDCF]="checkUsersWhoLeftDCFperRow(option.usersSelected)"
              (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, i)"
              (removedPeoplePickerUser)="deleteExcludeUser($event, i)">
            </app-peoplepicker>
            <div *ngIf="!editable && option && option.usersSelected.length">
              <span *ngFor="let user of option.usersSelected">
                {{ user.DisplayText }}
              </span>
            </div>
          </td>
          <td>
            <app-dropdown *ngIf="editable"
              [optionsList]="thirdColumnItemList.itemList"
              [keySelected]="option.optionsSelectedThirdColumn.key"
              [readonly]="readonly"
              (optionSelected)="onSelectedOptionThirdColumn($event, i)">
            </app-dropdown>
            <span *ngIf="!editable && option && option.optionsSelectedThirdColumn">
              {{ option.optionsSelectedThirdColumn.value }}
            </span>
          </td>
          <td *ngIf="editable">
            <span
              class="glyphicon glyphicon-remove delete-deal-team-icon delete-deal-team"
              [class.readonly]="readonly"
              (click)="deleteRow(i)">
            </span>
          </td>
        </tr>
        <tr *ngIf="editable"
          class="last-row">
          <td colspan="5">
            <span
              class="deal-team-watermark"
              [class.readonly]="readonly"
              (click)="addRow()">
              Add new Deal Team members here...
            </span>
          </td>
        </tr>
        <tr *ngIf="hasError">
          <td>
            <div class="text-danger">
              The same Deal Team member cannot be added several times
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!firstColumnItemList && secondColumnItemList && !thirdColumnItemList">
      <table id="deal-team-table">
        <thead>
          <tr>
            <th scope="col">
              <span>
                {{ header.header }}
              </span>
            </th>
            <th scope="col">
              <span>
                {{ secondColumnItemList.header.header }}
              </span>
            </th>
            <th *ngIf="editable"
              scope="col" class="delete-container">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let option of valuesList; let i = index">
            <td>
              <app-peoplepicker #PeoplePicker *ngIf="editable"
                [excludedUsers]="excludedUsers"
                [allowMultiple]="false"
                [readonly]="readonly"
                [dealTeamTable]="dealTeamTable"
                [peoplePickerUserList]="option.usersSelected"
                [userLeftDCF]="checkUsersWhoLeftDCFperRow(option.usersSelected)"
                (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, i)"
                (removedPeoplePickerUser)="deleteExcludeUser($event, i)">
              </app-peoplepicker>
              <div *ngIf="!editable && option && option.usersSelected.length">
                <span *ngFor="let user of option.usersSelected">
                  {{ user.DisplayText }}
                </span>
              </div>
            </td>
            <td>
              <app-dropdown *ngIf="editable"
                [optionsList]="secondColumnItemList.itemList"
                [keySelected]="option.optionsSelectedSecondColumn.key"
                [readonly]="readonly"
                (optionSelected)="onSelectedOptionSecondColumn($event, i)">
              </app-dropdown>
              <span *ngIf="!editable && option && option.optionsSelectedSecondColumn">
                {{ option.optionsSelectedSecondColumn.value }}
              </span>
            </td>
            <td *ngIf="editable">
              <span
                class="glyphicon glyphicon-remove delete-deal-team-icon delete-deal-team"
                [class.readonly]="readonly"
                (click)="deleteRow(i)">
              </span>
            </td>
          </tr>
          <tr *ngIf="editable"
            class="last-row">
            <td colspan="5">
              <span
                class="deal-team-watermark"
                [class.readonly]="readonly"
                (click)="addRow()">
                Add new Deal Team members here...
              </span>
            </td>
          </tr>
          <tr *ngIf="hasError">
            <td>
              <div class="text-danger">
                The same Deal Team member cannot be added several times
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="firstColumnItemList && !secondColumnItemList && !thirdColumnItemList">
      <table id="deal-team-table">
        <thead>
          <tr>
            <th scope="col">
              <span>
                {{ header.header }}
              </span>
            </th>
            <th scope="col">
              <span>
                {{ firstColumnItemList.header.header }}
              </span>
            </th>
            <th *ngIf="editable"
              scope="col" class="delete-container">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let option of valuesList; let i = index">
            <td>
              <app-peoplepicker #PeoplePicker *ngIf="editable"
                [excludedUsers]="excludedUsers"
                [allowMultiple]="false"
                [readonly]="readonly"
                [dealTeamTable]="dealTeamTable"
                [peoplePickerUserList]="option.usersSelected"
                [userLeftDCF]="checkUsersWhoLeftDCFperRow(option.usersSelected)"
                (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, i)"
                (removedPeoplePickerUser)="deleteExcludeUser($event, i)">
              </app-peoplepicker>
              <div *ngIf="!editable && option && option.usersSelected.length">
                <span *ngFor="let user of option.usersSelected">
                  {{ user.DisplayText }}
                </span>
              </div>
            </td>
            <td>
              <app-dropdown *ngIf="editable"
                [optionsList]="firstColumnItemList.itemList"
                [keySelected]="option.optionsSelectedFirstColumn.key"
                [readonly]="readonly"
                (optionSelected)="onSelectedOptionFirstColumn($event, i)">
              </app-dropdown>
              <span *ngIf="!editable && option && option.optionsSelectedFirstColumn">
                {{ option.optionsSelectedFirstColumn.value }}
              </span>
            </td>
            <td *ngIf="editable">
              <span
                class="glyphicon glyphicon-remove delete-deal-team-icon delete-deal-team"
                [class.readonly]="readonly"
                (click)="deleteRow(i)">
              </span>
            </td>
          </tr>
          <tr *ngIf="editable"
            class="last-row">
            <td colspan="5">
              <span
                class="deal-team-watermark"
                [class.readonly]="readonly"
                (click)="addRow()">
                Add new Deal Team members here...
              </span>
            </td>
          </tr>
          <tr *ngIf="hasError">
            <td>
              <div class="text-danger">
                The same Deal Team member cannot be added several times
              </div>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
  <!--If yes, please list Add new series 24 section starts here-->
  <div *ngIf="!firstColumnItemList && !secondColumnItemList && !thirdColumnItemList">
    <table id="deal-team-table">
      <tbody>
        <tr *ngFor="let option of valuesList; let i = index">
          <td class="peoplePickerRow">
            <app-peoplepicker #PeoplePicker
              [excludedUsers]="excludedUsers"
              [allowMultiple]="false"
              [readonly]="readonly"
              [peoplePickerUserList]="option.usersSelected"
              [userLeftDCF]="checkUsersWhoLeftDCFperRow(option.usersSelected)"
              (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, i)"
              (removedPeoplePickerUser)="deleteExcludeUser($event, i)">
            </app-peoplepicker>
          </td>
          <td class="deleteRow">
            <span
              class="glyphicon glyphicon-remove delete-deal-team-icon delete-deal-team"
              [class.readonly]="readonly"
              (click)="deleteRow(i)">
            </span>
          </td>
        </tr>
        <tr class="last-row">
          <td colspan="5">
            <span
              class="deal-team-watermark"
              [class.readonly]="readonly"
              (click)="addRow()">
              {{ placeHolderForLastTable }}
            </span>
          </td>
        </tr>
        <tr *ngIf="hasError">
          <td>
            <div class="text-danger">
              The same Deal Team member cannot be added several times
            </div>
          </td>
        </tr>
      </tbody>
      </table>
  </div>
  <!--If yes, please list Add new series 24 section ends here-->
</div>
<newdeal-app-notify-popup></newdeal-app-notify-popup>