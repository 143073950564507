export class SharepointObjectDealWbsSetup {
  MemberFirm: any;
  MemberFirmClientID :any;
  MemberFirmContract: any;
  MemberFirmReferenceID: any;
  ModelAfterPriorEngagement: string;
  PriorEngagement: string;
  IsEngagementAudit: string;
  IsArchiveRequired: string;
  WasCMRPerformed: string;
  // MasterEngagementID: string;
  // FinancialPlanID: string;
  //MemberFirmIdBillingAddress: string;
  BillingContact: string;
  EmailAddress: string;
  IsSubcontractingArrangement: string;
  PrintSecondBillingAddress: string;
  InvoiceMedium: string;
  PrintClientReferenceNumber: string;
  AllowInvoiceConsolidation: string;
  BillingCurrency: string;
  InvoiceLayout: string;
  PrintEngagementNumber: string;
  PaymentTerms: string;
  FXRate: string;
  FXRiskSharing: string;
  BaselineFXRate: string;
  ParticipatingManager: any;
  // ParticipatingPartnerId: string;
  //ConsultingMarket: string;
  // BillingPlanType: string;
  // BillingIntervals: string;
  // BillingIfMilestone: string;
  // BillingFrom: string;
  // BillingTo: string;
  ContractType: string;
  ResourceRateType: string;
  USIResourceRateType: string;
  EventIndicator: string;
  BudgetGroup: string;
  PlannedNetRevenue: string;
  Discount: string;
  PlannedMargin: string;
  PlannedCost: string;
  TotalPlannedHours: string;
  TimeRevenueCap: string;
  DerivedAtAddressSameClientAddres: any;
  DerivedAtAddress: any;
  EngagementTypeName:string;
}
