<div id="free-text-table-container">
    <div *ngIf="firsSelectColumnItemList && secondSelectColumnItemList">
        <table id="free-text-table">
            <thead>
                <tr>
                  <th scope="col">
                    <span>
                      {{ headerFreeTextOne }}
                    </span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ headerSelectOne}}
                    </span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ headerFreeTextTwo }}
                    </span>
                    <!-- TODO Add tooltip functionality when the US is needed -->
                    <!-- <app-popover
                      [tooltipText]="">
                    </app-popover> -->
                  </th>
                  <th scope="col">
                    <span>
                      {{ headerSelectTwo }}
                    </span>
                     <!-- TODO Add tooltip functionality when the US is needed -->
                     <app-popover
                     [tooltipText]="getTooltipText('restrictedEntityTooltip')">
                   </app-popover>

                    <!-- <app-popover
                        [tooltipText]=restrictedEntityTooltip>
                    </app-popover> -->
                  </th>
                  <th scope="col" class="delete-container">
          
                  </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let option of valuesList; let i = index">
                    <td>
                        <input  *ngIf="!readonly"
                        value="{{ option.freeTextOptionOne.name || '' }}"
                        class="input-data-text full-width section-input"
                        type="text"
                        autocomplete="off"
                        placeholder="Enter counterparty name..."
                        (change)="onFreeTextChange($event, i)">
                        <input  *ngIf="readonly"
                        value="{{ option.freeTextOptionOne.name || '' }}"
                        class="input-data-text full-width section-input"
                        type="text"
                        autocomplete="off"
                        placeholder="Enter counterparty name..."
                        (change)="onFreeTextChange($event, i)"
                        readonly>
                    </td>
                    <td>
                        <app-dropdown [selectorWidth]="'width: 100%'"
                            [optionsList]="firsSelectColumnItemList.itemList"
                            [keySelected]="option.optionsSelectedFirstColumn.value"
                            [readonly]="readonly"
                            (optionSelected)="onSelectedOptionFirstColumn($event, i)">
                        </app-dropdown>
                    </td>
                    <td>
                        <input *ngIf="!readonly"
                        value="{{ option.freeTextOptionTwo.name || '' }}"
                        class="input-data-text full-width section-input"
                        type="text"
                        autocomplete="off"
                        (change)="onFreeTextChangeTwo($event, i)"
                        placeholder="Enter ticker symbol...">
                        <input *ngIf="readonly"
                        value="{{ option.freeTextOptionTwo.name || '' }}"
                        class="input-data-text full-width section-input"
                        type="text"
                        autocomplete="off"
                        (change)="onFreeTextChangeTwo($event, i)"
                        placeholder="Enter ticker symbol..." 
                        readonly>
                    </td>
                    <td>
                        <app-dropdown [selectorWidth]="'width: 100%'"
                            [optionsList]="secondSelectColumnItemList.itemList"
                            [keySelected]="option.optionsSelectedSecondColumn.value"
                            [readonly]="readonly"
                            (optionSelected)="onSelectedOptionSecondColumn($event, i)">
                        </app-dropdown>
                    </td>
                    <td>
                    <span
                        class="glyphicon glyphicon-remove delete-deal-team-icon delete-deal-team"
                        [class.readonly]="readonly"
                        (click)="deleteRow(i)">
                    </span>
                    </td>
                </tr>
                <tr class="last-row">
                    <td colspan="5">
                    <span
                        class="deal-team-watermark"
                        [class.readonly]="readonly"
                        (click)="addRow()">
                        Add new counterparties here...
                    </span>
                    </td>
                </tr>
                <tr *ngIf="hasError">
                    <td>
                    <div class="text-danger">
                        The same Deal Team member cannot be added several times
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!firsSelectColumnItemList && !secondSelectColumnItemList">
        <table id="free-text-table">
            <tbody>
                <tr *ngFor="let option of valuesList; let i = index">
                    <td>
                        <input *ngIf="!readonly"
                        value="{{ option.freeTextOptionOne.name || '' }}"
                        class="input-data-text full-width section-input"
                        type="text"
                        placeholder="Enter DTTL member firm..."
                        autocomplete="off"
                        (change)="onFreeTextChange($event, i)">
                        <input *ngIf="readonly"
                        value="{{ option.freeTextOptionOne.name || '' }}"
                        class="input-data-text full-width section-input"
                        type="text"
                        placeholder="Enter DTTL member firm..."
                        autocomplete="off"
                        (change)="onFreeTextChange($event, i)"
                        readonly>
                    </td>
                    <td>
                        <span
                            class="glyphicon glyphicon-remove delete-deal-team-icon delete-deal-team"
                            [class.readonly]="readonly"
                            (click)="deleteRow(i)">
                        </span>
                    </td>
                </tr>
                <tr class="last-row">
                    <td colspan="5">
                    <span
                        class="deal-team-watermark"
                        [class.readonly]="readonly"
                        (click)="addRow()">
                        Add new firm here...
                    </span>
                    </td>
                </tr>
                <tr *ngIf="hasError">
                    <td>
                    <div class="text-danger">
                        The same Deal Team member cannot be added several times
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
