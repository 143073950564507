import { Injectable } from '@angular/core';
import { DealTeamItem } from 'src/shared/models/deal-team-item';
import { EngagementHealthObjectItem } from '../../models/engagement-health-object-item';
import { AppSettings } from 'src/shared/app-settings';
import { AvailableFormItem } from 'src/shared/models/available-form-item';
import { AttachmentItem } from 'src/shared/models/attachment-item';
import { FilesService } from 'src/shared/services/files.service';
import { ComplianceStatusValues } from '../../models/compliance-status-values';
import { DealTeamUserUpdate } from '../../models/deal-team-user-update';
import { EngagementStatusValues } from '../../models/engagement-status-values';
import { EngagementStatusColors } from '../../models/engagement-status-colors';
import { FormStatusValue } from 'src/shared/models/form-status-value';
import { FormStatusObjectItem} from '../../models/form-status-object-item';

@Injectable({
  providedIn: 'root'
})

export class EngagementHealthObjectServiceHelper {

  constructor(private filesService: FilesService) {}

  createEngagementHealthObject(results:any): EngagementHealthObjectItem {
    const engagement = results.Engagement;
    const newDeal = results.NewDeal;
    const myDeal = results.MyDeal;

    //added newly for the API object mapping
    if(newDeal.Status==null || newDeal.Status==undefined || newDeal.Status==""){
      newDeal.Status = newDeal.FormStatus
    }
    const engStatus = this._calculateEngagementStatus(newDeal.SubmissionDate, newDeal.Status);
    const engStatusColor = this._calculateEngagementStatusColor(engStatus);
    const engagementHealthObjectItem: EngagementHealthObjectItem = {
      newDealId: Number.parseInt(newDeal.ID) + AppSettings.engagementIdInitialValue,
      engagementName: myDeal.EngagementName,
      projectStartDate: myDeal.StartDate,
      projectEndDate: myDeal.EndDate,
      status: newDeal.Status,
      engagementType: myDeal.EngagementType,
      submissionDate: newDeal.SubmissionDate,
      newDealItemId: newDeal.ID,
      engagementItemId: engagement.ID,
      complianceStatus: engagement.ComplianceStatus ? engagement.ComplianceStatus : ComplianceStatusValues.NA,
      complianceComments: engagement.ComplianceComments,
      engagementStatus: engStatus,
      engagementStatusColor: engStatusColor,
      myDealId: engagement[AppSettings.myDealSiteColumn],
      // adminAssigned : myDeal.AdminAssigned.Title ? myDeal.AdminAssigned.Title : "",
      // adminUserName : myDeal.AdminAssigned.UserName ? myDeal.AdminAssigned.UserName : "",
      adminAssigned : myDeal.Title ? myDeal.Title : "",
      adminUserName : myDeal.AdminAssignedDisplayName ? myDeal.AdminAssignedDisplayName : "",
      prdCode:newDeal.SwiftCode?newDeal.SwiftCode:"-",
      wbsCode:myDeal.WbsCode?myDeal.WbsCode:"-",
      opportunityId:myDeal.OpportunityID?myDeal.OpportunityID:"-",
      salesForceCode:newDeal.SalesforceCode?newDeal.SalesforceCode:"-",
      engagementCreatedDate:engagement.Created
    };
    return engagementHealthObjectItem;
  }

  createDealTeamItemsObject(serviceResponse:any): Array<DealTeamItem> {
    const dealTeamList = serviceResponse.map((item:any) => {
      const name = item.Name;
      const role = item.Role;
      const dealTeamItem = new DealTeamItem();
      dealTeamItem.dealTeamFormId = item.FormId;
      dealTeamItem.dealTeamItemId = item.Id;
      dealTeamItem.dealTeamEMail = name.EMail;
      dealTeamItem.dealTeamName = name.Name;
      dealTeamItem.dealTeamTitle = name.Title;
      dealTeamItem.dealTeamUserId = name.Id;
      dealTeamItem.dealTeamLoginName = name.UserName;
      dealTeamItem.dealTeamRoleTitle = role.Role;
      dealTeamItem.dealTeamRoleId = role.Id;
      return dealTeamItem;
    });
    return dealTeamList;
  }

  createFormStatusItem(results:any): FormStatusObjectItem {
    const formStatusObjectItem: FormStatusObjectItem = {
      status:         results.Status,
      Id:  results.ID
   };
    return formStatusObjectItem;
  }

  createAvailableFormItemsObject(serviceResponse:any): Array<AvailableFormItem> {
    const availableFormsList = serviceResponse.map((item:any) => {
      const availableFormItem = new AvailableFormItem();
      availableFormItem.availableFormTitle = item.Title;
      availableFormItem.availableFormListName = item.ListName;
      availableFormItem.availableFormSiteUrl = item.SiteUrl;
      availableFormItem.availableFormPageUrl = item.PageUrl;
      availableFormItem.availableAltFormPageUrl = item.AltPageUrl;
      availableFormItem.availableFormCreated = false;
      availableFormItem.availableFormSidebar = item.Sidebar;
      availableFormItem.availableFormModal = item.Modal;
      availableFormItem.itemId = item.ID;
      return availableFormItem;
    });
    return availableFormsList;
  }
  createAvailableFormItemsObjects(serviceResponse:any): Array<AvailableFormItem> {
    const availableFormsList = serviceResponse.map((item:any) => {
      const availableFormItem = new AvailableFormItem();
      availableFormItem.availableFormTitle = item.Title;
      availableFormItem.availableFormListName = item.ListName;
      availableFormItem.availableFormSiteUrl = item.ListName=="NewDealSetupForm"?AppSettings.newDeal:item.ListName=="CrossBorderForm"?AppSettings.crossBorder:item.ListName=="CAFFormList"?AppSettings.CAFForm:item.ListName=="ClosingCheckListForm"?AppSettings.ClosingCheckListForm:item.ListName=="DealWbsSetupForm"?AppSettings.dealWbsForm:item.ListName=="BriefingPaperForm"?AppSettings.briefingForm:"";
      availableFormItem.availableFormPageUrl = item.ListName=="NewDealSetupForm"?AppSettings.newDeal+"?id={formId}":item.ListName=="CrossBorderForm"?AppSettings.crossBorder+"?item_id={formId}":item.ListName=="CAFFormList"?AppSettings.CAFForm+"?id={formId}":item.ListName=="ClosingCheckListForm"?AppSettings.ClosingCheckListForm+"?id={formId}":item.ListName=="DealWbsSetupForm"?AppSettings.dealWbsForm+"?id={formId}":item.ListName=="BriefingPaperForm"?AppSettings.briefingForm+"?id={formId}":"";
      availableFormItem.availableAltFormPageUrl = "";
      availableFormItem.availableFormCreated = false;
      availableFormItem.availableFormSidebar = item.Sidebar;
      availableFormItem.availableFormModal = item.Modal;
      availableFormItem.itemId = item.ID;
      return availableFormItem;
    });
    return availableFormsList;
  }

  createFileObject(attachmentName: string, attachmentUrl: string): AttachmentItem {
    const fileItem = new AttachmentItem();
    fileItem.attachmentName = attachmentName;
    fileItem.attachmentUrl = attachmentUrl;
    return fileItem;
  }

  mapFilesResponseToObject(response:any, folderUrl: string, formBaseUrl: any, listName: string): Array<AttachmentItem> {
    let attachItems: Array<AttachmentItem>;
    attachItems = response.map((f:any) => {
      //const fileUrl = this.filesService.generateFileUrl(f.Name, folderUrl, formBaseUrl, listName);
      const fileUrl= this.filesService.generateFileUrl(f.FileName, folderUrl);
      return this.createFileObject(f.FileName, fileUrl);
      
    });
    return attachItems;
  }

  createDealTeamUpdateObject(complianceStatus: string, complianceComments: string): DealTeamUserUpdate {
    const dealTeamUpdate: DealTeamUserUpdate = {
      ComplianceStatus: complianceStatus,
      ComplianceComments: complianceComments
    };
    return dealTeamUpdate;
  }

  createMyDealUpdateObject(engagementId: number): any {
    const myDealEngagementId = {
      EngagementId: engagementId.toString(),
    };
    return myDealEngagementId;
  }

  createNewFormObject(myDealId: number): any {
    const associateForm:any = {};
    associateForm[AppSettings.myDealSiteColumn] = myDealId;
    associateForm["Status"] = "Pending for Deal Team Submission";
    associateForm["FormStatus"]="Draft";
    associateForm["FormActionGroup"]="DealTeamMembers";
    return associateForm;
  }

  createNewCCFormObject(myDealId: number): any {
    const associateForm:any = {};
    associateForm[AppSettings.myDealSiteColumn] = myDealId;
    associateForm["Status"] = "Draft";
    associateForm["FormStatus"]="Draft";
    associateForm["FormActionGroup"]="BusinessAdminInitiate";
    associateForm["IsFinanceForm"]="TRUE";
    return associateForm;
  }

  private _calculateEngagementStatus(submissionDate: Date, formStatus: string): string {
    let status: string;
    if (submissionDate) {
      status = EngagementStatusValues.Pursuit;
    } else {
      status = EngagementStatusValues.NotSubmitted;
    }
    if (formStatus === FormStatusValue.discontinued) {
      status = EngagementStatusValues.DealLost;
    }
    return status;
  }

  private _calculateEngagementStatusColor(engagementStatus:any): any {
    switch (engagementStatus) {
      case EngagementStatusValues.NotSubmitted:
        return EngagementStatusColors.NotSubmitted;
      case EngagementStatusValues.Pursuit:
        return EngagementStatusColors.Pursuit;
      case EngagementStatusValues.DealWon:
        return EngagementStatusColors.DealWon;
      case EngagementStatusValues.DealLost:
        return EngagementStatusColors.DealLost;
      case EngagementStatusValues.DealClosed:
        return EngagementStatusColors.DealClosed;
    }
  }
}
