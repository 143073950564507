export class SharepointObject {
    // Engagement Overview
    WBSCode: string;
    ComplianceDealType: string;
    IsClientPublicEntity: string;
    Symbol: string;
    RemoveFromBlackList: string;
    Counterparties: string;
    BranchOffice: string;
    WerePreviousSeries24: string;
    PPMDDCFUser: string;
    PPMDNonDCFUser: string;
    WerePreviousPPMD: string;
    //CurrentSeries24Id: string;
   // CurrentPPMDLeadId: string;
    PreviousSeries24: string;
    PreviousPPMDLead: string;

    // Cross Border
    IsDTTLInvolved: string;
    IsXBEngagementAn: string;
    CapitalRaised: string;
    DTTLInvolved: string;

    // Marketing and Communications
    PitchBook: string;
    PitchBookSeries24: string;
    Teaser: string;
    TeaserSeries24: string;
    CIMMemorandumPPM: string;
    CIMMemorandumPPMSeries24: string;
    Tombstone: string;
    TombstoneSeries24: string;
    CaseStudies: string;
    CaseStudiesSeries24: string;
    PressRelease: string;
    PressReleaseSeries24: string;
    LeagueTablesMarketing: string;

    // Books and Records
    LinkFinalEngagement: string;
    CopyFinalBriefingPaper: string;
    ExecutedEngagementLetter: string;
    AmendmentsEngagementLetter: string;
    FullyExecutedIFA: string;
    PitchProposal: string;
    TeaserBooks: string;
    CIMOfferingMemorandum: string;
    ManagementPresentation: string;
    ClientLetterMarketingPress: string;
    TombstoneBooks: string;
    PressReleaseBooks: string;
    CaseStudiesBooks: string;
    LeagueTables: string;
    ListProspectiveInvestorsBuyers: string;
    ListActualInvestorsBuyers: string;
    ExpressionOfInterestLettersInt: string;
    ContainsAllTransactionDocuments: string;
    DidProjectGoIC: string;
    IfNoExplain: string;
    DateICReviewApproval: string;
    ZipFileSent: string;

    // Supervisory Signoff
    Series24ReviewedTransaction: string;
    Series24ReviewedPersonnelHours: string;

    // Fees
    TotalAmountsPaid: string;
    RetainerReceived: string;
    PeriodicOrFlat: string;
    AmountRetainer: string;
    PeriodRetainer: string;
    RetainerCredited: string;
    EngagementFeeBased: string;
    SuccessFeePaidFull: string;
    AmountSuccessPaid: string;
    PotentialAmountOutstanding: string;
    DateSuccessFeeReceived: string;
    ExpensesBilled: string;
    FinderReferralFeeOwed: string;
    AmountFinderOwed: string;
    RecipientFinderFee: string;
    DatePaidFinderFee: string;

    //Deal Overview
    DealType: string;

}
