import { Component, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal, NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const defaultOptions: NgbModalOptions = {
  backdrop: 'static',
  centered: true
};

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {

  @Input() hasCancel: boolean;

  @ViewChild('content', {static: false}) content: TemplateRef<string>;

  instance: NgbActiveModal;

  constructor(private modalService: NgbModal) {}

  open(options: NgbModalOptions = {}) {

    const modalOptions = {
      ...defaultOptions,
      ...options
    };

    this.instance = this.modalService.open(this.content, modalOptions);
  }

  close() {
    this.instance.close();
  }

}
