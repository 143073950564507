import { FormControl, FormGroup } from '@angular/forms';
import { CustomFormControl } from '../custom/custom-form-control';

export class FormValidations {

  static isRequired(formGroup: FormGroup, formControlName: string): boolean {
    const formField = formGroup.get(formControlName);
    if (!formField?.validator) {
      return false;
    }

    const errors: any = formField.validator && formField.validator(new FormControl());

    return errors !== null && errors.required;
  }

  static showAsterisk(formGroup: FormGroup, formControlName: string): boolean {
    return this.isRequired(formGroup, formControlName) && !formGroup.get(formControlName)?.disabled;
  }

  static highlightRequiredField(formSubmitted: boolean, formGroup: FormGroup, formControlName: string): boolean {
    return formSubmitted
      && formGroup.get(formControlName)?.errors
      && formGroup.get(formControlName)?.errors?.['required'];
  }

  static isReadOnly(formGroup: FormGroup, formControlName: string): boolean {
    const elem = formGroup.get(formControlName) as CustomFormControl;
    return elem.isReadOnly();
  }
  
  static editedExternally(formGroup: FormGroup, formControlName: string): boolean {
    const elem = formGroup.get(formControlName) as CustomFormControl;
    return elem.isEditedExternally();
  }

  static hasMergeConflict(formGroup: FormGroup, formControlName: string): boolean {
    const elem = formGroup.get(formControlName) as CustomFormControl;
    return elem.hasConflictOnMerge();
  }
}
