<div
  id="reactivate-form"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
    <span>Reactivate Form</span>
  </div>
  <div class="row reactivate-section">
    <div class="col-xs-4">
        <i>Discontinued By</i>
        <b><input
          type="text"
          class="reactivate-user"
          [value]="discontinuedBy"
          disabled>
        </b>
    </div>
    <div class="col-xs-4">
        <i>Discontinued On</i>
        <b>
          <input
            class="reactivate-date"
            placeholder="mm/dd/yyyy"
            type="text"
            [value]="discontinuedOn"
            disabled>
        </b>
    </div>
    <div class="col-xs-4">
        <button
          type="button"
          class="btn btn-primary reactivate-button submit submit-btn float-right"
          (click)="onReactivate()">
          Reactivate
        </button>
    </div>
  </div>
</div>

