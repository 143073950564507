<div
  id="client-setup"
  [formGroup]="clientSetupFormGroup"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
        <span>Client Setup</span>
    </div>
    <div id="client-setup-section" class="content-section">
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('deloitteUSEntityClient')"
                [class.edited-externally]="editedExternally('deloitteUSEntityClient')"
              [class.conflict-on-merge]="hasMergeConflict('deloitteUSEntityClient')">Deloitte US Entity</span>
                <span *ngIf="showAsterisk('deloitteUSEntityClient')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8 typeahead-input">
                <app-typeahead
                    [listName]="deloitteUSEntityCustomList"
                    [valueSelected]="clientSetupFormGroup.get('deloitteUSEntityClient')?.value"
                    [readonly]="isReadOnly('deloitteUSEntityClient')"
                    (selectedTypeaheadItem)="onDeloitteUSEntitySelection($event)">
                </app-typeahead>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('salesOfficeClient')"
                [class.edited-externally]="editedExternally('salesOfficeClient')"
              [class.conflict-on-merge]="hasMergeConflict('salesOfficeClient')">Sales Office</span>
                <span *ngIf="showAsterisk('salesOfficeClient')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8 typeahead-input">
                <app-typeahead
                    [listName]="salesOfficeCustomList"
                    [valueSelected]="clientSetupFormGroup.get('salesOfficeClient')?.value"
                    [readonly]="isReadOnly('salesOfficeClient')"
                    (selectedTypeaheadItem)="onSalesOfficeSelection($event)">
                </app-typeahead>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('ownershipTypeI1')"
                [class.edited-externally]="editedExternally('ownershipTypeI1')"
              [class.conflict-on-merge]="hasMergeConflict('ownershipTypeI1')">Ownership Type - I1 (Primary)</span>
                <span *ngIf="showAsterisk('ownershipTypeI1')" class="red-asterik"></span>
                <app-popover
                  [tooltipText]="getTooltipText('OwnershipTypeI1')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                  [optionsList]="ownershipTypeI1List"
                  [keySelected]="clientSetupFormGroup.get('ownershipTypeI1')?.value"
                  [readonly]="isReadOnly('ownershipTypeI1')"
                  (optionSelected)="onOwnershipTypeI1Selection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('ownershipTypeI2')"
                [class.edited-externally]="editedExternally('ownershipTypeI2')"
              [class.conflict-on-merge]="hasMergeConflict('ownershipTypeI2')">Ownership Type - I2</span>
                <span *ngIf="showAsterisk('ownershipTypeI2')" class="red-asterik"></span>
                <app-popover
                  [tooltipText]="getTooltipText('OwnershipTypeI2')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                  [optionsList]="ownershipTypeI2List"
                  [keySelected]="clientSetupFormGroup.get('ownershipTypeI2')?.value"
                  [readonly]="isReadOnly('ownershipTypeI2')"
                  (optionSelected)="onOwnershipTypeI2Selection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('ownershipTypeI13')"
                [class.edited-externally]="editedExternally('ownershipTypeI13')"
              [class.conflict-on-merge]="hasMergeConflict('ownershipTypeI13')">Ownership Type - I13</span>
                <span *ngIf="showAsterisk('ownershipTypeI13')" class="red-asterik"></span>
                <app-popover
                  [tooltipText]="getTooltipText('OwnershipTypeI13')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                  [optionsList]="ownershipTypeI13List"
                  [keySelected]="clientSetupFormGroup.get('ownershipTypeI13')?.value"
                  [readonly]="isReadOnly('ownershipTypeI13')"
                  (optionSelected)="onOwnershipTypeI13Selection($event)">
                </app-dropdown>
            </div>
        </div>
        
        <!-- <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 margin-top-5">
                    <span [class.highlight-warning]="highlightRequiredField('dateBecameClient')"
                    [class.edited-externally]="editedExternally('dateBecameClient')"
              [class.conflict-on-merge]="hasMergeConflict('dateBecameClient')">Date became Client</span>
                    <span *ngIf="showAsterisk('dateBecameClient')" class="red-asterik"></span>
                    <app-popover
                      [tooltipText]="getTooltipText('DateBecameClient')">
                    </app-popover>
                </div>
                <div class="col-xs-8">
                    <app-date-picker
                      [selectedDate]="clientSetupFormGroup.get('dateBecameClient').value"
                      [readonly]="isReadOnly('dateBecameClient')"
                      (dateSelected)="onDateBecameClientSelection($event)">
                    </app-date-picker>
                    <span class="numeric-validation-msg"></span>
                </div>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('financialYearEnd')"
                [class.edited-externally]="editedExternally('financialYearEnd')"
              [class.conflict-on-merge]="hasMergeConflict('financialYearEnd')">Financial Year End</span>
                <span *ngIf="showAsterisk('financialYearEnd')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-date-picker-no-year
                    [selectedDate]="clientSetupFormGroup.get('financialYearEnd').value"
                    [readonly]="isReadOnly('financialYearEnd')"
                    (dateSelected)="onfinancialYearEndSelection($event)">
                </app-date-picker-no-year>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('consultingSICCode')"
                [class.edited-externally]="editedExternally('consultingSICCode')"
              [class.conflict-on-merge]="hasMergeConflict('consultingSICCode')">Consulting SIC Code</span>
                <span *ngIf="showAsterisk('consultingSICCode')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-numeric
                  [type]="'number'"
                  [value]="clientSetupFormGroup.get('consultingSICCode').value"
                  [readonly]="isReadOnly('consultingSICCode')"
                  (change)="sicCodeSetManuallyEvent()"
                  (selectedValue)="onConsultingSICCodeSelection($event)">
                </app-numeric>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('globalUltimateParentID')"
                [class.edited-externally]="editedExternally('globalUltimateParentID')"
              [class.conflict-on-merge]="hasMergeConflict('globalUltimateParentID')">Global Ultimate Parent (GUP) ID</span>
                <span *ngIf="showAsterisk('globalUltimateParentID')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-numeric
                  [type]="'number'"
                  [value]="clientSetupFormGroup.get('globalUltimateParentID')?.value"
                  [readonly]="isReadOnly('globalUltimateParentID')"
                  (selectedValue)="onGlobalUltimateParentIDSelection($event)">
                </app-numeric>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('globalClientPortfolio')"
                [class.edited-externally]="editedExternally('globalClientPortfolio')"
              [class.conflict-on-merge]="hasMergeConflict('globalClientPortfolio')">Global Client Portfolio</span>
                <span *ngIf="showAsterisk('globalClientPortfolio')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                  [optionsList]="globalClientPortfolioList"
                  [keySelected]="clientSetupFormGroup.get('globalClientPortfolio')?.value"
                  [readonly]="isReadOnly('globalClientPortfolio')"
                  (optionSelected)="onGlobalClientPortfolioSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('globalClientIntelligenceNumber')"
                [class.edited-externally]="editedExternally('globalClientIntelligenceNumber')"
              [class.conflict-on-merge]="hasMergeConflict('globalClientIntelligenceNumber')">Global Client Intelligence Number</span>
                <span *ngIf="showAsterisk('globalClientIntelligenceNumber')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-numeric
                  [type]="'number'"
                  [value]="clientSetupFormGroup.get('globalClientIntelligenceNumber')?.value"
                  [readonly]="isReadOnly('globalClientIntelligenceNumber')"
                  (selectedValue)="onGlobalClientIntelligenceNumberSelection($event)">
                </app-numeric>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('gst')"
                [class.edited-externally]="editedExternally('gst')"
              [class.conflict-on-merge]="hasMergeConflict('gst')">GST</span>
                <span *ngIf="showAsterisk('gst')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input
                  formControlName="gst"
                  class="input-data-text full-width section-input"
                  [class.readonly]="isReadOnly('gst')"
                  type="text"
                  autocomplete="off"
                  maxlength="255">
            </div>
        </div>
        <div class="row">
          <!--Buyer Name renamed to CLIENT CONTACT-->
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('buyerNameClient')"
                [class.edited-externally]="editedExternally('buyerNameClient')"
              [class.conflict-on-merge]="hasMergeConflict('buyerNameClient')">Main Client Contact</span>
                <span *ngIf="showAsterisk('buyerNameClient')" class="red-asterik"></span>
                <app-popover
                  [tooltipText]="getTooltipText('BuyerNameClient')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <input
                  formControlName="buyerNameClient"
                  class="input-data-text full-width section-input"
                  [class.readonly]="isReadOnly('buyerNameClient')"
                  type="text"
                  autocomplete="off"
                  maxlength="255">
            </div>
        </div>
        <div class="row">
          <!--Buyer Title renamed to CLIENT JOB POSITION-->
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('buyerTitle')"
                [class.edited-externally]="editedExternally('buyerTitle')"
              [class.conflict-on-merge]="hasMergeConflict('buyerTitle')">Main Client Job Position</span>
                <span *ngIf="showAsterisk('buyerTitle')" class="red-asterik"></span>
                <app-popover
                  [tooltipText]="getTooltipText('BuyerTitle')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <input
                  formControlName="buyerTitle"
                  class="input-data-text full-width section-input"
                  [class.readonly]="isReadOnly('buyerTitle')"
                  type="text"
                  autocomplete="off"
                  maxlength="255">
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('buyerEmail')"
                [class.edited-externally]="editedExternally('buyerEmail')"
              [class.conflict-on-merge]="hasMergeConflict('buyerEmail')">Buyer Email</span>
                <span *ngIf="showAsterisk('buyerEmail')" class="red-asterik"></span>
                <app-popover
                  [tooltipText]="getTooltipText('BuyerEmail')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <input
                formControlName="buyerEmail"
                class="input-data-text full-width section-input"
                [class.readonly]="isReadOnly('buyerEmail')"
                type="text"
                autocomplete="off"
                maxlength="255">
            </div>
        </div> -->
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="
                    highlightRequiredField('billingAddressSameClientAddress') ||
                    highlightRequiredField('billingAddress')"
                    [class.edited-externally]="editedExternally('billingAddressSameClientAddress')"
                    [class.conflict-on-merge]="hasMergeConflict('billingAddressSameClientAddress')">
                    Is Billing Address same as Client Address?
                </span>
                <span *ngIf="showAsterisk('billingAddressSameClientAddress')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-toggle-yes-no
                    [value]="myDealFormGroup.get('billingAddressSameClientAddress').value"
                    [readonly]="isReadOnly('billingAddressSameClientAddress')"
                    [disabled]="myDealFormGroup.get('billingAddressSameClientAddress').disabled"
                    (handleToggleYesNo)="onBillingAddressSameClientAddressSelection($event)">
                </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label"></div>
            <div class="col-xs-8">
                <input
                    [formControl]="myDealFormGroup.get('billingAddress')"
                    class="input-data-text full-width section-input dependent-field-input"
                    [class.readonly]="isReadOnly('billingAddress')"
                    type="text"
                    autocomplete="off"
                    maxlength="255"
                    [placeholder]='myDealFormGroup.get("billingAddressSameClientAddress").value === "NO" ? "Please enter &#39;Billing Address&#39;" : "N/A"'>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="
                    highlightRequiredField('payerAddressSameClientAddress') ||
                    highlightRequiredField('payerAddress')"
                    [class.edited-externally]="editedExternally('payerAddressSameClientAddress')"
              [class.conflict-on-merge]="hasMergeConflict('payerAddressSameClientAddress')">
                    Is Payer Address same as Client Address?</span>
                <span *ngIf="showAsterisk('payerAddressSameClientAddress')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-toggle-yes-no
                  [value]="myDealFormGroup.get('payerAddressSameClientAddress').value"
                  [readonly]="isReadOnly('payerAddressSameClientAddress')"
                  [disabled]="myDealFormGroup.get('payerAddressSameClientAddress').disabled"
                  (handleToggleYesNo)="onPayerAddressSameClientAddressSelection($event)">
                </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label"></div>
            <div class="col-xs-8">
                <input
                  [formControl]="myDealFormGroup.get('payerAddress')"
                  class="input-data-text full-width section-input dependent-field-input"
                  [class.readonly]="isReadOnly('payerAddress')"
                  type="text"
                  autocomplete="off"
                  maxlength="255"
                  [placeholder]='myDealFormGroup.get("payerAddressSameClientAddress").value === "NO" ? "Please enter &#39;Payer Address&#39;" : "N/A"'>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span
                    [class.highlight-warning]="
                        highlightRequiredField('isServiceSameAsClientAddress') ||
                        highlightRequiredField('serviceAtAddress')"
                        [class.edited-externally]="editedExternally('isServiceSameAsClientAddress')"
              [class.conflict-on-merge]="hasMergeConflict('isServiceSameAsClientAddress')">
                    Is Service-At Address same as Client Address?
                </span>
                <span *ngIf="showAsterisk('isServiceSameAsClientAddress')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-toggle-yes-no
                  [value]="myDealFormGroup.get('isServiceSameAsClientAddress').value"
                  [readonly]="isReadOnly('isServiceSameAsClientAddress')"
                  [disabled]="myDealFormGroup.get('isServiceSameAsClientAddress').disabled"
                  (handleToggleYesNo)="onServiceAddressSameClientAddressSelection($event)">
                </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
          <div class="col-xs-4 section-label"></div>
          <div class="col-xs-8">
            <input
              [formControl]="myDealFormGroup.get('serviceAtAddress')"
              class="input-data-text full-width section-input dependent-field-input"
              [class.readonly]="isReadOnly('serviceAtAddress')"
              type="text"
              autocomplete="off"
              maxlength="255"
              [placeholder]='myDealFormGroup.get("isServiceSameAsClientAddress")?.value == "NO" ? "Please enter &#39;Service-At Address&#39;" : "N/A"'>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4 margin-top-5">
              <span [class.highlight-warning]="highlightRequiredField('attestStatus')"
              [class.edited-externally]="editedExternally('attestStatus')"
              [class.conflict-on-merge]="hasMergeConflict('attestStatus')">Attest Status</span>
              <span *ngIf="showAsterisk('attestStatus')" class="red-asterik"></span>
          </div>
          <div class="col-xs-8">
              <app-dropdown
                [optionsList]="attestStatusList"
                [keySelected]="clientSetupFormGroup.get('attestStatus')?.value"
                [readonly]="isReadOnly('attestStatus')"
                (optionSelected)="onAttestStatusSelection($event)">
              </app-dropdown>
          </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('attestStatusParent')"
                [class.edited-externally]="editedExternally('attestStatusParent')"
                [class.conflict-on-merge]="hasMergeConflict('attestStatusParent')">Attest Status Parent</span>
                <span *ngIf="showAsterisk('attestStatusParent')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                  [optionsList]="attestStatusParentList"
                  [keySelected]="clientSetupFormGroup.get('attestStatusParent')?.value"
                  [readonly]="isReadOnly('attestStatusParent')"
                  [disabled]="clientSetupFormGroup.get('attestStatusParent')?.disabled"
                  (optionSelected)="onAttestStatusParentSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('previousAuditor')"
                [class.edited-externally]="editedExternally('previousAuditor')"
              [class.conflict-on-merge]="hasMergeConflict('previousAuditor')">Previous Auditor</span>
                <span *ngIf="showAsterisk('previousAuditor')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input
                  [formControl]="myDealFormGroup.get('previousAuditor')"
                  class="input-data-text full-width section-input"
                  [class.readonly]="isReadOnly('previousAuditor')"
                  type="text"
                  autocomplete="off"
                  maxlength="255"
                  [placeholder]="isNotAttestSelected()">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('currentAuditor')"
                [class.edited-externally]="editedExternally('currentAuditor')"
              [class.conflict-on-merge]="hasMergeConflict('currentAuditor')">Current Auditor</span>
                <span *ngIf="showAsterisk('currentAuditor')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input
                    [formControl]="myDealFormGroup.get('currentAuditor')"
                    class="input-data-text full-width section-input"
                    [class.readonly]="isReadOnly('currentAuditor')"
                    type="text"
                    autocomplete="off"
                    maxlength="255"
                    [placeholder]="isNotAttestSelected()">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('memberFirmPerformingAttest')"
                [class.edited-externally]="editedExternally('memberFirmPerformingAttest')"
              [class.conflict-on-merge]="hasMergeConflict('memberFirmPerformingAttest')">Member Firm performing Attest</span>
                <span *ngIf="showAsterisk('memberFirmPerformingAttest')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input
                  formControlName="memberFirmPerformingAttest"
                  class="input-data-text full-width section-input"
                  [class.readonly]="isReadOnly('memberFirmPerformingAttest')"
                  type="text"
                  autocomplete="off"
                  maxlength="255"
                  [placeholder]="isNotAttestSelected()">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('entityOnWhichPerformsAttestWork')"
                [class.edited-externally]="editedExternally('entityOnWhichPerformsAttestWork')"
              [class.conflict-on-merge]="hasMergeConflict('entityOnWhichPerformsAttestWork')">Entity on which Firm performs Attest work</span>
                <span *ngIf="showAsterisk('entityOnWhichPerformsAttestWork')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                  [optionsList]="entityOnWhichPerformsAttestWorkList"
                  [keySelected]="clientSetupFormGroup.get('entityOnWhichPerformsAttestWork')?.value"
                  [readonly]="isReadOnly('entityOnWhichPerformsAttestWork')"
                  [disabled]="clientSetupFormGroup.get('entityOnWhichPerformsAttestWork')?.disabled"
                  (optionSelected)="onEntityOnWhichPerformsAttestWorkSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 margin-top-5">
                    <span [class.highlight-warning]="highlightRequiredField('currentAuditorTenureExpiryDate')"
                    [class.edited-externally]="editedExternally('currentAuditorTenureExpiryDate')"
              [class.conflict-on-merge]="hasMergeConflict('currentAuditorTenureExpiryDate')">Current Auditor Tenure Expiry Date</span>
                    <span *ngIf="showAsterisk('currentAuditorTenureExpiryDate')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-date-picker
                        [selectedDate]="clientSetupFormGroup.get('currentAuditorTenureExpiryDate')?.value"
                        [readonly]="isReadOnly('currentAuditorTenureExpiryDate')"
                        [disabled]="clientSetupFormGroup.get('currentAuditorTenureExpiryDate')?.disabled"
                        (dateSelected)="onCurrentAuditorTenureExpiryDateSelection($event)">
                    </app-date-picker>
                    <span class="numeric-validation-msg"></span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 margin-top-5">
                    <span [class.highlight-warning]="highlightRequiredField('auditorStartDate')"
                    [class.edited-externally]="editedExternally('auditorStartDate')"
              [class.conflict-on-merge]="hasMergeConflict('auditorStartDate')">Auditor Start Date</span>
                    <span *ngIf="showAsterisk('auditorStartDate')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-date-picker
                      [selectedDate]="clientSetupFormGroup.get('auditorStartDate')?.value"
                      [readonly]="isReadOnly('auditorStartDate')"
                      [disabled]="clientSetupFormGroup.get('auditorStartDate')?.disabled"
                      (dateSelected)="onAuditorStartDateSelection($event)">
                    </app-date-picker>
                    <span class="numeric-validation-msg"></span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('pcaobReview')"
                [class.edited-externally]="editedExternally('pcaobReview')"
              [class.conflict-on-merge]="hasMergeConflict('pcaobReview')">PCAOB Review</span>
                <span *ngIf="showAsterisk('pcaobReview')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-toggle-yes-no
                  [value]="clientSetupFormGroup.get('pcaobReview')?.value"
                  [readonly]="isReadOnly('pcaobReview')"
                  [disabled]="clientSetupFormGroup.get('pcaobReview')?.disabled"
                  (handleToggleYesNo)="onPcaobReviewSelection($event)">
                </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('descDesignation')"
                [class.edited-externally]="editedExternally('descDesignation')"
              [class.conflict-on-merge]="hasMergeConflict('descDesignation')">DESC Designation</span>
                <span *ngIf="showAsterisk('descDesignation')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                 [optionsList]="descDesignationList"
                 [keySelected]="clientSetupFormGroup.get('descDesignation')?.value"
                 [readonly]="isReadOnly('descDesignation')"
                 [disabled]="clientSetupFormGroup.get('descDesignation')?.disabled"
                 (optionSelected)="onDescDesignationSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
              <span [class.highlight-warning]="highlightRequiredField('approvingPartnerTelephone')"
              [class.edited-externally]="editedExternally('approvingPartnerTelephone')"
              [class.conflict-on-merge]="hasMergeConflict('approvingPartnerTelephone')">Approving Partner Telephone Number</span>
              <span *ngIf="showAsterisk('approvingPartnerTelephone')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
              <app-numeric
                [type]="'number'"
                [value]="clientSetupFormGroup.get('approvingPartnerTelephone')?.value"
                [readonly]="isReadOnly('approvingPartnerTelephone')"
                [disabled]="clientSetupFormGroup.get('approvingPartnerTelephone')?.disabled"
                [placeholder]="isNotAttestSelected()"
                (selectedValue)="onApprovingPartnerTelephoneSelection($event)">
              </app-numeric>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
              <span [class.highlight-warning]="highlightRequiredField('clientAcceptanceProcedures')"
              [class.edited-externally]="editedExternally('clientAcceptanceProcedures')"
              [class.conflict-on-merge]="hasMergeConflict('clientAcceptanceProcedures')">Client Acceptance Procedures followed?</span>
              <span *ngIf="showAsterisk('clientAcceptanceProcedures')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
              <app-toggle-yes-no
                  [value]="clientSetupFormGroup.get('clientAcceptanceProcedures')?.value"
                  [readonly]="isReadOnly('clientAcceptanceProcedures')"
                  [disabled]="clientSetupFormGroup.get('clientAcceptanceProcedures')?.disabled"
                  (handleToggleYesNo)="onClientAcceptanceProceduresSelection($event)">
              </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('reasonForBypassingProcedures')"
                [class.edited-externally]="editedExternally('reasonForBypassingProcedures')"
              [class.conflict-on-merge]="hasMergeConflict('reasonForBypassingProcedures')">Reason for bypassing Client Acceptance Procedures</span>
                <span *ngIf="showAsterisk('reasonForBypassingProcedures')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8 dropdown-dependent-field">
                <app-dropdown #dropdownReasonClientProcedures
                    [disabled]="clientSetupFormGroup.get('reasonForBypassingProcedures')?.disabled"
                    [optionsList]="reasonForBypassingProceduresList"
                    [keySelected]="clientSetupFormGroup.get('reasonForBypassingProcedures')?.value"
                    [readonly]="isReadOnly('reasonForBypassingProcedures')"
                    (optionSelected)="onReasonForBypassingProceduresSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('anySpecialInstructions')"
                [class.edited-externally]="editedExternally('anySpecialInstructions')"
              [class.conflict-on-merge]="hasMergeConflict('anySpecialInstructions')">Any Special Instructions</span>
                <span *ngIf="showAsterisk('anySpecialInstructions')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <textarea formControlName="anySpecialInstructions"
                  type="text"
                  rows="3"
                  placeholder="You can add comments here"
                  [class.readonly]="isReadOnly('anySpecialInstructions')"
                  class="input-data-text text-area-style">
                </textarea>
            </div>
        </div>
    </div>
</div>
