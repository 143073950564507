import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ClosingChecklistFormComponent } from './closing-checklist-setup/closing-checklist-form.component';
import { SharedModule } from 'src/shared/SharedModule'; 
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DateFormatterService } from 'src/shared/utils/utils-date'; 
import { EngagementOverviewComponent } from './engagement-overview/engagement-overview.component';
import { PreviousS24TableComponent } from './previous-s24-table/previous-s24-table.component';
import { PreviousPpmdTableComponent } from './previous-ppmd-table/previous-ppmd-table.component';
import { SidebarComponent } from './sidebar/sidebar.component';
 import { CrossBorderComponent } from './cross-border/cross-border.component';
import { CapitalRaisedComponent } from './capital-raised/capital-raised.component';
 import { DttlTableComponent } from './dttl-table/dttl-table.component';
import { MarketingAndCommunicationsComponent } from './marketing-and-communications/marketing-and-communications.component';
import { BooksAndRecordsComponent } from './books-and-records/books-and-records.component';
import { SupervisorySignoffComponent } from './supervisory-signoff/supervisory-signoff.component';
import { CounterpartiesTableComponent } from './counterparties-table/counterparties-table.component';
import { FeesComponent } from './fees/fees.component';
import { ProjectOverviewComponent } from './project-overview/project-overview.component'
import { DealTeamTableComponent } from './deal-team-table/deal-team-table.component'; 
//import { FreeTextTableComponent } from '../shared/components/free-text-table/free-text-table.component'
 import { BuyerDealMarketingComponent } from './buyer-deal-marketing/buyer-deal-marketing.component';
import { ValuationMetricsComponent } from './valuation-metrics/valuation-metrics.component';
import { DealOverviewComponent } from './deal-overview/deal-overview.component';
import { ClientOverviewComponent } from './client-overview/client-overview.component';
import { DatesTimelineComponent } from './dates-timeline/dates-timeline.component';
import { QualityOfEarningsComponent } from './quality-of-earnings/quality-of-earnings.component';
 import { DealStructureComponent } from './deal-structure/deal-structure.component';
import { IndemnificationComponent } from './indemnification/indemnification.component';
import { FinanceComponent } from './finance/finance.components';
 import { DCFFeeStructureComponent } from './dcf-fee-structure/dcf-fee-structure.component';
import { SubmitForReviewComponent } from './submit-for-review/submit-for-review.component';
 import { AdditionalCommentaryComponent } from './additional-commentary/additional-commentary.component';
import { DealTeamSignoffsComponent } from './deal-team-signoffs/deal-team-signoffs.component';
import { Series24ApprovalComponent } from './series-24-approval/series-24-approval.component';
import { FreeTextTableComponent } from '../../shared/components/free-text-table/free-text-table.component';
import { DropdownComponent } from '../../shared/components/dropdown/dropdown.component';
import { AlertModule } from '../_alert';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule
  ],
  declarations: [
    ClosingChecklistFormComponent,
    ValuationMetricsComponent,
    FinanceComponent,
    SubmitForReviewComponent,
     AdditionalCommentaryComponent,
     DealTeamSignoffsComponent,
    Series24ApprovalComponent,
    DCFFeeStructureComponent,
    DealOverviewComponent,
     ClientOverviewComponent,
     DatesTimelineComponent,
    QualityOfEarningsComponent,
    IndemnificationComponent,
    DealStructureComponent,
    BuyerDealMarketingComponent,
    EngagementOverviewComponent,
    PreviousS24TableComponent,
    PreviousPpmdTableComponent,
    SidebarComponent,
    CrossBorderComponent,
     CapitalRaisedComponent,
     DttlTableComponent,
    MarketingAndCommunicationsComponent,
     BooksAndRecordsComponent,
    SupervisorySignoffComponent,
     CounterpartiesTableComponent,
    FeesComponent,
    ProjectOverviewComponent,
    DealTeamTableComponent,
    FreeTextTableComponent
    
  ],
  exports: [
    ClosingChecklistFormComponent,
    ValuationMetricsComponent,
    FinanceComponent,
    SubmitForReviewComponent,
     AdditionalCommentaryComponent,
    DealTeamSignoffsComponent,
    Series24ApprovalComponent,
     DCFFeeStructureComponent,
     DealOverviewComponent,
    //  ClientOverviewComponent,
    DatesTimelineComponent,
    QualityOfEarningsComponent,
    IndemnificationComponent,
     DealStructureComponent,
     BuyerDealMarketingComponent,
    EngagementOverviewComponent,
    PreviousS24TableComponent,
    PreviousPpmdTableComponent,
    // SidebarComponent,
    CrossBorderComponent,
     CapitalRaisedComponent,
     DttlTableComponent,
    MarketingAndCommunicationsComponent,
     BooksAndRecordsComponent,
    SupervisorySignoffComponent,
     CounterpartiesTableComponent,
    FeesComponent,
    FreeTextTableComponent
   
    // ProjectOverviewComponent,
    // DealTeamTableComponent
  ],
  providers: [
    DatePipe,
    DateFormatterService
    
    
  ]
})
export class ClosingChecklistModule { }
