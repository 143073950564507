<div [formGroup]="clientOverviewFormGroup" id="client-overview" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span>Client Overview</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>This should be very straightforward and should all be information that has been entered elsewhere previously (i.e. New Deal Form, Rtrak, etc.)</p>
            <p>
                The Referral Source has the same general categories as the Deal Sheet. For Referral Source Sub-Type, please provide additional detail on the specific source. Example:
        <br>
                <span class="tooltiptext-dcf-bold tooltiptext-dcf-underline">Internal/International Referrals
                </span>
                <br>
                If the source is Deloitte Consulting, the Referral Source would be "Deloitte U.S." and the Sub-Type would be "Consulting".<br>
                Deloitte UK = "Deloitte Int'l Firm" and "UK"
            </p>
        </span>
    </div>
    <div id="client-overview-section" class="content-section">
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('clientName')"
                [class.edited-externally]="editedExternally('clientName')"
                [class.conflict-on-merge]="hasMergeConflict('clientName')">Client Name</span>
            <span *ngIf="showAsterisk('clientName')" class="red-asterik"></span>
                <br>
            </div>
            <div class="col-xs-8">
                <input  
                [formControl]="myDealFormGroup.get('clientName')"               
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                [class.highlight-warning]="highlightRequiredField('clientType')"
                [class.edited-externally]="editedExternally('clientType')"
                [class.conflict-on-merge]="hasMergeConflict('clientType')"
                >Client Type</span>
                <span *ngIf="showAsterisk('clientType')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <!-- <select name="ClientType" id="ClientType" class="input-data-select input-select section-select enableDependencyInput"
                    disable-target-input="SponsorName,SubsidiaryBeingSold"
                    disable-target-value-for-sponsorname="Family/Entrepreneur Owned,Corporate - Private,Corporate - Public"
                    disable-target-value-for-subsidiarybeingsold="PEG-Backed,Family/Entrepreneur Owned"
                    value-when-input-disabled-for-sponsorname="N/A" value-when-input-disabled-for-subsidiarybeingsold="N/A">
                    <option value="0" disabled selected>Select your option</option>
                </select> -->

                <app-dropdown
                [optionsList]="clientTypeOptions"
                [keySelected]="clientOverviewFormGroup.get('clientType')?.value"
                [readonly]="isReadOnly('clientType')"
                (optionSelected)="onClientTypeSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label dependent">
                <!-- <span class="span-to-highlight dependent-field">Sponsor Name</span> -->
                <span class="span-to-highlight dependent-field" 
                [class.highlight-warning]="highlightRequiredField('sponsorName')"
                [class.edited-externally]="editedExternally('sponsorName')"
                [class.conflict-on-merge]="hasMergeConflict('sponsorName')">Sponsor Name</span>
            <span *ngIf="showAsterisk('sponsorName')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8 dependent">
                <input
                formControlName="sponsorName"
                class="input-data-text full-width section-input"
                type="text"
                [disabled]="clientOverviewFormGroup.get('sponsorName')?.disabled"
                autocomplete="off"
                [placeholder]="clientOverviewFormGroup.get('sponsorName')?.disabled ? 'N/A' : ''"
                >
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label dependent">
                <span class="span-to-highlight dependent-field"
                [class.highlight-warning]="highlightRequiredField('subsidiaryBeingSold')"
                [class.edited-externally]="editedExternally('subsidiaryBeingSold')"
                [class.conflict-on-merge]="hasMergeConflict('subsidiaryBeingSold')">Subsidiary being sold (if divestiture)</span>
                <span *ngIf="showAsterisk('subsidiaryBeingSold')" class="red-asterik align"></span>
            </div>
            <div class="col-xs-8 dependent">
                <input
                formControlName="subsidiaryBeingSold"
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off"
                [disabled]="clientOverviewFormGroup.get('subsidiaryBeingSold')?.disabled"
                [placeholder]="clientOverviewFormGroup.get('subsidiaryBeingSold')?.disabled ? 'N/A' : ''"
                >
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight">Client Relationship</span>
                <!-- <span *ngIf="showAsterisk('clientRelationship')" class="red-asterik"></span> -->
            </div>
            <div class="col-xs-8">
                <app-dropdown
                [optionsList]="clientRelationshipOptions"
                [keySelected]="clientOverviewFormGroup.get('clientRelationship')?.value"
                [readonly]="isReadOnly('clientRelationship')"
                (optionSelected)="onClientRelationshipSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                [class.highlight-warning]="highlightRequiredField('referralSource')"
                [class.edited-externally]="editedExternally('referralSource')"
                [class.conflict-on-merge]="hasMergeConflict('referralSource')">Referral Source</span>
                <span *ngIf="showAsterisk('referralSource')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <!-- <select name="ReferralSource" id="ReferralSource" class="input-data-select input-select section-select enableDependencyInput"
                    disable-target-input="ReferralSourceSubType"
                    disable-target-value-for-referralsourcesubtype="DCF - MROC"
                    value-when-input-disabled-for-referralsourcesubtype="N/A">
                    <option value="0" disabled selected>Select your option</option>
                </select> -->
                <app-dropdown
                [optionsList]="referralSourceOptions"
                [keySelected]="clientOverviewFormGroup.get('referralSource')?.value"
                [readonly]="isReadOnly('referralSource')"
                (optionSelected)="onReferralSourceSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label dependent">
                <span class="span-to-highlight dependent-field"
                [class.highlight-warning]="highlightRequiredField('referralSourceSubType')"
                [class.edited-externally]="editedExternally('referralSourceSubType')"
                [class.conflict-on-merge]="hasMergeConflict('referralSourceSubType')">Referral Source Sub-Type</span>
                <span *ngIf="showAsterisk('referralSourceSubType')" class="red-asterik"></span>
                
                    <app-popover
          [tooltipText]="getTooltipText('referringGroup')"
         >
        </app-popover>
                    <!-- Write in referring group (i.e. Tax, BVAL, Consulting), Deloitte Firm (i.e. UK, Japan, Mexico), or other source (i.e. Deutsche)</span> -->
            </div>
            <div class="col-xs-8 dependent">
                <input
                formControlName="referralSourceSubType"
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off"
                [disabled]="clientOverviewFormGroup.get('referralSourceSubType')?.disabled"
                [placeholder]="clientOverviewFormGroup.get('referralSourceSubType')?.disabled ? 'N/A' : ''">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                [class.highlight-warning]="highlightRequiredField('clientLocation')"
                [class.edited-externally]="editedExternally('clientLocation')"
                [class.conflict-on-merge]="hasMergeConflict('clientLocation')">Client Location (State or Int'l)</span>
                <span *ngIf="showAsterisk('clientLocation')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                [optionsList]="locationList"
                [keySelected]="clientOverviewFormGroup.get('clientLocation')?.value"
                [readonly]="isReadOnly('clientLocation')"
                (optionSelected)="onLocationSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label dependent">
                <span class="span-to-highlight dependent-field"
                [class.highlight-warning]="highlightRequiredField('clientCountry')"
                [class.edited-externally]="editedExternally('clientCountry')"
                [class.conflict-on-merge]="hasMergeConflict('clientCountry')">Client Country (if Int'l)</span>
                <span *ngIf="showAsterisk('clientCountry')" class="red-asterik"></span>
               
                
                    <app-popover
                    [tooltipText]="getTooltipText('clientCountry')">
                  </app-popover>
                    
            </div>
            <div class="col-xs-8 dependent">
                <app-dropdown
                [optionsList]="countryList"
                [keySelected]="clientOverviewFormGroup.get('clientCountry')?.value"
                [disabled]="clientOverviewFormGroup.get('clientCountry')?.disabled" 
                [readonly]="isReadOnly('clientCountry')"
                (optionSelected)="onCountrySelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                [class.highlight-warning]="highlightRequiredField('industry')"
                [class.edited-externally]="editedExternally('industry')"
                [class.conflict-on-merge]="hasMergeConflict('industry')">Industry</span>
                <span *ngIf="showAsterisk('industry')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                [optionsList]="industryOptions"
                [keySelected]="myDealFormGroup.get('industry')?.value"
                [readonly]="isReadOnly('industry')"
                [disabled]="myDealFormGroup.get('industry')?.disabled"
                (optionSelected)="onindustrySelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                [class.highlight-warning]="highlightRequiredField('subsector')"
                [class.edited-externally]="editedExternally('subsector')"
                [class.conflict-on-merge]="hasMergeConflict('subsector')">Subsector</span>
                <span *ngIf="showAsterisk('subsector')" class="red-asterik"></span>
                <app-popover
                    [tooltipText]="getTooltipText('subSector')"
                   >
                  </app-popover>
                
            </div>
            <div class="col-xs-8">
                <textarea class="text-area-style input-data-text my-deal-input" 
                [formControl]="myDealFormGroup.get('subsector')" 
                rows="3"
                type="text">
                </textarea>
            </div>
        </div>
    </div>
</div>
