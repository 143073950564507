import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PaginationValues } from '../../models/pagination-values';
import { DropdownItem } from '../../models/dropdown-item';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() page: number;
  @Input() pageSize: any;
  @Input() paginationListValues: Array<DropdownItem>;
  @Input() listElementsSize: number;
  @Input() rotate = true;
  @Input() boundaryLinks = true;
  @Input() directionLinks = true;
  @Input() maxSize = 5;
  @Input() disabled = false;

  @Output() paginationValues: EventEmitter<PaginationValues> = new EventEmitter<PaginationValues>();

  constructor() { }

  ngOnInit() {}

  onChangeSelection() {
    const values: PaginationValues = {
      page: this.page,
      pageSize: this.pageSize
    };
    this.paginationValues.emit(values);
  }

  onPageSizeSelection(event: DropdownItem) {
    this.pageSize = Number(event.key);
    this.onChangeSelection();
  }

  setPaginationValues(values: Array<DropdownItem>) {
    this.paginationListValues = values;
  }

}
