import { Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { PeoplePickerService } from 'src/app/services/peoplepicker.service'; 
import { AdminService } from 'src/app/services/admin.service';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';

@Component({
  selector: 'app-peoplepicker-search',
  templateUrl: './peoplepicker-search.component.html',
  styleUrls: ['./peoplepicker-search.component.scss']
})
export class PeoplepickerSearchComponent{
  @ViewChild('input') inputEl: any;
  searchText:any='';
  searchData:any=[];
  isUserExist:any=true;
  selectedUser=[];
  public base64:string='data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAADi0lEQVR4nO2cTUtVURSGH9MiBUsqJYhMy8I+iAbRxASptBo47MsaBP2A/kKTIJoFFSH9C0chRaFWGEVUphlC2aiENAujLG2wrmAXO7dz7tlr7+tZD7yj+7H2etl337PXWWeDYRiGYRiGYRhGoJT5HkABmoCjQAvQDGwBqnOvTQPjwAjQD9wBxjyMsWSpAM4CD4H5mBoAuoBy9VGXGMeAUeIbnK8RoEN57CVBFXCb4g3OVzdQqZhH0NQBT0nf5AU9AWrVsgmUOuRn7srkxUtJZs2uwu1MXmpmr1bJLDBcrMmFdEsls4DoQN/kBR1XyC8IKkjnEi6phsnIdfY5/Jm8oNPOswyAR/g3ut95lp5pwr/J88Ac0Og4179YoRkMKRCFQBnKW3Rto1uU40XRqhlM2+idyvGiaNYMpm30ZuV4UdRrBtM2urrwW9RYoxlM+w7LvHK8Qqjlrz2jM4sZrYS20T+V40XxQzOYttFfleNFMa0ZTNvoD8rxohjXDKZt9GvleFEMawbTNnpAOV4Uy7qCtw2pnIVQvWtwnKt3knQgpa0+51kGQBf+jT7pPMsAKEenl+NfGiIj9wwB2vFn9CGF/IKiG32Tb6pkFhiVSPeQlsmPyWinEkg/nMZ6PQxsUMopWGqBQdzO5Mw2OOZTifTFuViTM7tcRHEE+ZkXa/AQGby6iEs5cAapRcTZrs8hO75TBHhDI/SnshqRRpdWpFUh/6ms98ifaR/yVNY7D2M0DMMwDMMwDMMobULcGa4FdgF7gN3ADmAdUJN7rSb3vqlFmgTeIDWOV0j/iGonUiFCMHojUgA6DLQBW1P63jHgPnAXuAd8TOl7S4p9wFVk9mncXZkDXgBXgL0K+XmlHrgIPEPH3EJl1EvAdqcZK3MQ6CGMDqWlZnov0EkYy2hsVgEXgJf4N/N/9Rw4D6x04IcTOoG3+DcuqUaBEwQ8ww8gd0V8G5WWHgD7U3WoSCqQP5Zf+Dcnbf0GrhHActKA9Dz7NsS1BpEDA7zQhuzKfJugpUmUnx8nF/B7kQMvRc2geHDAemDCcUIhawKpvcQiSZ/wZaQukVWqEN96XQfyefBUKIr9dFmSi/IZ7LzPb8Q8qSFJ69SnBJ9ZbsQuuSYxuifBZ5YbKh5sAj7jf530pSkUT9JpB744TihETeKhHbgRuIFchcwmHHgpaDaX43Wkm9UwDMMwDMMwDMMwDCPz/AHwMcyzZEtWEAAAAABJRU5ErkJggg==';
  noImageFile:any="eyJl";
  lastSearchTerm:string='';
  @Input()
  placeholder = 'Search People';
   
  @Output()
   selectedPeoplePickerUser: EventEmitter<any> = new EventEmitter<any>();
  constructor(private peoplepickerservice:PeoplePickerService,private adminService:AdminService,private commonHelperService:CommonHelperService,private spinnerService:SpinnerService) {}
  onChange($event:any){ 

        if(this.searchText.trim().length>2){  
         // this.spinnerService.showAutoSaveSpinner();        
          setTimeout(()=>{
            if(this.lastSearchTerm != this.searchText && this.searchText != '')                       
            {this.getPeoplePicker();}
          }, 500);          
        }      
    }
    getPeoplePicker(){
      this.isUserExist=true;
      this.peoplepickerservice.getPeoplePickerUsers(this.searchText).subscribe((result:any)=>{
        if(result != undefined &&result != null&&result != "")
        {                  
            this.searchData = [];   
            this.searchData = this.selectedUser.slice();                            
            result.value.forEach((element: any) => {              
              if((this.searchData.findIndex((el:any)=>el.mail == element.mail) == -1)){
                element.isSelected = false;
                 this.searchData.push(element);                
              }                            
            }); 
              this.searchData.forEach((e:any) => {
                 if(e.profilepicture.substring(0, 4)==this.noImageFile){
                //  e.profilepicture="../../../assets/img/Shadow-image.png";
                 e.profilepicture=this.base64;
                 }
                 else{
                  e.profilepicture="data:image/jpg;base64,"+e.profilepicture;
                 }
              });              
              this.spinnerService.hideAutoSaveSpinner();                                          
         } 
         else{
          this.isUserExist=false;
          this.searchData = []; 
          this.spinnerService.hideAutoSaveSpinner();    
         }         
      });
      this.lastSearchTerm = this.searchText;
    }
  onSelectUser(event:any)  {    
    event.isSelected = event.isSelected ? false : true;
    this.searchData.forEach((el:any)=>{
      if(el.mail==event.mail){el=event;}
    })    
    this.selectedUser = this.searchData.filter((el:any)=>el.isSelected == true); 
    this.selectedPeoplePickerUser.emit(this.selectedUser);
  }
  onSelectUserCheckBox(event:any){    
  }
  addSelectedUser() {
    if(this.selectedUser.length>0){
    this.inputEl.nativeElement.value="";    
    this.selectedPeoplePickerUser.emit(this.selectedUser);
    this.searchData = [];
    this.selectedUser=[];
    this.searchText = '';
    this.lastSearchTerm='';
    }
  }
  close(){
    this.inputEl.nativeElement.value="";
    this.searchData = [];
    this.selectedUser=[];
    this.searchText = '';
    this.lastSearchTerm='';
    this.isUserExist=true;
  }
}
