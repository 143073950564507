import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {

  constructor(private datePipe: DatePipe,public globalHelperService:GlobalHelperService) {}

  formatDateToCST(date: Date = new Date()): string | any{
    return this.datePipe.transform(date, 'dd MMM y hh:mm a', 'CST');
  }

  formatDateToSend(date:string) {
    //return this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC');
    
    return date ? 
    this.datePipe.transform(date.toString().length == 20 ? date.toString().slice(0,10):date.toString().slice(0,15), 'yyyy-MM-dd z')?.slice(0,10) 
    : null;
  }

  formatDateOnRetrieve(date: string): Date | any { 
     // return date ?new Date(this.datePipe.transform(date, 'medium', 'UTC')) : null;
    let res= date ? this.datePipe.transform(date.replace('Z',''), 'medium') : null;
    if(res!=null){
      return new Date(res);
     }
     return res;
  }
  formatDate(date: Date = new Date(), format: string) {
    return this.datePipe.transform(date, format);
  }
  formatDateToCDT(date: Date = new Date()) {
    if(this.globalHelperService.currentAccessPage?.toString() == "DealWBSForm")
    {
      return this.datePipe.transform(date, 'dd MMM y hh:mm a', 'CST');
    }
    else
    {
      return this.datePipe.transform(date, 'dd MMM y hh:mm a', 'CDT');      
    }    
  }

  formatDateToCDT_CCL(date: Date = new Date()) {
    return this.datePipe.transform(date, 'dd MMM y hh:mm a', 'CDT');
  }
}
