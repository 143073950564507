<div [formGroup]="feesFormGroup" class="content-section" id="fees">
  <div class="row">
      <span class="title-section">Fees</span>
  </div>
  <div class="row">
    <div class="col-xs-4 margin-top-5">
      <span [class.highlight-warning]="highlightRequiredField('totalAmountsPaid')"
      [class.edited-externally]="editedExternally('totalAmountsPaid')"
        [class.conflict-on-merge]="hasMergeConflict('totalAmountsPaid')">Total Amounts paid by Client</span>
      <span *ngIf="showAsterisk('totalAmountsPaid')" class="red-asterik"></span>
      <app-popover
        [tooltipText]="tooltipTotalAmount">
      </app-popover>
    </div>
    <div class="col-xs-7">
      <app-numeric class="complianceNumeric"
        [type]="'decimal2'"
        [format]="'currencyWithDecimal'"
        [readonly]="isReadOnly('totalAmountsPaid')"
        [value]="feesFormGroup.get('totalAmountsPaid').value"
        (selectedValue)="onTotalAmountsPaidSelection($event)">
      </app-numeric>
    </div>
  </div>
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('retainerReceived')"
          [class.edited-externally]="editedExternally('retainerReceived')"
        [class.conflict-on-merge]="hasMergeConflict('retainerReceived')">Retainer Received?</span>
          <span *ngIf="showAsterisk('retainerReceived')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
          <app-toggle-yes-no  #yesNoRetainerReceived
            [value]="feesFormGroup.get('retainerReceived').value"
            [readonly]="isReadOnly('retainerReceived')"
            (handleToggleYesNo)="onRetainerReceivedSelection($event)"
            class="buttons-app-yes-no">
          </app-toggle-yes-no>
      </div>
  </div>  
  <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span class="identation-span">
          <p [class.highlight-warning]="highlightRequiredField('periodicOrFlat')"
          [class.edited-externally]="editedExternally('periodicOrFlat')"
        [class.conflict-on-merge]="hasMergeConflict('periodicOrFlat')">Periodic Retainer or Flat Fee?</p>
          <span *ngIf="showAsterisk('periodicOrFlat')" class="red-asterik"></span>
        </span>
      </div>
      <div class="col-xs-7">
        <app-dropdown
          [optionsList]="periodicOrFlatOptions"
          [keySelected]="feesFormGroup.get('periodicOrFlat').value"
          [readonly]="isReadOnly('periodicOrFlat')"
          [disabled]="feesFormGroup.get('periodicOrFlat').disabled"
          (optionSelected)="onPeriodicOrFlatSelection($event)">
        </app-dropdown>
      </div>
  </div>
  <div class="row">
    <div class="col-xs-4 margin-top-5">
      <span class="identation-span">
        <p [class.highlight-warning]="highlightRequiredField('amountRetainer')"
        [class.edited-externally]="editedExternally('amountRetainer')"
        [class.conflict-on-merge]="hasMergeConflict('amountRetainer')">Amount</p>
        <span *ngIf="showAsterisk('periodicOrFlat')" class="red-asterik"></span>
      </span>
    </div>
    <div class="col-xs-7">
      <app-numeric class="complianceNumeric"
        [type]="'decimal2'"
        [format]="'currency'"
        [readonly]="isReadOnly('amountRetainer')"
        [disabled]="feesFormGroup.get('amountRetainer').disabled"
        [placeholder]="feesFormGroup.get('amountRetainer').disabled ? 'N/A' : ''"
        [value]="feesFormGroup.get('amountRetainer').value"
        (selectedValue)="onAmountRetainerSelection($event)">
      </app-numeric>
    </div>
  </div>  
  <div class="row">
    <div class="col-xs-4 margin-top-5">
      <span class="identation-span">
        <p [class.highlight-warning]="highlightRequiredField('periodRetainer')"
        [class.edited-externally]="editedExternally('periodRetainer')"
        [class.conflict-on-merge]="hasMergeConflict('periodRetainer')"
         class="period-class">Period</p>
        <span *ngIf="showAsterisk('periodRetainer')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="tooltipPeriod">
        </app-popover>
      </span>
    </div>
    <div class="col-xs-7">
        <input
            formControlName="periodRetainer"
            class="input-data-text full-width section-input"
            type="text"
            autocomplete="off"
            [placeholder]="feesFormGroup.get('periodRetainer').disabled ? 'N/A': ''">
    </div>
  </div>  
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('retainerCredited')"
          [class.edited-externally]="editedExternally('retainerCredited')"
        [class.conflict-on-merge]="hasMergeConflict('retainerCredited')">Retainer credited against the success fee?</span>
          <span *ngIf="showAsterisk('retainerCredited')" class="red-asterik"></span>
      </div>
      <!--<div class="col-xs-7">
          <app-toggle-yes-no  #yesNoRetainerCredited
            [value]="feesFormGroup.get('retainerCredited').value"
            [readonly]="isReadOnly('retainerCredited')"
            (handleToggleYesNo)="onRetainerCreditedSelection($event)"
            notApplicableEnabled
            class="buttons-app-yes-no">
          </app-toggle-yes-no>
      </div>-->
    <div class="col-xs-7">
          <app-toggle-yes-no  #yesNoRetainerCredited
            [value]="feesFormGroup.get('retainerCredited').value"
            [readonly]="isReadOnly('retainerCredited')"
            (handleToggleYesNo)="onRetainerCreditedSelection($event)"
            [notApplicableEnabled]="notApplicableEnabled"
            class="buttons-app-yes-no">
          </app-toggle-yes-no>
      </div>
  </div>  
  <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('engagementFeeBased')"
        [class.edited-externally]="editedExternally('engagementFeeBased')"
        [class.conflict-on-merge]="hasMergeConflict('engagementFeeBased')">Was the engagement fee T&M, milestone (with final milestone not tied to a closing) or success based?</span>
        <span *ngIf="showAsterisk('engagementFeeBased')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
        <app-dropdown
          [optionsList]="engagementFeeBasedOptions"
          [keySelected]="feesFormGroup.get('engagementFeeBased').value"
          [readonly]="isReadOnly('engagementFeeBased')"
          (optionSelected)="onEngagementFeeBasedSelection($event)">
        </app-dropdown>
      </div>
  </div>
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('successFeePaidFull')"
          [class.edited-externally]="editedExternally('successFeePaidFull')"
        [class.conflict-on-merge]="hasMergeConflict('successFeePaidFull')">Was the Success Fee paid in full at closing?</span>
          <span *ngIf="showAsterisk('successFeePaidFull')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
          <app-toggle-yes-no  #yesNoSuccessFeePaidFull
            [value]="feesFormGroup.get('successFeePaidFull').value"
            [readonly]="isReadOnly('successFeePaidFull')"
            (handleToggleYesNo)="onSuccessFeePaidFullSelection($event)"
            class="buttons-app-yes-no">
          </app-toggle-yes-no>
      </div>
  </div>  
  <div class="row">
    <div class="col-xs-4 margin-top-5">
      <span class="identation-span">
        <p [class.highlight-warning]="highlightRequiredField('amountSuccessPaid')"
        [class.edited-externally]="editedExternally('amountSuccessPaid')"
        [class.conflict-on-merge]="hasMergeConflict('amountSuccessPaid')">If the total Success Fee was not paid at closing, what amount was paid at closing?</p>
        <span *ngIf="showAsterisk('amountSuccessPaid')" class="red-asterik"></span>
      </span>
    </div>
    <div class="col-xs-7">
      <app-numeric class="complianceNumeric"
        [type]="'decimal2'"
        [format]="'currencyWithDecimal'"
        [readonly]="isReadOnly('amountSuccessPaid')"
        [disabled]="feesFormGroup.get('amountSuccessPaid').disabled"
        [placeholder]="feesFormGroup.get('amountSuccessPaid').disabled ? 'N/A':''"
        [value]="feesFormGroup.get('amountSuccessPaid').value"
        (selectedValue)="onAmountSuccessPaidSelection($event)">
      </app-numeric>
    </div>
  </div>  
  <div class="row">
    <div class="col-xs-4 margin-top-5">
      <span class="identation-span">
        <p [class.highlight-warning]="highlightRequiredField('potentialAmountOutstanding')"
        [class.edited-externally]="editedExternally('potentialAmountOutstanding')"
        [class.conflict-on-merge]="hasMergeConflict('potentialAmountOutstanding')">If the total Success Fee was not paid at closing, what is the potential success fee amount outstanding post closing?</p>
        <span *ngIf="showAsterisk('potentialAmountOutstanding')" class="red-asterik"></span>
      </span>
    </div>
    <div class="col-xs-7">
      <app-numeric class="complianceNumeric"
        [type]="'decimal2'"
        [format]="'currencyWithDecimal'"
        [readonly]="isReadOnly('potentialAmountOutstanding')"
        [placeholder]="feesFormGroup.get('potentialAmountOutstanding').disabled ?'N/A':''"
        [disabled]="feesFormGroup.get('potentialAmountOutstanding').disabled"
        [value]="feesFormGroup.get('potentialAmountOutstanding').value"
        (selectedValue)="onPotentialAmountOutstandingSelection($event)">
      </app-numeric>
    </div>
  </div>  
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('dateSuccessFeeReceived')"
          [class.edited-externally]="editedExternally('dateSuccessFeeReceived')"
        [class.conflict-on-merge]="hasMergeConflict('dateSuccessFeeReceived')">Date success fee received</span>
          <span *ngIf="showAsterisk('dateSuccessFeeReceived')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
        <app-date-picker #pickerDateDateSuccessFeeReceived
          [selectedDate]="feesFormGroup.get('dateSuccessFeeReceived').value"
          [readonly]="isReadOnly('dateSuccessFeeReceived')"
          (dateSelected)="onDateSuccessFeeReceivedSelection($event)">
        </app-date-picker>
      </div>
  </div>  
  <div class="row">
    <div class="col-xs-4 margin-top-5">
      <span [class.highlight-warning]="highlightRequiredField('expensesBilled')"
      [class.edited-externally]="editedExternally('expensesBilled')"
        [class.conflict-on-merge]="hasMergeConflict('expensesBilled')">Expenses billed to the client?</span>
      <span *ngIf="showAsterisk('expensesBilled')" class="red-asterik"></span>
    </div>
    <div class="col-xs-7">
      <app-numeric class="complianceNumeric"
        [type]="'decimal2'"
        [format]="'currency'"
        [readonly]="isReadOnly('expensesBilled')"
        [disabled]="feesFormGroup.get('expensesBilled').disabled"
        [value]="feesFormGroup.get('expensesBilled').value"
        (selectedValue)="onExpensesBilledSelection($event)">
      </app-numeric>
    </div>
  </div>  
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('finderReferralFeeOwed')"
          [class.edited-externally]="editedExternally('finderReferralFeeOwed')"
        [class.conflict-on-merge]="hasMergeConflict('finderReferralFeeOwed')">Finder / Referral Fee owed?</span>
          <span *ngIf="showAsterisk('finderReferralFeeOwed')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
          <app-toggle-yes-no  #yesNoExpensesBilled
            [value]="feesFormGroup.get('finderReferralFeeOwed').value"
            [readonly]="isReadOnly('finderReferralFeeOwed')"
            (handleToggleYesNo)="onFinderReferralFeeOwedSelection($event)"
            class="buttons-app-yes-no">
          </app-toggle-yes-no>
      </div>
  </div>  
  <div class="row">
    <div class="col-xs-4 margin-top-5">
      <span class="identation-span">
        <p [class.highlight-warning]="highlightRequiredField('amountFinderOwed')"
        [class.edited-externally]="editedExternally('amountFinderOwed')"
        [class.conflict-on-merge]="hasMergeConflict('amountFinderOwed')">Amount</p>
        <span *ngIf="showAsterisk('amountFinderOwed')" class="red-asterik"></span>
      </span>
    </div>
    <div class="col-xs-7">
        <app-numeric class="complianceNumeric"
          [type]="'decimal2'"
          [format]="'currency'"
          [readonly]="isReadOnly('amountFinderOwed')"
          [disabled]="feesFormGroup.get('amountFinderOwed').disabled"
          [placeholder]="feesFormGroup.get('amountFinderOwed').disabled ? 'N/A':''"
          [value]="feesFormGroup.get('amountFinderOwed').value"
          (selectedValue)="onAmountFinderOwedSelection($event)">
        </app-numeric>
    </div>
  </div>  
  <div class="row">
    <div class="col-xs-4 margin-top-5">
      <span class="identation-span">
        <p [class.highlight-warning]="highlightRequiredField('recipientFinderFee')"
        [class.edited-externally]="editedExternally('recipientFinderFee')"
        [class.conflict-on-merge]="hasMergeConflict('recipientFinderFee')">Recipient</p>
        <span *ngIf="showAsterisk('recipientFinderFee')" class="red-asterik"></span>
      </span>
    </div>
    <div class="col-xs-7">
        <input
            formControlName="recipientFinderFee"
            class="input-data-text full-width section-input"
            type="text"
            autocomplete="off"
            [placeholder]="feesFormGroup.get('recipientFinderFee').disabled ? 'N/A':''">
    </div>
  </div>
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span class="identation-span">
            <p [class.highlight-warning]="highlightRequiredField('datePaidFinderFee')"
            [class.edited-externally]="editedExternally('datePaidFinderFee')"
        [class.conflict-on-merge]="hasMergeConflict('datePaidFinderFee')">Date Paid</p>
            <span *ngIf="showAsterisk('datePaidFinderFee')" class="red-asterik"></span>
          </span>
      </div>
      <div class="col-xs-7">
        <app-date-picker #pickerDateDatePaidFinderFee
          [selectedDate]="feesFormGroup.get('datePaidFinderFee').value"
          [readonly]="isReadOnly('datePaidFinderFee')"          
          [disabled]="feesFormGroup.get('datePaidFinderFee').disabled"
          (dateSelected)="onDatePaidFinderFeeSelection($event)">
        </app-date-picker>
      </div>
  </div>
</div>
