import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppSettings } from "src/shared/app-settings";
import { AttachmentItem } from "src/shared/models/attachment-item";
import { AttachmentToDeleteItem } from "src/shared/models/attachment-to-delete-item";
import { DocumentTypeItem } from "src/shared/models/document-type-item";
import { DropdownItem } from "src/shared/models/dropdown-item";
import { SharepointFileItem } from "src/shared/models/sharepoint-file-item";
import { TooltipObject } from "src/shared/models/tooltip-object";
import { DropdownService } from "src/shared/services/dropwdown.service"; 
import { FilesService } from "src/shared/services/files.service";
import { SecurityService } from "src/shared/services/security.service";
import { Spcrud } from "src/shared/services/spcrud";
import { TooltipHelperService } from "src/shared/services/tooltip-helper.service";
import { FormValidations } from "src/shared/utils/form-validations";
import { FormListFields } from "../shared/models/form-list-fields";
import { ComplianceServiceHelper } from "../shared/services/compliance-service-helper";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { catchError } from "rxjs";
import { ClosingChecklistNotifyPopupComponent } from "src/shared/components/alerts/closing-checklist-notify-popup/closing-checklist-notify-popup.component";
import { environment } from "src/environments/environment";
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';
import { NewdealService } from "src/app/services/newdeal.service";

const modalTypes  = {
  additionalDocumentTypes: 'additional-document-types',
  deleteAttachment: 'delete-attachment',
  fileNameUploading: 'file-name-uploading',
  fileOverwrite: 'file-overwrite',
  fileNameBlocked: 'file-name-blocked',
  fileNameTooLong: 'file-name-too-long',
  fileEncrypted: 'file-encrypted',
  fileEmpty: 'file-empty'
};

@Component({
    selector: 'app-finance',
    templateUrl: './finance.component.html',
    styleUrls: ['./finance.component.scss']
  })
export class FinanceComponent implements OnInit {

  
    @Input() formGroups:Array<FormGroup>;
    @Input() formSubmitted: boolean;
    @Input() disabled: boolean=false;
    @Input() isFinanceForm: boolean = false;
    @Input() readOnly:boolean;
    financeFormGroup:any;
    @Output() selectedFile = new EventEmitter<boolean>();
    @Output() uploadedAttachments = new EventEmitter<any>();
    @Output() providerNameType = new EventEmitter<string>();

    @ViewChild(ClosingChecklistNotifyPopupComponent, { static: true }) cclPopupComponent: ClosingChecklistNotifyPopupComponent;

    sharePointMapper:any = new ComplianceSharepointMapper();
    financeFuturePaymentsTypeOptions:Array<DropdownItem>=[];
    countryOptions:Array<DropdownItem>=[];
    financeProviderNameTypeOptions:Array<DropdownItem>=[];

    // Fields related to Document upload
    documentTypesValues: Array<DocumentTypeItem> | any = [];
    additionalDocumentTypesAdded: Array<DocumentTypeItem> = [];
    additionaldocumentTypesToAdd: Array<DocumentTypeItem>;
    // documentTypesToShow: Array<DocumentTypeItem> = []; Uncomment when the component is ready
    documentTypesToShow: Array<DocumentTypeItem> = [AppSettings.documentType];
    invalidFileNameCharactersList:any = [];
    blockedFileTypes:any = [];
    filesPendingToBeUploaded:any = [];
    additionalDocumentTypesAvailable = true;
    formId: number;
    modalType: string;
    folderUrl: string;
    folderName: string;
    attachmentToDeleteItem: AttachmentToDeleteItem = new AttachmentToDeleteItem();
    sharepointFileItem: SharepointFileItem = new SharepointFileItem();
    fileFullURL: string;
    showNewDealpopup:boolean = false;
    showDealWBSpopup:boolean = false;
    showCCLListpopup:boolean = true;

    constructor(private dropdownService: DropdownService,
      private tooltipHelperService: TooltipHelperService,
        private securityService: SecurityService,
        private fileService: FilesService,
               private spcrud: Spcrud,
               private complianceServiceHelper: ComplianceServiceHelper,
               private CCLService:ClosingCheckListService,
               private NewdealService:NewdealService){}
        
    ngOnInit(): void {
      this.invalidFileNameCharactersList = AppSettings.invalidFileNameCharactersList;
      this.blockedFileTypes = AppSettings.blockedFileTypes;

        this.financeFormGroup= this.formGroups[0];
        this.financeFuturePaymentsTypeOptions = AppSettings.FinanceFuturePaymentsArrayList;
        this.financeProviderNameTypeOptions = AppSettings.FinanceProviderNameTypeArrayList;
        // this.dropdownService.fillDropdownOptions(AppSettings.closingChecklistForm,FormListFields.financeFuturePaymentsType)
        // .then(response=>{
        //     this.financeFuturePaymentsTypeOptions=response;
        // });

        this.dropdownService.fillLookupOptions_CCF(AppSettings.closingCheckListCountries,'Title',environment.ClosingChecklistForm)
        .subscribe(response => {
          this.countryOptions =response;
        });
          //this.countryOptions =  this.dropdownService.countriesList;
       



        // this.dropdownService.fillDropdownOptions(AppSettings.closingChecklistForm,FormListFields.financeProviderNameType).
        // then(response=>{
        //     this.financeProviderNameTypeOptions  = response;
        // })

    }

    getControlName(formControlName: string) {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return formGroup.get(formControlName);
      }
      return null;
    }

  populateDocumentTypes(responseDocumentTypes: Array<DocumentTypeItem>, formId: number) {
    this.formId = formId;
    this._setFormUrl();
    this.documentTypesValues = responseDocumentTypes;

    this._filterDocumentTypesToShow();
    this.additionaldocumentTypesToAdd = this._filterAdditionalDocumentTypes();
    this.evaluateAdditionalDocumentTypes();
  }

  private _setFormUrl() {
    this.folderName = AppSettings.attachmentsFolderName + this.formId;
    this.folderUrl = this._createFolderUrl();
  }

  private _openModal(modalType: string) {
    this.modalType = modalType;
    this.cclPopupComponent.openPopup("ClosingChecklist",modalType );
  }

  private _createFolderUrl() {
    const libraryName = AppSettings.attachmentsList;
    return libraryName + '/' + this.folderName;
  }

  saveFileToLibrary(formId: number) {
    this.formId = formId;
    this._setFormUrl();
    const file = this.sharepointFileItem.fileItem;
    this.filesPendingToBeUploaded.push(file);
    const documentTypeItemId = this.sharepointFileItem.documentTypeItemId;
    const documentTypeId = this.sharepointFileItem.documentTypeId;
    const fileName = file.name;
    const docTitle = this.sharepointFileItem.documentTitle;
    const isUploading = true;
   
    //TODO:Need to check this var,looks wrong configuration in document component as well.
    if (fileName.length <= AppSettings.maxUrlLength) 
    {
      this._isFileUploaded(this.documentTypesValues, fileName, documentTypeItemId).then(replaceAction => {
        if (replaceAction.fileUploaded) 
        {
          if (replaceAction.overwriteAllowed) 
          {           
            this._openModal(modalTypes.fileOverwrite);
          } 
          else 
          {
            this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));         
            this._openModal(modalTypes.fileNameBlocked);                
          }
        } 
        else 
        {
          this.showAttachments(fileName, documentTypeId, isUploading);
          this.fileService.validateEncryptedFile(file).then((isValid) => {            
            if (isValid==true) 
            {
              var uploadRequestObj =this.prepareRequestObj('file',this.formId.toString(),fileName,docTitle);
              var attachmentRequestObj =this.prepareRequestObj('attachment',this.formId.toString(),fileName,docTitle);
              this.uploadFile(uploadRequestObj,attachmentRequestObj,file,documentTypeId);         
            }
            else if(isValid==false)
            {
              this.removeDocumentFromUI(fileName,documentTypeId);  
              this._openModal(modalTypes.fileEncrypted);
              this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));                            
            }
            else 
            {
              this.removeDocumentFromUI(fileName,documentTypeId); 
              this._openModal(modalTypes.fileEmpty);              
              this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));      
            }    
          });                    
        }
      });      
    } else {
      this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));  
      this._openModal(modalTypes.fileNameTooLong);    
    }
  }
  prepareRequestObj(uploadType:any,formId:any,fileName:any,docType:any):any
  {
    var fileUploadInput:any = {};
    
    fileUploadInput['FormID'] = this.formId.toString();
    fileUploadInput['FileName'] = fileName;    
    if(uploadType=='file')
    {               
      fileUploadInput['FormType'] = 'ClosingCheckList';
      fileUploadInput['CurrentAttachmentEnviroment'] = AppSettings.closingCheckListAttachmentsList;     
    }
    if(uploadType=='attachment')
    {      
      fileUploadInput['DocumentType'] = docType;      
      fileUploadInput['Title'] = fileName; 
      fileUploadInput['Name'] = fileName; 
      fileUploadInput['CreatedBy'] = localStorage.getItem("AuditorFullName")?.toString();  
      fileUploadInput['Created'] = "";
      fileUploadInput['ID'] = "";
    }
    return fileUploadInput;
  }

  showAttachments(fileName: string, documentTypeId: number, isUploading: boolean) {
    const url = this.fileService.generateFileUrl(fileName, this.folderUrl);
    const index = this._getDocumentTypeIndex(documentTypeId);
    this._setAttachmentItem(fileName, url, isUploading, index);
  }

  uploadFile(uploadRequestObj:any,attachmentRequestObj:any,file:any,documentTypeId:any) {
    return this.fileService.uploadFile(uploadRequestObj).subscribe({
      next: (fileMeta: any) => 
      {
        this.NewdealService.uploadFileWithPresignedURL(fileMeta,file).subscribe(
          {
            next:()=>
            {
              this.fileService.addAttachement(attachmentRequestObj,AppSettings.FormTypes.ClosingCheckListForm).subscribe(
                {
                  next: (response2: any) => 
                  {
                    this.showAttachments(uploadRequestObj.FileName, documentTypeId, false);
                    this._cleanFilesPendingToBeUploaded(this._getFileIndex(uploadRequestObj.FileName))
                  },error:(err2:any)=>{}
                }
              )
            },error:()=>{},
          }
        )      
      },
      error: (err: any) => {         
        console.log("Error" + err)
      }
    }
    ); 
  }

  openAdditionalDocumentTypesModal() {
    this._openModal(modalTypes.additionalDocumentTypes);
  }

  evaluateAdditionalDocumentTypes() {
    if (this.additionaldocumentTypesToAdd.length === 0) {
      this.additionalDocumentTypesAvailable = false;
    }
  }

  deleteAttachment(fileName: string, documentTypeId: number, attachmentIndex: number) {
    this.attachmentToDeleteItem.fileName = fileName;
    this.attachmentToDeleteItem.documentTypeId = documentTypeId;
    this.attachmentToDeleteItem.attachmentIndex = attachmentIndex;
    this._openModal(modalTypes.deleteAttachment);
  }

  ConfirmDetails(event:any) //Handle the popup click
  {
    if(event.isOkayClicked) // Okay is clicked on the popup
    {
        //based on the type propgate the function call
        switch(event.modalType)
        {
          case modalTypes.deleteAttachment:
            this.confirmDeleteAttachment();
             break;
          case modalTypes.fileOverwrite:
            this.confirmOverwriteFile();
            break;          
          default:
            break;
        }
    }
    else //cancel clicked
    {
      switch(event.modalType)
      {        
        case modalTypes.fileOverwrite:
          this.cancelOverWriteFile();
          break;          
        default:break;
      }
    }
  }

  confirmDeleteAttachment() {
    this.fileService.deleteFile(this.formId,this.attachmentToDeleteItem.fileName, AppSettings.FormTypes.ClosingCheckListForm).subscribe(() => { 
      const index = this._getDocumentTypeIndex(this.attachmentToDeleteItem.documentTypeId);
      this.documentTypesValues[index].documentTypeAttachments.splice(this.attachmentToDeleteItem.attachmentIndex, 1);
      this._filterDocumentTypesToShow();
    })
    catchError((error:any) => {
      throw error
    });
  }

  confirmOverwriteFile() {
    const file = this.sharepointFileItem.fileItem;
    const documentTypeId = this.sharepointFileItem.documentTypeId;
    const fileName = file.name;
    const isUploading = true;
    const docTitle = this.sharepointFileItem.documentTitle;
    this.fileService.deleteFile(this.formId,fileName,AppSettings.FormTypes.ClosingCheckListForm).subscribe(
      {
        next: (response: any) => {  
          this.showAttachments(fileName, documentTypeId, isUploading);  
          this.fileService.validateEncryptedFile(file).then((isValid) => {            
            if (isValid==true) 
            {
              var uploadRequestObj =this.prepareRequestObj('file',this.formId.toString(),fileName,docTitle);
              var attachmentRequestObj =this.prepareRequestObj('attachment',this.formId.toString(),fileName,docTitle);
              this.uploadFile(uploadRequestObj,attachmentRequestObj,file,documentTypeId);         
            }
            else if(isValid==false)
            {
              this.removeDocumentFromUI(fileName,documentTypeId);  
              this._openModal(modalTypes.fileEncrypted);           
              this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));                                     
            }
            else 
            {
              this.removeDocumentFromUI(fileName,documentTypeId);  
              this._openModal(modalTypes.fileEmpty);                
              this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));                            
            }            
          });          
        },
        error: (err: any) => {         
          console.log("Error" + err)
        }
      }
    );
  }
  removeDocumentFromUI(fileName:any,documentTypeId:any)
  {
    const index = this._getDocumentTypeIndex(documentTypeId);
    this.documentTypesValues[index].documentTypeAttachments.forEach((item:any, i:number) => {
      if(item.attachmentName === fileName) this.documentTypesValues[index].documentTypeAttachments.splice(i,1);
    });
    this._filterDocumentTypesToShow();
  }
  cancelOverWriteFile() {
    const file = this.sharepointFileItem.fileItem;
    const fileName = file.name;
    this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));
  }

  populateDocumentSection(attachmentsData: any) {
    //remove unmatched files from current attchements only when file available in current attachment and not present in respose attachement.
    let responseDocTypeItems = this.complianceServiceHelper.createAttachmentsObject(attachmentsData);
    responseDocTypeItems.forEach(doc => {
      const index = this._getDocumentTypeItemIndex(doc.documentTypeId);
      if(index !== -1){
        let currentFiles = this.documentTypesValues.find((d:any)=>d.documentTypeId == doc.documentTypeId).documentTypeAttachments;
        let responseFiles = doc.documentTypeAttachments;
        for(let i = 0 ; i < currentFiles.length ;i++ )
        {
          let responseFileIndex = responseFiles.findIndex((f:any) => f.attachmentName === currentFiles[i].attachmentName);
          if(responseFileIndex == -1)
          {
            this.documentTypesValues.find((d:any)=>d.documentTypeId == doc.documentTypeId).documentTypeAttachments.splice(i,1)
          }
        }
        
      }
    });

    attachmentsData.forEach((attachment:any) => {
      const url = this.fileService.generateFileUrl(attachment.Name, this.folderUrl);
      const index = this._getDocumentTypeItemIndex(attachment?.documentTypeId);
      if (index !== -1) {
        this._setAttachmentItem(attachment.Name, url, false, index);
      }
    });
  }

  getFolderUrl() {
    return this.folderUrl;
  }

  setFormUrl() {
    this.folderName = AppSettings.attachmentsFolderName + this.formId;
    this.folderUrl = this._createFolderUrl();
  }

  setFormId(formId: number) {
    this.formId = formId;
  }
  
  getFilesPendingToBeUploaded() {
    return this.filesPendingToBeUploaded.length !== 0;
  }

  getDocumentTypeAttachments() {
    const documentTypesAttachmentsToEmit = this.documentTypesToShow.map( item => {
      return {documentType: item.documentTypeTitle, hasAttachments: item.documentTypeAttachments.length > 0 };
    });
    this.uploadedAttachments.emit(documentTypesAttachmentsToEmit);
  }

  getAttachmentsRowsComplete(): Array<DocumentTypeItem> {
    const rowsCompleted: Array<DocumentTypeItem> = [];
    if (this.documentTypesToShow) {
      this.documentTypesToShow.forEach(row => {
        if (row && row.documentTypeAttachments.length > 0) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }
  
  private _isFileUploaded(files: any, fileName: string,  documentTypeItemId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let fileValidUploaded: boolean;
      let fileUploaded: any;
      const replaceAction = {
        fileUploaded: false,
        overwriteAllowed: false
      };
      if(files && files.length > 0)
      {
        const isFileUploaded =  files[0].documentTypeAttachments.forEach((file:any) => {
          if (fileName.toLowerCase() === file.attachmentName?.toLowerCase()) {
              fileUploaded = file;
              replaceAction.fileUploaded=true;
              replaceAction.overwriteAllowed = true;
          }
      });
      }
      resolve(replaceAction);
    });
  }

  private _setAttachmentItem(fileName: string, url: string, isUploading: boolean, index: number) {
    const attachmentIndex = this.documentTypesValues[index].documentTypeAttachments.map((item:any) => item.attachmentName).indexOf(fileName);

    if (attachmentIndex !== -1) {
      this.documentTypesValues[index].documentTypeAttachments[attachmentIndex].attachmentUploading = isUploading;
    } else {
      const attachmentItem = new AttachmentItem();
      attachmentItem.attachmentName = fileName;
      attachmentItem.attachmentUrl = url;
      attachmentItem.attachmentUploading = isUploading;
      this.documentTypesValues[index].documentTypeAttachments.push(attachmentItem);
    }
    this._filterDocumentTypesToShow();
  }

  private _getDocumentTypeIndex(documentTypeId: number): number {
    if(documentTypeId == undefined)
      documentTypeId=1;
    return this.documentTypesValues.map((item:any) => item.documentTypeId).indexOf(documentTypeId);
  }

  private _getDocumentTypeItemIndex(documentTypeItemId: number): number {
    if(documentTypeItemId == undefined)
      documentTypeItemId=1;
    return this.documentTypesValues.map((item:any) => item.documentTypeItemId).indexOf(documentTypeItemId);
  }

  private _getFileIndex(fileName: string): number {
    return this.filesPendingToBeUploaded.map((item:any) => item.name).indexOf(fileName);
  }

  private _cleanFilesPendingToBeUploaded(index: number) {
    this.filesPendingToBeUploaded.splice(index, 1);
  }

  private _filterAdditionalDocumentTypes() {
    return this.documentTypesValues.filter(
      (documentType:any) => !documentType.documentTypeRequired && !this._inArray(this.additionalDocumentTypesAdded, documentType));
  }

  private _filterDocumentTypesToShow() {
    this.documentTypesToShow = this.documentTypesValues.filter( (documentType:any) => {
      return documentType.documentTypeRequired;
    });
    let additionalsToAdd: Array<DocumentTypeItem> = [];
    this.additionalDocumentTypesAdded.forEach(documentType => {
      if (!this._inArray(this.documentTypesToShow, documentType)) {
        additionalsToAdd = additionalsToAdd.concat(this.documentTypesValues.filter((d:any) => d.documentTypeId === documentType.documentTypeId));
      }
    });

    this.documentTypesToShow = this.documentTypesToShow.concat(additionalsToAdd);
    this.getDocumentTypeAttachments();
  }

  private _inArray(documentTypes: Array<DocumentTypeItem>, document: DocumentTypeItem): boolean {
    return Boolean(documentTypes.find(d => d.documentTypeId === document.documentTypeId));
  }

  // Public Standart Functions
  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if(formControlName=="financeFileUpload"&&formGroup!=null){
      if(formGroup.get(formControlName)?.value==undefined){
      formGroup.get(formControlName)?.setErrors({required:true});
      }
    }
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }


  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }
   // Private Standart Functions

   private _setAsDisabled(formControlName: string) {
    const controlName = this.financeFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }


    onSelectedValue(event:any){
        this.financeFormGroup.controls.financeEnterpriseValue.setValue(event);
    }
    isFinanceDeloitteEntity(event:any){
        this.financeFormGroup.controls.financeDeloitteEntity.setValue(event);
        this.evaluateFinanceDeloitteEntity(event);
    }
    IsFinanceFutureExpenses(event:any){
        this.financeFormGroup.controls.financeFutureExpenses.setValue(event);
        this.evaluateFinanceFutureExpenses(event);
    }

    onSelectedAmountValue(event:any){
        this.financeFormGroup.controls.financeFutureExpensesAmount.setValue(event);
    }
    isFinanceFuturePayments(event:any){
        this.financeFormGroup.controls.financeFuturePayments.setValue(event);
        this.evaluateFinanceFuturePayments(event);
    }

    onFuturePaymentTypeSelection(event:any){
        this.financeFormGroup.controls.financeFuturePaymentsType.setValue(event.key);
    }

    isFinanceAssetSaleDeal(event:any){
        this.financeFormGroup.controls.financeAssetSaleDeal.setValue(event);
       // this.evaluateFinanceAssetDeal(event);
    }

    isCrossBorderEngagement(event:any){
        this.financeFormGroup.controls.financeCrossBorderEngagement.setValue(event);
        this.evaluateFinanceCrossBorder(event);
    }

    onCrossBorderCountrySelection(event:any){
        this.financeFormGroup.controls.financeCrossBorderCountry.setValue(event.key);
    }

    isFinanceDealLeagueTable(event:any){
        this.financeFormGroup.controls.financeDealLeagueTable.setValue(event);
    }

    isfinanceDealGlobalDeloitteSite(event:any){
        this.financeFormGroup.controls.financeDealGlobalDeloitteSite.setValue(event);
    }
    isFinanceDealAnnouncement(event:any){
        this.financeFormGroup.controls.financeDealAnnouncement.setValue(event);
    }
    isFinanceDealCaseStudy(event:any){
        this.financeFormGroup.controls.financeDealCaseStudy.setValue(event);
    }
    onFinanceProviderNameTypeSelection(event:any){
        this.financeFormGroup.controls.financeProviderNameType.setValue(event.key);
        this.providerNameType.emit(event.key);
    }

    evaluateFinanceDeloitteEntity(value:any) {
        if(value === "YES"){
            this.financeFormGroup.controls.financeDeloitteEntityDescription.enable();
        }
        else{
            this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.financeDeloitteEntityDescription));
        }
    }

    evaluateFinanceFutureExpenses(value:any){
        if(value === "YES"){
            this.financeFormGroup.controls.financeFutureExpensesAmount.enable();
        }
        else{
            this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.financeFutureExpensesAmount));
        }
    }

    evaluateFinanceFuturePayments(value:any){
        if(value === "YES"){
            this.financeFormGroup.controls.financeFuturePaymentsType.enable();
        }
        else{
            this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.financeFuturePaymentsType));
        }
    }

    evaluateDealType(value:any){
      if(value === AppSettings.strategicAdvisory){
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.financeProviderName));
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.financeProviderNameType));
    }
    else{
        this.financeFormGroup.controls.financeProviderName.enable();
        this.financeFormGroup.controls.financeProviderNameType.enable();
    }
    }
    
    evaluateFinanceCrossBorder(value:any){
        if(value === "YES"){
            this.financeFormGroup.controls.financeCrossBorderCountry.enable();
        }
        else{
            this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.financeCrossBorderCountry));
        }
    }
    getTooltip(tooltipTitle: string): TooltipObject {
      return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
    }
  
    getTooltipText(tooltipTitle:string):string{
      const tooltip = this.getTooltip(tooltipTitle);
      return tooltip ? tooltip.getTooltipText() : '';
    }
    downloadAttachment(fileName: any) 
    {   
        let attachementFolderName = '';
        let pageSource='ClosingCheckList';
        attachementFolderName=AppSettings.closingCheckListAttachmentsList;
        
        this.CCLService.downloadAttachments(this.formId,fileName,attachementFolderName,pageSource).subscribe
        ({
          next: (response: any) => {
            if(response!=null && response.FileUrl!=null)
              {
                  window.open(response.FileUrl, "_blank");
              }               
          },
          error: (err: any) => {         
            console.log("Error" + err)
          }
        }
        );          
    }

    onFileSelected(fileSelected: any, documentTypeItemId: number, documentTypeId: number,docTitle:string) {
      this.sharepointFileItem.fileItem = fileSelected;
      this.sharepointFileItem.documentTypeItemId = documentTypeItemId;
      this.sharepointFileItem.documentTypeId = documentTypeId;
      this.sharepointFileItem.documentTitle = docTitle;
      if (this._getFileIndex(fileSelected.name) !== -1) 
      {
        this._openModal(modalTypes.fileNameUploading);    
      } 
      else 
      {
        this.selectedFile.emit(true);
      }
    }
   
}


