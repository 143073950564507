<div id="billing-schedule"
  class="container-fluid border container-border-color container-section"
  [formGroup]="formGroup">
  <div class="row bg-title">
    <span>Billing Schedule (if any)</span>
  </div>
  <div class="content-section">
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('billingPlanType')"
              [class.edited-externally]="editedExternally('billingPlanType')"
              [class.conflict-on-merge]="hasMergeConflict('billingPlanType')">
            Billing Plan Type
        </span>
        <span *ngIf="showAsterisk('billingPlanType')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-dropdown [optionsList]="billingPlanTypeOptions"
                      [keySelected]="formGroup.get('billingPlanType')?.value"
                      [readonly]="isReadOnly('billingPlanType')"
                      (optionSelected)="onBillingPlanSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row" [style.display]="formGroup.controls.billingPlanType.value === appSettings.billingPlanTypeDefaultValue ? 'block' : 'none'">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('billingIntervals')"
              [class.edited-externally]="editedExternally('billingIntervals')"
              [class.conflict-on-merge]="hasMergeConflict('billingIntervals')"
              class="dependent-field">
            Billing Intervals
        </span>
        <span *ngIf="showAsterisk('billingIntervals')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-dropdown [optionsList]="billingIntervalsOptions"
                      [keySelected]="formGroup.get('billingIntervals')?.value"
                      [readonly]="isReadOnly('billingIntervals')"
                      [attr.disabled]="disabledFunc()"
                      (optionSelected)="onBillingIntervalsSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row" [style.display]="formGroup.controls.billingPlanType.value !== appSettings.billingPlanTypeDefaultValue ? 'block' : 'none'">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('billingIfMilestone')"
                [class.edited-externally]="editedExternally('billingIfMilestone')"
                [class.conflict-on-merge]="hasMergeConflict('billingIfMilestone')"
                class="dependent-field">
                  Billing Date if Milestone
          </span>
          <span *ngIf="showAsterisk('billingIfMilestone')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
          <app-date-picker [selectedDate]="formGroup.get('billingIfMilestone')?.value"
                           [readonly]="isReadOnly('billingIfMilestone')"
                           [attr.disabled]="disabledFunc()"
                           (dateSelected)="eventDateSelected($event,'billingIfMilestone')">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('billingFrom')"
            [class.edited-externally]="editedExternally('billingFrom')"
            [class.conflict-on-merge]="hasMergeConflict('billingFrom')">
              Billing From
            </span>
          <span *ngIf="showAsterisk('billingFrom')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
          <app-date-picker
            [selectedDate]="formGroup.get('billingFrom')?.value"
            [readonly]="isReadOnly('billingFrom')"
            (dateSelected)="eventDateSelected($event,'billingFrom')">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('billingTo')"
            [class.edited-externally]="editedExternally('billingTo')"
            [class.conflict-on-merge]="hasMergeConflict('billingTo')">
              Billing To
          </span>
          <span *ngIf="showAsterisk('billingTo')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
          <app-date-picker
            [selectedDate]="formGroup.get('billingTo')?.value"
            [readonly]="isReadOnly('billingTo')"
            (dateSelected)="eventDateSelected($event,'billingTo')">
          </app-date-picker>
        </div>
      </div>
    </div>
  </div>
</div>

