import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MarketOfferingMap {

  valuesToMap: Map<string, string>;

  constructor() {
  }

  setValuesToMap(values: Map<string, string>) {
    this.valuesToMap = values;
  }

  getMarketOfferingValueMapped(value: string): any {
    return this.valuesToMap.get(value);
  }
}
