<div class="col-xs-12">
  <app-peoplepicker-dynamic-table #DealTeam
    *ngIf="dealTeamRoleColumn.itemList && dealTeamPursuitRoleColumn.itemList"
    [header]="dealTeamNameHeader"
    [firstColumnItemList]="firstColumnVisible ? dealTeamRoleColumn : null"
    [secondColumnItemList]="secondColumnVisible ? dealTeamPursuitRoleColumn : null"
    [thirdColumnItemList]="thirdColumnVisible ? dealTeamSwiftRoleColumn : null"
    [valuesList]="dealTeamValues"
    [readonly]="readonly"
    [editable]="editable"
    [dealTeamTable]="true"
    (selectedRow)="onDealTeamRowSelection($event)"
    (validateSwiftRole)="validateSwiftRole($event)"
    (validateDealTeamCount)="validateDealTeamMemberCount($event)">
  </app-peoplepicker-dynamic-table>
</div>

<app-modal #Modal>
  <ng-container body>
    <ng-container *ngTemplateOutlet="modalBody">
    </ng-container>
  </ng-container>
  <ng-container footer>
    <ng-container *ngTemplateOutlet="modalFooter">
    </ng-container>
  </ng-container>
</app-modal>

<ng-template #modalBody>
  <div [ngSwitch]="modalType">
      <h3 *ngSwitchCase="'deal-team-row-added'" class="text-primary text-center text-bigger">
        By adding new Deal Team members, an email 
        <br>
        notification will be sent to them.
      </h3>
      <h3 *ngSwitchCase="'swift-role-valid'" class="text-primary text-center text-bigger">
        Each swift role can only be chosen once.
      </h3>
      <h3 *ngSwitchCase="'deal-team-row-delete-valid'" class="text-primary text-center text-bigger">
        Atleast one deal team member is required.
      </h3>
  </div>
</ng-template>
<ng-template #modalFooter>
  <div [ngSwitch]="modalType">
    <div *ngSwitchCase="'deal-team-row-added'">
      <button class="btn btn-secondary btn-sm bg-modal-button-cancel" (click)="cancelModal()">Cancel</button>
      <button class="btn btn-primary btn-ok" (click)="confirmModal()">OK</button>
    </div>
    <div *ngSwitchCase="'swift-role-valid'">
      <button class="btn btn-primary btn-ok" (click)="modal.close()">OK</button>
    </div>
    <div *ngSwitchCase="'deal-team-row-delete-valid'">
      <button class="btn btn-primary btn-ok" (click)="modal.close()">OK</button>
    </div>
  </div>
</ng-template>
<newdeal-app-notify-popup (deleteConfirmDetails)="deleteConfirmDetails($event)"></newdeal-app-notify-popup>