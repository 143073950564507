import { Injectable } from '@angular/core';
import { Spcrud } from './spcrud';
import { DropdownItem } from '../models/dropdown-item';
import { BaseService } from './base.service';
import { RootSiteSpcrud } from './root-site-spcrud';
import { Observable, catchError, from, map } from 'rxjs';
import { NewdealService } from 'src/app/services/newdeal.service';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { AppSettings } from '../app-settings';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropdownService extends BaseService {
  result: Array<DropdownItem> = [];
  userList: Array<DropdownItem> = [];
  countriesList:any;

  constructor(spcrud: Spcrud, rootSiteSpcrud: RootSiteSpcrud,private newdealService:NewdealService,private dealWbsService:DealwbsService,private closingCheckListService: ClosingCheckListService) {
    super(spcrud, rootSiteSpcrud);
  }
  fillDropdownOptions(listName: string, columnName: string): Promise<Array<DropdownItem>> {
    const spcrud = this.getSpcrud(listName);
    return spcrud.getColumnChoiceOptions(listName, columnName)
      .then((response:any) => {
        const results = response.d.results[0].Choices.results;
        this.result = results.map((val:any) => {
          const dropdownItem = new DropdownItem();
          dropdownItem.key = val;
          dropdownItem.value = val;
          return dropdownItem;
        });
       return this.result;
      }).catch((error:any) => {
        return [];
      });
  }

  fillDropdownOptions_ClosingCheckList(listName: string, columnName: string): Observable<Array<DropdownItem>> {
    const spcrud = this.getSpcrud(listName);
    return spcrud.getColumnChoiceOptions(listName, columnName).pipe(
      map((response: any) => {
        const results = response.d.results[0].Choices.results;
        this.result = results.map((val: any) => {
          const dropdownItem = new DropdownItem();
          dropdownItem.key = val;
          dropdownItem.value = val;
          return dropdownItem;
        }); 
        return this.result;
      }),
      catchError((err: any) => {
        console.log('Error fetching dropdown data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }

  fillDropdownOptions_DealWBS(listName: string, columnName: string): Observable<Array<DropdownItem>> {
     let appendURL=this.dealWbsService.getAppendURL(listName);
      return from(this.dealWbsService.GetDropDownData(appendURL,listName)).pipe(
        map((response: any) => {
          const allowed = [columnName];
          let results = response.map( (item:any) => {   
           Object.keys(item)
         .filter(key => !allowed.includes(key))
         .forEach(key => delete item[key]);
        });
          results= results.filter((x:any) => (x !== null&&x!=="")); //restricting null values
          this.result = results.map((val:any) => {
            const dropdownItem = new DropdownItem();
            dropdownItem.key = val;
            dropdownItem.value = val;
            return dropdownItem;
          });
          return this.result; 
      }),
      catchError((err: any) => {
        console.log('Error fetching dropdown data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }

  fillLookupOptions(listName: string, columnName: string): Promise<Array<DropdownItem>> {
    const spcrud = this.getSpcrud(listName);
    const column = columnName;
    const options = {select: column + ', Id'};    
    return spcrud.read(listName, options)
    .then((response:any) => {
        const results = response.d.results;
        this.result = results.map((item:any) => {
           const dropdownItem = new DropdownItem();
           dropdownItem.key = item.Id;
           dropdownItem.value = item[column];
           return dropdownItem;
         });
        return this.result;
    }).catch((error:any) => {
      return [];
    });
    return new Promise((resolve, reject) => {    
    });
  
  }
  fillLookupOptionsCountries(listName: string, columnName: string): Observable<Array<DropdownItem>> {
    const appendURL = this.closingCheckListService.getAppendURL(listName);
    return from(this.closingCheckListService.GetDropDownData(appendURL, listName,environment.ClosingChecklistForm))
      .pipe(
        map((results: any[]) => results.map(item => {
          const dropdownItem = new DropdownItem();
          //dropdownItem.key = item.ID;
          dropdownItem.key = item.Title;
          dropdownItem.value = item.Title;
          return dropdownItem;
        })),
        catchError((err: any) => {
          console.error('Error fetching data:', err);
          throw err; 
        })
      );
  }

  fillLookupOptions_DealWBS(listName: string, columnName: string): Observable<Array<DropdownItem>> {
  
    let appendURL=this.newdealService.getAppendURL(listName);
    return from(this.newdealService.GetDropDownData(appendURL,listName)).pipe(
      map((response: any) => {
        const dropdownItems = response.map((item: any) => {
          const dropdownItem = new DropdownItem();
          dropdownItem.key = item.ID;
          dropdownItem.value = item[columnName]; // Assuming columnName is a valid property in the item
          return dropdownItem;
        }); 
        return dropdownItems;
    }),
    catchError((err: any) => {
      console.log('Error fetching dropdown data:', err);
      throw err; // Re-throw the error to propagate it through the Observable stream
    })
  );
  }

  fillLookupOptionsNew(listName: string, columnName: string): Promise<Array<DropdownItem>> {
  return new Promise((resolve, reject) => {
    const appendURL = this.newdealService.getAppendURL(listName);

    // Use the promise-based GetDropDownData method
    this.newdealService.GetDropDownData(appendURL)
      .then((results: any) => {
        const dropdownItems = results.map((item: any) => {
          const dropdownItem = new DropdownItem();
          dropdownItem.key = item.ID;
          dropdownItem.value = item[columnName]; // Assuming columnName is a valid property in the item
          return dropdownItem;
        });
        resolve(dropdownItems);
      })
      .catch((err: any) => {
        console.log('Error fetching dropdown data:', err);
        reject(err);
      });
  });
}


fillLookupOptions_CCF(listName: string, columnName: string,lambdaUrl:any): Observable<Array<DropdownItem>> {
  const appendURL = this.closingCheckListService.getAppendURL(listName);
  return from(this.closingCheckListService.GetDropDownData(appendURL, listName,lambdaUrl))
    .pipe(
      map((results: any[]) => results.map(item => {
        const dropdownItem = new DropdownItem();
        //dropdownItem.key = item.ID; 
        dropdownItem.key = item[columnName];
        dropdownItem.value = item[columnName]; 
        return dropdownItem;
      })),
      catchError((err: any) => {
        console.error('Error fetching data:', err);
        throw err;
      })
    );
}

  fillDropdownFromSharepointGroup(groupName: string): Promise<Array<DropdownItem>> {
    const spcrud = this.getSpcrud();
    return spcrud.getGroupUsers(groupName)
    .then((response:any) => {
      const results = response.d.results;
      for (const  user of results) {
        const dropdownItem = new DropdownItem();
        dropdownItem.key = user.Id;
        dropdownItem.value = user.Title;
        this.userList.push(dropdownItem);
      }

      return this.userList;
    }).catch((error:any) => {
      return [];
    });
  }
}
