export class DealTeamObject {
  // NameId: number;
  // RoleId: number;
  // PursuitRoleId: number;
  // SwiftRoleId: number;
  // FormId: number;
  Name: string;
  Role: string;
  PursuitRole: string;
  SwiftRole: string;
  FormId: string;
  ID: any
  Email: string;
}
