import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-yes-no',
  templateUrl: './toggle-yes-no.component.html',
  styleUrls: ['./toggle-yes-no.component.scss']
})
export class ToggleYesNoComponent implements OnInit {

  @Input()
  value: any = null;
  

  @Input() isCompressWidth?:boolean= false; //Added for closing checklist form
  
  @Input()
  notApplicableEnabled: any = false; //Changed boolean to any for fix closing checklist form books-and-records

  @Input()
  readonly: boolean;

  @Input()
  disabled: any;
  @Input()
  disabledYes = false;

  @Input()
  disabledNo = false;

  @Output()
  handleToggleYesNo: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {    
  }

  toggleButtonActive(event: any, emitEvent: boolean = true) {
    if (!this.readonly && !this.disabled) {
      this.value = event;
      if (emitEvent) {
        this.handleToggleYesNo.emit(this.value);
      }
    }
  }
}
