import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-active-new-deal',
  templateUrl: './active-new-deal.component.html',
  styleUrls: ['./active-new-deal.component.scss']
})
export class ActiveNewDealComponent implements OnInit {

  @Output()
  adminActiveSwiftCodeAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  @Input() isNotificationSent: boolean;


  ngOnInit() {
  }
  adminActiveSwiftCode() {
    this.adminActiveSwiftCodeAction.emit(true);
    //this.isNotificationSent =true;
  }

}
