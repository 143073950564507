import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { environment } from 'src/environments/environment';
import { AppSettings } from 'src/shared/app-settings';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ClosingCheckListService {

  constructor(private myhttp:HttpClient) { }
  getAppendURL(dropdownName: string) {
    let url: any = "";
    switch (dropdownName) {
      case "MyDealRoles":
        return url = "api/MyDealRoles";
        break;
      case "MyDealPursuitRoles":
        return url = "api/MyDealPursuitRoles";
        break;
      case "MyDealSwiftRoles":
        return url = "api/MyDealSwiftRoles";
        break;
      case "MyDealDeloitteUSEntity":
        return url = "api/MyDealDeloitteUSEntity";
        break;
      case "MyDealSalesOffice":
        return url = "api/MyDealSalesOffice";
        break;
      case "DocumentManager":
        return url = "api/DocumentManager";
        break;
      case "MyDeal":
        return url = "api/MyDeal";
        break;
      case "ClosingCheckListForm":
        return url = "api/ClosingCheckListForm";
        break;
      case "MyDealDealTeam":
        return url = "api/MyDealDealTeam";
        break;
      case "DealTeamAuditLog":
        return url = "api/DealTeamAuditLog";
        break;
      case "EngagementHealthObject":
        return url = "api/EngagementHealthObject";
        break;
      case "CrossBorderForm":
        return url = "api/CrossBorderForm";
        break;
      case "DealTeamAuditLog":
        return url = "api/DealTeamAuditLog";
        break;
      case "ClosingCheckListValuations":
          return url = "api/ClosingCheckListValuations";
          break;
      case "ClosingCheckListDealMarketingCount":
          return url = "api/ClosingCheckListDealMarketingCount";
          break;
      case "Compliance":
          return url = "api/Compliance";
          break;
      case "CompliancePreviousSeries24":
          return url = "api/CompliancePreviousSeries24";
          break;
      case "CompliancePreviousPPMD":
            return url = "api/CompliancePreviousPPMD";
            break;
      case "ComplianceDTTLInvolved":
            return url = "api/ComplianceDTTLInvolved";
            break;
      case "ComplianceCounterParties":
            return url = "api/ComplianceCounterParties";
            break;
      case "ClosingChecklistDealTeamSignOff":
            return url = "api/ClosingChecklistDealTeamSignOff";
            break;
      case "ClosingCheckListFeeStructureBreakpoints":
            return url = "api/ClosingCheckListFeeStructureBreakpoints";
            break;
      case "ClosingChecklistAdditionalComments":
            return url = "api/ClosingChecklistAdditionalComments";
            break;
      case "ClosingCheckListLocations":
            return url = "api/ClosingCheckListLocations";
            break;
      case "ClosingCheckListCountries":
        return url = "api/ClosingCheckListCountries";
        break;
      case "ClosingCheckListIndustries":
        return url = "api/ClosingCheckListIndustries";
        break;  
      case "ClosingCheckListIndustries":
         return url = "api/ClosingCheckListIndustries";
         break;  
      default:
        return "";
        break;
    }
  }
  
  GetClosingCheckListFormDetails(formID:any="",listName:any=""):Observable<any>{
    var url="";
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    if(formID==""){
      url =  envURL+'/api/ClosingCheckListForm/GetList'; 
    }
    else{
      url =  envURL+'/api/ClosingCheckListForm/GetList?formID='+formID; 
    }
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }

  GetDropDownData(appendedURL: any,listName:any,lambdaUrl:any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,lambdaUrl)
    const url = envURL + '/' + appendedURL + '/GetList';   
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }

  // getAsteriskList() : Observable<any> {
  //   var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
  //   const url = envURL +'/api/ClosingChecklistValidationFields/GetList';
  //   return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  //   'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  // }
  
  SaveFormDetails(appendedURL:any,data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL+'/'+appendedURL+'/Add'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
  }
  getClosingCheckListTooltipsList() : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL +'/api/ClosingCheckListTooltips/GetList';
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  getDynamoTableList(formID:any,listName:any,lambdaUrl:any) : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,lambdaUrl)
    var appendedURL=this.getAppendURL(listName);
    let url = '';
    if(formID!=undefined && formID!="")
    {
      url = envURL +"/"+appendedURL+"/"+'GetList?formID='+formID;
    }
    else
    {
      url = envURL +"/"+appendedURL+"/"+'GetList';
    }
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }

  UpdateFormDetails_CCForm(appendedURL:any,data:any, listName:any):Observable<any>{
    var envURL;
    if(listName=="MyDeal" || listName=="MyDealDealTeam"){
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    else if(listName=="ClosingCheckListForm"){
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    }
    const url = envURL+'/'+appendedURL+'/Update'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
  }

  GetDocumentComments_CCL(listName: any, formId: any) : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL +'/api/ClosingChecklistAdditionalComments/GetList?formId='+formId;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  GetSignOffByStatus(status: any) : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL +'/api/ClosingChecklistDealTeamSignOff/GetList?status='+status;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  SaveCommentsDetails(appendedURL:any,data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL+'/'+appendedURL+'/Add'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
  }
  downloadAttachments(formId: any,fileName: any,attachEnviroment: any,pageSource:any):Observable<any>
  {
    const appendedURL = this.getAppendURL(AppSettings.DocumentManager);
    var data = JSON.stringify({FormID: formId.toString(), FileName: fileName,AttachEnviroment:attachEnviroment,PageSource:pageSource})
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DocumentManager)
    const url = envURL + '/' + appendedURL + '/DownloadFile';   
     return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
         'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  GetBreakPointValuesCCL(listName: any, formId: any) : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL +'/api/ClosingCheckListFeeStructureBreakpoints/GetList?formId='+formId;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  
  GetClosingChecklistDealTeamSignOff(listName: any,formId:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL +'/api/ClosingChecklistDealTeamSignOff/GetList?formID='+formId;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  UpdateDealTeamDetails(appendedURL:any,itemID:any,data:any):Promise<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL+'/'+appendedURL+'/Update'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })}).toPromise();
  }
  
  UpdateFormDetails_CCL(appendedURL:any,data:any, listName:any):Observable<any>{
    var envURL;
    if(listName=="MyDeal" || listName=="MyDealDealTeam"){
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    else {
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    }
    const url = envURL+'/'+appendedURL+'/Update'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
  }
  DeleteListItem(appendedURL:any,data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL+ '/' + appendedURL + '/Delete';          
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})
  })
  }
  SaveFormDetailsDDT_CCL(appendedURL:any,data:any,listName:any):Observable<any>{
    var envURL="";
    if(listName=="MyDeal" || listName=="MyDealDealTeam"||listName=="DealTeamAuditLog"){
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    else{
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    }
   
    const url = envURL+'/'+appendedURL+'/Add'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
  }
  GetDealTeamUserByEmail_CCL(formId:any,name:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL +'/api/MyDealDealTeam/GetList?formId='+formId+'&Email='+name;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})});
    }
    GetDealTeamUserByEmail_Compliance(formId:any,name:any,appendedURL:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
      const url = envURL +"/"+appendedURL+'/GetList?formID='+formId+'&name='+name;
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})});
      }
    GetUserRoleGroups(userId:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
      const url = envURL +'/api/UserGroups/GetUserGroupsByEmail?email='+userId;
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    }

    getDealTeamAuditLogData(formName: any, formId: any): Observable<any> {
      const appendedURL = this.getAppendURL(formName);
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType, environment.Newdealform)
      const url = envURL + '/' + appendedURL + '/GetList?formId=' + formId;
      return this.myhttp.get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("idToken")
        })
      });
    }

    DeleteListItemCompliance(appendedURL:any,formId:any,itemid:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
      const url = envURL+ '/' + appendedURL + '/Delete?ID='+itemid+'&partitionKey='+formId;          
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    
    }
    UpdateListItemCompliance(appendedURL:any,data:any ,listname :any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
      const url = envURL+ '/' + appendedURL + '/Update';          
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    })
   
   }
   BreakpointUpdate(appendedURL:any,data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    const url = envURL+'/'+appendedURL+'/Update'; 
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
  }
  }
 


