<div class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog " role="document">
        <div class="modal-content custom-content z-index-max" style="background-color: white;" [ngStyle]="getModalStyles()" >

            <div class="form-group" [style]="showNewDealAdditionalDocumentTypes?{'padding':'15px'}:{}">
                <div class="row">
                    <div class="col-md-5 header">
                        <div  class="modal-title-header"><label>{{headerMsg}}</label></div>
                    </div>

                </div>
                <div *ngIf="showNewDealAdditionalDocumentTypes">
                    <div *ngIf="additionalDocumentTypesAvailable">
                        <span class="modal-span">Select a Document Type:</span>
                        <div *ngFor="let additionalDocumentType of additionaldocumentTypesToAdd; let i = index" class="display-inline-flex" style="margin: 0.5em">
                            <button type="button"  class="btn btn-download-template" (click)="addAdditionalDocumentType(additionalDocumentType.documentTypeItemId, i)">
                                {{additionalDocumentType.documentTypeTitle}}
                            </button>
                        </div>
                    </div>
                    <span class='modal-span' *ngIf="!additionalDocumentTypesAvailable">There are no more available Document Types.</span>
                </div>
                <div class="row">
                    <div *ngIf="!showNewDealAdditionalDocumentTypes">
                        <div *ngIf="!showMissingRolesMsg&&!showReturnForReworkMsg&&!showDiscontinueMsg" style="margin: 0% 4%;text-align: center;font-size: 15px;"><label [innerHTML]="Msg"></label></div>
                        <div style="margin: 0% 4%;font-size: 15px; text-align: center; color:#0072C6 ;" *ngIf="showMissingRolesMsg">
                            Mandatory roles are missing in deal team:
                            <ul class="ul-margin">
                                <li>Engagement Partner</li>
                                <li>Engagement Manager</li>
                                <li>Billing Partner</li>
                            </ul>
                        
                        </div>
                        <div style="margin: 0% 4% ;font-size: 15px;" *ngIf="showReturnForReworkMsg">
                            <h3 class="text-primary text-center text-bigger" style="font-weight: 400;">
                                Are you sure you want to return the form for rework?
                            </h3>
                            <span>Reason:</span>
                            <textarea type="text" [(ngModel)]="returnForReworkReason" name="ReturnForReworkReason" rows="3" class="input-data-text text-area-style" required></textarea>     
                        </div>
                        <div style="margin: 0% 4% ;font-size: 15px;" *ngIf="showDiscontinueMsg">
                            <h3 class="text-primary text-center text-bigger " style="font-weight: 400;">
                                Are you sure you want to discontinue the form?
                            </h3>
                            <span>Reason:</span>
                            <textarea type="text" [(ngModel)]="discontinueReason" name="DiscontinueReason" rows="3" class="input-data-text text-area-style" required></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row" [ngStyle]="{'margin-left':showReturnForReworkMsg||showDiscontinueMsg?'20%':'-7%'}">
                            <div *ngIf="isUserDelete" class="col-md-2 column no-Style" >
                                <button type="button" class="btn custom-newdeal-ok mr" (click)="ConfirmToDelete()">
                                   {{UserDeleteOkText}}
                                </button>
                            </div>                            
                            <div *ngIf="isUserDelete" class="col-md-2 column yes-Style" >
                                <button type="button" class="btn custom-newdeal-cancel mr" (click)="ConfirmToCancel()">
                                    {{UserDeleteCancelText}}
                                </button>
                            </div>
                            <div *ngIf="!isUserDelete&&!showNewDealAdditionalDocumentTypes" class="col-md-2 column ok-Style" [style]="bigMsgStyle? {'margin-top': '15px'}:{'margin-top':'30px'}">
                                <button type="button" class="btn custom-add mr" (click)="closePopup()">
                                    {{cancelText}} 
                                </button>
                            </div>
                            <div *ngIf="showNewDealAdditionalDocumentTypes">
                                <button class="btn btn-secondary btn-sm bg-modal-button-cancel" (click)="closePopup()" style="float: right;margin-right: 6%;margin-top: 10%;">Cancel</button>
                              </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="modal-backdrop in"></div>
