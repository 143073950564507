<div *ngIf="btnType=='Add'" class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog " role="document">
        <div class="modal-content custom-content ">
            <div class="">

                <button type="button" class="close close-size" (click)="closePopup()" data-dismiss="modal"
                    aria-label="Close"><span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="form-group">

                <div class="row margins-top">
                    <div style="margin-left: 2%;"><label>Name</label></div>
                    <div class="col-md-8 column">
                        <app-peoplepicker-search class="borderradius padding-0"
                            [placeholder]="'Enter names or email address...'"
                            (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event)">
                        </app-peoplepicker-search>
                    </div>
                    <div class="col-md-2 column">
                        <button type="button" class="btn custom-add mr" (click)="addUser()">
                            Add user
                        </button>
                    </div>
                    <div class="col-md-2 column">
                        <button type="button" class="btn custom-cancel mr" (click)="closePopup()">
                            Cancel
                        </button>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>


<!-- Check Permissions  -->
<div *ngIf="btnType!='Add'" class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog " role="document">
            <div [ngClass]="isUserChecked?'modal-content custom-new': 'modal-content custom-content-cp'">
            <div class="">
                
                <button type="button" class="close close-size" (click)="closePopup()" data-dismiss="modal"
                    aria-label="Close"><span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="header">
                <div class="headerManagement">{{formTypeValue}}: Check Permissions</div> 
              </div>
            <div class="form-group">
                <div class="row margins-top">
                    <div style="margin-left: 2%;"><label>User:</label></div>
                    <div class="col-md-8 column">
                        <app-peoplepicker-search class="borderradius padding-0"
                            [placeholder]="'Enter names or email address...'"
                            (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event)">
                        </app-peoplepicker-search>
                    </div>
                    <div class="col-md-2 column">
                        <button type="button" class="btn custom-add mr" (click)="checkPermissions()">
                            Check Now
                        </button>
                    </div>
                    <div class="col-md-2 column">
                        <button type="button" class="btn custom-cancel mr" (click)="closePopup()">
                            Cancel
                        </button>
                    </div>
                </div> 
                  
            </div>
<div *ngIf="isUserChecked">
    <p class="per-info">Below are the Permission levels given to {{fullName}}:</p>
<div class="role-style"  *ngFor="let per of permissions">
<p>* {{per.Role}}</p>

</div>
<span *ngIf="permissions && permissions.length === 0" style="margin-left:12%;" class="no-matches">* No Permissions Provided.</span>
</div>


        </div>
    </div>
</div>

<app-notify-popup ></app-notify-popup>