import { Component, OnInit } from '@angular/core';
import { EngagementHealthObjectItem } from '../models/engagement-health-object-item';

@Component({
  selector: 'app-third-party-codes',
  templateUrl: './third-party-codes.component.html',
  styleUrls: ['./third-party-codes.component.scss']
})
export class ThirdPartyCodesComponent implements OnInit {
  engagementObject: EngagementHealthObjectItem;
  constructor() { }
  setThirdPartyCodes(engagementObject: EngagementHealthObjectItem){
    this.engagementObject = engagementObject;
  }
  ngOnInit() {
  }

}
