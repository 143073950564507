import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { AppSettings } from 'src/shared/app-settings';
import { FormListFields } from '../models/form-list-fields'; 
import { FormGroup} from '@angular/forms';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { FormValidations } from 'src/shared/utils/form-validations';
import { SecurityService } from 'src/shared/services/security.service';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { DealTeamTableComponent } from '../deal-team-table/deal-team-table.component';
import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';
import { CurrentUser } from 'src/shared/models/current-user-data';
import { NewdealService } from 'src/app/services/newdeal.service';
import { AdminService } from 'src/app/services/admin.service';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls:['./project-overview.component.scss']
})

export class ProjectOverviewComponent implements OnInit {
  //engagementIndustrySectorOptions: Array<DropdownItem> = [];
  // sourceOptions: Array<DropdownItem> = [];
  // transactionTypeOptions: Array<DropdownItem> = [];
  s24Options: Array<DropdownItem> = [];
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];
  series24LeftDCF: boolean;
  sharePointMapper = new NewDealSetupSharepointMapper();
  projectOverviewFormGroup: FormGroup;
  myDealFormGroup: any;
  updateDealTeamTable = true;
  allowToDuplicateUser = true;
  @Input() formGroups: Array<FormGroup>;
  @Input() formSubmitted: boolean;
  @Input() s24GroupUsers: any;

  @Output() dealTeamRowToStore = new EventEmitter<PeoplePickerSelectedRow>();
  @Output() sicCodeChange = new EventEmitter<string>();

  @ViewChild('dealTeam')
  dealTeamComponent: DealTeamTableComponent;

  constructor(
    private dropdownService: DropdownService,
    private securityService: SecurityService,
    private tooltipHelperService: TooltipHelperService,
    private newdealservice: NewdealService,
    private adminService:AdminService,
    private commonHelperService:CommonHelperService,
    private spinnerService:SpinnerService

  ) { }

  ngOnInit() {
    this.projectOverviewFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
    this.GetS24Users();
    // this.dropdownService.fillDropdownOptions(AppSettings.formListName, FormListFields.engagementIndustrySector)
    //   .then(response => {
    //     this.engagementIndustrySectorOptions = response;
    //   });

    // this.dropdownService.fillDropdownOptions(AppSettings.myDealFormList, FormListFields.source)
    //   .then(response => {
    //     this.sourceOptions = response;
    //   });

    // this.dropdownService.fillDropdownOptions(AppSettings.myDealFormList, FormListFields.transactionType)
    //   .then(response => {
    //     this.transactionTypeOptions = response;
    //   });    
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.s24GroupUsers) {
      this.GetS24Users();
    }
  }


  GetS24Users() {
    if(this.s24GroupUsers!= undefined && this.s24GroupUsers.length >0)
    {
      const s24dropdownItems = this.s24GroupUsers.map((item: any) => {
      const dropdownItem = new DropdownItem();
      dropdownItem.key = item.Email;
      dropdownItem.value = item.LastName+', '+item.FirstName; 
      return dropdownItem;
      }).sort((a:any,b:any)=>a.value > b.value?1:-1);
      this.s24Options = s24dropdownItems;
    }
    // this.commonHelperService.getAllUsers_MyDealS24().subscribe(
    //   {
    //     next: (result: any) => {
    //       const s24dropdownItems = result.map((item: any) => {
    //         const dropdownItem = new DropdownItem();
    //         dropdownItem.key = item.Email;
    //         dropdownItem.value = item.LastName+', '+item.FirstName; 
    //         return dropdownItem;
    //       }).sort((a:any,b:any)=>a.value > b.value?1:-1);
    //       this.s24Options = s24dropdownItems;  
    //     },
    //     error: (err: any) => {
    //       if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
    //       this.spinnerService.hideAutoSaveSpinner();
    //       console.log("Error" + err);
    //     }
    //   })
  }
  setSharepointMapper(mapper: NewDealSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  onDealTeamRowSelection(rowToStore: PeoplePickerSelectedRow) {
    this.dealTeamRowToStore.emit(rowToStore);
  }

  setDealTeamValues(dealTeamValues:any) {
    this.dealTeamValues = dealTeamValues;
  }
 
  // onIsIndiaInvolvedSelection(event) {
  //   this.projectOverviewFormGroup.controls.isIndiaInvolved.setValue(event);
  // }

  // onIsAlumniInvolvedSelection(event) {
  //   this.projectOverviewFormGroup.controls.isDeloitteAlumniInvolved.setValue(event);
  //   this.evaluateIsDeloitteAlumniDependency(event);
  // }

  onIsOpportunityViaGlobalSharepointSelection(event:any){
    this.projectOverviewFormGroup.controls.isOpportunityViaGlobalSharepoint.setValue(event);
    this.evaluateIsOpportunityViaGlobalSharepoint(event);
  }

  // onReferralPercentageChange(event) {
  //   this.projectOverviewFormGroup.controls.referralPercentage.setValue(event);
  // }

  // this was not being used as consulting SICCode has removed as part of UAT feebackchanges
  // onSICCodeChange(event) {
  //   this.sicCodeChange.emit(event.target.value);
  // }

  onEngagementIndustrySICCodeChange(event:any) {
    this.myDealFormGroup.controls.engagementIndustrySICCode.setValue(event);
  }

  // onTransactionTypeSelection(event) {
  //   this.myDealFormGroup.controls.transactionType.setValue(event.value);
  // }

  onSeries24Selection(event:any) {
    this.series24LeftDCF = false;
    this.myDealFormGroup.controls.series24.setValue(event.key);
    localStorage.setItem("series24EmailValue",event.value.toString());
    //add series24 into DealTeam
    this.dealTeamComponent.addSeries24Row(event)
  }

  addCurrentUserInDealTeam(currentUser:CurrentUser){
    let userObj: any = { key: currentUser.email, value: localStorage.getItem("NewDealAuditorName")?.toString() };
    this.dealTeamComponent.addUserDealTeamRow(userObj);
  }

  onEngagementIndustrySectorSelection(event:any) {
    this.projectOverviewFormGroup.controls.engagementIndustrySector.setValue(event.value);
  }

  // onSourceSelection(event) {
  //   this.myDealFormGroup.controls.source.setValue(event.value);
  // }

  // evaluateIsDeloitteAlumniDependency(value) {
  //   if (value === 'YES') {
  //     this.projectOverviewFormGroup.controls.deloitteAlumniName.enable();
  //     this.projectOverviewFormGroup.controls.deloitteAlumniRole.enable();
  //   } else {
  //     this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.deloitteAlumniName));
  //     this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.deloitteAlumniRole));
  //   }
  // }

  evaluateIsOpportunityViaGlobalSharepoint(value:any) {
    if (value === 'YES') {
      this.projectOverviewFormGroup.controls.internationalCommunity.enable();
      if((this.projectOverviewFormGroup.controls.isOpportunityViaGlobalSharepoint as CustomFormControl).isReadOnly()){
        (this.projectOverviewFormGroup.controls.internationalCommunity as CustomFormControl).setReadOnly();
      }
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.internationalCommunity));
    }
  }

  getDealTeamRowsComplete(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.dealTeamValues) {
      this.dealTeamValues.forEach(row => {
        if (row && row.isComplete()) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }

  setUpdateDealTeam(updateDealTeamTable:any) {
    this.updateDealTeamTable = updateDealTeamTable;
  }

  getUpdateDealTeam(): boolean {
    return this.updateDealTeamTable;
  }

  setItemPeoplePicker(itemId: number, index: number) {
    this.dealTeamComponent.setItemId(itemId, index);
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if(formControlName=="series24"&&formGroup!=null&&formGroup.get(formControlName)?.value==null){
      formGroup.get(formControlName)?.setErrors({required:true});
    }
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  setSeries24WarningMessage(value:any) {
    this.series24LeftDCF = value;
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldName(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _setAsDisabled(formControlName: string) {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      const controlName = formGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
