<div class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog " role="document">
        <div class="modal-content custom-content  z-index-max" style="background-color: white;width:35%;height: 160px;">

            <div class="form-group">
                <div class="row">
                    <div class="col-md-5">
                        <div style="margin-left: 5%;font-size: 106%;"><label></label></div>

                    </div>

                </div>
                <div class="row">
                    <div>
                        <div style="margin: 0% 4%; text-align: center; font-size: 15px;"><label [ngStyle]="{'margin': hideOKButton ? '5% 5% 5% 5%' : '0% 4%', 'text-align': 'center', 'font-size': '15px'}">{{Msg}}</label></div>

                    </div>
                    <div class="row">

                        <div class="row">
                            <div *ngIf="isUserDelete && !isHardDelete" class="col-md-2 column no-Style">
                                <button type="button" class="btn custom-cancel mr" (click)="ConfirmToCancel()">
                                    No
                                </button>
                            </div>
                            <div *ngIf="isUserDelete && isHardDelete" class="col-md-2 column no-HardDeleteMsgStyle">
                                <button type="button" class="btn custom-cancel mr" (click)="ConfirmToCancel()">
                                    No
                                </button>
                            </div>
                            <div *ngIf="isUserDelete" class="col-md-2 column yes-Style">
                                <button type="button" class="btn custom-add mr" (click)="ConfirmToDelete()">
                                    Yes
                                </button>
                            </div>
                            <div *ngIf="!isUserDelete&&!hideOKButton" class="col-md-2 column ok-Style" style="margin-top: 20px;margin-left: 65%;">
                                <button type="button" class="btn custom-add mr" (click)="closePopup()">
                                    OK  
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div> <div class="modal-backdrop in"></div>
</div>   


