<div class="col-xs-11 align-center">
  <button
  class="btn btn-sm open-selector-file"
  [ngClass]="{'document-type-button-color-green':filesAssociated,'btn-primary':!filesAssociated,'no-cursor-pointer':disabled}"
    (click)="toggleFileExplorer()">
    {{ buttonTitle }}
   
  </button>
  </div>
  <div class="col-xs-1 align-center custompopover">
  <app-popover  *ngIf="buttonTitle =='DESC Conflict Checks'" [tooltipText]="getTooltipText('ConflictCheck')"
  [keepOpen]="true"> [
  </app-popover>
  </div>
  <span #inputFilePlaceHolder>
  
  </span>
  
  
<newdeal-app-notify-popup 
[maxAllowedFileNameLength]="maxAllowedFileNameLength"
[invalidFileNameCharactersList]="invalidFileNameCharactersList">
</newdeal-app-notify-popup>

<deal-wbs-setup-app-notify-popup  
[maxAllowedFileNameLength]="maxAllowedFileNameLength"
[invalidFileNameCharactersList]="invalidFileNameCharactersList">
</deal-wbs-setup-app-notify-popup >

<closing-checklist-app-notify-popup  
[maxAllowedFileNameLength]="maxAllowedFileNameLength"
[invalidFileNameCharactersList]="invalidFileNameCharactersList">
</closing-checklist-app-notify-popup>