import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { DropdownItem } from '../../models/dropdown-item';
import { SandboxImages } from '../../utils/sandbox-images';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges {

  @Input() selectorWidth?:any = "width: 40%;  float: left;";
@Input() dropdownWidth?:any='width: fit-content';

  @Input()
  optionsList: Array<DropdownItem> = [];

  @Input()
  excludedOptionsList: Array<DropdownItem> = [];

  @Input()
  swiftRoleValues: Array<DropdownItem> = [];

  @Input()
  keySelected: string;

  @Input()
  disabled: any;

  @Input()
  readonly: boolean;

  @Input()
  userLeftDCF: boolean;

  @Input()
  customStyle: boolean;

  @Output()
  optionSelected: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  @Output()
  validateSwiftRole = new EventEmitter<boolean>();

  activeClass = 'active';
  defaultValue = 'Select your option';

  constructor(private sandboxImages: SandboxImages,public globalHelperService:GlobalHelperService) { }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes && changes.userLeftDCF) {
    //   this.userLeftDCF = changes.userLeftDCF.currentValue;
    // }
  }

  ngOnInit(){
    this.applyDealTeamTblDropDownWidth();
    this.optionsList?.sort((a:any,b:any)=>(a.value > b.value?1:-1))
  }
applyDealTeamTblDropDownWidth(){
  // if (localStorage.getItem("CurrentAccessPage")?.toString() == "DealWBSForm"){
    if (this.globalHelperService.currentAccessPage?.toString() == "DealWBSForm"){
    this.selectorWidth="width: 100%;  float: left;";
  }
 
}
  getUnderlinedImage(): string {
    return this.sandboxImages.getUnderlineImage();
  }

  changeSelectedValue(optionValue: DropdownItem) {
    if (!this.disabled && !this.readonly) {
      if (this.swiftRoleValues.map(item => item.value).indexOf(optionValue.value) > -1 && optionValue.value!="N/A") {
        this.validateSwiftRole.emit(true);
      } else {
        this.keySelected = optionValue.key;
        this.userLeftDCF = false;
        this.optionSelected.emit(optionValue);
      }
    }
  }

  get selectedValue(): string {
    if (this.keySelected) {
      let option;
      if (this.optionsList || this.excludedOptionsList) {
        option = this.optionsList && this._findOptionSelected(this.optionsList);
        if (!option) {
          option = this.excludedOptionsList && this._findOptionSelected(this.excludedOptionsList);
        }
      }
      return option ? option.value : this.defaultValue;
    }

    return this.defaultValue;
  }

  private _findOptionSelected(list: Array<DropdownItem>): any {
    
    return list.find(option => option.key.toString() === this.keySelected.toString());
  }
}
