export class FormListFields {

    // Project overview section:
    static engagementName = 'EngagementName';
    static series24 = 'Series24Id';
    static isIndiaInvolved = 'IsIndiaInvolved';
    static isPriorDESattendee= 'IsPriorDESattendee';
    static deloitteAlumniInvolved = 'DeloitteAlumniInvolved';
    static deloitteAlumniName = 'DeloitteAlumniName';
    static internationalCommunity = 'InternationalCommunity';
    static deloitteAlumniRole = 'DeloitteAlumniRole';
    static engagementIndustrySICCode = 'EngagementIndustrySICCode';
    static engagementIndustrySector = 'EngagementIndustrySector';
    static source = 'ReferralSource';
    static sourceName = 'ReferralSourceName';
    static referralPercentage = 'ReferralPercentage';
    static referralSourceName = 'ReferralSourceName';
    static transactionType = 'TransactionType';
    static crossBusinessOffering = 'CrossBusinessOffering';
    static integratedMarketOffering = 'IntegratedMarketOffering';
    static projectStartDate = 'StartDate';
    static projectEndDate = 'EndDate';
    static pursuitStartDate = 'PursuitStartDate';
    static expectedCloseDate = 'ExpectedCloseDate';
    static dealTeamTable = 'DealTeamTable';

    // Client overview
    static clientLegalName = 'ClientName';
    static clientType = 'ClientType';
    static leadClientServicePartner = 'LeadClientServicePartnerId';
    static leadBusinessPartner = 'LeadBusinessPartnerId';
    static globalLCSP = 'GlobalLCSPId';
    static doingBusinessAs = 'DoingBusinessAs';
    static streetAddress = 'StreetAddress';
    static city = 'City';
    static state = 'StateFormListFields';
    static postalCode = 'PostalCode';
    static telephone = 'Telephone';
    static industy = 'Industry';

    // New Deal
    static clientRevenue = 'ClientRevenue';
    static ebitda = 'EBITDA';
    static statusNewDeal = 'StatusNewDeal';
    static isCompetitivePitch = 'IsCompetitivePitch';
    static businessDescription = 'BusinessDescription';
    static subsector = 'Subsector';
    static isPubliclyTradedSecurities = 'PubliclyTradedSecurities';
    static isPubliclyTradedEquity = 'IsPubliclyTradedEquity';
    static tickerCode = 'TickerCode';
    static isPubliclyTradedDebt = 'IsPubliclyTradedDebt';
    static cusip = 'Cusip';
    static firmType = 'FirmType';
    static conflictCheckCompletion = 'ConflictCheckCompletion';
    static obaPstPersonnelConflict = 'ObaPstPersonnelConflict';
    static obaPstPersonnelConflictDate = 'ObaPstPersonnelConflictDate';
    static backgroundCheckCompletion = 'BackgroundCheckCompletion';
    static ofacCompletion = 'OfacCompletion';
    static commentsNewDeal = 'CommentsNewDeal';
    static competitorsList = 'CompetitorsList';
    static otherCompetitor = 'OtherCompetitor';

    // Mercury
    static maConfidentialSubsidiary = 'M_AConfidentialSubsidiary';
    static opportunityPhase = 'OpportunityPhase';
    static totalValue = 'TotalValue';
    static buyerName = 'BuyerName';
    static buyerRole = 'BuyerRole';
    static initiative = 'Initiative';
    static feeType = 'FeeType';
    static isThisAReproposal = 'IsThisAReproposal';
    static isThisARecurringOpportunity = 'IsThisARecurringOpportunity';
    static annualOpportunity = 'AnnualOpportunity';
    static isThisAPullThroughOpportunity = 'IsThisAPullThroughOpportunity';
    static originalOpportunityID = 'OriginalOpportunityID';
    static isThisAFollowOnOpportunity = 'IsThisAFollowOnOpportunity';
    static covid19RelatedNewOpportunity = 'COVID19RelatedNewOpportunity'
    static category = 'Category';
    static subCategory = 'SubCategory';
    static offering = 'Offering';
    static subcontractor = 'Subcontractor';
    static allianceProduct = 'AllianceProduct';
    static memberFirmRecognizingRevenue = 'MemberFirmRecognizingRevenue';

    // PRD
    static clientID = 'ClientID';
    static engagementType = 'EngagementType';
    static isRecurringWBS = 'IsRecurringWBS';
    static deloitteUSEntity = 'DeloitteUSEntity';
    static salesOffice = 'SalesOffice';
    static isInternationalEngagement = 'IsInternationalEngagement';
    static areResourcesWorkOutside = 'AreResourcesWorkOutside';
    static memberFirmTaxablePresence = 'MemberFirmTaxablePresence';
    static ohterMemberFirmsParticipating = 'OtherMemberFirmsParticipating';
    static whereWorkMemberFirms = 'WhereWorkMemberFirms';
    static marketOffering = 'MarketOffering';
    static material = 'Material';

    // Client setup
    static deloitteUSEntityClient = 'DeloitteUSEntityClient';
    static salesOfficeClient = 'SalesOfficeClient';
    static ownershipTypeI1 = 'OwnershipTypeI1';
    static ownershipTypeI2 = 'OwnershipTypeI2';
    static ownershipTypeI13 = 'OwnershipTypeI13';
    static dateBecameClient = 'DateBecameClient';
    static financialYearEnd = 'FinancialYearEnd';
    static consultingSICCode = 'ConsultingSICCode';
    static globalUltimateParentID = 'GlobalUltimateParentID';
    static globalClientPortfolio = 'GlobalClientPortfolio';
    static globalClientIntelligenceNumber = 'GlobalClientIntelligenceNumber';
    static gst = 'GST';
    static buyerNameClient = 'BuyerNameClient';
    static buyerTitle = 'BuyerTitle';
    static buyerEmail = 'BuyerEmail';
    static billingAddressSameClientAddress = 'BillingAddressSameClientAddress';
    static billingAddress = 'BillingAddress';
    static payerAddressSameClientAddress = 'PayerAddressSameClientAddress';
    static payerAddress = 'PayerAddress';
    static serviceAddressSameClientAddress = 'IsServiceSameAsClientAddress';
    static serviceAddress = 'ServiceAtAddress';
    static attestStatus = 'AttestStatus';
    static attestStatusParent = 'AttestStatusParent';
    static previousAuditor = 'PreviousAuditor';
    static currentAuditor = 'CurrentAuditor';
    static memberFirmPerformingAttest = 'MemberFirmPerformingAttest';
    static entityOnWhichPerformsAttestWork = 'EntityOnWhichPerformsAttestWork';
    static currentAuditorTenureExpiryDate = 'CurrentAuditorTenureExpiryDate';
    static auditorStartDate = 'AuditorStartDate';
    static pcaobReview = 'PcaobReview';
    static descDesignation = 'DescDesignation';
    static approvingPartnerTelephone = 'ApprovingPartnerTelephone';
    static clientAcceptanceProcedures = 'ClientAcceptanceProcedures';
    static reasonForBypassingProcedures = 'ReasonForBypassingProcedures';
    static anySpecialInstructions = 'AnySpecialInstructions';

    // Documents
    static documentTypesAttachments = 'DocumentTypesAttachments';
}
