import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormValidations } from 'src/shared/utils/form-validations';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { DealTeamTableComponent } from '../deal-team-table/deal-team-table.component';
import { SecurityService } from 'src/shared/services/security.service';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { AppSettings } from 'src/shared/app-settings';
import { DropdownItem } from 'src/shared/models/dropdown-item';

@Component({
  selector: 'app-engagement-team',
  templateUrl: './engagement-team.component.html',
  styleUrls: ['./engagement-team.component.scss']
})
export class EngagementTeamComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];
  updateDealTeamTable = true;
  allowToDuplicateUser = false;

  @Input() formGroup: FormGroup;
  @Input() formSubmitted: boolean;

  @Output() dealTeamRowToStore = new EventEmitter<PeoplePickerSelectedRow>();

  @ViewChild('dealTeam', {static: true})
  dealTeamComponent: DealTeamTableComponent;

  constructor(private securityService: SecurityService) { }

  ngOnInit() {
  }

  setUpdateDealTeam(updateDealTeamTable:any) {
    this.updateDealTeamTable = updateDealTeamTable;
  }

  getUpdateDealTeam(): boolean {
    return this.updateDealTeamTable;
  }

  updatedExcludedUsers() {
    const rows = this.getDealTeamRowsComplete();
    const currentUsers: Array<PeoplePickerUser> = [];
    rows.forEach(row => {
      currentUsers.push(...row.usersSelected);
    });
    this.dealTeamComponent.updatedExcludedUsers(currentUsers);
  }

  onDealTeamRowSelection(rowToStore: PeoplePickerSelectedRow) {
    const dropdownRole = this._createDefaultValueToRole(AppSettings.roleNA.toString());
    const dropdownPursuitRole = this._createDefaultValueToRole(AppSettings.pursuitRoleNA.toString());
    rowToStore.item.setOptionFirstColumn(dropdownRole);
    rowToStore.item.setOptionSecondColumn(dropdownPursuitRole);
    this.dealTeamRowToStore.emit(rowToStore);
  }

  setDealTeamValues(dealTeamValues: PeoplepickerDynamicTableItem[]) {
    this.dealTeamValues = dealTeamValues;
  }

  getDealTeamValues(): Array<PeoplepickerDynamicTableItem> {
    return this.dealTeamValues;
  }

  getDealTeamRowsComplete(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.dealTeamValues) {
      this.dealTeamValues.forEach(row => {
        if (row && row.isComplete_DealWbs()) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }

  setItemPeoplePicker(itemId: number, index: number) {
    this.dealTeamComponent.setItemId(itemId, index);
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  showAsterisk(formControlName: string): boolean {
    return FormValidations.showAsterisk(this.formGroup, formControlName);
  }

  highlightRequiredField(formControlName: string): boolean {
    return FormValidations.highlightRequiredField(this.formSubmitted, this.formGroup, formControlName);
  }

  editedExternally(formControlName: string): boolean {
    return FormValidations.editedExternally(this.formGroup, formControlName);
  }

  hasMergeConflict(formControlName: string): boolean {
    return FormValidations.hasMergeConflict(this.formGroup, formControlName);
  }

  private _createDefaultValueToRole(pursuitRoleNA:any) {
    const dropdownPursuitRole: DropdownItem = {
      key: pursuitRoleNA,
      value: AppSettings.defaultValueRoleNA
    };
    return dropdownPursuitRole;
  }
}
