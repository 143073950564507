import { DatePipe, getLocaleDateFormat } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Series24FinalApproval } from "src/shared/models/series24FinalApproval";
import { FormValidations } from "src/shared/utils/form-validations";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { DateFormatterService } from "src/shared/utils/utils-date";
import { DateService } from "src/app/services/date.service";

@Component({
  selector: "app-series-24-approval",
  templateUrl: "./series-24-approval.component.html",
  styleUrls: ["./series-24-approval.component.scss"],
})
export class Series24ApprovalComponent implements OnInit {
  @Input() series24FinalApproval: Series24FinalApproval;
  @Input() series24Signature: boolean;
  @Input() discontinuePermission: boolean;
  @Input() series24FinalApprovalSection:boolean
  @Input() approvePermission:boolean;
  @Output() onApprove = new EventEmitter();
  @Output() onReturnForWork = new EventEmitter();
  @Output() onDiscontinue = new EventEmitter();
  constructor(private datePipe: DatePipe,private dateFormatterService: DateFormatterService,private dateService: DateService) {}
  ngOnInit() {}
  private _formatAdminReviewDate(series24ApprovalDate: string): string {
    let dateObject = new Date(series24ApprovalDate);
    dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,series24ApprovalDate);  
    return this.datePipe.transform(dateObject, "dd MMM y hh:mm a", 'CDT') + " CST";
    //Enable below for Actual CST
    // const dateObject = this.dateFormatterService.formatDateOnRetrieve(series24ApprovalDate);
    // return `${this.dateService._FormatDateCCL(dateObject.toString())}`;
  }
  setSeries24FinalApproval(series24FinalApproval:any) {
    this.series24FinalApproval = series24FinalApproval;
    if (this.series24FinalApproval.series24Signature) {
      this.series24Signature = true;
      this.series24FinalApproval.series24ApprovalDate =
        this._formatAdminReviewDate(
          this.series24FinalApproval.series24ApprovalDate
        );
    }
  }
  Approve() {
    this.onApprove.emit();
  }
  ReturnForWork() {
    this.onReturnForWork.emit();
  }
  Discontinue() {
    this.onDiscontinue.emit();
  }
}
