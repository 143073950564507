import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DropdownItem } from '../../../shared/models/dropdown-item';
import { FreeTextTableItem } from '../../../shared/models/free-text-table-item';
import { FreeTextTableDropdownItemList } from '../../../shared/models/free-text-table-dropdown-item-list';
import { FreeTextSelectedRow } from '../../../shared/components/free-text-table/free-text-selected-row.interface';
import { FreeTextTableComponent } from '../../../shared/components/free-text-table/free-text-table.component';
import { DropdownService } from '../../../shared/services/dropwdown.service';
import { MainService } from '../../../shared/services/main.service';
import { AppSettings } from '../../../shared/app-settings';
import { DynamicTableAction } from '../../../shared/models/dynamic-table-action';
import { ComplianceService } from "../shared/services/compliance-service";
// Services

@Component({
  selector: 'app-counterparties-table',
  templateUrl: './counterparties-table.component.html',
  styleUrls: ['./counterparties-table.component.scss']
})
export class CounterpartiesTableComponent implements OnInit {
  publicTradedEntityList: Array<DropdownItem> = AppSettings.counterpartiesOptions;
  restrictedEntityList: Array<DropdownItem> = AppSettings.counterpartiesOptions;
  dttlList: Array<FreeTextTableItem> = [];
  publicTradedEntityColumn: FreeTextTableDropdownItemList = new FreeTextTableDropdownItemList();
  restrictedEntityColumn: FreeTextTableDropdownItemList = new FreeTextTableDropdownItemList();
  rowToAdd: FreeTextSelectedRow;

  @ViewChild('freeTextTable', {static: false})
  freeTextTableComponent: FreeTextTableComponent;
 

  @Input()
  readonly = false;

  @Input()
  firstColumnVisible = true;

  @Input()
  secondColumnVisible = true;

  @Input()
  dttlValues: Array<FreeTextTableItem> = [];

  @Input()
  dttlTableFormGroup: any;

  @Output()
  dttlRowToStore: EventEmitter<FreeTextSelectedRow> = new EventEmitter<FreeTextSelectedRow>();

  constructor(private dropdownService: DropdownService,
              private ComplianceService: ComplianceService,
              private mainService: MainService) { }

  ngOnInit()
  {
     this.publicTradedEntityColumn.itemList = AppSettings.counterpartiesOptions;
    this.restrictedEntityColumn.itemList = AppSettings.counterpartiesOptions;
  }

  setItemId(itemId: number, index: number) {
    // zTODO THIS FREETEXTTABLECOMPOENT IS UNDEFINED
    this.freeTextTableComponent.setItemId(itemId, index);
  }

  onDTTLRowSelection(dttlRow: FreeTextSelectedRow) {
    if (dttlRow.actionType === DynamicTableAction.Add) {
      this._handlerNewRow(dttlRow);
    } else {
      const dttlValues: FreeTextTableItem[] = this.dttlTableFormGroup.value;
      const arrayWithoutRowToDelete = dttlValues.filter(user => user.itemId !== dttlRow.item.itemId);
      this.dttlTableFormGroup.setValue([...arrayWithoutRowToDelete]);
      this.dttlRowToStore.emit(dttlRow);
    }
  }

  private _handlerNewRow(dttlRow: FreeTextSelectedRow) {
    this.rowToAdd = dttlRow;
    if (dttlRow) {
      this._setNewRow();
    } else {
      this.freeTextTableComponent.showError();
    }
  }

  private _setNewRow() {
    const itemIdToInsert = this.rowToAdd.item.itemId;
    const dttlRows: FreeTextTableItem[] = this.dttlTableFormGroup.value;
    if (!itemIdToInsert) {
      this.dttlTableFormGroup.setValue([...dttlRows, this.rowToAdd.item]);
    } else {
      const arrayWithoutRowToUpdate = dttlRows.filter(item => item.itemId !== this.rowToAdd.item.itemId);
      this.dttlTableFormGroup.setValue([...arrayWithoutRowToUpdate, this.rowToAdd.item]);
    }
    this.dttlRowToStore.emit(this.rowToAdd);
  }
}


