import { AttachmentItem } from './attachment-item';

export class DocumentTypeItem {
    documentTypeId: number;
    documentTypeItemId: number;
    documentTypeTitle: string;
    documentTypeRequired: boolean;
    documentTypeAdditional: boolean;
    documentTypeAttachments: Array<AttachmentItem>;

    equals(other: DocumentTypeItem) {
        if (this.isDocumentTypeIdEquals(other.documentTypeId) &&
            this.isDocumentTypeAttachmentsEquals(other.documentTypeAttachments)) {
            return true;
        }
        return false;
    }

  isDocumentTypeIdEquals(otherDocumentTypeId: number): boolean {
    return this.documentTypeId === otherDocumentTypeId;
  }

  isDocumentTypeAttachmentsEquals(otherDocumentTypeAttachments: Array<AttachmentItem>): boolean {
    if (this.documentTypeAttachments.length !== otherDocumentTypeAttachments.length) {
      return false;
    } else {
      this.documentTypeAttachments.forEach((item, index) => {
        if (item.attachmentName !== otherDocumentTypeAttachments[index].attachmentName) {
          return false;
        }
        return true; //
      });
    }
    return true;
  }
}
