import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppSettings } from 'src/shared/app-settings';

interface InputValue {
  value: any;
  formGroup: string;
  controlName: string;
}

@Injectable({
  providedIn: 'root'
})

export class DefaultValues {
  defaultValues: Map<string, InputValue>;
  
  constructor() {
    this.defaultValues = new Map<string, InputValue>([
      [ 'CrossBusinessOffering', {value: 'DCF M&A Transaction', formGroup: 'mercuryOpportunityFormGroup', controlName: 'crossBusinessOffering'}],
      //[ 'IntegratedMarketOffering', {value: 'M&A Transaction CFMO', formGroup: 'projectOverViewFormGroup', controlName: 'integratedMarketOffering'}],
      [ 'OpportunityPhase', {value: 'Request to Propose', formGroup: 'mercuryOpportunityFormGroup', controlName: 'opportunityPhase'}],
      [ 'TotalValue', {value: '750000', formGroup: 'mercuryOpportunityFormGroup', controlName: 'totalValue'}],
      //[ 'EngagementType', {value: 'Client pursuit (PRD)', formGroup: 'prdFormGroup', controlName: 'engagementType'}],
      //[ 'Material', {value: 'Consulting', formGroup: 'prdFormGroup', controlName: 'material'}],
      [ 'Initiative', {value: 'N/A', formGroup: 'mercuryOpportunityFormGroup', controlName: 'initiative'}],
      [ 'Category', {value: 'Mergers, Acquisitions & Divestitures', formGroup: 'mercuryOpportunityFormGroup', controlName: 'category'}],
      [ 'SubCategory', {value: 'MA&D - Corporate Finance', formGroup: 'mercuryOpportunityFormGroup', controlName: 'subCategory'}],
      [ 'AllianceProduct', {value: 'None', formGroup: 'mercuryOpportunityFormGroup', controlName: 'allianceProduct'}],
      [ 'DeloitteUSEntityClient', {value: 'Deloitte CF LLC', formGroup: 'clientSetupFormGroup', controlName: 'deloitteUSEntityClient'}],
      // [ 'SalesOfficeClientId', {value: "3", formGroup: 'clientSetupFormGroup', controlName: 'salesOfficeClient'}],
      //FinancialYearEnd was commented as per UAT feeback changes
      //[ 'FinancialYearEnd', {value: new Date(new Date().getFullYear(), 11, 31), formGroup: 'clientSetupFormGroup', controlName: 'financialYearEnd'}]
    ]);
  }

  initializeAppFormGroup(appFormGroup: FormGroup, response:any) {
    this.defaultValues.forEach((obj: InputValue, key: string) => {
      const columnValue = this._getColumnValue(response, key);
      if (!columnValue) {
        const formGroup = obj.formGroup;
        const value = obj.value;
        const controlName = obj.controlName;
        appFormGroup.get(formGroup)?.get(controlName)?.setValue(value);
      }
    });
  }

  initializeSharepointObject(responseSharepointObject:any) {
    Object.keys(responseSharepointObject).forEach(column => {
      const exist = this.defaultValues.get(column);
      if (!responseSharepointObject[column] && exist) {
        const value = exist.value;
        responseSharepointObject[column] = value;
      }
    });
    return responseSharepointObject;
  }

  initializeMyDealSharepointObject(responseMySharepointObject:any)
  {
    if (responseMySharepointObject) {
      responseMySharepointObject.EngagementType = responseMySharepointObject.EngagementType ? responseMySharepointObject.EngagementType : AppSettings.engagementTypeDefaultValue;
      responseMySharepointObject.IntegratedMarketOffering = responseMySharepointObject.IntegratedMarketOffering ? responseMySharepointObject.IntegratedMarketOffering : AppSettings.integratedMarketOfferingDefaultValue;
      responseMySharepointObject.Material = responseMySharepointObject.Material ? responseMySharepointObject.Material : AppSettings.materialDefaultValue;
    }
    return responseMySharepointObject;
  }

  private _getColumnValue(response:any, key: string) {
    let value = null;
    Object.keys(response).forEach(columnName => {
      if (columnName === key) {
        value = response[key];
      }
    });

    return value;
  }
}
