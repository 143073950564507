import { SharepointObjectMyDeal } from '../models/sharepoint-object-mydeal';
import { FormStatusValue, StatusValue } from 'src/shared/models/form-status-value';
import { Injectable } from '@angular/core';
import { MainService } from 'src/shared/services/main.service';
import { AppContext } from '../models/app-context';
import { SubmitObject } from '../models/submit-object';
import { AdminDiscontinueObject } from '../models/admin-discontinue-object';
import { FormActionGroupValue } from '../models/form-action-group-value';
import { AppSettings } from 'src/shared/app-settings';
import { SharepointObjectCompliance } from '../models/sharepoint-object-compliance';
import { SubmitObjectS24 } from '../models/submitObjectS24';
import { SharepointObject } from '../models/sharepoint-object';
import { SharepointValuationObject } from '../models/sharepoint-valudation-object';
import { SharepointClosingCheckListObject, SharepointDealMarketingSection, SharePointObjectValuationMetrics } from '../models/sharepoint-closingchecklist-object';
import { SharepointDealMarketingObject } from '../models/sharepoint-deal-marketing-object';
import { DocumentTypeItem } from 'src/shared/models/document-type-item';
import { AttachmentItem } from 'src/shared/models/attachment-item';
import { FormListFields } from '../models/form-list-fields';
import { DateFormatterService } from 'src/shared/utils/utils-date';

@Injectable({
  providedIn: 'root'
})

export class ComplianceServiceHelper {

  constructor(private mainService: MainService,private dateFormatterService: DateFormatterService,) {

  }

  createAppContext(autoSaveInterval: number, autoSaveEnabled: boolean,autoSaveSpinningWheelInterval:number): AppContext {
    const appContext: AppContext = {
      autoSaveInterval,
      autoSaveEnabled,
      autoSaveSpinningWheelInterval
    };
    return appContext;
  }

  createNewDealObjectToSend(myDealId: number) {
    const newDealObject:any = {};
    newDealObject[AppSettings.myDealSiteColumn] = myDealId;
    return newDealObject;
  }

  createSubmitObject(submissionDate: Date,newWorkflow:boolean) {
    const objectToSend: SubmitObject = {
      SubmissionDate: submissionDate,
      FormStatus: FormStatusValue.submitted,
      ExecuteWorkflow: "TRUE",
      ExecuteNewWorkflow:newWorkflow == true? "TRUE" : "FALSE",
      Status:StatusValue.AdminReviewpending,
    };
    return objectToSend;
  }

  createAdminDiscontinueObject(formStatus: string,discontinueReason: string): AdminDiscontinueObject {
    const objectToSend:any = {
      DiscontinuedOn: new Date(),
      DiscontinuedBy: this.mainService.currentUser.title,
      DiscontinueReason: discontinueReason,
      Status:FormStatusValue.discontinued,
      FormStatus: FormStatusValue.discontinued,
      DiscontinuedFormStatus: formStatus,
      ExecuteWorkflow: "TRUE"
    };
    return objectToSend;
  }

  CreateComplianceSetupObject(response:any) : SharepointObject{//created for object formation
  const ComplianceObject:SharepointObject ={
  WBSCode : response.WBSCode,
  DealType : response.DealType,
  IsClientPublicEntity :response.IsClientPublicEntity,
  Symbol :response.Symbol,
  RemoveFromBlackList :response.RemoveFromBlackList,
  Counterparties :response.Counterparties,
  BranchOffice :response.BranchOffice,
  WerePreviousSeries24 :response.WerePreviousSeries24,
  PPMDDCFUser :response.PPMDDCFUser,
  PPMDNonDCFUser :response.PPMDNonDCFUser,
  WerePreviousPPMD :response.WerePreviousPPMD,
  //CurrentSeries24Id:response.CurrentSeries24Id,
 // CurrentPPMDLeadId :response.CurrentPPMDLeadId,
  PreviousSeries24 :response.PreviousSeries24,
  PreviousPPMDLead :response.PreviousPPMDLead,
  IsDTTLInvolved :response.IsDTTLInvolved,
  DTTLInvolved :response.DTTLInvolved,
  IsXBEngagementAn :response.IsXBEngagementAn,
  CapitalRaised :response.CapitalRaised,
  PitchBook :response.PitchBook,
  PitchBookSeries24 :response.PitchBookSeries24,
  Teaser :response.Teaser,
  TeaserSeries24 :response.TeaserSeries24,
  CIMMemorandumPPM :response.CIMMemorandumPPM,
  CIMMemorandumPPMSeries24 :response.CIMMemorandumPPMSeries24,
  Tombstone :response.Tombstone,
  TombstoneSeries24 :response.TombstoneSeries24,
  CaseStudies :response.CaseStudies,
  CaseStudiesSeries24 :response.CaseStudiesSeries24,
  PressRelease :response.PressRelease,
  PressReleaseSeries24 :response.PressReleaseSeries24,
  LeagueTablesMarketing :response.LeagueTablesMarketing,
  LinkFinalEngagement :response.LinkFinalEngagement,
  CopyFinalBriefingPaper :response.CopyFinalBriefingPaper,
  ExecutedEngagementLetter :response.ExecutedEngagementLetter,
  AmendmentsEngagementLetter :response.AmendmentsEngagementLetter,
  FullyExecutedIFA :response.FullyExecutedIFA,
  PitchProposal :response.PitchProposal,
  TeaserBooks :response.TeaserBooks,
  CIMOfferingMemorandum :response.CIMOfferingMemorandum,
  ManagementPresentation :response.ManagementPresentation,
  ClientLetterMarketingPress :response.ClientLetterMarketingPress,
  TombstoneBooks :response.TombstoneBooks,
  PressReleaseBooks :response.PressReleaseBooks,
  CaseStudiesBooks :response.CaseStudiesBooks,
  LeagueTables :response.LeagueTables,
  ListProspectiveInvestorsBuyers :response.ListProspectiveInvestorsBuyers,
  ListActualInvestorsBuyers :response.ListActualInvestorsBuyers,
  ExpressionOfInterestLettersInt :response.ExpressionOfInterestLettersInt,
  ContainsAllTransactionDocuments :response.ContainsAllTransactionDocuments,
  DidProjectGoIC :response.DidProjectGoIC,
  IfNoExplain :response.IfNoExplain,
  DateICReviewApproval :response.DateICReviewApproval,
  ZipFileSent :response.ZipFileSent,
  Series24ReviewedTransaction :response.Series24ReviewedTransaction,
  Series24ReviewedPersonnelHours :response.Series24ReviewedPersonnelHours,
  TotalAmountsPaid :response.TotalAmountsPaid,
  RetainerReceived :response.RetainerReceived,
  PeriodicOrFlat :response.PeriodicOrFlat,
  AmountRetainer :response.AmountRetainer,
  PeriodRetainer :response.PeriodRetainer,
  RetainerCredited :response.RetainerCredited,
  EngagementFeeBased :response.EngagementFeeBased,
  SuccessFeePaidFull :response.SuccessFeePaidFull,
  AmountSuccessPaid :response.AmountSuccessPaid,
  PotentialAmountOutstanding :response.PotentialAmountOutstanding,
  DateSuccessFeeReceived :response.DateSuccessFeeReceived,
  ExpensesBilled :response.ExpensesBilled,
  FinderReferralFeeOwed :response.FinderReferralFeeOwed,
  AmountFinderOwed :response.AmountFinderOwed,
  RecipientFinderFee :response.RecipientFinderFee,
  DatePaidFinderFee: response.DatePaidFinderFee,
  ComplianceDealType: response.ComplianceDealType,
    };
    return ComplianceObject;
  }
  CreateMyDealObject(response:any) : SharepointObjectMyDeal{
    const myDealObject:any ={
      Series24: response.Series24,
      EngagementName : response.EngagementName,
      IsCompetitivePitch:response.IsCompetitivePitch,
      ClientName:response.ClientName ,
      Industry:response.Industry,
      Subsector:response.Subsector,
      BuyerName:response.BuyerName,
      FeeType:response.FeeType,
      Retainer:response.Retainer
    };
    return myDealObject;
  }
  CreateValuationDifferenceObject(response:any):SharepointValuationObject{
  const valuationDifferences:SharepointValuationObject={
    IOIValueClosingLow: response.IOIValueClosingLow,
    IOIValueClosingHigh: response.IOIValueClosingHigh,
    IOIValueContingent: response.IOIValueContingent,
    IOIValueTotalHigh: response.IOIValueTotalHigh,
    IOIValueClosingAvg: response.IOIValueClosingAvg,
    IOIValueTotalAvg: response.IOIValueTotalAvg,
    LOIValueClosingLow: response.LOIValueClosingLow,
    LOIValueClosingHigh: response.LOIValueClosingHigh,
    LOIValueContingent: response.LOIValueContingent,
    LOIValueTotalHigh: response.LOIValueTotalHigh,
    LOIValueClosingAvg: response.LOIValueClosingAvg,
    LOIValueTotalAvg: response.LOIValueTotalAvg,
    NumericalPriceIncClosingLow: response.NumericalPriceIncClosingLow,
    NumericalPriceIncClosingHigh: response.NumericalPriceIncClosingHigh,
    NumericalPriceIncContingent: response.NumericalPriceIncContingent,
    NumericalPriceIncTotalHigh: response.NumericalPriceIncTotalHigh,
    NumericalPriceIncClosingAvg: response.NumericalPriceIncClosingAvg,
    NumericalPriceIncTotalAvg: response.NumericalPriceIncTotalAvg,
    PercentagePriceIncClosingLow: response.PercentagePriceIncClosingLow,
    PercentagePriceIncClosingHigh: response.PercentagePriceIncClosingHigh,
    PercentagePriceIncContingent: response.PercentagePriceIncContingent,
    PercentagePriceIncTotalHigh: response.PercentagePriceIncTotalHigh,
    PercentagePriceIncClosingAvg: response.PercentagePriceIncClosingAvg,
    PercentagePriceIncTotalAvg: response.PercentagePriceIncTotalAvg,
  };
    return valuationDifferences
  }

  CreateBuyerDealMarketingDifferenceObject(response:any):SharepointDealMarketingObject
  {
    const dealMarketingObject:SharepointDealMarketingObject = {
      ApproachedIntlStrategic : response.ApproachedIntlStrategic,
      ApproachedPEG: response.ApproachedPEG,
      ApproachedTotal: response.ApproachedTotal,
      ApproachedUSStrategic: response.ApproachedUSStrategic,
      AttendedMPPEG: response.AttendedMPPEG,
      AttendedMPPEGNoPortCo: response.AttendedMPPEGNoPortCo,
      AttendedMPTotal: response.AttendedMPTotal,
      AttendedMPUSStrategic: response.AttendedMPUSStrategic,
      SentCIMIntlStrategic: response.SentCIMIntlStrategic,
      SentCIMPEG: response.SentCIMPEG,
      SentCIMPEGNoPortCo: response.SentCIMPEGNoPortCo,
      SentCIMTotal: response.SentCIMTotal,
      SentCIMUSStrategic: response.SentCIMUSStrategic,
      SubmittedIOIIntlStrategic: response.SubmittedIOIIntlStrategic,
      SubmittedIOIPEGNoPortCo: response.SubmittedIOIPEGNoPortCo,
      SubmittedIOITotal: response.SubmittedIOITotal,
      SubmittedIOIUSStrategic: response.SubmittedIOIUSStrategic,
      SubmittedLOIIntlStrategic: response.SubmittedLOIIntlStrategic,
      SubmittedLOIPEG: response.SubmittedLOIPEG,
      SubmittedLOIPEGNoPortCo: response.SubmittedLOIPEGNoPortCo,
      SubmittedLOITotal: response.SubmittedLOITotal,
      SubmittedLOIUSStrategic: response.SubmittedLOIUSStrategic,
      ApproachedPEGNoPortCo:response.ApproachedPEGNoPortCo,
      AttendedMPIntlStrategic:response.AttendedMPIntlStrategic,
      SubmittedIOIPEG:response.SubmittedIOIPEG
    }
    return dealMarketingObject;
  }
  CreateCCFormDifferenceObject(response:any):SharepointClosingCheckListObject{
    const ccFormDifferences:SharepointClosingCheckListObject={
      MinimumFeeApply:response.MinimumFeeApply,
      Retainer:response.Retainer,
     MinimumSucessFee:response.MinimumSucessFee,
     FeeType:response.FeeType,
     FinimumFeeApply:response.FinimumFeeApply,
     DescribeFeeStructure:response.DescribeFeeStructure,
     BreakpointValue: response.BreakpointValue,
     BreakpointQuestion: response.BreakpointQuestion,
     BaseFeeNumerical:response.BaseFeeNumerical,
    BaseFeePercentage: response.BaseFeePercentage,
    BaseFeeRadio: response.BaseFeeRadio,
    IncentiveFeeRadio: response.IncentiveFeeRadio,
    IncentiveFeePercentage: response.IncentiveFeePercentage,
    IncentiveFeeNumerical: response.IncentiveFeeNumerical,
      ClosingConsideration: response.ClosingConsideration,
      ContingentConsideration: response.ContingentConsideration,
      DescribeDealStructure: response.DescribeDealStructure,
      Ebitda: response.Ebitda,
      EbitdaMultipleClosing: response.EbitdaMultipleClosing,
      EbitdaMultipleTotal: response.EbitdaMultipleTotal,
      EbitdaPeriod: response.EbitdaPeriod,
      ElBreakpoint: response.ElBreakpoint,
      FinalEvPitchRange: response.FinalEvPitchRange,
      HitBreakpointEL: response.HitBreakpointEL,
      IfBelowWhy: response.IfBelowWhy,
      IfBelowWhyOtherReason: response.IfBelowWhyOtherReason,
      ImpliedValuationPremium: response.ImpliedValuationPremium,
      IoiAboveBreakpoint: response.IoiAboveBreakpoint,
      LoiAboveBreakpoint: response.LoiAboveBreakpoint,
      LoiClosedConsideration: response.LoiClosedConsideration,
      LoiClosedContingent: response.LoiClosedContingent,
      LoiTotal: response.LoiTotal,
      MultipleRangeMidpoint: response.MultipleRangeMidpoint,
      PitchEBITDAMultipleRangeFirst: response.PitchEBITDAMultipleRangeFirst,
      PitchEBITDAMultipleRangeSecond: response.PitchEBITDAMultipleRangeSecond,
      TotalConsideration: response.TotalConsideration,
      ValNumericalClosedConsideration: response.ValNumericalClosedConsideration,
      ValNumericalTotal: response.ValNumericalTotal,
      ValPercentageClosedConsideration: response.ValPercentageClosedConsideration,
      ValPercentageTotal: response.ValPercentageTotal,
      ValuationPremiumOverBp: response.ValuationPremiumOverBp,
      ClientType: response.ClientType,
      SponsorName: response.SponsorName,
      SubsidiaryBeingSold: response.SubsidiaryBeingSold,
      ClientRelationship: response.ClientRelationship,
      ReferralSource: response.ReferralSource,
      ReferralSourceSubType: response.ReferralSourceSubType,
      ClientLocation: response.ClientLocation,
      ClientCountry: response.ClientCountry,
      DealType: response.DealType,
      CrossBorderEngagement: response.CrossBorderEngagement,
      FirstProcessRun: response.FirstProcessRun,
      FirstInstitutionalCapital: response.FirstInstitutionalCapital,
      NumberOfParticipants: response.NumberOfParticipants,
      BasketType: response.BasketType,
      CapNumerical: response.CapNumerical,
      CapPercentageClosing: response.CapPercentageClosing,
      CapPercentageEV: response.CapPercentageEV,
      SurvivalMonths: response.SurvivalMonths,
      DeductibleClosing: response.DeductibleClosing,
      DeductibleEV: response.DeductibleEV,
      DeductibleNumerical: response.DeductibleNumerical,
      EscrowNumerical: response.EscrowNumerical,
      EscrowTotalEV: response.EscrowTotalEV,
      EscrowClosing: response.EscrowClosing,
      ReleaseMonths: response.ReleaseMonths,
      ReconciliationEscrow: response.ReconciliationEscrow,
      IfOtherWhat: response.IfOtherWhat,
      SpecialIndemnification: response.SpecialIndemnification,
      SpecialIndemnificationTopic: response.SpecialIndemnificationTopic,
      BuyerCountry:response.BuyerCountry,
      BuyerParentCompany:response.BuyerParentCompany,
      BuyerRelationship:response.BuyerRelationship,
      BuyerType:response.BuyerType,
      DeloitteLCSP:response.DeloitteLCSP,
      DescribeDealMarketing:response.DescribeDealMarketing,
      IdentifiedTierPitch:response.IdentifiedTierPitch,
      InternationalBuyer:response.InternationalBuyer,
      MarketingProcessType:response.MarketingProcessType,
      PreemptiveBidder:response.PreemptiveBidder,
      PreemptiveFinalPurchaser:response.PreemptiveFinalPurchaser,
      PreemptiveOffer:response.PreemptiveOffer,
      PremiumOverInitialOffer:response.PremiumOverInitialOffer,
      EquityContributionClosingPct: response.EquityContributionClosingPct,
      EquityContributionTotalPct: response.EquityContributionTotalPct,
      ContingentTotalEVPct: response.ContingentTotalEVPct,
      LegalStructure: response.LegalStructure,
      ManagementFee: response.ManagementFee,
      ManagementFeePct: response.ManagementFeePct,
      ManagementFeeQty: response.ManagementFeeQty,
      RolloverAfterTaxPct: response.RolloverAfterTaxPct,
      RolloverEquity: response.RolloverEquity,
      RolloverPreTaxPct: response.RolloverPreTaxPct,
      TriggerType: response.TriggerType,
      TotalLeverage: response.TotalLeverage,
      TimeUntilContingentPayment: response.TimeUntilContingentPayment,
      StockConsideration: response.StockConsideration,
      StockConsiderationTotalEVPct: response.StockConsiderationTotalEVPct,
      ManagementOptionPool: response.ManagementOptionPool,
      SponsorTransactionClosingFee: response.SponsorTransactionClosingFee,

      FinanceEnterpriseValue: response.FinanceEnterpriseValue,
      FinanceDeloitteEntity: response.FinanceDeloitteEntity,
      FinanceDeloitteEntityDescription: response.FinanceDeloitteEntityDescription,
      FinanceFutureExpenses: response.FinanceFutureExpenses,
      FinanceFutureExpensesAmount: response.FinanceFutureExpensesAmount,
      FinanceFuturePayments: response.FinanceFuturePayments,
      FinanceFuturePaymentsType: response.FinanceFuturePaymentsType,
      FinanceAssetSaleDeal: response.FinanceAssetSaleDeal,
      FinanceCrossBorderEngagement:response.FinanceCrossBorderEngagement,
      FinanceCrossBorderCountry: response.FinanceCrossBorderCountry,
      FinanceProviderName:response.FinanceProviderName,
      FinanceProviderNameType: response.FinanceProviderNameType,
      FinanceDealLeagueTable: response.FinanceDealLeagueTable,
      FinanceDealLeagueTableDesc: response.FinanceDealLeagueTableDesc,
      FinanceDealGlobalDeloitteSite: response.FinanceDealGlobalDeloitteSite,
      FinanceDealGlobalDelSiteDesc: response.FinanceDealGlobalDelSiteDesc,
      FinanceDealAnnouncement: response.FinanceDealAnnouncement,
      FinanceDealAnnouncementDesc: response.FinanceDealAnnouncementDesc,
      FinanceDealCaseStudy: response.FinanceDealCaseStudy,
      FinanceDealCaseStudyDesc: response.FinanceDealCaseStudyDesc,
      PitchedDate: this.dateFormatterService.formatDateToSend(response.PitchedDate),
      SignedEngagementLetter: this.dateFormatterService.formatDateToSend(response.SignedEngagementLetter),
      EnteredMarket: this.dateFormatterService.formatDateToSend(response.EnteredMarket),
      ReceivedIoiTermSheets: this.dateFormatterService.formatDateToSend(response.ReceivedIoiTermSheets),
      ReceivedLOIs: this.dateFormatterService.formatDateToSend(response.ReceivedLOIs),
      SignedLOI: this.dateFormatterService.formatDateToSend(response.SignedLOI),
      TransactionClose: this.dateFormatterService.formatDateToSend(response.TransactionClose),
      SimultaneousSignClose:response. SimultaneousSignClose,
      SignDate: this.dateFormatterService.formatDateToSend(response.SignDate),
      CommentAffectingTimeline: response.CommentAffectingTimeline,
      SellsideQofEPerformed: response.SellsideQofEPerformed,
      QofEStage: response.QofEStage,
      WhatFirmPerformed:response. WhatFirmPerformed,
      MainContact:response. MainContact,
      QofEDeloitteBranded: response.QofEDeloitteBranded

    }
    return ccFormDifferences
  }
  CreateValuationMetricsObject(response:any,responseFormItem:any):SharePointObjectValuationMetrics{
    const ValuationMetrics:SharePointObjectValuationMetrics={
      ClosingConsideration: responseFormItem.ClosingConsideration,
      ContingentConsideration: responseFormItem.ContingentConsideration,
      TotalConsideration: responseFormItem.TotalConsideration,
      Ebitda: responseFormItem.Ebitda,
      EbitdaPeriod: responseFormItem.EbitdaPeriod,
      EbitdaMultipleTotal: responseFormItem.EbitdaMultipleTotal,
      EbitdaMultipleClosing: responseFormItem.EbitdaMultipleClosing,
      FinalEvPitchRange: responseFormItem.FinalEvPitchRange,
      IfBelowWhy: responseFormItem.IfBelowWhy,
      IfBelowWhyOtherReason: responseFormItem.IfBelowWhyOtherReason,
      PitchEBITDAMultipleRangeFirst: responseFormItem.PitchEBITDAMultipleRangeFirst,
      PitchEBITDAMultipleRangeSecond: responseFormItem.PitchEBITDAMultipleRangeSecond,
      MultipleRangeMidpoint: responseFormItem.MultipleRangeMidpoint,
      ImpliedValuationPremium: responseFormItem.ImpliedValuationPremium,
      HitBreakpointEL: responseFormItem.HitBreakpointEL,
      ElBreakpoint: responseFormItem.ElBreakpoint,
      ValuationPremiumOverBp: responseFormItem.ValuationPremiumOverBp,
      IoiAboveBreakpoint: responseFormItem.IoiAboveBreakpoint,
      LoiAboveBreakpoint: responseFormItem.LoiAboveBreakpoint,
      DescribeDealStructure: responseFormItem.DescribeDealStructure,
      IOIValueClosingLow: response.IOIValueClosingLow,
      IOIValueClosingHigh: response.IOIValueClosingHigh,
      IOIValueContingent: response.IOIValueContingent,
      IOIValueTotalHigh: response.IOIValueTotalHigh,
      IOIValueClosingAvg: response.IOIValueClosingAvg,
      IOIValueTotalAvg: response.IOIValueTotalAvg,
      LOIValueClosingLow: response.LOIValueClosingLow,
      LOIValueClosingHigh: response.LOIValueClosingHigh,
      LOIValueContingent: response.LOIValueContingent,
      LOIValueTotalHigh: response.LOIValueTotalHigh,
      LOIValueClosingAvg: response.LOIValueClosingAvg,
      LOIValueTotalAvg: response.LOIValueTotalAvg,
      NumericalPriceIncClosingLow: response.NumericalPriceIncClosingLow,
      NumericalPriceIncClosingHigh: response.NumericalPriceIncClosingHigh,
      NumericalPriceIncContingent: response.NumericalPriceIncContingent,
      NumericalPriceIncTotalHigh: response.NumericalPriceIncTotalHigh,
      NumericalPriceIncClosingAvg: response.NumericalPriceIncClosingAvg,
      NumericalPriceIncTotalAvg: response.NumericalPriceIncTotalAvg,
      PercentagePriceIncClosingLow: response.PercentagePriceIncClosingLow,
      PercentagePriceIncClosingHigh: response.PercentagePriceIncClosingHigh,
      PercentagePriceIncContingent: response.PercentagePriceIncContingent,
      PercentagePriceIncTotalHigh: response.PercentagePriceIncTotalHigh,
      PercentagePriceIncClosingAvg: response.PercentagePriceIncClosingAvg,
      PercentagePriceIncTotalAvg: response.PercentagePriceIncTotalAvg,
      LoiClosedConsideration: responseFormItem.LoiClosedConsideration,
      LoiClosedContingent: responseFormItem.LoiClosedContingent,
      LoiTotal: responseFormItem.LoiTotal,
      ValNumericalClosedConsideration: responseFormItem.ValNumericalClosedConsideration,
      ValNumericalTotal: responseFormItem.ValNumericalTotal,
      ValPercentageClosedConsideration: responseFormItem.ValPercentageClosedConsideration,
      ValPercentageTotal: responseFormItem.ValPercentageTotal,
    };
    return ValuationMetrics
  }


  CreateDealMarketingSectionObject(responseDealMarketing:any,responseFormItem:any):any {
    let dealMarketingObject:SharepointDealMarketingSection = {
      BuyerCountry: responseFormItem.BuyerCountry,
      BuyerParentCompany: responseFormItem.BuyerParentCompany,
      BuyerType: responseFormItem.BuyerType,
      BuyerRelationship: responseFormItem.BuyerRelationship,
      DeloitteLCSP: responseFormItem.DeloitteLCSP,
      DescribeDealMarketing: responseFormItem.DescribeDealMarketing,
      IdentifiedTierPitch: responseFormItem.IdentifiedTierPitch,
      InternationalBuyer: responseFormItem.InternationalBuyer,
      MarketingProcessType: responseFormItem.MarketingProcessType,
      PreemptiveBidder: responseFormItem.PreemptiveBidder,
      PreemptiveFinalPurchaser: responseFormItem.PreemptiveFinalPurchaser,
      PreemptiveOffer: responseFormItem.PreemptiveOffer,
      PremiumOverInitialOffer: responseFormItem.PremiumOverInitialOffer,

      ApproachedIntlStrategic : responseDealMarketing.ApproachedIntlStrategic,
      ApproachedPEG: responseDealMarketing.ApproachedPEG,
      ApproachedTotal: responseDealMarketing.ApproachedTotal,
      ApproachedUSStrategic: responseDealMarketing.ApproachedUSStrategic,
      AttendedMPPEG: responseDealMarketing.AttendedMPPEG,
      AttendedMPPEGNoPortCo: responseDealMarketing.AttendedMPPEGNoPortCo,
      AttendedMPTotal: responseDealMarketing.AttendedMPTotal,
      AttendedMPUSStrategic: responseDealMarketing.AttendedMPUSStrategic,
      SentCIMIntlStrategic: responseDealMarketing.SentCIMIntlStrategic,
      SentCIMPEG: responseDealMarketing.SentCIMPEG,
      SentCIMPEGNoPortCo: responseDealMarketing.SentCIMPEGNoPortCo,
      SentCIMTotal: responseDealMarketing.SentCIMTotal,
      SentCIMUSStrategic: responseDealMarketing.SentCIMUSStrategic,
      SubmittedIOIIntlStrategic: responseDealMarketing.SubmittedIOIIntlStrategic,
      SubmittedIOIPEGNoPortCo: responseDealMarketing.SubmittedIOIPEGNoPortCo,
      SubmittedIOITotal: responseDealMarketing.SubmittedIOITotal,
      SubmittedIOIUSStrategic: responseDealMarketing.SubmittedIOIUSStrategic,
      SubmittedLOIIntlStrategic: responseDealMarketing.SubmittedLOIIntlStrategic,
      SubmittedLOIPEG: responseDealMarketing.SubmittedLOIPEG,
      SubmittedLOIPEGNoPortCo: responseDealMarketing.SubmittedLOIPEGNoPortCo,
      SubmittedLOITotal: responseDealMarketing.SubmittedLOITotal,
      SubmittedLOIUSStrategic: responseDealMarketing.SubmittedLOIUSStrategic,
      ApproachedPEGNoPortCo:responseDealMarketing.ApproachedPEGNoPortCo,
      AttendedMPIntlStrategic:responseDealMarketing.AttendedMPIntlStrategic,
      SubmittedIOIPEG:responseDealMarketing.SubmittedIOIPEG
    }
    return dealMarketingObject
  }

  createAttachmentsObject(responseAttachments:any): Array<DocumentTypeItem> {
    const attachmentsObject: Array<DocumentTypeItem> = [];
    responseAttachments.forEach((attachment:any) => {
      //const documentTypeId = attachment.ListItemAllFields.DocumentTypeId;
      const documentTypeId =1;
      const index = attachmentsObject.map(item => item.documentTypeId).indexOf(documentTypeId);
      const attachmentItem = new AttachmentItem();
      attachmentItem.attachmentName = attachment.Name;
      if (index !== -1) {
        attachmentsObject[index].documentTypeAttachments.push(attachmentItem);
      } else {
        const documentTypeItem = new DocumentTypeItem();
        documentTypeItem.documentTypeId = documentTypeId;
        documentTypeItem.documentTypeAttachments = [];
        documentTypeItem.documentTypeAttachments.push(attachmentItem);
        attachmentsObject.push(documentTypeItem);
      }
    });
    return attachmentsObject;
  }
  

}
