import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
    static CharactersOnly(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            const regExp = /^[A-Za-z ñÑáéíóúÁÉÍÓÚüÜ]+$/;

            const valid = regExp.test(control.value);

            return !valid ? {charactersOnly: { value: control.value }} : null;
        };
    }

    static NumbersOnly(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            const regExp = /^[0-9]+$/;

            const valid = regExp.test(control.value);

            return !valid ? {numbersOnly: { value: control.value }} : null;
        };
    }

    static Date(format: string = 'dd/mm/yyyy'): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            // dd/mm/yyyy pattern
            // let ptDateRegExp =  /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;
            // mm/dd/yyyy pattern
            // let usDateRegExp = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/;

            const valid = (control.value == null) ? true : (control.value instanceof Date) ? true : false;

            return !valid ? {date: { value: control.value }} : null;
        };
    }

    static IntegerOnly(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            const valid = parseInt(control.value) === parseFloat(control.value);

            return !valid ? {integerOnly: { value: control.value }} : null;
        };
    }

    static PositiveOnly(includeZero: boolean = true) {
        return (control: AbstractControl): {[key: string]: any} | null => {
            const value = parseFloat(control.value);

            let valid = false;

            if (includeZero) {
                valid = (value >= 0) ? true : false;
            } else {
                valid = (value > 0) ? true : false;
            }

            return !valid ? {positiveOnly: { value: control.value }} : null;
        };
    }

    static PeoplePickerRequired(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        //  if(value==undefined){
        //     return{required:true};
        //  }
        if (value !== undefined && ( value === null || !value.results || !value.results.length)) {
          return { required: true };
        }
        return null;
    }

    static PeoplePickerDynamicTableRequired(control: AbstractControl): ValidationErrors | null {
        const value = control.value;

        if (value !== undefined && ( value === null || !value.length)) {
          return { required: true };
        }
        return null;
    }

    static DocumentTypesAttachmentsRequired(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        let missingAttachments = 0;

        if (value !== undefined) {
            if (value === null) {
                return { required: true };
            } else if (value.length > 0) {
                value.forEach((item:any) => {
                    if (item.hasAttachments === false) {
                        missingAttachments++;
                    }
                });
                if (missingAttachments > 0) {
                    return { required: true };
                }
            }
        }
        return null;
    }

    static InputWhithoutWhiteSpaces(control: AbstractControl): ValidationErrors | null {
      const value = control.value;

      if (value !== undefined && ( value === null || value.toString().trim().length === 0)) {
        return { required: true };
      }
      return null;
    }

}
