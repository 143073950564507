import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { DropdownService } from 'src/shared/services/dropwdown.service'; 
import { SecurityService } from 'src/shared/services/security.service';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { FormValidations } from 'src/shared/utils/form-validations';
import { DealTeamTableComponent   } from 'src/app/deal-wbs-setup/deal-team-table/deal-team-table.component';  // Create this component in Closing Checklist
import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit {

  @Input() formGroups: Array<FormGroup>;
  @Input() formSubmitted: boolean;
  @Output() initiateForm = new EventEmitter<boolean>();
  @Output() dealTeamRowToStore = new EventEmitter<PeoplePickerSelectedRow>();
  projectOverviewFormGroup: FormGroup;
  myDealFormGroup: any;
  updateDealTeamTable = true;
  showInitiate=false;
  @Input() readOnly:boolean;
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];
  sharePointMapper:any = new ComplianceSharepointMapper();
  allowToDuplicateUser = false;

  @ViewChild('dealTeam',{static:true})
  dealTeamComponent: DealTeamTableComponent;

  constructor(
    private dropdownService: DropdownService,
    private securityService: SecurityService,
    private tooltipHelperService: TooltipHelperService)
     { }

  ngOnInit() {
    this.projectOverviewFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
  }

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }
  onInitiate()
  {
    this.initiateForm.emit(true)
  }

  showInitiateButton()
  {
    this.showInitiate=true;
  }



  onDealTeamRowSelection(rowToStore: any) {
    this.dealTeamRowToStore.emit(rowToStore);
  }

  setDealTeamValues(dealTeamValues: PeoplepickerDynamicTableItem[]) {
    this.dealTeamValues = dealTeamValues;
  }

  getDealTeamRowsComplete(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.dealTeamValues) {
      this.dealTeamValues.forEach(row => {
        if (row && row.isComplete()) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }
  getDealTeamRowsComplete_CCL(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.dealTeamValues) {
      this.dealTeamValues.forEach(row => {
        if (row) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }
  setUpdateDealTeam(updateDealTeamTable:any) {
    this.updateDealTeamTable = updateDealTeamTable;
  }

  getUpdateDealTeam(): boolean {
    return this.updateDealTeamTable;
  }

  setItemPeoplePicker(itemId: number, index: number) {
    this.dealTeamComponent.setItemId(itemId, index);
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameCCL(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _setAsDisabled(formControlName: string) {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      const controlName = formGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}