
<!--btn-width class added for closing checklist form-->
<div class="btn-group btn-group-xs input-data-toggle-group input-toggle-group" 
[ngClass]="!notApplicableEnabled ? 'btn-group-two-options' : 'btn-group-three-options'"
role="group">
  <button
    type="button"
    class="btn btn-default btn-xs two-toggle-button"
    [ngClass]="{'toggle-button-active': value === 'YES','btn-width':isCompressWidth}"
    [class.readonly]="readonly"
    [disabled]="(disabled ||disabledYes) && !readonly"
    [value]="'YES'"
    (click)="toggleButtonActive('YES')">YES
  </button>
  <button
    type="button"
    class="btn btn-default btn-xs two-toggle-button"
    [ngClass]="{'toggle-button-active': value === 'NO','btn-width':isCompressWidth}"
    [class.readonly]="readonly"
    [disabled]="(disabled ||disabledNo) && !readonly"
    [value]="'NO'"
    (click)="toggleButtonActive('NO')">NO
    </button>
  <button *ngIf="notApplicableEnabled"
    type="button"
    class="btn btn-default btn-xs two-toggle-button"
    [ngClass]="{'toggle-button-active': value === 'N/A'||value === 'NA','btn-width':isCompressWidth}"
    [class.readonly]="readonly"
    [disabled]="disabled && !readonly"
    [value]="'N/A'"
    (click)="toggleButtonActive('N/A')">N/A
  </button>
</div>
