export class FormListFields {
  // Client Information
  static memberFirm = 'MemberFirm';
  static memberFirmContract = 'MemberFirmContract';
  static memberFirmReferenceID = 'MemberFirmReferenceID';
  static isThisCrossBorder = 'IsThisCrossBorder';
  static memberFirmClientID = 'MemberFirmClientID'

  // My Deal
  static clientName = 'ClientName';
  static clientID = 'ClientID';
  static engagementName = 'EngagementName';
  
  static startDate = 'StartDate';
  static endDate = 'EndDate';
  static isRecurringWBS = 'IsRecurringWBS';
  static deloitteUSEntity = 'DeloitteUSEntityId';
  static integratedMarketOffering = 'IntegratedMarketOffering';
  static salesOffice = 'SalesOffice';
  static engagementIndustrySICCode = 'EngagementIndustrySICCode';
  static isInternationalEngagement = 'IsInternationalEngagement';
  static areResourcesWorkOutside = 'AreResourcesWorkOutside';
  static memberFirmTaxablePresence = 'MemberFirmTaxablePresence';
  static otherMemberFirmsParticipating = 'OtherMemberFirmsParticipating';
  static whereWorkMemberFirms = 'WhereWorkMemberFirms';
  static serviceAtAddress  = 'ServiceAtAddress';
  static marketOffering  = 'MarketOffering';
  static isServiceSameAsClientAddress  = 'IsServiceSameAsClientAddress';
  static material = 'Material';
  static billingAddressSameClientAddress = 'BillingAddressSameClientAddress';
  static billingAddress = 'BillingAddress';
  static payerAddressSameClientAddress = 'PayerAddressSameClientAddress';
  static payerAddress = 'PayerAddress';
  static participatingPartner = 'Series24';

  // Engagement Information
  static modelAfterPriorEngagement = 'ModelAfterPriorEngagement';
  static priorEngagement = 'PriorEngagement';
  static engagementTypeName = 'EngagementTypeName';

  // Engagement Team
  static dealTeamTable = 'DealTeamTable';

  // WBS Element Classification
  static opportunityID = 'OpportunityID';

  // Invoice Details & Addresses
  //static memberFirmIdBillingAddress = 'MemberFirmIdBillingAddress';
  static billingContact = 'BillingContact';
  static emailAddress = 'EmailAddress';
  static isSubcontractingArrangement = 'IsSubcontractingArrangement';
  static printSecondBillingAddress = 'PrintSecondBillingAddress';
  static invoiceMedium = 'InvoiceMedium';
  static printClientReferenceNumber = 'PrintClientReferenceNumber';
  static allowInvoiceConsolidation = 'AllowInvoiceConsolidation';
  static billingCurrency = 'BillingCurrency';
  static invoiceLayout = 'InvoiceLayout';
  static printEngagementNumber = 'PrintEngagementNumber';
  static paymentTerms = 'PaymentTerms';
  static fxRate = 'FXRate';
  static fxRiskSharing = 'FXRiskSharing';
  static baselineFXRate = 'BaselineFXRate';

  // removed these sections as part of deal WBS feedback changes
  // // Master Engagement ID
  // static masterEngagementID = 'MasterEngagementID';
  // static financialPlanID = 'FinancialPlanID';

  // // Billing Schedule
  // static billingPlanType = 'BillingPlanType';
  // static billingIntervals = 'BillingIntervals';
  // static billingIfMilestone = 'BillingIfMilestone';
  // static billingFrom = 'BillingFrom';
  // static billingTo = 'BillingTo';

  // Archive/CMR
  static isEngagementAudit  = 'IsEngagementAudit';
  static currentAuditor = 'CurrentAuditor';
  static previousAuditor = 'PreviousAuditor';
  static isArchiveRequired = 'IsArchiveRequired';
  static wasCMRPerformed = 'WasCMRPerformed';

  // Pricing Details
  static contractType = 'ContractType';
  static resourceRateType = 'ResourceRateType';
  static usiResourceRateType = 'USIResourceRateType';
  static eventIndicator = 'EventIndicator';
  static budgetGroup = 'BudgetGroup';
  static plannedNetRevenue = 'PlannedNetRevenue';
  static discount = 'Discount';
  static plannedMargin = 'PlannedMargin';
  static plannedCost = 'PlannedCost';
  static totalPlannedHours = 'TotalPlannedHours';
  static timeRevenueCap = 'TimeRevenueCap';

  // Participation
  static participatingManager = 'ParticipatingManager';
  static derivedAtAddressSameClientAddres = 'DerivedAtAddressSameClientAddres';
  static derivedAtAddress = 'DerivedAtAddress';
  //static consultingMarket = 'ConsultingMarket';

  // Admin Review
  static wbsCode = 'WbsCode';
  static prdCode = 'PrdCode';

  // Documents
  static documentTypesAttachments = 'DocumentTypesAttachments';

}
