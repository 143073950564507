<!-- Below is for EHO Add New Form Popup -->
<div *ngIf="!isEHOCompliance" class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog " role="document">
        <div class="modal-content custom-content" style="background-color: white;" [ngStyle]="getModalStyles()" >
          
            <div [ngSwitch]="modalType">
              <div *ngSwitchCase="'add-new-form'" class="modal-title-header">
                Add new Form
                <button type="button" class="close closeEHO" aria-label="Close" (click)="closePopup()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          
          
            <div [ngSwitch]="modalType">
              <div *ngSwitchCase="'add-new-form'" class="add-new-form-modal">
                <div  *ngIf="availableForms">
                  <span class="modal-span">Available Forms:</span>
                  <ul>
                    <li *ngFor="let availableForm of modalAvailableFormsList; let i = index"
                      class="forms-list"
                      (click)="addFormToEngagement(availableForm)">
                        {{availableForm.availableFormTitle}}
                    </li> 
                  </ul>
                </div>
                <span class='modal-span' *ngIf="!availableForms">There are no more available Forms to add.</span>
              </div>
            </div>
          
            <!-- <div class="form-group">
                <div class="row">
                    <div class="col-md-5 header">
                        <div  class="modal-title-header"><label>{{headerMsg}}</label></div>
                    </div>

                </div>
                <div class="row">
                    <div class="row">
                            <div style="margin: 0% 4%; font-size: 15px; text-align: center;"><label>{{Msg}}</label></div>
                        <div class="row" style="margin-left: -4%;">
                            <div class="row">
                                <div *ngIf="isUserDelete" class="col-md-2 column no-Style" >
                                    <button type="button" class="btn custom-cancel mr" (click)="ConfirmToCancel()">
                                    {{UserDeleteCancelText}}
                                    </button>
                                </div>                            
                                <div *ngIf="isUserDelete" class="col-md-2 column yes-Style" >
                                    <button type="button" class="btn custom-add mr" (click)="ConfirmToDelete()">
                                        {{UserDeleteOkText}}
                                    </button>
                                </div>
                                </div>
                                <div *ngIf="!isUserDelete" class="col-md-2 column ok-Style">
                                    <button type="button" class="btn custom-add mr" (click)="closePopup()">
                                        {{cancelText}} 
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div> -->
            </div>
        </div>
      </div>

<!-- Below is for EHO Compliance Popup -->
<div *ngIf="isEHOCompliance" class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog " role="document">
          <div [ngClass]="'modal-content custom-new'"style="z-index: 2147483647;">
          <div class="">
              
              <button type="button" class="close close-size" (click)="closePopup()" data-dismiss="modal"
                  aria-label="Close"><span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="header">
              <div class="headerManagement"> Engagement Health - Compliance Status</div> 
            </div>
          <div class="form-group">
              <div class="row margins-top">
                <div [ngSwitch]="EHOCompliance">
                  <div *ngSwitchCase="'complianceModal'" class="body-container">
                    <div class="row" style="margin-left: 2%;">
                      <input
                        type="radio"
                        [value]="complianceStatusValues.NoIssues"
                        class="issues-radio-button"
                        [checked]="isChecked(complianceStatusValues.NoIssues)"
                        (change)="updateCurrentRadioSelected(complianceStatusValues.NoIssues)">
                      <span
                        [ngStyle]="{'color': complianceStatusColors.NoIssues}">
                        No issues found
                      </span>
                      <input
                        type="radio"
                        [value]="complianceStatusValues.Issues"
                        class="issues-radio-button"
                        [checked]="isChecked(complianceStatusValues.Issues)"
                        (change)="updateCurrentRadioSelected(complianceStatusValues.Issues)">
                      <span
                        [ngStyle]="{'color': complianceStatusColors.Issues}">
                        Issues found
                      </span>
                      <input
                        type="radio"
                        [value]="complianceStatusValues.NA"
                        class="issues-radio-button"
                        [checked]="isChecked(complianceStatusValues.NA)"
                        (change)="updateCurrentRadioSelected(complianceStatusValues.NA)">
                      <span
                        [ngStyle]="{'color': complianceStatusColors.NA}">
                        N/A
                      </span>
                    </div>
                    <div class="row" style="width: 97%;margin-left: 2%;">
                      <textarea
                        placeholder="Add issues here"
                        type="text"
                        rows="3"
                        class="text-area-style"
                        value="{{engagementObject.complianceComments}}"
                        [(ngModel)]="currentComments">
                      </textarea>
                    </div>
                  </div>
                  <div *ngSwitchCase="'dealTeamModal'" class="body-container">
                    <textarea
                      id="comments-compliance-status-for-deal-team"
                      type="text"
                      rows="3"
                      class="text-area-style textarea-readonly"
                      value="{{engagementObject.complianceComments}}"
                      [(ngModel)]="currentComments"
                      [class.readonly]="true"
                      disabled>
                    </textarea>
                  </div>
                </div>

                
              </div> 
              <div class="row" style="margin-left: 80%;">
              <div [ngSwitch]="EHOCompliance">
                <div *ngSwitchCase="'complianceModal'">
                  <button style="width: 92px;height: 40px;" class="btn btn-primary btn-ok" (click)="saveComplianceData();">Save</button>
                 
                </div>
                <div *ngSwitchCase="'dealTeamModal'">
                 
                </div>
              </div>
            </div>
                
          </div>


      </div>
      <div class="modal-backdrop in"></div>
  </div>
</div>
           
