import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AdminAction } from '../../newdeal/models/admin-action';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { FormValidations } from 'src/shared/utils/form-validations';
import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';
import { DatePipe } from '@angular/common';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-admin-review',
  templateUrl: './admin-review.component.html',
  styleUrls: ['./admin-review.component.scss'],
})
export class AdminReviewComponent implements OnInit {

  adminReviewFormGroup: FormGroup;
  myDealFormGroup: any;
  sharePointMapper = new NewDealSetupSharepointMapper();

  @Input() formGroup: FormGroup;

  @Input() formSubmitted: boolean;

  @Input() saveInProgress: boolean;

  @Input() saveButtonVisible: boolean;

  @Input() formGroups: Array<FormGroup>;

  @Output()
  adminAction: EventEmitter<string> = new EventEmitter<string>();

  adminApprovalName: string;
  adminApprovalDate: string;
  editable: boolean;

  constructor(private dateFormatterService: DateFormatterService, private datePipe: DatePipe, private dateService: DateService) { }

  ngOnInit() {
    this.adminReviewFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
  }

  adminApproval() {
    this.adminAction.emit(AdminAction.Approve);
  }

  adminReturnForRework() {
    this.adminAction.emit(AdminAction.Return);
  }

  adminDiscontinue() {
    this.adminAction.emit(AdminAction.Discontinue);
  }

  adminSave() {
    this.adminAction.emit(AdminAction.Save);
  }

  setAdminApprovalStatus(editable: boolean, adminApprovalDate: string, adminApprovalName: string) {
    this.adminApprovalName = adminApprovalName;
    this.adminApprovalDate = this._formatAdminReviewDate(adminApprovalDate);
    this.editable = editable;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }
  
  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  private _formatAdminReviewDate(adminApprovalDate: string): string | any {
    if (adminApprovalDate) {
      const dateObject = this.dateFormatterService.formatDateOnRetrieve(adminApprovalDate);
       return `${this.dateFormatterService.formatDateToCDT(dateObject)} CST`;
      //return `${this.dateService.FormatDate(dateObject.toString())}`;
    }
    return '';
  }
}
