import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditService } from '../services/audit.service';
import { AdminService } from '../services/admin.service';
import { DateService } from 'src/app/services/date.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { CommonHelperService } from '../commonHelper/common-helper.service';
import { GlobalHelperService } from '../globalHelper/global-helper.service';
@Component({
  selector: 'app-crosbocrossborderform',
  templateUrl: './crosbocrossborderform.component.html',
  styleUrls: ['./crosbocrossborderform.component.scss']
})
export class CrosbocrossborderformComponent implements OnInit {
  //analyticsDataLayer:any;
  constructor(private router:Router,public globalHelperService:GlobalHelperService,private activatedRoute:ActivatedRoute,private auditService:AuditService,private adminService:AdminService,public dateService:DateService,private commonHelperService:CommonHelperService) { }
  showitem:any;
  formID:any;
  isAttachmentAuditDisbaled:any=false;
  auditAttachmentExportToExcel: Array<any> = [];
  auditAttachmentData: Array<any> = [];
  ngOnInit(): void {
    localStorage.setItem("CurrentAccessPage","CrossBorderForm");
    this.globalHelperService.currentAccessPage="CrossBorderForm";
    this.ApplyBrowserScroll();
    if(localStorage.getItem("fromtoken")=="true")
    {
      window.location.reload();
    }
    const url = window.location.href;
    this.formID=url.includes("?")?url.split('?')[1]?.split("=")[1]:"";
    this.globalHelperService.formID=this.formID.toString();
  }
  ApplyBrowserScroll(){
    document.body.style.overflow="auto";
  }
  auditCrossBorderAttachment(event:any){
    this.auditService.GetAttachmentAuditDetails(this.formID).subscribe(
      {
        next: (result: any) => {
          result.forEach((e:any) => {
            e.CreatedOn=this.dateService._AuditFormatDate(e.CreatedOn);
          });
          this.auditAttachmentData=result;
          this.AuditDataValidation(this.auditAttachmentData);
          this.LoadAuditDataInExcel(this.auditAttachmentExportToExcel);   
        },
        error: (err: any) => {
          this.commonHelperService.hideSpinner();
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          console.log("Error" + err)
        }
      })
  }
  AuditDataValidation(res:any){
    this.auditAttachmentExportToExcel = [];  
    res.forEach((v:any)=>{
    let data=this.LoadDataIntoActualObject(v);
    this.auditAttachmentExportToExcel.push(data);
    });  
  }

  LoadDataIntoActualObject(data:any){
  let auditObj={};
  let cstCreatedDate=data.CreatedOn;
  let DateAndTime=cstCreatedDate.split(' ');
 return auditObj={
    FormID:data.FormID,
    ProjectName:data.ProjectName,
    DocumentType:data.DocumentType,
    Action:data.Action,
    RelatedAttachmentorAction:data.ActionDetails,
    UserName:data.UserName,
    Email:data.Email,
    Date:DateAndTime[1]+"/"+DateAndTime[0]+"/"+DateAndTime[2],
    Time:DateAndTime[3]+" "+DateAndTime[4]+" "+DateAndTime[5]
  }
  }
  LoadAuditDataInExcel(jsonData:any[]){
      if(jsonData.length > 0)
      {
        let projectName = jsonData[0].ProjectName.trim();
        jsonData = jsonData.map(doc => Object.values(doc));
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
        worksheet['A1'].v = "Form ID";
        worksheet['B1'].v = "Project Name";
        worksheet['C1'].v = "Document Type";
        worksheet['D1'].v = "Action";
        worksheet['E1'].v = "Related attachment or action";
        worksheet['F1'].v = "User Name";
        worksheet['G1'].v = "Email";
        worksheet['H1'].v = "Date";   
        worksheet['I1'].v = "Time";
      
        const workbook: XLSX.WorkBook = { Sheets: { 'Audit trail': worksheet }, SheetNames: ['Audit trail'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
        FileSaver.saveAs(data, projectName +"-Audit trail.xlsx");
      }
  }
}
