import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { AdminAction } from '../models/admin-action';
import { FormValidations } from 'src/shared/utils/form-validations';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormListFields } from '../models/form-list-fields';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-admin-review',
  templateUrl: './admin-review.component.html',
  styleUrls: ['./admin-review.component.scss']
})
export class AdminReviewComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();

  @Input() formGroup: FormGroup;

  @Input() formSubmitted: boolean;

  @Input() saveInProgress: boolean;

  @Input() saveButtonVisible: boolean;

  @Output()
  adminAction: EventEmitter<string> = new EventEmitter<string>();

  adminApprovalName: string;
  adminApprovalDate: string;
  editable: boolean;

  adminReviewFormGroup: FormGroup;
  myDealFormGroup: FormGroup;

  constructor(private dateFormatterService: DateFormatterService,private dateService: DateService) { }

  ngOnInit() {}

  adminApproval() {
    this.adminAction.emit(AdminAction.Approve);
  }

  adminReturnForRework() {
    this.adminAction.emit(AdminAction.Return);
  }

  adminDiscontinue() {
    this.adminAction.emit(AdminAction.Discontinue);
  }

  adminSave() {
    this.adminAction.emit(AdminAction.Save);
  }

  setAdminApprovalStatus(editable: boolean, adminApprovalDate: string, adminApprovalName: string) {
    this.adminApprovalName = adminApprovalName;
    this.adminApprovalDate = this._formatAdminReviewDate(adminApprovalDate);
    this.editable = editable;
  }

  isReadOnly(formControlName: string): boolean {
    return FormValidations.isReadOnly(this.formGroup, formControlName);
  }

  showAsterisk(formControlName: string): boolean {
    return FormValidations.showAsterisk(this.formGroup, formControlName);
  }

  highlightRequiredField(formControlName: string): boolean {
    return FormValidations.highlightRequiredField(this.formSubmitted, this.formGroup, formControlName);
  }

  editedExternally(formControlName: string): boolean {
    return FormValidations.editedExternally(this.formGroup, formControlName);
  }

  hasMergeConflict(formControlName: string): boolean {
    return FormValidations.hasMergeConflict(this.formGroup, formControlName);
  }

  private _formatAdminReviewDate(adminApprovalDate: string): string | any {
    if (adminApprovalDate) {
      let dateObject = new Date(adminApprovalDate);
      dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,adminApprovalDate);
      return `${this.dateFormatterService.formatDateToCST(dateObject)} CST`;
      // const dateObject = this.dateFormatterService.formatDateOnRetrieve(adminApprovalDate);
      // return `${this.dateService._FormatDateEHO(dateObject.toString())}`;
    }
    return '';
  }
}
