import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';
import { FormValidations } from '../../../shared/utils/form-validations';
import { SecurityService } from '../../../shared/services/security.service';
import { FormListFields } from '../shared/models/form-list-fields';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-books-and-records',
  templateUrl: './books-and-records.component.html',
  styleUrls: ['./books-and-records.component.scss']
})
export class BooksAndRecordsComponent implements OnInit {
  sharePointMapper = new ComplianceSharepointMapper();
  booksAndRecordsFormGroup: any;

  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;

  SubmissionUser:string;
  SubmissionDate:string;
  SubmissionSeries24Date:string;
  SubmissionSeries24User:string;

  showSubmitUser:boolean;
  showSeries24User:boolean;
  notApplicableEnabled:boolean=true; //Added

  constructor(private securityService: SecurityService,private datePipe: DatePipe,private dateFormatterService: DateFormatterService,private dateService: DateService) { }

  ngOnInit() {
    this.booksAndRecordsFormGroup = this.formGroups[0];
  }

  // Public Standart Functions

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }


  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  // Custom functions for section

  onPitchBookSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.pitchBook.setValue(event);
  }

  onCopyFinalBriefingPaperSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.copyFinalBriefingPaper.setValue(event);
  }

  onExecutedEngagementLetterSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.executedEngagementLetter.setValue(event);
  }

  onAmendmentsEngagementLetterSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.amendmentsEngagementLetter.setValue(event);
  }

  onFullyExecutedIFASelection(event:any) {
    this.booksAndRecordsFormGroup.controls.fullyExecutedIFA.setValue(event);
  }

  onPitchProposalSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.pitchProposal.setValue(event);
  }

  onTeaserBooksSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.teaserBooks.setValue(event);
  }

  onCimOfferingMemorandumSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.cimOfferingMemorandum.setValue(event);
  }

  onManagementPresentationSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.managementPresentation.setValue(event);
  }

  onClientLetterMarketingPressSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.clientLetterMarketingPress.setValue(event);
  }

  onTombstoneBooksSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.tombstoneBooks.setValue(event);
  }

  onPressReleaseBooksSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.pressReleaseBooks.setValue(event);
  }

  onCaseStudiesBooksSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.caseStudiesBooks.setValue(event);
  }

  onLeagueTablesSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.leagueTables.setValue(event);
  }

  onListProspectiveInvestorsBuyersSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.listProspectiveInvestorsBuyers.setValue(event);
  }

  onListActualInvestorsBuyersSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.listActualInvestorsBuyers.setValue(event);
  }

  onExpressionOfInterestLettersOfIntentSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.expressionOfInterestLettersInt.setValue(event);
  }

  onContainsAllTransactionDocumentsSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.containsAllTransactionDocuments.setValue(event);
  }

  onDidProjectGoICSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.didProjectGoIC.setValue(event);
    this.evaluateDidProjectGoICSelection(event);
  }

  evaluateDidProjectGoICSelection(value: string) {
    if (value === 'NO') {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.dateICReviewApproval));
      this.booksAndRecordsFormGroup.controls.ifNoExplain.enable();
    } else if (value === 'YES') {
      this.booksAndRecordsFormGroup.controls.dateICReviewApproval.enable();
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.ifNoExplain));
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.ifNoExplain));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.dateICReviewApproval));
    }
  }
  evaluateComplianceFormUserDetails(complianceSubmissionUser: string,complianceSubmissionDate:string,SubmissionSeries24User:string,SubmissionSeries24Date:string) {
   
    if(complianceSubmissionUser!=undefined && complianceSubmissionUser!="")
    {
    this.SubmissionUser=complianceSubmissionUser;
    this.SubmissionDate=
    this._formatAdminReviewDate(
      complianceSubmissionDate
    );
    this.showSubmitUser=true;
    }
    if(SubmissionSeries24User!=undefined && SubmissionSeries24User!="")
    {
      this.showSeries24User=true;
      this.SubmissionSeries24User=SubmissionSeries24User;
      this.SubmissionSeries24Date=this._formatAdminReviewDate(
        SubmissionSeries24Date
      );
    }

  }

  private _formatAdminReviewDate(approvalDate: string): string {
    let dateObject = new Date(approvalDate);
    dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,approvalDate);
    return this.datePipe.transform(dateObject, "dd MMM y hh:mm a") + " CST";
    // const dateObject = this.dateFormatterService.formatDateOnRetrieve(approvalDate);
    // return `${this.dateService._FormatDateCCL(dateObject.toString())}`;
  }
  

  onDateICReviewApprovalSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.dateICReviewApproval.setValue(event);
  }

  onZipFileSentSelection(event:any) {
    this.booksAndRecordsFormGroup.controls.zipFileSent.setValue(event);
  }

  // Private Standart Functions

  private _setAsDisabled(formControlName: string) {
    const controlName = this.booksAndRecordsFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
