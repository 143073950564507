 export class DefaultFieldsValues {
  static CrossBusinessOffering = 'DCF M&A Transaction';
  static IntegratedMarketOffering = 'M&A Transaction CFMO';
  static OpportunityPhase = 'Request to Propose';
  static TotalValue = '750000';
  static EngagementType = 'CLIENT ENGAGEMENT WITH PURSUIT (PRD)';
  static Material = 'Management Consulting';
  static Initiative = 'N/A';
  static Category = 'Mergers, Acquisitions & Divestitures';
  static SubCategory = 'MA&D - Corporate Finance';
  static AllianceProduct = 'None';
  static DeloitteUSEntityClientId = 37;
  static DeloitteUSEntityClient = "Deloitte CF LLC";//Added to restrict the default value for Autosave for Empty Forms
  static DeloitteUSEntityId = 37;
  static FinancialYearEnd = new Date(new Date().getFullYear(), 11, 31).toDateString();
  static IsThisAFollowOnOpportunity = 'NO';
  static IsThisARecurringOpportunity = 'NO';
  static Subcontractor = 'None';
  static IsRecurringWBS = 'NO';
  static IsServiceSameAsClientAddress = 'YES';
  static BillingAddressSameClientAddress = 'YES';
  static PayerAddressSameClientAddress = 'YES';

  
}
