import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { AppSettings } from 'src/shared/app-settings';
import { FormListFields } from '../../models/form-list-fields';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { FormValidations } from 'src/shared/utils/form-validations';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { MarketOfferingMap } from 'src/shared/utils/market-offering-map';
import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';

@Component({
  selector: 'app-mercury-opportunity',
  templateUrl: './mercury-opportunity.component.html',
})
export class MercuryOpportunityComponent implements OnInit {
  // Dropdown lists
  maConfidentialSubsidiaryOptions: Array<DropdownItem> = [];
  engagementIndustrySectorOptions: Array<DropdownItem> = [];
  buyerRoleOptions: Array<DropdownItem> = [];
  feeTypeOptions: Array<DropdownItem> = [];
  categoryOptions: Array<DropdownItem> = [];
  subCategoryOptions: Array<DropdownItem> = [];
  offeringOptions: Array<DropdownItem> = [];
  competitorsOptions: Array<DropdownItem> = [];
  memberFirmRecognizingRevenueOptions: Array<DropdownItem> = [];
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];
  sharePointMapper = new NewDealSetupSharepointMapper();
  mercuryOportunityFormGroup: FormGroup;
  myDealFormGroup: FormGroup;

  @Input() formGroups: Array<FormGroup>;
  @Input() formSubmitted: boolean;

  @Output() recurringOpportunityChange = new EventEmitter<string>();
  @Output() offeringChange = new EventEmitter<string>();
  @Output() buyerNameChange = new EventEmitter<string>();

  constructor(
    private dropdownService: DropdownService,
    private tooltipHelperService: TooltipHelperService,
    private marketOfferingMap: MarketOfferingMap) { }

  ngOnInit() {
    this.mercuryOportunityFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
    this.maConfidentialSubsidiaryOptions = AppSettings.M_AConfidentialSubsidiaryListArray;
    //this.buyerRoleOptions = AppSettings.;
    this.feeTypeOptions = AppSettings.FeeTypeListArray;
    this.categoryOptions = AppSettings.CategoryListArray;
    this.subCategoryOptions = AppSettings.SubCategoryListArray;
    this.offeringOptions = AppSettings.OfferingListArray;
    this.memberFirmRecognizingRevenueOptions = AppSettings.MemberFirmRevenueListArray;
    this.engagementIndustrySectorOptions = AppSettings.EngagementIndustrySectorListArray;
  
  }

  setSharepointMapper(mapper: NewDealSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  onMaConfidentialSubsidiarySelection(event:any) {
    this.mercuryOportunityFormGroup.controls.maConfidentialSubsidiary.setValue(event.value);
  }

  onBuyerRoleSelection(event:any) {
    this.mercuryOportunityFormGroup.controls.buyerRole.setValue(event.value);
  }

  onFeeTypeSelection(event:any) {
    this.myDealFormGroup.controls.feeType.setValue(event.value);
  }

  onCategorySelection(event:any) {
    this.mercuryOportunityFormGroup.controls.category.setValue(event.value);
  }

  onSubCategorySelection(event:any) {
    this.mercuryOportunityFormGroup.controls.subCategory.setValue(event.value);
  }

  onOfferingSelection(event:any) {
    const valueMapped = this.marketOfferingMap.getMarketOfferingValueMapped(event.value);
    this.mercuryOportunityFormGroup.controls.offering.setValue(event.value);
    this.offeringChange.emit(valueMapped);
  }

  onMemberFirmRecognizingRevenueSelection(event:any) {
    this.mercuryOportunityFormGroup.controls.memberFirmRecognizingRevenue.setValue(event.value);
  }

  ontotalValueChange(event:any) {
    this.mercuryOportunityFormGroup.controls.totalValue.setValue(event);
  }

  // onOriginalOpportunityIDChange(event) {
  //   this.mercuryOportunityFormGroup.controls.originalOpportunityID.setValue(event);
  // }

  // onIsThisAReproposalSelection(event) {
  //   this.mercuryOportunityFormGroup.controls.isThisAReproposal.setValue(event);
  // }

  onIsThisARecurringOpportunitySelection(event:any) {
    this.mercuryOportunityFormGroup.controls.isThisARecurringOpportunity.setValue(event);
    this.evaluateIsThisARecurringOpportunityDependency(event);
    this.recurringOpportunityChange.emit(event);
  }

  // onIsThisAPullThroughOpportunitySelection(event) {
  //   this.mercuryOportunityFormGroup.controls.isThisAPullThroughOpportunity.setValue(event);
  //   this.evaluateIsThisAPullThroughOpportunityDependency(event);
  // }

  onIsThisAFollowOnOpportunitySelection(event:any) {
    this.mercuryOportunityFormGroup.controls.isThisAFollowOnOpportunity.setValue(event);
  }

  oncovid19RelatedNewOpportunity(event:any)  {
    this.mercuryOportunityFormGroup.controls.covid19RelatedNewOpportunity.setValue(event);
  }

  onBuyerNameChange(event:any) {
    this.buyerNameChange.emit(event.target.value);
    this.myDealFormGroup.controls.buyerNameClient.setValue(event.target.value);
    this.myDealFormGroup.controls.buyerName.setValue(event.target.value);
  }

  onIsIndiaInvolvedSelection(event:any) {
    this.mercuryOportunityFormGroup.controls.isIndiaInvolved.setValue(event);
  }
  
  onIsPriorDESattendeeSelection(event:any) {
    this.mercuryOportunityFormGroup.controls.isPriorDESattendee.setValue(event);
  }
  
  onEngagementIndustrySectorSelection(event:any) {
    this.mercuryOportunityFormGroup.controls.engagementIndustrySector.setValue(event.value);
  }

  onIsAlumniInvolvedSelection(event:any) {
    this.mercuryOportunityFormGroup.controls.isDeloitteAlumniInvolved.setValue(event);
    this.evaluateIsDeloitteAlumniDependency(event);
  }

  evaluateIsDeloitteAlumniDependency(value:any) {
    if (value === 'YES') {
      this.mercuryOportunityFormGroup.controls.deloitteAlumniName.enable();
      this.mercuryOportunityFormGroup.controls.deloitteAlumniRole.enable();
      if((this.mercuryOportunityFormGroup.get('isDeloitteAlumniInvolved')as CustomFormControl).isReadOnly()){
        (this.mercuryOportunityFormGroup.controls.deloitteAlumniName as CustomFormControl).setReadOnly();
        (this.mercuryOportunityFormGroup.controls.deloitteAlumniRole as CustomFormControl).setReadOnly();
      }
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.deloitteAlumniName));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.deloitteAlumniRole));
    }
  }

  myDealeventDateSelected(event:any, formControlName: string) {
    this.myDealFormGroup.get(formControlName)?.setValue(event);
  }

  eventDateSelected(event:any, formControlName: string) {
    this.mercuryOportunityFormGroup.get(formControlName)?.setValue(event);
  }

  evaluateIsThisARecurringOpportunityDependency(value: string) {
    if (value === 'YES') {
      this.mercuryOportunityFormGroup.controls.annualOpportunity?.enable();
      if((this.mercuryOportunityFormGroup.get('isThisARecurringOpportunity')as CustomFormControl).isReadOnly()){
        (this.mercuryOportunityFormGroup.controls.isThisARecurringOpportunity as CustomFormControl).setReadOnly();
      }
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.annualOpportunity));
    }
  }

  evaluateIsThisAPullThroughOpportunityDependency(value: string) {
    if (value === 'YES') {
      this.mercuryOportunityFormGroup.controls.originalOpportunityID?.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.originalOpportunityID));
    }
  }

  showDealTeamRowsComplete(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    this.dealTeamValues.forEach(row => {
     if (row && row.isComplete_DealWbs()) {
      if (row&&row.itemId>0) {
        rowsCompleted.push(row);
      }
    }
    });
    return rowsCompleted;
  }

  setDealTeamValues(dealTeamValues: PeoplepickerDynamicTableItem[]) {
    this.dealTeamValues = dealTeamValues;
  }
  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldName(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _setAsDisabled(formControlName: string) {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      const controlName = formGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
