import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { UserManagementItem } from 'src/app/models/userManagementItem';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';

@Component({
  selector: 'app-add-user-popup',
  templateUrl: './add-user-popup.component.html',
  styleUrls: ['./add-user-popup.component.scss']
})
export class AddUserPopupComponent implements OnInit {

  displayStyle = "none";
  selectedUser=[];
  btnType:any="";
  fullName:any="dasari,venu";
  permissions:any=[];
  isUserChecked:any=false;
  @Input() initialUserManagementList: Array<UserManagementItem> = [];
  @Input() formTypeValue:any="";
  @Input() formTypeID:any="";
  @Output()  addUserDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  CloseUserDetails:EventEmitter<any>=new EventEmitter<any>();
  @ViewChild(NotifyPopupComponent, { static: true }) notifypopupComponent: NotifyPopupComponent;
  
  constructor(public adminService:AdminService, public commonHelperService:CommonHelperService, private spinnerService:SpinnerService) { }

  ngOnInit(): void {
    
  }

  openPopup(actionType:any) {  
    this.btnType=actionType;    
    this.displayStyle = "block";
   }
   closePopup() {    
    this.CloseUserDetails.emit("true");
     this.displayStyle = "none";
     this.isUserChecked=false;
   }
   onSelectedPeoplePickerUser(event:any) { 
    this.selectedUser=event;    
  }
  addUser(){
    if(this.isValidRecord()){
      this.addUserDetails.emit(this.selectedUser);
      this.displayStyle = "none";
      this.selectedUser=[];
   }
  }
  isValidRecord(){
    this.spinnerService.showAutoSaveSpinner();
    let isValid=false;
    if(this.selectedUser.length>0){
      var isUserExist= this.validateUser(this.selectedUser);
      if(!isUserExist){
        isValid=true;
      }
      else{
        this.spinnerService.hideAutoSaveSpinner();
        this.notifypopupComponent.openPopup("Admin","Warning");
      }
    }
    else{
      this.spinnerService.hideAutoSaveSpinner();
      this.notifypopupComponent.openPopup('Admin','Info');
    }
    return isValid;

   }
   validateUser(event:any){
    let user_Flag=false;
    event.forEach((e:any)=>{
      if(e.mail!=null&&e.mail!=undefined&&e.mail!=""){
        this.initialUserManagementList.forEach(function(key,value){
        if(key.Email==e.mail){
          user_Flag=true;
        }
        });   
       }
        return user_Flag;
    })
    return user_Flag;
  };
  //Check Permissions
  checkPermissions(){
   if(this.selectedUser.length>0){
    //this.permissions=[];
    this.GetUsersListsInGroups(this.selectedUser);  
   }
   else{
     this.notifypopupComponent.openPopup("Admin","PermissionInfo");
     this.selectedUser=[];
   }
   
   }
   restrictDuplicateRecords(resData:any){
    return [...new Map(resData.map((item:any) => [item["Role"], item])).values()];
   }
  GetUsersListsInGroups(user:any){
    this.fullName= user[0]?.displayName?.split(/,/); 
    this.adminService.GetUserRoleGroupsForPermission(user[0]?.mail,this.formTypeID).subscribe(
      {
        next: (result: any) => {
          result=result.filter((s:any)=>s.Title!=null);
          result=this.restrictDuplicateRecords(result);
          result.sort((a:any, b:any) => (a.Role < b.Role ? -1 : 1));
           this.permissions=result;  
           this.isUserChecked=true;
           this.selectedUser=[];
        },
        error: (err: any) => {
          this.selectedUser=[];
          this.spinnerService.hideAutoSaveSpinner();
          this.notifypopupComponent.openPopup("Admin","PermissionError")
          console.log("Error" + err)
        }
      })
  }
   
}
