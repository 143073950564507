import { DropdownItem } from './dropdown-item';
import { FreeTextItem } from './free-text-item';

export class FreeTextTableItem {
  freeTextOptionOne: FreeTextItem = new FreeTextItem();
  optionsSelectedFirstColumn: DropdownItem = new DropdownItem();
  freeTextOptionTwo: FreeTextItem = new FreeTextItem();
  optionsSelectedSecondColumn: DropdownItem = new DropdownItem();
  index: number;
  itemId: number;

  constructor() { }
isCompleteForDttl(){
    if(!!this.freeTextOptionOne.name && !!this.freeTextOptionTwo.name)
    {return true}

return false
  }
isComplete(): boolean {
  if(!!this.optionsSelectedFirstColumn.value && !!this.optionsSelectedSecondColumn.value && !!this.freeTextOptionOne.name && !!this.freeTextOptionTwo.name){
    return true;
  }

    return false;
  }


  setOptionFirstColumn(item: DropdownItem) {
    this.optionsSelectedFirstColumn = item;
  }

  setOptionSecondColumn(item: DropdownItem) {
    this.optionsSelectedSecondColumn = item;
  }

  setIndex(index:any) {
    this.index = index;
  }

  getIndex() {
    return this.index;
  }

  setItemId(itemId:any) {
    this.itemId = itemId;
  }

  getItemId() {
    return this.itemId;
  }
}
