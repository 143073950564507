<div id="deal-structure" [formGroup]="dealStructureFormGroup" class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
    <span>Deal Structure</span>
    <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
    <span class="tooltiptext-dcf visibility-hidden">
      <p>A few explanations below. If there are unique circumstances, just leave a note.</p>
      <p>
        <span class="tooltiptext-dcf-bold">
          Trigger Type
        </span>
        - How is the contingent payment triggered? Could just be contingent on continued employment or in
        the case of an earnout, what thresholds that have to be met (i.e. revenue, EBITDA).
      </p>
      <p>
        <span class="tooltiptext-dcf-bold">
          Rollover Equity
        </span>
        - Rollover is structured differently, so fill in what you can. Please at least try to give rollover a % of Total
        Newcomb Equity and also either Pre-Tax or After-Tax Proceeds. If multiple members of the management team are rolling
        different percentages, just take an approximate average or use the figure for key management.
      </p>
      <p>
        <span class="tooltiptext-dcf-bold">
          Management Fee
        </span>
        - Fill in either the $ or % as relevant.
      </p>
    </span>
  </div>
  <div id="deal-structure-section" class="content-section">
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('legalStructure')"
              [class.edited-externally]="editedExternally('legalStructure')"
              [class.conflict-on-merge]="hasMergeConflict('legalStructure')">Legal Structure</span>
        <span *ngIf="showAsterisk('legalStructure')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <!-- <select name="LegalStructure" id="LegalStructure" class="input-data-select input-select section-select">
            <option value="0" disabled selected>Select your option</option>
        </select> -->
        <app-dropdown [optionsList]="legalStructureOptions"
                      [keySelected]="dealStructureFormGroup.get('legalStructure')?.value"
                      [readonly]="isReadOnly('legalStructure')"
                      (optionSelected)="onLegalStructureeSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('contingentTotalEVPct')"
              [class.edited-externally]="editedExternally('contingentTotalEVPct')"
              [class.conflict-on-merge]="hasMergeConflict('contingentTotalEVPct')">Contingent as % of Total EV</span>
        <span *ngIf="showAsterisk('contingentTotalEVPct')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="ContingentTotalEVPct" name="ContingentTotalEVPct" class="input-data-number input-number percentage-input" formula="( ContingentConsideration ) / ( TotalConsideration )" format="percentage" disabled><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [readonly]="isReadOnly('contingentTotalEVPct')"
                     [placeholder]="'N/A'"
                     [value]="dealStructureFormGroup.get('contingentTotalEVPct')?.value"
                     [disabled]="dealStructureFormGroup.get('contingentTotalEVPct')?.disabled">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('timeUntilContingentPayment')"
              [class.edited-externally]="editedExternally('timeUntilContingentPayment')"
              [class.conflict-on-merge]="hasMergeConflict('timeUntilContingentPayment')">Time Until Contingent Payment</span>
        <span *ngIf="showAsterisk('timeUntilContingentPayment')" class="red-asterik"></span>

        <app-popover [tooltipText]="getTooltipText('timeUntilContingentPayment')">
        </app-popover>


      </div>
      <div class="col-xs-8">
        <!-- <input class="input-data-text full-width section-input" id="TimeUntilContingentPayment" name="TimeUntilContingentPayment" type="text" placeholder="N/A" disabled> -->
        <input class="input-data-text full-width section-input"
               [formControl]="dealStructureFormGroup.get('timeUntilContingentPayment')"
               type="text"
               [placeholder]="dealStructureFormGroup.get('timeUntilContingentPayment')?.disabled ? 'N/A' : ''"
               [class.readonly]="isReadOnly('timeUntilContingentPayment')"
               [disabled]="dealStructureFormGroup.get('timeUntilContingentPayment')?.disabled"
               autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('triggerType')"
              [class.edited-externally]="editedExternally('triggerType')"
              [class.conflict-on-merge]="hasMergeConflict('triggerType')">Trigger Type</span>
        <span *ngIf="showAsterisk('triggerType')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('triggerType')">
        </app-popover>

      </div>
      <div class="col-xs-8">
        <!-- <input class="input-data-text full-width section-input" id="TriggerType" name="TriggerType" type="text" placeholder="N/A" disabled> -->
        <input class="input-data-text full-width section-input"
               [formControl]="dealStructureFormGroup.get('triggerType')"
               type="text"
               [placeholder]="dealStructureFormGroup.get('triggerType')?.disabled ? 'N/A' : ''"
               [class.readonly]="isReadOnly('triggerType')"
               [disabled]="dealStructureFormGroup.get('triggerType')?.disabled"
               autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 section-title"><span><b>Strategic Buyers</b></span></div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('stockConsideration')"
              [class.edited-externally]="editedExternally('stockConsideration')"
              [class.conflict-on-merge]="hasMergeConflict('stockConsideration')">Stock Consideration ($)</span>
        <span *ngIf="showAsterisk('stockConsideration')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('stockConsideration')">
        </app-popover>

      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="StockConsideration" name="StockConsideration" class="input-data-number input-number non-decimal-input section-input" operations="StockConsiderationTotalEVPct" disabled placeholder="N/A"><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'currency'"
                     [readonly]="isReadOnly('stockConsideration')"
                     [value]="dealStructureFormGroup.get('stockConsideration')?.value"
                     [disabled]="dealStructureFormGroup.get('stockConsideration')?.disabled"
                     [placeholder]="dealStructureFormGroup.get('stockConsideration')?.disabled ?'N/A':''"
                     (selectedValue)="onStockConsideration($event,'stockConsideration')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('stockConsiderationTotalEVPct')"
              [class.edited-externally]="editedExternally('stockConsiderationTotalEVPct')"
              [class.conflict-on-merge]="hasMergeConflict('stockConsiderationTotalEVPct')">Stock Consideration (% of Total EV) ($000s)</span>
        <span *ngIf="showAsterisk('stockConsiderationTotalEVPct')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <!-- <input
            type="text"
            id="StockConsiderationTotalEVPct"
            name="StockConsiderationTotalEVPct"
            class="input-data-number input-number percentage-input"
            formula="( StockConsideration ) / ( TotalConsideration ) * ( 1000 )"
            format="percentage"
            disabled>
        <br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [placeholder]="'N/A'"
                     [readonly]="isReadOnly('stockConsiderationTotalEVPct')"
                     [value]="dealStructureFormGroup.get('stockConsiderationTotalEVPct')?.value"
                     [disabled]="dealStructureFormGroup.get('stockConsiderationTotalEVPct')?.disabled">
        </app-numeric>

      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 section-title"><span><b>PE Buyers</b></span></div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('rolloverEquity')"
              [class.edited-externally]="editedExternally('rolloverEquity')"
              [class.conflict-on-merge]="hasMergeConflict('rolloverEquity')">Rollover Equity (% of Total NewCo Equity)</span>
        <span *ngIf="showAsterisk('rolloverEquity')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="RolloverEquity" name="RolloverEquity" class="input-data-number input-number percentage-input" operations=""><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [readonly]="isReadOnly('rolloverEquity')"
                     [value]="dealStructureFormGroup.get('rolloverEquity')?.value"
                     (selectedValue)="onSelectedValue($event,'rolloverEquity')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('rolloverPreTaxPct')"
              [class.edited-externally]="editedExternally('rolloverPreTaxPct')"
              [class.conflict-on-merge]="hasMergeConflict('rolloverPreTaxPct')">Rollover (% of Pre-Tax Proceeds)</span>
        <span *ngIf="showAsterisk('rolloverPreTaxPct')" class="red-asterik"></span>

        <app-popover [tooltipText]="getTooltipText('rolloverPreTaxPct')">
        </app-popover>

      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="RolloverPreTaxPct" name="RolloverPreTaxPct" class="input-data-number input-number percentage-input" operations=""><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [readonly]="isReadOnly('rolloverPreTaxPct')"
                     [value]="dealStructureFormGroup.get('rolloverPreTaxPct')?.value"
                     (selectedValue)="onSelectedValue($event,'rolloverPreTaxPct')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('rolloverAfterTaxPct')"
              [class.edited-externally]="editedExternally('rolloverAfterTaxPct')"
              [class.conflict-on-merge]="hasMergeConflict('rolloverAfterTaxPct')">Rollover (% of After-Tax Proceeds)</span>
        <span *ngIf="showAsterisk('rolloverAfterTaxPct')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('rolloverAfterTaxPct')">
        </app-popover>

      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="RolloverAfterTaxPct" name="RolloverAfterTaxPct" class="input-data-number input-number percentage-input" operations=""><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [readonly]="isReadOnly('rolloverAfterTaxPct')"
                     [value]="dealStructureFormGroup.get('rolloverAfterTaxPct')?.value"
                     (selectedValue)="onSelectedValue($event,'rolloverAfterTaxPct')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('managementOptionPool')"
              [class.edited-externally]="editedExternally('managementOptionPool')"
              [class.conflict-on-merge]="hasMergeConflict('managementOptionPool')">Management Option Pool (%)</span>
        <span *ngIf="showAsterisk('managementOptionPool')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('managementOptionPool')">
        </app-popover>

      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="ManagementOptionPool" name="ManagementOptionPool" class="input-data-number input-number percentage-input section-input" operations=""><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [readonly]="isReadOnly('managementOptionPool')"
                     [value]="dealStructureFormGroup.get('managementOptionPool')?.value"
                     (selectedValue)="onSelectedValue($event,'managementOptionPool')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('sponsorTransactionClosingFee')"
              [class.edited-externally]="editedExternally('sponsorTransactionClosingFee')"
              [class.conflict-on-merge]="hasMergeConflict('sponsorTransactionClosingFee')">Sponsor Transaction/Closing Fee (%)</span>
        <span *ngIf="showAsterisk('sponsorTransactionClosingFee')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('sponsorTransactionClosingFee')">
        </app-popover>
        <!-- <span class="glyphicon glyphicon-question-sign section-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">Enter 0% if PEG deal with no closing fee. Enter N/A for strategic deal.</span> -->
      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="SponsorTransactionClosingFee" name="SponsorTransactionClosingFee" class="input-data-number input-number percentage-input" operations=""><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [readonly]="isReadOnly('sponsorTransactionClosingFee')"
                     [value]="dealStructureFormGroup.get('sponsorTransactionClosingFee')?.value"
                     (selectedValue)="onSelectedValue($event,'sponsorTransactionClosingFee')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('managementFee')"
              [class.edited-externally]="editedExternally('managementFee')"
              [class.conflict-on-merge]="hasMergeConflict('managementFee')">Management Fee?</span>
        <span *ngIf="showAsterisk('managementFee')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <!-- <select name="ManagementFee" id="ManagementFee" class="input-data-select input-select section-select enableDependencyInput"
            disable-target-input="ManagementFeeQty,ManagementFeePct"
            disable-target-value-for-managementfeeqty="Yes - %,No,N/A - Strategic Deal"
            disable-target-value-for-managementfeepct="Yes - $,No,N/A - Strategic Deal"
            value-when-input-disabled-for-managementfeeqty="N/A"
            value-when-input-disabled-for-managementfeepct="N/A">
            <option value="0" disabled selected>Select your option</option>
        </select> -->
        <app-dropdown [optionsList]="managementFeeOptions"
                      [keySelected]="dealStructureFormGroup.get('managementFee')?.value"
                      [readonly]="isReadOnly('managementFee')"
                      (optionSelected)="onManagementFeeSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('managementFeePct')"
              [class.edited-externally]="editedExternally('managementFeePct')"
              [class.conflict-on-merge]="hasMergeConflict('managementFeePct')">Management Fee (%)</span>
        <span *ngIf="showAsterisk('managementFeePct')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('managementFeePct')">
        </app-popover>

      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="ManagementFeePct" name="ManagementFeePct" class="input-data-number input-number percentage-input" operations="" placeholder="N/A" disabled><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [placeholder]="dealStructureFormGroup.get('managementFeePct')?.disabled ? 'N/A' : ''"
                     [readonly]="isReadOnly('managementFeePct')"
                     [value]="dealStructureFormGroup.get('managementFeePct')?.value"
                     [disabled]="dealStructureFormGroup.get('managementFeePct')?.disabled"
                     (selectedValue)="onSelectedValue($event,'managementFeePct')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('managementFeeQty')"
              [class.edited-externally]="editedExternally('managementFeeQty')"
              [class.conflict-on-merge]="hasMergeConflict('managementFeeQty')">Management Fee ($000s)</span>
        <span *ngIf="showAsterisk('managementFeeQty')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('managementFeeQty')">
        </app-popover>

      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="ManagementFeeQty" name="ManagementFeeQty" class="input-data-number input-number non-decimal-input" operations="" placeholder="N/A" disabled><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'currency'"
                     [placeholder]="dealStructureFormGroup.get('managementFeeQty')?.disabled ? 'N/A' : ''"
                     [readonly]="isReadOnly('managementFeeQty')"
                     [disabled]="dealStructureFormGroup.get('managementFeeQty')?.disabled"
                     [value]="dealStructureFormGroup.get('managementFeeQty')?.value"
                     (selectedValue)="onSelectedValue($event,'managementFeeQty')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('totalLeverage')"
              [class.edited-externally]="editedExternally('totalLeverage')"
              [class.conflict-on-merge]="hasMergeConflict('totalLeverage')">Total Leverage ($000)</span>
        <span *ngIf="showAsterisk('totalLeverage')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="TotalLeverage" name="TotalLeverage" class="input-data-number input-number non-decimal-input" operations="EquityContributionTotalPct,EquityContributionClosingPct"><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'currency'"
                     [readonly]="isReadOnly('totalLeverage')"
                     [value]="dealStructureFormGroup.get('totalLeverage')?.value"
                     (selectedValue)="onTotalLeverage($event,'totalLeverage')">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('equityContributionTotalPct')"
              [class.edited-externally]="editedExternally('equityContributionTotalPct')"
              [class.conflict-on-merge]="hasMergeConflict('equityContributionTotalPct')">Equity Contribution (% of Total)</span>
        <span *ngIf="showAsterisk('equityContributionTotalPct')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="EquityContributionTotalPct" name="EquityContributionTotalPct" class="input-data-number input-number percentage-input" formula="( ( TotalConsideration ) - ( TotalLeverage ) ) / ( TotalConsideration ) * ( 100 )" format="percentage" disabled><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [placeholder]="'N/A'"
                     [readonly]="isReadOnly('equityContributionTotalPct')"
                     [value]="dealStructureFormGroup.get('equityContributionTotalPct')?.value"
                     [disabled]="dealStructureFormGroup.get('equityContributionTotalPct')?.disabled">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 required-div section-label">
        <span [class.highlight-warning]="highlightRequiredField('equityContributionClosingPct')"
              [class.edited-externally]="editedExternally('equityContributionClosingPct')"
              [class.conflict-on-merge]="hasMergeConflict('equityContributionClosingPct')">Equity Contribution (% of Closing)</span>
        <span *ngIf="showAsterisk('equityContributionClosingPct')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <!-- <input type="text" id="EquityContributionClosingPct" name="EquityContributionClosingPct" class="input-data-number input-number percentage-input" formula="( ( ClosingConsideration ) - ( TotalLeverage ) ) / ( ClosingConsideration ) * ( 100 )" format="percentage" disabled><br>
        <span class="numeric-validation-msg"></span> -->
        <app-numeric class="custom"
                     [type]="'decimal2'"
                     [format]="'percentage'"
                     [placeholder]="'N/A'"
                     [readonly]="isReadOnly('equityContributionClosingPct')"
                     [value]="dealStructureFormGroup.get('equityContributionClosingPct')?.value"
                     [disabled]="dealStructureFormGroup.get('equityContributionClosingPct')?.disabled">
        </app-numeric>
      </div>
    </div>
  </div>
</div>
