export class TerminationFormItem{
    lastName:string;
    firstName:string;
    dateOfTermination:string;
    voluntaryTermination:string;
    voluntaryTerminationReason:string;
    StatusofIndividual:string;
    RegisteredStatus:string;
  //  emailSent:string;
    NRFStatus:string;
    otherNRFStatusReason:string;
    series24LicensedSupervisor:string;
    TerminationReason:string;
    TerminationReasonComments:string;
    ThreatTeamContact:string;
    threatTeamContactReason:string;
    OpenInternalThreat:string;
    openInternalThreatReason:string;
    dateTheEmployeeRemoved:string;
    jakiePolsonNotifiedDate:string;
    jakiePolsonNotifiedDateNA:string
    allowedToTermedDate:string;
    allowedToTermedDateNA:string;
    pstToTermedClosedDeniedDate:string;
    pstToTermedClosedDeniedDateNA:string;
    dateTheEmployeeRemovedDCFKnowledge:string;
    dateTheEmployeeRemovedFlowPerms:string;
    dateTheEmployeeRemovedFlowPermsNA:string;
    dateTheEmployeeRemovedFlowPermsReason:string;
    dateSupportTicketOpened:string;
    dateSupportTicketOpenedNA:string;
    dateSupportTicketOpenedReason:string;
    terminatedIndividualRemovedEmployee:string;
    terminatedIndividualRemovedEmployeeNA:string;
    terminatedIndividualRemovedEmployeeReason:string;
    dateTheEmployeeRetired:string;
    SmarshEmail:string;
    SmarshEmailComment:string;
    dateTheEmployeeRemovedSupervision:string;
    dateTheEmployeeDeloitteMobile:string;
    dateTheEmployeeDeloitteMobileNA:string;
    DateTheNewHiresTermedCompliance:string;
    DateTheNewHiresTermedComplianceNA:string;
    DateTheNewHiresTermedComplianceReason:string;
    DateTheEmployeeRemovedSupervisorOrganization:string;
    notifiedBrokerageFirmsDate:string;
    notifiedBrokerageFirmsDateNA:string;
    SentSignedCopy:string;
    NRF:string;
    SentSignedCopyOtherText:string;
    trackingNumber:string;
    ShippingMethodUsed:string;
    SignatureDate:string;
    Created:string;
    CreatedBy:string;
    Modified:string;
    ModifiedBy:string;
    AdditionalComments:string;
}
