<ng-template #content let-modal>
  <div class="modal-header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="modal-body">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="modal-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</ng-template>
