<div *ngIf="engagementObject" id="engagement-health-details-container">
  <div class="row">
    <div class="col-xs-2">
      <span>Engagement Name:</span>
    </div>
    <div class="col-xs-10">
      <span>{{engagementObject.engagementName}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>Engagement ID:</span>
    </div>
    <div class="col-xs-10">
      <span>{{engagementObject.newDealId}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>Engagement Type:</span>
    </div>
    <div class="col-xs-10">
      <span>{{engagementObject.engagementType}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>Start Date:</span>
    </div>
    <div class="col-xs-10">
      <span>{{startDate}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>End Date:</span>
    </div>
    <div class="col-xs-10">
      <span>{{endDate}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>Engagement Status:</span>
    </div>
    <div class="col-xs-10">
      <span
        [ngStyle]="{'color': engagementObject.engagementStatusColor}">
        {{engagementObject.engagementStatus}}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>
        Compliance Status:
      </span>
    </div>
    <div class="col-xs-10">
      <span
        class="label-clickeable"
        [ngStyle]="{'color': getComplianceStatusColor(engagementObject.complianceStatus)}"
        (click)="openComplianceModal($event)">
        {{engagementObject.complianceStatus}}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>
        Admin Assigned:
      </span>
    </div>
    <div class="col-xs-10">
      <a *ngIf="hasAdminAssigned" (click)="openNewTab(getDpnProfileLink(engagementObject.adminUserName))">
        <span class="deal-team-name">{{engagementObject.adminUserName}}</span>
      </a>
      <span *ngIf="!hasAdminAssigned">Admin not yet assigned</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>Previous Step:</span>
    </div>
    <div class="col-xs-10">
      <span>-</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2">
      <span>Next Step:</span>
    </div>
    <div class="col-xs-10">
      <span>-</span>
    </div>
  </div>
</div>

<app-modal #EngagementHealthModal>
    <ng-container header>
        <ng-container
            *ngTemplateOutlet="modalHeader">
        </ng-container>
    </ng-container>
    <ng-container body>
        <ng-container
            *ngTemplateOutlet="modalBody">
        </ng-container>
    </ng-container>
    <ng-container footer>
        <ng-container
            *ngTemplateOutlet="modalFooter">
        </ng-container>
    </ng-container>
</app-modal>

<ng-template #modalHeader>
  <div [ngSwitch]="modalType" class="header-container">
    <h3 *ngSwitchCase="'complianceModal'" class="text-primary text-bigger">
      Engagement Health - Compliance Status
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
    <h3 *ngSwitchCase="'dealTeamModal'" class="text-primary text-bigger">
        Engagement Health - Compliance Status
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h3>
  </div>
</ng-template>
<ng-template #modalBody>
  <div [ngSwitch]="modalType">
    <div *ngSwitchCase="'complianceModal'" class="body-container">
      <div class="row">
        <input
          type="radio"
          [value]="complianceStatusValues.NoIssues"
          class="issues-radio-button"
          [checked]="isChecked(complianceStatusValues.NoIssues)"
          (change)="updateCurrentRadioSelected(complianceStatusValues.NoIssues)">
        <span
          [ngStyle]="{'color': complianceStatusColors.NoIssues}">
          No issues found
        </span>
        <input
          type="radio"
          [value]="complianceStatusValues.Issues"
          class="issues-radio-button"
          [checked]="isChecked(complianceStatusValues.Issues)"
          (change)="updateCurrentRadioSelected(complianceStatusValues.Issues)">
        <span
          [ngStyle]="{'color': complianceStatusColors.Issues}">
          Issues found
        </span>
        <input
          type="radio"
          [value]="complianceStatusValues.NA"
          class="issues-radio-button"
          [checked]="isChecked(complianceStatusValues.NA)"
          (change)="updateCurrentRadioSelected(complianceStatusValues.NA)">
        <span
          [ngStyle]="{'color': complianceStatusColors.NA}">
          N/A
        </span>
      </div>
      <div class="row">
        <textarea
          placeholder="Add issues here"
          type="text"
          rows="3"
          class="text-area-style"
          value="{{engagementObject.complianceComments}}"
          [(ngModel)]="currentComments">
        </textarea>
      </div>
    </div>
    <div *ngSwitchCase="'dealTeamModal'" class="body-container">
      <textarea
        id="comments-compliance-status-for-deal-team"
        type="text"
        rows="3"
        class="text-area-style textarea-readonly"
        value="{{engagementObject.complianceComments}}"
        [(ngModel)]="currentComments"
        [class.readonly]="true"
        disabled>
      </textarea>
    </div>
  </div>
</ng-template>
<ng-template #modalFooter>
  <div [ngSwitch]="modalType">
    <div *ngSwitchCase="'complianceModal'">
      <button class="btn btn-primary btn-ok" (click)="saveComplianceData(); modal.close()">Save</button>
      <ng-container
         *ngTemplateOutlet="userWithIssues">
      </ng-container>
    </div>
    <div *ngSwitchCase="'dealTeamModal'">
      <ng-container
         *ngTemplateOutlet="userWithIssues">
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #userWithIssues>
</ng-template>
<app-eho-notify-popup (engagementHealthUpdateValue)="engagementHealthUpdateValue($event)" [engagementObject]="engagementObject"> </app-eho-notify-popup>