import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationResult } from '@azure/msal-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenService } from './services/token.service';
import { environment } from "src/environments/environment";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']  
})

export class AppComponent implements OnInit {
  title = 'WebApp';
  public user : any   
  public isAuthorized : boolean = false;
  public imagePath:any;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,private msalService:MsalService,private tokenService : TokenService,private router:Router,private sanitizer: DomSanitizer)
 {
  localStorage.setItem("EnvironmentFlag",environment.environmentType.toString());
 }
 login()
 {
   this.msalService.loginPopup().subscribe((response:AuthenticationResult)=>{
       this.msalService.instance.setActiveAccount(response.account);
       const account = this.msalService.instance.getActiveAccount();   
      this.tokenService.accessToken=response?.accessToken;
       this.tokenService.idToken=response?.idToken;   
       localStorage.setItem("idToken",this.tokenService.idToken);  
        this.validateUser(account);
      //  this.getUserProfile(account.username);
   })
 }
 isUserLoggedIn():boolean
 {
   if(this.msalService.instance.getActiveAccount()!=null)
   {
     return true;
   }
   return false;
 }

 validateUser(account : any){
  this.tokenService.validateUser().subscribe((response: any) =>
         {          
         if(response.isSuccessStatusCode)
         {          
           var fullname= account?.name?.split(/,/);
       var displayName=fullname[1].trim() +' '+ fullname[0].trim() ;      
 
        this.user=displayName;
        localStorage.setItem("username",this.user)  
        
        this.isAuthorized=true;
        this.router.navigate(['/home']);
         }else{
           this.router.navigate(['unauthorized']);  
         }
       })
  }  
 
 ngOnInit(): void {     
  // this.validateUser(null);

  // if(!this.isUserLoggedIn())
  //  {
  //    this.login()    
  //  }
  //  else{
  //   this.user=localStorage.getItem("username");
  //   this.isAuthorized= true;
  //  }
  this.loadAdobeScripts();  
  }

loadAdobeScripts()
{
  // Load omniture  related scripts
const scriptName = environment.environmentType === "prd" 
    ? "launch-5e5197696911.min.js" // prod script
    : "launch-af5666a9e3f4-development.min.js"; // dev script
var scriptSource = `https://assets.adobedtm.com/571f1a0c43d6/db9c0d909c60/`+scriptName+``;    
let isOmniturescriptExists = document.querySelector(`script[src="`+scriptSource+`"]`);
if (!isOmniturescriptExists) {
  let script = document.createElement('script');  
  script.src = scriptSource;
  script.setAttribute("async", "");
  document.head.append(script);
}
}
}

