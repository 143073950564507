import { Injectable } from '@angular/core';
import { Spcrud } from 'src/shared/services/spcrud';
import { AppSettings } from 'src/shared/app-settings';
import { EngagementHealthObjectServiceHelper } from './engagement-health-object-helper';
import { AvailableFormItem } from 'src/shared/models/available-form-item';
import { ComplianceData } from '../../models/compliance-data';
import { BaseService } from 'src/shared/services/base.service';
import { RootSiteSpcrud } from 'src/shared/services/root-site-spcrud';
import { CustomSiteSpcrud } from 'src/shared/services/custom-site-spcrud';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { MainServiceHelper } from 'src/shared/services/main-service-helper';
import { Observable, catchError, forkJoin, from, map, mergeMap, of, switchAll, switchMap, tap, toArray } from 'rxjs';
import { NewDealSetupService } from 'src/shared/services/new-deal-setup.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { EngagementHealthObjectServiceService } from 'src/app/services/engagement-health-object-service.service';
import { MainService } from 'src/shared/services/main.service';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { AdminService } from 'src/app/services/admin.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementHealthObjectService extends BaseService {
  engObject:any;
  constructor(
    spcrud: Spcrud,
    rootSiteSpcrud: RootSiteSpcrud,
    customSiteSpcrud: CustomSiteSpcrud,
    private mainServiceHelper: MainServiceHelper,
    private engagementHealthObjectHelper: EngagementHealthObjectServiceHelper,
  private newDealService:NewdealService,private EHOService:EngagementHealthObjectServiceService,private dealWbsService:DealwbsService,private adminService:AdminService,
private mainService:MainService) {
      super(spcrud, rootSiteSpcrud, customSiteSpcrud);
    }

  retrieveEngagementObject(formId: number, newDealBaseUrl: string): Promise<any> {
    return new Promise<any>((resolve) => {
      const listName = AppSettings.engagementHealthList;
      const newDealListName = AppSettings.newDealSetupFormList;
      const spcrud = this.getSpcrud(listName);
     // const customSpcrud = this.getSpcrud(newDealListName, newDealBaseUrl);
     const customSpcrud = this.getSpcrud(newDealListName);
      const options = {
        filter: `ID eq ${formId}`,
        select: `*, ComplianceStatus, ` + AppSettings.myDealSiteColumn
      };
      const results = {Engagement: {}, MyDeal: {}, NewDeal: {}};
      spcrud.read(listName, options)
      .then( (response:any) => {
        results.Engagement = response.d.results[0];
        const myDealId = response.d.results[0][AppSettings.myDealSiteColumn];
        const newDealOptions = {
          filter: `${AppSettings.myDealSiteColumnQuery} eq ${myDealId}`,
          select: `ID, Status, SubmissionDate,SalesforceCode,SwiftCode`
        };
        Promise.all([
          this.retrieveMyDealObject(myDealId),
          customSpcrud.read(newDealListName, newDealOptions)])
        .then(([mydealResponse, newDealResponse]) => {
          results.MyDeal = mydealResponse.d.results[0];
          results.NewDeal = newDealResponse.d.results[0];
          const engObject = this.engagementHealthObjectHelper.createEngagementHealthObject(results);
          resolve(engObject);
        });
      });
    }).catch(error => {
    });
  }
  retrieveEngagementObjectEHO(formId: number, myDealId?: string): Promise<any> {
    const results = {Engagement: {}, MyDeal: {}, NewDeal: {}};
    return new Promise<any>((resolve) => {
      forkJoin([
     this.EHOService.GetEHOObjects(formId,null),
     this.retrieveMyDealObjects(myDealId),
     this.retrieveNewDealObjects(myDealId)
      ])
     .subscribe(
      {
      next:([response,mydealResponse,newDealResponse]) => {
        if(response.length==0) return resolve(0);
        results.Engagement = response[0];        
        results.MyDeal = mydealResponse[0];
        results.NewDeal = newDealResponse[0];
        this.engObject = this.engagementHealthObjectHelper.createEngagementHealthObject(results);
        resolve(this.engObject);                  
    },
    error:(err: any) => {
      console.log('Error fetching User data:', err);
      throw err; // Re-throw the error to propagate it through the Observable stream
    }
     });
});
  }
  retrieveEngagementObjectEHONew(formId: number, newDealBaseUrl: string): Observable<any> {
    const results = {Engagement: {}, MyDeal: {}, NewDeal: {}};
    return from(this.EHOService.GetEHOObjects(formId)).pipe(
      map((response: any) => {
        results.Engagement = response[0];
        const myDealId = response[0][AppSettings.myDealSiteColumn];
        forkJoin([
          this.retrieveMyDealObjects(myDealId),
          this.retrieveNewDealObjects(myDealId)])
          .subscribe({
          next:([mydealResponse, newDealResponse])=>{
          results.MyDeal = mydealResponse[0];
          results.NewDeal = newDealResponse[0];
          this.engObject = this.engagementHealthObjectHelper.createEngagementHealthObject(results);
          return this.engObject;
          },
          error: error => {
             console.error('Error:', error);
           }
          });
          
    }),
    catchError((err: any) => {
      console.log('Error fetching User data:', err);
      throw err; // Re-throw the error to propagate it through the Observable stream
    })
  );
  }
  retrieveMyDealObjects(myDealId: any): Observable<any> {
    return from(this.newDealService.GetMyDealDetails(myDealId)).pipe(
      map((response: any) => {
        return response;
  }),
  catchError((err: any) => {
    console.log('Error fetching User data:', err);
    throw err; // Re-throw the error to propagate it through the Observable stream
  })
);
  }
   retrieveNewDealObjects(myDealId: any): Observable<any> {
    return from(this.EHOService.GetNewDealDetails("EHO",myDealId)).pipe(
      map((response: any) => {
        return response;
  }),
  catchError((err: any) => {
    console.log('Error fetching User data:', err);
    throw err; // Re-throw the error to propagate it through the Observable stream
  })
);
  }
  retrieveMyDealObject(myDealId: number): Promise<any> {
    const listName = AppSettings.myDealFormList;
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: `ID eq ${myDealId}`,
      select: `*, EngagementName, EngagementType, StartDate, EndDate, ClientName, ID, AdminAssigned/Title,AdminAssigned/UserName`,
      expand: 'AdminAssigned'
    };
    return spcrud.read(listName, options);
  }

  updateMyDealEngagementId(engagementId: number, myDealId: number) {
    const listName = AppSettings.myDealFormList;
    const spcrud = this.getSpcrud(listName);
    const objectToSend = this.engagementHealthObjectHelper.createMyDealUpdateObject(engagementId);
    return spcrud.update(listName, myDealId, objectToSend);
  }
  updateMyDealEngagementIdEHO(engagementId: number, myDealId: number):Observable<any>{
    const objectToSend = this.engagementHealthObjectHelper.createMyDealUpdateObject(engagementId);
    return from(this.mainService.updateForm(AppSettings.myDealFormList,myDealId,objectToSend,"EHO")).pipe(
      map((response: any) => {
        return response;
  }),
  catchError((err: any) => {
    console.log('Error fetching User data:', err);
    throw err; // Re-throw the error to propagate it through the Observable stream
  }) 
); 
  }

  retrieveFormStatusObject(listName: string, myDealId: number, customBaseUrl: string): Promise<any> {
    // const spcrud = this.getSpcrud(listName, customBaseUrl);
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: `${AppSettings.myDealSiteColumnQuery} eq ${myDealId}`,
      select: `*, ID, Status`
    };

    return spcrud.read(listName, options)
    .then( (response:any) => {
      if (response.d.results.length === 0) {
        return null;
      }
      const results = response.d.results[0];
      const engObject = this.engagementHealthObjectHelper.createFormStatusItem(results);
      return engObject;
    }).catch((error:any) => {
    });
  }
  retrieveFormStatusObjectEHO(listName: string, myDealId: number): Observable<any> {
   let URL=this.newDealService.getAppendURL(listName);
 return from(this.EHOService.GetFORMEHOObjects(URL,myDealId,listName)).pipe(
  map((response: any) => {
    if (response.length === 0) {
      return null;
    }
    const engObject = this.engagementHealthObjectHelper.createFormStatusItem(response);
    return engObject;
}),
catchError((err: any) => {
console.log('Error fetching User data:', err);
throw err; // Re-throw the error to propagate it through the Observable stream
})
);
  }

  getDealTeamUsers(formId: number): Promise<any> {
    const listName = AppSettings.dealTeamTableList;
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: 'Form/Id eq ' + formId,
      select: 'Id, FormId, Role/Role, Role/Id, Name/EMail, Name/Id, Name/Title, Name/Name, Name/UserName',
      expand: 'Role, Name'};
    return spcrud.read(listName, options)
    .then( (response:any) => {
      const results = response.d.results;
      const dealTeamUsers = this.engagementHealthObjectHelper.createDealTeamItemsObject(results);
      return dealTeamUsers;
    }).catch((error:any) => {
    });
  }
  getDealTeamUsersEHO(formId: number): Observable<any> {
    return from(this.newDealService.getDealTeamTableList(formId)).pipe(
      map((response: any) => {
        // const dealTeamUsers:any = this.engagementHealthObjectHelper.createDealTeamItemsObject(response);
        return response;
    }),
    catchError((err: any) => {
    console.log('Error fetching User data:', err);
    throw err; // Re-throw the error to propagate it through the Observable stream
    })
    );
  }

  getDealTeamTableUsers(formId: number): any {
    const listName = AppSettings.dealTeamTableList;
    const spcrud = this.getSpcrud(listName);
    if (formId) {
      const optionsDealTeamTable = {
        filter: 'Form/Id eq ' + formId,
        select: `Id, FormId, Role/Role, Role/Id, PursuitRole/Role, PursuitRole/Id, SwiftRole/Role, SwiftRole/Id, Name/EMail, Name/Id, Name/Title, Name/Name`,
        expand: 'Role, PursuitRole,SwiftRole, Name'
      };
      return spcrud.read(listName, optionsDealTeamTable)
        .then((response:any) => {
          const results = response.d.results;
          const dealTeamUsers = results.map((item:any) => {
            const peoplePickerUser = this.mainServiceHelper.createPeoplePickerUser(item.Name.Name, item.Name.Title, item.Name.EMail);
            return this.mainServiceHelper.createPeoplepickerDynamicTableItem(item.Name.Id, peoplePickerUser, item);
          });
          return dealTeamUsers;

        }).catch((error:any) => {
        });
    } else {
      return null;
    }
  }
  getDealTeamTableUsersEHO(formId: number): Observable<any> {
    return from(this.newDealService.getDealTeamTableList(formId)).pipe(
      map((response: any) => {
        const results = response;
        const dealTeamUsers:any = results.map((item:any) => {
          const peoplePickerUser = this.mainServiceHelper.createPeoplePickerUser(item.Name.Name, item.Name.Title, item.Name.EMail);
          return this.mainServiceHelper.createPeoplepickerDynamicTableItem(item.Name.Id, peoplePickerUser, item);
        });
        return dealTeamUsers;
    }),
    catchError((err: any) => {
    console.log('Error fetching User data:', err);
    return err; // Re-throw the error to propagate it through the Observable stream
    })
    );
  }
  getAvailableForms(): Promise<any> {
    const listName = AppSettings.engagementAvailableForms;
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: 'Visible eq ' + 1
    };
    return spcrud.read(listName, options)
      .then( (response:any) => {
        const results = response.d.results;
        const availableForms = this.engagementHealthObjectHelper.createAvailableFormItemsObject(results);
        return availableForms;
      }).catch((error:any) => {
      });
  }

  getCreatedForms(availableFormsObject: Array<AvailableFormItem>, myDealId: number, newDealItemId: number) {
    const promises = [];
    for (const item of availableFormsObject) {
      //const spcrud = this.getSpcrud(item.availableFormListName, item.availableFormSiteUrl);
      const spcrud = this.getSpcrud(item.availableFormListName);
      if (item.availableFormListName === AppSettings.newDealSetupFormList) {
        item.availableFormCreated = true;
        item.availableFormItemId = newDealItemId;
        promises.push(item);
      } else {
        const options = {
          filter: `${AppSettings.myDealSiteColumnQuery} eq ` + myDealId,
          select: 'Id, Status'
        };
        const p = spcrud.read(item.availableFormListName, options);
        promises.push(p);
      }
    }
    return Promise.all(promises).then(response => {
      response.forEach((item, index) => {
        if (item.d) {
          const result = item.d.results;
          if (result && result.length > 0) {
            availableFormsObject[index].availableFormItemId = result[0].Id;
            availableFormsObject[index].availableFormCreated = true;
            availableFormsObject[index].availableFormModal = false;
            availableFormsObject[index].availableFormSidebar = true;
            availableFormsObject[index].formStatus = result[0].Status;
          }
        }
      });
      return availableFormsObject;
    });
  }
  getCreatedForms_EHO_bck(availableFormsObject: Array<AvailableFormItem>, myDealId: number, newDealItemId: number):any {
    let arrayList:any = [];
    return new Promise((resolve) => {
      availableFormsObject.forEach((item:any, index:any) => {
      if (item.availableFormListName === AppSettings.newDealSetupFormList) {
        item.availableFormCreated = true;
        item.availableFormItemId = newDealItemId;
        arrayList.push(item);
      } else {
        // if(item.availableFormListName=="DealWbsSetupForm"){//Need to remove this condition once closing checklist done
        let URL=this.newDealService.getAppendURL(item.availableFormListName);
         this.EHOService.GetFORMEHOObjects(URL,myDealId,item.availableFormListName).subscribe({
          next:(res:any)=>{
           if(res.length>0|| res.ID != null){
            let data={
              Id:res.ID,
              Status:res.Status==null?res.FormStatus:res.Status
            }
            arrayList.push(data);
            availableFormsObject[index].availableFormItemId = data.Id;
            availableFormsObject[index].availableFormCreated = true;
            availableFormsObject[index].availableFormModal = false;
            availableFormsObject[index].availableFormSidebar = true;
            availableFormsObject[index].formStatus = data.Status;
           }  
           return resolve(availableFormsObject);
          },
          error:err=>{
            console.error(err);
          }
        })
      //}   
      }
    });
  })
   
  }
  //should add the logic if new form will be implemented
  getCreatedForms_EHO(availableFormsObject: Array<AvailableFormItem>, myDealId: number, newDealItemId: number): Observable<any> {
    let isDealWbsAvailable,isClosingChecklistAvailable=false;
    availableFormsObject.forEach((item:any, index:any) => {
      if (item.availableFormListName === AppSettings.dealWbsSetupFormList) {isDealWbsAvailable=true;}
      if (item.availableFormListName === AppSettings.closingChecklistFormList) {isClosingChecklistAvailable=true;}
    });
    return forkJoin([
      isDealWbsAvailable ? this.EHOService.GetFORMEHOObjects(this.newDealService.getAppendURL(AppSettings.dealWbsSetupFormList), myDealId, AppSettings.dealWbsSetupFormList) : of([]),
      isClosingChecklistAvailable ? this.EHOService.GetFORMEHOObjects(this.newDealService.getAppendURL(AppSettings.closingChecklistFormList), myDealId, AppSettings.closingChecklistFormList) : of([])
    ]).pipe(
      switchMap(([responseDealWbs, responseCCL]) => {       
          return of({responseDealWbs, responseCCL});
      })
    );
  }
  // getDealTeamUserByEmail(listName: string, formId: number, email: string): Promise<any> {
  //   const spcrud = this.getSpcrud(listName);
  //   const options = {
  //     filter: `Form/Id eq ${formId} and Name/EMail eq '${email}'`,
  //     select: 'Id, Name/EMail',
  //     expand: 'Name'
  //   };
  //   return spcrud.read(AppSettings.dealTeamTableList, options)
  //   .then( response => {
  //     return response.d.results[0];
  //   }).catch(error => {
  //   });
  // }
  
  getDealTeamUserByEmail( formId: number, email: string): Promise<any> {
    const listName = AppSettings.dealTeamTableList;
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: `Form/Id eq ${formId} and Name/EMail eq '${email}'`,
      select: 'Id, Name/EMail',
      expand: 'Name'
    };
    return spcrud.read(listName, options)
    .then( (response:any) => {
      return response.d.results[0];
    }).catch((error:any) => {
    });
  }
  updateEngagementHealthEHO(engagementHealthData: ComplianceData,rangeKey:any) {
    const objectToSend = this.engagementHealthObjectHelper.createDealTeamUpdateObject
    (engagementHealthData.complianceStatus, engagementHealthData.complianceComments);
    this.mainService.updateComplianceEHO(engagementHealthData.dealTeamItemId,objectToSend,rangeKey);
  }
  updateEngagementHealth(engagementHealthData: ComplianceData) {
    const listName = AppSettings.engagementHealthList;
    const spcrud = this.getSpcrud(listName);
    const objectToSend = this.engagementHealthObjectHelper.createDealTeamUpdateObject
    (engagementHealthData.complianceStatus, engagementHealthData.complianceComments);
    return spcrud.update(listName, engagementHealthData.dealTeamItemId, objectToSend);
  }

  associateNewForm(listName: string, myDealId: number, formBaseUrl: string) {
    // const spcrud = this.getSpcrud(listName, formBaseUrl);
    const spcrud = this.getSpcrud(listName);
    const objectToSend = this.engagementHealthObjectHelper.createNewFormObject(myDealId);
    return spcrud.create(listName, objectToSend);
  }
  associateNewFormEHO(listName: string, myDealId: number, formBaseUrl: string):Observable<any> {
    var appendedURL=this.dealWbsService.getAppendURL(listName);
    var objectToSend;
    if(listName=="DealWbsSetupForm")
     objectToSend = this.engagementHealthObjectHelper.createNewFormObject(myDealId);
    else
      objectToSend = this.engagementHealthObjectHelper.createNewCCFormObject(myDealId);

    return from(this.dealWbsService.SaveEHODetailsToForm(appendedURL,objectToSend,listName)).pipe(
      map((response: any) => {
        return response;
    }),
    catchError((err: any) => {
    console.log('Error fetching User data:', err);
    throw err; // Re-throw the error to propagate it through the Observable stream
    })
    );
  }

  getXBRegisterFlag(formId: number, formBaseUrl: string): Promise<any> {
    const listName = AppSettings.crossBorderFormList;
    // const spcrud = this.getSpcrud(listName, formBaseUrl);
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: `ID eq ${formId}`,
      select: 'FormRegistered',
    };
    return spcrud.read(listName, options);
  }
  getXBRegisterFlagEHO(formId: number, formBaseUrl: string): Observable<any> {
    const listName = AppSettings.crossBorderFormList;
    return from(this.EHOService.GetFormDetails(formId)).pipe(
      map((response: any) => {
        return response;
    }),
    catchError((err: any) => {
    console.log('Error fetching User data:', err);
    throw err; // Re-throw the error to propagate it through the Observable stream
    })
    );
  }

  getBaseUrl(listName: string) {
    const spcrud = this.getSpcrud(listName);
    return spcrud.baseUrl;
  }
}
