import { Injectable } from '@angular/core';
import { TooltipObject } from '../models/tooltip-object';
import { AppSettings } from '../app-settings';
import { Spcrud } from './spcrud';
import { RootSiteSpcrud } from './root-site-spcrud';
import { BaseService } from './base.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { Observable, forkJoin } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { AdminService } from 'src/app/services/admin.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';

@Injectable({
  providedIn: 'root'
})
export class TooltipHelperService extends BaseService {

  tooltipList: Array<TooltipObject> = [];
  tooltipListDealWbs: Array<TooltipObject>=[];
  tooltipListCCL: Array<TooltipObject>=[];

  constructor(spcrud: Spcrud, rootSiteSpcud: RootSiteSpcrud,private newDealService:NewdealService,
    private spinnerService: SpinnerService,private adminService:AdminService,private commonHelperService:CommonHelperService, private dealWbsService: DealwbsService,private closingchecklist:ClosingCheckListService) {
    super(spcrud, rootSiteSpcud);
   }

  getTooltips(): any {
   this.newDealService.getTooltipsList().subscribe({
      next: ((result:any)=>{
          //This needs to be removed after migration correction
          if(result!=undefined && result.length > 0)
          {
             result.forEach((item:any)=>{
              if(item.FieldName == 'SalesOfficeId')
              {
                item.FieldName = 'SalesOffice'
              }
             });
          }
          this.tooltipList = result.map((item:any)=>{
              const tooltipSelector = this.createTooltipObject(
                  item.FieldName, item.TooltipText, item.TooltipTitle);
              return tooltipSelector;
          });
          return this.tooltipList;
      })
      ,
      error:((err:any)=>{
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hide();
        console.log("Error" + err);
      })
  })
  
  }

  getTooltips_CCF(tooltipList: any): Promise<any> {
    const spcrud = this.getSpcrud(tooltipList);
    return spcrud.read(tooltipList)
    .then( (response : any) => {
      const results = response.d.results;
      const newList = results.map((item : any) => {
        const tooltipSelector = this.createTooltipObject(
          item.FieldName, item.TooltipText, item.TooltipTitle);
        return tooltipSelector;
      });
      this.tooltipList = [ ...this.tooltipList, ...newList];
      return this.tooltipList;
    }).catch((error : any) => {
    });
  }

  getTooltipsClosingCheckList() {
    this.closingchecklist.getClosingCheckListTooltipsList().subscribe({
     next: ((results:any)=>{
           const newList = results.map((item: any) => {
               const tooltipSelector = this.createTooltipObject(
               item.FieldName, item.TooltipText, item.TooltipTitle);
               return tooltipSelector;
           });
           this.tooltipListCCL = [...this.tooltipListCCL, ...newList];
           return this.tooltipListCCL;
       })
   }
    )
   }

  createTooltipObject(fieldName: string, tooltipText: string, tooltipTitle: string): TooltipObject {
    return new TooltipObject(fieldName, tooltipText, tooltipTitle);
  }

  getTooltipByFieldName(fieldName: string): TooltipObject | any{
    return !!this.tooltipList.length ? this.tooltipList.find(t => t.FieldName === fieldName) : null;
  }



  getTooltipsDealWbs(): any {
    forkJoin([
      this.dealWbsService.getTooltipsList(),
      this.dealWbsService.getMyDealTooltipsList()
    ]).subscribe({
   // this.dealWbsService.getTooltipsList().subscribe({
       next: ([result,myDealRes])=>{
           //This needs to be removed after migration correction
           if(result!=undefined && result.length > 0)
           {
              result.forEach((item:any)=>{
               if(item.FieldName == 'SalesOfficeId')
               {
                 item.FieldName = 'SalesOffice'
               }
              });
           }
           this.tooltipListDealWbs = result.map((item:any)=>{
               const tooltipSelector = this.createTooltipObject(
                   item.FieldName, item.TooltipText, item.TooltipTitle);
               return tooltipSelector;
           });
           //this.getMyDealTooltips();
           this.loadMyDealToolTip(myDealRes);
           return this.tooltipListDealWbs;
       },
       error:((err:any)=>{
         if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
         this.spinnerService.hide();
         console.log("Error" + err);
       })
   })
  }
loadMyDealToolTip(result:any){
  if(result!=undefined && result.length > 0)
    {
       result.forEach((item:any)=>{
        if(item.FieldName == 'SalesOfficeId')
        {
          item.FieldName = 'SalesOffice'
        }
       });
    }
    const newList = result.map((item:any)=>{
        const tooltipSelector = this.createTooltipObject(
            item.FieldName, item.TooltipText, item.TooltipTitle);
        return tooltipSelector;
    });
    this.tooltipListDealWbs = [...this.tooltipListDealWbs,...newList];
    return this.tooltipListDealWbs;
}
  getMyDealTooltips(): any {
    this.dealWbsService.getMyDealTooltipsList().subscribe({
       next: ((result:any)=>{
           //This needs to be removed after migration correction
           if(result!=undefined && result.length > 0)
           {
              result.forEach((item:any)=>{
               if(item.FieldName == 'SalesOfficeId')
               {
                 item.FieldName = 'SalesOffice'
               }
              });
           }
           const newList = result.map((item:any)=>{
               const tooltipSelector = this.createTooltipObject(
                   item.FieldName, item.TooltipText, item.TooltipTitle);
               return tooltipSelector;
           });
           this.tooltipListDealWbs = [...this.tooltipListDealWbs,...newList];
           return this.tooltipListDealWbs;
       })
       ,
       error:((err:any)=>{
         if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
         this.spinnerService.hide();
         console.log("Error" + err);
       })
   })
  }

  getTooltipByFieldNameDealWbs(fieldName: string): TooltipObject | any{
    return !!this.tooltipListDealWbs.length ? this.tooltipListDealWbs.find(t => t.FieldName === fieldName) : null;
  }
  getTooltipByFieldNameCCL(fieldName: string): TooltipObject | any{
    return !!this.tooltipListCCL.length ? this.tooltipListCCL.find(t => t.FieldName === fieldName) : null;
  }
}
