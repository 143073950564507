import { AppSettings } from '../app-settings';
export class FormStatus {
    formStatus: string;
    formActionGroup: string;
    isValid: boolean;

    validateStatus() {
      const validFormStatus = AppSettings.validFormStatus.find(status => {
        return this.formStatus === status;
      });

      this.isValid = validFormStatus ? true : false;
    }
  }
