<div class="pagination-container" style="margin-left: 6%;">
  
  <ngb-pagination style="z-index: 0 !important;"
    [collectionSize]="listElementsSize"
    [(page)]="page"
    [pageSize]="pageSize"
    [rotate]="rotate"
    [boundaryLinks]="boundaryLinks"
    [directionLinks]="directionLinks"
    [maxSize]="maxSize"
    [disabled]="disabled"
    (pageChange)="onChangeSelection()"></ngb-pagination>

  <!-- <app-dropdown
    class="select-page-size"
    [optionsList]="paginationListValues"
    [keySelected]="pageSize"
    (optionSelected)="onPageSizeSelection($event)"></app-dropdown> -->
</div>
