export class SharepointObjectNewDeal {
   // Project overview section
   //EngagementName: string;
   //Series24Id: string;
   DealTeamTable: string;
   IsIndiaInvolved: string;
   IsPriorDESattendee:string;
   DeloitteAlumniInvolved: string;
   DeloitteAlumniName: string;
   DeloitteAlumniRole: string;
   OpportunityViaGlobalSharepoint: string;
   InternationalCommunity: string;
   //EngagementIndustrySICCode: string;
   //Source: string;
   SourceName: string;
   //ReferralPercentage: string;
   //ReferralSourceName: string;
   //TransactionType: string;
   CrossBusinessOffering: string;
   //IntegratedMarketOffering: string;
   EngagementIndustrySector: string;

    // Client overview
    //ClientName: string;
    //ClientType: string;
    LeadClientServicePartnerId: string;
    LeadBusinessPartnerId: string;
    GlobalLCSPId: string;
    DoingBusinessAs: string;
    // StreetAddress: string;
    // City: string;
    // State: string;
    // PostalCode: string;
    // Industry: string;
    // TelephoneNumber: string;

    // New Deal
    ClientRevenue: string;
    //EBITDA: string;
    StatusNewDeal: string;
    //IsCompetitivePitch: string;
    BusinessDescription: string;
    //Subsector: string;
    PubliclyTradedSecurities: string;
    //IsPubliclyTradedEquity: string;
    //TickerCode: string;
    IsPubliclyTradedDebt: string;
    Cusip: string;
    FirmType : string;
    ConflictCheckCompletion: string;
    ObaPstPersonnelConflict: string;
    ObaPstPersonnelConflictDate: string;
    BackgroundCheckCompletion: string;
    OfacCompletion: string;
    CommentsNewDeal: string;
    CompetitorsList: string;
    OtherCompetitor: string;

    // Mercury
    M_AConfidentialSubsidiary: string;
    OpportunityPhase: string;
    TotalValue: string;
    BuyerName: string;
    // BuyerRole: string;
    Initiative: string;
    //FeeType: string;
    // IsThisAReproposal: string;
    IsThisARecurringOpportunity: string;
    // AnnualOpportunity: string;
    // IsThisAPullThroughOpportunity: string;
    IsThisAFollowOnOpportunity: string;
    COVID19RelatedNewOpportunity: string;
    Category: string;
    SubCategory: string;
    Offering: string;
    Subcontractor: string;
    AllianceProduct: string;
    //ProjectStartDate: string;
    //ProjectEndDate: string;
    PursuitStartDate: string;
    ExpectedCloseDate: string;
    MemberFirmRecognizingRevenue: string;
    // OriginalOpportunityID: string; // Note: This is different from MyDeal OppotunityID

    // PRD
    //ClientID: string;
    //EngagementType: string;
    // IsRecurringWBS: string;
    // DeloitteUSEntityId: string;
    // SalesOfficeId: string;
    // IsInternationalEngagement: string;
    // AreResourcesWorkOutside: string;
    // MemberFirmTaxablePresence: string;
    // OhterMemberFirmsParticipating: string;
    // WhereWorkMemberFirms: string;
    // MarketOffering: string;
    // Material: string;

    // Client setup
    // DeloitteUSEntityClientId: string;
    // SalesOfficeClientId: string;
    DeloitteUSEntityClient: string;
    SalesOfficeClient: string;
    OwnershipTypeI1: string;
    OwnershipTypeI2: string;
    OwnershipTypeI13: string;
    // DateBecameClient: string;
    //FinancialYearEnd: string;
    // ConsultingSICCode: string;
    GlobalUltimateParentID: string;
    GlobalClientPortfolio: string;
    GlobalClientIntelligenceNumber: string;
    GST: string;
    //BuyerNameClient: string;
    BuyerTitle: string;
    // BuyerEmail: string;
    //BillingAddressSameClientAddress: string;
    //BillingAddress: string;
    //PayerAddressSameClientAddress: string;
    //PayerAddress: string;
    //ServiceAddressSameClientAddress: string;
    //ServiceAddress: string;
    AttestStatus: string;
    AttestStatusParent: string;
    //PreviousAuditor: string;
    //CurrentAuditor: string;
    MemberFirmPerformingAttest: string;
    EntityOnWhichPerformsAttestWork: string;
    CurrentAuditorTenureExpiryDate: string;
    AuditorStartDate: string;
    PcaobReview: string;
    DescDesignation: string;
    ApprovingPartnerTelephone: string;
    ClientAcceptanceProcedures: string;
    ReasonForBypassingProcedures: string;
    AnySpecialInstructions: string;

    // Admin Review
    SalesforceCode: string;
    //MercuryOpportunityCod: string;
    SwiftCode: string;
}
