import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AppSettings } from 'src/shared/app-settings';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { DropdownService } from 'src/shared/services/dropwdown.service'; 
import { SecurityService } from 'src/shared/services/security.service';
import { FormValidations } from 'src/shared/utils/form-validations';
import { FormListFields } from '../shared/models/form-list-fields';
import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.scss']
})
export class FeesComponent implements OnInit {
  sharePointMapper:any = new ComplianceSharepointMapper();
  feesFormGroup: any;

  notApplicableEnabled:boolean = true;

  periodicOrFlatOptions: Array<DropdownItem> = [];
  engagementFeeBasedOptions: Array<DropdownItem> = [];

  tooltipTotalAmount = 'Total of all fees, retainers, expenses, etc.';
  tooltipPeriod = 'e.g. monthly, specific milestone, etc.';

  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;

  constructor(private dropdownService: DropdownService,private tooltipHelperService: TooltipHelperService,
              private securityService: SecurityService) { }

  ngOnInit() {
    this.feesFormGroup = this.formGroups[0];

    // this.dropdownService.fillDropdownOptions(AppSettings.complianceFormList, FormListFields.periodicOrFlat)
    // .then(response => {
    //   this.periodicOrFlatOptions = response;
    // });
    // this.dropdownService.fillDropdownOptions(AppSettings.complianceFormList, FormListFields.engagementFeeBased)
    // .then(response => {
    //   this.engagementFeeBasedOptions = response;
    // });
    this.periodicOrFlatOptions = AppSettings.PeriodicOrFlatArrayList;
    this.engagementFeeBasedOptions = AppSettings.EngagementFeeBasedArrayList;
  }

  // Public Standart Functions

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }


  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  // Note: tooltips are currently handled as string variables, uncomment block and adjust markup if they need to be retrieved from BE

   getTooltip(tooltipTitle: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  } 

  // Custom functions for section

  onTotalAmountsPaidSelection(event :any) {
    this.feesFormGroup.controls.totalAmountsPaid.setValue(event);
  }

  onRetainerReceivedSelection(event :any) {
    this.feesFormGroup.controls.retainerReceived.setValue(event);
    this.evaluateRetainerReceived(event);
  }

  evaluateRetainerReceived(value: string) {
    if (value === 'YES') {
      this.feesFormGroup.controls.periodicOrFlat.enable();
      this.feesFormGroup.controls.amountRetainer.enable();
      this.feesFormGroup.controls.periodRetainer.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.periodicOrFlat));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.amountRetainer));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.periodRetainer));
    }
  }

  onPeriodicOrFlatSelection(event:any) {
    this.feesFormGroup.controls.periodicOrFlat.setValue(event.value);
  }

  onAmountRetainerSelection(event:any) {
    this.feesFormGroup.controls.amountRetainer.setValue(event);
  }

  onRetainerCreditedSelection(event:any) {
    this.feesFormGroup.controls.retainerCredited.setValue(event);
  }

  onEngagementFeeBasedSelection(event:any) {
    this.feesFormGroup.controls.engagementFeeBased.setValue(event.value);
  }

  onSuccessFeePaidFullSelection(event:any) {
    this.feesFormGroup.controls.successFeePaidFull.setValue(event);
    this.evaluateSuccessFeePaidFull(event);
  }

  evaluateSuccessFeePaidFull(value: string) {
    if (value === 'NO') {
      this.feesFormGroup.controls.amountSuccessPaid.enable();
      this.feesFormGroup.controls.potentialAmountOutstanding.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.amountSuccessPaid));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.potentialAmountOutstanding));
    }
  }

  onAmountSuccessPaidSelection(event:any) {
    this.feesFormGroup.controls.amountSuccessPaid.setValue(event);
  }

  onPotentialAmountOutstandingSelection(event:any) {
    this.feesFormGroup.controls.potentialAmountOutstanding.setValue(event);
  }

  onDateSuccessFeeReceivedSelection(event :any) {
    this.feesFormGroup.controls.dateSuccessFeeReceived.setValue(event);
  }

  onExpensesBilledSelection(event :any) {
    this.feesFormGroup.controls.expensesBilled.setValue(event);
  }

  onFinderReferralFeeOwedSelection(event :any) {
    this.feesFormGroup.controls.finderReferralFeeOwed.setValue(event);
    this.evaluateFinderReferralFeeOwed(event);
  }

  evaluateFinderReferralFeeOwed(value: string) {
    if (value === 'YES') {
      this.feesFormGroup.controls.amountFinderOwed.enable();
      this.feesFormGroup.controls.recipientFinderFee.enable();
      this.feesFormGroup.controls.datePaidFinderFee.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.amountFinderOwed));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.recipientFinderFee));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.datePaidFinderFee));
    }
  }

  onAmountFinderOwedSelection(event :any) {
    this.feesFormGroup.controls.amountFinderOwed.setValue(event);
  }

  onDatePaidFinderFeeSelection(event :any) {
    this.feesFormGroup.controls.datePaidFinderFee.setValue(event);
  }

  // Private Standart Functions

  private _setAsDisabled(formControlName: string) {
    const controlName = this.feesFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

}
