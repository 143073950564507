export class TooltipObject {
  FieldName: string;
  TooltipText: string;
  TooltipTitle: string;

  constructor(fieldName: string, tooltipText: string, tooltipTitle: string) {
    this.FieldName = fieldName;
    this.TooltipText = tooltipText;
    this.TooltipTitle = tooltipTitle;
  }

  getTooltipText(): string {
    return this.TooltipText;
  }

  getTooltipTitle(): string {
    return this.TooltipTitle;
  }
}
