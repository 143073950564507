import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppSettings } from 'src/shared/app-settings';
import { TypeaheadItem } from 'src/shared/models/typeahead-item';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { FormListFields } from 'src/app/models/form-list-fields';
import { DropdownComponent } from 'src/shared/components/dropdown/dropdown.component';
import { FormValidations } from 'src/shared/utils/form-validations';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';
import { FormActionGroupValue } from '../../models/form-action-group-value';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';

@Component({
  selector: 'app-client-setup',
  templateUrl: './client-setup.component.html',
})

export class ClientSetupComponent implements OnInit {

  @Input() formGroups: Array<FormGroup>;
  @Input() formSubmitted: boolean;

  @ViewChild ('dropdownReasonClientProcedures')
  dropdownReasonClientProcedures: DropdownComponent;

  deloitteUSEntityCustomList: string;
  salesOfficeCustomList: string;
  ownershipTypeI1List: any = null;
  ownershipTypeI2List: any = null;
  ownershipTypeI13List: any = null;
  globalClientPortfolioList: any = null;
  attestStatusList: any = null;
  attestStatusParentList: any = null;
  entityOnWhichPerformsAttestWorkList: any = null;
  descDesignationList: any = null;
  reasonForBypassingProceduresList: any = null;
  sharePointMapper = new NewDealSetupSharepointMapper();
  clientSetupFormGroup: FormGroup;
  myDealFormGroup: any;

  constructor(
      private dropdownService: DropdownService,
      private tooltipHelperService: TooltipHelperService) {
    this.deloitteUSEntityCustomList = AppSettings.deloitteUSEntityCustomList;
    this.salesOfficeCustomList = AppSettings.salesOfficeCustomList;
  }

  private deloitteUSEntitySetManually: boolean;
  private salesOfficeSetManually: boolean;
  private sicCodeSetManually: boolean;
  private buyerNameSetManually: boolean;

  ngOnInit() {
    this.clientSetupFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
    this.ownershipTypeI1List = AppSettings.OwnerTypeL1ListArray;
    this.ownershipTypeI2List = AppSettings.OwnerTypeL2ListArray;
    this.ownershipTypeI13List = AppSettings.OwnerTypeL3ListArray;
    this.globalClientPortfolioList = AppSettings.GlobalClientPortfolioListArray;
    this.attestStatusList = AppSettings.AttestStatusListArray;
    this.attestStatusParentList = AppSettings.AttestStatusParentListArray;
    this.entityOnWhichPerformsAttestWorkList = AppSettings.EntityOnWhichPerformsAttestWorkListArray;
    this.descDesignationList = AppSettings.DescDesignationListArray;
    this.reasonForBypassingProceduresList = AppSettings.ReasonForBypassingProceduresListArray;
  }

  setSharepointMapper(mapper: NewDealSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  onDeloitteUSEntitySelection(event: TypeaheadItem) {
    //this.clientSetupFormGroup.controls.deloitteUSEntityClient.setValue(event.Key);
    this.clientSetupFormGroup.controls.deloitteUSEntityClient.setValue(event.Value);
    this.deloitteUSEntitySetManually = true;
  }

  onSalesOfficeSelection(event: TypeaheadItem) {
    //this.clientSetupFormGroup.controls.salesOfficeClient.setValue(event.Key);
    this.clientSetupFormGroup.controls.salesOfficeClient.setValue(event.Value);
    this.salesOfficeSetManually = true;
  }

  onOwnershipTypeI1Selection(event:any) {
    this.clientSetupFormGroup.controls.ownershipTypeI1.setValue(event.value);
  }

  onOwnershipTypeI2Selection(event:any) {
    this.clientSetupFormGroup.controls.ownershipTypeI2.setValue(event.value);
  }

  onOwnershipTypeI13Selection(event:any) {
    this.clientSetupFormGroup.controls.ownershipTypeI13.setValue(event.value);
  }

  onDateBecameClientSelection(event:any) {
    this.clientSetupFormGroup.controls.dateBecameClient.setValue(event);
  }

  onfinancialYearEndSelection(event:any) {
    this.clientSetupFormGroup.controls.financialYearEnd.setValue(event);
  }

  onConsultingSICCodeSelection(event:any) {
    this.clientSetupFormGroup.controls.consultingSICCode.setValue(event);
  }

  onGlobalUltimateParentIDSelection(event:any) {
    this.clientSetupFormGroup.controls.globalUltimateParentID.setValue(event);
  }

  onGlobalClientPortfolioSelection(event:any) {
    this.clientSetupFormGroup.controls.globalClientPortfolio.setValue(event.value);
  }

  onGlobalClientIntelligenceNumberSelection(event:any) {
    this.clientSetupFormGroup.controls.globalClientIntelligenceNumber.setValue(event);
  }

  onBillingAddressSameClientAddressSelection(event:any) {
    this.myDealFormGroup.controls.billingAddressSameClientAddress.setValue(event);
    this.evaluateBillingAddressDependency(event);
  }

  onPayerAddressSameClientAddressSelection(event:any) {
    this.myDealFormGroup.controls.payerAddressSameClientAddress.setValue(event);
    this.evaluatePayerAddressDependency(event);
  }

  onServiceAddressSameClientAddressSelection(event:any) {
    this.myDealFormGroup.controls.isServiceSameAsClientAddress.setValue(event);
    this.evaluateServiceAddressDependency(event);
  }

  onAttestStatusSelection(event:any) {
    this.clientSetupFormGroup.controls.attestStatus.setValue(event.value);
    this.evaluateAttestStatusDependency(event.value);
  }

  onAttestStatusParentSelection(event:any) {
    this.clientSetupFormGroup.controls.attestStatusParent.setValue(event.value);
  }

  onEntityOnWhichPerformsAttestWorkSelection(event:any) {
    this.clientSetupFormGroup.controls.entityOnWhichPerformsAttestWork.setValue(event.value);
  }

  onCurrentAuditorTenureExpiryDateSelection(event:any) {
    this.clientSetupFormGroup.controls.currentAuditorTenureExpiryDate.setValue(event);
  }

  onAuditorStartDateSelection(event:any) {
    this.clientSetupFormGroup.controls.auditorStartDate.setValue(event);
  }

  onPcaobReviewSelection(event:any) {
    this.clientSetupFormGroup.controls.pcaobReview.setValue(event);
  }

  onDescDesignationSelection(event:any) {
    this.clientSetupFormGroup.controls.descDesignation.setValue(event.value);
  }

  onApprovingPartnerTelephoneSelection(event:any) {
    this.clientSetupFormGroup.controls.approvingPartnerTelephone.setValue(event);
  }

  onClientAcceptanceProceduresSelection(event:any) {
    this.clientSetupFormGroup.controls.clientAcceptanceProcedures.setValue(event);
    this.evaluateClientAcceptanceProceduresDependency(event);
  }

  onReasonForBypassingProceduresSelection(event:any) {
    this.clientSetupFormGroup.controls.reasonForBypassingProcedures.setValue(event.value);
  }

  evaluateBillingAddressDependency(value:any) {
    if (value === 'NO') {
      this.myDealFormGroup.controls.billingAddress.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.billingAddress));
    }
  }

  evaluatePayerAddressDependency(value:any) {
    if (value === 'NO') {
      this.myDealFormGroup.controls.payerAddress.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.payerAddress));
    }
  }

  evaluateServiceAddressDependency(value:any) {
    if (value === 'NO') {
      this.myDealFormGroup.controls.serviceAtAddress.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.serviceAddress));
    }
  }

  evaluateClientAcceptanceProceduresDependency(value:any) {
    if (value === 'NO') {
      this.clientSetupFormGroup.controls.reasonForBypassingProcedures.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.reasonForBypassingProcedures));
    }
  }

  evaluateAttestStatusDependency(value:any) {
    if (value === AppSettings.attestStatusDefaultValue) {
      this.clientSetupFormGroup.controls.attestStatusParent.disable();
      this.myDealFormGroup.controls.currentAuditor.disable();
      this.myDealFormGroup.controls.previousAuditor.disable();
      this.clientSetupFormGroup.controls.memberFirmPerformingAttest.disable();
      this.clientSetupFormGroup.controls.entityOnWhichPerformsAttestWork.disable();
      this.clientSetupFormGroup.controls.currentAuditorTenureExpiryDate.disable();
      this.clientSetupFormGroup.controls.auditorStartDate.disable();
      this.clientSetupFormGroup.controls.pcaobReview.disable();
      this.clientSetupFormGroup.controls.descDesignation.disable();
      this.clientSetupFormGroup.controls.approvingPartnerTelephone.disable();
      this.clientSetupFormGroup.controls.clientAcceptanceProcedures.disable();
      this.clientSetupFormGroup.controls.reasonForBypassingProcedures.disable();
    } else {
      this.clientSetupFormGroup.controls.attestStatusParent.enable();
      this.myDealFormGroup.controls.currentAuditor.enable();
      this.myDealFormGroup.controls.previousAuditor.enable();
      this.clientSetupFormGroup.controls.memberFirmPerformingAttest.enable();
      this.clientSetupFormGroup.controls.entityOnWhichPerformsAttestWork.enable();
      this.clientSetupFormGroup.controls.currentAuditorTenureExpiryDate.enable();
      this.clientSetupFormGroup.controls.auditorStartDate.enable();
      this.clientSetupFormGroup.controls.pcaobReview.enable();
      this.clientSetupFormGroup.controls.descDesignation.enable();
      this.clientSetupFormGroup.controls.approvingPartnerTelephone.enable();
      this.clientSetupFormGroup.controls.clientAcceptanceProcedures.enable();

      if((this.clientSetupFormGroup.controls.attestStatus as CustomFormControl).isReadOnly()){
        (this.clientSetupFormGroup.controls.attestStatusParent as CustomFormControl ).setReadOnly();
        (this.myDealFormGroup.controls.currentAuditor as CustomFormControl ).setReadOnly();
        (this.myDealFormGroup.controls.previousAuditor as CustomFormControl ).setReadOnly();
        (this.clientSetupFormGroup.controls.memberFirmPerformingAttest as CustomFormControl).setReadOnly();
        (this.clientSetupFormGroup.controls.entityOnWhichPerformsAttestWork as CustomFormControl).setReadOnly();
        (this.clientSetupFormGroup.controls.currentAuditorTenureExpiryDate as CustomFormControl).setReadOnly();
        (this.clientSetupFormGroup.controls.auditorStartDate as CustomFormControl).setReadOnly();
        (this.clientSetupFormGroup.controls.pcaobReview as CustomFormControl).setReadOnly();
        (this.clientSetupFormGroup.controls.descDesignation as CustomFormControl).setReadOnly();
        (this.clientSetupFormGroup.controls.approvingPartnerTelephone as CustomFormControl).setReadOnly();
        (this.clientSetupFormGroup.controls.clientAcceptanceProcedures as CustomFormControl).setReadOnly();
      }
    }
  }

  isNotAttestSelected(): string {
    return this.clientSetupFormGroup.get('attestStatus')?.value !== AppSettings.attestStatusDefaultValue ? '' : 'N/A';
  }

  getDeloitteUsEntitySetManually(): boolean {
    return this.deloitteUSEntitySetManually;
  }

  setDeloitteUsEntity(item:any) {
    //this.clientSetupFormGroup.controls.deloitteUSEntityClient.setValue(item.Key);
    this.clientSetupFormGroup.controls.deloitteUSEntityClient.setValue(item.Value);
  }

  getSalesOfficeSetManually(): boolean {
    return this.salesOfficeSetManually;
  }

  setSalesOffice(item:any) {
    //this.clientSetupFormGroup.controls.salesOfficeClient.setValue(item.Key);
    this.clientSetupFormGroup.controls.salesOfficeClient.setValue(item.Value);
  }

  getConsultingSicCodeSetManually(): boolean {
    return this.sicCodeSetManually;
  }

  setConsultingSicCode(sicCode: string) {
    this.clientSetupFormGroup.controls.consultingSICCode.setValue(sicCode);
  }

  sicCodeSetManuallyEvent() {
    this.sicCodeSetManually = true;
  }

  getBuyerNameSetManually(): boolean {
    return this.buyerNameSetManually;
  }

  setBuyerName(buyerNameClient: string) {
    this.myDealFormGroup.controls.buyerNameClient.setValue(buyerNameClient);
  }

  buyerNameSetManuallyEvent(event:any) {
    this.buyerNameSetManually = true;
    this.myDealFormGroup.controls.buyerName.setValue(event.target.value);
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }
  
  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }
  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldName(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _setAsDisabled(formControlName: string) {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      const controlName = formGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

}
