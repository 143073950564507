export class PeoplePickerUser {
    Key: string;
    Description: string;
    DisplayText: string;
    displayName:string;
    id:number;
    mail:string;
    EntityType: string;
    ProviderDisplayName: string;
    ProviderName: string;
    IsResolved: boolean;
    EntityData: EntityData;
    MultipleMatches: Array<any>;
    LeftDCF: boolean;
}

export class EntityData {
    Title: string;
    Organization: string;
    Email: string;
    AccountName: string;
}
export class LcsprUser {
    displayName: string;
    mail: string;
}