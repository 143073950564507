import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  staticAlertClosed = false;
  alertMessage: any;

  @Input()
  type = '';

  @Input()
  dismissible = true;

  @Input()
  selfClosingTime = 5000;

  constructor() { }

  private alert$ = new Subject<string>();

  ngOnInit() {
    setTimeout(() => this.staticAlertClosed = true, 20000);

    this.alert$.subscribe((message) => this.alertMessage = message);
    this.alert$.pipe(
      debounceTime(this.selfClosingTime)
    ).subscribe(() => this.alertMessage = null);
  }

  public displayAlertMessage(message: string) {
    this.alert$.next(message);
  }

}
