import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

interface InputValue {
  value: any;
  formGroup: string;
  controlName: string;
}

@Injectable({
  providedIn: 'root'
})

export class DefaultValues {
  defaultValues: Map<string, InputValue>;

  constructor() {
    this.defaultValues = new Map<string, InputValue>([
      [ 'EngagementTypeName', {value: 'Client Commercial Engagement - SBR', formGroup: 'engagementInformationFormGroup', controlName: 'engagementTypeName'}],
      [ 'IntegratedMarketOffering', {value: 'M&A Transaction CFMO', formGroup: 'myDealFormGroup', controlName: 'integratedMarketOffering'}],
      [ 'DeloitteUSEntityId', {value: 37, formGroup: 'myDealFormGroup', controlName: 'deloitteUSEntity'}],
      //[ 'IsSubcontractingArrangement', {value: 'YES', formGroup: 'invoiceDetailsAndAddressesFormGroup', controlName: 'isSubcontractingArrangement'}],
      [ 'AllowInvoiceConsolidation', {value: 'YES', formGroup: 'invoiceDetailsAndAddressesFormGroup', controlName: 'allowInvoiceConsolidation'}],
      [ 'ModelAfterPriorEngagement', {value: 'NO', formGroup: 'engagementInformationFormGroup', controlName: 'modelAfterPriorEngagement'}],
      [ 'DerivedAtAddressSameClientAddres', {value: 'YES', formGroup: 'participationFormGroup', controlName: 'derivedAtAddressSameClientAddres'}],
      [ 'InvoiceLayout', {value: 'Summary page only', formGroup: 'invoiceDetailsAndAddressesFormGroup', controlName: 'invoiceLayout'}],
      [ 'InvoiceMedium', {value: 'Print', formGroup: 'invoiceDetailsAndAddressesFormGroup', controlName: 'invoiceMedium'}],
      [ 'BillingCurrency', {value: 'USD', formGroup: 'invoiceDetailsAndAddressesFormGroup', controlName: 'billingCurrency'}],
      [ 'PaymentTerms', {value: 'Due upon receipt', formGroup: 'invoiceDetailsAndAddressesFormGroup', controlName: 'paymentTerms'}],
      [ 'FXRate', {value: 'Variable', formGroup: 'invoiceDetailsAndAddressesFormGroup', controlName: 'fxRate'}],
      //[ 'BillingPlanType', {value: 'Periodic', formGroup: 'billingScheduleFormGroup', controlName: 'billingPlanType'}],
      [ 'ContractType', {value: 'Deloitte T&M SBR', formGroup: 'pricingDetailsFormGroup', controlName: 'contractType'}],
      [ 'ResourceRateType', {value: 'National Bill Rate', formGroup: 'pricingDetailsFormGroup', controlName: 'resourceRateType'}],
      [ 'USIResourceRateType', {value: 'Discountable', formGroup: 'pricingDetailsFormGroup', controlName: 'usiResourceRateType'}],
      [ 'BudgetGroup', {value: 'Budget Group 01', formGroup: 'pricingDetailsFormGroup', controlName: 'budgetGroup'}],
      [ 'PlannedCost', {value: '7500', formGroup: 'pricingDetailsFormGroup', controlName: 'plannedCost'}],
      [ 'PlannedNetRevenue', {value: '750000', formGroup: 'pricingDetailsFormGroup', controlName: 'plannedNetRevenue'}],
      [ 'Discount', {value: '100', formGroup: 'pricingDetailsFormGroup', controlName: 'discount'}],
      [ 'PlannedMargin', {value: '1', formGroup: 'pricingDetailsFormGroup', controlName: 'plannedMargin'}],
      [ 'TotalPlannedHours', {value: '1000', formGroup: 'pricingDetailsFormGroup', controlName: 'totalPlannedHours'}],
      [ 'TimeRevenueCap', {value: '0', formGroup: 'pricingDetailsFormGroup', controlName: 'timeRevenueCap'}],
      [ 'Material', {value: 'Management Consult', formGroup: 'myDealFormGroup', controlName: 'material'}],
      [ 'IsRecurringWBS', {value: 'NO', formGroup: 'myDealFormGroup', controlName: 'isRecurringWBS'}],
      [ 'EventIndicator', {value: 'NO', formGroup: 'pricingDetailsFormGroup', controlName: 'eventIndicator'}],
      [ 'IsThisCrossBorder', {value: 'NO', formGroup: 'clientInformationFormGroup', controlName: 'isThisCrossBorder'}]
      
    ]);
  }

  initializeAppFormGroup(appFormGroup: any, response:any) {
    this.defaultValues.forEach((obj: InputValue, key: string) => {
      const columnValue = this._getColumnValue(response, key);
      if (!columnValue) {
        const formGroup = obj.formGroup;
        const value = obj.value;
        const controlName = obj.controlName;
        appFormGroup.get(formGroup).get(controlName).setValue(value);
      }
    });
  }

  initializeSharepointObject(responseSharepointObject:any) {
    Object.keys(responseSharepointObject).forEach(column => {
      const exist = this.defaultValues.get(column);
      if (!responseSharepointObject[column] && exist) {
        const value = exist.value;
        responseSharepointObject[column] = value;
      }
    });
    return responseSharepointObject;
  }

  private _getColumnValue(response:any, key: string) {
    let value = null;
    response.forEach((formObject:any) => {
      Object.keys(formObject).forEach(columnName => {
        if (columnName === key) {
          value = formObject[key];
        }
      });
    });
    return value;
  }
}
