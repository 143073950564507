<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div id="loadingfade" style="display: none;">
  <div id="fadeloader" style="display: none;"></div>
  <app-notify-popup (addNotifyDetails)="addNotifyDetails($event)"></app-notify-popup>
</div>
<div class="container" style="width: 100%;">
  <div class="row">
    <div class="col-xs-6"><div class="headerManagement">ClosingChecklistFormS24ApprovalReport</div> </div>
    </div>
    <div class="row">
      <div class="col-xs-3">
            <div id="search-container">
              <app-search-box
                (reportListToEmit)="onSearchResult($event)">
              </app-search-box>
            </div>
          </div>
          <div class="col-xs-4">
            <div id="show-deals-container">
             
              <button
                type="button"
                class="clear-filters-button"
                (click)="clearFilters()">
                Clear Filters
              </button>
            </div>
          </div>
          <div class="col-xs-4">
            <div id="add-new-deal-container">
              <div>
                
              </div>
               
            </div>
          </div>
          <div class="col-sm-2" style="float:right ;">
            <button style="margin-left: 35%;width: 63%;height:6vh;"  type="button"
            class="engagement-dashboard-button btn_Custom" [disabled]="showExportButton()"
            (click)="exportToExcel()">
            Export to Excel
            </button>
          </div>
        </div>    
      <!-- </div> -->
        <div id="engagement-table-container" class="row">
            <app-closing-checklist-table></app-closing-checklist-table>
        </div>
    </div>
  
  <app-modal #Modal>
    <ng-container body>
        <ng-container
            *ngTemplateOutlet="modalBody">
        </ng-container>
    </ng-container>
    <ng-container footer>
        <ng-container
            *ngTemplateOutlet="modalFooter">
        </ng-container>
    </ng-container>
  </app-modal>
  <ng-template #modalBody>
    <div [ngSwitch]="modalType">
       
        <h3 *ngSwitchCase="'no-dcf-member'" class="text-primary text-center text-bigger">
          You are not allowed to access this form.
        </h3>
        
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <div [ngSwitch]="modalType">
      
      <!-- <div *ngSwitchCase="'no-dcf-member'">
        <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
      </div> -->
      
    </div>
  </ng-template>

<!-- <div id="form-body">
    <div id="form-sections">
        
    </div>
</div> -->
