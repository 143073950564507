<div style="width: 200px;margin-left: -9px;line-height: initial;" class="col-xs-10" id="div-navigation-index" [style]="isFixed">
    <nav>
        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#project-overview')" class="bg-blue-a-active">Project Overview</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#client-overview')" >Client Overview</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#deal-overview')" >Deal Overview</a></li>
        </ul>
       
        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#dates-timeline')" >Dates/TimeLine</a></li>
        </ul>
        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#dcf-fee-structure')">DCF Fee Structure</a></li>
        </ul>

        <!-- TODO: Remove below UL Tag when the API is ready -->
        <!-- <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#finance')">Finance</a></li>
        </ul> -->
        
        <ul id="ul-navigation-section" [ngClass]="!isFinanceForm ? 'visibility-text' : 'nav'">
            <li><a (click)="onclick('#finance')">Finance</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#valuation-metrics')" >Valuation Metrics</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#buyer-deal-marketing')" >Buyer/Deal Marketing</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#deal-structure')" >Deal Structure</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#indemnification')" >Indemnification</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#quality-of-earnings')">Quality Of Earnings</a></li>
        </ul>


        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#engagement-overview')">Compliance Form</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#additional-commentary')">Additional Commentary</a></li>
        </ul>
       

        <ul id="ul-navigation-section" class="nav" *ngIf="ShowDealTeamSignoffSection">
            <li><a (click)="onclick('#deal-team-signoffs')">Deal Team Signoffs</a></li>
        </ul>
        
        <!-- <ul id="ul-navigation-section" class="nav" *ngIf="ShowSignoffSection">
            <li><a (click)="onclick('#supervisory-signoff')">Supervisory Signoff</a></li>
        </ul> -->

        <ul id="ul-navigation-section" class="nav" *ngIf="ShowSeries24ApprovalSection">
            <li><a (click)="onclick('#series-24-final-approval')">Series 24 Final Approval</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav" *ngIf="showReactivateSection">
            <li><a (click)="onclick('#reactivate-form')">Reactivate Form</a></li>
        </ul>

        <ul id="ul-navigation-section" class="nav" *ngIf="ShowSubmitForReviewSection">
            <li><a (click)="onclick('#submit-for-review')">Submit Form for Review</a></li>
        </ul>
        <button *ngIf="!isAutoSaveEnabled&&ShowSubmitForReviewSection"
                  class="btn btn-outline-primary save-rework-cancel-btn margin-left-10 savebuttonstyles"
                  [disabled]="saveInProgress"
                  (click)="onSaveForm()">
                  Save
                </button>
    </nav>
    <div id="back-to-top-button" class="hidden">
        <span (click)="scrollTop()" class="glyphicon glyphicon-menu-up"></span>
    </div>
</div>
