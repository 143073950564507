import { FormStatusValue } from 'src/shared/models/form-status-value';
import { StatusValue } from 'src/shared/models/form-status-value';
import { AdminApprovalObject } from '../../app/models/admin-approval-object';
import { Injectable } from '@angular/core';
import { AdminDiscontinueObject } from '../../app/models/admin-discontinue-object';
import { MainService } from 'src/shared/services/main.service';
import { AdminReturnForReworkObject } from '../../app/models/admin-return-for-rework-object';
import { SubmitObject } from '../../app/models/submit-object';
import { AppContext } from '../../app/models/app-context';
// import { EngagementHealthObject } from '../../models/engagement-health-object';
import { AdminAssigned } from '../../app/models/admin-assigned';
import { AppSettings } from 'src/shared/app-settings';
import { RetryWorkflowObject } from '../../app/models/retry-workflow-object';
import { SharepointObjectNewDeal } from '../../app/models/sharepoint-object-new-deal';
import { DocumentTypeItem } from 'src/shared/models/document-type-item';
import { AttachmentItem } from 'src/shared/models/attachment-item';
import { SharepointObjectMyDeal } from '../../app/models/sharepoint-object-my-deal';
import { AdminReturnForReworkCommentObject } from '../../app/models/admin-return-for-rework-comment-object';

@Injectable({
  providedIn: 'root'
})

export class NewDealSetupServiceHelper {
  
  constructor(private mainService: MainService) {}
  
  createAdminApprovalObject(): any {
    // let userName = localStorage.getItem("AuditorFullName")?.toString();
    let userName = localStorage.getItem("NewDealAuditorName")?.toString();
    const objectToSend: AdminApprovalObject = {
      AdminApprovalDate: new Date(),
      AdminApprovalName: userName,
      FormStatus: FormStatusValue.approved,
      Status:StatusValue.completed
    };
    return objectToSend;
  }

  createAdminDiscontinueObject(formStatus: string, discontinueReason: string,formActionGroup:any): AdminDiscontinueObject {
    // let userName = localStorage.getItem("AuditorFullName")?.toString();
    let userName = localStorage.getItem("NewDealAuditorName")?.toString();
    const objectToSend: AdminDiscontinueObject = {
      DiscontinuedOn: new Date(),
      DiscontinuedBy: userName,
      DiscontinueReason: discontinueReason,
      FormStatus: FormStatusValue.discontinued,
      DiscontinuedFormStatus: formStatus,
      Status:StatusValue.discontinued,
      DiscontinuedStatus:getStatusByFormStatus(formStatus,formActionGroup)
    };
    return objectToSend;
  }

  createAdminReturnForReworkObject(returnForReworkReason: string): AdminReturnForReworkObject {
    // let userName = localStorage.getItem("AuditorFullName")?.toString();
    let userNameEmail = localStorage.getItem("userProfileId")?.toString();
    const objectToSend: AdminReturnForReworkObject = {
      ReturnForReworkReason: returnForReworkReason,
      FormStatus: FormStatusValue.rejected,
      ReturnedByUser: userNameEmail,
      Status:StatusValue.returnedToRework
    };
    return objectToSend;
  }

  createReturnForReworkCommentsObject(formId: number, comment: string):AdminReturnForReworkCommentObject {
    // let userName = localStorage.getItem("AuditorFullName")?.toString();
    let userName = localStorage.getItem("NewDealAuditorName")?.toString();
    const objectToSend: AdminReturnForReworkCommentObject = {
      FormID : formId,
      Comment : comment,
      ReturnedByUser: userName,
      Created:new Date().toString(),
      CreatedBy:userName,
      ID:''
    };
    return objectToSend;
  }

  createSubmitObject(admin: AdminAssigned, submissionDate: string): SubmitObject {
    const objectToSend: SubmitObject = {
      SubmissionDate: submissionDate,
      FormStatus: FormStatusValue.submitted,
      AdminAssigned: admin.adminEmail,
      AdminAssignedDisplayName:admin.adminName,
      Status:StatusValue.AdminReviewpending
    };
    return objectToSend;
  }

  createRetryWorkflowObject(formStatus: string): RetryWorkflowObject {
    const objectToSend: RetryWorkflowObject = {
      FormStatus: formStatus,
      ExecuteWorkflow: "true",
    };
    return objectToSend;
  }

  createAppContext(autoSaveInterval: number, autoSaveEnabled: boolean, excludedFromAdminRotationCycle: string,autoSaveSpinningWheelInterval:number): AppContext {
    let maxLengthComments = 0;
    const appContext: AppContext = {
      autoSaveInterval,
      autoSaveEnabled,
      excludedFromAdminRotationCycle,
      autoSaveSpinningWheelInterval,
      maxLengthComments
    };
    return appContext;
  }

  createEngagementObjectToSend(newDealId: number, myDealId: number,myDealData:any): any {
    const engObject:any = {};
    const userName = localStorage.getItem("AuditorFullName");
    engObject[AppSettings.myDealSiteColumn] = myDealId;
    engObject['EngagementName'] = myDealData.EngagementName;
    engObject['ClientName'] = myDealData.ClientName;
    engObject['AdminAssigned'] = myDealData.AdminAssigned;
    engObject['CreatedBy'] = userName;
    engObject['ModifiedBy'] = userName;
    return engObject;
  }

  createAdminAssignedObjectToSend(admin : any): any{    
    const objectToSend: any = {            
      AdminAssigned: admin.adminEmail,
      AdminAssignedDisplayName:admin.adminName
    };
    return objectToSend;



  }
  
  createNewDealObjectToSend(myDealId: number) {
    const newDealObject:any = {};
    newDealObject[AppSettings.myDealSiteColumn] = myDealId.toString();
    return newDealObject;
  }

  createAdminReviewAssignmentObject(lastAssignmentDate: string) {
    return {"LastAssignmentDate": lastAssignmentDate};
  }

  createAdminAssigned(adminName: string, assignmentDate: string,adminEmail:string): AdminAssigned {
    const nextAdmin: AdminAssigned = {      
      assignmentDate,
      adminName,
      adminEmail
    };
    return nextAdmin;
  }

  createNewDealSetupObject(response:any): SharepointObjectNewDeal {
    const newDealObject: SharepointObjectNewDeal = {
      // Project overview section
      //EngagementName: response.ProjectName,
      //Series24Id: response.Series24Id,
      DealTeamTable: response.DealTeamTable,
      IsIndiaInvolved: response.IsIndiaInvolved,
      IsPriorDESattendee:response.IsPriorDESattendee,
      DeloitteAlumniInvolved: response.DeloitteAlumniInvolved,
      DeloitteAlumniName: response.DeloitteAlumniName,
      DeloitteAlumniRole: response.DeloitteAlumniRole,
      OpportunityViaGlobalSharepoint: response.OpportunityViaGlobalSharepoint,
      InternationalCommunity: response.InternationalCommunity,
      //ReferralPercentage: response.ReferralPercentage,
      CrossBusinessOffering: response.CrossBusinessOffering,
      SourceName: response.SourceName,
      EngagementIndustrySector: response.EngagementIndustrySector,
      //EngagementIndustrySICCode: response.EngagementIndustrySICCode,
      //Source: response.Source,
      //ReferralSourceName: response.ReferralSourceName,
      //TransactionType: response.TransactionType,
      //IntegratedMarketOffering: response.IntegratedMarketOffering,

      // Client overview
      //ClientName: response.ClientLegalName,
      //ClientType: response.ClientType,
      LeadClientServicePartnerId: response.LeadClientServicePartnerId,
      LeadBusinessPartnerId: response.LeadBusinessPartnerId,
      GlobalLCSPId: response.GlobalLCSPId,
      DoingBusinessAs: response.DoingBusinessAs,
      //StreetAddress: response.StreetAddress,
      //City: response.City,
      //State: response.State,
      //PostalCode: response.PostalCode,
      //TelephoneNumber: response.TelephoneNumber,
      //Industry: response.Industry,

      // New Deal
      ClientRevenue: response.ClientRevenue,
      StatusNewDeal: response.StatusNewDeal,
      BusinessDescription: response.BusinessDescription,
      PubliclyTradedSecurities: response.PubliclyTradedSecurities,
      IsPubliclyTradedDebt: response.IsPubliclyTradedDebt,
      Cusip: response.Cusip,
      FirmType: response.FirmType,
      ObaPstPersonnelConflict: response.ObaPstPersonnelConflict,
      CommentsNewDeal: response.CommentsNewDeal,
      ObaPstPersonnelConflictDate: response.ObaPstPersonnelConflictDate,
      BackgroundCheckCompletion: response.BackgroundCheckCompletion,
      OfacCompletion: response.OfacCompletion,
      ConflictCheckCompletion: response.ConflictCheckCompletion,
      CompetitorsList: response.CompetitorsList,
      OtherCompetitor: response.OtherCompetitor,

      //EBITDA: response.EBITDA,
      // IsCompetitivePitch: response.IsCompetitivePitch,
      // Subsector: response.Subsector,
      // IsPubliclyTradedEquity: response.IsPubliclyTradedEquity,
      // TickerCode: response.TickerCode,
      

      // Mercury
      M_AConfidentialSubsidiary: response.M_AConfidentialSubsidiary,
      OpportunityPhase: response.OpportunityPhase,
      TotalValue: response.TotalValue,
      //BuyerRole: response.BuyerRole,
      Initiative: response.Initiative,
      //IsThisAReproposal: response.IsThisAReproposal,
      IsThisARecurringOpportunity: response.IsThisARecurringOpportunity,
      //AnnualOpportunity: response.AnnualOpportunity,
      //IsThisAPullThroughOpportunity: response.IsThisAPullThroughOpportunity,
      IsThisAFollowOnOpportunity: response.IsThisAFollowOnOpportunity,
      COVID19RelatedNewOpportunity : response.COVID19RelatedNewOpportunity,
      Category: response.Category,
      SubCategory: response.SubCategory,
      Offering: response.Offering,
      Subcontractor: response.Subcontractor,
      AllianceProduct: response.AllianceProduct,
      PursuitStartDate: response.PursuitStartDate,
      ExpectedCloseDate: response.ExpectedCloseDate,
      MemberFirmRecognizingRevenue: response.MemberFirmRecognizingRevenue,
      //OriginalOpportunityID: response.OriginalOpportunityID,
      BuyerName: response.BuyerName,
      //FeeType: response.FeeType,
      //ProjectStartDate: response.ProjectStartDate,
      //ProjectEndDate: response.ProjectEndDate,


      // PRD
      //ClientID: response.ClientID,
      //EngagementType: response.EngagementType,
      //IsRecurringWBS: response.IsRecurringWBS,
      //DeloitteUSEntityId: response.DeloitteUSEntityId,
      //SalesOfficeId: response.SalesOfficeId,
      //IsInternationalEngagement: response.IsInternationalEngagement,
      //AreResourcesWorkOutside: response.AreResourcesWorkOutside,
      //MemberFirmTaxablePresence: response.MemberFirmTaxablePresence,
      //OhterMemberFirmsParticipating: response.OhterMemberFirmsParticipating,
      //WhereWorkMemberFirms: response.WhereWorkMemberFirms,
      // MarketOffering: response.MarketOffering,
      // Material: response.Material,

      // Client setup
      // SalesOfficeClientId: response.SalesOfficeClientId,
      // DeloitteUSEntityClientId: response.DeloitteUSEntityClientId,
      SalesOfficeClient: response.SalesOfficeClient,
      DeloitteUSEntityClient: response.DeloitteUSEntityClient,
      OwnershipTypeI1: response.OwnershipTypeI1,
      OwnershipTypeI2: response.OwnershipTypeI2,
      OwnershipTypeI13: response.OwnershipTypeI13,
      //DateBecameClient: response.DateBecameClient,
      //FinancialYearEnd: response.FinancialYearEnd,
      //ConsultingSICCode: response.ConsultingSICCode,
      GlobalUltimateParentID: response.GlobalUltimateParentID,
      GlobalClientPortfolio: response.GlobalClientPortfolio,
      GlobalClientIntelligenceNumber: response.GlobalClientIntelligenceNumber,
      GST: response.GST,
      BuyerTitle: response.BuyerTitle,
      //BuyerEmail: response.BuyerEmail,
      AttestStatus: response.AttestStatus,
      AttestStatusParent: response.AttestStatusParent,
      MemberFirmPerformingAttest: response.MemberFirmPerformingAttest,
      EntityOnWhichPerformsAttestWork: response.EntityOnWhichPerformsAttestWork,
      CurrentAuditorTenureExpiryDate: response.CurrentAuditorTenureExpiryDate,
      AuditorStartDate: response.AuditorStartDate,
      PcaobReview: response.PcaobReview,
      DescDesignation: response.DescDesignation,
      ApprovingPartnerTelephone: response.ApprovingPartnerTelephone,
      ClientAcceptanceProcedures: response.ClientAcceptanceProcedures,
      ReasonForBypassingProcedures: response.ReasonForBypassingProcedures,
      AnySpecialInstructions: response.AnySpecialInstructions,

      // BuyerNameClient: response.BuyerNameClient,
      // BillingAddressSameClientAddress: response.BillingAddressSameClientAddress,
      // BillingAddress: response.BillingAddress,
      // PayerAddressSameClientAddress: response.PayerAddressSameClientAddress,
      // PayerAddress: response.PayerAddress,
      // ServiceAddressSameClientAddress: response.ServiceAddressSameClientAddress,
      // ServiceAddress: response.ServiceAddress,
      // PreviousAuditor: response.PreviousAuditor,
      // CurrentAuditor: response.CurrentAuditor,
      
      
      // Admin Review
      SalesforceCode: response.SalesforceCode,
      SwiftCode: response.SwiftCode,
      //MercuryOpportunityCod: response.MercuryOpportunityCod,
      
    };
    
    return newDealObject;
  }
  createMyDealObject(response:any): SharepointObjectMyDeal {
    const myDealObject: SharepointObjectMyDeal = {
      ClientName: response.ClientName,
      ClientID: response.ClientID,
      EngagementName: response.EngagementName,
      EngagementType: response.EngagementType,
      StartDate: response.StartDate,
      EndDate: response.EndDate,
      IsRecurringWBS: response.IsRecurringWBS,
      DeloitteUSEntityId: response.DeloitteUSEntityId,
      IntegratedMarketOffering: response.IntegratedMarketOffering,
      //SalesOfficeId: response.SalesOfficeId,
      SalesOffice: response.SalesOffice,
      EngagementIndustrySICCode: response.EngagementIndustrySICCode,
      IsInternationalEngagement: response.IsInternationalEngagement,
      AreResourcesWorkOutside: response.AreResourcesWorkOutside,
      MemberFirmTaxablePresence: response.MemberFirmTaxablePresence,
      OtherMemberFirmsParticipating: response.OtherMemberFirmsParticipating,
      WhereWorkMemberFirms: response.WhereWorkMemberFirms,
      BillingAddressSameClientAddress: response.BillingAddressSameClientAddress,
      BillingAddress: response.BillingAddress,
      PayerAddressSameClientAddress: response.PayerAddressSameClientAddress,
      PayerAddress: response.PayerAddress,
      MarketOffering: response.MarketOffering,
      IsServiceSameAsClientAddress: response.IsServiceSameAsClientAddress,
      ServiceAtAddress: response.ServiceAtAddress,
      Material: response.Material,
      //Series24Id: response.Series24Id,
      Series24Id: response.Series24,
      // participatingPartner: response.Series24Id
      CurrentAuditor: response.CurrentAuditor,
      PreviousAuditor: response.PreviousAuditor,
      OpportunityID: response.OpportunityID,
      Telephone: response.Telephone,
      IsPubliclyTradedEquity: response.IsPubliclyTradedEquity,
      TickerCode: response.TickerCode,
      FeeType: response.FeeType,
      //BuyerName: response.BuyerName,
      ClientType: response.ClientType,
      City: response.City,
      EBITDA: response.EBITDA,
      Industry: response.Industry,
      IsCompetitivePitch: response.IsCompetitivePitch,
      PostalCode: response.PostalCode,
      ReferralSource: response.ReferralSource,
      ReferralSourceName: response.ReferralSourceName,
      State: response.State,
      StreetAddress: response.StreetAddress,
      Subsector: response.Subsector,
      TransactionType: response.TransactionType
    };
    return myDealObject;
  }

  createAttachmentsObject(responseAttachments:any,documentTypesValues?:any): Array<DocumentTypeItem> {
    const attachmentsObject: Array<DocumentTypeItem> = [];
    responseAttachments.forEach((attachment:any) => {
      if(attachment.DocumentType!=null && attachment.DocumentType!=undefined)
      {
      const documentTypeId = documentTypesValues.find((obj: { documentTypeTitle: any; }) => obj.documentTypeTitle===attachment.DocumentType)?.documentTypeItemId 
      const index = attachmentsObject.map(item => item.documentTypeId).indexOf(documentTypeId);
      const attachmentItem = new AttachmentItem();
      attachmentItem.attachmentName = attachment.FileName;
      if (index !== -1) {
        attachmentsObject[index].documentTypeAttachments.push(attachmentItem);
      } else {
        const documentTypeItem = new DocumentTypeItem();
        documentTypeItem.documentTypeId = documentTypeId;
        documentTypeItem.documentTypeAttachments = [];
        documentTypeItem.documentTypeAttachments.push(attachmentItem);
        attachmentsObject.push(documentTypeItem);
      }
    }
    });
    return attachmentsObject;
  }
   
}
function getStatusByFormStatus(formStatus: string,formActionGroup?:any) {
  switch(formStatus)
  {
    case FormStatusValue.approved:
      return StatusValue.completed
    case FormStatusValue.discontinued:
      return StatusValue.discontinued;
    case FormStatusValue.submitted:
      return StatusValue.AdminReviewpending
    case FormStatusValue.pending:
      return formActionGroup=="AdminReview" ? StatusValue.AdminReviewpending : StatusValue.returnedToRework
      case FormStatusValue.draft:
      return StatusValue.draft      
      case FormStatusValue.rejected:
        return StatusValue.returnedToRework
    default:
      return "";
  }
}

