import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  spinnerContainer;

  constructor() {
    this.spinnerContainer = document.getElementById('DeltaDelegateControls');
  }

  show() {
    if (this.spinnerContainer && !this.spinnerContainer.classList.contains('container-loader')) {
      this.spinnerContainer.classList.add('container-loader');
            document.body.style.overflow="hidden";
      let spinnerId=document.getElementById('test',) as HTMLInputElement;
      spinnerId.style.position = 'fixed';
      spinnerId.style.width="100%"
    }
  }

  hide() {
    if (this.spinnerContainer) {
      this.spinnerContainer.classList.remove('container-loader');
     // document.body.style.overflow="auto"; Added to hide scroll while loading the forms
      let spinnerId=document.getElementById('test',) as HTMLInputElement;
   spinnerId.style.position = 'initial';
    }
  }
  showAutoSaveSpinner(){
    let spinnerId=document.getElementById('loadingAuto',) as HTMLInputElement;
    spinnerId.style.display = 'block';
    document.body.style.overflowY='hidden';
  }
  hideAutoSaveSpinner(){
    let spinnerId=document.getElementById('loadingAuto',) as HTMLInputElement;
    spinnerId.style.display = 'none';
    document.body.style.overflowY='auto';
  }
}
