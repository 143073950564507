<div [formGroup]="valuationMetricsFormGroup" id="valuation-metrics" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span>Valuation Metrics</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>
                There will be some subjectivity in this section and we ask for multiple data points as we try to figure out which are the best to use for comparison between deals in aggregate.
             Use your best judgement and discuss with the deal team.
            </p>
            <p>
                Also, please explain the deal structure in the space provided. Include cash up front, 
            contingent payments, level of certainty around receiving the contingent payment, unique deal-specific considerations, etc.
            </p>
            <p>
                The goal of this section is to track statistics that give us the ability to demonstrate the value we deliver to clients. For example, in the last 2 years:<br>
                X% of deals closed above the breakpoint.<br>
                Of those that closed about breakpoint, they were an average of X% higher.<br>
                On average, bids increased X& between IOI and LOI.<br>
                On average, buyers paid an average premium of X% above the #2 bidder.<br>
            </p>
        </span>
    </div>
    <div id="valuation-metrics-section" class="content-section">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('closingConsideration')"
                    [class.edited-externally]="editedExternally('closingConsideration')"
                    [class.conflict-on-merge]="hasMergeConflict('closingConsideration')">Closing Consideration ($000s)</span>
                    <span *ngIf="showAsterisk('closingConsideration')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="ClosingConsideration" name="ClosingConsideration" class="input-data-number input-number non-decimal-input feeStructureFormulas" operations="TotalConsideration,EbitdaMultipleClosing,ValNumericalClosedConsideration,ValPercentageClosedConsideration,EbitdaMultipleTotal,ImpliedValuationPremium,ValuationPremiumOverBp,ValNumericalTotal,ValPercentageTotal,PremiumOverInitialOffer,CapPercentageEV,CapPercentageClosing,EscrowTotalEV,EscrowClosing,DeductibleEV,DeductibleClosing,ContingentTotalEVPct,StockConsiderationTotalEVPct,EquityContributionTotalPct,EquityContributionClosingPct"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'currency'" 
                        [readonly]="isReadOnly('closingConsideration')"
                        [value]="valuationMetricsFormGroup.get('closingConsideration').value"
                        (selectedValue)="onSelectedValue($event,'closingConsideration')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('contingentConsideration')"
                    [class.edited-externally]="editedExternally('contingentConsideration')"
                    [class.conflict-on-merge]="hasMergeConflict('contingentConsideration')">Contingent Consideration ($000s)</span>
                    <span *ngIf="showAsterisk('contingentConsideration')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="ContingentConsideration" name="ContingentConsideration"
                        class="input-data-number input-number non-decimal-input feeStructureFormulas enableDependencyInput"
                        operations="TotalConsideration,EbitdaMultipleTotal,ImpliedValuationPremium,ValuationPremiumOverBp,ValNumericalTotal,ValPercentageTotal,PremiumOverInitialOffer,CapPercentageEV,EscrowTotalEV,DeductibleEV,ContingentTotalEVPct,StockConsiderationTotalEVPct,EquityContributionTotalPct"
                        disable-target-input="TimeUntilContingentPayment,TriggerType"
                        disable-target-value-for-timeuntilcontingentpayment="0,N/A,''"
                        disable-target-value-for-triggertype="0,N/A,''"
                        value-when-input-disabled-for-timeuntilcontingentpayment="N/A"
                        value-when-input-disabled-for-triggertype="N/A">
                    <br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'currency'" 
                        [readonly]="isReadOnly('contingentConsideration')"
                        [value]="valuationMetricsFormGroup.get('contingentConsideration').value"
                        (selectedValue)="onSelectedValue($event,'contingentConsideration')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('totalConsideration')"
            [class.edited-externally]="editedExternally('totalConsideration')"
            [class.conflict-on-merge]="hasMergeConflict('totalConsideration')">Total Consideration ($000s)</span>
                    <span *ngIf="showAsterisk('totalConsideration')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="TotalConsideration" name="TotalConsideration" class="input-data-number input-number non-decimal-input" formula="( f.default( ClosingConsideration ,0) ) + ( f.default( ContingentConsideration ,0) )" format="negativeCurrency" disabled><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('totalConsideration')"
                        [value]="valuationMetricsFormGroup.get('totalConsideration').value"
                        [disabled]="true">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('ebitda')"
            [class.edited-externally]="editedExternally('ebitda')"
            [class.conflict-on-merge]="hasMergeConflict('ebitda')">EBITDA ($000s)</span>
                    <span *ngIf="showAsterisk('ebitda')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="Ebitda" name="Ebitda" class="input-data-number input-number non-decimal-input" operations="EbitdaMultipleClosing,EbitdaMultipleTotal,ImpliedValuationPremium"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('ebitda')"
                        [value]="valuationMetricsFormGroup.get('ebitda').value"
                        (selectedValue)="onSelectedValue($event,'ebitda')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('ebitdaPeriod')"
            [class.edited-externally]="editedExternally('ebitdaPeriod')"
            [class.conflict-on-merge]="hasMergeConflict('ebitdaPeriod')">EBITDA Period</span>
                    <span *ngIf="showAsterisk('ebitdaPeriod')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <select name="EbitdaPeriod" id="EbitdaPeriod" class="input-data-select input-select section-select">
                        <option value="0" disabled selected>Select your option</option>
                    </select><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-dropdown
                    [optionsList]="ebitdaPeriodOptions"
                    [keySelected]="valuationMetricsFormGroup.get('ebitdaPeriod').value"
                    [readonly]="isReadOnly('ebitdaPeriod')"
                    (optionSelected)="onEbitdaPeriodDropDownSelection($event,'ebitdaPeriod')">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('ebitdaMultipleTotal')"
            [class.edited-externally]="editedExternally('ebitdaMultipleTotal')"
            [class.conflict-on-merge]="hasMergeConflict('ebitdaMultipleTotal')">EBITDA Multiple (Total)</span>
                    <span *ngIf="showAsterisk('ebitdaMultipleTotal')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="EbitdaMultipleTotal" name="EbitdaMultipleTotal" class="input-data-number input-number multiply-decimal-input" formula="( TotalConsideration ) / ( Ebitda ) " format="multiply" disabled><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'multiply'"
                        [placeholder]="'0.0x'"
                        [readonly]="isReadOnly('ebitdaMultipleTotal')"
                        [value]="valuationMetricsFormGroup.get('ebitdaMultipleTotal').value"
                        [disabled]="true">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('ebitdaMultipleClosing')"
            [class.edited-externally]="editedExternally('ebitdaMultipleClosing')"
            [class.conflict-on-merge]="hasMergeConflict('ebitdaMultipleClosing')">EBITDA Multiple (Closing)</span>
                    <span *ngIf="showAsterisk('ebitdaMultipleClosing')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="EbitdaMultipleClosing" name="EbitdaMultipleClosing" class="input-data-number input-number multiply-decimal-input" formula="( ClosingConsideration ) / ( Ebitda )" format="multiply" disabled><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'multiply'"
                        [placeholder]="'0.0x'"
                        [readonly]="isReadOnly('ebitdaMultipleClosing')"
                        [value]="valuationMetricsFormGroup.get('ebitdaMultipleClosing').value"
                        [disabled]="true">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('finalEvPitchRange')"
            [class.edited-externally]="editedExternally('finalEvPitchRange')"
            [class.conflict-on-merge]="hasMergeConflict('finalEvPitchRange')">Final EV vs. Pitch Range</span>
                    <span *ngIf="showAsterisk('finalEvPitchRange')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <select name="FinalEvPitchRange" id="FinalEvPitchRange" class="input-data-select input-select section-select enableDependencyInput" disable-target-input="IfBelowWhyOtherReason,IfBelowWhy" disable-target-value-for-ifbelowwhy="Above,Within,N/A" value-when-input-disabled-for-ifbelowwhy="0" disable-target-value-for-ifbelowwhyotherreason="Above,Within,Below,N/A" value-when-input-disabled-for-ifbelowwhyotherreason="N/A">
                        <option value="0" disabled selected>Select your option</option>
                    </select><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-dropdown
                    [optionsList]="finalEvPitchRangeOptions"
                    [keySelected]="valuationMetricsFormGroup.get('finalEvPitchRange').value"
                    [readonly]="isReadOnly('finalEvPitchRange')"
                    (optionSelected)="onFinalEvPitchRangeDropDownSelection($event,'finalEvPitchRange')">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('ifBelowWhy')"
                    [class.edited-externally]="editedExternally('ifBelowWhy')"
                    [class.conflict-on-merge]="hasMergeConflict('ifBelowWhy')"
                    class="dependent-field">If below, why</span>
                    <span *ngIf="showAsterisk('ifBelowWhy')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8 double-column-IfBelowWhy">
                    <div class="col-xs-6">
                        <!-- <select name="IfBelowWhy" id="IfBelowWhy" class="input-data-select input-select section-select enableDependencyInput"
                            disable-target-input="IfBelowWhyOtherReason" disable-target-value-for-ifbelowwhyotherreason="Company performance,Market conditions,N/A" value-when-input-disabled-for-ifbelowwhyotherreason="N/A" disabled>
                            <option value="0" disabled selected>Select your option</option>
                        </select><br>
                        <span class="numeric-validation-msg"></span> -->
                        <app-dropdown class="dropdown-custom"
                        [optionsList]="ifBelowWhyOptions"
                        [keySelected]="valuationMetricsFormGroup.get('ifBelowWhy').value"
                        [readonly]="isReadOnly('ifBelowWhy')"
                        [disabled]="valuationMetricsFormGroup.get('ifBelowWhy').disabled"
                        (optionSelected)="onIfBelowWhyDropDownSelection($event,'ifBelowWhy')"
                        >
                        </app-dropdown>
                    </div>
                    <div class="col-xs-6">
                        <input type="text" id="IfBelowWhyOtherReason"  [formControl]="valuationMetricsFormGroup.get('ifBelowWhyOtherReason')"  
                        class="input-data-text section-input"
                         [placeholder]="valuationMetricsFormGroup.get('ifBelowWhyOtherReason').disabled ? 'N/A' : ''" 
                        [class.readonly]="isReadOnly('IfBelowWhyOtherReason')"
                        [disabled]="valuationMetricsFormGroup.get('ifBelowWhyOtherReason').disabled">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('pitchEBITDAMultipleRangeFirst')"
            [class.edited-externally]="editedExternally('pitchEBITDAMultipleRangeFirst')"
            [class.conflict-on-merge]="hasMergeConflict('pitchEBITDAMultipleRangeFirst')">Pitch EBITDA Multiple Range</span>
                    <span *ngIf="showAsterisk('pitchEBITDAMultipleRangeFirst')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8 double-column-pitch-ebitda">
                    <div class="col-xs-6">
                        <!-- <input type="text" id="PitchEBITDAMultipleRangeFirst" name="PitchEBITDAMultipleRangeFirst" class="input-data-number input-number multiply-decimal-input" operations="MultipleRangeMidpoint,ImpliedValuationPremium"><br>
                        <span class="numeric-validation-msg"></span> -->
                        <app-numeric class="custom2"
                        [type]="'decimal2'"
                        [format]="'multiply'" 
                        [readonly]="isReadOnly('pitchEBITDAMultipleRangeFirst')"
                        [value]="valuationMetricsFormGroup.get('pitchEBITDAMultipleRangeFirst').value"
                        (selectedValue)="onSelectedValue($event,'pitchEBITDAMultipleRangeFirst')">
                        </app-numeric>
                    </div>
                    <div class="col-xs-6">
                        <!-- <input type="text"  name="PitchEBITDAMultipleRangeSecond" class="input-data-number input-number multiply-decimal-input" operations="MultipleRangeMidpoint,ImpliedValuationPremium"><br>
                        <span class="numeric-validation-msg"></span> -->
                        <app-numeric class="custom2"
                        id="PitchEBITDAMultipleRangeSecond"
                        [type]="'decimal2'"
                        [format]="'multiply'"
                        [readonly]="isReadOnly('pitchEBITDAMultipleRangeSecond')"
                        [value]="valuationMetricsFormGroup.get('pitchEBITDAMultipleRangeSecond').value"
                        (selectedValue)="onSelectedValue($event,'pitchEBITDAMultipleRangeSecond')">
                        </app-numeric>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('multipleRangeMidpoint')"
            [class.edited-externally]="editedExternally('multipleRangeMidpoint')"
            [class.conflict-on-merge]="hasMergeConflict('multipleRangeMidpoint')">Multiple Range Midpoint</span>
                    <span *ngIf="showAsterisk('multipleRangeMidpoint')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="MultipleRangeMidpoint" name="MultipleRangeMidpoint" class="input-data-number input-number multiply-decimal-input" formula="f.avg( PitchEBITDAMultipleRangeFirst , PitchEBITDAMultipleRangeSecond )" format="multiply" disabled><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'multiply'"
                        [placeholder]="'0.0x'"
                        [readonly]="isReadOnly('multipleRangeMidpoint')"
                        [value]="valuationMetricsFormGroup.get('multipleRangeMidpoint').value"
                        [disabled]="true">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('impliedValuationPremium')"
            [class.edited-externally]="editedExternally('impliedValuationPremium')"
            [class.conflict-on-merge]="hasMergeConflict('impliedValuationPremium')">Implied Valuation Premium (%)</span>
                    <span *ngIf="showAsterisk('impliedValuationPremium')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input
                        type="text"
                        id="ImpliedValuationPremium"
                        name="ImpliedValuationPremium"
                        class="input-data-number input-number percentage-input"
                        formula="( ( TotalConsideration ) - ( ( MultipleRangeMidpoint ) * ( Ebitda ) ) ) / ( ( MultipleRangeMidpoint ) * ( Ebitda ) ) * ( 100 )"
                        format="percentage"
                        disabled>
                    <br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('impliedValuationPremium')"
                        [value]="valuationMetricsFormGroup.get('impliedValuationPremium').value"
                        [disabled]="true">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('hitBreakpointEL')"
            [class.edited-externally]="editedExternally('hitBreakpointEL')"
            [class.conflict-on-merge]="hasMergeConflict('hitBreakpointEL')">Hit Breakpoint in EL?</span>
                    <span *ngIf="showAsterisk('hitBreakpointEL')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <div name="HitBreakpointEL" id="HitBreakpointEL" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group div-three-toggle-input" role="group">
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="NO">NO</button>
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="NA">N/A</button>
                    </div> -->
                    <app-toggle-yes-no  #hitBreakpointEL
                    [value]="valuationMetricsFormGroup.get('hitBreakpointEL').value"                
                    [readonly]="isReadOnly('hitBreakpointEL')"
                    [disabled]="valuationMetricsFormGroup.get('hitBreakpointEL').disabled"
                    (handleToggleYesNo)="onhitBreakpointEL($event)"
                    [notApplicableEnabled]="true"
                    class="buttons-app-yes-no">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('elBreakpoint')"
            [class.edited-externally]="editedExternally('elBreakpoint')"
            [class.conflict-on-merge]="hasMergeConflict('elBreakpoint')">EL Breakpoint ($000s)</span>
                    <span *ngIf="showAsterisk('elBreakpoint')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="ElBreakpoint" name="ElBreakpoint" class="input-data-number input-number non-decimal-input" operations="ValuationPremiumOverBp" format="negativeCurrency"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('elBreakpoint')"
                        [value]="valuationMetricsFormGroup.get('elBreakpoint').value"
                        (selectedValue)="onSelectedValue($event,'elBreakpoint')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('valuationPremiumOverBp')"
            [class.edited-externally]="editedExternally('valuationPremiumOverBp')"
            [class.conflict-on-merge]="hasMergeConflict('valuationPremiumOverBp')">Valuation Premium over BP (%)</span>
                    <span *ngIf="showAsterisk('valuationPremiumOverBp')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input
                        type="text"
                        id="ValuationPremiumOverBp"
                        name="ValuationPremiumOverBp"
                        class="input-data-number input-number percentage-input"
                        formula="( ( TotalConsideration ) - ( ElBreakpoint ) ) / ( ( ElBreakpoint ) ) * ( 100 )"
                        format="percentage"
                        disabled>
                    <br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('valuationPremiumOverBp')"
                        [value]="valuationMetricsFormGroup.get('valuationPremiumOverBp').value"
                        [disabled]="true">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('ioiAboveBreakpoint')"
            [class.edited-externally]="editedExternally('ioiAboveBreakpoint')"
            [class.conflict-on-merge]="hasMergeConflict('ioiAboveBreakpoint')">Number of IOIs Above Breakpoint</span>
                    <span *ngIf="showAsterisk('ioiAboveBreakpoint')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="IoiAboveBreakpoint" name="IoiAboveBreakpoint" class="input-data-number input-number integer-input"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [readonly]="isReadOnly('ioiAboveBreakpoint')"
                        [value]="valuationMetricsFormGroup.get('ioiAboveBreakpoint').value"
                        (selectedValue)="onSelectedValue($event,'ioiAboveBreakpoint')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('loiAboveBreakpoint')"
            [class.edited-externally]="editedExternally('loiAboveBreakpoint')"
            [class.conflict-on-merge]="hasMergeConflict('loiAboveBreakpoint')">Number of LOIs Above Breakpoint</span>
                    <span *ngIf="showAsterisk('loiAboveBreakpoint')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <input type="text" id="LoiAboveBreakpoint" name="LoiAboveBreakpoint" class="input-data-number input-number integer-input"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [readonly]="isReadOnly('loiAboveBreakpoint')"
                        [value]="valuationMetricsFormGroup.get('loiAboveBreakpoint').value"
                        (selectedValue)="onSelectedValue($event,'loiAboveBreakpoint')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row" id="valuation-metrics-comments">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('describeDealStructure')"
            [class.edited-externally]="editedExternally('describeDealStructure')"
            [class.conflict-on-merge]="hasMergeConflict('describeDealStructure')">Describe Deal Structure/Consideration</span>
                    <span *ngIf="showAsterisk('describeDealStructure')" class="red-asterik"></span>

                    <app-popover
                    [tooltipText]="getTooltipText('describeDealStructure')">
                  </app-popover>

                    <!-- <span class="glyphicon glyphicon-question-sign section-question-sign tooltip-dcf"></span>
                    <span class="tooltiptext-dcf visibility-hidden">Example: Purchase price up to $22.75m with $15m cash at close and end up to $7.75m earnout. Earnout is to be paid 12 months after closed based on 2016 EBITDA. Earnout calculation is 5x 2016 EBITDA in excess of $2.75m. To maximize payout (i.e hit the $7.75m threshold), they need to hit $4.3 million in 2016. Management is fairly confident that they will get to the $4.3 number given their 2015 performance and upward trend over the past couple of years.</span> -->
                </div>
                <div class="col-xs-8">
                    <textarea type="text" rows="3" placeholder="You can add comments here"  
                    formControlName="describeDealStructure"  
                    [class.readonly]="isReadOnly('describeDealStructure')"
                    class="input-data-text text-area-style"></textarea>
                </div>
            </div>
        </div>
        <div  id="valuation-table" class="numeric-input-table">
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  valuation-metrics-label valuation-rows">
                        <span class="span-to-highlight"><b>Winning Bidder Valuation</b></span>
                        
                    </div>
                    <div class="col-xs-10 valuation-metrics-table-title valuation-rows">
                        <div class="col-xs-2">
                            <div class="valuation-metrics-table-label">
                                <span><b>At Closing - Low</b></span>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="valuation-metrics-table-label">
                                <span><b>At Closing - High</b></span>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="valuation-metrics-table-label">
                                <span><b>Contingent (i.e. Earnout)</b></span>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="valuation-metrics-table-label">
                                <span><b>Total - High</b></span>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="valuation-metrics-table-label">
                                <span><b>Average</b></span>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="valuation-metrics-table-label">
                                <span><b>Total - Avg.</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" id="ioi-row">
                <div class="col-xs-12">
                    <div class="col-xs-2  valuation-rows">
                        <span class="span-to-highlight">LOI ($000s)</span>

                        <app-popover
                    [tooltipText]="getTooltipText('valuationTooltipMessage')">
                  </app-popover>

                    </div>
                    <div class="col-xs-10 valuation-metrics-table-title valuation-rows">
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('iOIValueClosingLow')"
                        [value]="valuationMetricsFormGroup.get('iOIValueClosingLow').value"
                        (selectedValue)="onBidder1($event,'iOIValueClosingLow')">
                    </app-numeric>
                            <!-- <input type="text" id="IOIValueClosingLow" name="IOIValueClosingLow" class="input-data-number input-number non-decimal-input valuation-table-list" operations="IOIValueClosingAvg,NumericalPriceIncClosingLow,NumericalPriceIncClosingAvg,NumericalPriceIncTotalAvg,PercentagePriceIncClosingLow,PercentagePriceIncClosingAvg,PercentagePriceIncTotalAvg,IOIValueTotalAvg"><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('iOIValueClosingHigh')"
                        [value]="valuationMetricsFormGroup.get('iOIValueClosingHigh').value"
                        (selectedValue)="onBidder1($event,'iOIValueClosingHigh')">
                    </app-numeric>
                            <!-- <input type="text" id="IOIValueClosingHigh" name="IOIValueClosingHigh" class="input-data-number input-number non-decimal-input valuation-table-list" operations="IOIValueTotalHigh,IOIValueClosingAvg,IOIValueTotalAvg,NumericalPriceIncClosingHigh,NumericalPriceIncTotalHigh,NumericalPriceIncClosingAvg,NumericalPriceIncTotalAvg,PercentagePriceIncClosingHigh,PercentagePriceIncClosingAvg,PercentagePriceIncTotalHigh,PercentagePriceIncTotalAvg"><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('iOIValueContingent')"
                        [value]="valuationMetricsFormGroup.get('iOIValueContingent').value"
                        (selectedValue)="onBidder1($event,'iOIValueContingent')">
                    </app-numeric>
                            <!-- <input type="text" id="IOIValueContingent" name="IOIValueContingent" class="input-data-number input-number non-decimal-input valuation-table-list" operations="IOIValueTotalHigh,IOIValueTotalAvg,NumericalPriceIncContingent,NumericalPriceIncTotalHigh,NumericalPriceIncTotalAvg,PercentagePriceIncContingent,PercentagePriceIncTotalAvg,PercentagePriceIncTotalHigh"><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('iOIValueTotalHigh')"
                        [value]="valuationMetricsFormGroup.get('iOIValueTotalHigh').value"
                        [disabled]="valuationMetricsFormGroup.get('iOIValueTotalHigh').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="IOIValueTotalHigh" name="IOIValueTotalHigh" class="input-data-number input-number non-decimal-input valuation-table-list" formula=" ( f.default( IOIValueClosingHigh ,0) ) + ( f.default( IOIValueContingent ,0) )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('iOIValueClosingAvg')"
                        [value]="valuationMetricsFormGroup.get('iOIValueClosingAvg').value"
                        [disabled]="valuationMetricsFormGroup.get('iOIValueClosingAvg').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="IOIValueClosingAvg" name="IOIValueClosingAvg" class="input-data-number input-number non-decimal-input valuation-table-list" formula="f.avg( IOIValueClosingLow , IOIValueClosingHigh )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('iOIValueTotalAvg')"
                        [value]="valuationMetricsFormGroup.get('iOIValueTotalAvg').value"
                        [disabled]="valuationMetricsFormGroup.get('iOIValueTotalAvg').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="IOIValueTotalAvg" name="IOIValueTotalAvg" class="input-data-number input-number non-decimal-input valuation-table-list" formula="f.avg( IOIValueClosingLow , IOIValueTotalHigh )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  valuation-rows">                
                        <span class="span-to-highlight">LOI ($000s)</span>

                        <app-popover
                    [tooltipText]="getTooltipText('valuationTooltipMessage')">
                  </app-popover>
                    </div>
                    <div class="col-xs-10 valuation-metrics-table-title valuation-rows">
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('lOIValueClosingLow')"
                        [value]="valuationMetricsFormGroup.get('lOIValueClosingLow').value"
                        (selectedValue)="onBidder1($event,'lOIValueClosingLow')"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="LOIValueClosingLow" name="LOIValueClosingLow" class="input-data-number input-number non-decimal-input valuation-table-list" operations="LOIValueClosingAvg,LOIValueTotalAvg,NumericalPriceIncClosingLow,NumericalPriceIncClosingAvg,NumericalPriceIncTotalAvg,PercentagePriceIncClosingLow,PercentagePriceIncClosingAvg,PercentagePriceIncTotalAvg"><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('lOIValueClosingHigh')"
                        [value]="valuationMetricsFormGroup.get('lOIValueClosingHigh').value"
                        (selectedValue)="onBidder1($event,'lOIValueClosingHigh')"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="LOIValueClosingHigh" name="LOIValueClosingHigh" class="input-data-number input-number non-decimal-input valuation-table-list" operations="LOIValueTotalHigh,LOIValueClosingAvg,LOIValueTotalAvg,NumericalPriceIncClosingHigh,NumericalPriceIncTotalHigh,NumericalPriceIncClosingAvg,NumericalPriceIncTotalAvg,PercentagePriceIncClosingHigh,PercentagePriceIncClosingAvg,PercentagePriceIncTotalAvg,PercentagePriceIncTotalHigh"><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('lOIValueContingent')"
                        [value]="valuationMetricsFormGroup.get('lOIValueContingent').value"
                        (selectedValue)="onBidder1($event,'lOIValueContingent')"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="LOIValueContingent" name="LOIValueContingent" class="input-data-number input-number non-decimal-input valuation-table-list" operations="LOIValueTotalHigh,LOIValueTotalAvg,NumericalPriceIncContingent,NumericalPriceIncTotalHigh,NumericalPriceIncTotalAvg,PercentagePriceIncContingent,PercentagePriceIncTotalAvg,PercentagePriceIncTotalHigh"><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('lOIValueTotalHigh')"
                        [value]="valuationMetricsFormGroup.get('lOIValueTotalHigh').value"
                        [disabled]="valuationMetricsFormGroup.get('lOIValueTotalHigh').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="LOIValueContingent" name="LOIValueTotalHigh" class="input-data-number input-number non-decimal-input valuation-table-list" formula="( f.default( LOIValueClosingHigh ,0) ) + ( f.default( LOIValueContingent ,0) )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('lOIValueClosingAvg')"
                        [value]="valuationMetricsFormGroup.get('lOIValueClosingAvg').value"
                        [disabled]="valuationMetricsFormGroup.get('lOIValueClosingAvg').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="LOIValueClosingAvg" name="LOIValueClosingAvg" class="input-data-number input-number non-decimal-input valuation-table-list" formula="f.avg( LOIValueClosingLow , LOIValueClosingHigh )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('lOIValueTotalAvg')"
                        [value]="valuationMetricsFormGroup.get('lOIValueTotalAvg').value"
                        [disabled]="valuationMetricsFormGroup.get('lOIValueTotalAvg').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="LOIValueTotalAvg" name="LOIValueTotalAvg" class="input-data-number input-number non-decimal-input valuation-table-list" formula="f.avg( LOIValueClosingLow , LOIValueTotalHigh )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  valuation-metrics-label valuation-rows">
                        <span class="span-to-highlight">Price Increase ($000s)</span>                
                    </div>
                    <div class="col-xs-10 valuation-metrics-table-title valuation-rows">
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('numericalPriceIncClosingLow')"
                        [value]="valuationMetricsFormGroup.get('numericalPriceIncClosingLow').value"
                        [disabled]="valuationMetricsFormGroup.get('numericalPriceIncClosingLow').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="NumericalPriceIncClosingLow" name="NumericalPriceIncClosingLow" class="input-data-number input-number non-decimal-input valuation-table-list" formula="( f.default( LOIValueClosingLow ,0) ) - ( f.default( IOIValueClosingLow ,0) )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('numericalPriceIncClosingHigh')"
                        [value]="valuationMetricsFormGroup.get('numericalPriceIncClosingHigh').value"
                        [disabled]="valuationMetricsFormGroup.get('numericalPriceIncClosingHigh').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="NumericalPriceIncClosingHigh" name="NumericalPriceIncClosingHigh" class="input-data-number input-number non-decimal-input valuation-table-list" formula="( f.default( LOIValueClosingHigh ,0) ) - ( f.default( IOIValueClosingHigh ,0) )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('numericalPriceIncContingent')"
                        [value]="valuationMetricsFormGroup.get('numericalPriceIncContingent').value"
                        [disabled]="valuationMetricsFormGroup.get('numericalPriceIncContingent').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="NumericalPriceIncContingent" name="NumericalPriceIncContingent" class="input-data-number input-number non-decimal-input valuation-table-list" formula="( f.default( LOIValueContingent ,0) ) - ( f.default( IOIValueContingent ,0) )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('numericalPriceIncTotalHigh')"
                        [value]="valuationMetricsFormGroup.get('numericalPriceIncTotalHigh').value"
                        [disabled]="valuationMetricsFormGroup.get('numericalPriceIncTotalHigh').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="NumericalPriceIncTotalHigh" name="NumericalPriceIncTotalHigh" class="input-data-number input-number non-decimal-input valuation-table-list" formula=" ( f.default( LOIValueTotalHigh ,0) ) - ( f.default( IOIValueTotalHigh ,0) )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('numericalPriceIncClosingAvg')"
                        [value]="valuationMetricsFormGroup.get('numericalPriceIncClosingAvg').value"
                        [disabled]="valuationMetricsFormGroup.get('numericalPriceIncClosingAvg').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="NumericalPriceIncClosingAvg" name="NumericalPriceIncClosingAvg" class="input-data-number input-number non-decimal-input valuation-table-list" formula="( f.default( LOIValueClosingAvg ,0) ) - ( f.default( IOIValueClosingAvg ,0) )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('numericalPriceIncTotalAvg')"
                        [value]="valuationMetricsFormGroup.get('numericalPriceIncTotalAvg').value"
                        [disabled]="valuationMetricsFormGroup.get('numericalPriceIncTotalAvg').disabled"
                        >
                    </app-numeric>
                            <!-- <input type="text" id="NumericalPriceIncTotalAvg" name="NumericalPriceIncTotalAvg" 
                                class="input-data-number input-number non-decimal-input valuation-table-list" 
                                formula="( f.default( LOIValueTotalAvg ,0) ) - ( f.default( IOIValueTotalAvg ,0) )" format="negativeCurrency" disabled><br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  valuation-rows">
                        <span class="span-to-highlight">Price Increase (%)</span>
                    </div>
                    <div class="col-xs-10 valuation-metrics-table-title valuation-rows">
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('percentagePriceIncClosingLow')"
                        [value]="valuationMetricsFormGroup.get('percentagePriceIncClosingLow').value"
                        [disabled]="valuationMetricsFormGroup.get('percentagePriceIncClosingLow').disabled"
                        >
                    </app-numeric>
                            <!-- <input
                                type="text"
                                id="PercentagePriceIncClosingLow"
                                name="PercentagePriceIncClosingLow"
                                class="input-data-number input-number percentage-input valuation-table-list"
                                formula="( NumericalPriceIncClosingLow ) / ( IOIValueClosingLow ) * ( 100 )"
                                format="percentage"
                                disabled>
                            <br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('percentagePriceIncClosingHigh')"
                        [value]="valuationMetricsFormGroup.get('percentagePriceIncClosingHigh').value"
                        [disabled]="valuationMetricsFormGroup.get('percentagePriceIncClosingHigh').disabled"
                        >
                    </app-numeric>
                            <!-- <input
                                type="text"
                                id="PercentagePriceIncClosingHigh"
                                name="PercentagePriceIncClosingHigh"
                                class="input-data-number input-number percentage-input valuation-table-list"
                                formula="( NumericalPriceIncClosingHigh ) / ( IOIValueClosingHigh ) * ( 100 )"
                                format="percentage"
                                disabled>
                            <br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('percentagePriceIncContingent')"
                        [value]="valuationMetricsFormGroup.get('percentagePriceIncContingent').value"
                        [disabled]="valuationMetricsFormGroup.get('percentagePriceIncContingent').disabled"
                        >
                    </app-numeric>
                            <!-- <input
                                type="text" id="PercentagePriceIncContingent"
                                name="PercentagePriceIncContingent"
                                class="input-data-number input-number percentage-input valuation-table-list"
                                formula="( NumericalPriceIncContingent ) / ( IOIValueContingent ) * ( 100 )"
                                format="percentage"
                                disabled>
                            <br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('percentagePriceIncTotalHigh')"
                        [value]="valuationMetricsFormGroup.get('percentagePriceIncTotalHigh').value"
                        [disabled]="valuationMetricsFormGroup.get('percentagePriceIncTotalHigh').disabled"
                        >
                    </app-numeric>
                            <!-- <input
                                type="text"
                                id="PercentagePriceIncTotalHigh"
                                name="PercentagePriceIncTotalHigh"
                                class="input-data-number input-number percentage-input valuation-table-list"
                                formula="( NumericalPriceIncTotalHigh ) / ( IOIValueTotalHigh ) * ( 100 )"
                                format="percentage"
                                disabled>
                            <br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('percentagePriceIncClosingAvg')"
                        [value]="valuationMetricsFormGroup.get('percentagePriceIncClosingAvg').value"
                        [disabled]="valuationMetricsFormGroup.get('percentagePriceIncClosingAvg').disabled"
                        >
                    </app-numeric>
                            <!-- <input
                                type="text"
                                id="PercentagePriceIncClosingAvg"
                                name="PercentagePriceIncClosingAvg"
                                class="input-data-number input-number percentage-input valuation-table-list"
                                formula="( NumericalPriceIncClosingAvg ) / ( IOIValueClosingAvg ) * ( 100 )"
                                format="percentage"
                                disabled>
                            <br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation" 
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('percentagePriceIncTotalAvg')"
                        [value]="valuationMetricsFormGroup.get('percentagePriceIncTotalAvg').value"
                        [disabled]="valuationMetricsFormGroup.get('percentagePriceIncTotalAvg').disabled"
                        >
                    </app-numeric>
                            <!-- <input
                                type="text"
                                id="PercentagePriceIncTotalAvg"
                                name="PercentagePriceIncTotalAvg"
                                class="input-data-number input-number percentage-input valuation-table-list"
                                formula="( NumericalPriceIncTotalAvg ) / ( IOIValueTotalAvg ) * ( 100 )"
                                format="percentage"
                                disabled>
                            <br>
                            <span class="numeric-validation-msg"></span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="bidder-valuation-table" class="numeric-input-table">
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-3  valuation-metrics-label">
                        <span class="span-to-highlight"><b>#2 Bidder Valuation</b></span>
                    </div>
                    <div class="col-xs-9 bidder-valuation-labels">
                        <div class="col-xs-4">
                            <span><b>Closing</b></span><br>
                            <span><b>Consideration</b></span>
                        </div>
                        <div class="col-xs-4">
                            <span><b>Contingent</b></span>
                        </div>
                        <div class="col-xs-4">
                            <span><b>Total</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-3 ">
                        <span class="span-to-highlight">LOI Value ($000s)</span>
                        <app-popover
                    [tooltipText]="getTooltipText('valuationTooltipMessage')">
                  </app-popover>
                    </div>
                    <div class="col-xs-9 bidder-valuation-labels">
                        <div class="col-xs-4 " >
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('loiClosedConsideration')"
                        [value]="valuationMetricsFormGroup.get('loiClosedConsideration').value"
                        (selectedValue)="onBidder2($event,'loiClosedConsideration')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-4 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('loiClosedContingent')"
                        [value]="valuationMetricsFormGroup.get('loiClosedContingent').value"
                        (selectedValue)="onBidder2($event,'loiClosedContingent')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-4 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="isReadOnly('loiTotal')"
                        [placeholder]="'$0'"
                        [value]="valuationMetricsFormGroup.get('loiTotal').value"
                        [disabled]="valuationMetricsFormGroup.get('loiTotal').disabled"
                        >
                    </app-numeric>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-3 ">
                        <span class="span-to-highlight">Valuation Premium ($)</span>
                    </div>
                    <div class="col-xs-9 bidder-valuation-labels">
                        <div class="col-xs-4 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('valNumericalClosedConsideration')"
                        [value]="valuationMetricsFormGroup.get('valNumericalClosedConsideration').value"
                        [disabled]="valuationMetricsFormGroup.get('valNumericalClosedConsideration').disabled"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-4">
                        </div>
                        <div class="col-xs-4 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="'$0'"
                        [readonly]="isReadOnly('valNumericalTotal')"
                        [value]="valuationMetricsFormGroup.get('valNumericalTotal').value"
                        [disabled]="valuationMetricsFormGroup.get('valNumericalTotal').disabled"
                        >
                    </app-numeric>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-3 ">
                        <span class="span-to-highlight">Valuation Premium (%)</span>
                    </div>
                    <div class="col-xs-9 bidder-valuation-labels">
                        <div class="col-xs-4 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('valPercentageClosedConsideration')"
                        [value]="valuationMetricsFormGroup.get('valPercentageClosedConsideration').value"
                        [disabled]="valuationMetricsFormGroup.get('valPercentageClosedConsideration').disabled"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-4">
                        </div>
                        <div class="col-xs-4 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="'N/A'"
                        [readonly]="isReadOnly('valPercentageTotal')"
                        [value]="valuationMetricsFormGroup.get('valPercentageTotal').value"
                        [disabled]="valuationMetricsFormGroup.get('valPercentageTotal').disabled"
                        >
                    </app-numeric>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

