import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormValidations } from "src/shared/utils/form-validations";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";

@Component({
    selector: 'app-submit-for-review',
    templateUrl: './submit-for-review.component.html',
    styleUrls: ['./submit-for-review.component.scss']
  })
export class SubmitForReviewComponent implements OnInit {
    ngOnInit(): void {
        throw new Error("Method not implemented.");
    }

    
}