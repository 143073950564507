import { Injectable } from '@angular/core';
import { EngagementDashboardtItem } from '../../models/engagement-dashboard-item';
import { BehaviorSubject, Subject } from 'rxjs';
import { SearchState } from '../../models/engagement-search';
import { debounceTime, tap, switchMap, delay } from 'rxjs/operators';
import { EngagementDashboardService } from './engagement-dashboard.service';
import { EngagementDashboardSort } from '../../models/engagement-sort';
import { EngagementTableFilterItem } from '../../models/engagement-table-filter-item';
import { AppSettings } from 'src/shared/app-settings';
import { EscapeCharacters } from 'src/shared/utils/escape-characters';
import { MainService } from 'src/shared/services/main.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementTableService {
  loading$ = new BehaviorSubject<boolean>(false);
  search$ = new Subject<void>();
  engagementList$ = new BehaviorSubject<EngagementDashboardtItem[]>([]);
  total$ = new BehaviorSubject<number>(0);
  state: SearchState = {
    page: 1,
    pageSize: AppSettings.pageSizeDefault,
    searchTerm: '',
    sortData: {sortColumn: 'engagementId', sortDirection: 'desc'},
    filterDataList: []
  };

  constructor(
      private engagementDashboardService: EngagementDashboardService,
      private escapeCharacters: EscapeCharacters, public globalHelperService:GlobalHelperService,
      private mainService: MainService) {
    this.search$.pipe(
      tap(() => this.loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this.loading$.next(false))
    ).subscribe(result => {
      this.engagementList$.next(result);
    });
  }

  get searchTerm() { return this.state.searchTerm; }
  set searchTerm(searchTerm: string) {this._set({searchTerm}); }
  set sortData(sortData: EngagementDashboardSort) { this._set({sortData}); }
  set filterDataList(filterDataList: Array<EngagementTableFilterItem>) { this._set({filterDataList}); }

  sort(column: any, direction: string, engagementList: Array<any>): Array<EngagementDashboardtItem> {
    if (direction !== '') {
      engagementList = [...engagementList].sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
    return engagementList;
  }

  compare(v1:any, v2:any) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  private _set(patch: Partial<SearchState>) {
    Object.assign(this.state, patch);
    if (typeof(patch.searchTerm) !== 'undefined') {
      this.search$.next();
    }
  }

  private _search(): Promise<any> {
    const {pageSize, page, searchTerm, sortData, filterDataList} = this.state;
    if ((searchTerm.trim().length >= 3)) {
      const searchTermParsed = this.escapeCharacters.replaceCharacteres(searchTerm);
      return this.engagementDashboardService.searchEngagements(searchTermParsed).then(response => {
        return this._isDCFAdmin() ? response : this._getAssignedEngagements(response,this.mainService.assignedMyDealIds);
      });
    } else {
      return this.engagementDashboardService.retrieveEngagements().then(response => {
        return this._isDCFAdmin() ? response : this._getAssignedEngagements(response,this.mainService.assignedMyDealIds);
      });
    }
  }

  private _isDCFAdmin(): boolean {
    const newDealAdminGroup = AppSettings.adminsGroup(this.globalHelperService.currentAccessPage?.toString());
    const usergroups = this.mainService.currentUser.groups;
    return !!usergroups.find(group => group === newDealAdminGroup);
  }

  private _getAssignedEngagements(responseEngagements: any, assignedMyDealIds: number[]): EngagementDashboardtItem[] {
    return responseEngagements.filter((eng:any) => assignedMyDealIds.includes(eng.myDealId));
  }

}
