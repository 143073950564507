import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateService } from 'src/app/services/date.service';
import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';

@Component({
  selector: 'app-comments-section',
  templateUrl: './comments-section.component.html',
  styleUrls: ['./comments-section.component.scss']
})
export class CommentsSectionComponent implements OnInit {
  maxNumOfCharacters: number=500;
  isWhiteSpace: boolean;
  isShowComments: boolean;
  formId: number;
  SubmissionDate: string;
  usercomments: any = [];
  toggleClass: boolean = false;
  sharePointMapper: NewDealSetupSharepointMapper;
  @Output() usercomment: EventEmitter<any> = new EventEmitter<any>();

  
  constructor(private route: ActivatedRoute,private datePipe: DatePipe,private mainService: MainService,private dateService: DateService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(){
    this.usercomments.pop();
    if(this.formId!=0)
    this.formId=Number(this.route.snapshot.queryParams.id);

     this.mainService.getcommentsListItem(AppSettings.newDealReviewComments, this.formId).subscribe(
      {
        next: (uresponseFormItem: any) => {
          let  count=0;   
          uresponseFormItem.sort((a:any, b:any) => new Date(b.Created).getTime() - new Date(a.Created).getTime());   
         uresponseFormItem.forEach((user:any) => {
          user.index=count;
          user.rowCount=user.Comment.split("\n").length;
          user.rowCount+=Math.round(user.Comment.length/110)<1?1:Math.round(user.Comment.length/110);
          user.Created=this._formatAdminReviewDate(user.Created);
            this.usercomments.push(user);
            count=count+1;
        });
        if(this.usercomments.length>0)
        {
         this.isShowComments=true;
         this.usercomment.emit(this.usercomments); 
      }
        //this.usercomments.reverse();
        },
        error: (err: any) => {          
        }
      }

     )   
  }
  private _formatAdminReviewDate(adminApprovalDate: string): string | any{
    let dateObject = new Date(adminApprovalDate);
    dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,adminApprovalDate);
  
    return this.datePipe.transform(dateObject, 'dd MMM y hh:mm a', 'CST');
   //return `${this.dateService.FormatDate(dateObject.toString()).split(' ').slice(0,-1).join(" ")}`;
  
  }

}
