import { Injectable } from '@angular/core';
import { Spcrud } from './spcrud';
import { Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class CustomSiteSpcrud extends Spcrud {

  constructor(http: Http) {
   super(http);
  }

  setCustomBaseUrl(baseUrl: string) {
    // Change to custom Site
   this.setBaseUrl(false);
  }
}
