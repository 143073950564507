import { FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { CustomFormControl } from '../custom/custom-form-control';
export interface SharepointMappingElement {
  columnSharepointName: string;
  columnFormControlName: string;
}

export interface SharepointValidator {
  FieldName: string;
  Validator: string;
  Value: any;
  id: number;
  ID: number;
}

export const SharepointValidatorName  = {
  required: 'Required',
  numeric: 'Numeric',
};

export class SharepointMapper {

  elementsFormGroup: SharepointMappingElement[];

  constructor(elements: SharepointMappingElement[] = []) {
    this.elementsFormGroup = elements;
  }

  addMapping(columnSharepointName: string, columnFormControlName: string ) {
    this.elementsFormGroup.push({
      columnSharepointName,
      columnFormControlName
    });
  }

  fillFormGroup(formGroup: FormGroup, sharepointData:any) {
    Object.keys(formGroup.controls).forEach(formControlName => {
      const sharePointColumnName = this._getSharepointColumnName(formControlName);
      if (sharePointColumnName) {
        formGroup.get(formControlName)?.setValue(sharepointData[sharePointColumnName]);
      }
    });
  }

  fillSharepointObject(formGroup: FormGroup) {
    const sharepointObject = {};

    Object.keys(formGroup.controls).forEach(formControlName => {
      const sharePointColumnName = this._getSharepointColumnName(formControlName);
      if (sharePointColumnName) {
        (sharepointObject as any)[sharePointColumnName] = formGroup.get(formControlName)?.value;
      }
    });

    return sharepointObject;
  }

  private _getsharePointColumnValidators(
    validators: SharepointValidator[],
    sharePointColumnName: string,
    formControlName: string): ValidatorFn[] {

    const sharePointValidators = validators.filter(el => el.FieldName === sharePointColumnName);

    const controlValidators = sharePointValidators.map(sharepointValidator => {
      return this.getValidator(sharepointValidator, formControlName);
    });

    return controlValidators.filter(Boolean);
  }

  setFormControlGroupValidators(formGroup: FormGroup, validators: SharepointValidator[]) {
    Object.keys(formGroup.controls).forEach(formControlName => {
      const sharePointColumnName = this._getSharepointColumnName(formControlName);
      const controlValidators: ValidatorFn[] =
        this._getsharePointColumnValidators(validators, sharePointColumnName, formControlName);

      if (controlValidators) {
        formGroup.get(formControlName)?.setValidators(controlValidators);
       if(formControlName=='currentPPMDLead'){
         if (formGroup.get(formControlName)?.value != null && formGroup.get(formControlName)?.value != "" && typeof (formGroup.get(formControlName)?.value) != 'object' && formGroup.get(formControlName)?.value != undefined)
          formGroup.get(formControlName)?.updateValueAndValidity();
       }
       else if(formControlName != 'financeFileUpload') 
       formGroup.get(formControlName)?.updateValueAndValidity();
      }
    });
  }

  getFormGroup(targetFormControlName: string, formGroups: Array<FormGroup>): FormGroup | any {
    let aux:any;
    formGroups.forEach(formGroup => {
      Object.keys(formGroup.controls).forEach(formControlName => {
        if (formControlName === targetFormControlName) {
          aux = formGroup;
        }
      });
    });
    return aux;
  }

  getFormControlColumnName(sharepointColumnName: string): string | any {
    const elem = this.elementsFormGroup.find(el => el.columnSharepointName === sharepointColumnName);

    if (elem) {
      return elem.columnFormControlName;
    } else {
      return null;
    }
  }

  getFormGroupByName(columnName: string, formGroupsNames:any, appFormGroup: FormGroup): FormGroup | any{
    let aux:any;
    Object.keys(formGroupsNames).forEach(name => {
      const formGroup = appFormGroup.get(name);
      const controlName = this.getFormControlColumnName(columnName);
      if (formGroup?.get(controlName)) {
        aux = formGroup;
      }
    });
    return aux;
  }

  cleanInputsEditedExternally(appFormGroup: FormGroup) {
    Object.keys(appFormGroup.controls).forEach(formGroupName => {
      const formGroup = appFormGroup.get(formGroupName) as FormGroup;
      Object.keys(formGroup.controls).forEach(formControlName => {
        const formControl = formGroup.get(formControlName) as CustomFormControl;
        formControl.setEditedExternally(false);
        formControl.setConflictOnMerge(false);
      });
    });
  }

  protected getValidator(sharepointValidator: SharepointValidator, formControlName: string): ValidatorFn | any {
    let validator: ValidatorFn | any;

    switch (sharepointValidator.Validator) {
      case SharepointValidatorName.required:
        validator = Validators.required;
        break;
      default:
        break;
    }

    return validator;
  }

  private _getSharepointColumnName(formControlName: string): string | any {
    const elem = this.elementsFormGroup.find(el => el.columnFormControlName === formControlName);

    if (elem) {
      return elem.columnSharepointName;
    } else {
      return null;
    }
  }

}
