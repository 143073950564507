<div class="row engagement-title">
  <a><span class="engagement-title-label">
     Engagement {{engagementId}} - {{projectName}}
    </span>
  </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

  <a  *ngIf="showAdminSection" class="engagement-title-label" (click)="onclick('#audit-log')">AuditLog</a>
  <!-- <div
    class="btn btn-outline-primary view-my-deal"
    (click)="openMyDeal()">
      <span>View My Deal</span>
  </div> -->
  
</div>



