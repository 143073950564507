import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DateService } from 'src/app/services/date.service';
//import { e } from '@angular/core/src/render3';
import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';

@Component({
  selector: 'app-deal-team-auditlog',
  templateUrl: './deal-team-auditlog.component.html',
  styleUrls: ['./deal-team-auditlog.component.scss']
})
export class DealTeamAuditlogComponent implements OnInit {
  AuditLog: Array<any> = [];
  toggleClass: boolean = false;
  myDealAdminGroup:boolean=false
  auditlogStatus: boolean = false;
  constructor(private datePipe: DatePipe,private mainService: MainService, private dateService: DateService) { }

  ngOnInit() {
  }
  
  getAuditLogDetails(myDealId:number,engagementName:string)
  {
    this.myDealAdminGroup=true;
    this.AuditLog.length=0;
    this.mainService.getDealTeamAuditListItem(AppSettings.staticDealTeamAuditLog, myDealId)
    .subscribe((results:any) => {
      let count = 0;
      results.sort((a:any, b:any) => new Date(b.Created).getTime() - new Date(a.Created).getTime());
      results.forEach((user: any) => {
        user.index=count;
        user.Created=this._formatAdminReviewDate(user.Created);
          this.AuditLog.push(user);
          count=count+1;
      });
    });

  }
  private _formatAdminReviewDate(adminApprovalDate: string): any {
    let dateObject = new Date(adminApprovalDate); 
    dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,adminApprovalDate);
   return this.datePipe.transform(dateObject, 'dd MMM y hh:mm a', 'CST');
   // return `${this.dateService._FormatDateEHO(dateObject.toString())}`;

  }

}
