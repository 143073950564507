import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AppSettings } from 'src/shared/app-settings';
import { FormListFields } from '../models/form-list-fields';
import { FormGroup } from '@angular/forms';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { FormValidations } from 'src/shared/utils/form-validations';
import { DynamicTableDropdownItemList } from 'src/shared/models/dynamic-table-dropdown-item-list';
import { SelectDynamicTableItem } from 'src/shared/models/select-dynamic-table-item';
import { DynamicTableHeader } from 'src/shared/models/dynamic-table-header';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';
import { SelectedDynamicTableValue } from 'src/shared/components/select-dynamic-table/select-dynamic-table.interface';
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action';
import { SelectDynamicTableComponent } from 'src/shared/components/select-dynamic-table/select-dynamic-table.component';
import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';

@Component({
  selector: 'app-new-deal',
  templateUrl: './new-deal.component.html',
})
export class NewDealComponent implements OnInit {
  // Dropdown lists
  statusNewDealOptions: DropdownItem[] = [];
  publiclyTradedSecuritiesOptions: DropdownItem[] = [];
  firmTypeOptions: DropdownItem[] = [];
  competitorsOptions: Array<DropdownItem> = [];
  industryOptions: Array<DropdownItem> = [];
  sourceOptions: Array<DropdownItem> = [];
  transactionTypeOptions: Array<DropdownItem> = [];
  sharePointMapper = new NewDealSetupSharepointMapper();
  newDealFormGroup: any;
  myDealFormGroup: any;

  // Competitor List const
  competitorDynamicTableDropdownItemList: DynamicTableDropdownItemList  = new DynamicTableDropdownItemList();
  competitorValuesList: Array<DropdownItem> = [];
  // competitorsListValues: Array<SelectDynamicTableItem> = [];
  updateCompetitors = true;

  @Input() formGroups: Array<FormGroup>;
  @Input() formSubmitted: boolean;
  @Output() competitorListToDelete = new EventEmitter<SelectDynamicTableItem[]>();
  @Output() competitorListItemToStore = new EventEmitter<SelectedDynamicTableValue>();

  @ViewChild (SelectDynamicTableComponent)
  selectDynamicTableComponent: SelectDynamicTableComponent;

  constructor(private dropdownService: DropdownService,
    private tooltipHelperService: TooltipHelperService) {
  }

  ngOnInit() {
    this.newDealFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
    this.statusNewDealOptions=AppSettings.StatusListArray;
    this.publiclyTradedSecuritiesOptions = AppSettings.PublicTradedSecuritiesListArray;
    this.firmTypeOptions = AppSettings.FirmTypeListArray;
    this.industryOptions = AppSettings.IndustryListArray;
    this.sourceOptions = AppSettings.SourceListArray;
    this.transactionTypeOptions = AppSettings.TransactionTypeListArray;
    this.dropdownService.fillLookupOptions(AppSettings.competitorsCustomList, 'Title')
    .then(response => {
      this.competitorsOptions = response;
      this.competitorDynamicTableDropdownItemList.itemList = this.competitorsOptions;
    })
    .catch(error => {
    });
    this.setCompetitorsReadOnly(true);

    const competitorHeader = new DynamicTableHeader();
    competitorHeader.header = 'Competitors';
    this.competitorDynamicTableDropdownItemList.header = competitorHeader;
  }

  setSharepointMapper(mapper: NewDealSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  onStatusSelection(event:any) {
    this.newDealFormGroup.controls.statusNewDeal.setValue(event.value);
  }

  onPubliclyTradedSecuritiesSelection(event:any) {
    this.newDealFormGroup.controls.publiclyTradedSecurities.setValue(event.value);
  }

  onFirmTypeSelection(event:any){
    this.newDealFormGroup.controls.firmType.setValue(event.value);
  }

  onIsPubliclyTradedEquitySelection(event:any) {
    this.myDealFormGroup.controls.isPubliclyTradedEquity.setValue(event);
    this.evaluateIsPubliclyTradedEquityDependency(event);
  }

  onIsPubliclyTradedDebtSelection(event:any) {
    this.newDealFormGroup.controls.isPubliclyTradedDebt.setValue(event);
    this.evaluateIsPubliclyTradedDebtDependency(event);
  }

  onObaPstPersonnelConflictSelection(event:any) {
    this.newDealFormGroup.controls.obaPstPersonnelConflict.setValue(event);
    this.evaluateObaPstPersonnelConflictDependency(event);
  }

  onIsCompetitivePitchSelection(event:any) {
    this.myDealFormGroup.controls.isCompetitivePitch.setValue(event);
    this.evaluateIsCompetitivePitchDependency(event);
  }

  onConflictCheckCompletionSelection(event:any) {
    this.newDealFormGroup.controls.conflictCheckCompletion.setValue(event);
  }

  onObaPstPersonnelConflictDateSelection(event:any) {
    this.newDealFormGroup.controls.obaPstPersonnelConflictDate.setValue(event);
  }

  onBackgroundCheckCompletionSelection(event:any) {
    this.newDealFormGroup.controls.backgroundCheckCompletion.setValue(event);
  }

  onOfacCompletionSelection(event:any) {
    this.newDealFormGroup.controls.ofacCompletion.setValue(event);
  }

  onClientRevenueSelection(event:any) {
    this.newDealFormGroup.controls.clientRevenue.setValue(event);
  }

  onEbitdaSelection(event:any) {
    this.myDealFormGroup.controls.ebitda.setValue(event);
  }

  onCusipSelection(event:any) {
    this.newDealFormGroup.controls.cusip.setValue(event);
  }

  onIndustrySelection(event:any) {
    this.myDealFormGroup.controls.industry.setValue(event.value);
  }

  onSourceSelection(event:any) {
    this.myDealFormGroup.controls.source.setValue(event.value);
  }

  onTransactionTypeSelection(event:any) {
    this.myDealFormGroup.controls.transactionType.setValue(event.value);
  }

  //onCompetitorsTableChange(competitorItemRow: SelectedDynamicTableValue) {
  //  const competitorsList = this.newDealFormGroup.get(this.sharePointMapper.getFormControlColumnName(FormListFields.competitorsList));
  //  const competitorsListValue: Array<SelectDynamicTableItem> = competitorsList.value;
  //  let listElements: SelectDynamicTableItem[];
  //  if (competitorItemRow.actionType === DynamicTableAction.Add) {
  //    const itemIdToInsert = competitorItemRow.item.itemId;
  //    if (!itemIdToInsert) {
  //      listElements = [...competitorsListValue, competitorItemRow.item];
  //    } else {
  //      const arrayWithoutRowToUpdate = competitorsListValue.filter(user => user.itemId !== competitorItemRow.item.itemId);
  //      listElements = [...arrayWithoutRowToUpdate, competitorItemRow.item];
  //    }
  //  } else {
  //    const arrayWithoutRowToDelete = competitorsListValue.filter(user => user.itemId !== competitorItemRow.item.itemId);
  //    listElements = [...arrayWithoutRowToDelete];
  //  }
  //  competitorsList.setValue(listElements);
  //  this._toogleCompetitorOtherFieldVisibility(listElements);
  //  this.competitorListItemToStore.emit(competitorItemRow);
  //}

  evaluateIsPubliclyTradedEquityDependency(value:any) {
    if (value === 'YES') {
      this.myDealFormGroup.controls.tickerCode.enable();
      // if((this.newDealFormGroup.get('isPubliclyTradedEquity')as CustomFormControl).isReadOnly()){
      //   (this.newDealFormGroup.controls.tickerCode as CustomFormControl).setReadOnly();
      // }
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.tickerCode));
    }
  }

  evaluateIsPubliclyTradedDebtDependency(value:any) {
    if (value === 'YES') {
      this.newDealFormGroup.controls.cusip.enable();
      if((this.newDealFormGroup.get('isPubliclyTradedDebt')as CustomFormControl).isReadOnly()){
        (this.newDealFormGroup.controls.cusip as CustomFormControl).setReadOnly();
      }
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.cusip));
    }
  }

  evaluateObaPstPersonnelConflictDependency(value:any) {
    if (value === 'YES') {
      this.newDealFormGroup.controls.obaPstPersonnelConflictDate.enable();
      if((this.newDealFormGroup.get('obaPstPersonnelConflict')as CustomFormControl).isReadOnly()){
        (this.newDealFormGroup.controls.obaPstPersonnelConflictDate as CustomFormControl).setReadOnly();
      }
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.obaPstPersonnelConflictDate));
    }
  }

  evaluateIsCompetitivePitchDependency(value:any) {
    if(this.myDealFormGroup.get('isCompetitivePitch')?.disabled) return
    if (value === 'YES') {
      this.newDealFormGroup.controls.competitorsList.enable();
      this.setCompetitorsReadOnly(false);
    } else {
      this.setCompetitorsReadOnly(true);
      this.newDealFormGroup.controls.competitorsList.disable();
      //this._deleteCompetitors();
    }
  }

  setItemCompetitor(itemId: number, index: number) {
    this.selectDynamicTableComponent.setItemId(itemId, index);
  }

  // setCompetitorsValues(dealTeamValues: Array<SelectDynamicTableItem>) {
  //    this.competitorsListValues = dealTeamValues;
  //    this._toogleCompetitorOtherFieldVisibility(dealTeamValues);
  //    if (this.myDealFormGroup.controls.isCompetitivePitch.value === 'NO') {
  //     this._deleteCompetitors();
  //   }
  // }

  setCompetitorsReadOnly(isReadOnly: boolean) {
    const elem = this.newDealFormGroup.controls.competitorsList as CustomFormControl;
    elem.readOnly = isReadOnly;
  }

  // getCompetitorsRowsComplete(): Array<SelectDynamicTableItem> {
  //   const rowsCompleted: Array<SelectDynamicTableItem> = [];
  //   if (this.competitorsListValues) {
  //     this.competitorsListValues.forEach(row => {
  //       if (row && row.isComplete()) {
  //         rowsCompleted.push(row);
  //       }
  //     });
  //   }
  //   return rowsCompleted;
  // }

  setUpdateCompetitors(updateCompetitors:any) {
    this.updateCompetitors = updateCompetitors;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  private _setAsDisabled(formControlName: string) {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      const controlName = formGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }
  }

  private _toogleCompetitorOtherFieldVisibility(listElements: SelectDynamicTableItem[]) {
    const showField = listElements.find( el => el.competitorItem.value === 'Other');
    const elem:any = this.newDealFormGroup.get(this.sharePointMapper.getFormControlColumnName(FormListFields.otherCompetitor));
    if (showField) {
      elem.enable();
    } else {
      elem.disable();
      elem.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  //private _deleteCompetitors() {
  //  if (this.competitorsListValues.length > 0) {
  //    this.competitorListToDelete.emit(this.competitorsListValues);
  //    this.newDealFormGroup.get(this.sharePointMapper.getFormControlColumnName(FormListFields.competitorsList)).setValue([]);
  //    this.setCompetitorsValues([]);
  //  }
  //}

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldName(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }
}