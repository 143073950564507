import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateService } from 'src/app/services/date.service';
import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  maxNumOfCharacters: number=500;
  isWhiteSpace: boolean;
  isShowComments: boolean;
  formId: number;
  SubmissionDate: string;
  usercomments: any = [];
  toggleClass: boolean = false;
  sharePointMapper: NewDealSetupSharepointMapper;
  
  constructor(private route: ActivatedRoute,private datePipe: DatePipe,private mainService: MainService, private dateService: DateService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(){
    this.usercomments.pop();
    if(this.formId!=0)
    this.formId=Number(this.route.snapshot.queryParams.item_id);
    Promise.all([
      
      this.mainService.getcommentsListItem(AppSettings.newDealReviewComments, this.formId)])
      .then(([uresponseFormItem]) => {
      let  count=0;
      
        uresponseFormItem.forEach((user:any) => {
          user.index=count;
          user.Created=this._formatAdminReviewDate(user.Created)
            this.usercomments.push(user);
            count=count+1;
        });
        if(this.usercomments.length>0)
        this.isShowComments=true;
        this.usercomments.reverse();
      }).catch(error => {
        
      });
  }
  private _formatAdminReviewDate(adminApprovalDate: string): string | any{
    let dateObject = new Date(adminApprovalDate);
    dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,adminApprovalDate);  
    return this.datePipe.transform(dateObject, 'dd MMM y hh:mm a', 'CST');
   //return `${this.dateService.FormatDate(dateObject.toString())}`;
  
  }

}
