import { Injectable } from '@angular/core';

@Injectable()
export class WindowRefServic {
     get nativeWindow (){
        return getWindow();
    }
}
function getWindow ():any{
    return window;

}