export class DealTeamItem {
    dealTeamItemId: number;
    dealTeamFormId: number;
    dealTeamEMail: string;
    dealTeamName: string;
    dealTeamLoginName: string;
    dealTeamTitle: string;
    dealTeamUserId: number;
    dealTeamRoleTitle: string;
    dealTeamRoleId: number;
    SwiftRole:any;
    Name:string;
}
