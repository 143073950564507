import { HttpClient,HttpHeaders,HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
 
import { BehaviorSubject, Observable, Subject } from "rxjs"; 
import { environment } from "src/environments/environment"; 
import { SearchState } from "../models/userManagement-search";
import { UserManagementItem } from "../models/userManagementItem";
import { UserManagementSort } from "../models/userManagement-sort";
import { UserManagementTableFilterItem } from "../models/userManagement-table-filter-item";
import * as moment from 'moment';
import { AppSettings } from "src/shared/app-settings";
@Injectable()
export class AdminService{
    private header = new HttpHeaders({ 'content-type': 'application/json' });
    constructor(private myhttp:HttpClient){}
    getAppendURL(formName: string) {
      let url: any = "";
      switch (formName) {
        case "NewDealSetupForm":
          return url = "api/NewDealSetupForm";
          break;
          case "DealWbsSetupForm":
          return url = "api/DealWbsSetupForm";
          break;
        case "ClosingCheckListForm":
            return url = "api/ClosingCheckListForm";
            break;
        default:
          return "";
          break;
      }
    }
    
     GetUserGroups(groupID:any,ascedingOrder:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
       const url = envURL +'/api/UserGroups/GetDataByFormGroupID?groupID='+groupID+'&ascedingOrder='+ ascedingOrder;
        return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    }
    GetUserRoleGroupsForPermission(userId:any,formTypeId:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement) 
      const url = envURL +'/api/UserGroups/GetUserGroupsByEmail?email='+userId+'&formTypeId='+formTypeId;
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
    GetPeoplepicker(term:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
      const url = envURL +'/api/UserGroups/GetProfiles?criteria='+term+'&accessToken='+localStorage.getItem("accessToken");
        return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    }
    GetFormTypes():Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
      const url = envURL +'/api/FormType/GetFormList';
        return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    }
    GetIndustryRepresentativeGroups():Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorder)
      const url = envURL +'/api/CrossBorderIndustries/GetList';
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
    GetFormGroups():Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
        const url = envURL +'/api/FormGroup/GetFormGroup';
        return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    } 
    DeleteUser(partitionKeyValue:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
      const url = envURL +'/api/UserGroups/Delete?partitionKeyValue='+partitionKeyValue;
        return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    }
    AddUser(data:any):Observable<any>{        
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)    
      const url = envURL +'/api/UserGroups/Add';
        return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
        ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })})
    }
    GetUserRoleGroups(userId:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)  
      const url = envURL +'/api/UserGroups/GetUserGroupsByEmail?email='+userId;
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
   }
   GetUserReportDetails(formTypeId:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)  
    const url = envURL +'/api/UserGroups/GetUserReport?formTypeId='+formTypeId;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }    

  GetDashboardDetails(searchText:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorder)
    const url = envURL +'/api/CrossBorderForm/GetDashboardDetails?searchValue='+searchText;
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
}
    GetDealADGroupDetails(): Observable<any> {
      const url = AppSettings.GetDealADGroupDetails();
      return this.myhttp.get(url, {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("accessToken")
          })
      })
    }  
  CallClosingCheckListTestMethod():Observable<any>
  {
    const url = environment.apiUrl +'/api/Test/GetListClosingCheckListLambda';
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  GetMyDealIDDetailsFromForm(formID:any,listName:any,appendedURL:any):Observable<any>{
    if(listName==AppSettings.dealWbsSetupFormList){
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
    }
    else if(listName==AppSettings.closingChecklistFormList){
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    }
    else if(listName==AppSettings.formListName){
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    else{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    const url = envURL+'/'+appendedURL+'/GetList?formID='+formID; 
    
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
}
