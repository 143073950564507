<ng-template  #rt let-r="result" let-t="term">
    <ngb-highlight  [result]="r.Value" [term]="t"></ngb-highlight>
</ng-template>

<div class="form-group has-search">
    <span *ngIf="showArrowIcons" class="fa fa-angle-down fa-lg form-control-feedback" style="margin-top: 10px;"></span>
    <input style="height: 30px"
      id="typeahead-focus"
      [ngbTypeahead]="search"
      type="text"
      class="form-control"
      [class.readonly]="readonly"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
      [disabled]="disabled || readonly"
      (focus)="openFocusPopup($event)"
      (click)="openClickPopup($event)"
      (selectItem)="onSelectItem($event)"
      (change)="onChangeItem($event)"
      #instance="ngbTypeahead"
      [value]="selectedValue"
    />
</div>