/**
 * Library with Angular 2+ operations for CRUD operations to SharePoint 2013/2016/Online lists over REST api
 *
 * Contains 6 core functions and other misc helper functions
 *
 * 1) Create    - add item to List
 * 2) Read      - find all items or single item from List
 * 3) Update    - update item in List
 * 4) Delete    - delete item in List
 * 5) jsonRead  - read JSON to List
 * 6) jsonWrite - write JSON to List ("upsert" = add if missing, update if exists)
 *
 * NOTE - 5 and 6 require the target SharePoint List to have two columns: "Title" (indexed) and "JSON" (mult-text).   These are
 * intendend to save JSON objects for JS internal application needs.   For example, saving user preferences to a "JSON-Settings" list
 * where one row is created per user (Title = current user Login) and JSON multi-text field holds the JSON blob.
 * Simple and flexible way to save data for many scenarios.
 *
 * @spjeff
 * spjeff@spjeff.com
 * http://spjeff.com
 *
 * version 0.2.08
 * last updated 06-16-2017
 *
 */

import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
// RxJS dependency
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { filter, share } from 'rxjs/operators';
// import { reject } from 'q';
import { AppSettings } from '../app-settings';
import { LcsprUser, PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { environment } from 'src/environments/environment';
const GRAPH_ENDPOINT_firstpart = 'https://graph.microsoft.com/v1.0/groups/';
const GRAPH_ENDPOINT_secondpart='/members?$select=displayName,jobTitle,userPrincipalName&$top=999';

@Injectable()
export class Spcrud {

  // Data
  jsonHeader = 'application/json; odata=verbose';
  headers = new Headers({ 'Content-Type': this.jsonHeader, Accept: this.jsonHeader });
  options = new RequestOptions({ headers: this.headers });
  baseUrl: string;
  serverRelativeUrl: string;
  apiUrl: string;
  apiFieldUrl: string;
  apiGroupUrl: string;
  apiUserUrl: string;
  apiPeoplePickerUrl: string;
  currentUser: string;
  login: string;
  searchresults:any;
  fileSpCrudFullURL: string;

  constructor(private http: Http) {
    this.setBaseUrl();
  }

  /*
  **********************
  * SHAREPOINT GENERAL *
  **********************
  */

  // Set base working URL path
  setBaseUrl(siteRootUrl?: boolean) {
    // tslint:disable-next-line: no-string-literal
    const ctx= (window as any) ['_spPageContextInfo'];
    if (siteRootUrl) {
      // siteRoot content
      if (ctx) {
        this.baseUrl = ctx.siteAbsoluteUrl;
      }
    } else {
      // default local SharePoint context
      if (ctx) {
        this.baseUrl = ctx.webAbsoluteUrl;
        this.serverRelativeUrl = ctx.webServerRelativeUrl;
      }
    }

    // Default to local web URL
    this.apiUrl =
      this.baseUrl + '/_api/web/lists/GetByTitle(\'{0}\')/items';
    this.apiFieldUrl =
      this.baseUrl + '/_api/web/lists/GetByTitle(\'{0}\')/fields';
    this.apiGroupUrl =
      this.baseUrl + '/_api/web/sitegroups/getbyname(\'{0}\')';
    this.apiUserUrl =
      this.baseUrl + '/_api/web/siteusers/getbyemail(\'{0}\')';
    this.apiPeoplePickerUrl =
      this.baseUrl + '/_api/SP.UI.ApplicationPages.ClientPeoplePickerWebServiceInterface.ClientPeoplePickerSearchUser';
    this.fileSpCrudFullURL =
    this.baseUrl + '/_api/web/GetFolderByServerRelativeUrl(\'{0}\')/files/add(overwrite=true, url=\'{1}\')';

    // Request digest
    const el = document.querySelector('#__REQUESTDIGEST');
    if (el) {
      // Digest local to ASPX page
      // this.headers.delete('X-RequestDigest');
      this.headers.append('X-RequestDigest', el.nodeValue as any);
    }
  }

  // Refresh digest token
  refreshDigest(): Promise<any> {
    const svc = this;
    return this.http.post(this.baseUrl + '/_api/contextinfo', null, this.options).toPromise().then(function(res: any) {
      svc.headers.delete('X-RequestDigest');
      svc.headers.append('X-RequestDigest', res.json().d.GetContextWebInformation.FormDigestValue);
    })
    .catch(error => {
      // reject(error);
      }
    );
  }

  // Send email
  sendMail(to: string, ffrom: string, subj: string, body: string): Promise<any> {
    // Append metadata
    const tos: string[] = to.split(',');
    const recip: string[] = (tos instanceof Array) ? tos : [tos];
    const message = {
      properties: {
        __metadata: {
          type: 'SP.Utilities.EmailProperties'
        },
        To: {
          results: recip
        },
        From: ffrom,
        Subject: subj,
        Body: body
      }
    };
    const url = this.baseUrl + '/_api/SP.Utilities.Utility.SendEmail';
    const data = JSON.stringify(message);
    return this.http.post(url, data, this.options).toPromise();
  }

  /*
  ****************************
  * SHAREPOINT USER PROFILES *
  ****************************
  */

  // Lookup SharePoint current web user
  getCurrentUser(): Promise<any> {
    const url = this.baseUrl + '/_api/web/currentuser?$expand=Groups';
    return this.http.get(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Lookup my SharePoint profile
  getMyProfile(): Promise<any> {
    const url = this.baseUrl + '/_api/SP.UserProfiles.PeopleManager/GetMyProperties?select=*';
    return this.http.get(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Lookup any SharePoint profile
  getProfile(login: string): Promise<any> {
    const url = this.baseUrl + '/_api/SP.UserProfiles.PeopleManager/GetPropertiesFor(accountName=@v)?@v=\'' + login + '\'&select=*';
    return this.http.get(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Lookup SharePoint Group ID
  getGroupId(groupName: string): Promise<any> {
    let url = this.apiGroupUrl.replace('{0}', groupName);
    const options = {select: 'id'};
    url = this.readBuilder(url, options);
    return this.http.get(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Lookup any SharePoint UserInfo
  getUserInfo(id: number): Promise<any> {
    const url = this.baseUrl + '/_api/web/getUserById(' + id + ')';
    return this.http.get(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Get user list from SharePoint group
  getGroupUsers(groupName: string): Promise<any> {
    const url = this.apiGroupUrl.replace('{0}', groupName) + '/Users';
    return this.http.get(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Ensure SPUser exists in target web
  ensureUser(login: string): Promise<any> {
    return this.refreshDigest()
    .then( res => {
      this._setPostheaders();
      const url = this.baseUrl + '/_api/web/ensureuser';
      const sharepointObject = {logonName: login};
      const data = JSON.stringify(sharepointObject);
      return this.http.post(url, data,  new RequestOptions({ headers: this.headers })).toPromise()
      .then( response => {
        return response?.json();
      })
      .catch(this.handleError);
    });
  }
  checkUserinAdGroup(adGroupValue:string,userValue:string,separator:string):boolean{
    let result = true;
    userValue.split(separator).forEach(user=>{
      if(!adGroupValue.includes(user.trim()))
      result = false;
    })
    return result;
  }
  // Search users for Custom People Picker
  getPeoplePickerUserSuggestions(query: any,dealTeamTable:boolean): Promise<any> {
    return Promise.all([this.refreshDigest()]) 
      .then(([res]) => {
        console.log()
        this._setPostheaders();
        let group:any = JSON.parse(localStorage.getItem("adGroupMember") as any);
        let lcspgroup: any; //= JSON.parse(localStorage.getItem("lcspGroup1"));
        if(dealTeamTable){
          let filtered = group.filter((user:any) => user.userPrincipalName.toLowerCase().includes(
            query.queryParams.QueryString.toLowerCase().trim()) 
          || user.displayName.toLowerCase().includes(query.queryParams.QueryString.toLowerCase().trim())
          || this.checkUserinAdGroup(user.displayName.toLowerCase(),query.queryParams.QueryString.toLowerCase().trim()," ")
          || this.checkUserinAdGroup(user.displayName.toLowerCase(),query.queryParams.QueryString.toLowerCase().trim(),",")
          );
          if(filtered.length > 0){
            return filtered;
          }
          else{
            return [];
          }

        }
        else{
          const url =environment.documentUrl+'/api/document/SearchTest?criteria='+query.queryParams.QueryString.trim();
         return Promise.all([this.getSearchResults(url)])
          .then(([res])=>{
            lcspgroup=res;
            if(res.length > 0){
             return lcspgroup;
            }
            return [];
            
          });

        } 
      });
  }
  getsharepointPeoplePickerUserSuggestions(query: any): Promise<any> {
    return Promise.all([this.refreshDigest()]) 
      .then(([res]) => {
        this._setPostheaders();
        //query.queryParams.QueryString =query;
        return this.http.post(this.apiPeoplePickerUrl, query, new RequestOptions({ headers: this.headers })).toPromise()
          .then(response => {
            const jsonResponse = response?.json();
            const result = jsonResponse.d.ClientPeoplePickerSearchUser;
            // Filtering people picker users response  based on AD group user results
            let resultFiltered:PeoplePickerUser[] = JSON.parse(result);
            return resultFiltered;
          });
      })
    
  }
  // Get UserCollection Id by Email
  getUserIdByEmail(email: string): Promise<any> {
    const url = this.apiUserUrl.replace('{0}', email);
    return this.http.get(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  getSearchResults(url:any):Promise<any>{
     return this.http.get(url).toPromise()
    .then(res => {
      return  res?.json();
  })
}
  /*
  ******************************
  * SHAREPOINT LIST AND FIELDS *
  ******************************
  */

  // Create list
  // createList(title: string, baseTemplate: string, description: string): Promise<any> {
  //   const data = {
  //     '__metadata': { 'type': 'SP.List' },
  //     'BaseTemplate': baseTemplate,
  //     'Description': description,
  //     'Title': title
  //   };
  //   const url = this.baseUrl + '/_api/web/lists';
  //   this.headers = new Headers({ 'Content-Type': this.jsonHeader, 'Accept': this.jsonHeader });
  //   this.options = new RequestOptions({ headers: this.headers });
  //   return this.http.post(url, data, this.options).toPromise().then(function (res: Response) {
  //     return res.json();
  //   }).catch(this.handleError);
  // }

  // Create field
  createField(listTitle: string, fieldName: string, fieldType: string): Promise<any> {
    const data = {
      __metadata: { type: 'SP.Field' },
      Type: fieldType,
      Title: fieldName
    };
    const url = this.baseUrl + '/_api/web/lists/GetByTitle(\'' + listTitle + '\')/fields';
    return this.http.post(url, data, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Get options from a column of type Choice
  getColumnChoiceOptions(listTitle: string, fieldName: string) {
    let url = this.apiFieldUrl.replace('{0}', listTitle);
    const options = {filter: 'EntityPropertyName eq \'' + fieldName + '\''};
    url = this.readBuilder(url, options);
    return this.http.get(url, this.options).toPromise().then(function(resp: any) {
      return resp.json();
    }).catch(this.handleError);
  }

  /*
  ********************************
  * SHAREPOINT FILES AND FOLDERS *
  ********************************
  */

  // Create folder
  createFolder(libraryName: string, folderName: string): Promise<any> {
    const url = this.baseUrl + '/_api/web/Folders/add(\'' + libraryName + '/' + folderName + '\')';
    return this.refreshDigest()
    .then(res => {
      this._setPostheaders();
      return this.http.post(url, null, this.options).toPromise()
      .then(resp => {
        return resp?.json();
      })
      .catch(this.handleError);
    });
  }

  // Upload file to folder
  // var binary = new Uint8Array(FileReader.readAsArrayBuffer(file[0]));
  uploadFile(folderUrl: string, fileName: string, binary: any): Promise<any> {
    const url = this.baseUrl + '/_api/web/GetFolderByServerRelativeUrl(\''
      + folderUrl + '\')/files/add(overwrite=true, url=\'' + fileName + '\')';
    return this.refreshDigest()
    .then(res => {
      this._setPostheaders();
      const options = new RequestOptions({ headers: this.headers });
      return this.http.post(url, binary, options).toPromise()
      .then(resp => {
        return resp?.json();
      })
      .catch(this.handleError);
    });
  }

  validateFileEncrypted(file: any): Promise<any> {
      let formData:FormData = new FormData();
      formData.append('uploadFile', file.fileData,file.name);
        
      return this.http.post(environment.docEncryptionUrl, formData).toPromise()
      .then(resp => {
        return resp?.json();
      })
      .catch(this.handleError);
    }

  // Get files
  getFiles(folderUrl: string): Promise<any> {
    const url = this.baseUrl + '/_api/web/GetFolderByServerRelativeUrl(\''
      + folderUrl + '\')/Files?$orderby=TimeLastModified&$expand=ListItemAllFields';
    return this.http.get(url, this.options).toPromise()
    .then(res => {
      return res?.json();
    })
    .catch(this.handleError);
  }

  // Update file metadata
  updateFile(folderUrl: string, fileName: string, sharepointObject: any): Promise<any> {
    // Append metadata
    if (!sharepointObject.__metadata) {
      sharepointObject.__metadata =  { type: 'SP.ListItem' };
    }
    const data = JSON.stringify(sharepointObject);
    const fileUrl = this.serverRelativeUrl + '/' + folderUrl + '/' + fileName;
    const url = this.baseUrl + '/_api/Web/GetFileByServerRelativeUrl(@f)/ListItemAllFields?@f=\'{0}\''.replace('{0}', fileUrl);
    return this.refreshDigest()
    .then(res => {
      this._setHeaderOptions('PATCH');
      const options = new RequestOptions({ headers: this.headers });
      return this.http.post(url, data, options).toPromise()
      .then(resp => {
        return resp?.json();
      });
    });
}

  // Update folder metadata
  updateFolder(folderUrl: string, sharepointObject: any): Promise<any> {
    // Append metadata
    if (!sharepointObject.__metadata) {
      sharepointObject.__metadata =  { type: 'SP.ListItem' };
    }
    const data = JSON.stringify(sharepointObject);
    const url = this.baseUrl + '/_api/Web/GetFolderByServerRelativeUrl(\'{0}\')/ListItemAllFields'.replace('{0}', folderUrl);
    return this.refreshDigest()
    .then(res => {
      this._setHeaderOptions('PATCH');
      const options = new RequestOptions({ headers: this.headers });
      return this.http.post(url, data, options).toPromise()
      .then(resp => {
        return resp?.json();
      });
    });
  }

// delete file
  deleteFile(folderUrl: string, fileName: string) {
    const parsedFileName = encodeURIComponent(fileName);
    const urlFileName = parsedFileName.replace(/'/g, '\'\'');
    const url = this.baseUrl + '/_api/web/GetFolderByServerRelativeUrl(\'' + folderUrl + '\')/Files(url=@f)?@f=\'' + urlFileName + '\'';
    return this.refreshDigest()
      .then(res => {
        this._setHeaderOptions('DELETE');
        const options = new RequestOptions({ headers: this.headers });
        return this.http.post(url, null, options).toPromise()
        .then(resp => {
          return resp?.json();
        });
      });
  }

  // Upload attachment to item
  uploadAttach(listName: string, id: string, fileName: string, binary: any, overwrite?: boolean): Promise<any> {
    let url = this.baseUrl + '/_api/web/lists/GetByTitle(\'' + listName + '\')/items(' + id;
    const options = this.options;
    if (overwrite) {
      // Append HTTP header PUT for UPDATE scenario
      options.headers?.append('X-HTTP-Method', 'PUT');
      url += ')/AttachmentFiles(\'' + fileName + '\')/$value';
    } else {
      // CREATE scenario
      url += ')/AttachmentFiles/add(FileName=\'' + fileName + '\')';
    }
    return this.http.post(url, binary, options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Get attachment for item
  getAttach(listName: string, id: string): Promise<any> {
    const url = this.baseUrl + '/_api/web/lists/GetByTitle(\'' + listName + '\')/items(' + id + ')/AttachmentFiles';
    return this.http.get(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  // Copy file
  copyFile(sourceUrl: string, destinationUrl: string): Promise<any> {
    const url = this.baseUrl + '/_api/web/GetFileByServerRelativeUrl(\''
      + sourceUrl + '\')/copyto(strnewurl=\'' + destinationUrl + '\',boverwrite=false)';
    return this.http.post(url, this.options).toPromise().then(function(res: any) {
      return res.json();
    }).catch(this.handleError);
  }

  /*
  ************************
  * SHAREPOINT LIST CORE *
  ************************
  */

  // Build URL string with OData parameters
  readBuilder(url: string, options: any): string {
    if (options) {
        if (options.filter) {
            url += ((url.indexOf('?') == -1) ? '?' : '&') + '$filter=' + options.filter;
        }
        if (options.select) {
            url += ((url.indexOf('?') == -1) ? '?' : '&') + '$select=' + options.select;
        }
        if (options.orderby) {
            url += ((url.indexOf('?') == -1) ? '?' : '&') + '$orderby=' + options.orderby;
        }
        if (options.expand) {
            url += ((url.indexOf('?') == -1) ? '?' : '&') + '$expand=' + options.expand;
        }
        if (options.top) {
            url += ((url.indexOf('?') == -1) ? '?' : '&') + '$top=' + options.top;
        }
        if (options.skip) {
            url += ((url.indexOf('?') == -1) ? '?' : '&') + '$skip=' + options.skip;
        }
    }
    return url;
  }

  // READ entire list - needs $http factory and SharePoint list name
  read(listName: string, options?: any): Promise<any> {
    // Build URL syntax
    // https://msdn.microsoft.com/en-us/library/office/fp142385.aspx#bk_support
    let url = this.apiUrl.replace('{0}', listName);
    url = this.readBuilder(url, options);
    return this.http.get(url, this.options).toPromise().then(function(resp: any) {
      return resp.json();
    });
  }

  // READ single item - SharePoint list name, and item ID number
  readItem(listName: string, id: string): Promise<any> {
    let url = this.apiUrl.replace('{0}', listName) + '(' + id + ')';
    url = this.readBuilder(url, null);
    return this.http.get(url, this.options).toPromise().then(function(resp: any) {
      return resp.json();
    });
  }

  // CREATE item - SharePoint list name, and JS object to stringify for save
  create(listName: string, sharepointObject: any): Promise<any> {
    if (sharepointObject && !sharepointObject.__metadata) {
      sharepointObject.__metadata =  { type: 'SP.Data.' + listName + 'ListItem' };
    }

    return this.refreshDigest()
    .then((res: Response) => {
      this._setPostheaders();
      const url = this.apiUrl.replace('{0}', listName);
      const data = JSON.stringify(sharepointObject);
      return this.http.post(url, data, new RequestOptions({ headers: this.headers })).toPromise()
      .then((res: any) => {
        return res.json();
      });
    });
  }

  // UPDATE item - SharePoint list name, item ID number, and JS object to stringify for save
  update(listName: string, id: number, sharepointObject: any): Promise<any> {
    // Append HTTP header MERGE for UPDATE scenario
    // Append metadata

    sharepointObject.__metadata =  { type: 'SP.Data.' + listName + 'ListItem' };

    const data = JSON.stringify(sharepointObject);
    const url = this.apiUrl.replace('{0}', listName) + '(' + id + ')';

    return this.refreshDigest()
    .then(res => {
      this._setHeaderOptions('MERGE');
      const options = new RequestOptions({ headers: this.headers });
      return this.http.post(url, data, options).toPromise()
      .then(resp => {
        return resp?.json();
      });
    }).catch(error => {
      // reject(error);
    });
  }

  // DELETE item - SharePoint list name and item ID number
  del(listName: string, id: number): Promise<any> {
    // append HTTP header DELETE for DELETE scenario
    const url = this.apiUrl.replace('{0}', listName) + '(' + id + ')';
    return this.refreshDigest()
    .then(res => {
      this._setHeaderOptions('DELETE');
      const options = new RequestOptions({ headers: this.headers });

      return this.http.post(url, null, options).toPromise().then(resp => {
        return resp?.json();
      });
    });
  }

  // JSON blob read from SharePoint list - SharePoint list name
  jsonRead(listName: string): Promise<any> {
    const svc = this;
    return this.getCurrentUser().then(function(res: any) {
      // GET SharePoint Current User
      svc.currentUser = res.d;
      svc.login = res.d.LoginName.toLowerCase();
      if (svc.login.indexOf('\\')) {
        // Parse domain prefix
        svc.login = svc.login.split('\\')[1];
      }

      // GET SharePoint List Item
      const url = svc.apiUrl.replace('{0}', listName) + '?$select=JSON,Id,Title&$filter=Title+eq+\'' + svc.login + '\'';
      return svc.http.get(url, svc.options).toPromise().then(function(res2: any) {

        // Parse JSON response
        const d2 = res2.json().d;
        if (d2.results.length) {
          return d2.results[0];
        } else {
          return null;
        }

      }).catch(svc.handleError);
    });
  }

  // JSON blob upsert write to SharePoint list - SharePoint list name and JS object to stringify for save
  jsonWrite(listName: string, jsonBody: any) {
    const svc = this;
    return this.refreshDigest().then(function(res: Response) {
      return svc.jsonRead(listName).then(function(item: any) {
        // HTTP 200 OK
        if (item) {
          // update if found
          item.JSON = JSON.stringify(jsonBody);
          return svc.update(listName, item.Id, item);
        } else {
          // create if missing
          item = {
            __metadata: {
              type: 'SP.ListItem'
            },
            Title: svc.login,
            JSON: JSON.stringify(jsonBody)
          };
          return svc.create(listName, item);
        }
      });
    });
  }

  // HTTP Error handling
  private handleError(error: Response | any) {
    // Generic from https://angular.io/docs/ts/latest/guide/server-communication.html
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status || ''} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return throwError(()=>errMsg);
  }

  // String ends with
  private endsWith(str: string, suffix: string) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  private _setHeaderOptions(httpMethod:any) {
    if (!this.headers.has('X-HTTP-Method')) {
      this.headers.append('X-HTTP-Method', httpMethod);
    } else {
      this.headers.delete('X-HTTP-Method');
      this.headers.append('X-HTTP-Method', httpMethod);
    }
    if (!this.options.headers?.has('If-Match')) {
      this.headers.append('If-Match', '*');
    }
  }

  private _setPostheaders() {
    if (this.headers.has('X-HTTP-Method')) {
      this.headers.delete('X-HTTP-Method');
    }
    if (this.headers.has('If-Match')) {
      this.headers.delete('If-Match');
    }
  }
  // **
}
