import { SecurityMatrix } from 'src/shared/utils/security-matrix';

export class NewDealSetupSecurityMatrix extends SecurityMatrix {
    Access: boolean = false;
    Edit: boolean = false;
    AutoSave: boolean = false;
    AddSeries24: boolean = false;
    AddDealTeamMember: boolean = false;
    Submit: boolean = false;
    Approve: boolean = false;
    ReturnForReWork: boolean = false;
    Cancel: boolean = false;
    Discontinue: boolean = false;
    Reactivate: boolean = false;
    Review: boolean = false;
    ReadOnly: boolean = false;
    ShowAdminSection: boolean = false;
    EditAdminSection: boolean = false;
    AttachDocuments: boolean = false;
    RemoveDocuments: boolean = false;
    AddAdditionalDocumentTypes: boolean = false;
    ShowActiveSwiftCodeSection:boolean= false;
    EditActiveSwiftCodeSection: boolean = false;
}
