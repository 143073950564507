import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ReportDashboardItem } from '../models/reportDashboardItem';
import { ReportDashboardItemExportAll } from '../models/reportDashboardItemExportAll';
import { ReportDashboardTableFilterItem } from '../models/reportDashboard-table-filter-item';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { NgbdSortableHeaderDirective, SortDirection, SortEvent } from 'src/shared/directives/sorteable.directive';
import { PaginationComponent } from 'src/shared/components/pagination/pagination.component';
import { EngagementTableFilterItem } from '../models/engagement-table-filter-item';
import { ReportDashboardSort } from '../models/reportDashboard-sort';
import { PaginationValues } from 'src/shared/models/pagination-values';
import { DatePipe } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { AppSettings } from 'src/shared/app-settings';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { environment } from 'src/environments/environment';
import { DateService } from '../services/date.service';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { CommonHelperService } from '../commonHelper/common-helper.service';
import { GlobalHelperService } from '../globalHelper/global-helper.service';

@Component({
  selector: 'app-crossborder-dashboard-details',
  templateUrl: './crossborder-dashboard-details.component.html',
  styleUrls: ['./crossborder-dashboard-details.component.scss']
})
export class CrossborderDashboardDetailsComponent implements OnInit {
  AdminRole:any="";
  reportDashboardExportAll: Array<ReportDashboardItemExportAll> = [];
  reportDashboards: Array<ReportDashboardItem>;
  reportDashboardsPerPage: Array<ReportDashboardItem>;
  filterList: Array<ReportDashboardTableFilterItem> = [];
  targetField: string;
  targetFilter: ReportDashboardTableFilterItem;
  fieldOptionValues: Array<string> = [];
  s24List:any=[];
  isColumnfilterApply:any = false;
  IndustryRepresenatativeList:any=[];
  initialReportDashboardList: Array<ReportDashboardItem> = [];
  onSearchApplyOnFilteredData:Array<ReportDashboardItem> = [];
  SearchingResults:Array<ReportDashboardItem> = [];
  initialReportDashboardListForSearch: Array<ReportDashboardItem> = [];
  SearchingValue: any="";
  @ViewChild(NotifyPopupComponent, { static: true }) notifypopupComponent: NotifyPopupComponent;
  pageSizeListValues: Array<any> = [];
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  isAdminUser: any = false;
  @ViewChild('pagination', { static: false })
  pagination: PaginationComponent;
  engagementHealthObjectUrl: string;
  reportDashboardExportToExcel: Array<any> = [];
  selectedPageSize: any;
  reportDashboardPageDetails: Array<any> = [];
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  fileName = 'ExcelSheet.xlsx';
  showDisable: boolean;
  pageDropdownDisable: boolean;
  searchTerm: any="";
  constructor(public adminService: AdminService,public globalHelperService:GlobalHelperService, private datePipe: DatePipe,private dateService: DateService,private spinnerService:SpinnerService,public commonHelperService:CommonHelperService
  ) {

  }

  ngOnInit(): void {  
      this.showDisable = true;
      this.spinnerService.showAutoSaveSpinner();
      localStorage.setItem("CurrentAccessPage", "Dashboard");
      this.globalHelperService.currentAccessPage="Dashboard";
      this.commonHelperService.state = this.commonHelperService.getSearchState();
      this.removeBrowserScroll();
      this.CheckAdminUser();

  }

  removeBrowserScroll(){
    document.body.style.overflow="hidden";
  }
  onPageSizeValueChange(event: any) {
    if(this.selectedPageSize=="All")
    {
    const values: PaginationValues = {
      page: this.commonHelperService.state.page,
      pageSize: parseInt(this.initialReportDashboardList.length.toString())
    };
    this.onChangePaginationValues(values);
  }
  else{
    const values: PaginationValues = {
      page: this.commonHelperService.state.page,
      pageSize: parseInt(this.selectedPageSize)
    };
    this.onChangePaginationValues(values);
  }
  }
  getDashboardDetails(searchText: any) {
    // const el = document.getElementById('onetrust-consent-sdk');
    // if (el != null) 
    // {
    //       el.style.display = 'none';
    // }
    this.adminService.GetDashboardDetails(searchText).subscribe(
      {
        next: (result: any) => {
          this.reportDashboardExportAll = result;
          result=this.BindRoleLevelData(result);//Displaying role level Data
          this.initialReportDashboardList = this.createCrossBorderObject(result);        
          this.initialReportDashboardListForSearch=this.initialReportDashboardList;
          this.setReportDashboard(this.initialReportDashboardList, this.pageSizeListValues);
          // const el = document.getElementById('onetrust-consent-sdk');
          // if (el != null) 
          // {
          //       el.style.display = 'block';
          // }
        },
        error: (err: any) => {
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
        }
        
      })
  }
  BindRoleLevelData(result:any){
    if(this.AdminRole != "Cross Border Administrators"){
    result=this.CheckUserInExistTeams(result);
    }
    return result;
  }
  LoadReviewStatusValues(formObj:any){
    //Added as per SharePoint Version
    if(formObj.BusinessReviewStatus==null||formObj.BusinessReviewStatus!=""||formObj.BusinessReviewStatus!=undefined){
     formObj.BusinessReviewStatus="Review in Progress";
    }
    if(formObj.QRMReviewStatus==null||formObj.QRMReviewStatus==""||formObj.QRMReviewStatus==undefined){
      formObj.QRMReviewStatus="Review in Progress";
    }
    if(formObj.ComplianceReviewStatus==null||formObj.ComplianceReviewStatus==""||formObj.ComplianceReviewStatus==undefined){
      formObj.ComplianceReviewStatus="Review in Progress";
    }
  }
  getActualStatus(formObj:any){
let resStatus:any="";
    if (formObj.FormActionGroup == "MemberFirmTeam") {
      this.LoadReviewStatusValues(formObj);
      if (formObj.FormStatus == "Draft") {
        resStatus = "Draft";
      }
      else if (formObj.FormStatus == "Pending") {
        resStatus = "Returned for Rework";
      }
      else if (formObj.FormStatus == "Reactivated") {
        resStatus = "Reactivated";
      }
      else if (formObj.FormStatus == "Rejected") {
        resStatus = "Rejected";
      }
      else {
        if (formObj.FormStatus == "OnHold") {
          resStatus = "On Hold";
        }
        else {
          resStatus = formObj.FormStatus;
        }
      }

    }
    else if (formObj.FormActionGroup == "SecondSubmissionTeam") {
      if (formObj.FormStatus == "Pending") {
        resStatus = "Pending for submission";
      }
      else if (formObj.FormStatus == "Approved") {
        resStatus = "Approved";
      }
      else {
        if (formObj.FormStatus == "OnHold") {
          resStatus = "On Hold";
        }
        else {
          resStatus = formObj.FormStatus;
        }
      }
    }
    else if (formObj.FormActionGroup == "Series24") {
      if (formObj.FormStatus == "Completed") {
        resStatus = "Completed";
      }
      else if (formObj.FormStatus == "Pending") {
        resStatus = "Pending for Series 24 approval";
      }
      else if (formObj.FormStatus == "Submitted") {
        resStatus = "Submitted";
      }
      else {
        if (formObj.FormStatus == "OnHold") {
          resStatus = "On Hold";
        }
        else {
          resStatus = formObj.FormStatus;
        }
      }
    }
    else if (formObj.FormActionGroup == "BusinessQRMCompliance") {
      if (formObj.FormStatus == "Pending") {
        resStatus = "Review in progress";
      }
      else {
        if (formObj.FormStatus == "OnHold") {
          resStatus = "On Hold";
        }
        else {
          resStatus = formObj.FormStatus;
        }
      }
    }
    else if (formObj.FormActionGroup == "IndustryRepresentative") {
      this.LoadReviewStatusValues(formObj);
      if (formObj.FormStatus == "Pending") {
        resStatus = "Pending for Industry Representative approval";
      }
      else {
        if (formObj.FormStatus == "OnHold") {
          resStatus = "On Hold";
        }
        else {
          resStatus = formObj.FormStatus;
        }
      }
    }
    else {
      if (formObj.FormStatus == "OnHold") {
        resStatus = "On Hold";
      }
      else {
        resStatus = formObj.FormStatus;
      }
    }
    return resStatus;
  }
  BindIndustryRepresentative(xobj:any){
    let representativeGrp:any="";
if(xobj.Industry!=""&&xobj.Industry!=null){
  this.IndustryRepresenatativeList.forEach((x:any)=>{
    if(x.Industry.toLowerCase().trim()==xobj.Industry.toLowerCase().trim()){representativeGrp=x.Representative;}
  });
  return representativeGrp;
}
  }
  createCrossBorderObject(result: any) {
    let crossBorderArrayObject: Array<any> = [];
    result.forEach((x: any) => {
        x.IndustryRepresentative=this.BindIndustryRepresentative(x);
        x.Status= this.getActualStatus(x);
      let obj = {
        iD: x.ID,
        projectName: x.ProjectName == null ? 'Empty' : x.ProjectName,
        isSelected: false,
        status: x.Status == null ?'-' : x.Status,
        country: x.Country == null ? '-' : x.Country,
        industry: x.Industry == null ? '-' : x.Industry,
        //dCFRiskRating:x.DFCRiskRating==null?'-':this._formatDCFRiskRating(x.DFCRiskRating),
        enterpriseValue: x.EnterpriseValue == null ? '-' : this._formatenterpriseValue(x.EnterpriseValue),
        nameOfS24: x.Series24 == null ? '-' : this.replaceMailIDWithDisplayNames(x.Series24,x.Series24DisplayNames),
        // s24ApprovalStatus:x.Status =='Completed'?'Completed': x.Status=="Pending for Series 24 approval"?'Pending':'Not submitted for S24 approval',
        created: x.Created == null ? '-' : this.dateService.FormatDate(x.Created),
        //dCFDateApproval:x.Series24DateApproval==null?'-':x.Status =='Completed'?this._formatDate(x.Series24DateApproval):'-',
        //New Columns Added
        clientName: x.ClientName == null ? '-' : x.ClientName,
        proposedTransactionType: x.ProposedTransactionType == null ? '-' : this.splitSplCharacterValues(x.ProposedTransactionType),
        modified: x.Modified == null ? '-' : this.dateService.FormatDate(x.Modified),
        businessReviewStatus: x.BusinessReviewStatus == null ? '-' : this.RestrictDuplicacyReviewStatus(x.BusinessReviewStatus),
        QRMReviewStatus: x.QRMReviewStatus == null ? '-' : this.RestrictDuplicacyReviewStatus(x.QRMReviewStatus),
        complianceReviewStatus: x.ComplianceReviewStatus == null ? '-' : this.RestrictDuplicacyReviewStatus(x.ComplianceReviewStatus),
        internationalMemberFirmTeam: x.InternationalMemberFirmTeam == null ? '-' : this.replaceMailIDWithDisplayNames(x.InternationalMemberFirmTeam,x.InternationalMemberFirmTeamDisplayNames),
        DCFEngagementTeam: x.DCFEngagementTeam == null ? '-' : this.replaceMailIDWithDisplayNames(x.DCFEngagementTeam,x.DCFEngagementTeamDisplayNames),
        industryRepresentative: x.IndustryRepresentative == null ? '-' : this.splitSplCharacterValues(x.IndustryRepresentative),
        formActionGroup: x.FormActionGroup == null ? '-' : x.FormActionGroup,
        formStatus: x.FormStatus == null ? '-' : x.FormStatus,
        discontinuedOn: x.DiscontinuedOn == null ? '-' : this.dateService.FormatDate(x.DiscontinuedOn),
        discontinueReason: x.DiscontinueReason == null ? '-' : x.DiscontinueReason,
        series24Comments: x.Series24Comments == null ? '-' : x.Series24Comments,
        series24DateApproval: x.Series24DateApproval == null ? '-' : this.dateService.FormatDate(x.Series24DateApproval),
        series24Signature: x.Series24Signature == null ? '-' : x.Series24Signature,
        createdBy: x.CreatedBy == null ? '-' : x.CreatedBy,
        dealTeamBusinessReviewer: x.DealTeamBusinessReviewer == null ? '-' : this.replaceMailIDWithDisplayNames(x.DealTeamBusinessReviewer,x.DealTeamBusinessReviewerDisplayNames),
        DCFDateApproval: x.DCFDateApproval == null ? '-' : this.dateService.FormatDate(x.DCFDateApproval),
        incomeYear1: x.IncomeYear1 == null ? '-' : x.IncomeYear1,
        incomeRevenuePercentage1: x.IncomeRevenuePercentage1 == null ? '-' : x.IncomeRevenuePercentage1,
        incomeYear2: x.IncomeYear2 == null ? '-' : x.IncomeYear2,
        incomeRevenuePercentage2: x.IncomeRevenuePercentage2 == null ? '-' : x.IncomeRevenuePercentage2,
        incomeYear3: x.IncomeYear3 == null ? '-' : x.IncomeYear3,
        incomeRevenuePercentage3: x.IncomeRevenuePercentage3 == null ? '-' : x.IncomeRevenuePercentage3,
        modifiedBy: x.ModifiedBy == null ? '-' : x.ModifiedBy

      }
        if(obj.iD > 0)
          crossBorderArrayObject.push(obj); 
    });
    crossBorderArrayObject.sort((a, b) => b.iD - a.iD);
    return crossBorderArrayObject;
  }

RestrictDuplicacyReviewStatus(reviewStatus:string){
if(reviewStatus=="Review in Progress"||reviewStatus=="Review in progress"){
  reviewStatus="Review in Progress";
}
return reviewStatus;
}

  replaceMailIDWithDisplayNames(valueID:any,valueName:any){
    let resultData:any="";
    try{
if(valueName!=null){
  if(valueName.includes(";#"))
  {
    valueName=valueName.split(";#");
    for (let i=0;i<valueName.length;i++) {    
      resultData=resultData+valueName[i];
      if(i!=valueName.length-1){
        resultData=resultData+"; ";
      }
    }

  }
  else{
    resultData=valueName;
  }
}
else{
      if(valueID.includes(";#"))
      {
        valueID=valueID.split(";#");
        for (let i=0;i<valueID.length;i++) {    
          resultData=resultData+valueID[i];
          if(i!=valueID.length-1){
            resultData=resultData+"; ";
          }
        }
  
      }
      else{
        resultData=valueID;
      }
    }
    }
    catch(e){
    
    }
    
return resultData;
  }
  replaceMailIDWithName(mailId:any){
    let value=mailId;
   this.s24List.forEach((x:any)=>{
if(mailId==x.Email){value=x.fullName;}
    });
    return value;
  }
  splitSplCharacterValues(value:any){
    let resultData:any="";
    try{
      if(value.includes(";#"))
      {
        value=value.split(";#");
        for (let i=0;i<value.length;i++) {
         value[i]= this.convertStringTextFormat(value[i]);
          resultData=resultData+value[i];
          if(i!=value.length-1){
            resultData=resultData+",";
          }
        }
  
      }
      else{
        resultData=this.convertStringTextFormat(value);
      }
    }
    catch(e){
      resultData=value;
    }
    
return resultData;
  }
  convertStringTextFormat(value:any){
    if (value == "EquityCapitalRaise") {
      value = "Equity Capital Raise";
    }
    else if (value == "DebtCapitalRaise") {
      value = "Debt Capital Raise";
    }
    return value;
  }
  private _formatDCFRiskRating(dCFRiskRating: string): string {

    if (dCFRiskRating == "Normal") {
      dCFRiskRating = "NR"
    }
    else if (dCFRiskRating == "GreaterThanNormal") {
      dCFRiskRating = "GTNR"
    }
    else {
      dCFRiskRating = "MGTNR"
    }
    return dCFRiskRating;
  }
  private _formatenterpriseValue(enterpriseValue: string): string {

    if (enterpriseValue.includes("-")) {
      enterpriseValue = "$" + enterpriseValue;

      let Symbol = "$";
      let index =
        enterpriseValue.indexOf("-");
      enterpriseValue = [enterpriseValue.slice(0, index + 1), Symbol, enterpriseValue.slice(index + 1)].join('');

    }
    else {
      enterpriseValue = "$" + enterpriseValue;
    }

    return enterpriseValue;
  }
  
  private _formatDate(adminApprovalDate: string): string {
    try {
      var isIstTime:any = false;
      if(!adminApprovalDate.includes("T")){
        adminApprovalDate = this.dateService.ConvertUTCToIST(adminApprovalDate).toString();
        isIstTime = true;
      }
      if (adminApprovalDate != "" && adminApprovalDate != null && adminApprovalDate != undefined) {
        const dateObject = new Date(adminApprovalDate);

        return this.dateService.GetCSTDateTimeString(dateObject,isIstTime);
      }
      else {
        return adminApprovalDate;
      }
    }
    catch (e) {
      return adminApprovalDate;
    }
  }

  getIndustryRepresentativeGroups(){
    this.adminService.GetIndustryRepresentativeGroups().subscribe(
      {
        next: (result: any) => {          
        
this.IndustryRepresenatativeList=result;
this.getDashboardDetails("ALL");
        },
        error: (err: any) => {
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err)
        }
      })
  }
  getS24List(){
    let S24GroupId="98551249-f939-4b84-a2be-6533d5289813";
    this.adminService.GetUserGroups(S24GroupId,true).subscribe(
      {
        next: (result: any) => {          
        
result.forEach((v:any)=>{
if(v.FirstName!=""||v.LastName!=""){
  v.fullName=v.LastName+", "+v.FirstName;
}
});
this.s24List=result;
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err)
        }
      })
  }
  private setPagenationMultiSelect(checkboxStatus: any) {
    if (this.reportDashboardPageDetails.length > 0) {
      this.reportDashboardPageDetails.forEach(v => {
        if (v.PageNumber == this.commonHelperService.state.page) {
          v.IsMultiSelect = checkboxStatus
        }
        else {
          let reportPageItem = {
            PageNumber: this.commonHelperService.state.page,
            PageSize: this.commonHelperService.state.pageSize,
            IsMultiSelect: checkboxStatus
          };
          this.reportDashboardPageDetails.push(reportPageItem);
        }
      });
    }
    else {
      let reportPageItem = {
        PageNumber: this.commonHelperService.state.page,
        PageSize: this.commonHelperService.state.pageSize,
        IsMultiSelect: checkboxStatus
      };
      this.reportDashboardPageDetails.push(reportPageItem);
    }
  }
  onCheckboxChange(checkbox: any) {
    this.setPagenationMultiSelect(checkbox.target.checked);
    if (checkbox.target.checked) {
      this.reportDashboardsPerPage.forEach(v => {

        let reportDashboardItem = {
          ID: v.iD,
          ProjectName: v.projectName,
          // ClientName: v.clientName,
          Status: v.status,
          Country: v.country,
          Industry: v.industry,
          //  DCFRiskRating:v.dCFRiskRating,
          // EnterpriseValue:v.enterpriseValue,
          ProposedTransactionType: v.proposedTransactionType,
          NameOfS24: v.nameOfS24,
          //  S24ApprovalStatus:v.s24ApprovalStatus,
          Created: v.created,
          // DCFDateApproval:v.dCFDateApproval,
          Modified: v.modified,
          BusinessReviewStatus: v.businessReviewStatus,
          QRMReviewStatus: v.QRMReviewStatus,
          ComplianceReviewStatus: v.complianceReviewStatus,
          InternationalMemberFirmTeam: v.internationalMemberFirmTeam,
          DCFEngagementTeam: v.DCFEngagementTeam,
          IndustryRepresentative: v.industryRepresentative,
          FormActionGroup: v.formActionGroup,
          FormStatus: v.formStatus,
          DiscontinuedOn: v.discontinuedOn,
          DiscontinueReason: v.discontinueReason,
          EnterpriseValue: v.enterpriseValue,
          Series24Comments: v.series24Comments,
          Series24DateApproval: v.series24DateApproval,
          Series24Signature: v.series24Signature,
          CreatedBy: v.createdBy,
          DealTeamBusinessReviewer: v.dealTeamBusinessReviewer,
          DCFDateApproval: v.DCFDateApproval,
          IncomeYear1: v.incomeYear1,
          IncomeRevenuePercentage1: v.incomeRevenuePercentage1,
          IncomeYear2: v.incomeYear2,
          IncomeRevenuePercentage2: v.incomeRevenuePercentage2,
          IncomeYear3: v.incomeYear3,
          IncomeRevenuePercentage3: v.incomeRevenuePercentage3,
          ModifiedBy: v.modifiedBy
        };
        if (!this.reportDashboardExportToExcel.find(e => e.ID === v.iD)) {
          this.reportDashboardExportToExcel.push(reportDashboardItem);
        }

      });
      this.reportDashboards.forEach(v => {
        if (this.reportDashboardExportToExcel.find(e => e.ID === v.iD))
          v.isSelected = true;
      });
      //enable the export option
      this.showDisable = true;
      if (this.reportDashboardExportToExcel.length > 0) {
        this.showDisable = false;
      }
    }
    else {
      this.reportDashboardsPerPage.forEach(v => {
        this.reportDashboardExportToExcel = this.reportDashboardExportToExcel.
          filter(e => e.ID !== v.iD);
      })

      this.reportDashboards.forEach(v => {
        if (this.reportDashboardsPerPage.find(e => e.iD === v.iD))
          v.isSelected = false;
      });

      //enable the export option
      this.showDisable = true;
      if (this.reportDashboardExportToExcel.length > 0) {
        this.showDisable = false;
      }
    }
  }
  
GetMonthName (date:any) {
  var objDate = new Date(date),
  locale = "en-us",
  month = objDate.toString().split(" ")[1];
  return month;
}
  onCheckboxChanged(event: any) {
    if (this.reportDashboards.find(e => e.iD == event && e.isSelected == false)) {
      let reportDashboardItem: any;
      this.reportDashboards.forEach(v => {
        if (v.iD == event) {
          reportDashboardItem = {
            ID: v.iD,
            ProjectName: v.projectName,
            //ClientName: v.clientName,
            Status: v.status,
            Country: v.country,
            Industry: v.industry,
            //  DCFRiskRating:v.dCFRiskRating,
            // EnterpriseValue:v.enterpriseValue,
            ProposedTransactionType: v.proposedTransactionType,
            NameOfS24: v.nameOfS24,
            //  S24ApprovalStatus:v.s24ApprovalStatus,
            Created: v.created,
            // DCFDateApproval:v.dCFDateApproval,
            Modified: v.modified,
            BusinessReviewStatus: v.businessReviewStatus,
            QRMReviewStatus: v.QRMReviewStatus,
            ComplianceReviewStatus: v.complianceReviewStatus,
            InternationalMemberFirmTeam: v.internationalMemberFirmTeam,
            DCFEngagementTeam: v.DCFEngagementTeam,
            IndustryRepresentative: v.industryRepresentative,
            FormActionGroup: v.formActionGroup,
            FormStatus: v.formStatus,
            DiscontinuedOn: v.discontinuedOn,
            DiscontinueReason: v.discontinueReason,
            EnterpriseValue: v.enterpriseValue,
            Series24Comments: v.series24Comments,
            Series24DateApproval: v.series24DateApproval,
            Series24Signature: v.series24Signature,
            CreatedBy: v.createdBy,
            DealTeamBusinessReviewer: v.dealTeamBusinessReviewer,
            DCFDateApproval: v.DCFDateApproval,
            IncomeYear1: v.incomeYear1,
            IncomeRevenuePercentage1: v.incomeRevenuePercentage1,
            IncomeYear2: v.incomeYear2,
            IncomeRevenuePercentage2: v.incomeRevenuePercentage2,
            IncomeYear3: v.incomeYear3,
            IncomeRevenuePercentage3: v.incomeRevenuePercentage3,
            ModifiedBy: v.modifiedBy
          };
        }
      })

      this.reportDashboardExportToExcel.push(reportDashboardItem);
      this.reportDashboards.forEach(v => {
        if (v.isSelected == false && v.iD == event)
          v.isSelected = true;
      });
      if (!this.reportDashboardsPerPage.find(v => v.isSelected == false)) {
        const checkbox = document.getElementById(
          'chk_MultiCheckBox',
        ) as HTMLInputElement;
        checkbox.checked = true;
        if (this.reportDashboardPageDetails.length != 0) {
          this.reportDashboardPageDetails.forEach(v => {
            if (v.PageNumber == this.commonHelperService.state.page) {
              v.IsMultiSelect = true
            }
            else {
              let reportPageItem = {
                PageNumber: this.commonHelperService.state.page,
                PageSize: this.commonHelperService.state.pageSize,
                IsMultiSelect: true
              };
              this.reportDashboardPageDetails.push(reportPageItem);
            }
          });
        }
        else {
          let reportPageItem = {
            PageNumber: this.commonHelperService.state.page,
            PageSize: this.commonHelperService.state.pageSize,
            IsMultiSelect: true
          };
          this.reportDashboardPageDetails.push(reportPageItem);
        }
      }
    }
    else {
      this.reportDashboardExportToExcel = this.reportDashboardExportToExcel.
        filter(e => e.ID !== event);
      this.reportDashboards.forEach(v => {
        if (v.isSelected == true && v.iD == event)
          v.isSelected = false;
      });
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement;
      checkbox.checked = false;
      this.reportDashboardPageDetails.forEach(v => {
        if (v.PageNumber == this.commonHelperService.state.page) {
          v.IsMultiSelect = false
        }
      });
    }
    this.showDisable = true;
    if (this.reportDashboardExportToExcel.length > 0) {
      this.showDisable = false;
    }
    else {
      this.reportDashboardPageDetails.length = 0;
    }
  }
  clearSearchFilters() {
    this.isColumnfilterApply=false;
    this.getSearchResults("ALL");
    this.clearAllFilters(true);
  }
  onSearchResult(result: any) {
    //this.clearAllFilters(false);
    //API Call
    //this.getDashboardDetails(result == "" ? "ALL" : result);

    if(!this._isSomeFilterApply())
        this.onSearchApplyOnFilteredData = [];
    this.SearchingResults=[];
        
  // Customised search Functionality
   this.getSearchResults(result == "" ? "ALL" : result);
  }
  getSearchResults(searchValue:any){
    this.searchTerm = searchValue;
    let searchArray:any=[];
    if(searchValue=="ALL"&&!this.isColumnfilterApply){
      searchArray=this.initialReportDashboardListForSearch;
    }
    else{
      if(this.onSearchApplyOnFilteredData.length>0){
        if(searchValue=="ALL"){
         searchArray=this.onSearchApplyOnFilteredData;
        }
        else{
        // searchArray=this.onSearchApplyOnFilteredData.filter(x=>x.projectName.toLowerCase().includes(searchValue.toLowerCase()));
        searchArray = this.onSearchApplyOnFilteredData.filter((item) => {
          // Apply filter conditions on each column
          return (
            item.iD.toString().includes(searchValue.toLowerCase()) ||
            item.projectName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.clientName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.country.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.industry.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.proposedTransactionType.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        }
      }
      else{
        // searchArray=this.initialReportDashboardListForSearch.filter(x=>x.projectName.toLowerCase().includes(searchValue.toLowerCase()));
        searchArray = this.initialReportDashboardListForSearch.filter((item) => {
          // Apply filter conditions on each column
          return (
            item.iD.toString().includes(searchValue.toLowerCase()) ||
            item.projectName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.clientName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.country.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.industry.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.proposedTransactionType.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
      }
      this.SearchingResults=searchArray;
    }
   
    this.setReportDashboard(searchArray, this.pageSizeListValues);
  }
  exportToExcel() {

    // for (let i = 0; i < this.reportDashboardExportToExcel.length; i++) {
    //   delete this.reportDashboardExportToExcel[i].ID;
    // }

    this.exportExcel(this.reportDashboardExportToExcel, 'CrossBorderReport');

  }
  public exportExcel(jsonData: any[], fileName: string): void {
    jsonData = jsonData.map(doc => Object.values(doc));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    worksheet['A1'].v = "ID";
    worksheet['B1'].v = "Project Name";
    // worksheet['C1'].v = "Client Name";
    worksheet['C1'].v = "Status";
    worksheet['D1'].v = "Member Firm's Country";
    worksheet['E1'].v = "Industry";
    worksheet['F1'].v = "Proposed Transaction Type";
    // worksheet['G1'].v = "Risk Rating";
    //worksheet['H1'].v = "Enterprise value (in USD $)";
    worksheet['G1'].v = "Series 24";
    // worksheet['H1'].v = "S24 Approval Status";
    worksheet['H1'].v = "Date Created";
    //worksheet['J1'].v = "Date Approved";
    worksheet['I1'].v = "Date Modified";
    worksheet['J1'].v = "Business Review Status";
    worksheet['K1'].v = "QRM Review Status";
    worksheet['L1'].v = "Compliance Review Status";
    worksheet['M1'].v = "International MemberFirm Team";
    worksheet['N1'].v = "DCF Engagement Team";
    worksheet['O1'].v = "Industry Representative";
    worksheet['P1'].v = "Form Action Group";
    worksheet['Q1'].v = "Form Status";
    worksheet['R1'].v = "Discontinued On";
    worksheet['S1'].v = "Discontinue Reason";
    worksheet['T1'].v = "Enterprise value (in USD $)";
    worksheet['U1'].v = "Series24 Comments";
    worksheet['V1'].v = "Series24 Date Approval";
    worksheet['W1'].v = "Series24 Signature";
    worksheet['X1'].v = "Created By";
    worksheet['Y1'].v = "Deal Team Business Reviewer";
    worksheet['Z1'].v = "DCF Date Approval";
    worksheet['AA1'].v = "Income Year1";
    worksheet['AB1'].v = "Income Revenue Percentage1 (in %)";
    worksheet['AC1'].v = "Income Year2";
    worksheet['AD1'].v = "Income Revenue Percentage2 (in %)";
    worksheet['AE1'].v = "Income Year3";
    worksheet['AF1'].v = "Income Revenue Percentage3 (in %)";
    worksheet['AG1'].v = "Modified By";
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, 'Result_export_' + new Date().getTime() + ".xlsx");

    // const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([{A:1,B:2},{B:2,C:3}], {header:['C']})
    //  //XLSX.utils.json_to_sheet(jsonData,{header:Header});
    // const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    // const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  private _formatIncomeRevenueValue(incomeRevenue: string): string {
    if (!incomeRevenue.includes("$")) {
      incomeRevenue = "$" + incomeRevenue;
    }
    return incomeRevenue;
  }

  createCrossBorderObjectExportAll(result: any) {
    let crossBorderArrayObject: Array<any> = [];
    result.forEach((x: any) => {
      let obj = {
                iD: x.ID,
                formStatus: x.FormStatus == null ? '' : x.FormStatus,
                status: x.Status == null ?'' : x.Status,
                complianceReviewStatus: x.ComplianceReviewStatus == null ? '' : x.ComplianceReviewStatus,
                country: x.Country == null ? '' : x.Country,
                businessReviewStatus: x.BusinessReviewStatus == null ? '' :x.BusinessReviewStatus,
                QRMReviewStatus: x.QRMReviewStatus == null ? '' : x.QRMReviewStatus,
                industry: x.Industry == null ? '' : x.Industry,
                dFCRiskRating: x.DFCRiskRating == null ? '' : x.DFCRiskRating,
                myDealSeries24: x.MyDealSeries24 == null ? '' : x.MyDealSeries24,
                series24: x.Series24 == null ? '' : x.Series24,
                nameOfS24: x.Series24 == null ? '' : x.Series24DisplayNames,
                series24Comments: x.Series24Comments == null ? '' : x.Series24Comments,
                series24DateApproval: x.Series24DateApproval == null ? '' : this.dateService.FormatDate(x.Series24DateApproval),
                created: x.Created == null ? '' : this.dateService.FormatDate(x.Created),
                DCFDateApproval: x.DCFDateApproval == null ? '' : this.dateService.FormatDate(x.DCFDateApproval),
                uRL: x.URL == null ? '' : x.URL,
                createdBy: x.CreatedBy == null ? '' : x.CreatedBy,
                dealTeamBusinessReviewer: x.DealTeamBusinessReviewer == null ? '' : x.DealTeamBusinessReviewer,
                DealTeamBusinessReviewerDisplayNames: x.DealTeamBusinessReviewerDisplayNames == null ? '' : x.DealTeamBusinessReviewerDisplayNames,
                projectName: x.ProjectName == null ? '' : x.ProjectName,
                myDealEngagementName: x.MyDealEngagementName == null ? '' : x.MyDealEngagementName,
                myDealClientName: x.MyDealClientName == null ? '' : x.MyDealClientName,
                proposedTransactionType: x.ProposedTransactionType == null ? '' : x.ProposedTransactionType,
                internationalMemberFirmTeam: x.InternationalMemberFirmTeam == null ? '' : x.InternationalMemberFirmTeam,
                internationalMemberFirmTeamDisplayNames: x.InternationalMemberFirmTeamDisplayNames == null ? '' : x.InternationalMemberFirmTeamDisplayNames,
                dCFEngagementTeam: x.DCFEngagementTeam == null ? '' : x.DCFEngagementTeam,
                dCFEngagementTeamDisplayNames: x.DCFEngagementTeamDisplayNames == null ? '' : x.DCFEngagementTeamDisplayNames,
                enterpriseValue: x.EnterpriseValue == null ? '' : this._formatenterpriseValue(x.EnterpriseValue),
                modified: x.Modified == null ? '' : this.dateService.FormatDate(x.Modified),
                modifiedBy: x.ModifiedBy == null ? '' : x.ModifiedBy,
                crossBorderIntegrationWorkflowSTG: x.CrossBorderIntegrationWorkflowSTG == null ? '' : x.CrossBorderIntegrationWorkflowSTG,
                myDeal: x.MyDeal == null ? '' : x.MyDeal,
                myDealProjectName: x.MyDealProjectName == null ? '' : x.MyDealProjectName,
                myDealID: x.MyDealID == null ? '' : x.MyDealID,
                formActionGroup: x.FormActionGroup == null ? '' : x.FormActionGroup,
                countryOfOrigin: x.CountryOfOrigin == null ? '' : x.CountryOfOrigin,
                isMyDealForm: x.IsMyDealForm == null ? '' : x.IsMyDealForm,
                engagementTeam: x.EngagementTeam == null ? '' : x.EngagementTeam,
                schedule3DocumentRequired: x.Schedule3DocumentRequired == null ? '' : x.Schedule3DocumentRequired,
                businessReturnedForRework: x.BusinessReturnedForRework == null ? '' : x.BusinessReturnedForRework,
                clientName: x.ClientName == null ? '' : x.ClientName,
                companySubsectorComments: x.CompanySubsectorComments == null ? '' : x.CompanySubsectorComments,
                complianceReturnedForRework: x.ComplianceReturnedForRework == null ? '' : x.ComplianceReturnedForRework,
                contentType: x.ContentType == null ? '' : x.ContentType,
                createdDate: x.CreatedDate == null ? '' : this.dateService.FormatDate(x.CreatedDate),
                dCFComments: x.DCFComments == null ? '' : x.DCFComments,
                discontinuedBy: x.DiscontinuedBy == null ? '' : x.DiscontinuedBy,
                discontinuedFormActionGroup: x.DiscontinuedFormActionGroup == null ? '' : x.DiscontinuedFormActionGroup,
                discontinuedFormStatus: x.DiscontinuedFormStatus == null ? '' : x.DiscontinuedFormStatus,
                discontinuedOn: x.DiscontinuedOn == null ? '' : this.dateService.FormatDate(x.DiscontinuedOn),
                discontinueReason: x.DiscontinueReason == null ? '' : x.DiscontinueReason,
                expectedCompanyValueComments: x.ExpectedCompanyValueComments == null ? '' : x.ExpectedCompanyValueComments,
                formRegistered: x.FormRegistered == null ? '' : x.FormRegistered,
                geographConcentrationComments: x.GeographConcentrationComments == null ? '' : x.GeographConcentrationComments,
                incomeRevenue1: x.IncomeRevenue1 == null ? '' : this._formatIncomeRevenueValue(x.IncomeRevenue1),
                incomeRevenue2: x.IncomeRevenue2 == null ? '' : this._formatIncomeRevenueValue(x.IncomeRevenue2),
                incomeRevenue3: x.IncomeRevenue3 == null ? '' : this._formatIncomeRevenueValue(x.IncomeRevenue3),
                incomeRevenuePercentage1: x.IncomeRevenuePercentage1 == null ? '' : x.IncomeRevenuePercentage1,
                incomeRevenuePercentage2: x.IncomeRevenuePercentage2 == null ? '' : x.IncomeRevenuePercentage2,
                incomeRevenuePercentage3: x.IncomeRevenuePercentage3 == null ? '' : x.IncomeRevenuePercentage3,
                incomeYear1: x.IncomeYear1 == null ? '' : x.IncomeYear1,
                incomeYear2: x.IncomeYear2 == null ? '' : x.IncomeYear2,
                incomeYear3: x.IncomeYear3 == null ? '' : x.IncomeYear3,
                industryRepresentative: x.IndustryRepresentative == null ? '' : x.IndustryRepresentative,
                industryRepresentativeComments: x.IndustryRepresentativeComments == null ? '' : x.IndustryRepresentativeComments,
                industryRepresentativeSignature: x.IndustryRepresentativeSignature == null ? '' : x.IndustryRepresentativeSignature,
                initialSettings: x.InitialSettings == null ? '' : x.InitialSettings,
                itemChildCount: x.ItemChildCount == null ? '' : x.ItemChildCount,
                otherProposedTransactionType: x.OtherProposedTransactionType == null ? '' : x.OtherProposedTransactionType,
                putOnHoldBy: x.PutOnHoldBy == null ? '' : x.PutOnHoldBy,
                putOnHoldFormActionGroup: x.PutOnHoldFormActionGroup == null ? '' : x.PutOnHoldFormActionGroup,
                putOnHoldFormStatus: x.PutOnHoldFormStatus == null ? '' : x.PutOnHoldFormStatus,
                putOnHoldOn: x.PutOnHoldOn == null ? '' : this.dateService.FormatDate(x.PutOnHoldOn),
                putOnHoldReactivatedBy: x.PutOnHoldReactivatedBy == null ? '' : x.PutOnHoldReactivatedBy,
                putOnHoldReactivatedOn: x.PutOnHoldReactivatedOn == null ? '' : this.dateService.FormatDate(x.PutOnHoldReactivatedOn),
                putOnHoldReason: x.PutOnHoldReason == null ? '' : x.PutOnHoldReason,
                qRMComplianceNotificationNeeded: x.QRMComplianceNotificationNeeded == null ? '' : x.QRMComplianceNotificationNeeded,
                qRMReturnedForRework: x.QRMReturnedForRework == null ? '' : x.QRMReturnedForRework,
                reactivatedBy: x.ReactivatedBy == null ? '' : x.ReactivatedBy,
                reactivatedOn: x.ReactivatedOn == null ? '' : this.dateService.FormatDate(x.ReactivatedOn),
                revenueAndEBITDAComments: x.RevenueAndEBITDAComments == null ? '' : x.RevenueAndEBITDAComments,
                series24Signature: x.Series24Signature == null ? '' : x.Series24Signature,
                shareholderObjectivesComments: x.ShareholderObjectivesComments == null ? '' : x.ShareholderObjectivesComments,
                title: x.Title == null ? '' : x.Title,
                uSCounterpartiesContactComments: x.USCounterpartiesContactComments == null ? '' : x.USCounterpartiesContactComments
                // canceledOn: x.CanceledOn == null ? '' : this._formatDate(x.CanceledOn),
                // chareholderObjectivesComments: x.ChareholderObjectivesComments == null ? '' : x.ChareholderObjectivesComments,
                // canceledBy: x.CanceledBy == null ? '' : x.CanceledBy,
                // lastFormID: x.LastFormID == null ? '' : x.LastFormID
          };
        
          if(obj.iD > 0)
          crossBorderArrayObject.push(obj);
    });
    crossBorderArrayObject.sort((a, b) => b.iD - a.iD);
    return crossBorderArrayObject;
  }

  exportToAllExcel() {   
      this.exportAllExcel(this.createCrossBorderObjectExportAll(this.reportDashboardExportAll), 'CrossBorderReportAllReport');    
  }
  public exportAllExcel(jsonData: any[], fileName: string): void {
    jsonData = jsonData.map(doc => Object.values(doc));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    worksheet['A1'].v = "ID";
    worksheet['B1'].v = "Form Status";
    worksheet['C1'].v = "Status";
    worksheet['D1'].v = "Compliance Review Status";
    worksheet['E1'].v = "Country";
    worksheet['F1'].v = "Business Review Status";
    worksheet['G1'].v = "QRM Review Status";
    worksheet['H1'].v = "Industry";
    worksheet['I1'].v = "DCF Risk Rating";
    worksheet['J1'].v = "My Deal Series 24";
    worksheet['K1'].v = "Series 24";
    worksheet['L1'].v = "Series 24 Display Names";
    worksheet['M1'].v = "Series 24 Comments";
    worksheet['N1'].v = "Series 24 Date Approval";
    worksheet['O1'].v = "Created";
    worksheet['P1'].v = "DCF Date Approval";
    worksheet['Q1'].v = "URL";
    worksheet['R1'].v = "Created By";
    worksheet['S1'].v = "DealTeam Business Reviewer";
    worksheet['T1'].v = "DealTeam Business Reviewer Display Names";
    worksheet['U1'].v = "Project Name";
    worksheet['V1'].v = "My Deal Engagement Name";
    worksheet['W1'].v = "My Deal Client Name";
    worksheet['X1'].v = "Proposed Transaction Type";
    worksheet['Y1'].v = "International Member Firm Team";
    worksheet['Z1'].v = "International Member Firm Team Display Names";
    worksheet['AA1'].v = "DCF Engagement Team";
    worksheet['AB1'].v = "DCF Engagement Team Display Names";
    worksheet['AC1'].v = "Enterprise value (in USD $)";
    worksheet['AD1'].v = "Modified Date";
    worksheet['AE1'].v = "Modified By";
    worksheet['AF1'].v = "Cross Border Integration Workflow STG";
    worksheet['AG1'].v = "My Deal";
    worksheet['AH1'].v = "My Deal Project Name";
    worksheet['AI1'].v = "My Deal ID";
    worksheet['AJ1'].v = "Form Action Group";
    worksheet['AK1'].v = "Country Of Origin";
    worksheet['AL1'].v = "Is My Deal Form";
    worksheet['AM1'].v = "Engagement Team";
    worksheet['AN1'].v = "Schedule 3 Document Required";
    worksheet['AO1'].v = "Business Returned For Rework";
    worksheet['AP1'].v = "Client Name";
    worksheet['AQ1'].v = "Company Subsector Comments";
    worksheet['AR1'].v = "Compliance Returned For Rework";
    worksheet['AS1'].v = "Content Type";
    worksheet['AT1'].v = "Created Date";
    worksheet['AU1'].v = "DCF Comments";
    worksheet['AV1'].v = "Discontinued By";
    worksheet['AW1'].v = "Discontinued Form Action Group";
    worksheet['AX1'].v = "Discontinued Form Status";
    worksheet['AY1'].v = "Discontinued On";
    worksheet['AZ1'].v = "Discontinue Reason";
    worksheet['BA1'].v = "Expected Company Value Comments";
    worksheet['BB1'].v = "Form Registered";
    worksheet['BC1'].v = "Geograph Concentration Comments";
    worksheet['BD1'].v = "Income Revenue 1";
    worksheet['BE1'].v = "Income Revenue 2";
    worksheet['BF1'].v = "Income Revenue 3";
    worksheet['BG1'].v = "Income Revenue Percentage 1 (in %)";
    worksheet['BH1'].v = "Income Revenue Percentage 2 (in %)";
    worksheet['BI1'].v = "Income Revenue Percentage 3 (in %)";
    worksheet['BJ1'].v = "Income Year 1";
    worksheet['BK1'].v = "Income Year 2";
    worksheet['BL1'].v = "Income Year 3";
    worksheet['BM1'].v = "Industry Representative";
    worksheet['BN1'].v = "Industry Representative Comments";
    worksheet['BO1'].v = "Industry Representative Signature";
    worksheet['BP1'].v = "Initial Settings";
    worksheet['BQ1'].v = "Item Child Count";
    worksheet['BR1'].v = "Other Proposed Transaction Type";
    worksheet['BS1'].v = "Put On Hold By";
    worksheet['BT1'].v = "Put On Hold Form Action Group";
    worksheet['BU1'].v = "Put On Hold Form Status";
    worksheet['BV1'].v = "Put On Hold On";
    worksheet['BW1'].v = "Put On Hold Reactivated By";
    worksheet['BX1'].v = "Put On Hold Reactivated On";
    worksheet['BY1'].v = "Put On Hold Reason";
    worksheet['BZ1'].v = "QRM Compliance Notification Needed";
    worksheet['CA1'].v = "QRM Returned For Rework";
    worksheet['CB1'].v = "Reactivated By";
    worksheet['CC1'].v = "Reactivated On";
    worksheet['CD1'].v = "Revenue And EBITDA Comments";
    worksheet['CE1'].v = "Series 24 Signature";
    worksheet['CF1'].v = "Share holder Objectives Comments";
    worksheet['CG1'].v = "Title";
    worksheet['CH1'].v = "US Counter parties Contact Comments";
    // worksheet['CI1'].v = "Canceled On";
    // worksheet['CJ1'].v = "Chare holder Objectives Comments";
    // worksheet['CK1'].v = "Canceled By";
    // worksheet['CL1'].v = "Last Form ID";    
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, 'All_Result_export_' + new Date().getTime() + ".xlsx");
  }
  openCrossBorderForm(id: number) {
    // if(!this.CheckUserInExistTeams(id)){
    //   this.showMsgs('DashboardRedirect', 'Info');
    // }
    // else{
    this.openNewTab(AppSettings.crossBorder + '?item_id=' + id);
   // }
  }
  // //https://americas.internal.deloitteonline.com/sites/stgdcfforms/crossborderform/Pages/CrossBorderForm.aspx?item_id=193
  // getFormUrl(form: string): string {
  //     return `${AdminService.crossBorderBaseUrl}/Pages/${form}.aspx`;
  // }
  CheckUserInExistTeams(dashboardList:any){
    let roleLevelData:any=[];
    let profileId=localStorage.getItem("userProfileId")?.toString();
    let profileName=localStorage.getItem("userProfileName")?.toString();
    dashboardList.forEach((x:any)=>{
      let isExist=false;
      if(x.ID!=null){
      isExist=this.CompareInGroupTeams(x.InternationalMemberFirmTeam,x.InternationalMemberFirmTeamDisplayNames,isExist,profileId,profileName);
      if(!isExist)
      isExist=this.CompareInGroupTeams(x.DCFEngagementTeam,x.DCFEngagementTeamDisplayNames,isExist,profileId,profileName);
      if(!isExist)
      isExist=this.CompareInGroupTeams(x.DealTeamBusinessReviewer,x.DealTeamBusinessReviewerDisplayNames,isExist,profileId,profileName);
      if(!isExist)
      isExist=this.CompareInGroupTeams(x.Series24,x.Series24DisplayNames,isExist,profileId,profileName);
      }
      
    if(isExist){
      roleLevelData.push(x);
      }

    });
    return roleLevelData;
  }
  CompareInGroupTeams(xObjid:any,xObjNames:any,isExist:any,profileId:any,profileName:any){
    xObjid=xObjid==null?"-":xObjid;
    xObjNames=xObjNames==null?"-":xObjNames;
     if(xObjid.trim().toLowerCase().includes(profileId.trim().toLowerCase()))
     {
isExist=true;
     }
     else if(xObjNames.trim().includes(profileName.trim())){
isExist=true;
     }
    return isExist;
  }
  openNewTab(url: string) {
    window.open(url);
  }
  setReportDashboard(
    reportDashboardList: Array<ReportDashboardItem>,
    pageSizeListValues: Array<any>) {
    pageSizeListValues = [{ id: 0, value: "All" },{ id: 1, value: 10 }, { id: 2, value: 25 }, { id: 3, value: 50 }, { id: 4, value: 100 }];
    let filteredReportsList = this._filterClosedDeals(reportDashboardList);
    //this.initialReportDashboardList = reportDashboardList;
    this.commonHelperService.state.page = 1;
    this.commonHelperService.state.pageSize = 100;
    const sortData = this.commonHelperService.state.sortData;
    const filterData = this.commonHelperService.state.filterDataList;
    filteredReportsList = this._getEngagementsMatchsFilterCriteria(filteredReportsList, filterData);
    this.reportDashboards = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, reportDashboardList);
    this._updateSortArrow(sortData.sortColumn, sortData.sortDirection);
    this._setReportsDashboardToCurrentPage();
    this._setPaginationValues(pageSizeListValues);
    this.pageDropdownDisable = reportDashboardList.length > 0 ? false : true;
    this.spinnerService.hideAutoSaveSpinner();
  }
  private _getEngagementsMatchsFilterCriteria(
    ReportDashboard: Array<ReportDashboardItem>,
    filterList: Array<EngagementTableFilterItem>): Array<ReportDashboardItem> {
    let newReportDashboardList: Array<ReportDashboardItem> = [];
    if (filterList.length > 0) {
      ReportDashboard.forEach(eng => {
        if (this._isEngagementMatchFilterCriteria(eng, filterList)) {
          newReportDashboardList.push(eng);
        }
      });
    } else {
      newReportDashboardList = ReportDashboard;
    }
    return newReportDashboardList;
  }
  private _isEngagementMatchFilterCriteria(
    ReportDashboard: any,
    filterList: Array<EngagementTableFilterItem>): boolean {
    let match = true;
    filterList.forEach(filter => {
      const field = filter.field;
      const values = filter.valueList;
      if (values.length && !values.find((v: any) => v === ReportDashboard[field].toString())) {
        match = false;
        //return false;
      }
    });
    return match;
  }
  GetUsersListsInGroups() {
    let userId = localStorage.getItem("userProfileId")?.toString();
    this.adminService.GetUserRoleGroups(userId).subscribe(
      {
        next: (result: any) => {
          result = result.filter((s: any) => s.Title != null)
          this.isInAdminGroup(result);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
        }
      })
  }
  CheckAdminUser() {
    this.GetUsersListsInGroups();
    this.CallClosingCheckListTestMethod();
  }
  CallClosingCheckListTestMethod()
  {
    this.adminService.CallClosingCheckListTestMethod().subscribe(
      {
        next: (result: any) => {
          console.log(result);
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.commonHelperService.hideSpinner();
          console.log("Error" + err);
        }
      });
  }
 
  isInAdminGroup(userGroups: any) {
    let isAdmin_flag = false;
    userGroups.forEach((u: any) => {
      if (u.Role == "Cross Border Administrators") {
        isAdmin_flag = true;
      }
    });
    if (isAdmin_flag) { this.AdminRole= "Cross Border Administrators"; } else { this.AdminRole=""; }
    if (this.AdminRole == "Cross Border Administrators") {
       this.isAdminUser = true;
      // this.getS24List(); 
      // const el = document.getElementById('onetrust-consent-sdk');
      // if (el != null) 
      // {
      //       el.style.display = 'none';
      // }
       this.getIndustryRepresentativeGroups()
      //  if (el != null) 
      // {
      //       el.style.display = 'none';
      // }
      // setTimeout(() => {
       
        
  //  }, 5000);  
  } 
   else { 
     this.isAdminUser = true;
      // this.getS24List(); 
       this.getIndustryRepresentativeGroups()
       //setTimeout(() => {
       // this.getDashboardDetails("ALL");
        
    //}, 5000);  

   // this.adminService.hideSpinner();
   // this.showMsgs('Dashboard', 'Info');
   }
  }
  createNewDeal(){
    document.location.href = environment.redirectUrl+"newdeal";
  }
  showMsgs(msg: any, msgType: any) {
    this.notifypopupComponent.openPopup(msg, msgType)
  }
  addNotifyDetails(event:any){
    if(event.MessageHeader=="Dashboard"){
      document.location.href = environment.redirectUrl+"home";
    }
    
  }
  private _filterClosedDeals(reportsList: Array<ReportDashboardItem>): Array<ReportDashboardItem> {
    let filteredreportsList;
    filteredreportsList = reportsList;

    return filteredreportsList;
  }
  private _updateSortArrow(sortColumn: string, sortDirection: SortDirection) {
    //this._resetSortIcons(sortColumn);
    //this._showSortDirectionArrow(sortColumn, sortDirection);
  }
  private _setPaginationValues(values: any) {
    this.pageSizeListValues = values.map((element: any) => {
      const parseValue = element.value.toString();
      const dropdownItem: DropdownItem = {
        key:'',
        value:''
      };
      if(parseValue=="All"){
         dropdownItem.key=parseValue,
         dropdownItem.value=parseValue+' Items'
      
      }
      else{
        dropdownItem.key=parseValue,
        dropdownItem.value=parseValue+' items per page'
    }
      return dropdownItem;
    });
    this.selectedPageSize = this.selectedPageSize == undefined ? CommonHelperService.pageSizeDefault : this.selectedPageSize;
    // this.pageSizeListValues.forEach((v: any) => {
    //  if (v.key == 100)
    //   this.selectedPageSize = v.key;
    // });
    // if (this.pagination) {
    //   this.pagination.setPaginationValues(this.pageSizeListValues);
    // }
  }
  sortTable({ column }: SortEvent) {
    this.reportDashboardPageDetails.length = 0;
    const newDirection = this._getSortDirection(column);
    this._resetSortIcons(column);
    this.reportDashboards = this.commonHelperService.sort(column, newDirection, this.reportDashboards);
    this._setReportsDashboardToCurrentPage();
    this._setSortOptions(column, newDirection);
    this.showDisable = true;
    this.reportDashboardExportToExcel.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement;
    checkbox.checked = false;
    this.reportDashboards.forEach(v => {
      if (v.isSelected == true)
        v.isSelected = false;
    });
  }
  private _getSortDirection(column: string): SortDirection {
    const header = this._getHeader(column);
    return header.direction === 'asc' ? 'desc' : 'asc';
  }
  isFilterApply(field: string) {
    let filterApply = false;
    this.filterList.forEach(filter => {
      if (filter.field === field && filter.valueList.length) {
        filterApply = true;
      }
    });
    return filterApply;
  }
  clearFilters() {
    if (this.targetFilter) {
      this.targetFilter.valueList = [];
      this._updateReportListBasedOnSearch();
      this.showDisable = true;
      this.reportDashboardExportToExcel.length = 0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement;
      checkbox.checked = false;
      this.reportDashboards.forEach(v => {
        if (v.isSelected == true)
          v.isSelected = false;
      });
      this.reportDashboardPageDetails.length = 0
    }
  }
  clearAllFilters(FromSearchForm: any) {
    this._resetSortIcons();
    this.filterList = [];
    this.targetField = "";
    this.reportDashboardExportToExcel.length = 0;
    this.reportDashboardPageDetails.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement;
    checkbox.checked = false;
    this.showDisable = true;
    this.reportDashboards.forEach(v => {
      v.isSelected = false;
    });
    if (FromSearchForm == true) {
      this.commonHelperService.searchTerm = '';
    }
    this.commonHelperService.state.sortData = { sortColumn: 'created', sortDirection: 'desc' };

    this.commonHelperService.filterDataList = [];
    this.commonHelperService.state.page = 1;
    this.commonHelperService.state.pageSize = CommonHelperService.pageSizeDefault;
    this.selectedPageSize = CommonHelperService.pageSizeDefault;
    // this.showDealClosedState.emit(false);
    // this.currentEngagementList = this._filterClosedDeals(false, this.initialEngagementsList);
    this.reportDashboards = this._filterClosedDeals(this.initialReportDashboardList);
    this._updateSortArrow(this.commonHelperService.state.sortData.sortColumn, this.commonHelperService.state.sortData.sortDirection);
    this._setReportsDashboardToCurrentPage();
    this.onSearchApplyOnFilteredData = [];
   
  }
  private _updateReportListBasedOnSearch() {
      // Assign the current search term to SearchingValue
      this.SearchingValue = this.searchTerm;
  
      // Check if SearchingValue is neither "ALL", undefined, nor an empty string
      if (this.SearchingValue.trim() !== "ALL" && this.SearchingValue.trim() !== undefined && this.SearchingValue.trim() !== "") {
          // Filter the report list based on SearchingResults
          const filteredEngagementList = this.SearchingResults;
          // Apply additional filters if any, otherwise use the filtered report list
          this.reportDashboards = this._isSomeFilterApply() ?
              this._getReportsMatchsFilterCriteria(filteredEngagementList, this.filterList) : filteredEngagementList;
      } else {
          // Handle the case where SearchingValue is "ALL", undefined, or an empty string
          const filteredEngagementList = this.initialReportDashboardList;
          // Apply additional filters if any, otherwise use the initial report list
          this.reportDashboards = this._isSomeFilterApply() ?
              this._getReportsMatchsFilterCriteria(filteredEngagementList, this.filterList) : filteredEngagementList;
      }
  
      // Sort the current report list based on the sort data
      const sortData = this.commonHelperService.state.sortData;
      this.reportDashboards = this.commonHelperService.sort(
          sortData.sortColumn, sortData.sortDirection, this.reportDashboards);
  
      // Set the reports to the current page
      this._setReportsDashboardToCurrentPage();
  }
  private _updateReportList() {
    this.reportDashboards = this._isSomeFilterApply() ?
      this._getReportsMatchsFilterCriteria(this.initialReportDashboardList, this.filterList) :
      this.initialReportDashboardList;

    const sortData = this.commonHelperService.state.sortData;
    this.reportDashboards = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, this.reportDashboards);
    this._setReportsDashboardToCurrentPage();
  }
  openFilterPopover(field: string) {
    this.targetField = field;
    this.targetFilter = this._getFilter(field);
    this.fieldOptionValues = this._mapReportDashboardListWithFilterList(this.initialReportDashboardList, field);
  }
  private _getFilter(field: string): any {
    return this.filterList.length ? this.filterList.filter(f => f.field === field)[0] : null;
  }
  private _mapReportDashboardListWithFilterList(
    listReports: Array<ReportDashboardItem>,
    field: string): Array<string> {
    const arrayOptionsList: any = [];
    listReports.forEach((report: any) => {
      const value = report[field];
      if (this._isValidValue(arrayOptionsList, value, field, listReports)) {
        arrayOptionsList.push(report[field]);
      }
    });
    if(field == "iD")
      return arrayOptionsList;

      return arrayOptionsList.sort((a : string, b : string) => a.toUpperCase().localeCompare(b.toUpperCase()));
  }
  private _isValidValue(
    arrayOptionsList: Array<string>,
    value: string,
    field: string,
    listreports: Array<ReportDashboardItem>): boolean {
    return (value !== '' && value !== null &&
      !this._inArray(arrayOptionsList, value) &&
      this._existReportForValue(listreports, field, value));
  }
  private _inArray(optionFields: Array<string>, option: string): boolean {
    return Boolean(optionFields.find(o => o === option));
  }
  private _existReportForValue(
    listReports: Array<ReportDashboardItem>,
    field: string,
    value: string): boolean {
    const filterArrayClone = [...this.filterList];
    const filterRemovedCurrentTarget = filterArrayClone.filter(f => f.field !== field);
    const filteredValues = this._getReportsMatchsFilterCriteria(listReports, filterRemovedCurrentTarget);
    const searchValue = filteredValues.find((v: any) => v[field] === value) ? true : false;
    return searchValue;
  }
  private _getReportsMatchsFilterCriteria(
    reports: Array<ReportDashboardItem>,
    filterList: Array<ReportDashboardTableFilterItem>): Array<ReportDashboardItem> {
    let newReportList: Array<ReportDashboardItem> = [];
    if (filterList.length > 0) {
      reports.forEach(rep => {
        if (this._isReportMatchFilterCriteria(rep, filterList)) {
          newReportList.push(rep);

        }
      });
    } else {
      newReportList = reports;
    }
    return newReportList;
  }
  private _isReportMatchFilterCriteria(
    report: any,
    filterList: Array<ReportDashboardTableFilterItem>): boolean {
    let match = true;
    try {
      filterList.forEach(filter => {
        const field = filter.field;
        const values = filter.valueList;
        if (values.length && !values.find(v => v === report[field].toString())) {
          match = false;
          //return false;
        }
      });
      return match;
    }
    catch (e) {
      match = false;
    }
    return match;
  }
  onChangePaginationValues(paginationValues: PaginationValues) {
    this.commonHelperService.state.page = paginationValues.page;
    this.commonHelperService.state.pageSize = paginationValues.pageSize;
    this._setReportsDashboardToCurrentPage();
    this.setMultiSelectedCheckBoxPages(paginationValues.page, paginationValues.pageSize);
    //Suvishna Commented
    //this.setMultiSelectedCheckBoxPages(paginationValues.page,paginationValues.pageSize);
    // When selected the multiple selected text box,
    //  only the forms specific to the particular page must be selected,
    //   even if the items per page increased, 
    //   it should select only the forms in that relevant page.
    //    If the user navigates to the other page , 
    //    no forms should have to be selected until that page multi select is clicked .
    //     Until the relevant page is not deselected , 
    //     it should remain selected only.
    //      The following selected files should be able to exported in the excel sheet

  }
  private setMultiSelectedCheckBoxPages(pageNumber: any, pageSize: any) {

    if (this.reportDashboardPageDetails.length > 0) {
      if (this.reportDashboardPageDetails.find(s => s.PageSize != pageSize && s.IsMultiSelect == true)) {
        this.reportDashboardExportToExcel.length = 0;
      }
      this.reportDashboardPageDetails.forEach(s => {
        if (s.IsMultiSelect == true && s.PageSize != pageSize) {
          s.PageSize = pageSize
          const page = s.PageNumber;
          const pageSize1 = s.PageSize;
          this.reportDashboardsPerPage =
            this.reportDashboards.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
          this.reportDashboardsPerPage.forEach(v => {
            if (!this.reportDashboardExportToExcel.find(e => e.ID == v.iD)) {
              let reportDashboardItem = {
                ID: v.iD,
                ProjectName: v.projectName,
                //ClientName: v.clientName,
                Status: v.status,
                Country: v.country,
                Industry: v.industry,
                //  DCFRiskRating:v.dCFRiskRating,
                // EnterpriseValue:v.enterpriseValue,
                ProposedTransactionType: v.proposedTransactionType,
                NameOfS24: v.nameOfS24,
                //  S24ApprovalStatus:v.s24ApprovalStatus,
                Created: v.created,
                // DCFDateApproval:v.dCFDateApproval,
                Modified: v.modified,
                BusinessReviewStatus: v.businessReviewStatus,
                QRMReviewStatus: v.QRMReviewStatus,
                ComplianceReviewStatus: v.complianceReviewStatus,
                InternationalMemberFirmTeam: v.internationalMemberFirmTeam,
                DCFEngagementTeam: v.DCFEngagementTeam,
                IndustryRepresentative: v.industryRepresentative,
                FormActionGroup: v.formActionGroup,
                FormStatus: v.formStatus,
                DiscontinuedOn: v.discontinuedOn,
                DiscontinueReason: v.discontinueReason,
                EnterpriseValue: v.enterpriseValue,
                Series24Comments: v.series24Comments,
                Series24DateApproval: v.series24DateApproval,
                Series24Signature: v.series24Signature,
                CreatedBy: v.createdBy,
                DealTeamBusinessReviewer: v.dealTeamBusinessReviewer,
                DCFDateApproval: v.DCFDateApproval,
                IncomeYear1: v.incomeYear1,
                IncomeRevenuePercentage1: v.incomeRevenuePercentage1,
                IncomeYear2: v.incomeYear2,
                IncomeRevenuePercentage2: v.incomeRevenuePercentage2,
                IncomeYear3: v.incomeYear3,
                IncomeRevenuePercentage3: v.incomeRevenuePercentage3,
                ModifiedBy: v.modifiedBy
              };
              this.reportDashboardExportToExcel.push(reportDashboardItem);
            }
          });
          this.reportDashboards.forEach(v => {
            if (this.reportDashboardExportToExcel.find(e => e.ID === v.iD)) {
              v.isSelected = true;
            }
            else {
              v.isSelected = false;
            }
          });
        }

      });
      this._setReportsDashboardToCurrentPage();
    }
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement;
    checkbox.checked = false;
    if (this.reportDashboards.find(e => e.isSelected == true)) {
      this.reportDashboardPageDetails.forEach(v => {
        if (v.IsMultiSelect == true && v.PageNumber == pageNumber) {
          checkbox.checked = true;
        }
      });
    }

  }
  private _setReportsDashboardToCurrentPage() {
    const page = this.commonHelperService.state.page;
    const pageSize = this.commonHelperService.state.pageSize;
    this.reportDashboardsPerPage =
      this.reportDashboards.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
     
  }
  sortTableOnFilterModal(column: string, direction: SortDirection) {
    this._resetSortIcons(column);
    this._showSortDirectionArrow(column, direction);
    this._setReportsDashboardToCurrentPage();
    this.reportDashboards = this.commonHelperService.sort(column, direction, this.reportDashboards);
    this._setSortOptions(column, direction);
  }
  private _setSortOptions(column: string, direction: SortDirection) {
    const sortItem: ReportDashboardSort = {
      sortColumn: column,
      sortDirection: direction
    };
    this.commonHelperService.sortData = sortItem;
  }
  private _resetSortIcons(column?: string) {
    this.headers.forEach(header => {
      if ((column && header.sortable !== column) || !column) {
        header.direction = '';
      }
    });
  }
  private _showSortDirectionArrow(sortColumn: string, sortDirection: SortDirection) {
    const header = this._getHeader(sortColumn);
    header.direction = sortDirection;
    header.rotate();
  }
  isChecked(value: string): boolean {
    let checked = false;
    if (this.targetFilter) {
      this.targetFilter.valueList.forEach(v => {
        if (v === value.toString()) {
          checked = true;
        }
      });
    }
    return checked;
  }
  private _getHeader(column: string): NgbdSortableHeaderDirective {
    return this.headers.filter(h => h.sortable === column)[0];
  }
  onClickFilterCheckbox(event: any) {
    const checkbox = event.currentTarget;
    const valueSelected = checkbox.id;
    const isChecked = checkbox.checked;

    this._updateFilterList(valueSelected, isChecked);
    this._updateReportDashboardListBasedOnSearch();
  }
  private _updateReportDashboardList() {
    this.onSearchApplyOnFilteredData=[];
    this.reportDashboards = this._isSomeFilterApply() ?
      this._getReportsMatchsFilterCriteria(this.initialReportDashboardList, this.filterList) :
      this.initialReportDashboardListForSearch;

    const sortData = this.commonHelperService.state.sortData;
    this.reportDashboards = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, this.reportDashboards);
    this._setReportsDashboardToCurrentPage();
    if(this.initialReportDashboardListForSearch.length!=this.reportDashboards.length)
    this.onSearchApplyOnFilteredData=this.reportDashboardsPerPage;
  }
  private _updateReportDashboardListBasedOnSearch() {
      // Assign the current search term to SearchingValue
      this.SearchingValue = this.searchTerm;
  
      // Check if SearchingValue is neither "ALL", undefined, nor an empty string
      if (this.SearchingValue.trim() !== "ALL" && this.SearchingValue.trim() !== undefined && this.SearchingValue.trim() !== "") {
          // Filter the report list based on SearchingResults
          const filteredEngagementList = this.SearchingResults;
          // Apply additional filters if any, otherwise use the filtered report list
          this.reportDashboards = this._isSomeFilterApply() ?
              this._getReportsMatchsFilterCriteria(filteredEngagementList, this.filterList) : filteredEngagementList;
      } else {
          // Handle the case where SearchingValue is "ALL", undefined, or an empty string
          const filteredEngagementList = this.initialReportDashboardList;
          // Apply additional filters if any, otherwise use the initial report list
          this.reportDashboards = this._isSomeFilterApply() ?
              this._getReportsMatchsFilterCriteria(filteredEngagementList, this.filterList) : filteredEngagementList;
      }
  
      // Sort the current report list based on the sort data
      const sortData = this.commonHelperService.state.sortData;
      this.reportDashboards = this.commonHelperService.sort(
          sortData.sortColumn, sortData.sortDirection, this.reportDashboards);
  
      // Set the reports to the current page
      this._setReportsDashboardToCurrentPage();
  
      // Check if the length of the filtered list is different from the initial list
      // If so, update the onSearchApplyOnFilteredData property
      if (this.initialReportDashboardListForSearch.length != this.reportDashboards.length) {
          this.onSearchApplyOnFilteredData = this.reportDashboardsPerPage;
      }
  }
  private _isSomeFilterApply() {
    this.isColumnfilterApply = false;
    this.filterList.forEach(filter => {
      if (filter.valueList.length) {
        this.isColumnfilterApply = true;
      }
      // else
      // {
      //   this.isColumnfilterApply = false;
      // }
    });
    return this.isColumnfilterApply;
  }

  private _updateFilterList(valueSelected: string, isChecked: string) {
    const existFilter = this._getFilter(this.targetField);
    if (isChecked) {
      this._addFilterItem(existFilter, valueSelected);
    } else {
      this._removeFilterItem(existFilter, valueSelected);
    }
    this.showDisable = true;
    this.reportDashboardExportToExcel.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement;
    checkbox.checked = false;
    this.reportDashboards.forEach(v => {
      if (v.isSelected == true)
        v.isSelected = false;
    });
    this.reportDashboardPageDetails.length = 0
    this._setTargetFilter(existFilter);
  }

  private _setTargetFilter(existFilter: ReportDashboardTableFilterItem) {
    this.targetFilter = existFilter;
    this.commonHelperService.filterDataList = this.filterList;

  }
  private _addFilterItem(existFilter: ReportDashboardTableFilterItem, valueSelected: string) {
    if (existFilter) {
      existFilter.valueList.push(valueSelected);
    } else {
      const newFilter = this._createFilter(valueSelected);
      this.filterList.push(newFilter);
      existFilter = newFilter;
    }
  }

  private _removeFilterItem(existFilter: ReportDashboardTableFilterItem, valueSelected: string) {
    if (existFilter) {
      existFilter.valueList = this._removeFilter(existFilter.valueList, valueSelected);
    }
  }
  private _removeFilter(valueList: Array<string>, valueSelected: string): Array<string> {
    return valueList.filter(v => v !== valueSelected);
  }
  private _createFilter(valueSelected: string): ReportDashboardTableFilterItem {
    const newFilter = {
      field: this.targetField,
      valueList: [valueSelected]
    };
    return newFilter;
  }

}
