<span id="DeltaDelegateControls" class="container-loader"> 
</span>
<!-- New Deal AutoSave Spinner Changes -->
<div id="loadingAuto" style="display: none;">
    <div class="loader"></div>
  </div>
<div class='content' style="position: static;">
    <app-header></app-header>
    <div class="row">                 
     <router-outlet></router-outlet>              
    </div>
</div>
<app-footer class="footer"></app-footer>


<!-- Spinner Logic -->
<div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
        <span id="loading" style="display: none;"></span>
    </div>
</div>
