import { HttpClient } from '@angular/common/http'
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from 'src/shared/app-settings';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { MainService } from 'src/shared/services/main.service';
import { Spcrud } from 'src/shared/services/spcrud';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { ReportDashboardItem } from '../models/reportDashboardItem';
import { ReportDashboardService } from '../shared/services/report-dashboard.service';
import { ClosingChecklistTableComponent } from './closing-checklist-table/closing-checklist-table.component';
import { forkJoin } from 'rxjs';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';
const modalTypes  = {
 
  noDCFMember: 'no-dcf-member',
 
};
@Component({
  selector: 'app-closing-checklist-dashboard',
  templateUrl: './closing-checklist-dashboard.component.html',
  styleUrls: ['./closing-checklist-dashboard.component.scss']
})
export class ClosingChecklistDashboardBoardComponent implements OnInit {
  @ViewChild('Modal', {static: true})
  modal: ModalComponent;
  @ViewChild(NotifyPopupComponent, { static: true }) notifypopupComponent: NotifyPopupComponent;
  @ViewChild(ClosingChecklistTableComponent, {static: true})
  ClosingChecklistTableComponent: ClosingChecklistTableComponent;
  reportDashboardItemList: Array<ReportDashboardItem> = [];
  showClosedDeals = false;
  newDealUrl: string;
  adGroupMember:any;
  page:any;
  modalType: string;
  paginationValues: Array<number>;
  constructor(private spinnerService: SpinnerService,
    private reportDashboardService: ReportDashboardService,
      private httpClient: HttpClient,
      private mainService: MainService, private commonHelperService: CommonHelperService,private globalHelperService:GlobalHelperService,
    private spcrud: Spcrud) { }

  ngOnInit(): void {
  
      // this.getReportDashboard()
    localStorage.setItem("CurrentAccessPage", "ClosingChecklistDashboard");
    this.globalHelperService.currentAccessPage="ClosingChecklistDashboard";
    this.spinnerService.hide();
    this.spinnerService.showAutoSaveSpinner();
    this.removeBrowserScroll();
      this.getReportDashboard_new();
  }
  private _openModal(modalType: string) {
    this.modalType = modalType;
    this.modal.open();
  }

  removeBrowserScroll(){
    document.body.style.overflow="hidden";
  }

  showExportButton() {
    return this.ClosingChecklistTableComponent?.showDisable;
  }

  getReportDashboard_new(){
    forkJoin([this.mainService.getCurrentUserData_Observable(),this.reportDashboardService.retrieveReportDashboard_CCL()]).subscribe(([currentUser,responseReportData]) => {
      responseReportData = responseReportData.filter(function( element:any ) {
        return element !== undefined && element !== null;
     });
     this.mainService.currentUser = currentUser;
        this.reportDashboardItemList=responseReportData;
        if (this._isDCFAdmin(this.mainService.currentUser.groups))
        {
          this.spinnerService.hideAutoSaveSpinner();
          this.ClosingChecklistTableComponent.initialReportDashboardList = this.reportDashboardItemList;
          this.ClosingChecklistTableComponent.setReportDashboard(
            this.reportDashboardItemList, this.paginationValues);
        }
        else {
          this.spinnerService.hideAutoSaveSpinner();
              this.commonHelperService.showFade();
          // this._openModal(modalTypes.noDCFMember);
          this.notifypopupComponent.openPopup("ClosingChecklistDashboard",modalTypes.noDCFMember);

      }
    });
  
  }

  addNotifyDetails(event:any){
    if(event!=null){
      document.body.style.overflow="auto";
      window.location.href=AppSettings.DashboardURL;
    }
    }
  getReportDashboard() {
    Promise.all([
      this.mainService.getCurrentUserData(),
      this.reportDashboardService.retrieveReportDashboard(),
      this.reportDashboardService.getPaginationValues()]).
      then(([currentUser, responseReportData, responsePaginationValues]) => {
        responseReportData = responseReportData.filter(function (element: any) {
          return element !== undefined;
        });
        this.mainService.currentUser = currentUser;
        this.reportDashboardItemList = responseReportData;
        this.paginationValues = responsePaginationValues;
        if (this._isDCFAdmin(this.mainService.currentUser.groups)) {
          this.spinnerService.hideAutoSaveSpinner();
          this.ClosingChecklistTableComponent.setReportDashboard(
            this.reportDashboardItemList, this.paginationValues);
        }
        else {
          this.spinnerService.showAutoSaveSpinner();
          this._openModal(modalTypes.noDCFMember);
        }

      });
  }
  private _isDCFAdmin(groups: Array<string>): boolean {
    const mydealAdminGroup = AppSettings.myDealAdminsGroup;
    const closingCheckListAdminGroup = AppSettings.closingchecklistGroup;
    return !!groups.find((group:any) => group === mydealAdminGroup || group ===closingCheckListAdminGroup);
  }
  clearFilters() {
    this.ClosingChecklistTableComponent.clearAllFilters(true);    
  }
  onSearchResult(result: ReportDashboardItem[]) {
  //   this.ClosingChecklistTableComponent.clearAllFilters(false);

  //   this.reportDashboardItemList = result.filter(function( element ) {
  //     return element !== undefined;
  //  });
  //   this.ClosingChecklistTableComponent.setReportDashboard(
  //     this.reportDashboardItemList, this.paginationValues);
  this.ClosingChecklistTableComponent.onSearchResult(result,this.reportDashboardItemList);
  }
  exportToExcel()
  {
    this.ClosingChecklistTableComponent.exportToExcel();
  }
}
