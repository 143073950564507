<div [formGroup]="projectOverviewFormGroup" class="container-fluid border container-border-color container-section" id="project-overview">
  <div class="row bg-title">
    <span class="title-section">Project Overview</span>
</div>
<div id="project-overview-section" class="content-section">
    <div class="row">
        <div class="col-xs-4 section-label">
          <span [class.highlight-warning]="highlightRequiredField('engagementName')"
            [class.edited-externally]="editedExternally('engagementName')"
            [class.conflict-on-merge]="hasMergeConflict('engagementName')">Project Name</span>
          <span *ngIf="showAsterisk('engagementName')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
          <input
            [formControl]="myDealFormGroup.get('engagementName')"
            class="input-data-text full-width section-input" [class.readonly]="isReadOnly('engagementName')"
            type="text"
            autocomplete="off"
            maxlength="255">
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
          <span [class.highlight-warning]="highlightRequiredField('dealteamTable')"
            [class.edited-externally]="editedExternally('dealteamTable')"
            [class.conflict-on-merge]="hasMergeConflict('dealteamTable')">Deal Team</span>
          <span *ngIf="showAsterisk('dealteamTable')" class="red-asterik"></span>
          <!-- <app-popover
            [tooltipText]="getTooltipText('DealTeamTable')">
          </app-popover> -->
        </div>
        <div class="col-xs-8">
          <app-deal-team-table #dealTeam
            [readonly]="readOnly"
            [editable]="true"
            [allowToDuplicateUser]="allowToDuplicateUser"
            [dealTeamTableFormGroup]="projectOverviewFormGroup.get('dealteamTable')"
            [dealTeamValues]="dealTeamValues"
            [secondColumnVisible]="false"
            [thirdColumnVisible]="false"
            (dealTeamRowToStore)="onDealTeamRowSelection($event)">
          </app-deal-team-table>
        </div>
      </div>
    <div class="row">
      <div  style="float:right" *ngIf="showInitiate">
        <button 
        type="submit"
        (click)="onInitiate()"
        class="btn btn-primary submit-btn">
        Initiate
      </button>
    </div>
      </div> 
</div>
</div>