import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormValidations } from 'src/shared/utils/form-validations';
import { FormListFields } from '../models/form-list-fields';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { AppSettings } from 'src/shared/app-settings';
import { DropdownItem } from 'src/shared/models/dropdown-item';

@Component({
  selector: 'app-invoice-details-and-addresses',
  templateUrl: './invoice-details-and-addresses.component.html',
  styleUrls: ['./invoice-details-and-addresses.component.scss']
})
export class InvoiceDetailsAndAddressesComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();
  invoiceMediumOptions: Array<DropdownItem> = [];
  invoiceLayoutOptions: Array<DropdownItem> = [];
  paymentTermsOptions: Array<DropdownItem> = [];
  fxRateOptions: Array<DropdownItem> = [];

  @Input() formGroups: any;
  @Input() formSubmitted: boolean;

  invoiceDetailsFormGroup: FormGroup;
  myDealFormGroup: any;

  constructor(private tooltipHelperService: TooltipHelperService,
              private dropdownService: DropdownService) { }

  ngOnInit() {
    this.invoiceDetailsFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
    this.loadDropDown();
    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.invoiceMedium)
    // .then(response => {
    //   this.invoiceMediumOptions = response;
    // }).catch(error => {
    // });

    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.invoiceLayout)
    // .then(response => {
    //   this.invoiceLayoutOptions = response;
    // }).catch(error => {
    // });

    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.paymentTerms)
    // .then(response => {
    //   this.paymentTermsOptions = response;
    // }).catch(error => {
    // });

    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.fxRate)
    // .then(response => {
    //   this.fxRateOptions = response;
    // }).catch(error => {
    // });
  }
  loadDropDown(){
   this.invoiceMediumOptions=AppSettings.invoiceMedium;
   this.invoiceLayoutOptions=AppSettings.invoiceLayout;
   this.paymentTermsOptions=AppSettings.paymentTerms;
   this.fxRateOptions=AppSettings.fxRate;
    }
  onFxRateSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.fxRate.setValue(event.value);
  }

  onPaymentTermsSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.paymentTerms.setValue(event.value);
  }

  onInvoiceLayoutSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.invoiceLayout.setValue(event.value);
  }

  onInvoiceMediumSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.invoiceMedium.setValue(event.value);
  }

  onFxRiskSharingSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.fxRiskSharing.setValue(event);
  }

  onPrintEngagementNumberSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.printEngagementNumber.setValue(event);
  }

  onAllowInvoiceConsolidationSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.allowInvoiceConsolidation.setValue(event);
  }

  onPrintClientReferenceNumberSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.printClientReferenceNumber.setValue(event);
  }

  onPrintSecondBillingAddressSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.printSecondBillingAddress.setValue(event);
  }

  onIsSubcontractingArrangementSelection(event:any) {
    this.invoiceDetailsFormGroup.controls.isSubcontractingArrangement.setValue(event);
  }

  onBillingAddressSameClientAddressSelection(event:any) {
    this.myDealFormGroup.controls.billingAddressSameClientAddress.setValue(event);
    this.evaluateBillingAddressDependency(event);
  }

  onPayerAddressSameClientAddressSelection(event:any) {
    this.myDealFormGroup.controls.payerAddressSameClientAddress.setValue(event);
    this.evaluatePayerAddressDependency(event);
  }

  evaluateBillingAddressDependency(value:any) {
    if (value === 'NO') {
      this.myDealFormGroup.controls.billingAddress.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.billingAddress));
    }
  }

  evaluatePayerAddressDependency(value:any) {
    if (value === 'NO') {
      this.myDealFormGroup.controls.payerAddress.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.payerAddress));
    }
  }


  getControlName(formControlName: string):any {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return formGroup.get(formControlName);
    }
    return null;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameDealWbs(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _setAsDisabled(formControlName: string) {
    
    const controlName = this.myDealFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
