import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms"; 
import { ActivatedRoute } from "@angular/router"; 
import { NAFormatterService } from "../shared/utils/utils-handlingNA";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { DropdownItem } from "../../../shared/models/dropdown-item";
import { TooltipHelperService } from "../../../shared/services/tooltip-helper.service";
import { DropdownService } from "../../../shared/services/dropwdown.service";
import { ComplianceService } from "../shared/services/compliance-service";
import { AppSettings } from "../../../shared/app-settings";
import { FormListFields } from "../shared/models/form-list-fields";
import { FormValidations } from "../../../shared/utils/form-validations";
import { TooltipObject } from "../../../shared/models/tooltip-object";
import { MainService } from "src/shared/services/main.service";
@Component({
    selector: 'app-dcf-fee-structure',
    templateUrl: './dcf-fee-structure.component.html',
    styleUrls: ['./dcf-fee-structure.component.scss']
  })
export class DCFFeeStructureComponent implements OnInit {
    dcfFeeStrcutureFormGroup: any;
    @Input() formGroups: Array<FormGroup>;
    @Input() formSubmitted: boolean;
    @Input() readOnly:boolean;
    @Output() breakPointMultiValue = new EventEmitter<boolean>();
    showBreakPoint:boolean;
  showBreakPointtable: boolean;
  feeTypeOptions: Array<DropdownItem> = AppSettings.FeeTypeListArray;
    sharePointMapper = new ComplianceSharepointMapper();
    breakPointValue: Array<any> = [];
    Percentage:boolean;
    Numerical:boolean;
    breakpointChange:boolean;
    formId:number;
    multiBreakPointValidation:boolean;
    showMultiAsterisk:boolean=false;
    showhighlight:boolean=false;
    valuationMetrics:FormGroup;
    constructor(private tooltipHelperService: TooltipHelperService,private dropdownService:DropdownService,private ComplianceService: ComplianceService,private route: ActivatedRoute,private mainservice :MainService
      ,private naFormatService:NAFormatterService) { }
    ngOnInit() {
      this.formId=Number(this.route.snapshot.queryParams.id);
      this.showBreakPoint=true;
        this.dcfFeeStrcutureFormGroup = this.formGroups[0];
        this.valuationMetrics=this.formGroups[1];
          this.dcfFeeStrcutureFormGroup.controls.breakpointValue.enable();
    //   this.dropdownService.fillDropdownOptions_ClosingCheckList(AppSettings.myDeal, FormListFields.feeType)
    // .subscribe(response => {
    //   this.feeTypeOptions = response;
    // })
    // this.ComplianceService.getBreakPointValuesCCL(AppSettings.closingCheckListFeeStructureBreakpoints,Number(this.route.snapshot.queryParams.id))
    this.getClosingCheckListFeeStructureBreakpoints(this.formId);
    this.evaluateRetainerReceived(this.dcfFeeStrcutureFormGroup.controls.minimumFeeApply.value);
    }
    setFormGroup(formGroup: FormGroup) {
      this.valuationMetrics = formGroup;
      this.updateFeeStructureFormulas(false);
    }

  getClosingCheckListFeeStructureBreakpoints(formId: any)
    {
      this.ComplianceService.getBreakPointValuesCCL(AppSettings.closingCheckListFeeStructureBreakpoints,formId)
      .subscribe(response => {
        this.breakPointValue.length=0;
        response.forEach((user:any) => {
        user.BreakpointValue=user.BreakpointValue;
        user.Id=user.Id;
        user.ID=user.ID;
        user.FormId=user.FormId;
        this.breakPointValue.push(user);
      });
      if(this.breakPointValue.length>0)
      {
      this._setAsDisabled('breakpointValue');
        this.showBreakPoint=false;
        this.enableOneRadiobutton();
      
        this.showBreakPointtable=true;
    } 
      })
    }

    setSharepointMapper(mapper: ComplianceSharepointMapper) {
      this.sharePointMapper = mapper;
    }
  onMinimumSucessFeeSelection(event: any, columnName: any) {
      this.dcfFeeStrcutureFormGroup.controls[columnName].setValue(event);
      this.evaluateRetainerReceived(event);
    }
    adRow()
    {
      if(!this.readOnly){
      const objectToSend: object = {
        BreakpointValue:'',
        Id:0
        };
        this.breakPointValue.push(objectToSend);
        this.checkMultiBreakPointValueStatus();
      }
    }
  onSelectedBreakPointValue(item: any, row: any)
    {
     if(this.breakPointValue[row].Id==0 &&item!="" && this.breakPointValue[row].BreakpointValue!="")
     {
      const objectToSend: object = {
        BreakpointValue:item,
        FormId:this.formId.toString()
        };
      this.ComplianceService.createListItem_CCF(AppSettings.closingCheckListFeeStructureBreakpoints,objectToSend)
      .subscribe(response => {
        this.breakPointValue[row] = response;
        // this.breakPointValue[row].Id = response;
        //this.breakPointValue[row].BreakpointValue = item;
        this.showhighlight=false;
      });
    }
    else if(this.breakPointValue[row].Id!=0&&item!=""){
      const objectToSend: object = {
        BreakpointValue: item
      };
        this.ComplianceService.updateListItem_Breakpoint(this.breakPointValue[row].ID,AppSettings.closingCheckListFeeStructureBreakpoints,this.formId,objectToSend,this.breakPointValue[row].Created)
        .subscribe({
          next: (response) => {
            this.showhighlight = false;
          },
          error: (error) => {
            console.error('Error updating list item:', error);
          }
        });

    }
    this.breakPointValue[row].BreakpointValue=item;
    this.updateFeeStructureFormulas(false);
    this.checkMultiBreakPointValueStatus();

    }
    checkMultiBreakPointValueStatus(){
      this.breakPointValue.filter(
        breakPoint => breakPoint.BreakpointValue == "").length>=1?this.breakPointMultiValue.emit(true):this.breakPointMultiValue.emit(false);
        
    }

  deleteBreakPointValue(itemId: any, row: any) {
    if (!this.readOnly) {
      if (itemId === undefined) {
        // Directly remove the row if itemId is undefined dur to not having data  so Itemid will not be created
        this.breakPointValue.splice(row, 1);
      } else {
        this.mainservice.deleteListItem_CCLCompliance(AppSettings.closingCheckListFeeStructureBreakpoints, this.formId.toString(), itemId.toString())
          .subscribe(response => {
            this.breakPointValue.splice(row, 1);
            this.updateFeeStructureFormulas(false);
            this.checkMultiBreakPointValueStatus();
          });
      }
    }
  }

    deleteBreakpointValuesByFormId()
    {
      this.ComplianceService.getBreakPointValuesCCL(AppSettings.closingCheckListFeeStructureBreakpoints,this.formId)
      .subscribe(response => {
        response.forEach((user: any) => {
          this.ComplianceService.deleteListItem(AppSettings.closingCheckListFeeStructureBreakpoints,user.Id)
      .subscribe(response => {
        this.breakPointValue.length=0;
      });
      });
      })
    }
  toggleButtonActive(itemEvent: any, fromUI: any){
      if(fromUI==true &&itemEvent!="")
      {
        this.dcfFeeStrcutureFormGroup.controls['breakpointQuestion'].setValue(itemEvent);
        if(itemEvent=="NO")
        {
          this.showBreakPointtable=false;
          this.showBreakPoint=true
          this._setAsDisabled('breakpointValue');
          this. updateFeeStructureFormulas(true);
        this.showMultiAsterisk=false;
      this.showhighlight=false;
      this.Percentage=false;
      this.Numerical=false;
        }
        
        if(itemEvent=="YesOne")
        {
          this.Percentage=true;
          this.Numerical=true;
          this.showBreakPointtable=false;
          this.showBreakPoint=true
          this. updateFeeStructureFormulas(true);
           this.showMultiAsterisk=false;
           this.showhighlight=false;
          this.dcfFeeStrcutureFormGroup.controls.breakpointValue.enable();
        }
      }
      if(itemEvent=="NO" && fromUI=='false')
      {
      this.showBreakPointtable=false;
      this.showBreakPoint=true
      this.disableNoRadiobutton();
      this.dcfFeeStrcutureFormGroup.controls['baseFeeRadio'].setValue("Percentage");
      this.dcfFeeStrcutureFormGroup.controls.baseFeePercentage.enable();
        this._setAsDisabled("baseFeeNumerical");
        this._setAsDisabled('breakpointValue');
        this.deleteBreakpointValuesByFormId();
        this.breakPointValue.length=0;
        this. updateFeeStructureFormulas(true);
        this.showMultiAsterisk=false;
      this.showhighlight=false;
      this.dcfFeeStrcutureFormGroup.controls['breakpointQuestion'].setValue(itemEvent);
      this.Percentage=false;
      this.Numerical=false;
      }
      else if(itemEvent=="YesOne" && fromUI=='false')
      {
        this.showBreakPoint=true
        this.showBreakPointtable=false;
        this.enableYesRadiobutton();
        this.dcfFeeStrcutureFormGroup.controls.breakpointValue.enable();
        this.deleteBreakpointValuesByFormId();
        this.breakPointValue.length=0;
          this.dcfFeeStrcutureFormGroup.controls['baseFeeRadio'].setValue("Percentage");
          this.dcfFeeStrcutureFormGroup.controls.baseFeePercentage.enable();
//incentive Numerical
        this.dcfFeeStrcutureFormGroup.controls['incentiveFeeRadio'].setValue("Percentage");
        this.dcfFeeStrcutureFormGroup.controls.incentiveFeePercentage.enable();
        this.Percentage=true;
        this.Numerical=true;

        this._setAsDisabled('incentiveFeeNumerical');
        this._setAsDisabled('baseFeeNumerical');
         this. updateFeeStructureFormulas(true);
          this.showMultiAsterisk=false;
      this.showhighlight=false;
      this.dcfFeeStrcutureFormGroup.controls['breakpointQuestion'].setValue(itemEvent);
      }
      else if(itemEvent=='YesMultiple'){
        this._setAsDisabled('breakpointValue');
        this.showBreakPoint=false;
        this.enableOneRadiobutton();
       // this.enableYesRadiobutton();
        this.showBreakPointtable=true;
        this._setAsDisabled('incentiveFeePercentage');
      //  this._setAsDisabled('incentiveFeeRadio');
      this.Percentage=false;
      this.Numerical=true;
        this.dcfFeeStrcutureFormGroup.controls['incentiveFeeRadio'].setValue("Numerical");
        this.dcfFeeStrcutureFormGroup.controls.incentiveFeeNumerical.enable();
        this. updateFeeStructureFormulas(true);
        this.showMultiAsterisk=true;
        this.dcfFeeStrcutureFormGroup.controls['breakpointQuestion'].setValue(itemEvent);
      }
      else{
        if(fromUI==true &&(itemEvent=="" || itemEvent==undefined))
        {
        this.showBreakPointtable=false;
      this.showBreakPoint=true
      this.disableNoRadiobutton();
      this.enableOneRadiobutton();
      this._setAsDisabled('breakpointValue');
      this.breakPointValue.length=0;
      this.showMultiAsterisk=false;
      this.showhighlight=false;
        }
      }
    }
    enableOneRadiobutton()
    {
      this.dcfFeeStrcutureFormGroup.controls.baseFeeRadio.enable();
      if(this.dcfFeeStrcutureFormGroup.controls['baseFeeRadio'].value=='Percentage')
      {
        this.dcfFeeStrcutureFormGroup.controls.baseFeePercentage.enable();
        this._setAsDisabled('baseFeeNumerical');
        
      }
      else if(this.dcfFeeStrcutureFormGroup.controls['baseFeeRadio'].value=='Numerical'){
        this._setAsDisabled('baseFeePercentage');
        this.dcfFeeStrcutureFormGroup.controls.baseFeeNumerical.enable();
        
      }
      else{
        this._setAsDisabled('baseFeeNumerical');
        this.dcfFeeStrcutureFormGroup.controls.baseFeePercentage.enable();
        this.dcfFeeStrcutureFormGroup.controls['baseFeeRadio'].setValue("Percentage");
      }
    }
  radioBaseFeeEnableMethod(item: any, fromSharePoint: any)
    {
if(item=='Numerical')
{
  if(fromSharePoint=='false')
  this.dcfFeeStrcutureFormGroup.controls['baseFeeNumerical'].setValue("");

  this._setAsDisabled('baseFeePercentage');
  this.dcfFeeStrcutureFormGroup.controls['baseFeeRadio'].setValue("Numerical");
  this.dcfFeeStrcutureFormGroup.controls['baseFeePercentage'].setValue("");
  this.dcfFeeStrcutureFormGroup.controls.baseFeeNumerical.enable();
}else if(item=='Percentage'){
  this.dcfFeeStrcutureFormGroup.controls['baseFeeNumerical'].setValue("");
  this.dcfFeeStrcutureFormGroup.controls['baseFeeRadio'].setValue("Percentage");
  this._setAsDisabled('baseFeeNumerical');
  this.dcfFeeStrcutureFormGroup.controls.baseFeePercentage.enable();
}
else{
  this.dcfFeeStrcutureFormGroup.controls['baseFeeNumerical'].setValue("");
  this.dcfFeeStrcutureFormGroup.controls['baseFeeRadio'].setValue("Percentage");
  this._setAsDisabled('baseFeeNumerical');
  this.dcfFeeStrcutureFormGroup.controls.baseFeePercentage.enable();
}
}
  radioIncentiveFeeEnableMethod(item: any)
    {
        if(item=='Numerical')
        {
          this.dcfFeeStrcutureFormGroup.controls['incentiveFeeRadio'].setValue("Numerical");
          this._setAsDisabled('incentiveFeePercentage');
          this.dcfFeeStrcutureFormGroup.controls.incentiveFeeNumerical.enable();
          this.Percentage=true;
          if(this.dcfFeeStrcutureFormGroup.controls['breakpointQuestion'].value=="YesMultiple")
          this.Percentage=false;

          this.Numerical=true;
        }
        else if(item=='Percentage'){
          this.dcfFeeStrcutureFormGroup.controls['incentiveFeeRadio'].setValue("Percentage");
          this.dcfFeeStrcutureFormGroup.controls.incentiveFeePercentage.enable();
          this._setAsDisabled('incentiveFeeNumerical');
          this.Percentage=true;
          this.Numerical=true;
        }
        else{
         if(this.dcfFeeStrcutureFormGroup.controls['breakpointQuestion'].value=="NO")
         {
          this.Percentage=false;
          this.Numerical=false;
         }
         else
         {
          this.Percentage=true;
          this.Numerical=true;
         }
     
          this._setAsDisabled('incentiveFeePercentage');
          this._setAsDisabled('incentiveFeeNumerical');
        }
    }
    
    enableYesRadiobutton()
    {
      this.dcfFeeStrcutureFormGroup.controls.incentiveFeeRadio.enable();
      this.dcfFeeStrcutureFormGroup.controls.incentiveFeePercentage.enable();
    }
    disableNoRadiobutton()
    {
      this._setAsDisabled('incentiveFeeRadio');
      this._setAsDisabled('incentiveFeeNumerical');
      this._setAsDisabled('incentiveFeePercentage');
    }
    disableYesMultiple()
    {
      this._setAsDisabled('incentiveFeePercentage');
      this._setAsDisabled('incentiveFeeRadio');
    }
    evaluateRetainerReceived(value: string) {
      if (value!="" &&value!=undefined) {
        this.dcfFeeStrcutureFormGroup.controls.minimumFeeApply.setValidators;
        this.dcfFeeStrcutureFormGroup.controls.minimumFeeApply.enable();
      } else {
        this._setAsDisabled('minimumFeeApply');
      }
    }
    private _setAsDisabled(formControlName: string) {
      const controlName = this.dcfFeeStrcutureFormGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }
    highlightRequiredField(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
      }
      return false;
    }
  
    editedExternally(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.editedExternally(formGroup, formControlName);
      }
      return false;
    }
  
    hasMergeConflict(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.hasMergeConflict(formGroup, formControlName);
      }
      return false;
    }

  onminimumFeeApply(event: any){
      this.dcfFeeStrcutureFormGroup.controls.minimumFeeApply.setValue(event);
    }
  onFeeTypeSelection(event: any, columnName: any){
      this.dcfFeeStrcutureFormGroup.controls[columnName].setValue(event.key);
      this.evaluateBreakPointSection(event);
    }
    evaluateBreakPointSection(value: string) {
      if (value!=""&&value!=undefined) {
        this.dcfFeeStrcutureFormGroup.controls.describeFeeStructure.enable();
        this.dcfFeeStrcutureFormGroup.controls.breakpointQuestion.enable();
      } else {
        this._setAsDisabled("breakpointQuestion");
      }
    }

  onSelectedValue(event: any, columnName: any) {
        this.dcfFeeStrcutureFormGroup.controls[columnName].setValue(event);
        if(columnName=="baseFeePercentage"||columnName=="incentiveFeePercentage"||columnName=="breakpointValue")
        this.updateFeeStructureFormulas(false)
       
      }

  updateFeeStructureFormulas(breakpointChange: any) {
        var breakpointValueNo = "NO";
        var breakpointValueOne = "YesOne";
        var breakpointValueMultiple = "YesMultiple";
       let breakpoint= this.dcfFeeStrcutureFormGroup.controls['breakpointQuestion'].value;
        if (breakpoint!=undefined) {
            switch (breakpoint) {
                case breakpointValueNo:
                    this.setFeeStructureFormulasNoBreakpoint();
                    break;
                case breakpointValueOne:
                    this.setFeeStructureFormulasOneBreakpoint(breakpointChange);
                    break;
                case breakpointValueMultiple:
                    this.setFeeStructureFormulasMultipleBreakpoint();
                    break;
            }
        } else {
          this.setFeeStructureFormulasNoBreakpoint();
        }
    }
    setFeeStructureFormulasMultipleBreakpoint()
    {
      this.setBaseFeeInputsMultipleBreakpoint();
     // this.setIncentiveFeeInputsMultipleBreakpoint();
      
    }
    setBaseFeeInputsMultipleBreakpoint()
    {
      if(this.dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.disabled)
      {
        let firstMultipleBreakpointValue=0;
        if(this.breakPointValue.length>0)
         firstMultipleBreakpointValue =this.breakPointValue[0].BreakpointValue;
        let percentage = Number(this.naFormatService.formatNA(this.dcfFeeStrcutureFormGroup.get('baseFeePercentage')?.value));
        var total="";
        total =(Number(percentage) && Number(firstMultipleBreakpointValue) ? Math.round(firstMultipleBreakpointValue * (percentage/100)) : "").toString();
        this.dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.setValue(total)
      }
    }

  setFeeStructureFormulasOneBreakpoint(breakpointChange: any)
    {
      this.setBaseFeeInputsOneBreakpoint();
      this.setIncentiveFeeInputsOneBreakpoint(breakpointChange);
    }
  setIncentiveFeeInputsOneBreakpoint(breakpointChange: any)
    {
      let percentage = Number(this.naFormatService.formatNA(this.dcfFeeStrcutureFormGroup.get('incentiveFeePercentage')?.value))
    
    if(this.dcfFeeStrcutureFormGroup.get('incentiveFeeNumerical')?.disabled){
      let totalConsideration = Number(this.naFormatService.formatNA(this.valuationMetrics.get('totalConsideration')?.value));
      let breakpointValue = Number(this.naFormatService.formatNA(this.dcfFeeStrcutureFormGroup.get('breakpointValue')?.value));
     let total="";
      //var format = incentiveFeeNumerical.attr("format");
      if (Number(breakpointValue) && Number(totalConsideration) && totalConsideration > breakpointValue) {
          total = (Number(percentage) ? Math.round((totalConsideration - breakpointValue) * (percentage/100)) : "").toString();
      } else {
          if (!Number(breakpointValue) && Number(totalConsideration)) {
              total = (Number(percentage) ? Math.round((totalConsideration - 0) * (percentage/100)) : "").toString();
          } else {
              total = "";
          }
      }
      this.dcfFeeStrcutureFormGroup.get('incentiveFeeNumerical')?.setValue(total);
      //updateTargetInputValue(incentiveFeeNumerical, format, total);
  }
    }

    setBaseFeeInputsOneBreakpoint()
    {
      if(this.dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.disabled)
      {
     let breakpointValue = Number(this.naFormatService.formatNA(this.dcfFeeStrcutureFormGroup.get('breakpointValue')?.value))
        let percentage = Number(this.naFormatService.formatNA(this.dcfFeeStrcutureFormGroup.get('baseFeePercentage')?.value));
        let total="";
       total = (Number(percentage) &&Number(breakpointValue) ? Math.round(breakpointValue * (percentage/100)): "").toString();
        this.dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.setValue(total);
}
    }
    setFeeStructureFormulasNoBreakpoint()
    {
      
      this.setBaseFeeInputsNoBreakpoint();
    }
    setBaseFeeInputsNoBreakpoint()
    {
if(this.dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.disabled)
{
  let totalConsideration =Number(this.naFormatService.formatNA(this.valuationMetrics.get('totalConsideration')?.value));
  let percentage =Number(this.naFormatService.formatNA(this.dcfFeeStrcutureFormGroup.get('baseFeePercentage')?.value));
  let total="";
  total = (Number(percentage) && Number(totalConsideration) ?  Math.round(totalConsideration * (percentage/100)) : "").toString();
  var format="negativeCurrency";
  this.dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.setValue(total)
  //this.updateTargetInputValue(baseFeeNumerical, format, total);
}
    }

      isReadOnly(formControlName: string): boolean {
        const formGroup = this._getFormGroup(formControlName);
        if (formGroup) {
          return FormValidations.isReadOnly(formGroup, formControlName);
        }
        return false;
      }
      private _getFormGroup(formControlName: string): FormGroup {
        return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
      }

      showAsterisk(formControlName: string): boolean {
        const formGroup = this._getFormGroup(formControlName);
        if (formGroup) {
          return FormValidations.showAsterisk(formGroup, formControlName);
        }
        return false;
      }
      
  highlightMultiRequiredField(value: any) {
        this.showhighlight=value;
      }
    
      hasMergeMultiConflict(): boolean {
        let breakpointvalue= this.dcfFeeStrcutureFormGroup.controls['breakpointQuestion'].value;
        if(breakpointvalue=="YesMultiple"){
         if(this.multiBreakPointValidation)
         {
           return false;
         }
         else
         {
          return true;
         }
        }
        return false;
      }
      getTooltip(tooltipTitle: string): TooltipObject {
        return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
      }
    
      getTooltipText(tooltipTitle:string):string{
        const tooltip = this.getTooltip(tooltipTitle);
        return tooltip ? tooltip.getTooltipText() : '';
      }
  highlightRequiredFieldCheck(percentage: any, numerical: any){
        return (this.highlightRequiredField(percentage) || this.highlightRequiredField(numerical));
      }
  editedExternallyCheck(percentage: any, numerical: any){
        return (this.editedExternally(percentage) || this.editedExternally(numerical));
      }
  hasMergeConflictCheck(percentage: any, numerical: any){
        return (this.hasMergeConflict(percentage) || this.hasMergeConflict(numerical));
      }
}
