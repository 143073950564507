import { Injectable } from '@angular/core';
import { MainService } from 'src/shared/services/main.service';
import { AppSettings } from 'src/shared/app-settings';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { Spcrud } from 'src/shared/services/spcrud';
import { MainServiceHelper } from 'src/shared/services/main-service-helper';
import { DealWbsSetupServiceHelper } from './deal-wbs-setup-service-helper';
import { BaseService } from 'src/shared/services/base.service';
import { RootSiteSpcrud } from 'src/shared/services/root-site-spcrud';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { Observable, catchError, from, map } from 'rxjs';
import { formatCurrency } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DealWbsSetupService extends BaseService {

  constructor(
    private mainService: MainService,
    spcrud: Spcrud,
    rootSiteSpcud: RootSiteSpcrud,
    private mainServiceHelper: MainServiceHelper,
    private dealWbsServiceHelper: DealWbsSetupServiceHelper,
  private dealWbsService: DealwbsService) {
      super(spcrud, rootSiteSpcud);
    }

  retrieveMyDeal(myDealId: number): Promise<any> {
    return this.mainService.getListItem(AppSettings.myDealFormList, myDealId);
  }

  getDealTeamUsers(listName: string, formId: number): Promise<Array<PeoplepickerDynamicTableItem>> {
    const spcrud = this.getSpcrud(listName);
    const optionsDealTeamTable = {
      filter: 'Form/Id eq ' + formId,
      // tslint:disable-next-line: max-line-length
      select: 'Id, FormId, Role/Role, Role/Id, PursuitRole/Role, PursuitRole/Id, SwiftRole/Role, SwiftRole/Id, Name/EMail, Name/Id, Name/Title, Name/Name',
      expand: 'Role, PursuitRole,SwiftRole, Name'};
    return spcrud.read(listName, optionsDealTeamTable)
    .then( (response:any) => {
      const results = response.d.results;
      const dealTeamUsers = results.map((item:any) => {
        const peoplePickerUser = this.mainServiceHelper.createPeoplePickerUser(item.Name.Name, item.Name.Title, item.Name.EMail);
        return this.mainServiceHelper.createPeoplepickerDynamicTableItem(item.Name.Id, peoplePickerUser, item);
      });
      return dealTeamUsers;
    }).catch((error:any) => {
    });
  }

  getDealTeamUsers_DealWbs(formId:any):Observable<any>{
    return from(this.dealWbsService.getDealTeamTableList(formId)).pipe(
      map((res:any)=>{
      return res;
    }),
    catchError((err: any) => {
      console.log('Error fetching User data:', err);
      return err; // Re-throw the error to propagate it through the Observable stream
      })
    )
  }

  getDealTeamUserByEmail(listName: string, formId: number, email: string): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: `Form/Id eq ${formId} and Name/EMail eq '${email}'`,
      select: 'Id, Name/EMail',
      expand: 'Name'
    };
    return spcrud.read(AppSettings.dealTeamTableList, options)
    .then( (response:any) => {
      return response.d.results[0];
    }).catch((error:any) => {
    });
  }
  getDealTeamUserByEmail_DealWbs(formId:any, email:any):Observable<any>{
    return from(this.dealWbsService.GetDealTeamUserByEmail_DealWbs(formId,email))
      .pipe(
        map((results: any) => {
        return results;
      }),
      catchError((err: any) => {
        console.log('Error getting form data:', err);
        throw err;
      })
    )  
  }

  getSeries24GroupMembers() {
    return this.mainService.getGroupUsers(AppSettings.series24Group);
  }

  getDCFGroupMembers() {
    return this.mainService.getGroupUsers(AppSettings.dcfComplianceGroup);
  }

  isAssignedSeries24(listName: string, formId: number, userId: number): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: 'Id eq ' + formId + ' and Series24/Id eq ' + userId
     };
    return spcrud.read(listName, options)
    .then( (response:any) => {
      if (response.d.results.length > 0) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error:any) => {
    });
  }

  isAssignedSeries24_DealWbs(formId:any,userId:string):Observable<any>{
    return from(this.dealWbsService.GetMyDealDetails(formId)).pipe(
      map(res=>{
        this.mainService.myDealList=res;
        return res.find((user:any)=>user.Series24==userId);
    }),
    catchError((err: any) => {
      console.log('Error fetching documents data:', err);
      throw err; // Re-throw the error to propagate it through the Observable stream
    })
    )
  }

  updateFormOnSubmit(formId: number, adminAssignedId: number,rangeKey:any,initiateWorkflow:boolean=false,sendDealWbsEmail:boolean=false,dealTeamUSerEmail:any="",dealTeamEmail:any="") {
    let objectToSend:any = this.dealWbsServiceHelper.createSubmitObject(adminAssignedId);
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.mainService.cleanJsonObject(objectToSend),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendDealWbsEmail:sendDealWbsEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return this.mainService.updateListItem_DealWBS(AppSettings.dealWbsSetupFormList, formId, data);
  }

  updateFormOnDiscontinue(formStatus: string,formActionGroup:string, formId: number, discontinueReason: string,rangeKey:any,initiateWorkflow:boolean=false,sendDealWbsEmail:boolean=false,dealTeamUSerEmail:any="",dealTeamEmail:any="") {
    const objectToSend = this.dealWbsServiceHelper.createAdminDiscontinueObject(formStatus,formActionGroup, discontinueReason);
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.mainService.cleanJsonObject(objectToSend),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendDealWbsEmail:sendDealWbsEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return this.mainService.updateListItem_DealWbs(AppSettings.dealWbsSetupFormList, formId, data);
  }

  updateFormOnRetryWorkflow(formStatus: string, formId: number, rangeKey:any,initiateWorkflow:boolean=false,sendDealWbsEmail:boolean=false,dealTeamUSerEmail:any="",dealTeamEmail:any="") {
    const objectToSend = this.dealWbsServiceHelper.createRetryWorkflowObject(formStatus);
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.mainService.cleanJsonObject(objectToSend),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendDealWbsEmail:sendDealWbsEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return this.mainService.updateListItem_DealWbs(AppSettings.dealWbsSetupFormList, formId, data);
  }

  updateFormOnAdminReviewApproval(formId: number, rangeKey:any,initiateWorkflow:boolean=false,sendDealWbsEmail:boolean=false,dealTeamUSerEmail:any="",dealTeamEmail:any="") {
    const objectToSend = this.dealWbsServiceHelper.createAdminApprovalObject();
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.mainService.cleanJsonObject(objectToSend),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendDealWbsEmail:sendDealWbsEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return this.mainService.updateListItem_DealWbs(AppSettings.dealWbsSetupFormList, this.mainService.formId, data);
  }

  updateFormOnReturnForRework(returnForReworkReason: string,formId: number, rangeKey:any,initiateWorkflow:boolean=false,sendDealWbsEmail:boolean=false,dealTeamUSerEmail:any="",dealTeamEmail:any="") {
    const objectToSend = this.dealWbsServiceHelper.createAdminReturnForReworkObject(returnForReworkReason);
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.mainService.cleanJsonObject(objectToSend),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendDealWbsEmail:sendDealWbsEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return this.mainService.updateListItem_DealWbs(AppSettings.dealWbsSetupFormList, this.mainService.formId, data);
  }

  getAppContext(): Promise<any> {
    return Promise.all([
      this.getAutoSaveInterval(),
      this.getAutoSaveEnabled(),
      this.getAutoSaveSpinningWheelInterval()]).then(([autoSaveInterval, autoSaveEnabled,autoSaveSpinningWheelInterval]) => {
        return this.dealWbsServiceHelper.createAppContext(autoSaveInterval, autoSaveEnabled,autoSaveSpinningWheelInterval);
      });
  }

  getAutoSaveInterval(): Promise<any> {
    return this.mainService.getAppConfigurationValue('AutoSaveIntervalInMilliseconds').then((autoSaveIntervalInMilliseconds) => {
        let value = parseFloat(autoSaveIntervalInMilliseconds);
        if (isNaN(value)) {
          value = AppSettings.autoSaveIntervalDefault;
        }
        return value;
    });
  }

  getAutoSaveEnabled(): Promise<any> {
    return this.mainService.getAppConfigurationValue('EnableAutoSave').then((enableAutoSave) => {
      return (enableAutoSave === 'true');
    });
  }
  getAutoSaveSpinningWheelInterval():Promise<any>{
    return this.mainService.getAppConfigurationValue('AutoSaveSpinningWheelInterval').then((autoSaveSpinningWheelInterval)=>{
      let value=parseFloat(autoSaveSpinningWheelInterval);
      if(isNaN(value)){
        value=AppSettings.autoSaveSpinningWheelIntervalDefault;
      }
      return value;
    });
  }
}
