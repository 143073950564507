import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { DynamicTableHeader } from 'src/shared/models/dynamic-table-header';
import { DynamicTableDropdownItemList } from 'src/shared/models/dynamic-table-dropdown-item-list';
import { AppSettings } from 'src/shared/app-settings';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { PeoplepickerDynamicTableComponent } from 'src/shared/components/peoplepicker-dynamic-table/peoplepicker-dynamic-table.component';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
import { DealWbsSetupService } from '../shared/services/deal-wbs-setup.service';
import { MainService } from 'src/shared/services/main.service';
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { DealWbsNotifyPopupComponent } from 'src/shared/components/alerts/deal-wbs-notify-popup/deal-wbs-notify-popup.component';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { Observable, from, map } from 'rxjs';

const modalTypes  = {
  dealTeamRowAdded: 'deal-team-row-added',
  swiftRoleValid: 'swift-role-valid',
  dealTeamRowDeleteValid : 'deal-team-row-delete-valid'
}
@Component({
  selector: 'app-deal-team-table',
  templateUrl: './deal-team-table.component.html',
  styleUrls: ['./deal-team-table.component.scss']
})
export class DealTeamTableComponent implements OnInit {

  dealTeamRoleList: Array<DropdownItem> = [];
  dealTeamPursuitRoleList: Array<DropdownItem> = [];
  dealTeamSwiftRoleList: Array<DropdownItem> = [];
  dealTeamUserList: Array<PeoplepickerDynamicTableItem> = [];
  dealTeamNameHeader: DynamicTableHeader = new DynamicTableHeader();
  dealTeamRoleHeader: DynamicTableHeader = new DynamicTableHeader();
  dealTeamSwiftRoleHeader: DynamicTableHeader = new DynamicTableHeader();
  dealTeamPursuitRoleHeader: DynamicTableHeader = new DynamicTableHeader();
  dealTeamRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  dealTeamPursuitRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  dealTeamSwiftRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  sicCodesTooltip: string;
  rowToAdd: PeoplePickerSelectedRow;

  @Input()
  readonly = false;

  @Input()
  editable = false;

  @Input()
  firstColumnVisible = true;

  @Input()
  secondColumnVisible = true;

  @Input()
  thirdColumnVisible = true;

  @Input()
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];

  @Input()
  dealTeamTableFormGroup: any;

  @Input() allowToDuplicateUser: any = false;

  @Output()
  dealTeamRowToStore: EventEmitter<PeoplePickerSelectedRow> = new EventEmitter<PeoplePickerSelectedRow>();

  @ViewChild('Modal', {static: false})
  modal: ModalComponent;

  @ViewChild('DealTeam', {static: false})
  dealTeam: PeoplepickerDynamicTableComponent;

  @ViewChild(DealWbsNotifyPopupComponent, { static: false })
notifypopupComponent: DealWbsNotifyPopupComponent;
  modalType: string;
  

  constructor(private dropdownService: DropdownService,
              private peoplepickerService: PeoplepickerService,
              private dealWbsSetupService: DealWbsSetupService,
              private mainService: MainService,
            private commonHelperService:CommonHelperService,
          private spinnerService:SpinnerService,
        private dealwbsService: DealwbsService) { }

  ngOnInit() {
    this.LoadDealTeamDropdowns();
    // this.dropdownService.fillLookupOptions(AppSettings.dealTeamRole, 'Role')
    // .then(response => {
    //   this.dealTeamRoleList = response;
    //   this.dealTeamRoleColumn.itemList = this.dealTeamRoleList;
    //   this._setRoleNA();
    // });

    // this.dropdownService.fillLookupOptions(AppSettings.dealTeamPursuitRole, 'Role')
    //   .then(response => {
    //     this.dealTeamPursuitRoleList = response;
    //     this.dealTeamPursuitRoleColumn.itemList = this.dealTeamPursuitRoleList;
    //     this._setPursuitRoleNA();
    //   });

    // this.dropdownService.fillLookupOptions(AppSettings.dealTeamSwiftRole, 'Role')
    //   .then(response => {
    //     this.dealTeamSwiftRoleList = response;
    //     this.dealTeamSwiftRoleColumn.itemList = this.dealTeamSwiftRoleList;
    //     this._setSwiftRoleNA();
    //   });

    this.dealTeamNameHeader.header = 'Name';
    this.dealTeamRoleHeader.header = 'Role';
    this.dealTeamPursuitRoleHeader.header = 'Mercury Pursuit Role';
    this.dealTeamSwiftRoleHeader.header = 'SWIFT Role';
    this.dealTeamPursuitRoleHeader.toolTip = 'Primary Role receives Deal Credit, Support Role does not';

    this.dealTeamRoleColumn.header = this.dealTeamRoleHeader;
    this.dealTeamPursuitRoleColumn.header = this.dealTeamPursuitRoleHeader;
    this.dealTeamSwiftRoleColumn.header = this.dealTeamSwiftRoleHeader;
  }
LoadDealTeamDropdowns(){
  this.dropdownService.fillLookupOptions_DealWBS(AppSettings.dealTeamRole, 'Role').subscribe(
    {
      next: (response: any) => {          
        this.dealTeamRoleList = response;
        this.dealTeamRoleColumn.itemList = this.dealTeamRoleList;
        this._setRoleNA();
      },
      error: (err: any) => {
        this.spinnerService.hideAutoSaveSpinner();
        if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}  
        console.log("Error" + err)
      }
    })
    this.dropdownService.fillLookupOptions_DealWBS(AppSettings.dealTeamPursuitRole, 'Role').subscribe(
      {
        next: (response: any) => {          
          this.dealTeamPursuitRoleList = response;
          this.dealTeamPursuitRoleColumn.itemList = this.dealTeamPursuitRoleList;
          this._setPursuitRoleNA();
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}  
          console.log("Error" + err)
        }
      })
      this.dropdownService.fillLookupOptions_DealWBS(AppSettings.dealTeamSwiftRole, 'Role').subscribe(
        {
          next: (response: any) => {          
            this.dealTeamSwiftRoleList = response;
            this.dealTeamSwiftRoleColumn.itemList = this.dealTeamSwiftRoleList;
            this._setSwiftRoleNA();
          },
          error: (err: any) => {
            this.spinnerService.hideAutoSaveSpinner();
            if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}  
            console.log("Error" + err)
          }
        })
}
  updatedExcludedUsers(currentUsers: Array<PeoplePickerUser>) {
    this.dealTeam.updatedExcludedUsers(currentUsers);
  }

  setItemId(itemId: number, index: number) {
    this.dealTeam.setItemId(itemId, index);
  }

  onDealTeamRowSelection(dealTeamRow: PeoplePickerSelectedRow) {
    if (dealTeamRow.actionType === DynamicTableAction.Add) {
      this._handlerNewRow(dealTeamRow);
    } else {
      const dealTeamUsers: PeoplepickerDynamicTableItem[] = this.dealTeamTableFormGroup.value;
      const arrayWithoutRowToDelete = dealTeamUsers.filter(user => user.itemId !== dealTeamRow.item.itemId);
      this.dealTeamTableFormGroup.setValue([...arrayWithoutRowToDelete]);
      this.dealTeamRowToStore.emit(dealTeamRow);
    }
  }

  confirmModal() {
    this._setNewRow();
    this.modal.close();
  }

  cancelModal() {
    if (!this.rowToAdd.item.itemId) {
      this.dealTeam.deleteRow(this.rowToAdd.item.index);
    } else {
      const index = this.dealTeam.rowIndex(this.rowToAdd.item.usersSelected[0]);
      this.dealTeam.removeUserFromRow(
        this.rowToAdd.item.usersSelected[0],
        index
      );
    }
    this.modal.close();
  }

  private _handlerNewRow(dealTeamRow: PeoplePickerSelectedRow) {
    this.rowToAdd = dealTeamRow;
    //Below sharepoint one commented
    //this.peoplepickerService.getUserId(this.rowToAdd.item.usersSelected[0].EntityData.Email)
    const usersSelected = this.rowToAdd.item.usersSelected[0];
    this.dealwbsService.GetUserRoleGroups(this.rowToAdd.item.usersSelected[0].mail)
      .subscribe((idUser:any) => {
        const itemIdInTable = this._isUserInTable();
        itemIdInTable.subscribe(idInTable => {
          if (idInTable > 0) {
            const itemId = (this.rowToAdd.item.itemId as any)?.ID || this.rowToAdd.item.itemId 
            const sameRow = idInTable === itemId;
            this.rowToAdd.item.idUsersSelected = [idUser];

            if (sameRow) {
              this._setNewRow();
            } else {
              const index = this.dealTeam.rowIndex(this.rowToAdd.item.usersSelected[0]);
              this.dealTeam.removeUserFromRow(
                usersSelected,
                index
              );
              this.dealTeam.showError();
            }
          } else {
            this.rowToAdd.item.idUsersSelected = [usersSelected.id];
            this.modalType = modalTypes.dealTeamRowAdded;
            this.notifypopupComponent.openPopup("DealWbs",this.modalType);
          }
        });
      });
  }

  private _setNewRow() {
    const itemIdToInsert = this.rowToAdd.item.itemId;
    const dealTeamRows: PeoplepickerDynamicTableItem[] = this.dealTeamTableFormGroup.value;
    if (!itemIdToInsert) {
      this.dealTeamTableFormGroup.setValue([...dealTeamRows, this.rowToAdd.item]);
    } else {
      const arrayWithoutRowToUpdate = dealTeamRows.filter(user => user.itemId !== this.rowToAdd.item.itemId);
      this.dealTeamTableFormGroup.setValue([...arrayWithoutRowToUpdate, this.rowToAdd.item]);
    }
    this.dealTeamRowToStore.emit(this.rowToAdd);
  }

  private _isUserInTable(): Observable<any> {
      const defaultVal:any=0;
      const email = this.rowToAdd.item.usersSelected[0].mail;
      const formId = this.mainService.formId;
      const myDealFormId = this.mainService.myDealFormId;
      if (formId) {
        return from(this.dealWbsSetupService.getDealTeamUserByEmail_DealWbs(myDealFormId, email))
          .pipe(
            map(response => {
            if (response.length) {
              return (response[0].ID);
            } else {
              return (defaultVal);
            }
          })
        )
      } else {
        return from(defaultVal);
      }
  }

  private _setPursuitRoleNA() {
    AppSettings.pursuitRoleNA = Number(this.dealTeamPursuitRoleList.filter(item => item.value === 'Other')[0].key);
  }

  private _setSwiftRoleNA() {
    AppSettings.swiftRoleNA = Number(this.dealTeamSwiftRoleList.filter(item => item.value === 'N/A')[0].key);
  }

  private _setRoleNA() {
    AppSettings.roleNA = Number(this.dealTeamRoleList.filter(item => item.value === 'N/A')[0].key);
  }

  deleteConfirmDetails(event:any){
    if (event.isOkayClicked) {
      this.confirmModal();
    }else if (event.isCancelClicked) {
      this.cancelModal();
  }
    else if (event.isCancelClicked) {
      this.cancelModal();
    }
  }  
}
