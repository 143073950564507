import { Component, OnInit, ViewChildren, QueryList, Output, EventEmitter, ViewChild } from '@angular/core';
import { AppSettings } from 'src/shared/app-settings';
import { NgbdSortableHeaderDirective, SortDirection, SortEvent } from 'src/shared/directives/sorteable.directive';
import { PaginationComponent } from 'src/shared/components/pagination/pagination.component';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PaginationValues } from 'src/shared/models/pagination-values';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { ReportDashboardSort } from '../../models/reportDashboard-sort';
import { ReportDashboardTableFilterItem } from '../../models/reportDashboard-table-filter-item';
import { ReportDashboardItem } from '../../models/reportDashboardItem';
// import { ReportDashboardService } from '../../shared/report-table-dashboard.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { ReportTabletDashboardService } from '../../shared/report-table-dashboard.service';
import { EngagementTableFilterItem } from '../../models/engagement-table-filter-item';
import { environment } from 'src/environments/environment';
import { Spcrud } from 'src/shared/services/spcrud';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
@Component({
  selector: 'app-closing-checklist-table',
  templateUrl: './closing-checklist-table.component.html',
  styleUrls: ['./closing-checklist-table.component.scss']
})
export class ClosingChecklistTableComponent implements OnInit {
    reportDashboards: Array<ReportDashboardItem> = [];
    reportDashboardsPerPage : Array<ReportDashboardItem>;
    filterList: Array<ReportDashboardTableFilterItem> = [];
    targetField: string | any;
    selectedPageSize: any;
    targetFilter:ReportDashboardTableFilterItem;
    fieldOptionValues: Array<string> = [];
    initialReportDashboardList: Array<ReportDashboardItem> = [];
    initialReportDashboardListForSearch: Array<ReportDashboardItem> = [];
    pageSizeListValues: Array<DropdownItem> = [];
    pageDropdownDisable: boolean;
    @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
    
    @ViewChild('pagination', {static: false})
    pagination: PaginationComponent;
    engagementHealthObjectUrl: string;
    reportDashboardExportToExcel:Array<any>=[];
    reportDashboardPageDetails:Array<any>=[];
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension = '.xlsx';
    fileName= 'ExcelSheet.xlsx';
    showDisable:boolean;
    URL:any=AppSettings.ClosingCheckListForm + '?id=';
    onSearchApplyOnFilteredData:any;
    searchTerm:any="";
    filterApply:boolean=false;
    searchResults:any=[];
    constructor(public reportDashboardServices:ReportTabletDashboardService,private datePipe: DatePipe,
      private spinnerService: SpinnerService, private spcrud: Spcrud,public commonHelperService: CommonHelperService
      ) { 
        
      }
  
  ngOnInit(): void {
    this.reportDashboardExportToExcel.length = 0;
    // this.spinnerService.hideAutoSaveSpinner();
    this.showDisable=true;
    this.commonHelperService.state = this.commonHelperService.getSearchState();
  }
  private setPagenationMultiSelect(checkboxStatus:any)
  {
    if(this.reportDashboardPageDetails.length>0)
    {
      this.reportDashboardPageDetails.forEach(v => {
        if(v.PageNumber==this.reportDashboardServices.state.page)
        {
          v.IsMultiSelect=checkboxStatus
        }
        else{
          let reportPageItem = {
            PageNumber: this.reportDashboardServices.state.page,
            PageSize: this.reportDashboardServices.state.pageSize,
            IsMultiSelect:checkboxStatus
        };
        this.reportDashboardPageDetails.push(reportPageItem);
        }
      });
    }
    else{
    let reportPageItem = {
      PageNumber: this.reportDashboardServices.state.page,
      PageSize: this.reportDashboardServices.state.pageSize,
      IsMultiSelect:checkboxStatus
  };
  this.reportDashboardPageDetails.push(reportPageItem);
}
  }
  onCheckboxChange(checkbox:any){
    this.setPagenationMultiSelect(checkbox.target.checked);
    if(checkbox.target.checked)
    {
      this.reportDashboardServices.ExportData=[];
      this.reportDashboardsPerPage.forEach(v => {
        
          let reportDashboardItem = {
            EngagementId: v.engagementId,
            Link: this.URL+v.form_id,
            EngagementName: v.engagementName,
            S24ApprovalStatus: v.isS24ApprovalComplete,
            S24Name: v.nameOfS24,
            S24ApprovalDate :v.s24ApprovalDate
        };
      if(!this.reportDashboardExportToExcel.find(e => e.EngagementId === v.engagementId))
      {
        this.reportDashboardExportToExcel.push(reportDashboardItem);
        this.reportDashboardServices.ExportData.push(reportDashboardItem);
      }
       
    });
    this.reportDashboards.forEach(v => {
      if(this.reportDashboardExportToExcel.find(e => e.EngagementId === v.engagementId))
       v.isSelected=true;
    });
//enable the export option
this.showDisable=true;
if(this.reportDashboardExportToExcel.length>0)
{
  this.showDisable=false;
}
else{
  this.reportDashboardPageDetails.length=0;
}
  }
  else{
    this.reportDashboardsPerPage.forEach(v => {
      this.reportDashboardExportToExcel =  this.reportDashboardExportToExcel.
      filter(e=>e.EngagementId!==v.engagementId);
    })
    this.reportDashboardServices.ExportData=[];
    this.reportDashboards.forEach(v => {
      if(this.reportDashboardsPerPage.find(e => e.engagementId === v.engagementId))
       v.isSelected=false;
    });
    
//enable the export option
this.showDisable=true;
if(this.reportDashboardExportToExcel.length>0)
{
  this.showDisable=false;
}
else{
  this.reportDashboardPageDetails.length=0;
}
  }
  }
  onCheckboxChanged(event:any){

    if(this.reportDashboards.find(e => e.engagementId ==event && e.isSelected==false))
    {
      let Item :any=this.reportDashboards.find(e => e.engagementId ==event && e.isSelected==false)
      let reportDashboardItem = {
        EngagementId: Item.engagementId,
        Link: this.URL+Item.form_id, 
        EngagementName: Item.engagementName,
        S24ApprovalStatus: Item.isS24ApprovalComplete,
        S24Name: Item.nameOfS24,
        S24ApprovalDate :Item.s24ApprovalDate
    };
    this.reportDashboardExportToExcel.push(reportDashboardItem);
    this.reportDashboardServices.ExportData.push(reportDashboardItem);
    this.reportDashboards.forEach(v => {
      if(v.isSelected==false && v.engagementId==event)
       v.isSelected=true;
    }); 

     if(!this.reportDashboardsPerPage.find(v=>v.isSelected==false))
     {
      
      const checkbox:any = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
      checkbox.checked = true;
      if(this.reportDashboardPageDetails.length!=0)
      {
      this.reportDashboardPageDetails.forEach(v => {
        if(v.PageNumber==this.reportDashboardServices.state.page)
        {
          v.IsMultiSelect=true
        }
        else{
          let reportPageItem = {
            PageNumber: this.reportDashboardServices.state.page,
            PageSize: this.reportDashboardServices.state.pageSize,
            IsMultiSelect:true
        };
        this.reportDashboardPageDetails.push(reportPageItem);
        }
      });
      }
      else{
        let reportPageItem = {
          PageNumber: this.reportDashboardServices.state.page,
          PageSize: this.reportDashboardServices.state.pageSize,
          IsMultiSelect:true
      };
      this.reportDashboardPageDetails.push(reportPageItem);
      }
     }

    }
    else{
      this.reportDashboardExportToExcel =  this.reportDashboardExportToExcel.
      filter(e=>e.EngagementId!==event);

      this.reportDashboardServices.ExportData=this.reportDashboardServices.ExportData.
      filter((e:any)=>e.EngagementId!==event);

    this.reportDashboards.forEach(v => {
      if(v.isSelected==true && v.engagementId==event)
       v.isSelected=false;
    });
    const checkbox:any = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement | null;
    checkbox.checked = false;
    this.reportDashboardPageDetails.forEach(v => {
      if(v.PageNumber==this.reportDashboardServices.state.page)
      {
        v.IsMultiSelect=false
      }
    });
    }
    this.showDisable=true;
if(this.reportDashboardExportToExcel.length > 0 || this.reportDashboardServices.ExportData.length > 0)
{
  this.showDisable=false;
}
else{
  this.reportDashboardPageDetails.length=0;
}
  }
   exportToExcel(){
    if(this.reportDashboardExportToExcel.length==0){
      var reportDashboardItem;
      this.reportDashboards.forEach((item:any)=> {
          reportDashboardItem = {
            EngagementId: item.engagementId,
            Link: this.URL+item.form_id, 
            EngagementName: item.engagementName,
            S24ApprovalStatus: item.isS24ApprovalComplete,
            S24Name: item.nameOfS24,
            S24ApprovalDate :item.s24ApprovalDate
          };
          this.reportDashboardExportToExcel.push(reportDashboardItem);
      })

    }
    if(this.reportDashboardExportToExcel.length != this.reportDashboardServices.ExportData.length)
      {
        this.reportDashboardExportToExcel=[]
        var reportDashboardItem;
        this.reportDashboardServices.ExportData.forEach((item:any)=> {
            reportDashboardItem = {
              EngagementId: item.EngagementId,
              Link: this.URL+item.form_id, 
              EngagementName: item.EngagementName,
              S24ApprovalStatus: item.S24ApprovalStatus,
              S24Name: item.S24Name,
              S24ApprovalDate :item.S24ApprovalDate
            };
            this.reportDashboardExportToExcel.push(reportDashboardItem);
        })
      }
    this.exportExcel(this.reportDashboardExportToExcel, 'ClosingChecklistFormS24ApprovalReport');
    
  }
  // public exportExcel(jsonData: any[], fileName: string): void {

  //   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
  //   const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  //   const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   this.saveExcelFile(excelBuffer, fileName);
  // } 
  public exportExcel(jsonData: any[], fileName: string): void {
    jsonData.forEach(x=>{
      delete x.isSelected;
    })
    jsonData = jsonData.map(doc => Object.values(doc));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    for (let i = 1; i <= this.reportDashboardExportToExcel.length; i++) {
      let cell_address = {c: 1, r: i}; // 0 is the first column (ID), i is the row number
      if (!worksheet[XLSX.utils.encode_cell(cell_address)]) {
        worksheet[XLSX.utils.encode_cell(cell_address)] = {};
      }
      worksheet[XLSX.utils.encode_cell(cell_address)].l = { Target: AppSettings.ClosingCheckListForm + '?id=' + this.reportDashboardExportToExcel[i - 1].Link.split("=")[1], Tooltip: `Click to open ${this.reportDashboardExportToExcel[i - 1].Link.split("=")[1]}` };
      
    }
  
    worksheet['A1'].v = "Engagement ID";
    worksheet['B1'].v = "Link";
    worksheet['C1'].v = "Engagement Name";
    worksheet['D1'].v = "S24 Approval Status";
    worksheet['E1'].v = "S24 Name";    
    worksheet['F1'].v = "S24 Approval Date"; 
  
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, 'Result_export_' + new Date().getTime() + ".xlsx");
    this.reportDashboardExportToExcel=[];

    // const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([{A:1,B:2},{B:2,C:3}], {header:['C']})
    //  //XLSX.utils.json_to_sheet(jsonData,{header:Header});
    // const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    // const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // this.saveExcelFile(excelBuffer, fileName);
  }
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
  openClosingCheckListForm(id: any){
    //this.getFormUrl(AppSettings.closingCheckList);
    //this.openNewTab(this.getFormUrl(AppSettings.closingCheckList) + '#/main?item_id=' + id);
    this.openNewTab(AppSettings.ClosingCheckListForm+'?id=' + id);
  }
  getFormUrl(form: string): string {
    
      return `${AppSettings.closingCheckListBaseUrl}/Pages/${form+environment.closingCheckListSubDomain}.aspx`;
  }
  openNewTab(url: string) {
    window.open(url);
  }

  public onSearchResult(result:any,dashbaordInitialData:any){

this.initialReportDashboardListForSearch=dashbaordInitialData;

    if(!this._isSomeFilterApply())
        this.onSearchApplyOnFilteredData = [];
        
  // Customised search Functionality
   this.getSearchResults(result == "" ? "ALL" : result);
  }

  getSearchResults(searchValue:any){
    this.searchTerm = searchValue
    let searchArray:any=[];
    this.searchResults=[];
    if(searchValue=="ALL"&&!this.filterApply){
      searchArray=this.initialReportDashboardListForSearch
    }
    else{
      if(this.onSearchApplyOnFilteredData.length>0){
        if(searchValue=="ALL"){
         searchArray=this.onSearchApplyOnFilteredData;
        }
        else{
        // searchArray=this.onSearchApplyOnFilteredData.filter(x=>x.projectName.toLowerCase().includes(searchValue.toLowerCase()));
        searchArray = this.onSearchApplyOnFilteredData.filter((item:any) => {
          // Apply filter conditions on each column
          return (
            item.engagementName.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        }
      }
      else{
        // searchArray=this.initialReportDashboardListForSearch.filter(x=>x.projectName.toLowerCase().includes(searchValue.toLowerCase()));
        searchArray = this.initialReportDashboardListForSearch.filter((item:any) => {
          // Apply filter conditions on each column
          return (
            item.engagementName.toString().toLowerCase().includes(searchValue.toLowerCase())
          );
        });
      }
      
    }
   this.searchResults=searchArray;
    this.setReportDashboard(searchArray, this.pageSizeListValues);
  }

  setReportDashboard(
    reportDashboardList: Array<ReportDashboardItem>,
    pageSizeListValues: Array<any>) {
      pageSizeListValues = [{ id: 0, value: "All" },{ id: 1, value: 10 }, { id: 2, value: 25 }, { id: 3, value: 50 }, { id: 4, value: 100 }]; //added for pagination
      let filteredReportsList = this._filterClosedDeals(reportDashboardList);
      // this.initialReportDashboardList = reportDashboardList;
      const sortData = this.reportDashboardServices.state.sortData;
      const filterData = this.reportDashboardServices.state.filterDataList;
      filteredReportsList = this._getEngagementsMatchsFilterCriteria(filteredReportsList, filterData);
      this.reportDashboards = this.reportDashboardServices.sort(
          sortData.sortColumn, sortData.sortDirection, reportDashboardList);
      this._updateSortArrow(sortData.sortColumn, sortData.sortDirection);
      this._setReportsDashboardToCurrentPage();
      this._setPaginationValues(pageSizeListValues);
      this.pageDropdownDisable = this.reportDashboards.length > 0 ? false : true;

  }
  private _getEngagementsMatchsFilterCriteria(
    ReportDashboard: Array<ReportDashboardItem>,
    filterList: Array<EngagementTableFilterItem>): Array<ReportDashboardItem> {
    let newReportDashboardList: Array<ReportDashboardItem> = [];
    if (filterList.length > 0) {
      ReportDashboard.forEach(eng => {
        if (this._isEngagementMatchFilterCriteria(eng, filterList)) {
          newReportDashboardList.push(eng);
        }
      });
    } else {
      newReportDashboardList = ReportDashboard;
    }
    return newReportDashboardList;
  }
  private _isEngagementMatchFilterCriteria(
    ReportDashboard: ReportDashboardItem | any,
    filterList: Array<EngagementTableFilterItem>): boolean {
    let match = true;
    filterList.forEach(filter => {
      const field = filter.field;
      const values = filter.valueList;
      if (values.length && !values.find(v => v === ReportDashboard[field].toString())) {
        match = false;
        return false;
      }
      return match;
    });
    return match;
  }
  private _filterClosedDeals(reportsList: Array<ReportDashboardItem>): Array<ReportDashboardItem> {
    let filteredreportsList;
    filteredreportsList = reportsList;
    
    return filteredreportsList;
  }
  private _updateSortArrow(sortColumn: string, sortDirection: SortDirection) {
    this._resetSortIcons(sortColumn);
    this._showSortDirectionArrow(sortColumn, sortDirection);
  }
  
  // private _setPaginationValues(values:any) {
  //   this.pageSizeListValues = values.map((element:any) => {
  //     const parseValue = element.toString();
  //     const dropdownItem: DropdownItem = {
  //       key: parseValue,
  //       value: parseValue  + ' items per page'
  //     };
  //     return dropdownItem;
  //   });
  //   if (this.pagination) {
  //     this.pagination.setPaginationValues(this.pageSizeListValues);
  //   }
  // }

  private _setPaginationValues(values:any) {
    this.pageSizeListValues = values.map((element: any) => {
      const parseValue = element.value.toString();
      const dropdownItem: DropdownItem = {
        key:'',
        value:''
      };
      if(parseValue=="All"){
         dropdownItem.key=parseValue,
         dropdownItem.value=parseValue+' Items'
      
      }
      else{
        dropdownItem.key=parseValue,
        dropdownItem.value=parseValue+' items per page'
    }
      return dropdownItem;
    });
    this.selectedPageSize = this.selectedPageSize == undefined ? AppSettings.pageSizeDefault : this.selectedPageSize;

  }
  sortTable({column}: SortEvent) {
    this.reportDashboardPageDetails.length=0;
    const newDirection = this._getSortDirection(column);
    this._resetSortIcons(column);
    this.reportDashboards = this.reportDashboardServices.sort(column, newDirection, this.reportDashboards);
    this._setReportsDashboardToCurrentPage();
    this._setSortOptions(column, newDirection);
    this.reportDashboardExportToExcel=[] ;
    this.reportDashboardServices.ExportData=[];   
    this.showDisable=true;
                this.reportDashboardExportToExcel.length=0;
                const checkbox:any = document.getElementById(
                  'chk_MultiCheckBox',
                ) as HTMLInputElement | null;
                checkbox.checked = false;
              this.reportDashboards.forEach(v => {
                if(v.isSelected==true)
                 v.isSelected=false;
              });
  }
  private _getSortDirection(column: string): SortDirection {
    const header = this._getHeader(column);
    return header.direction === 'asc' ? 'desc' : 'asc';
  }
  isFilterApply(field: string) {
    let filterApply = false;
    this.filterList.forEach(filter => {
      if (filter.field === field && filter.valueList.length) {
        filterApply = true;
      }
    });
    return filterApply;
  }
    clearFilters(){
        if (this.targetFilter) {
            this.targetFilter.valueList = [];
            this._updateReportList();
            this.showDisable=true;
            this.reportDashboardExportToExcel.length=0;
            const checkbox:any = document.getElementById(
              'chk_MultiCheckBox',
            ) as HTMLInputElement | null;
            checkbox.checked = false;
          this.reportDashboards.forEach(v => {
            if(v.isSelected==true)
             v.isSelected=false;
          });
          this.reportDashboardPageDetails.length=0

          }
    }
    clearAllFilters(fromsearch:any) {
        this._resetSortIcons();
        this.filterList = [];
        this.targetField = null;
        this.reportDashboardExportToExcel.length = 0;
       this.reportDashboardPageDetails.length=0;
       this.reportDashboardServices.ExportData=[];
        const checkbox:any = document.getElementById(
          'chk_MultiCheckBox',
        ) as HTMLInputElement | null;
        checkbox.checked = false;
        this.showDisable=true;
        if( ((this.searchTerm==undefined || this.searchTerm.trim()=="" || this.searchTerm=="ALL") && (this.initialReportDashboardListForSearch.length == 0)) )
          {
        this.reportDashboards= this.initialReportDashboardList;
      }else{
        this.reportDashboards= this.initialReportDashboardListForSearch;
      }
        this.reportDashboards.forEach(v => {
           v.isSelected=false;
        });
        if(fromsearch===true)
        {
        this.commonHelperService.searchTerm = '';
        }
        this.searchTerm="";
        this.reportDashboardServices.state.sortData = {sortColumn: 'engagementId', sortDirection: 'desc'};
        this.reportDashboardServices.filterDataList = [];
        this.reportDashboardServices.state.page = 1;
        this.selectedPageSize = this.reportDashboardServices.state.pageSize = AppSettings.pageSizeDefault;
        // this.showDealClosedState.emit(false);
       // this.currentEngagementList = this._filterClosedDeals(false, this.initialEngagementsList);
        //this.reportDashboards = this._filterClosedDeals(this.initialReportDashboardList);
        this._updateSortArrow(this.reportDashboardServices.state.sortData.sortColumn, this.reportDashboardServices.state.sortData.sortDirection);
        this._setReportsDashboardToCurrentPage();
        this.onSearchApplyOnFilteredData = [];
        this.searchResults=[];
    }
    private _updateReportList() {
        this.reportDashboards = this._isSomeFilterApply() ?
          this._getReportsMatchsFilterCriteria(this.initialReportDashboardList, this.filterList) :
          this.initialReportDashboardList;
    
        const sortData = this.reportDashboardServices.state.sortData;
        this.reportDashboards = this.reportDashboardServices.sort(
          sortData.sortColumn, sortData.sortDirection, this.reportDashboards);
        this._setReportsDashboardToCurrentPage();
      }
    openFilterPopover(field: string) {
        this.targetField = field;
        this.targetFilter = this._getFilter(field);
        var currentList=[];
          if(this.searchTerm.trim()=="" || this.searchTerm==undefined || this.searchTerm=="ALL"){
            if(this.initialReportDashboardListForSearch.length > 0)
              {
              currentList=this.initialReportDashboardListForSearch;
            }else{
              currentList=this.initialReportDashboardList;
            }
            if(this.targetField=="engagementId")
              {
              this.fieldOptionValues = this._mapReportDashboardListWithFilterList(currentList, field).sort((a:any,b:any)=>b-a);
              }
           else{
            this.fieldOptionValues = this._mapReportDashboardListWithFilterList(currentList, field).sort();
               }
             
          }
          else{
            var fieldoptions= this.searchResults.filter((x:any)=>x.engagementName.toLowerCase().includes(this.searchTerm.toLowerCase()));
            if(this.targetField=="engagementId")
              this.fieldOptionValues = this._mapReportDashboardListWithFilterList(fieldoptions, field).sort((a:any,b:any)=>b-a);
            else
              this.fieldOptionValues = this._mapReportDashboardListWithFilterList(fieldoptions, field).sort();
        
          }
    }
    private _getFilter(field: string): ReportDashboardTableFilterItem | any {
        return this.filterList.length ? this.filterList.filter(f => f.field === field)[0] : null;
    }
    private _mapReportDashboardListWithFilterList(
        listReports: Array<ReportDashboardItem>,
        field: string): Array<string> {
        const arrayOptionsList:any = [];
        if(field=='s24ApprovalDate')
        {
          listReports=this.reportDashboardServices.sort('s24ApprovalDate', 'asc', listReports);
        }
        listReports.forEach((report:any) => {
          const value = report[field];
          if (this._isValidValue(arrayOptionsList, value, field, listReports) && report[field]!=undefined) {
            arrayOptionsList.push(report[field]);
          }
        });
        
        
       
        return arrayOptionsList;
    }
    private _isValidValue(
        arrayOptionsList: Array<string>,
        value: string,
        field: string,
        listreports: Array<ReportDashboardItem>): boolean {
          return (value !== '' && value !== null &&
                !this._inArray(arrayOptionsList, value) &&
                this._existReportForValue(listreports, field, value));
    }
    private _inArray(optionFields: Array<string>, option: string): boolean {
        return Boolean(optionFields.find(o => o === option));
    }
    private _existReportForValue(
        listReports: Array<ReportDashboardItem>,
        field: string,
        value: string): boolean {
        const filterArrayClone = [...this.filterList];
        const filterRemovedCurrentTarget = filterArrayClone.filter(f => f.field !== field);
        const filteredValues = this._getReportsMatchsFilterCriteria(listReports, filterRemovedCurrentTarget);
        const searchValue = filteredValues.find((v:any) => v[field] === value) ? true : false;
        return searchValue;
    }
    private _getReportsMatchsFilterCriteria(
        reports: Array<ReportDashboardItem>,
        filterList: Array<ReportDashboardTableFilterItem>): Array<ReportDashboardItem> {
        let newReportList: Array<ReportDashboardItem> = [];
        if (filterList.length > 0) {
            reports.forEach(rep => {
            if (this._isReportMatchFilterCriteria(rep, filterList)) {
                newReportList.push(rep);
            }
          });
          
        } else {
            newReportList = reports;
        }
       
        return newReportList;
    }
    private _isReportMatchFilterCriteria(
        report: ReportDashboardItem | any,
        filterList: Array<ReportDashboardTableFilterItem>): boolean {
        let match = true;
        filterList.forEach(filter => {
          const field = filter.field;
          const values = filter.valueList;
          if(values!=undefined &&  report[field]!=undefined)
          { 
          if (values.length && !values.find(v => v === report[field].toString())) {
            match = false;
            return false;
          }
        }
        return true; //added to remove error (Not all code paths return a value.)
        });
        return match;
    } 
    onChangePaginationValues(paginationValues: PaginationValues) {
        this.reportDashboardServices.state.page = paginationValues.page;
        this.reportDashboardServices.state.pageSize = paginationValues.pageSize;
        this._setReportsDashboardToCurrentPage();
        this.setMultiSelectedCheckBoxPages(paginationValues.page,paginationValues.pageSize);
        // When selected the multiple selected text box,
        //  only the forms specific to the particular page must be selected,
        //   even if the items per page increased, 
        //   it should select only the forms in that relevant page.
        //    If the user navigates to the other page , 
        //    no forms should have to be selected until that page multi select is clicked .
        //     Until the relevant page is not deselected , 
        //     it should remain selected only.
        //      The following selected files should be able to exported in the excel sheet

    }
    private setMultiSelectedCheckBoxPages(pageNumber:any,pageSize:any) {
     
      if(this.reportDashboardPageDetails.length>0)
      {
        if(this.reportDashboardPageDetails.find(s => s.PageSize!=pageSize && s.IsMultiSelect==true))
        {
          this.reportDashboardExportToExcel.length=0;
        }
        this.reportDashboardPageDetails.forEach(s => {
          if(s.IsMultiSelect==true && s.PageSize!=pageSize)
          {
            s.PageSize=pageSize
            const page = s.PageNumber;
            const pageSize1 = s.PageSize;
            this.reportDashboardsPerPage =
            this.reportDashboards.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
            this.reportDashboardsPerPage.forEach(v => {
              if(!this.reportDashboardExportToExcel.find(e => e.EngagementId == v.engagementId))
              {
                let reportDashboardItem = {
                  EngagementId: v.engagementId,
                  EngagementName: v.engagementName,
                  S24ApprovalStatus: v.isS24ApprovalComplete,
                  S24Name: v.nameOfS24,
                  S24ApprovalDate :v.s24ApprovalDate
              };
              this.reportDashboardExportToExcel.push(reportDashboardItem);
              }
        });
        this.reportDashboards.forEach(v => {
          if(this.reportDashboardExportToExcel.find(e => e.EngagementId === v.engagementId))
          {
           v.isSelected=true;
          }
          else
          {
            v.isSelected=false;
          }
        });
          }
           
        });
        this._setReportsDashboardToCurrentPage();
      }
      const checkbox:any = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
      checkbox.checked = false;
      if(this.reportDashboards.find(e => e.isSelected == true))
      {
      this.reportDashboardPageDetails.forEach(v => {
        if(v.IsMultiSelect==true && v.PageNumber==pageNumber)
        {
          checkbox.checked = true;
        }
      });
    }

    }
    private _setReportsDashboardToCurrentPage() {
        const page = this.reportDashboardServices.state.page;
        const pageSize = this.reportDashboardServices.state.pageSize;
        this.reportDashboardsPerPage =
          this.reportDashboards.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    }
    sortTableOnFilterModal(column: string, direction: SortDirection) {
        this._resetSortIcons(column);
        this._showSortDirectionArrow(column, direction);
        this._setReportsDashboardToCurrentPage();
        this.reportDashboards = this.reportDashboardServices.sort(column, direction, this.reportDashboards);
        this._setSortOptions(column, direction);
       
    }
    private _setSortOptions(column: string, direction: SortDirection) {
        const sortItem: ReportDashboardSort = {
          sortColumn: column,
          sortDirection: direction
        };
        this.reportDashboardServices.sortData = sortItem;
    }
    private _resetSortIcons(column?: string) {
        this.headers.forEach(header => {
          if ((column && header.sortable !== column) || !column) {
            header.direction = '';
          }
        });
    }
    private _showSortDirectionArrow(sortColumn: string, sortDirection: SortDirection) {
        const header = this._getHeader(sortColumn);
        header.direction = sortDirection;
        header.rotate();
    }
    isChecked(value: string): boolean {
        let checked = false;
        if (this.targetFilter) {
          this.targetFilter.valueList.forEach(v => {
            if (v ===  value.toString()) {
              checked = true;
            }
          });
        }
        return checked;
    }
    private _getHeader(column: string): NgbdSortableHeaderDirective {
        return this.headers.filter(h => h.sortable === column)[0];
    }
    onClickFilterCheckbox(event:any) {
        const checkbox = event.currentTarget;
        const valueSelected = checkbox.id;
        const isChecked = checkbox.checked;
    
        this._updateFilterList(valueSelected, isChecked);
        this._updateReportDashboardList();

    }
    private _updateReportDashboardList(){
      this.onSearchApplyOnFilteredData=[];

      if(this.searchResults.length > 0)
        {
          this.initialReportDashboardList=this.searchResults;
        }
            this.reportDashboards = this._isSomeFilterApply() ?
              this._getReportsMatchsFilterCriteria(this.initialReportDashboardList, this.filterList) :
              this.initialReportDashboardList;        
            const sortData = this.reportDashboardServices.state.sortData;
            this.reportDashboards = this.reportDashboardServices.sort(
              sortData.sortColumn, sortData.sortDirection, this.reportDashboards);
            this._setReportsDashboardToCurrentPage();
            if(this.initialReportDashboardListForSearch.length!=this.reportDashboards.length)
              this.onSearchApplyOnFilteredData=this.reportDashboardsPerPage;
    }
    private _isSomeFilterApply() {
        let filterApply = false;
        this.filterList.forEach(filter => {
          if (filter.valueList.length) {
            filterApply = true;
          }
        });
        return filterApply;
    }
    
    private _updateFilterList(valueSelected: string, isChecked: string) {
        const existFilter = this._getFilter(this.targetField);
        if (isChecked) {
          this._addFilterItem(existFilter, valueSelected);
        } else {
          this._removeFilterItem(existFilter, valueSelected);
        }
        this.showDisable=true;
          this.reportDashboardExportToExcel.length=0;
          const checkbox:any = document.getElementById(
            'chk_MultiCheckBox',
          ) as HTMLInputElement | null;
          checkbox.checked = false;
          this.reportDashboardPageDetails.length=0;
        this.reportDashboards.forEach(v => {
          if(v.isSelected==true)
           v.isSelected=false;
        });
        this._setTargetFilter(existFilter);
    }
    
      private _setTargetFilter(existFilter: ReportDashboardTableFilterItem) {
        this.targetFilter = existFilter;
        this.reportDashboardServices.filterDataList = this.filterList;
    
    }
    private _addFilterItem(existFilter: ReportDashboardTableFilterItem, valueSelected: string) {
        if (existFilter) {
          existFilter.valueList.push(valueSelected);
        } else {
          const newFilter = this._createFilter(valueSelected);
          this.filterList.push(newFilter);
          existFilter = newFilter;
        }
    }
    
      private _removeFilterItem(existFilter: ReportDashboardTableFilterItem, valueSelected: string) {
        if (existFilter) {
          existFilter.valueList = this._removeFilter(existFilter.valueList, valueSelected);
        }
    }
    private _removeFilter(valueList: Array<string>, valueSelected: string): Array<string> {
        return valueList.filter(v => v !== valueSelected);
    }
    private _createFilter(valueSelected: string): ReportDashboardTableFilterItem {
        const newFilter = {
          field: this.targetField,
          valueList: [valueSelected]
        };
        return newFilter;
    }

    onPageSizeValueChange(event: any) {
      if(this.selectedPageSize=="All")
      {
      const values: PaginationValues = {
        page: this.reportDashboardServices.state.page,
        pageSize: parseInt(this.reportDashboards.length.toString())
      };
      this.onChangePaginationValues(values);
    }
    else{
      const values: PaginationValues = {
        page: this.reportDashboardServices.state.page,
        pageSize: parseInt(this.selectedPageSize)
      };
      this.onChangePaginationValues(values);
    }
    }

    getModalStyles()
   {  
    var width= "96%";
    var marginleft= "3%";
    var margintop= "1%"; 
    if(this.reportDashboards?.length==0){
      var height= "10vh";
    }
    else{
      var height= "58vh";
    }   
    return {'width':width,'height':height,"margin-left":marginleft,"margin-top":margintop};
   }
}
