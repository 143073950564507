import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { NAFormatterService } from "../shared/utils/utils-handlingNA";
import { DropdownItem } from "../../../shared/models/dropdown-item";
import { DropdownService } from "../../../shared/services/dropwdown.service";
import { SecurityService } from "../../../shared/services/security.service";
import { TooltipHelperService } from "../../../shared/services/tooltip-helper.service";
import { AppSettings } from "../../../shared/app-settings";
import { FormListFields } from "../shared/models/form-list-fields";
import { FormValidations } from "../../../shared/utils/form-validations";
import { TooltipObject } from "../../../shared/models/tooltip-object";

@Component({
    selector: 'app-buyer-deal-marketing',
    templateUrl: './buyer-deal-marketing.component.html',
    styleUrls: ['./buyer-deal-marketing.component.scss']
  })
export class BuyerDealMarketingComponent implements OnInit {
  sharePointMapper = new ComplianceSharepointMapper();
  buyerDealMarketingFormGroup: any;
  valuationMetricsFormGroup: FormGroup;
  tooltipMessage = 'Enter "N/A" if not relevant.';
  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;
  @Output() buyerTypeValue = new EventEmitter<string>();
  myDealFormGroup: any;
  isDisabled:boolean = false;
  notApplicableEnabled : boolean;
  buyerTypeOptions: Array<DropdownItem> = AppSettings.buyerTypeOptions;
  buyerRelationshipOptions: Array<DropdownItem> = AppSettings.buyerRelationshipOptions;
  marketProcessTypeOptions: Array<DropdownItem> = AppSettings.marketProcessTypeOptions;
  closingCheckListCountryOptions: Array<DropdownItem> = [];

  constructor(private dropdownService: DropdownService,private tooltipHelperService: TooltipHelperService,
    private securityService:SecurityService,private naFormatService:NAFormatterService) { }
  ngOnInit(){
      this.buyerDealMarketingFormGroup = this.formGroups[0];
      this.myDealFormGroup = this.formGroups[1];
      this.valuationMetricsFormGroup = this.formGroups[2];
      this.notApplicableEnabled = true;       

    // this.dropdownService.fillDropdownOptions_ClosingCheckList(AppSettings.closingChecklistForm, FormListFields.buyerType)
    //   .subscribe(response => {       
    //     this.buyerTypeOptions = response;
    //   })

    // this.dropdownService.fillDropdownOptions_ClosingCheckList(AppSettings.closingChecklistForm, FormListFields.buyerRelationship)
    //   .subscribe(response =>{
    //     this.buyerRelationshipOptions = response; 
    //   })

    // this.dropdownService.fillDropdownOptions_ClosingCheckList(AppSettings.closingChecklistForm, FormListFields.marketingProcessType)
    //   .subscribe(response =>{
    //     this.marketProcessTypeOptions = response;
    //   })

      this.dropdownService.fillLookupOptionsCountries(AppSettings.closingCheckListCountries, 'Title')
      .subscribe((response:any) =>{
        this.closingCheckListCountryOptions = response;
           })
 //this.closingCheckListCountryOptions =  this.dropdownService.countriesList;
    
      this._formData();

  }
  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  //toggling selection
  onInternationalBuyer(event:any){
    this.buyerDealMarketingFormGroup.controls.internationalBuyer.setValue(event);
    this.evaluateOnInternationalBuyer(event);
  }

  onIdentifiedTierPitch(event: any)
  {
    this.buyerDealMarketingFormGroup.controls.identifiedTierPitch.setValue(event);
  }

  onDeloitteLCSP(event: any)
  {
    this.buyerDealMarketingFormGroup.controls.deloitteLCSP.setValue(event);
  }
  
  onPreemtiveBidder(event: any)
  {
    this.buyerDealMarketingFormGroup.controls.preemptiveBidder.setValue(event);
    this.evaluateOnPreemtiveBidder(event);
  }
  onPreemptiveFinalPurchaser(event:any) {
    this.buyerDealMarketingFormGroup.controls.preemptiveFinalPurchaser.setValue(event);
  }
  //dropdown selection
  onBuyerTypeSelection(event:any) {
    this.buyerDealMarketingFormGroup.controls.buyerType.setValue(event.key);
    this.buyerTypeValue.emit(event.key);
  }

  onBuyerCountrySelection(event: any)
  {
    this.buyerDealMarketingFormGroup.controls.buyerCountry.setValue(event.key);
  }

  onBuyerRelationshipSelection(event: any)
  {
    this.buyerDealMarketingFormGroup.controls.buyerRelationship.setValue(event.key);
  }

  onMarketingProcessSelection(event: any)
  {
    this.buyerDealMarketingFormGroup.controls.marketingProcessType.setValue(event.key);
  }

  //evolation on YES/No Selection
  evaluateOnInternationalBuyer(value: string) {
    if (value === 'YES') {
      this.buyerDealMarketingFormGroup.controls.buyerCountry.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.buyerCountry));
    }
  }

  evaluateOnPreemtiveBidder(value:string){
    if(value === 'YES'){
      this.buyerDealMarketingFormGroup.controls.preemptiveFinalPurchaser.enable();
      this.buyerDealMarketingFormGroup.controls.preemptiveOffer.enable();
    }else{
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.preemptiveFinalPurchaser));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.preemptiveOffer));
    }
  }
  evaluateDealType(value: any)
  {
    let obj= AppSettings.dealMarketingObject;
    if(value === AppSettings.strategicAdvisory){
      for( var i=0; i<obj.length; i++){
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(obj[i]));
      }
    }
    else{
      for( var i =0; i<obj.length; i++){
        this._setEnabled(this.sharePointMapper.getFormControlColumnName(obj[i]));
      }
      if(this.buyerDealMarketingFormGroup.get('preemptiveBidder')?.value != null)
      this.evaluateOnPreemtiveBidder(this.buyerDealMarketingFormGroup.get('preemptiveBidder')?.value);
      this.evaluateOnInternationalBuyer(this.buyerDealMarketingFormGroup.get('internationalBuyer')?.value);
    }
  }

  onSelectedValue(event: any, columnName: any){
    this.buyerDealMarketingFormGroup.controls[columnName].setValue(event);
    this._formData();
  }
  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  private _setAsDisabled(formControlName: string) {
    let controlName;
    if(formControlName == "buyerName"){
       controlName = this.myDealFormGroup.get(formControlName);}
      else{
       controlName = this.buyerDealMarketingFormGroup.get(formControlName);}
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _setEnabled(formControlName: string){
    let controlName;
    if(formControlName == "buyerName")
       controlName = this.myDealFormGroup.get(formControlName);
      else
       controlName = this.buyerDealMarketingFormGroup.get(formControlName);
    if (controlName) {
      controlName.enable();
    }
  }
  private _formData(){
    let approachedTotal=0;
    let sentCIMTotal=0;
    let submittedIOITotal=0;
    let attendedMPTotal=0;
    let submittedLOITotal=0;
    let premiumOverInitialOffer=0;
    
    approachedTotal = Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('approachedUSStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('approachedIntlStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('approachedPEGNoPortCo')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('approachedPEG')?.value));
    sentCIMTotal = Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('sentCIMUSStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('sentCIMIntlStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('sentCIMPEGNoPortCo')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('sentCIMPEG')?.value));
    submittedIOITotal = Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('submittedIOIUSStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('submittedIOIIntlStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('submittedIOIPEGNoPortCo')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('submittedIOIPEG')?.value));
    attendedMPTotal = Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('attendedMPUSStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('attendedMPIntlStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('attendedMPPEGNoPortCo')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('attendedMPPEG')?.value));
    submittedLOITotal = Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('submittedLOIUSStrategic')?.value)) +      
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('submittedLOIIntlStrategic')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('submittedLOIPEGNoPortCo')?.value)) + 
        Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('submittedLOIPEG')?.value));
    premiumOverInitialOffer = (Number(this.naFormatService.formatNA(this.valuationMetricsFormGroup.get('totalConsideration')?.value)) - 
          Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('preemptiveOffer')?.value)))/ (Number(this.naFormatService.formatNA(this.buyerDealMarketingFormGroup.get('preemptiveOffer')?.value))) * 100;
    this.buyerDealMarketingFormGroup.controls.approachedTotal.setValue(Math.round(approachedTotal).toString());
    this.buyerDealMarketingFormGroup.controls.sentCIMTotal.setValue(Math.round(sentCIMTotal).toString());
    this.buyerDealMarketingFormGroup.controls.submittedIOITotal.setValue(Math.round(submittedIOITotal).toString());
    this.buyerDealMarketingFormGroup.controls.attendedMPTotal.setValue(Math.round(attendedMPTotal).toString());
    this.buyerDealMarketingFormGroup.controls.submittedLOITotal.setValue(Math.round(submittedLOITotal).toString());
    this.buyerDealMarketingFormGroup.controls.premiumOverInitialOffer.setValue(premiumOverInitialOffer.toString());
  }
  onBuyerCount(event: any, columnName: any){    
        this.buyerDealMarketingFormGroup.controls[columnName].setValue(event);
        this._formData();
  }
  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }
  showAsterisk(formControlName: string): boolean {
    // Check if formControlName is 'buyerCountry'
    if (formControlName === 'buyerCountry') {
      // Access the buyerCountry form control
      const buyerCountryControl = this.buyerDealMarketingFormGroup.get('buyerCountry');
      // Return true if buyerCountry is enabled, false otherwise
      return buyerCountryControl ? !buyerCountryControl.disabled : false;
    }
  
    // For other form controls, use the existing logic
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }
  setFormGroup(formGroup: FormGroup) {
    this.valuationMetricsFormGroup = formGroup;
    this._formData();
  }
  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if(formControlName=="buyerCountry"&&formGroup!=null){
      if(formGroup.get(formControlName)?.disabled==false && formGroup.get(formControlName)?.value == undefined){
      formGroup.get(formControlName)?.setErrors({required:true});
      }
    }
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  hasPermission(action: string): boolean {
    return !!this.securityService.hasPermission(action);
  }
  getTooltip(tooltipTitle: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameCCL(tooltipTitle);
  }

  getTooltipText(tooltipTitle:string):string{
    const tooltip = this.getTooltip(tooltipTitle);
    return tooltip ? tooltip.getTooltipText() : '';
  }
  highlightRequiredFieldCheck(US: any, Intl: any, NoPortCo: any, PortCo: any){
    return (this.highlightRequiredField(US) || this.highlightRequiredField(Intl) || this.highlightRequiredField(NoPortCo) || this.highlightRequiredField(PortCo))
  }
  editedExternallyCheck(US: any, Intl: any, NoPortCo: any, PortCo: any){
    return (this.editedExternally(US) || this.editedExternally(Intl) || this.editedExternally(NoPortCo) || this.editedExternally(PortCo))
  }
  hasMergeConflictCheck(US: any, Intl: any, NoPortCo: any, PortCo: any){
    return (this.hasMergeConflict(US) || this.hasMergeConflict(Intl) || this.hasMergeConflict(NoPortCo) || this.hasMergeConflict(PortCo))
  }
}
