import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ModalComponent } from './components/modal/modal.component';
import { NgbdSortableHeaderDirective } from './directives/sorteable.directive';
import { PopoverComponent } from './components/popover/popover.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NumeralModule } from 'ngx-numeral';
//import { NumericDirective } from './directives/numeric.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import {NumericDirective} from './directives/numeric.directive'
import {PeoplepickerComponent} from '../shared/components/peoplepicker/peoplepicker.component'
import {PeoplepickerDynamicTableComponent} from '../shared/components/peoplepicker-dynamic-table/peoplepicker-dynamic-table.component'
import {ToggleYesNoComponent} from '../shared/components/toggle-yes-no/toggle-yes-no.component'
import {DatePickerComponent} from '../shared/components/date-picker/date-picker.component'
import {TypeaheadComponent} from '../shared/components/typeahead/typeahead.component'
import {AlertMessageComponent} from '../shared/components/alert-message/alert-message.component'
import {ReactivateFormComponent} from '../shared/components/reactivate-form/reactivate-form.component'
import {DocumentsComponent} from '../shared/components/documents/documents.component'
import {FileUploaderComponent} from '../shared/components/file-uploader/file-uploader.component'
import { SelectDynamicTableComponent } from './components/select-dynamic-table/select-dynamic-table.component';
import { NotifyPopupComponent } from './components/alerts/notify-popup/notify-popup.component';
import { NewDealNotifyPopupComponent } from './components/alerts/newdeal-notify-popup/newdeal-notify-popup.component';
import { EhoNotifyPopupComponent } from './components/alerts/eho-notify-popup/eho-notify-popup.component';
import { NumericComponent } from './components/numeric/numeric.component';
import { DealWbsNotifyPopupComponent } from './components/alerts/deal-wbs-notify-popup/deal-wbs-notify-popup.component';
import { ClosingChecklistNotifyPopupComponent } from 'src/shared/components/alerts/closing-checklist-notify-popup/closing-checklist-notify-popup.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    //NumeralModule.forRoot(),
  ],
  exports: [
   DropdownComponent,
    ModalComponent,
    PaginationComponent,
    NgbdSortableHeaderDirective,
    NumericDirective, 
    NumericDirective, 
    EhoNotifyPopupComponent,
    PeoplepickerComponent, ToggleYesNoComponent,
    SearchBoxComponent, PeoplepickerDynamicTableComponent, DatePickerComponent,
   PopoverComponent, TypeaheadComponent,AlertMessageComponent,ReactivateFormComponent,
   DocumentsComponent,FileUploaderComponent,SelectDynamicTableComponent,NotifyPopupComponent, NewDealNotifyPopupComponent,NumericComponent, 
   ClosingChecklistNotifyPopupComponent,
   DealWbsNotifyPopupComponent
  ],
  declarations: [
   DropdownComponent,
    ModalComponent, PeoplepickerComponent,
    PaginationComponent, PeoplepickerDynamicTableComponent, 
    NgbdSortableHeaderDirective,ToggleYesNoComponent, DatePickerComponent,
    SearchBoxComponent,AlertMessageComponent, ReactivateFormComponent,
    NumericDirective,DocumentsComponent, FileUploaderComponent,
    PopoverComponent, TypeaheadComponent,SelectDynamicTableComponent,NotifyPopupComponent, NewDealNotifyPopupComponent,EhoNotifyPopupComponent,NumericComponent,DealWbsNotifyPopupComponent, ClosingChecklistNotifyPopupComponent
  ],
  providers: [],
})
export class SharedModule {}
