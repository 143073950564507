<div [formGroup]="supervisorySignoffFormGroup" class="content-section" id="supervisory-signoff">
  <div class="row">
      <span class="title-section">Supervisory Signoff</span>
  </div>
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('series24ReviewedTransaction')">
            Project Series 24 certifies that they have reviewed this completed transaction
          </span>
          <span *ngIf="showAsterisk('series24ReviewedTransaction')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
          <app-toggle-yes-no  #yesNoTransaction
            [value]="supervisorySignoffFormGroup.get('series24ReviewedTransaction').value"
            [readonly]="isReadOnly('series24ReviewedTransaction')"
            (handleToggleYesNo)="onSeries24ReviewedTransactionSelection($event)">
          </app-toggle-yes-no>
      </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
    <div class="col-xs-4 margin-top-5">
      <span [class.highlight-warning]="highlightRequiredField('series24TransactionComments')">
        Comments
      </span>
      <span *ngIf="showAsterisk('series24TransactionComments')" class="red-asterik"></span>
    </div>
      <div class="col-xs-7">
        <input class="input-data-text full-width section-input"
        formControlName="series24TransactionComments" 
        id="series24TransactionComments" 
        type="text"
        [disabled]="supervisorySignoffFormGroup.get('series24TransactionComments').disabled"
        >
    </div>
    </div>
</div>
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('series24ReviewedPersonnelHours')">
            Project Series 24 has reviewed all personnel and hours charged to this Project’s WBS Code
          </span>
          <span *ngIf="showAsterisk('series24ReviewedPersonnelHours')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
          <app-toggle-yes-no  #yesNoPersonnelHours
            [value]="supervisorySignoffFormGroup.get('series24ReviewedPersonnelHours').value"
            [readonly]="isReadOnly('series24ReviewedPersonnelHours')"
            (handleToggleYesNo)="onSeries24ReviewedPersonnelHoursSelection($event)">
          </app-toggle-yes-no>
      </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
    <div class="col-xs-4 margin-top-5">
      <span [class.highlight-warning]="highlightRequiredField('series24WBSCodeComments')">
        Comments
      </span>
      <span *ngIf="showAsterisk('series24WBSCodeComments')" class="red-asterik"></span>
    </div>
      <div class="col-xs-7">
        <input class="input-data-text full-width section-input"
        formControlName="series24WBSCodeComments" 
        id="series24WBSCodeComments" 
        type="text"
        
        [disabled]="supervisorySignoffFormGroup.get('series24WBSCodeComments').disabled">
    </div>
    </div>
  </div>
</div>


