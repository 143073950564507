import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { CurrentUser } from "../../../shared/models/current-user-data";
import { DealTeamSignoffs } from "../../../shared/models/deal-team-signoffs";
import { AppSettings } from "../../../shared/app-settings";
import { DateFormatterService } from "src/shared/utils/utils-date";
import { DateService } from "src/app/services/date.service";

@Component({
  selector: "app-deal-team-signoffs",
  templateUrl: "./deal-team-signoffs.component.html",
  styleUrls: ["./deal-team-signoffs.component.scss"],
})
export class DealTeamSignoffsComponent implements OnInit {
  dealTeamSignOffs: DealTeamSignoffs[] = [];
  @Input() currentUser: CurrentUser;
  @Input() discontinuePermission: boolean;
  @Input() savePermission: boolean;
  @Input() formActionGroup:string;
  @Input() formStatus:string;
  @Input() dealTeamSignoffsSection: boolean;
  @Input() hasDealTeamSignoffApprovalAccess:boolean;
  @Output() onReturnForWork = new EventEmitter();
  @Output() onDiscontinue = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onDealTeamApproval = new EventEmitter<any>();
  constructor(private datePipe: DatePipe,private dateFormatterService: DateFormatterService,private dateService: DateService) {}
  ngOnInit() {}
  populateDealTeamSignoff(dataUsers: any, dataReviews: any, series24Data: any) {
    for (var key in dataUsers) {
      var userName = dataUsers[key].Name;
      if (userName != series24Data) {
        var role = dataUsers[key].Role;
        var userId = dataUsers[key].Email;
        var isCurrentUser = false;
        if (this.currentUser && this.currentUser.email && userId) {
          isCurrentUser = this.currentUser.email.toLowerCase() === userId.toLowerCase();
        }
        //var isCurrentUser = this.currentUser.email.toLowerCase() == userId.toLowerCase();
        var disabled = false;
        var comments = "";
        var status = false;
        var icon = "";
        var content = "";
        var isSiteAdmin = this.isClosingChecklistAdmin(this.currentUser.groups);
        var reviewForUser = this.getReviewForUser(userId, dataReviews);
        if (reviewForUser != null && reviewForUser.Comment != null) {
          disabled = true;
          comments = reviewForUser.Comment;
        }
        if (reviewForUser != null) {
          status = true;
          icon = reviewForUser.Status;
          switch (icon) {
            case "N/A":
              content = "Review N/A";
              break;
            case "Reviewed":
              content = this._formatAdminReviewDate(reviewForUser.Created);
              break;
          }
        } 
        // else if(reviewForUser != null && !isCurrentUser && !isSiteAdmin){
        //   status=true;
        //   disabled=false;
        //   comments="";
        // }
        else if (reviewForUser == null && (isCurrentUser || isSiteAdmin)) {
          status = false;
          disabled = false;
          comments = "";
        }
        else if(reviewForUser == null && !isCurrentUser && !isSiteAdmin){
          status=true;
          disabled=false;
          comments="";
        }
        const dealTeamSignoff = {
          role: role,
          userId: userId,
          userName: userName,
          icon: icon,
          content: content,
          comments: comments,
          disabled: disabled,
          status: status,
        };
        this.dealTeamSignOffs.push(dealTeamSignoff);
      }
    }
  }
  getReviewForUser(userId: any, dataReviews: any) {
    for (var review in dataReviews) {      
      if (dataReviews[review].DealTeamUser == userId) {
        return dataReviews[review];
      }
    }
    return null;
  }
  isClosingChecklistAdmin(userGroups: any[]) {
    return this.isInGroup(userGroups, AppSettings.closingCheckListAdminsGroup);
  }
  isInGroup(userGroups: any[], group: string) {
    return userGroups.includes(group);
  }
  private _formatAdminReviewDate(approvalDate: string): string {
    let dateObject = new Date(approvalDate);
    dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,approvalDate);
    return this.datePipe.transform(dateObject, "dd MMM y hh:mm a", 'CDT') + " CST";
    //Enable below for actual CST
    // const dateObject = this.dateFormatterService.formatDateOnRetrieve(approvalDate);
    // return `${this.dateService._FormatDateCCL(dateObject.toString())}`;
  }
  returnForRework() {
    this.onReturnForWork.emit();
  }
  discontinue() {
    this.onDiscontinue.emit();
  }
  save() {
    this.onSave.emit();
  }
  dealTeamApproval(dealTeamSignoff:any) {
    this.onDealTeamApproval.emit(dealTeamSignoff);
  }
}
