    <div *ngIf="series24FinalApprovalSection" id="series-24-final-approval-section" class="content-section">
        <div class="row">
            <div class="series24-section-border">
                <div>
                    <div class="approval-signature"><i>Series 24</i>
                        <div>
                            <b>
                                <span id="Series24Signature" name="Series24Signature"class="full-width signature-color">{{series24FinalApproval.series24Selected}}</span>
                            </b>
                        </div>
                        <hr class="border-hr-deal-team">
                    </div>
                    <div *ngIf="series24Signature" class="approval-date">
                        <span class="fa fa-check-circle reviewed-icon-color full-width"></span>
                        <span class="DCFDateApproval"><b>{{series24FinalApproval.series24ApprovalDate}}</b></span>
                    </div>
                    <div *ngIf="!series24Signature && approvePermission" class="approval-buttons">
                        <div class="float-right">
                            <button type="button" class="btn btn-success approve btn-approve approve-button actions-buttons" (click)="Approve()">Approve</button>
                            <button type="button" class="btn btn-outline-primary reject save-rework-cancel-btn return margin-left-10 actions-buttons return-button" (click)="ReturnForWork()">Return for rework</button>
                            <button type="button" class="btn btn-outline-primary discontinue-button save-rework-cancel-btn margin-left-10" id="discontinueButton" *ngIf="discontinuePermission" (click)="Discontinue()">Discontinue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
