export class FreeTextObject {
    DTTLInvolved: string;
    Name: string;
    PubliclyTradedEntity: string;
    TickerSymbol: string;
    RestrictedEntity: string;
    FormId: number;
    FormID:string;
    ModifiedBy:string;
    CreatedBy:string;

}
