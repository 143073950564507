import { Component, OnInit, ViewChild } from "@angular/core";
import { TerminationNotifyPopupComponent } from '../termination-form-popup/termination-form-popup.component';
import { FormControl, FormGroup, Validators,ReactiveFormsModule, ValidatorFn } from "@angular/forms";
import { TerminationFormItem } from "../models/terminationFormItem";
import { TerminationFormService } from "src/app/services/terminationform.service";
import { TerminationFormModel } from "../models/terminationFormModel";
import { ActivatedRoute } from "@angular/router";
import { FormValidations } from 'src/shared/utils/form-validations'
import { SpinnerService } from "src/shared/services/spinner.service";
import { AppSettings } from "src/shared/app-settings";
import { DatePipe } from "@angular/common";
import { DateService } from "src/app/services/date.service";
import { CustomValidators } from "src/shared/custom/custom-validators";
import { AdminService } from "src/app/services/admin.service";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { CommonHelperService } from "src/app/commonHelper/common-helper.service";
import { DropdownItem } from "src/shared/models/dropdown-item";
import { MainServiceHelper } from "src/shared/services/main-service-helper";
import { Observable, catchError, forkJoin, map } from "rxjs";
import { CurrentUser } from "src/shared/models/current-user-data";
import { ScriptLoaderService } from "src/app/services/script-loader.service";
import { GlobalHelperService } from "src/app/globalHelper/global-helper.service";
import { DateFormatterService } from "src/shared/utils/utils-date";

@Component({
    selector: 'app-termination-form',
    templateUrl: './termination-form.component.html',
    styleUrls: ['./termination-form.component.scss']
  })

export class TerminationFormComponent implements OnInit{
  rangeKey:any="";
  submitted:boolean = false;
  signatureDate:any="";
  signatureData:any=[];
  series24LeftDCF:any;
  additionalComments:any="";
  currentUser:any;
  initialSubmit:boolean=false;
  isVoluntaryTerminationReason:boolean=false;
  isTerminationReason:boolean=false;
  isThreatTeamContactReason:boolean=false;
  isOpenInternalThreatReason:boolean=false;
  isSmarshEmailComment:boolean=false;
  isRegisteredStatus: boolean=false;
  isNRFStatus: boolean=false;
  isOtherNRFStatus: boolean= false;
  NotifiedBrokerageFirms: any;
  IsNotifiedBrokerageFirmsOther:any=false;
  SentSignedCopy : any;
  signatureName:any;
  series24DisplayName:any="";
  isSaveAndUpdate:boolean=false;
  isNotifiedBrokerageFirmReason:boolean=false;
  isNotifiedBrokerageFirmDropDown:boolean=false;
  isVoluntaryTerminationReasonComments: string = '';
  isdateTheEmployeeRemovedFlowPermsReason: boolean = false;
  isDateTheNewHiresTermedComplianceReason: boolean = false;
  isdateSupportTicketOpenedReason: boolean = false;
  isterminatedIndividualRemovedEmployeeReason: boolean = false;
  s24Options: any =[];
  adGroupMember: any;
  TempName: any;
  isExportEnabled: boolean=false;
  @ViewChild(TerminationNotifyPopupComponent, { static: true }) terminationNotifyPopupComponent: TerminationNotifyPopupComponent;
  reason: any;
  SentSignedCopyNRF:any;
  formID:any;
  isTFAdmin:any=false;
  actionType:any="";
  terminationFormGroup:FormGroup;
  initialTerminationList:any;
  terminationFormAuditExcelToExport: any=[];
  ShowTrackingNumber:boolean=true;
constructor(private terminationFormService: TerminationFormService, private spinnerService: SpinnerService,private datePipe:DatePipe,private dateService:DateService,
  private adminService:AdminService, private commonHelperService: CommonHelperService,
  private mainServiceHelper: MainServiceHelper,
    private dateFormatterService: DateFormatterService,
    public globalHelperService: GlobalHelperService
) {

}

  ngOnInit(): void {  
   this.spinnerService.showAutoSaveSpinner();
   localStorage.setItem('CurrentAccessPage',"TerminationForm")
   this.globalHelperService.currentAccessPage="TerminationForm";
   this.createForm();
   this.ValidateUserAccess();
   
  }
  ValidateUserAccess() { 
  this.VerifyTerminationFormAdmin();  
  }

GetDealADGroupDetailsTF():Observable<any>{
  return this.terminationFormService.GetDealADGroupDetails().pipe(
    map((response:any)=>{
      return response;
    })
  )
}

  GetS24Users() {
    this.terminationFormService.GetS24Users().subscribe(
      {
        next: (result: any) => {
          const s24dropdownItems = result.map((item: any) => {
            const dropdownItem = new DropdownItem();
            dropdownItem.key = item.Email;
            dropdownItem.value = item.LastName+', '+item.FirstName; 
            return dropdownItem;
          }).sort((a:any,b:any)=>a.value > b.value?1:-1);
          this.s24Options = s24dropdownItems;  
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
        }
      })
  }

  onSeries24Selection(event:any) {
    this.terminationFormGroup.controls['series24LicensedSupervisor'].setValue(event.key);
    this.series24DisplayName = event.value;
    localStorage.setItem("series24EmailValue",event.value.toString());
  }

  VerifyTerminationFormAdmin() {
    let userId = localStorage.getItem("userProfileId")?.toString();
    this.adminService.GetUserRoleGroups(userId).subscribe(
      {
        next: (result: any) => {
          result = result.filter((s: any) => s.Title != null)
          let isTFAdmin = this.isInTFAdminGroup(result);
          if(isTFAdmin)
          {
            this.getAdGroup();
          }
          else
          {
            this.spinnerService.hideAutoSaveSpinner();
            this.showMsgs("termination", "InValidUser");
          }
         
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if (err.status == 403 && err.error.stringKey=="tokenexpired") {this.commonHelperService.isTokenExpired(); }
          console.log("Error" + err);
        }
      })
  }
  isInTFAdminGroup(userGroups: any) {
    let isAdmin_flag = false;
    userGroups.forEach((u: any) => {
      if (u.Role == "Termination Administrators") {
        isAdmin_flag = true;
      }
    });
    return isAdmin_flag;
  }
  LoadTFForm() {
    this.GetS24Users();
    this.BindSignature();
    this.loadForm(); 
    this.EnableExport();
  }



  BindSignatureTable(){
    this.terminationFormService.GetAuditDetails(this.formID).subscribe({
      next: res=>{
        res.sort((a:any, b:any) => new Date(b.SignatureDate).getTime() - new Date(a.SignatureDate).getTime())
        res.filter((sigData:any)=>{ (sigData.SignatureDate!=null) && 
          (sigData.SignatureName!=null) }) //filtering 

          this.signatureData = [...new Map(res.map((item:any) =>
            [item['SignatureDate'], item])).values()]; // get distinct objects by SignatureDate
          
            if(this.signatureData.length>0){
            this.signatureData =  this.signatureData.map((sign:any)=>{
              let data = {
                SignatureName: sign.SignatureName==null || sign.SignatureName==""?"-":sign.SignatureName,
                SignatureDate: sign.SignatureDate==null || sign.SignatureDate==""?"-":this.dateService.FormatDate(sign.SignatureDate)
              }
              return data;
            })
          }
      },
      error:(err:any)=>{
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err)
      }
    })
  }

  
  getTerminationFormAuditDetials(){
    this.terminationFormService.GetAuditDetails(this.formID).subscribe({
      next:(res:any)=>{
        res.forEach((e:any) => {
          e.Modified=this.dateService._AuditFormatDate(e.Modified);
        });
        this.AuditDataValidation(res);
        this.LoadAuditDataInExcel(this.terminationFormAuditExcelToExport)
        return res;
      },
      error:(err:any)=>{
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err)
      }
    })
    
  }
  LoadAuditDataInExcel(jsonData:any[]){
    if(jsonData.length > 0)
    {
      let FormId = jsonData[0].FormID;
      jsonData = jsonData.map(doc => Object.values(doc));
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      worksheet['A1'].v = "Form ID";
      worksheet['B1'].v = "Description";
      worksheet['C1'].v = "Field Name";
      worksheet['D1'].v = "Previous Value";
      worksheet['E1'].v = "Current Value";
      worksheet['F1'].v = "User Name";
      worksheet['G1'].v = "Email";
      worksheet['H1'].v = "Date";
      worksheet['I1'].v = "Time";
    
      const workbook: XLSX.WorkBook = { Sheets: { 'Termination Form Audit trail': worksheet }, SheetNames: ['Termination Form Audit trail'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
      FileSaver.saveAs(data, "TerminationForm_"+FormId +"-Audit trail.xlsx");
    }
}

  AuditDataValidation(response:any){
    this.terminationFormAuditExcelToExport = [];  
    response.forEach((v:any)=>{
    let data=this.LoadDataIntoActualObject(v);
    this.terminationFormAuditExcelToExport.push(data);
    }); 
  }

  LoadDataIntoActualObject(data:any){
    let auditObj={};
    let cstCreatedDate=data.Modified;
    let DateAndTime=cstCreatedDate.split(' ');
    return auditObj={
        FormID:data.FormID,
        Description:data.Description,
        FieldName: data.FieldDisplayName,
        PrevFieldValue:data.PrevFieldValue,
        FieldValue:data.FieldValue,
        UserName:data.ModifiedBy,
        Email:data.Email,
        Date:DateAndTime[1]+"/"+DateAndTime[0]+"/"+DateAndTime[2],
        Time:DateAndTime[3]+" "+DateAndTime[4]+" "+DateAndTime[5]
      }
    }

  
  EnableExport(): any{
    if (this.isExportEnabled == true || this.formID == undefined) return;
    this.terminationFormService.GetAuditDetails(this.formID).subscribe({
      next:(res:any)=>{
        if(res.length>0) return this.isExportEnabled = true;
        else return this.isExportEnabled = false;
      },
      error:(err:any)=>{
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err)
      }
    })
  }
  BindSignature(){
    this.signatureName= localStorage.getItem("AuditorName")?.toString();
  }

  BindSeries24DisplayName(res:any){
    this.series24DisplayName =  res.Series24 ? (res.Series24.includes("#;")?res.Series24.split("#;")[0]:""):"";
  }

  private loadForm() {
    const url = window.location.href;
      const formID = url.split('?')[1]?.split("=")[1];
      if(formID != undefined){
        this.spinnerService.showAutoSaveSpinner();
        this.formID = formID;
        this.globalHelperService.formID=this.formID.toString();
        this.terminationFormService.GetFormDetails(formID).subscribe({
          next:(res:any)=>{
            if (res.length == 0) {
              this.spinnerService.hideAutoSaveSpinner();
              this.showMsgs("termination", "InvalidForm");
              return;
            }
            this.BindSeries24DisplayName(res[0]);
            this.initialTerminationList = res[0];
            if(!res.length){
              this.spinnerService.hideAutoSaveSpinner();
            }
           this.LoadComparedObjectFields();
            this.MapToFormGroup();
            this.BindSignatureTable();
            if(this.initialTerminationList.FormStatus=="Completed"){this.actionType="Submit";}
            this.showMsgs("termination", "retrieve");
          },
          error: error=>{
            console.log("Error" + error);
            this.spinnerService.hideAutoSaveSpinner();
          }
        })
      }
      else{
        this.spinnerService.hideAutoSaveSpinner();
      }
  }

  MapToFormGroup(){
    // this.formID = 
    this.terminationFormGroup.controls['lastName'].setValue(this.initialTerminationList.LastName)
    this.terminationFormGroup.controls['firstName'].setValue(this.initialTerminationList.FirstName)
    this.terminationFormGroup.controls['dateOfTermination'].setValue(this.initialTerminationList.TerminationDate==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.TerminationDate))
    this.terminationFormGroup.controls['voluntaryTermination'].setValue(this.initialTerminationList.VoluntaryTermination)
    this.terminationFormGroup.controls['voluntaryTerminationReason'].setValue(this.initialTerminationList.VoluntaryTerminationDetail)
    this.terminationFormGroup.controls['StatusofIndividual'].setValue(this.initialTerminationList.StatusOfIndividualRegOrNRF)
    this.terminationFormGroup.controls['RegisteredStatus'].setValue(this.initialTerminationList.StatusOfIndividualSupervisory)
    this.terminationFormGroup.controls['NRFStatus'].setValue(this.initialTerminationList.StatusOfIndividualNRF)
    this.terminationFormGroup.controls['otherNRFStatusReason'].setValue(this.initialTerminationList.StatusOfIndividualNRFOther)
    this.terminationFormGroup.controls['series24LicensedSupervisor'].setValue(this.initialTerminationList.Series24?(this.initialTerminationList.Series24.includes("#;")?this.initialTerminationList.Series24.split("#;")[1]:this.initialTerminationList.Series24):this.initialTerminationList.Series24)
    this.terminationFormGroup.controls['TerminationReason'].setValue(this.initialTerminationList.TerminationReasonMatch)
    this.terminationFormGroup.controls['TerminationReasonComments'].setValue(this.initialTerminationList.TerminationReasonMatchOther)
    this.terminationFormGroup.controls['ThreatTeamContact'].setValue(this.initialTerminationList.WasThreatContacted)
    this.terminationFormGroup.controls['threatTeamContactReason'].setValue(this.initialTerminationList.WasThreatContactedOther)
    this.terminationFormGroup.controls['OpenInternalThreat'].setValue(this.initialTerminationList.InternalOpenThreat)
    this.terminationFormGroup.controls['openInternalThreatReason'].setValue(this.initialTerminationList.InternalOpenThreatDetail)
    this.terminationFormGroup.controls['dateTheEmployeeRemoved'].setValue(this.initialTerminationList.OutlookRemovedDate==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.OutlookRemovedDate))
    this.terminationFormGroup.controls['jakiePolsonNotifiedDate'].setValue(this.initialTerminationList.JakiePolsonNotifiedDate==null||this.initialTerminationList.JakiePolsonNotifiedDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.JakiePolsonNotifiedDate))
    this.terminationFormGroup.controls['jakiePolsonNotifiedDateNA'].setValue(this.initialTerminationList.JakiePolsonNotifiedDateNAValue)
    this.terminationFormGroup.controls['allowedToTermedDate'].setValue(this.initialTerminationList.AllowedToTermedDate==null||this.initialTerminationList.AllowedToTermedDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.AllowedToTermedDate))
    this.terminationFormGroup.controls['allowedToTermedDateNA'].setValue(this.initialTerminationList.AllowedToTermedDateNAValue)
    this.terminationFormGroup.controls['pstToTermedClosedDeniedDate'].setValue(this.initialTerminationList.PSTToTermedClosedDeniedDate==null||this.initialTerminationList.PSTToTermedClosedDeniedDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.PSTToTermedClosedDeniedDate))
    this.terminationFormGroup.controls['pstToTermedClosedDeniedDateNA'].setValue(this.initialTerminationList.PSTToTermedClosedDeniedDateNAValue)
    this.terminationFormGroup.controls['dateTheEmployeeRemovedDCFKnowledge'].setValue(this.initialTerminationList.DCFKCRemovedDate==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.DCFKCRemovedDate))
    this.terminationFormGroup.controls['dateTheEmployeeRemovedFlowPerms'].setValue(this.initialTerminationList.DateTheEmployeeRemovedFlowPerms==null||this.initialTerminationList.DateTheEmployeeRemovedFlowPerms==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.DateTheEmployeeRemovedFlowPerms))
    this.terminationFormGroup.controls['dateTheEmployeeRemovedFlowPermsNA'].setValue(this.initialTerminationList.DateTheEmployeeRemovedFlowPermsNAValue)
    this.terminationFormGroup.controls['dateTheEmployeeRemovedFlowPermsReason'].setValue(this.initialTerminationList.DateTheEmployeeRemovedFlowPermsDetail)
    this.terminationFormGroup.controls['dateSupportTicketOpened'].setValue(this.initialTerminationList.DateSupportTicketOpened==null||this.initialTerminationList.DateSupportTicketOpened==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.DateSupportTicketOpened))
    this.terminationFormGroup.controls['dateSupportTicketOpenedNA'].setValue(this.initialTerminationList.DateSupportTicketOpenedNAValue)
    this.terminationFormGroup.controls['dateSupportTicketOpenedReason'].setValue(this.initialTerminationList.DateSupportTicketOpenedDetail)
    this.terminationFormGroup.controls['terminatedIndividualRemovedEmployee'].setValue(this.initialTerminationList.TermedEmployeeMovedDate==null||this.initialTerminationList.TermedEmployeeMovedDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.TermedEmployeeMovedDate))
    this.terminationFormGroup.controls['terminatedIndividualRemovedEmployeeNA'].setValue(this.initialTerminationList.TermedEmployeeMovedDateNAValue)
    this.terminationFormGroup.controls['terminatedIndividualRemovedEmployeeReason'].setValue(this.initialTerminationList.TermedEmployeeMovedDateDetail)
    this.terminationFormGroup.controls['dateTheEmployeeRetired'].setValue(this.initialTerminationList.CSIRetiredDate==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.CSIRetiredDate))
    this.terminationFormGroup.controls['SmarshEmail'].setValue(this.initialTerminationList.RunSmarshEmailSpotCheck) 
    this.terminationFormGroup.controls['SmarshEmailComment'].setValue(this.initialTerminationList.RunSmarshEmailSpotCheckOther)
    this.terminationFormGroup.controls['dateTheEmployeeRemovedSupervision'].setValue(this.initialTerminationList.SupervisionGroupRemovedDate==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.SupervisionGroupRemovedDate))
    this.terminationFormGroup.controls['dateTheEmployeeDeloitteMobile'].setValue(this.initialTerminationList.SmarshTextCaptureRemovedDate==null||this.initialTerminationList.SmarshTextCaptureRemovedDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.SmarshTextCaptureRemovedDate))
    this.terminationFormGroup.controls['dateTheEmployeeDeloitteMobileNA'].setValue(this.initialTerminationList.SmarshTextCaptureRemovedDateNAValue)
    this.terminationFormGroup.controls['DateTheNewHiresTermedCompliance'].setValue(this.initialTerminationList.IdentifyIndividualTermedDate==null||this.initialTerminationList.IdentifyIndividualTermedDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.IdentifyIndividualTermedDate))
    this.terminationFormGroup.controls['DateTheNewHiresTermedComplianceNA'].setValue(this.initialTerminationList.IdentifyIndividualTermedDateNAValue)
    this.terminationFormGroup.controls['DateTheNewHiresTermedComplianceReason'].setValue(this.initialTerminationList.IdentifyIndividualTermedDateDetail)
    this.terminationFormGroup.controls['DateTheEmployeeRemovedSupervisorOrganization'].setValue(this.initialTerminationList.SupervisorOrgChartRemovalDate==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.SupervisorOrgChartRemovalDate))
    this.terminationFormGroup.controls['notifiedBrokerageFirmsDate'].setValue(this.initialTerminationList.NotifiedBrokerageFirmsDate==null||this.initialTerminationList.NotifiedBrokerageFirmsDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.NotifiedBrokerageFirmsDate))
    this.terminationFormGroup.controls['notifiedBrokerageFirmsDateNA'].setValue(this.initialTerminationList.NotifiedBrokerageFirmsNAValue)
    this.terminationFormGroup.controls['SentSignedCopy'].setValue(this.initialTerminationList.SentIndividualSignedCopy)
    this.terminationFormGroup.controls['NRF'].setValue(this.initialTerminationList.NRFTerminationFiledWithFinra)
    this.terminationFormGroup.controls['SentSignedCopyOtherText'].setValue(this.initialTerminationList.SentIndividualSignedCopyNoValue)
    this.terminationFormGroup.controls['trackingNumber'].setValue(this.initialTerminationList.TrackingNumber);   
    this.terminationFormGroup.controls['ShippingMethodUsed'].setValue(this.initialTerminationList.ShippingMethod);      
    //this.terminationFormGroup.controls['SignatureDate'].setValue(this.initialTerminationList.SignatureDate==null?"":new Date(this.initialTerminationList.SignatureDate))
      this.signatureDate=this.initialTerminationList.SignatureDate == null ? '' : this.dateService.FormatDate(new Date(this.initialTerminationList.SignatureDate).toString());
      this.signatureName = this.initialTerminationList.SignatureName;
      this.additionalComments = this.initialTerminationList.AdditionalComments;      
      this.PopulateOnchangeEvents();
      this.spinnerService.hideAutoSaveSpinner();
  }
  PopulateOnchangeEvents(){
  if(this.initialTerminationList.VoluntaryTermination=="No"){this.isVoluntaryTerminationReason = true;}
  if(this.initialTerminationList.StatusOfIndividualRegOrNRF=="Registered"){this.isRegisteredStatus = true;}
  if(this.initialTerminationList.StatusOfIndividualRegOrNRF=="NRF"){this.isNRFStatus = true;}
  if(this.initialTerminationList.StatusOfIndividualNRF=="Other"){this.isOtherNRFStatus=true;}
  if(this.initialTerminationList.TerminationReasonMatch=="No"||this.initialTerminationList.TerminationReasonMatch=="Other"){this.isTerminationReason=true;}
  if(this.initialTerminationList.WasThreatContacted=="No"||this.initialTerminationList.WasThreatContacted=="Other"){this.isThreatTeamContactReason=true;}
  if(this.initialTerminationList.InternalOpenThreat=="Yes"||this.initialTerminationList.InternalOpenThreat=="Other"){this.isOpenInternalThreatReason=true;}
  if(this.initialTerminationList.RunSmarshEmailSpotCheck=="No"){this.isSmarshEmailComment=true;}
  if(this.initialTerminationList.NotifiedBrokeragFirm=="No"){this.NotifiedBrokerageFirms="No";}
  if(this.initialTerminationList.NotifiedBrokeragFirmNoValue=="Other"){this.IsNotifiedBrokerageFirmsOther=true;}
  if(this.initialTerminationList.SentIndividualSignedCopy=="No"){this.SentSignedCopy="No";}
  if(this.initialTerminationList.NRFTerminationFiledWithFinra=="Other"){this.SentSignedCopyNRF="Other";}

  if(this.initialTerminationList.DateTheEmployeeRemovedFlowPermsNAValue=="N/A"){
    this.isdateTheEmployeeRemovedFlowPermsReason = true;
    this.terminationFormGroup.get("dateTheEmployeeRemovedFlowPerms")?.setErrors(null)
  }
  if(this.initialTerminationList.DateSupportTicketOpenedNAValue=="N/A"){
    this.isdateSupportTicketOpenedReason = true;
    this.terminationFormGroup.get("dateSupportTicketOpened")?.setErrors(null)
  }
  if(this.initialTerminationList.IdentifyIndividualTermedDateNAValue=="N/A"){
    this.isDateTheNewHiresTermedComplianceReason = true;
    this.terminationFormGroup.get("DateTheNewHiresTermedCompliance")?.setErrors(null)
  }
  if(this.initialTerminationList.TermedEmployeeMovedDateNAValue=="N/A"){
    this.isterminatedIndividualRemovedEmployeeReason = true;
    this.terminationFormGroup.get("terminatedIndividualRemovedEmployee")?.setErrors(null)
  }

  if(this.initialTerminationList.JakiePolsonNotifiedDateNAValue=="N/A – Not a PPMD or Client Facing PPMD"){
    this.terminationFormGroup.controls["jakiePolsonNotifiedDate"]?.setErrors(null)
  }
  if(this.initialTerminationList.AllowedToTermedDateNAValue=="N/A – No OBA(s)"){
    this.terminationFormGroup.get("allowedToTermedDate")?.setErrors(null)
  }
  if(this.initialTerminationList.NotifiedBrokerageFirmsNAValue=="No Accounts to Term"){
    this.terminationFormGroup.get("notifiedBrokerageFirmsDate")?.setErrors(null)
  }
  if(this.initialTerminationList.SmarshTextCaptureRemovedDateNAValue=="N/A - No Cell Phone to Term"){
    this.terminationFormGroup.get("dateTheEmployeeDeloitteMobile")?.setErrors(null)
  }
  if(this.initialTerminationList.PSTToTermedClosedDeniedDateNAValue=="N/A – No PST(s)"){
    this.terminationFormGroup.get("pstToTermedClosedDeniedDate")?.setErrors(null)
  }
  if(this.initialTerminationList.SentIndividualSignedCopy=="No"){
    this.terminationFormGroup.get("SentSignedCopy")?.setErrors(null);
    this.ShowTrackingNumber = false;
  }
}
createForm(){
  this.terminationFormGroup=new FormGroup({
    lastName:new FormControl<string>('',[Validators.required]),
    firstName:new FormControl<string>('',[Validators.required]),
    dateOfTermination:new FormControl<string>('',[Validators.required]),
    voluntaryTermination:new FormControl<string>('',[Validators.required]),
    voluntaryTerminationReason:new FormControl<string>('',[Validators.required]),
    StatusofIndividual:new FormControl<string>('',[Validators.required]),
    RegisteredStatus:new FormControl<string>('',[Validators.required]),
    NRFStatus:new FormControl<string>('',[Validators.required]),
    otherNRFStatusReason:new FormControl<string>('',[Validators.required]),
    series24LicensedSupervisor:new FormControl<string>('',[Validators.required]),
    TerminationReason:new FormControl<string>('',[Validators.required]),
    TerminationReasonComments:new FormControl<string>('',[Validators.required]),
    ThreatTeamContact:new FormControl<string>('',[Validators.required]),
    threatTeamContactReason:new FormControl<string>('',[Validators.required]),
    OpenInternalThreat:new FormControl<string>('',[Validators.required]),
    openInternalThreatReason:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemoved:new FormControl<string>('',[Validators.required]),
    jakiePolsonNotifiedDate:new FormControl<string>('',[Validators.required]),
    jakiePolsonNotifiedDateNA:new FormControl<string>('',[Validators.required]),
    allowedToTermedDate:new FormControl<string>('',[Validators.required]),
    allowedToTermedDateNA:new FormControl<string>('',[Validators.required]),
    pstToTermedClosedDeniedDate:new FormControl<string>('',[Validators.required]),
    pstToTermedClosedDeniedDateNA:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedDCFKnowledge:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedFlowPerms:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedFlowPermsNA:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedFlowPermsReason:new FormControl<string>('',[Validators.required]),
    dateSupportTicketOpened:new FormControl<string>('',[Validators.required]),
    dateSupportTicketOpenedNA:new FormControl<string>('',[Validators.required]),
    dateSupportTicketOpenedReason:new FormControl<string>('',[Validators.required]),
    terminatedIndividualRemovedEmployee:new FormControl<string>('',[Validators.required]),
    terminatedIndividualRemovedEmployeeNA:new FormControl<string>('',[Validators.required]),
    terminatedIndividualRemovedEmployeeReason:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRetired:new FormControl<string>('',[Validators.required]),
    SmarshEmail:new FormControl<string>('',[Validators.required]),
    SmarshEmailComment:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedSupervision:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeDeloitteMobile:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeDeloitteMobileNA:new FormControl<string>('',[Validators.required]),
    DateTheNewHiresTermedCompliance:new FormControl<string>('',[Validators.required]),
    DateTheNewHiresTermedComplianceNA:new FormControl<string>('',[Validators.required]),
    DateTheNewHiresTermedComplianceReason:new FormControl<string>('',[Validators.required]),
    DateTheEmployeeRemovedSupervisorOrganization:new FormControl<string>('',[Validators.required]),
    notifiedBrokerageFirmsDate:new FormControl<string>('',[Validators.required]),
    notifiedBrokerageFirmsDateNA:new FormControl<string>('',[Validators.required]),
    SentSignedCopy:new FormControl<string>('',[Validators.required]),
    NRF:new FormControl<string>('',[Validators.required]),
    SentSignedCopyOtherText:new FormControl<string>('',[Validators.required]),
    trackingNumber:new FormControl<string>('',[Validators.required]),
    ShippingMethodUsed:new FormControl<string>('',[Validators.required])
  })

}
  onVoluntaryTerminationChange(event:any){
    if(event.target.value=="No"){
      this.isVoluntaryTerminationReason = true;
      this.terminationFormGroup.controls["voluntaryTerminationReason"].setErrors({required:true});
      }
    else{
      this.isVoluntaryTerminationReason = false;
      this.formID==undefined?this.terminationFormGroup.controls["voluntaryTerminationReason"].setValue(""):this.initialTerminationList.VoluntaryTerminationDetail==null?this.terminationFormGroup.controls["voluntaryTerminationReason"].setValue(""):"";
    }
    this.terminationFormGroup.get('voluntaryTermination')?.setValue(event.target.value);
  }

  onTerminationMatchChange(event:any){
    if(event.target.value=="No" || event.target.value=="Other"){
      this.isTerminationReason = true;
      if(this.terminationFormGroup.controls["TerminationReasonComments"].value=="")
      this.terminationFormGroup.controls["TerminationReasonComments"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["TerminationReasonComments"].setValue(""):this.initialTerminationList.TerminationReasonMatchOther==null?this.terminationFormGroup.controls["TerminationReasonComments"].setValue(""):"";
      this.isTerminationReason = false;
    }
  }
  
  onDateTheNewHiresTermedComplianceChange(event:any){
    if(event.target.value=="N/A"){
      this.isDateTheNewHiresTermedComplianceReason = true;
      this.terminationFormGroup.controls['DateTheNewHiresTermedCompliance'].setValue(null);
      this.terminationFormGroup.controls["DateTheNewHiresTermedCompliance"].setErrors(null);
      if(this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].value=="")
      this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].setErrors({required:true});
      this.commonHelperService.removeInvalidDateClass();
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].setValue(""):this.initialTerminationList.IdentifyIndividualTermedDateDetail==null?this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].setValue(""):"";
      this.isDateTheNewHiresTermedComplianceReason = false;
    }
  }

  onjakiePolsonNotifiedDateChange(event:any){
    if(event.target.value=="N/A – Not a PPMD or Client Facing PPMD"){
      this.terminationFormGroup.controls['jakiePolsonNotifiedDate'].setValue(null);
      this.terminationFormGroup.controls["jakiePolsonNotifiedDate"].setErrors(null);
      this.commonHelperService.removeInvalidDateClass();
    }
  }
  onnotifiedBrokerageFirmsDate(event:any){
    if(event.target.value=="No Accounts to Term"){
      this.terminationFormGroup.controls['notifiedBrokerageFirmsDate'].setValue(null);
      this.terminationFormGroup.controls["notifiedBrokerageFirmsDate"].setErrors(null);
      this.commonHelperService.removeInvalidDateClass();
    }
  }
  
  ondateTheEmployeeDeloitteMobileChange(event:any){
    if(event.target.value=="N/A - No Cell Phone to Term"){
      this.terminationFormGroup.controls['dateTheEmployeeDeloitteMobile'].setValue(null);
      this.terminationFormGroup.controls["dateTheEmployeeDeloitteMobile"].setErrors(null);
      this.commonHelperService.removeInvalidDateClass();
    }
  }

  onallowedToTermedDateChange(event:any){
    if(event.target.value=="N/A – No OBA(s)"){
      this.terminationFormGroup.controls['allowedToTermedDate'].setValue(null);
      this.terminationFormGroup.controls["allowedToTermedDate"].setErrors(null);
      this.commonHelperService.removeInvalidDateClass();
    }
  }
  
  onpstToTermedClosedDeniedDateChange(event:any){
    if(event.target.value=="N/A – No PST(s)"){
      this.terminationFormGroup.controls['pstToTermedClosedDeniedDate'].setValue(null);
      this.terminationFormGroup.controls["pstToTermedClosedDeniedDate"].setErrors(null);
      this.commonHelperService.removeInvalidDateClass();
    }
  }
  
  ondateTheEmployeeRemovedFlowPermsChange(event:any){
    if(event.target.value=="N/A"){
      this.isdateTheEmployeeRemovedFlowPermsReason = true;
      this.terminationFormGroup.controls['dateTheEmployeeRemovedFlowPerms'].setValue(null);
      this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPerms"].setErrors(null);
      if(this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsReason"].value=="")
      this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsReason"].setErrors({required:true});
      this.commonHelperService.removeInvalidDateClass();

    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsReason"].setValue(""):this.initialTerminationList.DateTheEmployeeRemovedFlowPermsDetail==null?this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsReason"].setValue(""):"";
      this.isdateTheEmployeeRemovedFlowPermsReason = false;
    }
  }
  ondateSupportTicketOpenedChange(event:any){
    if(event.target.value=="N/A"){
      this.isdateSupportTicketOpenedReason = true;
      this.terminationFormGroup.controls['dateSupportTicketOpened'].setValue(null);
      this.terminationFormGroup.controls["dateSupportTicketOpened"].setErrors(null);
      if(this.terminationFormGroup.controls["dateSupportTicketOpenedReason"].value=="")
      this.terminationFormGroup.controls["dateSupportTicketOpenedReason"].setErrors({required:true});
      this.commonHelperService.removeInvalidDateClass();
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["dateSupportTicketOpenedReason"].setValue(""):this.initialTerminationList.DateSupportTicketOpenedDetail==null?this.terminationFormGroup.controls["dateSupportTicketOpenedReason"].setValue(""):"";
      this.isdateSupportTicketOpenedReason = false;
    }
  }
  onterminatedIndividualRemovedEmployeeChange(event:any){
    if(event.target.value=="N/A"){
      this.isterminatedIndividualRemovedEmployeeReason = true;
      this.terminationFormGroup.controls['terminatedIndividualRemovedEmployee'].setValue(null);
      this.terminationFormGroup.controls["terminatedIndividualRemovedEmployee"].setErrors(null);
      if(this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].value=="")
      this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].setErrors({required:true});
      this.commonHelperService.removeInvalidDateClass();
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].setValue(""):this.initialTerminationList.TermedEmployeeMovedDate==null?this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].setValue(""):"";
      this.isterminatedIndividualRemovedEmployeeReason = false;
    }
  }


  
  private _updateFormItem(differences:any): any {
    const objectNewDeal = differences.terminationFormDifferences;
    const newDealLength = Object.keys(objectNewDeal).length;   
    if(newDealLength==0) this.spinnerService.hideAutoSaveSpinner();     
   (objectNewDeal!=null && objectNewDeal!=undefined && newDealLength>0) ? this.updateForm(this.formID, objectNewDeal,this.rangeKey) : this.showMsgs("termination","NoUpdate");  
  }
  updateForm( formId: number, currentUpdates: any,rangeKey:any,initiateWorkflow:boolean=false): any {      
    const userEmail = localStorage.getItem("userProfileId");
    const userName = localStorage.getItem("AuditorName");
    currentUpdates.Email = userEmail;
    currentUpdates.ModifiedBy = userName;
    const data =JSON.stringify({formId:formId,updateData:this.cleanJsonObject(currentUpdates),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow});
    //const data =JSON.stringify({formId:formId,updateData:this.cleanJsonObject(currentUpdates),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,initiator:userEmail});
     this.terminationFormService.UpdateFormDetails(data).subscribe(
      {
        next: (result: any) => {
          this.formID = result[0].ID;
          if(result[0].FormStatus == "Completed")
          {
            this.isExportEnabled = true;
          }
          else{
            this.isExportEnabled = false;
          }
          this.initialTerminationList=result[0];
          this.spinnerService.hideAutoSaveSpinner();
          this.LoadComparedObjectFields();
          this.MapToFormGroup();
          if(this.submitted){
            this.BindSignatureTable();
          }
          if(this.actionType=="Submit"&&!this.isSaveAndUpdate)
          {
            this.showMsgs("termination", "SubmitSuccess");
          }
          else
          {
            this.showMsgs("termination", "UpdateSuccess");
          }
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          //this.adminService.hideSpinner();
          //if(err.status==0&&err.statusText=="Unknown Error"){this.adminService.refreshToken();}
          console.log("Error" + err);
        }
      })
  }
 LoadComparedObjectFields(){
  this.rangeKey=this.initialTerminationList.Created;
  delete this.initialTerminationList.Created;
  delete this.initialTerminationList.CreatedBy;
  delete this.initialTerminationList.Modified;
 }
  cleanJsonObject(obj:any) {
    for (const key in obj) {
      if (obj[key] === null||obj[key] === undefined) {
        obj[key] = '';
      } else if (typeof obj[key] === 'object') {
        this.cleanJsonObject(obj[key]);
      }
    }
    return obj;
  }
  private _findDifferencesBeforeSaveData() {
    const currentTerminationSetupObject = this.createFormObject();
    const terminationFormDifferences = this._getDifferences(this.initialTerminationList, currentTerminationSetupObject);
    return {terminationFormDifferences};
  }

  private _getDifferences(initialData:any, currentData:any) {
    const differences:any = {};
    for (const input in initialData) {
      if(input != "Modified" && input !="EmailSent") //Modfied,EmailSent will be added from api side
      {
        if(initialData[input]==null&&currentData[input]==""){}
        else if (initialData[input] !== currentData[input]) {
          differences[input] = currentData[input];
        }
      }
    }
    return differences;
  }

  getAdGroup(){
    forkJoin([this.GetDealADGroupDetailsTF()]). subscribe(([profile])=>{
        this.adGroupMember = profile;
        let userEmail=localStorage.getItem("userProfileId")?.toString();
        if (this._isInAdGroup(userEmail,profile.value)) {
          localStorage.setItem('adGroupMember',JSON.stringify(profile.value));
          this.LoadTFForm();
        }
        else{
          this.spinnerService.hideAutoSaveSpinner();
          this.showMsgs("termination", "InValidUser");
        }
    })

  }

  private _isInAdGroup(email:any,groups:Array<any>): boolean {

    return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
  }


  ValidateS24User():boolean{
    let valid = true;
    let series24EmailValue = this.terminationFormGroup.controls['series24LicensedSupervisor'].value;
    if(series24EmailValue){
      if (!this._isInAdGroup(series24EmailValue, JSON.parse((localStorage as any ).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)) {
        this.series24LeftDCF = true;
        valid = false;
      }
      else{
        this.series24LeftDCF = false;
      }
    }
    else{

      this.terminationFormGroup.controls['series24LicensedSupervisor'].setValue(null);
      this.series24LeftDCF = false;
    }
    return valid;
  }


  OnSaveForm(event:any){
    this.isSaveAndUpdate=this.actionType=="Submit"?false:true;
    this.spinnerService.showAutoSaveSpinner();
    if(this.ValidateS24User()){

      if(this.formID==undefined){
        this.saveForm();
      }
      else{
        this.submitted=this.initialTerminationList.FormStatus=="Completed"?true:false;
        if(this.submitted){
          this.isSaveAndUpdate=true;
          this.initialSubmit=false;
          if(this.IsTerminationFormValid()){
            let differences=this._findDifferencesBeforeSaveData();
            this._updateFormItem(differences);
          }
          else{
            this.spinnerService.hideAutoSaveSpinner();
            this.showMsgs("termination", "Info");
          }
        }
        else{
          this.isSaveAndUpdate=false;
          let differences=this._findDifferencesBeforeSaveData();
          this._updateFormItem(differences);
        }    
      }
    }
    else{
      this.spinnerService.hideAutoSaveSpinner();
      this.showMsgs("termination","user-left-dcf-member")
    }
  }
  saveForm(){
    let formObj:any = this.createFormObject();
    formObj.CreatedBy=localStorage.getItem("AuditorName")?.toString();
    this.createSaveForm(formObj);
  }

  createSaveForm(formObj:any):any{
    const userEmail = localStorage.getItem("userProfileId");
    formObj.Email = userEmail;
    this.terminationFormService.SaveFormDetails(formObj).subscribe(
      {
        next: (result: any) => {
          this.formID = result.ID;
          this.initialTerminationList=result;
          this.LoadComparedObjectFields();
          this.MapToFormGroup();
          this.spinnerService.hideAutoSaveSpinner();
          if(this.actionType=="Submit"){
            this.showMsgs("termination", "SubmitSuccess");
          }else{
            this.showMsgs("termination", "SaveSuccess");
          }      

        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          //this.adminService.hideSpinner();
          //if(err.status==0&&err.statusText=="Unknown Error"){this.adminService.refreshToken();}
          console.log("Error" + err);
        }
      })
  }



  createFormObject(): TerminationFormModel{
    var terminationFormObj:any = new TerminationFormItem();
    Object.keys(this.terminationFormGroup.controls).forEach(formControlName => {
      if (formControlName) {
        terminationFormObj[formControlName]=this.terminationFormGroup.get(formControlName)?.value==null?
        "":this.terminationFormGroup.get(formControlName)?.value;
      }
    });
    return this.MapToTerminationFormModel(terminationFormObj);
  }

  MapToTerminationFormModel(terminationformObj:TerminationFormItem): any{
    var terminationFormModel: TerminationFormModel;
     return terminationFormModel = {
      LastName:terminationformObj.lastName,
      FirstName:terminationformObj.firstName,
      TerminationDate:terminationformObj.dateOfTermination==""?"":this.datePipe.transform(new Date(terminationformObj.dateOfTermination), 'yyyy-MM-dd')?.toString(),
      TerminationReasonMatch:terminationformObj.TerminationReason,
      TerminationReasonMatchOther:terminationformObj.TerminationReason!="Yes"?terminationformObj.TerminationReasonComments:"",
      ID:this.formID==undefined?"":this.formID,
      //Created: new Date().toString(),
      //CreatedBy: localStorage.getItem("userProfileName")?.toString(),
      //Modified: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')?.toString(),
      //ModifiedBy: localStorage.getItem("userProfileName")?.toString(),
      //EmailSent: terminationformObj.emailSent,
      VoluntaryTermination: terminationformObj.voluntaryTermination,
      VoluntaryTerminationDetail:terminationformObj.voluntaryTermination=="No"?terminationformObj.voluntaryTerminationReason:"",
      StatusOfIndividualRegOrNRF: terminationformObj.StatusofIndividual,
      StatusOfIndividualSupervisory:terminationformObj.StatusofIndividual!="NRF"?terminationformObj.RegisteredStatus:"",
      StatusOfIndividualNRF:terminationformObj.StatusofIndividual=="NRF"? terminationformObj.NRFStatus:"",
      StatusOfIndividualNRFOther: terminationformObj.NRFStatus=="Other"?terminationformObj.otherNRFStatusReason:"",
      Series24: this.series24DisplayName+"#;" + terminationformObj.series24LicensedSupervisor,
      WasThreatContacted: terminationformObj.ThreatTeamContact,
      WasThreatContactedOther: terminationformObj.ThreatTeamContact!="Yes"?terminationformObj.threatTeamContactReason:"",
      InternalOpenThreat: terminationformObj.OpenInternalThreat,
      InternalOpenThreatDetail:terminationformObj.OpenInternalThreat!="No"? terminationformObj.openInternalThreatReason:"",
      OutlookRemovedDate:terminationformObj.dateTheEmployeeRemoved==""?"":this.datePipe.transform(new Date(terminationformObj.dateTheEmployeeRemoved), 'yyyy-MM-dd')?.toString(),

      JakiePolsonNotifiedDate: terminationformObj.jakiePolsonNotifiedDate==""?"":this.datePipe.transform(new Date(terminationformObj.jakiePolsonNotifiedDate), 'yyyy-MM-dd')?.toString(),
      JakiePolsonNotifiedDateNAValue: terminationformObj.jakiePolsonNotifiedDateNA,
      AllowedToTermedDate:terminationformObj.allowedToTermedDate==""?"":this.datePipe.transform(new Date(terminationformObj.allowedToTermedDate), 'yyyy-MM-dd')?.toString(),
      AllowedToTermedDateNAValue:terminationformObj.allowedToTermedDateNA,
      PSTToTermedClosedDeniedDate:terminationformObj.pstToTermedClosedDeniedDate==""?"":this.datePipe.transform(new Date(terminationformObj.pstToTermedClosedDeniedDate), 'yyyy-MM-dd')?.toString(),
      PSTToTermedClosedDeniedDateNAValue:terminationformObj.pstToTermedClosedDeniedDateNA,

      DCFKCRemovedDate:terminationformObj.dateTheEmployeeRemovedDCFKnowledge==""?"":this.datePipe.transform(new Date(terminationformObj.dateTheEmployeeRemovedDCFKnowledge), 'yyyy-MM-dd')?.toString(),

      DateTheEmployeeRemovedFlowPerms:terminationformObj.dateTheEmployeeRemovedFlowPerms==""?"":this.datePipe.transform(new Date(terminationformObj.dateTheEmployeeRemovedFlowPerms), 'yyyy-MM-dd')?.toString(),
      DateTheEmployeeRemovedFlowPermsNAValue:terminationformObj.dateTheEmployeeRemovedFlowPermsNA,
      DateTheEmployeeRemovedFlowPermsDetail:terminationformObj.dateTheEmployeeRemovedFlowPermsReason,
      DateSupportTicketOpened:terminationformObj.dateSupportTicketOpened==""?"":this.datePipe.transform(new Date(terminationformObj.dateSupportTicketOpened), 'yyyy-MM-dd')?.toString(),
      DateSupportTicketOpenedNAValue:terminationformObj.dateSupportTicketOpenedNA,
      DateSupportTicketOpenedDetail:terminationformObj.dateSupportTicketOpenedReason,

      TermedEmployeeMovedDate:terminationformObj.terminatedIndividualRemovedEmployee==""?"":this.datePipe.transform(new Date(terminationformObj.terminatedIndividualRemovedEmployee), 'yyyy-MM-dd')?.toString(),
      TermedEmployeeMovedDateNAValue:terminationformObj.terminatedIndividualRemovedEmployeeNA,
      TermedEmployeeMovedDateDetail:terminationformObj.terminatedIndividualRemovedEmployeeReason,
      
      CSIRetiredDate:terminationformObj.dateTheEmployeeRetired==""?"":this.datePipe.transform(new Date(terminationformObj.dateTheEmployeeRetired), 'yyyy-MM-dd')?.toString(),
      RunSmarshEmailSpotCheck: terminationformObj.SmarshEmail,
      RunSmarshEmailSpotCheckOther: terminationformObj.SmarshEmail!="Yes"?terminationformObj.SmarshEmailComment:"",
      SupervisionGroupRemovedDate:terminationformObj.dateTheEmployeeRemovedSupervision==""?"":this.datePipe.transform(new Date(terminationformObj.dateTheEmployeeRemovedSupervision), 'yyyy-MM-dd')?.toString(),
      SmarshTextCaptureRemovedDate:terminationformObj.dateTheEmployeeDeloitteMobile==""?"":this.datePipe.transform(new Date(terminationformObj.dateTheEmployeeDeloitteMobile), 'yyyy-MM-dd')?.toString() ,
      SmarshTextCaptureRemovedDateNAValue:terminationformObj.dateTheEmployeeDeloitteMobileNA ,

      IdentifyIndividualTermedDate:terminationformObj.DateTheNewHiresTermedCompliance==""?"":this.datePipe.transform(new Date(terminationformObj.DateTheNewHiresTermedCompliance), 'yyyy-MM-dd')?.toString(),
      IdentifyIndividualTermedDateNAValue:terminationformObj.DateTheNewHiresTermedComplianceNA,
      IdentifyIndividualTermedDateDetail:terminationformObj.DateTheNewHiresTermedComplianceReason,

      SupervisorOrgChartRemovalDate:terminationformObj.DateTheEmployeeRemovedSupervisorOrganization==""?"":this.datePipe.transform(new Date(terminationformObj.DateTheEmployeeRemovedSupervisorOrganization), 'yyyy-MM-dd')?.toString(),
      NotifiedBrokerageFirmsDate:terminationformObj.notifiedBrokerageFirmsDate==""?"":this.datePipe.transform(new Date(terminationformObj.notifiedBrokerageFirmsDate), 'yyyy-MM-dd')?.toString(),
      NotifiedBrokerageFirmsNAValue: terminationformObj.notifiedBrokerageFirmsDateNA,

      SentIndividualSignedCopy: terminationformObj.SentSignedCopy,
      SentIndividualSignedCopyNoValue:terminationformObj.NRF=="NRF"?"":terminationformObj.SentSignedCopyOtherText,
      NRFTerminationFiledWithFinra: terminationformObj.SentSignedCopy=="No"?terminationformObj.NRF:"",
      TrackingNumber: terminationformObj.trackingNumber,
      ShippingMethod: terminationformObj.ShippingMethodUsed,
      FormStatus: this.actionType=="Submit"?"Completed":"Pending",
      SignatureDate: this.actionType=="Submit"?this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')?.toString():"",
      SignatureName:this.actionType=="Submit"?localStorage.getItem("AuditorName")?.toString():"",
      AdditionalComments: this.additionalComments==undefined?"":this.additionalComments
    }
  }

  setValidators(){
    Object.keys(this.terminationFormGroup.controls).forEach(formControlName => {
      if(formControlName=="jakiePolsonNotifiedDate" || formControlName==="allowedToTermedDate"
      || formControlName==="pstToTermedClosedDeniedDate" || formControlName==="dateTheEmployeeRemovedFlowPerms" 
      || formControlName==="dateSupportTicketOpened" || formControlName=="DateTheNewHiresTermedCompliance"||
      formControlName=="terminatedIndividualRemovedEmployee" || formControlName=="notifiedBrokerageFirmsDate" || formControlName=="dateTheEmployeeDeloitteMobile") return;
      const controlValidators: ValidatorFn = CustomValidators.InputWhithoutWhiteSpaces;
        this.terminationFormGroup.get(formControlName)?.setValidators(controlValidators);
        this.terminationFormGroup.get(formControlName)?.updateValueAndValidity();
      })
  }

  IsTerminationFormValid():any{
    this.setValidators();
    if(this.terminationFormGroup.controls["voluntaryTermination"].value=="Yes"){
    this.terminationFormGroup.controls["voluntaryTerminationReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["StatusofIndividual"].value=="Registered"){
      this.terminationFormGroup.controls["NRFStatus"].setErrors(null);
      this.terminationFormGroup.controls["otherNRFStatusReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["StatusofIndividual"].value=="NRF"){
      this.terminationFormGroup.controls["RegisteredStatus"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["TerminationReason"].value=="Yes"){
      this.terminationFormGroup.controls["TerminationReasonComments"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["ThreatTeamContact"].value=="Yes"){
      this.terminationFormGroup.controls["threatTeamContactReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["OpenInternalThreat"].value=="No"){
      this.terminationFormGroup.controls["openInternalThreatReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["SmarshEmail"].value=="Yes"){
      this.terminationFormGroup.controls["SmarshEmailComment"].setErrors(null);
    }
    // if(this.terminationFormGroup.controls["notifiedBrokerageFirms"].value=="Yes"){
    //   this.terminationFormGroup.controls["noAccountsToTerm"].setErrors(null);
    //   this.terminationFormGroup.controls["notifiedBrokerageFirmsOther"].setErrors(null);
    // }
    if(this.terminationFormGroup.controls["SentSignedCopy"].value=="Yes"){
      this.terminationFormGroup.controls["NRF"].setErrors(null);
      this.terminationFormGroup.controls["SentSignedCopyOtherText"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["NRF"].value=="NRF"){
      this.terminationFormGroup.controls["SentSignedCopyOtherText"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["NRFStatus"].value=="Intern"||this.terminationFormGroup.controls["NRFStatus"].value=="USI"||
    this.terminationFormGroup.controls["NRFStatus"].value=="BDA Contractor"){
      this.terminationFormGroup.controls["otherNRFStatusReason"].setErrors(null);
    }

    if(this.terminationFormGroup.controls["jakiePolsonNotifiedDate"].value!=""&& 
    this.terminationFormGroup.controls["jakiePolsonNotifiedDate"].value!=null){
      this.terminationFormGroup.controls["jakiePolsonNotifiedDateNA"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["allowedToTermedDate"].value!=""&&
    this.terminationFormGroup.controls["allowedToTermedDate"].value!=null){
      this.terminationFormGroup.controls["allowedToTermedDateNA"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["pstToTermedClosedDeniedDate"].value!=""&&
    this.terminationFormGroup.controls["pstToTermedClosedDeniedDate"].value!=null){
      this.terminationFormGroup.controls["pstToTermedClosedDeniedDateNA"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["notifiedBrokerageFirmsDate"].value!=""&&
    this.terminationFormGroup.controls["notifiedBrokerageFirmsDate"].value!=null){
      this.terminationFormGroup.controls["notifiedBrokerageFirmsDateNA"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["dateTheEmployeeDeloitteMobile"].value!=""&&
    this.terminationFormGroup.controls["dateTheEmployeeDeloitteMobile"].value!=null){
      this.terminationFormGroup.controls["dateTheEmployeeDeloitteMobileNA"].setErrors(null);
    }

    if(this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPerms"].value!=""&&
    this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPerms"].value!=null){
      this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsNA"].setErrors(null);
      this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["dateSupportTicketOpened"].value!=""&&
    this.terminationFormGroup.controls["dateSupportTicketOpened"].value!=null){
      this.terminationFormGroup.controls["dateSupportTicketOpenedNA"].setErrors(null);
      this.terminationFormGroup.controls["dateSupportTicketOpenedReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["DateTheNewHiresTermedCompliance"].value!=""&&
    this.terminationFormGroup.controls["DateTheNewHiresTermedCompliance"].value!=null){
      this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceNA"].setErrors(null);
      this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["terminatedIndividualRemovedEmployee"].value!=""&&
    this.terminationFormGroup.controls["terminatedIndividualRemovedEmployee"].value!=null){
      this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeNA"].setErrors(null);
      this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["SentSignedCopy"].value=="No"){
      this.terminationFormGroup.controls["trackingNumber"].setValue("");
      this.terminationFormGroup.controls["trackingNumber"].setErrors(null);
      this.terminationFormGroup.controls["ShippingMethodUsed"].setValue("");
      this.terminationFormGroup.controls["ShippingMethodUsed"].setErrors(null);
    }
    
    
    if(this.terminationFormGroup.valid){return true;}else{return false;}
  }
  onSubmit(){
    this.isSaveAndUpdate=false;
    this.initialSubmit=false;
 if(this.IsTerminationFormValid()){
  this.actionType="Submit";
  this.initialSubmit=true;
  this.OnSaveForm(event);
 }
 else{
  this.actionType="";
//Mandatory Fields Popup
   this.showMsgs("termination", "Info");

 }
 this.submitted = true;
}
  onThreatTeamContanctChange(event:any){
    if(event.target.value=="No" || event.target.value=="Other"){
      this.isThreatTeamContactReason = true;
      if(this.terminationFormGroup.controls["threatTeamContactReason"].value=="")
      this.terminationFormGroup.controls["threatTeamContactReason"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["threatTeamContactReason"].setValue(""):this.initialTerminationList.WasThreatContactedOther==null?this.terminationFormGroup.controls["threatTeamContactReason"].setValue(""):"";
      this.isThreatTeamContactReason = false;
    }
    this.terminationFormGroup.get('ThreatTeamContact')?.setValue(event.target.value);
  }

onOpenInternalThreatChange(event:any){
    if(event.target.value=="Yes" || event.target.value=="Other"){
        this.isOpenInternalThreatReason = true;
        if(this.terminationFormGroup.controls["openInternalThreatReason"].value=="")
        this.terminationFormGroup.controls["openInternalThreatReason"].setErrors({required:true});
      }
      else{
        this.formID==undefined?this.terminationFormGroup.controls["openInternalThreatReason"].setValue(""):this.initialTerminationList.InternalOpenThreatDetail==null?this.terminationFormGroup.controls["openInternalThreatReason"].setValue(""):"";
        this.isOpenInternalThreatReason = false;
    }
    this.terminationFormGroup.get('OpenInternalThreat')?.setValue(event.target.value);
}

  onSmarshEmailChange(event:any){
    if(event.target.value=="No"){
      this.isSmarshEmailComment = true;
      this.terminationFormGroup.controls["SmarshEmailComment"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["SmarshEmailComment"].setValue(""):this.initialTerminationList.RunSmarshEmailSpotCheckOther==null?this.terminationFormGroup.controls["SmarshEmailComment"].setValue(""):"";
      this.isSmarshEmailComment = false;
    }
    this.terminationFormGroup.get('SmarshEmail')?.setValue(event.target.value);
  }

  onNotifiedBrokerageFirmsChange(event:any){
    this.formID==undefined?this.terminationFormGroup.get("noAccountsToTerm")?.setValue(""):this.initialTerminationList.NotifiedBrokeragFirmNoValue==null?this.terminationFormGroup.get("noAccountsToTerm")?.setValue(""):"";
    this.NotifiedBrokerageFirms = event.target.value
    this.IsNotifiedBrokerageFirmsOther = false;
    if(event.target.value=="No"){
      this.terminationFormGroup.controls["noAccountsToTerm"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["noAccountsToTerm"]?.setValue(""):this.initialTerminationList.NotifiedBrokeragFirmNoValue==null?this.terminationFormGroup.controls["noAccountsToTerm"]?.setValue(""):"";
    }
    this.terminationFormGroup.get('notifiedBrokerageFirms')?.setValue(event.target.value);
    //ToShow Reason Box
    this.IsNotifiedBrokerageFirmsOther=this.formID==undefined?false:this.initialTerminationList.NotifiedBrokeragFirmNoValue=="Other"&&this.terminationFormGroup.controls["noAccountsToTerm"]?.value=="Other"?true:false;
  }
  onNotifiedBrokerageFirmsChangeOthers(event:any){
    if(event.target.value=="Other"){
        this.IsNotifiedBrokerageFirmsOther = true;
        this.terminationFormGroup.controls["notifiedBrokerageFirmsOther"].setErrors({required:true});
      }
      else{
        this.formID==undefined?this.terminationFormGroup.controls["notifiedBrokerageFirmsOther"].setValue(""):this.initialTerminationList.NotifiedBrokeragFirmNoOtherText==null?this.terminationFormGroup.controls["notifiedBrokerageFirmsOther"].setValue(""):"";
        this.IsNotifiedBrokerageFirmsOther = false;
    }
    this.terminationFormGroup.get('noAccountsToTerm')?.setValue(event.target.value);

}
  onStatusofIndividualChange(event:any){
    this.formID==undefined?this.terminationFormGroup.get("RegisteredStatus")?.setValue(""):this.initialTerminationList.StatusOfIndividualSupervisory==null?this.terminationFormGroup.get("RegisteredStatus")?.setValue(""):"";
    this.formID==undefined?this.terminationFormGroup.get("NRFStatus")?.setValue(""):this.initialTerminationList.StatusOfIndividualNRF==null?this.terminationFormGroup.get("NRFStatus")?.setValue(""):"";
    if(event.target.value=="Registered"){
      this.isRegisteredStatus = true;
      this.isNRFStatus = false;
    }
    else{
      this.isNRFStatus = true;
      this.isRegisteredStatus = false;
    }
    this.isOtherNRFStatus = this.formID==undefined?false:this.initialTerminationList.StatusOfIndividualNRF=="Other"&&this.terminationFormGroup.get("NRFStatus")?.value=="Other"?true:false;
    this.terminationFormGroup.get('StatusofIndividual')?.setValue(event.target.value);
  }
  onNRFStatusChange(event:any){
    if(event.target.value=="Other"){
      this.isOtherNRFStatus = true;
      this.terminationFormGroup.controls["otherNRFStatusReason"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["otherNRFStatusReason"].setValue(""):this.initialTerminationList.StatusOfIndividualNRFOther==null?this.terminationFormGroup.controls["otherNRFStatusReason"].setValue(""):"";
      this.isOtherNRFStatus = false;
    }
    this.terminationFormGroup.get('NRFStatus')?.setValue(event.target.value);
  }
  onSentSignedCopyNRFChange(event:any){
    if(event.target.value!="NRF"){
      this.terminationFormGroup.controls["SentSignedCopyOtherText"].setErrors({required:true});
    }
  this.SentSignedCopyNRF=event.target.value;
  this.formID==undefined?this.terminationFormGroup.controls["SentSignedCopyOtherText"].setValue(""):this.initialTerminationList.SentIndividualSignedCopyNoValue==null||this.initialTerminationList.SentIndividualSignedCopyNoValue==""?this.terminationFormGroup.controls["SentSignedCopyOtherText"].setValue(""):"";
  this.terminationFormGroup.get('NRF')?.setValue(event.target.value);

  }
  onSentSignedCopyChange(event:any){
    this.ShowTrackingNumber = event.target.value=="Yes"?true:false;
    this.formID==undefined?this.terminationFormGroup.get("NRF")?.setValue(""):this.initialTerminationList.NRFTerminationFiledWithFinra==null?this.terminationFormGroup.get("NRF")?.setValue(""):"";
    if(event.target.value=="No"){
      this.formID==undefined?this.terminationFormGroup.controls["SentSignedCopyOtherText"].setValue(""):this.initialTerminationList.SentIndividualSignedCopyNoValue==null||this.initialTerminationList.SentIndividualSignedCopyNoValue==""?this.terminationFormGroup.controls["SentSignedCopyOtherText"].setValue(""):"";
    }
    else if(event.target.value=="Yes"){
      // this.terminationFormGroup.get("trackingNumber")?.setValue("");
      this.terminationFormGroup.controls["trackingNumber"].setErrors({required:true});   
        // this.terminationFormGroup.get("ShippingMethodUsed")?.setValue("");
        this.terminationFormGroup.controls["ShippingMethodUsed"].setErrors({required:true});
    }
    else if(event.target.value=="No"){
      this.terminationFormGroup.get("trackingNumber")?.setValue("");
      this.terminationFormGroup.controls["trackingNumber"].setErrors(null);   
        this.terminationFormGroup.get("ShippingMethodUsed")?.setValue("");
        this.terminationFormGroup.controls["ShippingMethodUsed"].setErrors(null);
    }
    this.SentSignedCopy = event.target.value;
    this.SentSignedCopyNRF=this.formID==undefined?"":event.target.value=="No"&&this.terminationFormGroup.get('NRF')?.value=="Other"?"Other":"";
  }
  eventDateSelected(event:any, formControlName: string) {
    this.terminationFormGroup.get(formControlName)?.setValue(event);

    if(formControlName=="jakiePolsonNotifiedDate"){
      this.terminationFormGroup.get("jakiePolsonNotifiedDateNA")?.setValue("");
      this.terminationFormGroup.get("jakiePolsonNotifiedDateNA")?.setErrors(null);
    }
    if(formControlName=="notifiedBrokerageFirmsDate"){
      this.terminationFormGroup.get("notifiedBrokerageFirmsDateNA")?.setValue("");
      this.terminationFormGroup.get("notifiedBrokerageFirmsDateNA")?.setErrors(null);
    }
    if(formControlName=="dateTheEmployeeDeloitteMobile"){
      this.terminationFormGroup.get("dateTheEmployeeDeloitteMobileNA")?.setValue("");
      this.terminationFormGroup.get("dateTheEmployeeDeloitteMobileNA")?.setErrors(null);
    }
    if(formControlName=="allowedToTermedDate"){
      this.terminationFormGroup.get("allowedToTermedDateNA")?.setValue("");
      this.terminationFormGroup.get("allowedToTermedDateNA")?.setErrors(null);
    }
    if(formControlName=="pstToTermedClosedDeniedDate"){
      this.terminationFormGroup.get("pstToTermedClosedDeniedDateNA")?.setValue("");
      this.terminationFormGroup.get("pstToTermedClosedDeniedDateNA")?.setErrors(null);
    }

    if(formControlName=="dateTheEmployeeRemovedFlowPerms"){
      this.terminationFormGroup.get("dateTheEmployeeRemovedFlowPermsNA")?.setValue("");
      this.terminationFormGroup.get("dateTheEmployeeRemovedFlowPermsNA")?.setErrors(null);
      this.terminationFormGroup.get("dateTheEmployeeRemovedFlowPermsReason")?.setValue("");
      this.terminationFormGroup.get("dateTheEmployeeRemovedFlowPermsReason")?.setErrors(null);
      this.isdateTheEmployeeRemovedFlowPermsReason = false;
    }
    if(formControlName=="dateSupportTicketOpened"){
      this.terminationFormGroup.get("dateSupportTicketOpenedNA")?.setValue("");
      this.terminationFormGroup.get("dateSupportTicketOpenedNA")?.setErrors(null);
      this.terminationFormGroup.get("dateSupportTicketOpenedReason")?.setValue("");
      this.terminationFormGroup.get("dateSupportTicketOpenedReason")?.setErrors(null);
      this.isdateSupportTicketOpenedReason = false;
    }
    if(formControlName=="DateTheNewHiresTermedCompliance"){
      this.terminationFormGroup.get("DateTheNewHiresTermedComplianceNA")?.setValue("");
      this.terminationFormGroup.get("DateTheNewHiresTermedComplianceNA")?.setErrors(null);
      this.terminationFormGroup.get("DateTheNewHiresTermedComplianceReason")?.setValue("");
      this.terminationFormGroup.get("DateTheNewHiresTermedComplianceReason")?.setErrors(null);
      this.isDateTheNewHiresTermedComplianceReason = false;
    }
    if(formControlName=="terminatedIndividualRemovedEmployee"){
      this.terminationFormGroup.get("terminatedIndividualRemovedEmployeeNA")?.setValue("");
      this.terminationFormGroup.get("terminatedIndividualRemovedEmployeeNA")?.setErrors(null);
      this.terminationFormGroup.get("terminatedIndividualRemovedEmployeeReason")?.setValue("");
      this.terminationFormGroup.get("terminatedIndividualRemovedEmployeeReason")?.setErrors(null);
      this.isterminatedIndividualRemovedEmployeeReason = false;
    }
  }


  showMsgs(msg: any, msgType: any) {
    this.terminationNotifyPopupComponent.openPopup(msg, msgType)
  }
  
 

  highlightRequiredField(formControlName: string): boolean {
    if (this.terminationFormGroup) {
      return FormValidations.highlightRequiredField(this.submitted, this.terminationFormGroup, formControlName);
    }
    return false;
  }
}
