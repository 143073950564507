<div class="col-xs-12">
  <app-peoplepicker-dynamic-table #DealTeam
  [header]="ppmdNameHeader"
  [firstColumnItemList]="firstColumnVisible ? ppmdRoleColumn : null"
  [secondColumnItemList]="secondColumnVisible ? ppmdPursuitRoleColumn : null"
  [thirdColumnItemList]="thirdColumnVisible ? ppmdSwiftRoleColumn : null"
  [valuesList]="ppmdValues"
  [readonly]="readonlyPPMD || readonly "
  [placeHolderForLastTable]="placeholderPreviousPPMD"
  (selectedRow)="onRowSelection($event)">
  </app-peoplepicker-dynamic-table>
</div>
