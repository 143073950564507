<!--  
<div>
<button style="float:right"  type="button"
class="engagement-dashboard-button btn_Custom"
(click)="exportToExcel()"  [disabled]="showDisable" >
Export to Excel
</button>
<br>
<br>
 -->
<div class="table-responsive table-body heightStyles" [ngStyle]="getModalStyles()">
<table id="engagement-table" class="table-striped">
    <thead>
      <tr>
        <th scope="col">
            <input id="chk_MultiCheckBox" style="height:13px ;" type="checkbox" (change)="onCheckboxChange($event)"/>
        </th>
        <th scope="col">
          <span
            class="th-label"
            sortable="engagementId"
            (sort)="sortTable($event)">
            Engagement Id
          </span>
         <app-popover #popoverNewDealId
            [active]="isFilterApply('engagementId')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('engagementId')">
         </app-popover>
        </th>
        <th scope="col">
          <span
            class="th-label"
            sortable="engagementName"
            (sort)="sortTable($event)">
              Engagement Name
          </span>
         <app-popover #popoverEngagementName
            [active]="isFilterApply('engagementName')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('engagementName')">
          </app-popover>
        </th>
        <th>
          
          <span class="th-label" sortable="isS24ApprovalComplete" (sort)="sortTable($event)">
            S24 Approval Status 
          </span>
          <app-popover #popoverIsS24ApprovalComplete
            [active]="isFilterApply('isS24ApprovalComplete')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('isS24ApprovalComplete')">
          </app-popover>
        </th>
         <th scope="col">
          <span
            class="th-label" sortable="nameOfS24" (sort)="sortTable($event)">
            S24 Name 
          </span>
          <app-popover #popovernameOfS24
          [active]="isFilterApply('nameOfS24')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('nameOfS24')">
        </app-popover>
        </th>
        <th scope="col">
          <span
            class="th-label"  sortable="s24ApprovalDate" (sort)="sortTable($event)">
            S24 Approval Date 
          </span>
          <app-popover #popovers24ApprovalDate id="s24approvalDatePopover"
          [active]="isFilterApply('s24ApprovalDate')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('s24ApprovalDate')">
        </app-popover>
        </th> 
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let report of reportDashboardsPerPage; let i = index">
        <td>
            <input style="height:13px ;" type="checkbox" [checked]="report.isSelected" 
            (change)="onCheckboxChanged(report.engagementId)"/>
        </td>
        <td>
          <span>
            {{report.engagementId}}
          </span>
        </td>
        <td>
          <span
          class="link-label"
            (click)="openClosingCheckListForm(report.form_id)">
            {{report.engagementName==null||report.engagementName==''? 'Empty' : report.engagementName}}
          </span>
        </td>
        <td>          
            <span class="deal-team-name">{{report.isS24ApprovalComplete}}</span>
        </td>
         <td>
          <span>{{report.nameOfS24}}</span>
        </td>
        <td>
          <span>
            {{report.s24ApprovalDate}}
          </span>
        </td> 
      </tr>
    </tbody>
  </table>
</div>
  <span *ngIf="reportDashboards && reportDashboards.length === 0" class="no-matches">No reports matched your search.</span>
<div class="row">
  <div class="col-xs-5" style="margin-left:0%">
    <app-pagination #pagination
      *ngIf="reportDashboards"
      [page]="reportDashboardServices.state.page"
      [pageSize]="reportDashboardServices.state.pageSize"
      [paginationListValues]="pageSizeListValues"
      [listElementsSize]="reportDashboards.length"
      (paginationValues)="onChangePaginationValues($event)">
    </app-pagination>
  </div>
  <div class="col-md-5">
    <div class="divPageSizeType" id="divPageSizeType">
      <select id="ddlPageSize" (change)="onPageSizeValueChange($event)" [(ngModel)]="selectedPageSize"
        class="form-select form-control" [disabled]="pageDropdownDisable">
        <option value="" selected disabled>Select your option</option>
        <option *ngFor="let pages of pageSizeListValues" [value]="pages.key">
          {{pages.value}}
        </option>
      </select>
    </div>
  </div>
</div>
<ng-template #popContent>
    <div class="filter-container">
      <div class="filter-body">
        <div
          class="row sort-row"
          (click)="sortTableOnFilterModal(targetField, 'asc')">
            <i class="fas fa-sort-alpha-down sort-icon"></i>
            <span>A on Top</span>
        </div>
        <div
          class="row sort-row"
          (click)="sortTableOnFilterModal(targetField, 'desc')">
            <i class="fas fa-sort-alpha-down-alt sort-icon"></i>
            <span>Z on Top</span>
        </div>
        <hr>
        <div class="row">
          <div
            [ngClass]="{'clear-filter-container-enable': isFilterApply(targetField)}"
            (click)="clearFilters()">
              <i class="fas fa-filter filter-icon"></i>
              <span
                class="filter-icon-span"
                [ngClass]="{'filter-icon-span-enable': !isFilterApply(targetField)}">
                  Clear Filters from Value
              </span>
          </div>
        </div>
        <div *ngFor="let value of fieldOptionValues"
          class="row">
          <label class="checkbox-label">
            <input
              type="checkbox"
              class="checkbox-option-field"
              id="{{value}}"
              [checked]="isChecked(value)"
              (click)="onClickFilterCheckbox($event)">
            {{value}}
          </label>
        </div>
      </div>
    </div>
  </ng-template>
