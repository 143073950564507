import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numeric]'
})

export class NumericDirective {

  @Input('numericType') numericType: any;

  constructor(private el: ElementRef) { }

  /*
  @Input() maxlength: number;
  @Input() min: number;
  @Input() max: number;
  */

  private regex:any = {
    number: new RegExp(/^\d*$/),
    // decimal: new RegExp(/\d*(\.\d{1,2})?/)
    decimal : new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g),
    decimal2:new RegExp(/^([0-9]+(\.[0-9]*){0,1}|[n\/a]|[N]|[n]+[\/]|[n]+[\/]+[a]|[A])$/i),//used in clf 
    number2: new RegExp(/^(\d*|[n\/a]|[N]|[n]+[\/]|[n]+[\/]+[a]|[A])$/i),//use in clf
     postalcode :(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/)
  };

  private specialKeys:any = {
    number: [46, 8, 9, 27, 13, 35, 36, 37, 39],
    decimal: [46, 8, 9, 27, 13, 35, 36, 37, 39, 110],
    number2: [46, 8, 9, 27, 13, 35, 36, 37, 39],
    decimal2: [46, 8, 9, 27, 13, 35, 36, 37, 39, 110],
  };

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.preventDefaultIfNotNumber(event);
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    this.preventDefaultIfNotNumber(event);
  }

  @HostListener('paste', ['$event'])
  onPaste(event:any) {
    const data = (event.clipboardData || (window as any).clipboardData).getData('text');
    const currentRegex = this.regex[this.numericType];
    if (!String(data).match(currentRegex)) {
      event.preventDefault();
    }
  }

  @HostListener('change', ['$event'])
  onChange(event: Event) {
    const data = this.el.nativeElement.value;
    const currentRegex = this.regex[this.numericType];
    if (!String(data).match(currentRegex)) {
      event.preventDefault();
      this.el.nativeElement.value = '';
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: Event) {
    const data: string = this.el.nativeElement.value;
    if (data.indexOf('.') === 0) {
      this.el.nativeElement.value = data.length === 1 ? '0.0' : '0' + data;
    }
  }

  private preventDefaultIfNotNumber(event: KeyboardEvent): void {
    const e = event as KeyboardEvent;
    if (this.isSpecialKeyOrShortCut(e)) {
      return;
    } else {
      const current: string = this.el.nativeElement.value;
      const next: string = current.concat(event.key);
      const currentRegex = this.regex[this.numericType];

      if ((next && !String(next).match(currentRegex)) /*||
        (this.maxlength && next.length > this.maxlength) ||
        (this.min && +next < this.min) ||
        (this.max && +next > this.max)*/
      ) {
        event.preventDefault();
      }
    }
  }

  private isSpecialKeyOrShortCut(event: KeyboardEvent): boolean {
    return (
      (this.specialKeys[this.numericType].indexOf(event.which) > -1) ||
      // // to allow backspace, enter, escape, arrows
      (event.which === 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.which === 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.which === 88 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.which === 86 && event.ctrlKey === true)
    );
  }
}
