export class SharepointClosingCheckListObject {

    // BaseFeeNumerical;
    // BaseFeePercentage;
    // BaseFeeRadio;

    /// ************Indemnification Section **********///
     BasketType:string;
     CapNumerical:string;
     CapPercentageClosing:string;
     CapPercentageEV:string;
     SurvivalMonths:string;
     DeductibleClosing:string;
     DeductibleEV:string;
     DeductibleNumerical:string;
     EscrowNumerical:string;
     EscrowTotalEV:string;
     EscrowClosing:string;
     ReleaseMonths:string;
     ReconciliationEscrow:string;
     IfOtherWhat:string;
     SpecialIndemnification:string;
     SpecialIndemnificationTopic:string;
    // BreakpointQuestion;
    // BreakpointValue;
    // BuyerCountryId;
    // BuyerParentCompany;
    // BuyerRelationship;
    // BuyerType;
     
    // ClientCountryId;
    // ClientLocationId;
    // ClientRelationship;
    // ClientType;
    
    /// ********** ValuationMetrics Section **********///
    ClosingConsideration:string;
    ContingentConsideration:string;
    DescribeDealStructure:string;
    Ebitda:string;
    EbitdaMultipleClosing:string;
    EbitdaMultipleTotal:string;
    EbitdaPeriod:string;
    ElBreakpoint:string;
    FinalEvPitchRange:string;
    IfBelowWhy:string;
    IfBelowWhyOtherReason:string;
    ImpliedValuationPremium:string;
    HitBreakpointEL:string;
    LoiAboveBreakpoint:string;
     LoiClosedConsideration:string;
     LoiClosedContingent:string;
     LoiTotal:string;
     IoiAboveBreakpoint:string;
     PitchEBITDAMultipleRangeFirst:string;
     PitchEBITDAMultipleRangeSecond:string;
     MultipleRangeMidpoint:string;
     TotalConsideration:string;
     ValNumericalClosedConsideration:string;
     ValNumericalTotal:string;
     ValPercentageClosedConsideration:string;
     ValPercentageTotal:string;
     ValuationPremiumOverBp:string;

     // Deal Structure Section
      EquityContributionClosingPct:string;
     EquityContributionTotalPct:string;
     ContingentTotalEVPct:string;
     LegalStructure:string;
      ManagementFee:string;
     ManagementFeePct:string;
     ManagementFeeQty:string;
     RolloverAfterTaxPct:string;
     RolloverEquity:string;
     RolloverPreTaxPct:string;
      TriggerType:string;
      TotalLeverage:string;
      TimeUntilContingentPayment:string;
      StockConsideration:string;
     StockConsiderationTotalEVPct:string;
      ManagementOptionPool:string;
      SponsorTransactionClosingFee:string;

    // CrossBorderEngagement;
    // DealType;
    // DeloitteLCSP;
    // DescribeDealMarketing;
    // DescribeFeeStructure;
    // EnteredMarket;

    
    FinanceEnterpriseValue:string;
    FinanceDeloitteEntity:string;
    FinanceDeloitteEntityDescription:string;
    FinanceFutureExpenses:string;
    FinanceFutureExpensesAmount:string;
    FinanceFuturePayments:string;
    FinanceFuturePaymentsType:string;
    FinanceAssetSaleDeal:string;
    FinanceCrossBorderEngagement:string;
    FinanceCrossBorderCountry:string;
    FinanceProviderName:string;
    FinanceProviderNameType:string;
    FinanceDealLeagueTable:string;
    FinanceDealLeagueTableDesc:string;
    FinanceDealGlobalDeloitteSite:string;
    FinanceDealGlobalDelSiteDesc:string;
    FinanceDealAnnouncement:string;
    FinanceDealAnnouncementDesc:string;
    FinanceDealCaseStudy:string;
    FinanceDealCaseStudyDesc:string;

    // FirstInstitutionalCapital;
    // FirstProcessRun;
    // FormStatus;
    // FormActionGroup;
    // IdentifiedTierPitch;
    // IncentiveFeeNumerical;
    // IncentiveFeePercentage;
    // IncentiveFeeRadio;
    // InternationalBuyer;
    // MainContact;
   
    // MarketingProcessType;
    // MinimumFeeApply;
    // MinimumSucessFee;
    // NumberOfParticipants;
    // PitchedDate;
    // PreemptiveBidder;
    // PreemptiveFinalPurchaser;
    // PreemptiveOffer;
    // PremiumOverInitialOffer;
    // QofEDeloitteBranded;
    // QofEStage;
    // ReceivedIoiTermSheets;
    // ReceivedLOIs;
    // ReconciliationEscrow;
    // ReferralSource;
    // ReferralSourceSubType;
    // ReleaseMonths;
    // //Retainer;
    // SellsideQofEPerformed;
    // SignDate;
    // SignedEngagementLetter;
    // SignedLOI;
    // SimultaneousSignClose;
    // SpecialIndemnification;
    // SpecialIndemnificationTopic;
    // SponsorName;
    
    
    // SubsidiaryBeingSold;
    
    // TransactionClose;

    // TriggerType;
    
    ///********Client overview section ********///   

    //Client overview section    

        ClientType: string;
        SponsorName: string;
        SubsidiaryBeingSold: string;
        ClientRelationship: string;
        ReferralSource: string;
        ReferralSourceSubType: string;
        ClientLocation: string;
        ClientCountry: string;
        //deal overview section
        DealType: string;
        CrossBorderEngagement: string;
        FirstProcessRun: string;
        FirstInstitutionalCapital: string;
        NumberOfParticipants: string;
        //Dates/Timeline Section

        PitchedDate:any;
        SignedEngagementLetter:any;
        EnteredMarket:any;
        ReceivedIoiTermSheets:any;
        ReceivedLOIs:any;
        SignedLOI:any;
        TransactionClose:any;
        SimultaneousSignClose:string;
        SignDate:any;
        CommentAffectingTimeline:string;

        //Quality Of Earning Section

         SellsideQofEPerformed :string;
         QofEStage :string;
         WhatFirmPerformed :string;
         MainContact: string;
         QofEDeloitteBranded: string;
      
    // BaseFeeNumerical,
    // BaseFeePercentage,
    // BaseFeeRadio,
    // BasketType,
    // BreakpointQuestion,
    // BreakpointValue,
   
    /// *********buyer deal Marketing section ****************///
    
    BuyerCountry:string;
    BuyerParentCompany:string;
    BuyerRelationship:string;
    BuyerType:string;
    DeloitteLCSP:string;
    DescribeDealMarketing:string;
    IdentifiedTierPitch:string;
    InternationalBuyer:string;
    MarketingProcessType:string;
    PreemptiveBidder:string;
    PreemptiveFinalPurchaser:string;
    PreemptiveOffer:string;
    PremiumOverInitialOffer:string;

    // CapNumerical,
    // CapPercentageClosing,
    // CapPercentageEV,
    // ClientCountryId,
    // ClientLocationId,
    // ClientRelationship,
    // ClientType,
    // ClosingConsideration,
    // CommentAffectingTimeline,
    // ContingentConsideration,
    // CrossBorderEngagement,
    // DealType,
    // DeductibleClosing,
    // DeductibleEV,
    // DeductibleNumerical,
    
    // DescribeDealStructure,
    // DescribeFeeStructure,
    // Ebitda,
    // EbitdaMultipleClosing,
    // EbitdaMultipleTotal,
    // EbitdaPeriod,
    // ElBreakpoint,
    // EnteredMarket,
    // EquityContributionClosingPct,
    // EquityContributionTotalPct,
    // EscrowClosing,
    // EscrowNumerical,
    // EscrowTotalEV,
    // FinalEvPitchRange,
    // FinanceEnterpriseValue,
    // FinanceDeloitteEntity,
    // FinanceDeloitteEntityDescription,
    // FinanceFutureExpenses,
    // FinanceFutureExpensesAmount,
    // FinanceFuturePayments,
    // FinanceFuturePaymentsType,
    // FinanceAssetSaleDeal,
    // FinanceCrossBorderEngagement,
    // FinanceCrossBorderCountryId,
    // FinanceProviderNameType,
    // FinanceDealLeagueTable,
    // FinanceDealLeagueTableDesc,
    // FinanceDealGlobalDeloitteSite,
    // FinanceDealGlobalDelSiteDesc,
    // FinanceDealAnnouncement,
    // FinanceDealAnnouncementDesc,
    // FinanceDealCaseStudy,
    // FinanceDealCaseStudyDesc,
    // FirstInstitutionalCapital,
    // FirstProcessRun,
    // FormStatus,
    // FormActionGroup,
    // HitBreakpointEL,
    
    // IfBelowWhy,
    // IfBelowWhyOtherReason,
    // IfOtherWhat,
    // ImpliedValuationPremium,
    // IncentiveFeeNumerical,
    // IncentiveFeePercentage,
    // IncentiveFeeRadio,
   
    // IoiAboveBreakpoint,
    // LegalStructure,
    // LoiAboveBreakpoint,
    // LoiClosedConsideration,
    // LoiClosedContingent,
    // LoiTotal,
    // MainContact,
    // ManagementFee,
    // ManagementFeePct,
    // ManagementFeeQty,
    // ManagementOptionPool,
  
    // MinimumFeeApply,
    // MinimumSucessFee,
    // MultipleRangeMidpoint,
    // NumberOfParticipants,
    // PitchEBITDAMultipleRangeFirst,
    // PitchEBITDAMultipleRangeSecond,
    // PitchedDate,
   
    // QofEDeloitteBranded,
    // QofEStage,
    // ReceivedIoiTermSheets,
    // ReceivedLOIs,
    
    // ReferralSource,
    // ReferralSourceSubType,
   
    // //Retainer,
    // RolloverAfterTaxPct,
    // RolloverEquity,
    // RolloverPreTaxPct,
    // SellsideQofEPerformed,
    // SignDate,
    // SignedEngagementLetter,
    // SignedLOI,
    // SimultaneousSignClose,
   
    // SponsorName,
    // SponsorTransactionClosingFee,
    // StockConsideration,
    // StockConsiderationTotalEVPct,
    // SubsidiaryBeingSold,
    // SurvivalMonths,
    // TimeUntilContingentPayment,
    // TotalConsideration,
    // TotalLeverage,
    // TransactionClose,
    // TriggerType,
    // ValNumericalClosedConsideration,
    // ValNumericalTotal,
    // ValPercentageClosedConsideration,
    // ValPercentageTotal,
    // ValuationPremiumOverBp,

    // WhatFirmPerformed

    //dcfFeestructure
     Retainer:string;
     MinimumSucessFee:string;
     FeeType:string;
     MinimumFeeApply:string;
     FinimumFeeApply:string;
     DescribeFeeStructure:string;
     BreakpointValue:string;
     BreakpointQuestion:string;
     BaseFeeNumerical:string;
    BaseFeePercentage:string;
    BaseFeeRadio:string;
    IncentiveFeeRadio:string;
    IncentiveFeePercentage:string;
    IncentiveFeeNumerical:string;
}
export class SharePointObjectValuationMetrics{
    ClosingConsideration: string;
    ContingentConsideration: string;
    TotalConsideration: string;
    Ebitda: string;
    EbitdaPeriod: string;
    EbitdaMultipleTotal: string;
    EbitdaMultipleClosing: string;
    FinalEvPitchRange: string;
    IfBelowWhy: string;
    IfBelowWhyOtherReason: string;
    PitchEBITDAMultipleRangeFirst: string;
    PitchEBITDAMultipleRangeSecond: string;
    MultipleRangeMidpoint: string;
    ImpliedValuationPremium: string;
    HitBreakpointEL: string;
    ElBreakpoint: string;
    ValuationPremiumOverBp: string;
    IoiAboveBreakpoint: string;
    LoiAboveBreakpoint: string;
    DescribeDealStructure: string;
    IOIValueClosingLow: string;
    IOIValueClosingHigh: string;
    IOIValueContingent: string;
    IOIValueTotalHigh: string;
    IOIValueClosingAvg: string;
    IOIValueTotalAvg: string;
    LOIValueClosingLow: string;
    LOIValueClosingHigh: string;
    LOIValueContingent: string;
    LOIValueTotalHigh: string;
    LOIValueClosingAvg: string;
    LOIValueTotalAvg: string;
    NumericalPriceIncClosingLow: string;
    NumericalPriceIncClosingHigh: string;
    NumericalPriceIncContingent: string;
    NumericalPriceIncTotalHigh: string;
    NumericalPriceIncClosingAvg: string;
    NumericalPriceIncTotalAvg: string;
    PercentagePriceIncClosingLow: string;
    PercentagePriceIncClosingHigh: string;
    PercentagePriceIncContingent: string;
    PercentagePriceIncTotalHigh: string;
    PercentagePriceIncClosingAvg: string;
    PercentagePriceIncTotalAvg: string;
    LoiClosedConsideration: string;
    LoiClosedContingent: string;
    LoiTotal: string;
    ValNumericalClosedConsideration: string;
    ValNumericalTotal: string;
    ValPercentageClosedConsideration: string;
    ValPercentageTotal: string;

}

export class SharepointDealMarketingSection{
      BuyerCountry: string;
      BuyerParentCompany:string;
      BuyerType:string;
      BuyerRelationship:string;
      DeloitteLCSP:string;
      DescribeDealMarketing:string;
      IdentifiedTierPitch:string;
      InternationalBuyer:string;
      MarketingProcessType:string;
      PreemptiveBidder:string;
      PreemptiveFinalPurchaser:string;
      PreemptiveOffer:string;
      PremiumOverInitialOffer:string;

      ApproachedIntlStrategic :string;
      ApproachedPEG:string;
      ApproachedTotal:string;
      ApproachedUSStrategic:string;
      AttendedMPPEG:string;
      AttendedMPPEGNoPortCo:string;
      AttendedMPTotal:string;
      AttendedMPUSStrategic:string;
      SentCIMIntlStrategic:string;
      SentCIMPEG:string;
      SentCIMPEGNoPortCo:string;
      SentCIMTotal:string;
      SentCIMUSStrategic:string;
      SubmittedIOIIntlStrategic:string;
      SubmittedIOIPEGNoPortCo:string;
      SubmittedIOITotal:string;
      SubmittedIOIUSStrategic:string;
      SubmittedLOIIntlStrategic:string;
      SubmittedLOIPEG:string;
      SubmittedLOIPEGNoPortCo:string;
      SubmittedLOITotal:string;
      SubmittedLOIUSStrategic:string;
      ApproachedPEGNoPortCo:string;
      AttendedMPIntlStrategic:string;
      SubmittedIOIPEG:string;
}