<div style="width: 100%;" class="col-xs-10" id="div-navigation-index" [style]="isFixed">
  <nav>
    <ul id="ul-navigation-section" class="nav">
      <li><a (click)="onclick('#client-information')">Client Information</a></li>
      <li><a (click)="onclick('#engagement-information')">Engagement Information</a></li>
      <li><a (click)="onclick('#international-work-indicators')">International Work Indicators</a></li>
      <li><a (click)="onclick('#archive-cmr')">Archive/CMR</a></li>
      <!-- <li><a (click)="onclick('#engagement-detail')">Master Engagement Details</a></li> -->
      <li><a (click)="onclick('#engagement-team')">Engagement Team</a></li>
      <li><a (click)="onclick('#invoice-details-and-addresses')">Invoice Details & Addresses</a></li>
      <li><a (click)="onclick('#wbs-element-classification')">WBS Element Classification</a></li>
      <li><a (click)="onclick('#participation')">Participation</a></li>
      <!-- <li><a (click)="onclick('#billing-schedule')">Billing Schedule</a></li> -->
      <li><a (click)="onclick('#pricing-details')">Pricing Details</a></li>
      <li><a (click)="onclick('#documents')">Documents</a></li>
      <li *ngIf="showAdminSection"><a (click)="onclick('#admin-review')">Admin Review</a></li>
      <li *ngIf="showReactivateSection"><a (click)="onclick('#reactivate-form')">Reactivate Form</a></li>
      <li *ngIf="showActiveWBSSection"><a (click)="onclick('#active-deal-wbs')">Active Deal WBS Section</a></li>
    </ul>
    <button *ngIf="!isAutoSaveEnabled&&saveButtonVisible&&isAdminReviewEditable"
    class="btn btn-outline-primary save-rework-cancel-btn margin-left-10 savebuttonstyles"
    [disabled]="saveInProgress"
    (click)="onSaveForm()">
    Save
  </button>
  </nav>
  <div id="back-to-top-button" class="hidden">
      <span class="glyphicon glyphicon-menu-up"></span>
  </div>
</div>
