import { Component, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { EngagementHealthObjectItem } from '../models/engagement-health-object-item';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { ComplianceStatusValues } from '../models/compliance-status-values';
import { ComplianceStatusColors } from '../models/compliance-status-colors';
import { UserPermisions } from '../engagement-health-object-setup/engagement-health-object.component';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { ComplianceData } from '../models/compliance-data';
import { DealTeamItem } from 'src/shared/models/deal-team-item';
import { AppSettings } from 'src/shared/app-settings';
import { EhoNotifyPopupComponent } from 'src/shared/components/alerts/eho-notify-popup/eho-notify-popup.component';

const modalTypes = {
  complianceModal: 'complianceModal',
  dealTeamModal: 'dealTeamModal'
};

@Component({
  selector: 'app-engagement-health-details',
  templateUrl: './engagement-health-details.component.html',
  styleUrls: ['./engagement-health-details.component.scss']
})
export class EngagementHealthDetailsComponent implements OnInit {

  engagementObject: EngagementHealthObjectItem;
  startDate: any;
  endDate: any;
  formatDate = 'MM/dd/yyyy';
  userPermisions: UserPermisions;
  modalType: string;
  dealTeamList: Array<DealTeamItem> = [];
  currentRadioButtonSelected: string;
  currentComments: string;
  hasAdminAssigned: boolean;

  @ViewChild('EngagementHealthModal')
  modal: ModalComponent;
  @ViewChild(EhoNotifyPopupComponent, { static: true }) ehoModal: EhoNotifyPopupComponent;

  @Output()
  engagementHealthUpdate: EventEmitter<ComplianceData> = new EventEmitter<ComplianceData>();

  constructor(private dateFormatterService: DateFormatterService) { }

  ngOnInit() {
  }

  setEngagementHealth(engagementObject: EngagementHealthObjectItem, userPermisions: UserPermisions, dealTeamList: Array<DealTeamItem>) {
    this.engagementObject = engagementObject;
    this.startDate = this._formatDate(engagementObject.projectStartDate);
    this.endDate = this._formatDate(engagementObject.projectEndDate);
    this.userPermisions = userPermisions;
    this.dealTeamList = dealTeamList;
    this.hasAdminAssigned =  this.engagementObject.adminUserName == "" ? false : true;
  }

  getComplianceStatusColor(complianceStatus: string) {
    let color;
    switch (complianceStatus) {
      case ComplianceStatusValues.Issues:
        color = ComplianceStatusColors.Issues;
        break;
      case ComplianceStatusValues.NoIssues:
        color = ComplianceStatusColors.NoIssues;
        break;
      case ComplianceStatusValues.NA:
        color = ComplianceStatusColors.NA;
        break;
    }
    return color;
  }

  openComplianceModal(event:any) {
    this.currentRadioButtonSelected = this.engagementObject.complianceStatus;
    this.currentComments = this.engagementObject.complianceComments;
    if (this.userPermisions.isComplianceUser) {
      this._openModal(modalTypes.complianceModal,this.currentRadioButtonSelected);
    } else {
      this._openModal(modalTypes.dealTeamModal,this.currentRadioButtonSelected);
    }
  }

  get complianceStatusValues() {
    return ComplianceStatusValues;
  }

  get complianceStatusColors() {
    return ComplianceStatusColors;
  }

  isChecked(complianceStatus: string): boolean {
    const result = this.currentRadioButtonSelected === complianceStatus;
    return result;
  }

  updateCurrentRadioSelected(value: string) {
    this.currentRadioButtonSelected = value;
  }

  saveComplianceData() {
    this.engagementObject.complianceStatus = this.currentRadioButtonSelected;
    this.engagementObject.complianceComments = this.currentComments;
    const complianceData = this._createObjectToSend(this.engagementObject.complianceStatus, this.engagementObject.complianceComments);
    this.engagementHealthUpdate.emit(complianceData);
  }
  engagementHealthUpdateValue(event:any){
    this.engagementHealthUpdate.emit(event);
  }
  openNewTab(url: string) {
    window.open(url);
  }

  getDpnProfileLink(loginName: string): string {
    return AppSettings.dpnProfileLink.replace('{userId}', loginName);
  }

  private _createObjectToSend(complianceStatus: string, complianceComments: string): ComplianceData {
    const complianceData: ComplianceData = {
      complianceStatus,
      complianceComments,
      dealTeamItemId: this.engagementObject.engagementItemId
    };
    return complianceData;
  }

  private _openModal(modalType: string,data:any) {
    this.modalType = modalType;
   // this.modal.open();
   this.ehoModal.openEHOCompliancePopup(this.modalType,data);
  }

  private _formatDate(date: Date) {
    return this.dateFormatterService.formatDate(date, this.formatDate);
  }
  
}
