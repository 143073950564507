import { Component, OnInit, Input } from '@angular/core';
import { FormValidations } from 'src/shared/utils/form-validations';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormGroup } from '@angular/forms';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { AppSettings } from 'src/shared/app-settings';
import { FormListFields } from '../models/form-list-fields';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { NumericComponent } from 'src/shared/components/numeric/numeric.component';

@Component({
  selector: 'app-pricing-details',
  templateUrl: './pricing-details.component.html',
  styleUrls: ['./pricing-details.component.scss']
})
export class PricingDetailsComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();
  resourceRateTypeOptions: Array<DropdownItem> = [];
  usiResourceRateTypeOptions: Array<DropdownItem> = [];
  budgetGroupOptions: Array<DropdownItem> = [];

  @Input() formGroup: any;
  @Input() formSubmitted: boolean;

  constructor(private tooltipHelperService: TooltipHelperService,
              private dropdownService: DropdownService) {
  }

  ngOnInit() {
    this.loadDropDown();
    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.resourceRateType)
    // .then(response => {
    //   this.resourceRateTypeOptions = response;
    // }).catch(error => {
    // });

    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.usiResourceRateType)
    // .then(response => {
    //   this.usiResourceRateTypeOptions = response;
    // }).catch(error => {
    // });

    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.budgetGroup)
    // .then(response => {
    //   this.budgetGroupOptions = response;
    // }).catch(error => {
    // });
  }
  loadDropDown(){
    this.resourceRateTypeOptions = AppSettings.resourceRateType;
    this.usiResourceRateTypeOptions = AppSettings.usiResourceRateType;
    this.budgetGroupOptions = AppSettings.budgetGroup;
    }
  onEventIndicatorSelection(event:any) {
    this.formGroup.controls.eventIndicator.setValue(event);
  }

  onPlannedNetRevenueChange(event:any) {
    this.formGroup.controls.plannedNetRevenue.setValue(event);
  }

  onDiscountChange(event:any) {
    this.formGroup.controls.discount.setValue(event);
  }

  onPlannedMarginChange(event:any) {
    this.formGroup.controls.plannedMargin.setValue(event);
  }

  onPlannedCostChange(event:any) {
    this.formGroup.controls.plannedCost.setValue(event);
  }

  onTotalPlannedHoursChange(event:any) {
    this.formGroup.controls.totalPlannedHours.setValue(event);
  }

  onTimeRevenueCapChange(event:any) {
    this.formGroup.controls.timeRevenueCap.setValue(event);
  }

  onResourceRateTypeSelection(event:any) {
    this.formGroup.controls.resourceRateType.setValue(event.value);
  }

  onUSIResourceRateTypeSelection(event:any) {
    this.formGroup.controls.usiResourceRateType.setValue(event.value);
  }

  onBudgetGroupSelection(event:any) {
    this.formGroup.controls.budgetGroup.setValue(event.value);
  }

  isReadOnly(formControlName: string): boolean {
    return FormValidations.isReadOnly(this.formGroup, formControlName);
  }

  showAsterisk(formControlName: string): boolean {
    return FormValidations.showAsterisk(this.formGroup, formControlName);
  }

  highlightRequiredField(formControlName: string): boolean {
    return FormValidations.highlightRequiredField(this.formSubmitted, this.formGroup, formControlName);
  }

  editedExternally(formControlName: string): boolean {
    return FormValidations.editedExternally(this.formGroup, formControlName);
  }

  hasMergeConflict(formControlName: string): boolean {
    return FormValidations.hasMergeConflict(this.formGroup, formControlName);
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameDealWbs(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }
}
