import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { FormGroup } from '@angular/forms';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
import { FormValidations } from 'src/shared/utils/form-validations';
import { FormListFields } from '../models/form-list-fields';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { AppSettings } from 'src/shared/app-settings';
import { SecurityService } from 'src/shared/services/security.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { MainService } from 'src/shared/services/main.service';

@Component({
  selector: 'app-participation',
  templateUrl: './participation.component.html',
  styleUrls: ['./participation.component.scss']
})
export class ParticipationComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();
  participatingManager: Array<PeoplePickerUser> = [];
  participationPartnerOptions: Array<DropdownItem> = [];
  participationPartnerLeftDCF: boolean;
  allowToDuplicateUser = false;

  @Input() formGroups: any;
  @Input() formSubmitted: boolean;
  @Output() Series24Name: EventEmitter<any> = new EventEmitter<any>();

  participationFormGroup: FormGroup;
  myDealFormGroup: any;

  constructor(
    private peoplepickerService: PeoplepickerService,
    private securityService: SecurityService,
    private dropdownService: DropdownService,
  private newDealService:NewdealService,
private spinnerService:SpinnerService,
private commonHelperService:CommonHelperService,
public mainService:MainService) {
  }

  ngOnInit() {
    this.participationFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
    this.GetS24Users();
    // this.dropdownService.fillDropdownFromSharepointGroup(AppSettings.series24Group)
    // .then(response => {
    //   this.participationPartnerOptions = response;
    // });
  }

   hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }
  GetS24Users() {
    this.newDealService.GetS24Users().subscribe(
      {
        next: (result: any) => {
        this.mainService.myDealS24List=result;
          const s24dropdownItems = result.map((item: any) => {
            const dropdownItem = new DropdownItem();
            dropdownItem.key = item.Email;
            dropdownItem.value = item.LastName+', '+item.FirstName; 
            return dropdownItem;
          }).sort((a:any,b:any)=>a.value > b.value?1:-1);
          this.participationPartnerOptions = s24dropdownItems;  
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
        }
      })
  }
  onDerivedAtAddressSameClientAddresSelection(event:any) {
    this.participationFormGroup.controls.derivedAtAddressSameClientAddres.setValue(event);
    this.evaluateDerivedAtAddressDependency(event);
  }

  evaluateDerivedAtAddressDependency(value:any) {
    if (value === 'NO') {
      this.participationFormGroup.controls.derivedAtAddress.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.derivedAtAddress));
    }
  }

  onParticipatingPartnerSelection(event:any) {
    this.myDealFormGroup.controls.participatingPartner.setValue(event.key);
    this.Series24Name.emit(event.value);
  }

  getControlName(formControlName: string) {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return formGroup.get(formControlName);
    }
    return null;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    return FormValidations.editedExternally(formGroup, formControlName);
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    return FormValidations.hasMergeConflict(formGroup, formControlName);
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  onSelectedPeoplePickerUser(user: PeoplePickerUser, inputName: string) {
    const peoplepickerObject:any = this.participationFormGroup.get(inputName);
    //this.peoplepickerService.getUserId(user.EntityData.Email)
    // this.peoplepickerService.getUserId(user.mail,"")
    //   .then((response:any) => {
        const values = peoplepickerObject.value;
        peoplepickerObject.setValue({ results: [...values.results, user] });
    //});
  }

  onRemovedPeoplePickerUser(user: PeoplePickerUser, inputName: string) {
  const peoplepickerObject:any = this.participationFormGroup.get(inputName);
 // this.peoplepickerService.getUserId(user.EntityData.Email)
//  this.peoplepickerService.getUserId(user.mail,"")
//     .then((response:any) => {
      const values = peoplepickerObject.value;
      peoplepickerObject.setValue({ results: values.results.filter((x:any) => x.displayName !== user.displayName) });
  //});
  }

  setParticipatingManager(participatingManager:any) {
    this.participatingManager = participatingManager;
  }

  setSeries24WarningMessage(value:any) {
    this.participationPartnerLeftDCF = value;
  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.participationFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
