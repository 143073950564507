import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-active-deal-wbs',
  templateUrl: './active-deal-wbs.component.html',
  styleUrls: ['./active-deal-wbs.component.scss']
})
export class ActiveDealWbsComponent implements OnInit {

  

  @Output()
  adminActiveWBSAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  @Input() isNotificationSent: boolean;

  //isActiveWBSNotificationSent:boolean = false;

  ngOnInit() {
  }
  adminActiveWBS() {
    this.adminActiveWBSAction.emit(true);
    //this.isNotificationSent =true;
  }
}
