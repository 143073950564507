import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { Injectable } from '@angular/core';
import { IRolesValidator } from 'src/shared/interfaces/new-deal-setup-roles-validator';
import { NewDealSetupService } from 'src/shared/services/new-deal-setup.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

@Injectable({
    providedIn: 'root'
})
export class RolesValidatorService implements IRolesValidator {
    constructor(
        private mainService: MainService,
        private newDealSetupService: NewDealSetupService,
        public globalHelperService:GlobalHelperService
      ) { }

      validateFormRoles(roleList: Array<any>, userId: number, formId?: number, myDealformId?: any): Promise<Array<string>> {
        return new Promise<Array<string>>((resolve, reject) => {
            const formGroups = [AppSettings.series24Group, AppSettings.adminsGroup(this.globalHelperService.currentAccessPage?.toString())];
            let roles = roleList.filter(item => {
                return (formGroups.find(x => x === item) !== undefined) ? true : false;
            });

            if (formId) {
                Promise.all([
                    this.mainService.isDealTeamMember(AppSettings.dealTeamTableList, myDealformId, userId),
                    this.newDealSetupService.isAssignedSeries24(AppSettings.myDealFormList, myDealformId, userId )])
                    .then(([dealTeamResponse, series24Response]) => {
                    if (dealTeamResponse) {
                        roles.push(AppSettings.dealTeamMembersGroup);
                    }
                    if (!series24Response) {
                        roles = roles.filter(item => {
                            return item !== AppSettings.series24Group;
                        });
                    }
                    resolve(roles);
                }).catch(error => {
                    reject(error);
                });
            } else {
                roles = roles.filter(item => {
                    return item !== AppSettings.series24Group;
                });
                //adding this roles to get dealteam member permission by default
                roles.push(AppSettings.dealTeamMembersGroup);
                resolve(roles);
            }
        });
    }
}
