<span *ngIf="!isNewDealPageAccessed && !isToolTip ">
  <ng-template #popContent>
  <ng-container *ngTemplateOutlet="tooltipTemplate ? tooltipTemplate : tooltipText"></ng-container>
</ng-template>
<ng-template #popTitle>{{tooltipTitle}}</ng-template>

  <div class="tooltip-container" id="popover-terminationform">
    <div *ngIf="!keepOpen"
      class="cursor-pointer"
      triggers="mouseenter:mouseleave"
      [ngStyle]="active && {'color': '#337AB7'}"
      [placement]="placement"
      [ngClass]="tooltipIcon"
      [ngbPopover]="tooltipTemplate ? tooltipTemplate : popContent"
      [popoverTitle]="popTitle">
    </div>

  <div *ngIf="keepOpen" #p="ngbPopover"
    class="cursor-pointer"
    [ngStyle]="active && {'color': '#337AB7'}"
    [placement]="placement"
    [ngClass]="tooltipIcon"
    [ngbPopover]="tooltipTemplate ? tooltipTemplate : popContent"
    [popoverTitle]="popTitle"
    [triggers]="triggers"
    [autoClose]="autoClose"
    (mouseover)="openOnFocus ? p.open() : 'return false;'"
    (click)="p.open()">
  </div>
</div>
</span>

<!-- NewDeal popup -->
<span *ngIf="isNewDealPageAccessed">
<ng-template #popContent>
  <div [innerHTML]="tooltipText"></div>
</ng-template>
<ng-template  #popTitle>{{tooltipTitle}}</ng-template>

<div class="tooltip-container">
<div  *ngIf="!keepOpen"
  class="glyphicon glyphicon-question-sign section-question-sign"
  placement="bottom"
  triggers="mouseenter:mouseleave"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle">
</div>

<div *ngIf="keepOpen" #p="ngbPopover"
  class="glyphicon glyphicon-question-sign section-question-sign"
  placement="bottom"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
  [autoClose]="true"
  (mouseover)="p.open()"
  (click)="p.open()">
</div>
</div>
</span>
<!-- Termination dashboard tool tip icon popup-->
<span *ngIf="isToolTip">
  <ng-template #popContent>
    <ng-container *ngTemplateOutlet="tooltipTemplate ? tooltipTemplate : tooltipText"></ng-container>
  </ng-template>
  <ng-template #popTitle>{{tooltipTitle}}</ng-template>

  <div class="tooltip-container" id="tooltip">
    <div *ngIf="!keepOpen"
      class="cursor-pointer"
      triggers="mouseenter:mouseleave"
      [ngStyle]="active && {'color': '#337AB7'}"
      [placement]="placement"
      [ngClass]="tooltipIcon"
      [ngbPopover]="tooltipTemplate ? tooltipTemplate : popContent"
      [popoverTitle]="popTitle">
    </div>

    <div *ngIf="keepOpen" #p="ngbPopover"
      class="cursor-pointer"
      [ngStyle]="active && {'color': '#337AB7'}"
      [placement]="placement"
      [ngClass]="tooltipIcon"
      [ngbPopover]="tooltipTemplate ? tooltipTemplate : popContent"
      [popoverTitle]="popTitle"
      [triggers]="triggers"
      [autoClose]="autoClose"
      (mouseover)="openOnFocus ? p.open() : 'return false;'"
      (click)="p.open()">
    </div>
  </div>
</span>
