import { Component, OnInit, Input } from '@angular/core';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormGroup } from '@angular/forms';
import { FormValidations } from 'src/shared/utils/form-validations';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { FormListFields } from '../models/form-list-fields';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { AppSettings } from 'src/shared/app-settings';
import { DropdownService } from 'src/shared/services/dropwdown.service';

@Component({
  selector: 'app-international-work-indicators',
  templateUrl: './international-work-indicators.component.html',
  styleUrls: ['./international-work-indicators.component.scss']
})
export class InternationalWorkIndicatorsComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();
  employessWorkingPlacesList: Array<DropdownItem> = [];

  @Input() formGroups: any;
  @Input() formSubmitted: boolean;
  
  intWorkIndicatorsFormGroup: FormGroup;
  myDealFormGroup: any;

  constructor(private tooltipHelperService: TooltipHelperService,
              private dropdownService: DropdownService) { }

  ngOnInit() {
    this.intWorkIndicatorsFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
    this.loadDropDown();
    // this.dropdownService.fillDropdownOptions(AppSettings.myDealFormList, FormListFields.whereWorkMemberFirms)
    // .then(response => {
    //   this.employessWorkingPlacesList = response;
    // })
    // .catch(error => {
    // });
  }
  loadDropDown(){
      this.employessWorkingPlacesList = AppSettings.whereWorkMemberFirms;
  }
  onAreResourcesWorkOutsideSelection(event:any) {
    this.myDealFormGroup.controls.areResourcesWorkOutside.setValue(event);
  }

  onEmployessWorkingPlacesSelection(event:any) {
    this.myDealFormGroup.controls.whereWorkMemberFirms.setValue(event.value);
  }

  onOtherMemberFirmsParticipatingSelection(event:any) {
    this.myDealFormGroup.controls.otherMemberFirmsParticipating.setValue(event);
  }

  onMemberFirmTaxablePresenceSelection(event:any) {
    this.myDealFormGroup.controls.memberFirmTaxablePresence.setValue(event);
  }

  onInternationalEngagementSelection(event:any) {
    this.myDealFormGroup.controls.isInternationalEngagement.setValue(event);
    this.evaluateIsThisAInternationalEngagementDependency(event);
  }

  evaluateIsThisAInternationalEngagementDependency(value: string) {
    if (value === 'YES') {
      this.myDealFormGroup.controls.areResourcesWorkOutside.enable();
      this.myDealFormGroup.controls.memberFirmTaxablePresence.enable();
      this.myDealFormGroup.controls.otherMemberFirmsParticipating.enable();
      this.myDealFormGroup.controls.whereWorkMemberFirms.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.areResourcesWorkOutside));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.memberFirmTaxablePresence));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.otherMemberFirmsParticipating));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.whereWorkMemberFirms));
    }
  }

  getControlName(formControlName: string):any {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return formGroup.get(formControlName);
    }
    return null;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameDealWbs(fieldName);
  }

  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.myDealFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
