import {
    FormControl,
    ValidatorFn,
    AbstractControlOptions,
    AsyncValidatorFn
  } from '@angular/forms'
  
  export class CustomFormControl extends FormControl {
    displayName: string;
    readOnly: boolean;
    editedExternally: boolean=false;
    conflictOnMerge: boolean=false;
  
    constructor(
      displayName: string,
      formState: any = null,
      validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
      asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
      super(formState, validatorOrOpts, asyncValidator);
      this.displayName = displayName;
      this.readOnly = false;
    }
  
    isReadOnly() {
      return this.readOnly;
    }
  
    setReadOnly(isReadOnly: boolean = true) {
      if (!this.disabled && isReadOnly) {
        this.disable();
        this.readOnly = true;
      } else {
        if (this.isReadOnly() && !isReadOnly) {
          this.readOnly = false;
          this.enable();
        }
      }
    }
   isReadonlySupervisorySection(){
    this.disable();
    this.readOnly = true;
   }
    setEditedExternally(value: boolean) {
      this.editedExternally = value;
    }
  
    isEditedExternally() {
      return this.editedExternally;
    }
  
    setConflictOnMerge(value: boolean) {
      this.conflictOnMerge = value;
    }
  
    hasConflictOnMerge() {
      return this.conflictOnMerge;
    }
  
  }
  