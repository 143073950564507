import { Injectable } from '@angular/core';
import { Spcrud } from './spcrud';
import { RootSiteSpcrud } from './root-site-spcrud';
import { AppSettings } from '../app-settings';
import { CustomSiteSpcrud } from './custom-site-spcrud';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor(private spcrud: Spcrud,
    private rootSiteSpcrud: RootSiteSpcrud,
    private customSiteSpcrud?: CustomSiteSpcrud) { }

getSpcrud(listName?: string): Spcrud | any {
if (AppSettings.rootSiteListArray.find( element => element === listName)) {
return this.rootSiteSpcrud;
// } else if (AppSettings.customSiteListArray.find( element => element === listName)) {
// this.customSiteSpcrud?.setCustomBaseUrl(AppSettings.crossBorderBaseUrl);
return this.customSiteSpcrud;
} else {
return this.spcrud;
}
}
}
