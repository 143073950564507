import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppSettings } from "src/shared/app-settings";
import { DropdownItem } from "src/shared/models/dropdown-item";
import { DropdownService } from "src/shared/services/dropwdown.service"; 
import { SecurityService } from "src/shared/services/security.service";
import { FormValidations } from "src/shared/utils/form-validations";
import { FormListFields } from "../shared/models/form-list-fields";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";

@Component({
    selector: 'app-quality-of-earnings',
    templateUrl: './quality-of-earnings.component.html',
    styleUrls: ['./quality-of-earnings.component.scss']
  })
export class QualityOfEarningsComponent implements OnInit {
    @Input() formSubmitted: boolean;
    @Input() formGroups: Array<FormGroup>;
    @Input() notApplicableEnabled = true;
    qualityOfEarningsFormGroup:any;
    stageOptions:Array<DropdownItem>=[];
    sharePointMapper:any = new ComplianceSharepointMapper();

    constructor(private dropdownService: DropdownService,private securityService: SecurityService,){}
    ngOnInit(): void {
      
      this.qualityOfEarningsFormGroup = this.formGroups[0];

      // this.dropdownService.fillDropdownOptions(AppSettings.closingChecklistForm,FormListFields.qofEStage).
      // then(response=>{
      //    this.stageOptions = response;
      // });
      
      this.stageOptions = AppSettings.StageArrayList;
    }
    //standard functions

    setSharepointMapper(mapper: ComplianceSharepointMapper) {
      this.sharePointMapper = mapper;
    }
  
    getControlName(formControlName: string) {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return formGroup.get(formControlName);
      }
      return null;
    }
    
    isReadOnly(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.isReadOnly(formGroup, formControlName);
      }
      return false;
    }
  
    showAsterisk(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.showAsterisk(formGroup, formControlName);
      }
      return false;
    }
  
    highlightRequiredField(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
      }
      return false;
    }
  
    editedExternally(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.editedExternally(formGroup, formControlName);
      }
      return false;
    }
  
    hasMergeConflict(formControlName: string): boolean {
      const formGroup = this._getFormGroup(formControlName);
      if (formGroup) {
        return FormValidations.hasMergeConflict(formGroup, formControlName);
      }
      return false;
    }
  
    hasPermission(action: string): boolean {
      return !!this.securityService.hasPermission(action);
    }
  
    private _getFormGroupControl(formControlName: string): any {
      const formGroup = this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
      return formGroup.get(formControlName);
    }
  
    private _getFormGroup(formControlName: string): FormGroup {
      return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
    }

    private _setAsDisabled(formControlName: string) {
      const controlName = this.qualityOfEarningsFormGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }

    evaluatesellSideQofEPerformed(value:any) {
        if(value === 'YES'){
          this.qualityOfEarningsFormGroup.controls.qofEStage.enable();
          this.qualityOfEarningsFormGroup.controls.whatFirmPerformed.enable();
          this.qualityOfEarningsFormGroup.controls.mainContact.enable();
          this.qualityOfEarningsFormGroup.controls.qofEDeloitteBranded.enable();
        }
      else{
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.qofEStage));
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.whatFirmPerformed));
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.mainContact));
        this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.qofEDeloitteBranded));
      }
    }

    isQofEPerformed(event:any){
      this.qualityOfEarningsFormGroup.controls.sellsideQofEPerformed.setValue(event);
      this.evaluatesellSideQofEPerformed(event);
    }
  
    onQofEStageSelection(event:any){
      this.qualityOfEarningsFormGroup.controls.qofEStage.setValue(event.key);
    }

    isQofEDeloitteBranded(event:any){
      this.qualityOfEarningsFormGroup.controls.qofEDeloitteBranded.setValue(event);
    }
}