import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormValidations } from 'src/shared/utils/form-validations';
import { FormListFields } from '../models/form-list-fields';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { AppSettings } from 'src/shared/app-settings';
import { DropdownItem } from 'src/shared/models/dropdown-item';

@Component({
  selector: 'app-billing-schedule',
  templateUrl: './billing-schedule.component.html',
  styleUrls: ['./billing-schedule.component.scss']
})

export class BillingScheduleComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();
  billingPlanTypeOptions: Array<DropdownItem> = [];
  billingIntervalsOptions: Array<DropdownItem> = [];

  @Input() formGroup: any;
  @Input() formSubmitted: boolean;

  constructor(private dropdownService: DropdownService) { }

  ngOnInit() {
    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.billingPlanType)
    // .then(response => {
    //   this.billingPlanTypeOptions = response;
    // }).catch(error => {
    // });

    // this.dropdownService.fillDropdownOptions(AppSettings.dealWbsSetupFormList, FormListFields.billingIntervals)
    // .then(response => {
    //   this.billingIntervalsOptions = response;
    // }).catch(error => {
    // });
  }

  get appSettings() { return AppSettings; }

  eventDateSelected(event:any, controlName: string) {
    this.formGroup.get(controlName).setValue(event);
  }

  onBillingPlanSelection(event:any) {
    this.formGroup.controls.billingPlanType.setValue(event.value);
  }

  onBillingIntervalsSelection(event:any) {
    this.formGroup.controls.billingIntervals.setValue(event.value);
  }

  isReadOnly(formControlName: string): boolean {
    return FormValidations.isReadOnly(this.formGroup, formControlName);
  }

  showAsterisk(formControlName: string): boolean {
    return FormValidations.showAsterisk(this.formGroup, formControlName);
  }

  highlightRequiredField(formControlName: string): boolean {
    return FormValidations.highlightRequiredField(this.formSubmitted, this.formGroup, formControlName);
  }

  editedExternally(formControlName: string): boolean {
    return FormValidations.editedExternally(this.formGroup, formControlName);
  }

  hasMergeConflict(formControlName: string): boolean {
    return FormValidations.hasMergeConflict(this.formGroup, formControlName);
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  disabledFunc() {
    let billingTypeFormControlName = this.formGroup.get('billingPlanType').value;

    if (billingTypeFormControlName === AppSettings.billingPlanTypeDefaultValue) {
      this.formGroup.get(AppSettings.billingIntName).enable();
      this.formGroup.get(AppSettings.billingIfName).disable();
      this.formGroup.get(AppSettings.billingIfName).setValue(null);
    } else if (billingTypeFormControlName === AppSettings.billingPlanOtherValue) {
      this.formGroup.get(AppSettings.billingIfName).enable();
      this.formGroup.get(AppSettings.billingIntName).disable();
      this.formGroup.get(AppSettings.billingIntName).setValue(null);
    }
  }
}
