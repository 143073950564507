import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MainService } from '../../../shared/services/main.service';
import { BaseService } from '../../../shared/services/base.service';
import { DropdownService } from '../../../shared/services/dropwdown.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainServiceHelper } from '../../../shared/services/main-service-helper';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { Spcrud } from '../../../shared/services/spcrud';
import { AuditService } from '../shared/services/audit-service';
import { ClosingChecklistDashboardBoardComponent } from './closing-checklist-dashboard.component';
import { ClosingChecklistTableComponent } from './closing-checklist-table/closing-checklist-table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "src/shared/SharedModule";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgbModule  
      ],
    declarations:[
        ClosingChecklistDashboardBoardComponent,
        ClosingChecklistTableComponent,
    ],
    providers:[
        // MainService,
        // DropdownService,
        // BaseService,
        // MainServiceHelper,
        // SpinnerService,
        // Spcrud,
        // AuditService,
        DatePipe    
    ],
})

export class ClosingChecklistDashboardBoardModule{}