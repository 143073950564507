<div [formGroup]="crossBorderFormGroup" class="content-section" id="cross-border">
  <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('isDTTLInvolved')"
          [class.edited-externally]="editedExternally('isDTTLInvolved')"
            [class.conflict-on-merge]="hasMergeConflict('isDTTLInvolved')">Did the engagement involve a Deloitte Touche Tohmatsu Limited (DTLL) Member Firm (Cross Border Transaction)?</span>
          <span *ngIf="showAsterisk('isDTTLInvolved')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
          <app-toggle-yes-no  #yesNoRecurringWBS
            [value]="crossBorderFormGroup.get('isDTTLInvolved')?.value"
            [readonly]="isReadOnly('isDTTLInvolved')"
            (handleToggleYesNo)="onIsDTTLInvolvedSelection($event)">
          </app-toggle-yes-no>
      </div>
  </div>
  <div class="row">
      <span class="title-section">Cross Border</span>
  </div>
  <div class="row">
    <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('dttlInvolved')"
        [class.edited-externally]="editedExternally('dttlInvolved')"
            [class.conflict-on-merge]="hasMergeConflict('dttlInvolved')">Which DTTL member firms were involved <br> in the engagement?</span>
        <span *ngIf="showAsterisk('dttlInvolved')" class="red-asterik"></span>
    </div>
    <div class="col-xs-7">
      <app-dttl-table #dttlTable
      class="table-disable"
      [readonly]="readOnly || isReadOnly('dttlInvolved')"
      [class.table-disable]="!isReadOnly('dttlInvolved')"
      [dttlTableFormGroup]="crossBorderFormGroup.get('dttlInvolved')"
      [dttlValues]="dttlValues"
      [firstColumnVisible]="false"
      [secondColumnVisible]="false"
      [thirdColumnVisible]="false"
      (dttlRowToStore)="onDTTLLRowSelection($event)">
    </app-dttl-table>
    </div>
</div>
  <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isXBEngagementAn')"
        [class.edited-externally]="editedExternally('isXBEngagementAn')"
        [class.conflict-on-merge]="hasMergeConflict('isXBEngagementAn')">Engagement Industry Sector</span>
        <span *ngIf="showAsterisk('isXBEngagementAn')" class="red-asterik"></span>
      </div>
      <div class="col-xs-7">
        <app-dropdown
          [optionsList]="isXBEngagementAnOptions"
          [keySelected]="crossBorderFormGroup.get('isXBEngagementAn')?.value"
          [readonly]="isReadOnly('isXBEngagementAn')"
          [disabled]="crossBorderFormGroup.get('isXBEngagementAn')?.disabled"
          (optionSelected)="onDealTypeSelection($event)">
        </app-dropdown>
      </div>
  </div>
</div>
