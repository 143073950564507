import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormValidations } from 'src/shared/utils/form-validations';
import { FormListFields } from '../models/form-list-fields';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';

@Component({
  selector: 'app-archive-cmr',
  templateUrl: './archive-cmr.component.html',
  styleUrls: ['./archive-cmr.component.scss']
})

export class ArchiveCmrComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();

  archiveCmrFormGroup: FormGroup;
  myDealFormGroup: any;
  wbsCode = 'wbsCode';
  prdCode = 'prdCode';

  @Input() formGroups: any;
  @Input() formSubmitted: boolean;

  constructor(private tooltipHelperService : TooltipHelperService) {}

  ngOnInit() {
    this.archiveCmrFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
  }

  getControlName(formControlName: string):any {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return formGroup.get(formControlName);
    }
    return null;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  onIsThisEngagementAuditSelection(event:any) {
    this.archiveCmrFormGroup.controls.isEngagementAudit.setValue(event);
    this.evaluateEngagementAuditDependency(event);
  }

  evaluateEngagementAuditDependency(value:any) {
    if (value === 'YES') {
      this.myDealFormGroup.controls.currentAuditor.enable();
      this.myDealFormGroup.controls.previousAuditor.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.previousAuditor));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.currentAuditor));
    }
  }

  onIsThisArchiveRequiredSelection(event:any) {
    if (this.archiveCmrFormGroup) {
      this.archiveCmrFormGroup.controls.isArchiveRequired.setValue(event);
    }
    return false;
  }

  onIsThisWasCMRPerformedSelection(event:any) {
    if (this.archiveCmrFormGroup) {
      this.archiveCmrFormGroup.controls.wasCMRPerformed.setValue(event);
    }
    return false;
  }

  enableWbsCode() {
    this.myDealFormGroup.controls.wbsCode.enable();
  }

  disableWbsCode() {
    this._setAsDisabled(this.wbsCode);
  }

  enablePrdCode(){
    this.myDealFormGroup.controls.prdCode.enable();
  }

  disablePrdCode(){
    this._setAsDisabled(this.prdCode);
  }
  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameDealWbs(fieldName);
  }
  
  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }
  
  private _setAsDisabled(formControlName: string) {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      const controlName = formGroup.get(formControlName);
      if (controlName) {
        controlName.disable();
        controlName.setValue(null);
      }
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
