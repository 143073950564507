import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppSettings } from 'src/shared/app-settings';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MSALInstanceFactory } from './MSALInstanceFactory';
import { TokenService } from './services/token.service';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AddUserPopupComponent } from './admin/add-user-popup/add-user-popup.component';
import { UserManagementComponent } from './admin/usermanagement/usermanagement.component';
import { AdminService } from './services/admin.service';
import {AuditService} from './services/audit.service';
import { DateService } from './services/date.service';
import {ContentService} from './services/content.service';
import { PeoplepickerSearchComponent } from './admin/peoplepicker-search/peoplepicker-search.component';
import { PeoplePickerService } from './services/peoplepicker.service';
import { FormsModule } from '@angular/forms';
import { ClickElsewhereDirective } from './click-elsewhere.directive';
import { SharedModule } from '../shared/SharedModule';
import { CrosbocrossborderformComponent } from './crosbocrossborderform/crosbocrossborderform.component';
import { CrossborderDashboardDetailsComponent } from './crossborder-dashboard-details/crossborder-dashboard-details.component';
import { CommonModule, DatePipe } from '@angular/common';
import {WindowRefServic} from './services/WindowRefService';
import { MsalGuard, MsalModule, MsalRedirectComponent, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from "src/environments/environment";
import { ContentManagementComponent } from './contentManagement/content-management/content-management.component';
import { AddContentPopupComponent } from './contentManagement/add-content-popup/add-content-popup.component';
import { AuditTrailComponent } from './contentManagement/audit-trail/audit-trail.component';
import { ClientOverviewComponent } from './newdeal/client-overview/client-overview.component'
import {PeoplepickerComponent} from '../shared/components/peoplepicker/peoplepicker.component'
import {NumericComponent} from '../shared/components/numeric/numeric.component'
import {CommentsComponent} from './newdeal/comments/comments.component';
import { NewDealSetupService } from '../shared/services/new-deal-setup.service';
import {CommentsSectionComponent} from './newdeal/comments-section/comments-section.component'
import {DealTeamTableComponent} from './newdeal/deal-team-table/deal-team-table.component'
import {MercuryOpportunityComponent} from './newdeal/mercury-opportunity/mercury-opportunity.component'
import {PrdComponent} from './newdeal/prd/prd.component'
import {SidebarComponent} from './newdeal/sidebar/sidebar.component'
import {SecurityService} from '../shared/services/security.service'
import {RolesValidatorService} from '../shared/utils/new-deal-setup-roles-validator-service'
import {FilesService} from '../shared/services/files.service'
import {NewDealSetupComponent} from './newdeal/new-deal-setup/new-deal-setup.component'
import {ClientSetupComponent} from './newdeal/client-setup/client-setup.component'
import {SpinnerService} from '../shared/services/spinner.service'
import { ActiveNewDealComponent } from './newdeal/active-new-deal/active-new-deal.component';
import { AdminReviewComponent } from './newdeal/admin-review/admin-review.component';
import { ProjectOverviewComponent } from './newdeal/project-overview/project-overview.component';
import { NewDealComponent } from './newdeal/new-deal/new-deal.component';
import { Spcrud } from 'src/shared/services/spcrud';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { NewdealService } from './services/newdeal.service';
import { AlertModule } from './_alert';
import { EngagementDashboardComponent } from './engagementDashboard/engagement-dashboard/engagement-dashboard.component';
import { EngagementTableComponent } from './engagementDashboard/engagement-table/engagement-table.component';
import { EngagementDashboardModule } from './engagementDashboard/engagement-dashboard.module';
import { EngagementHealthObjectModule } from './engagement-health-object/engagement-health-object.module';
import { CommonHelperService } from './commonHelper/common-helper.service';
import { DealWbsSetupModule } from './deal-wbs-setup/deal-wbs-setup.module';
import { ClosingChecklistModule } from './closing-checklist-form/closing-checklist-form.module';
import { TerminationFormComponent } from './termination-form/termination-form/termination-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TerminationFormService } from './services/terminationform.service';
import { TerminationNotifyPopupComponent } from './termination-form/termination-form-popup/termination-form-popup.component';
import { TerminationDashboardComponent } from './termination-dashboard/termination-dashboard.component';
import { TerminationFormSignatureTable } from 'src/shared/components/termination-form-signature-table/termination-form-signature-table';
import { GlobalHelperService } from './globalHelper/global-helper.service';
import {DateFormatterService} from '../shared/utils/utils-date'
import { ClosingChecklistDashboardBoardModule } from './closingChecklistDashboard/closing-checklist-dashboard/closing-checklist-dashboard.module';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
	UserManagementComponent,  
    PeoplepickerSearchComponent,
	AddUserPopupComponent,  
  ClickElsewhereDirective,
  CrosbocrossborderformComponent, TerminationFormComponent,
  CrossborderDashboardDetailsComponent,
  ContentManagementComponent,
  AddContentPopupComponent,
    AuditTrailComponent,
  ClientOverviewComponent, 
  CommentsComponent, CommentsSectionComponent, DealTeamTableComponent,
  MercuryOpportunityComponent, PrdComponent,SidebarComponent, NewDealSetupComponent,AdminReviewComponent,ActiveNewDealComponent,
  ClientSetupComponent,ProjectOverviewComponent,NewDealComponent,
  AuditTrailComponent,
  TerminationNotifyPopupComponent,TerminationDashboardComponent , TerminationFormSignatureTable
  
  ],
  imports: [
      BrowserModule, HttpModule, CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
          NgbModule,
          AlertModule,
          SharedModule,EngagementDashboardModule,EngagementHealthObjectModule,DealWbsSetupModule, ClosingChecklistModule,ClosingChecklistDashboardBoardModule,
          MsalModule.forRoot(new PublicClientApplication({
            auth: {
              clientId:environment.clientId,
              authority:"https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
              redirectUri:environment.redirectUrl
            },
            cache: {
              cacheLocation: 'localStorage'
            }
          }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
              scopes: ['user.read']
            }
          }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map<string, string[]>(),
          })
        ],
   providers: [
MsalService,
TokenService,
AdminService,
AuditService,
DateService,
CommonHelperService,
MsalGuard, 
ContentService,
PeoplePickerService,
WindowRefServic,
DatePipe, 
NewdealService,
NewDealSetupService,DateFormatterService,SecurityService,
RolesValidatorService,FilesService, SpinnerService,Spcrud
       , TooltipHelperService, TerminationFormService,GlobalHelperService
],
  bootstrap: [AppComponent,MsalRedirectComponent]

})
export class AppModule { }
