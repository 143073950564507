<div [formGroup]="timelineContentFormGroup" id="dates-timeline" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span>Dates/Timeline</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>
                Enter dates. Use your best judgement if you are unsure of the exact date (i.e. for date entered market). 
            The purpose of this is to track typical timeline of a deal from pitch/engagement to close.
            </p>
        </span>
    </div>
    <div id="timeline-content-section" class="content-section">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('pitchedDate')"
                    [class.edited-externally]="editedExternally('pitchedDate')"
                    [class.conflict-on-merge]="hasMergeConflict('pitchedDate')"
                    >Pitched</span>
                    <span *ngIf="showAsterisk('pitchedDate')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-date-picker #PitchedDate
                    [selectedDate]="timelineContentFormGroup.get('pitchedDate')?.value"
                    [readonly]="isReadOnly('pitchedDate')"
                    (dateSelected)="onPitchedDateSelection($event)">
                  </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('signedEngagementLetter')"
                    [class.edited-externally]="editedExternally('signedEngagementLetter')"
                    [class.conflict-on-merge]="hasMergeConflict('signedEngagementLetter')">Signed Engagement Letter</span>
                    <span *ngIf="showAsterisk('signedEngagementLetter')" class="red-asterik"></span>
                    
                </div>
                <div class="col-xs-8">
                    <app-date-picker #SignedEngagementLetter
                    [selectedDate]="timelineContentFormGroup.get('signedEngagementLetter')?.value"
                    [readonly]="isReadOnly('signedEngagementLetter')"
                    (dateSelected)="onEngagementLetterSelection($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('enteredMarket')"
                    [class.edited-externally]="editedExternally('enteredMarket')"
                    [class.conflict-on-merge]="hasMergeConflict('enteredMarket')"
                    >Entered Market</span>
                    <span *ngIf="showAsterisk('enteredMarket')" class="red-asterik"></span>
                    
                </div>
                <div class="col-xs-8">
                    <app-date-picker #EnteredMarket
                    [selectedDate]="timelineContentFormGroup.get('enteredMarket')?.value"
                    [readonly]="isReadOnly('enteredMarket')"
                    (dateSelected)="onEnteredMarketSelection($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('receivedIoiTermSheets')"
                    [class.edited-externally]="editedExternally('receivedIoiTermSheets')"
                    [class.conflict-on-merge]="hasMergeConflict('receivedIoiTermSheets')">Received IOIs/ Term Sheets</span>
                    <span *ngIf="showAsterisk('receivedIoiTermSheets')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-date-picker #ReceivedIoiTermSheets
                    [selectedDate]="timelineContentFormGroup.get('receivedIoiTermSheets')?.value"
                    [readonly]="isReadOnly('receivedIoiTermSheets')"
                    (dateSelected)="onIoiTermSheetsSelection($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('receivedLOIs')"
                    [class.edited-externally]="editedExternally('receivedLOIs')"
                    [class.conflict-on-merge]="hasMergeConflict('receivedLOIs')">Received LOIs</span>
                    <span *ngIf="showAsterisk('receivedLOIs')" class="red-asterik"></span>
                    
                </div>
                <div class="col-xs-8">
                    <app-date-picker #ReceivedLOIs
                    [selectedDate]="timelineContentFormGroup.get('receivedLOIs')?.value"
                    [readonly]="isReadOnly('receivedLOIs')"
                    (dateSelected)="onReceivedLoiSelection($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('signedLOI')"
                    [class.edited-externally]="editedExternally('signedLOI')"
                    [class.conflict-on-merge]="hasMergeConflict('signedLOI')">Signed LOI</span>
                    <span *ngIf="showAsterisk('signedLOI')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-date-picker #SignedLOI
                    [selectedDate]="timelineContentFormGroup.get('signedLOI')?.value"
                    [readonly]="isReadOnly('signedLOI')"
                    (dateSelected)="onSignedLoiSelection($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('transactionClose')"
                    [class.edited-externally]="editedExternally('transactionClose')"
                    [class.conflict-on-merge]="hasMergeConflict('transactionClose')">Transaction Close</span>
                    <span *ngIf="showAsterisk('transactionClose')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-date-picker #TransactionClose
                    [selectedDate]="timelineContentFormGroup.get('transactionClose')?.value"
                    [readonly]="isReadOnly('transactionClose')"
                    (dateSelected)="onTransactionCloseSelection($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div div-three-toggle-input">
                    <span class="span-to-highlight dependent-field span-three-toggle-input"
                    [class.highlight-warning]="highlightRequiredField('simultaneousSignClose')"
                    [class.edited-externally]="editedExternally('simultaneousSignClose')"
                    [class.conflict-on-merge]="hasMergeConflict('simultaneousSignClose')">Simultaneous Sign/Close?</span>
                    <span *ngIf="showAsterisk('simultaneousSignClose')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8 btn-align">
                    <app-toggle-yes-no  #SimultaneousSignClose
                    [value]="timelineContentFormGroup.get('simultaneousSignClose')?.value"
                    [disabled]="timelineContentFormGroup.get('simultaneousSignClose')?.disabled"
                    [readonly]="isReadOnly('simultaneousSignClose')"
                    (handleToggleYesNo)="isSimultaneousSignClose($event)"
                    [notApplicableEnabled]="notApplicableEnabled"
                    class="buttons-app-yes-no">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight dependent-field"
                    [class.highlight-warning]="highlightRequiredField('signDate')"
                    [class.edited-externally]="editedExternally('signDate')"
                    [class.conflict-on-merge]="hasMergeConflict('signDate')">If no, sign date?</span>
                    <span *ngIf="showAsterisk('signDate')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-date-picker #SignDate
                    [selectedDate]="timelineContentFormGroup.get('signDate')?.value"
                    [readonly]="isReadOnly('signDate')"
                    [disabled]="timelineContentFormGroup.get('signDate')?.disabled"
                    (dateSelected)="onSignDateSelection($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12" id="comments-dates-timeline">
                <div class="col-xs-4 required-div">
                    <div id="comments-date-timeline-label">
                        <span class="span-to-highlight"
                        [class.highlight-warning]="highlightRequiredField('commentAffectingTimeline')"
                        [class.edited-externally]="editedExternally('commentAffectingTimeline')"
                        [class.conflict-on-merge]="hasMergeConflict('commentAffectingTimeline')">Comment on any unusual circumstances affecting timeline</span>
                        <span *ngIf="showAsterisk('commentAffectingTimeline')" class="red-asterik"></span>
                        <app-popover
                        [isComments]="true"
                    [tooltipText]="getTooltipText('commentAffectingTimeline')">
                  </app-popover>
                        
                    </div>
                </div>
                <div class="col-xs-8 comments-align">
                  <textarea type="text" rows="3" placeholder="You can add comments here" [formControl]="timelineContentFormGroup.get('commentAffectingTimeline')"
                            class="input-data-text text-area-style"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
