import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DynamicTableDropdownItemList } from 'src/shared/models/dynamic-table-dropdown-item-list';
import { DropdownItem } from '../../models/dropdown-item';
import { SelectDynamicTableItem } from '../../models/select-dynamic-table-item';
import { SelectedDynamicTableValue } from './select-dynamic-table.interface';
import { DynamicTableAction } from '../../models/dynamic-table-action';

@Component({
  selector: 'app-select-dynamic-table',
  templateUrl: './select-dynamic-table.component.html',
  styleUrls: ['./select-dynamic-table.component.scss']
})
export class SelectDynamicTableComponent implements OnChanges {
  dropdownOptions: Array<DropdownItem> = [];
  excludedOptions: Array<DropdownItem> = [];

  @Input()
  dynamicTableDropdownItemListItem: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();

  @Input()
  valuesList: Array<SelectDynamicTableItem> = [];

  @Input()
  readonly: boolean;

  @Output()
  selectedValue: EventEmitter<SelectedDynamicTableValue> = new EventEmitter<SelectedDynamicTableValue>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.dropdownOptions = this.dynamicTableDropdownItemListItem.itemList;
    if (changes && changes.valuesList) {
      this.refreshDropdownOptions();
    }
  }

  onDropdownSelection(event:any, index:any) {
    this.valuesList[index].competitorItem = event;
    this.emitValue(this.valuesList[index], DynamicTableAction.Add);
  }

  addRow() {
    if (this.valuesList.length < this.dynamicTableDropdownItemListItem.itemList.length && !this.readonly) {
      this.valuesList.push(new SelectDynamicTableItem());
      const index = this.valuesList.length - 1;
      this.valuesList[index].setIndex(index);
    }
  }

  deleteRow(index:any) {
    if (!this.readonly) {
      this.emitValue(this.valuesList[index], DynamicTableAction.Delete);
      this.valuesList.splice(index, 1);
      this.refreshDropdownOptions();
    }
  }

  emitValue(item:any, actionType:any) {
    const objectToEmit: SelectedDynamicTableValue = {
      item,
      actionType
    };
    this.selectedValue.emit(objectToEmit);
  }

  refreshDropdownOptions() {
    const valuesList: SelectDynamicTableItem[]  = this.valuesList.filter(elem => elem.competitorItem.key);
    if (valuesList) {
      this.dropdownOptions = this.dynamicTableDropdownItemListItem.itemList.filter(itemListElement => {
        return !valuesList.find(selectedElement =>
          selectedElement.competitorItem.key.toString() === itemListElement.key.toString());
      });

      this.excludedOptions = valuesList.map(elem => elem.competitorItem);
    }
  }

  setItemId(itemId: number, index: number) {
    this.valuesList[index].setItemId(itemId);
  }
}
