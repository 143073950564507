import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportDashboardItem } from 'src/app/models/reportDashboardItem';
import { AdminService } from 'src/app/services/admin.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';


@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {
  reportDashboardList$: Observable<ReportDashboardItem[]>;

  @Output() reportListToEmit = new EventEmitter<any>();

  constructor( public adminService: AdminService,public commonHelperService:CommonHelperService) {

   
    // this.reportDashboardList$.subscribe(result => {
    //   this.reportListToEmit.emit(result);
    // });
  }
  ngOnInit() {
  }
  onsearch(event:any){
    this.commonHelperService.searchTerm=this.commonHelperService.searchTerm==undefined?"":this.commonHelperService.searchTerm
    this.reportListToEmit.emit(this.commonHelperService.searchTerm.trim());
  }
}
