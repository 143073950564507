import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EscapeCharacters {

  charactersToMap: Map<string, string>;

  constructor() {
    this.charactersToMap = new Map([
      [ `#`, `%23` ],
      [ `&`, `%26` ],
      [ `'`, `''` ],
    ]);
  }

  replaceCharacteres(text: string): string {
    let result = '';
    for (let i = 0; i < text.length; i++) {
      const char = text.charAt(i);
      const charEscaped = this.charactersToMap.get(char);
      if (charEscaped) {
        result += charEscaped;
      } else {
        result += char;
      }
    }
    return result;
  }
}
