import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  showAdminSection: boolean;
  showReactivateSection: boolean;
  showCommentSection:boolean;
  showActiveSection:boolean;
  // currentSection: string = '#project-overview';
  currentSection: string = '';
  isFixed: any;
  stoppingPoint: number = 100;
  @Input() isAutoSaveEnabled: boolean=false;
  @Input() isAdminReviewEditable: boolean=false;
  @Input() saveInProgress: boolean=false;
  @Input() saveButtonVisible: boolean=false;
  @Output()  SaveNewDealFormDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  BindCurrentSection:EventEmitter<any>=new EventEmitter<any>();
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const offset = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (offset >= this.stoppingPoint) {
      this.isFixed = "margin-top: -100px; position:fixed; max-width: fit-content;"
    } else {
      this.isFixed = "position: relative; max-width: fit-content; padding: 0; min-height: 26px;"
    }
  }
  constructor() { }

  ngOnInit() {
  }

  onclick(target:any) {
    //this.currentSection=target;
   const selector = document.querySelector(target);
    if (selector) {
      selector.scrollIntoView({behavior:'smooth'});
    }
    this.BindCurrentSection.emit(this.currentSection);
  }

  showAdminReview() {
    this.showAdminSection = true;
  }
  showCommentReview() {
    this.showCommentSection = true;
  }
  showReactivateFormSection() {
    this.showReactivateSection = true;
  }

  showActiveNewDealSection() {
    this.showActiveSection = true;
  }
  //Added below to perform save without clicking top to bottom
onSaveForm(){
  this.SaveNewDealFormDetails.emit(true);
 }
}
