import { Injectable } from '@angular/core';
import { TypeaheadItem } from '../models/typeahead-item';
import { Spcrud } from './spcrud';
import { BaseService } from './base.service';
import { RootSiteSpcrud } from './root-site-spcrud';
import { NewdealService } from 'src/app/services/newdeal.service';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TypeaheadService extends BaseService {

  constructor(spcrud: Spcrud, rootSiteSpcrud: RootSiteSpcrud,private newdealService:NewdealService) {
    super(spcrud, rootSiteSpcrud);
   }

   getItems(listName: string, itemsMaxLength: number = 500): Promise<TypeaheadItem[]> {
    return new Promise((resolve, reject) => {
      const appendURL = this.newdealService.getAppendURL(listName);
  
      // Use the promise-based GetDropDownData method
      this.newdealService.GetDropDownData(appendURL)
        .then((results: any) => {
          const typeaheadItems = results.map((val: any) => {
            const typeaheadItem = new TypeaheadItem();
            typeaheadItem.Key = val.ID;
            typeaheadItem.Value = val.Name;
            return typeaheadItem;
          });
          resolve(typeaheadItems);
        })
        .catch((err: any) => {
          console.log('Error fetching dropdown data:', err);
          reject(err);
        });
    });
  }  
}
