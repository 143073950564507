import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';
import { DttlTableComponent } from '../dttl-table/dttl-table.component';
import { DropdownItem } from '../../../shared/models/dropdown-item';
import { FreeTextTableItem } from '../../../shared/models/free-text-table-item';
import { FreeTextSelectedRow } from '../../../shared/components/free-text-table/free-text-selected-row.interface';
import { DropdownService } from '../../../shared/services/dropwdown.service';
import { SecurityService } from '../../../shared/services/security.service';
import { AppSettings } from '../../../shared/app-settings';
import { FormListFields } from '../shared/models/form-list-fields';
import { FormValidations } from '../../../shared/utils/form-validations';
import { CustomFormControl } from '../../../shared/custom/custom-form-control';

@Component({
  selector: 'app-cross-border',
  templateUrl: './cross-border.component.html',
  styleUrls: ['./cross-border.component.scss']
})
export class CrossBorderComponent implements OnInit {
  sharePointMapper = new ComplianceSharepointMapper();
  crossBorderFormGroup: FormGroup;

  isXBEngagementAnOptions: Array<DropdownItem> = AppSettings.isXBEngagementAnOptions;
  dttlValues: Array<FreeTextTableItem> = [];
  updatedDDTL = true;

  @ViewChild('dttlTable', {static: true})
  dttlComponent: DttlTableComponent;

  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;
  @Input() readOnly: boolean;
  @Output()
  dttlRowToStore: EventEmitter<FreeTextSelectedRow> = new EventEmitter<FreeTextSelectedRow>();

  @Output()
  dttlRowToDelete = new EventEmitter<FreeTextTableItem[]>();
  

  constructor(private dropdownService: DropdownService,
              private securityService: SecurityService) { }

  ngOnInit() {
    this.crossBorderFormGroup = this.formGroups[0];

    // this.dropdownService.fillDropdownOptions_ClosingCheckList(AppSettings.complianceFormList, FormListFields.isXBEngagementAn)
    // .subscribe(response => {
    //   this.isXBEngagementAnOptions = response;
    // });
  }

  // Public Standart Functions

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }


  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  // Custom functions for section

  onIsDTTLInvolvedSelection(event:any) {
    this.crossBorderFormGroup.controls.isDTTLInvolved.setValue(event);
    this.evaluateIsDTTLInvolved(event);
  }

  evaluateIsDTTLInvolved(value: any) {
    if (value === 'YES') {
      this.setDttlInvolvedReadOnly(false);
      this.crossBorderFormGroup.controls.dttlInvolved.enable();
      this.crossBorderFormGroup.controls.isXBEngagementAn.enable();
    } else {
      this.setDttlInvolvedReadOnly(true);
      this.crossBorderFormGroup.controls.dttlInvolved.disable();
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.isXBEngagementAn));
      this._deleteDttl();
    }
  }

  onDealTypeSelection(event: any) {
    this.crossBorderFormGroup.controls.isXBEngagementAn.setValue(event.value);
  }

  // Table functions

  setItemFreeText(itemId: number, index: number) {
    this.dttlComponent.setItemId(itemId, index);
  }

  onDTTLLRowSelection(rowToStore: FreeTextSelectedRow) {
    this.dttlRowToStore.emit(rowToStore);
  }

  setUpdatedDTTL(updatedDTTLValue: any)
  {
    this.updatedDDTL = updatedDTTLValue;
  }
  getUpdatedDTTL() :boolean {
    return this.updatedDDTL;
  }
  
  setDTTLValues(dttlValues: FreeTextTableItem[]) {
    this.dttlValues = dttlValues;
  }

  setDttlInvolvedReadOnly(isReadOnly: boolean) {
    const elem = this.crossBorderFormGroup.controls.dttlInvolved as CustomFormControl;
    elem.readOnly = isReadOnly;
  }

  // Private Standart Functions

  private _deleteDttl() {
    if (this.dttlValues.length > 0) {
      this.dttlRowToDelete.emit(this.dttlValues);
      this.crossBorderFormGroup.get(this.sharePointMapper.getFormControlColumnName(FormListFields.dttlInvolved))?.setValue([]);
      this.dttlValues = [];
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.crossBorderFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }
  getDttlValues(): Array<FreeTextTableItem> {//added
    const rowsCompleted:  Array<FreeTextTableItem> = [];
    if(this.dttlValues){
      this.dttlValues.forEach(row =>{
        if(row && row.isComplete()){
          rowsCompleted.push(row);
        }
      });
    }
return rowsCompleted;
  }
  getDttlValues_CCL(): Array<FreeTextTableItem> {//added
    const rowsCompleted:  Array<FreeTextTableItem> = [];
    if(this.dttlValues){
      this.dttlValues.forEach(row =>{
        if(row){
          rowsCompleted.push(row);
        }
      });
    }
return rowsCompleted;
  }
}
