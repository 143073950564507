import { Injectable } from '@angular/core';
import { AppContext } from '../../models/app-context';
import { SharepointObjectDealWbsSetup } from '../../models/sharepoint-object-deal-wbs-setup';
import { SharepointObjectMyDeal } from '../../models/sharepoint-object-my-deal';
import { SubmitObject } from '../../models/submit-object';
import { AdminDiscontinueObject } from '../../models/admin-discontinue-object';
import { FormStatusValue, StatusValue } from 'src/shared/models/form-status-value';
import { MainService } from 'src/shared/services/main.service';
import { AdminApprovalObject } from '../../models/admin-approval-object';
import { AdminReturnForReworkObject } from '../../models/admin-return-for-rework-object';
import { RetryWorkflowObject } from '../../models/retry-workflow-object';
import { DocumentTypeItem } from 'src/shared/models/document-type-item';
import { AttachmentItem } from 'src/shared/models/attachment-item';

@Injectable({
  providedIn: 'root'
})

export class DealWbsSetupServiceHelper {

  constructor(private mainService: MainService) {}

  createSubmitObject(adminAssigned:any): SubmitObject {
    const objectToSend: SubmitObject = {
      SubmissionDate: new Date(),
      FormStatus: FormStatusValue.submitted,
      Status:StatusValue.AdminReviewpending,
      AdminAssigned: adminAssigned,
      AdminAssignedDisplayNames: adminAssigned
    };
    return objectToSend;
  }

  createAdminDiscontinueObject(formStatus: string,formActionGroup: string, discontinueReason: string): AdminDiscontinueObject {
    const objectToSend: AdminDiscontinueObject = {
      DiscontinuedOn: new Date(),
      DiscontinuedBy: this.mainService.currentUser.Name,
      DiscontinueReason: discontinueReason,
      FormStatus: FormStatusValue.discontinued,
      DiscontinuedFormStatus: formStatus,
      Status:StatusValue.discontinued,
      DiscontinuedStatus:getStatusByFormStatus(formStatus,formActionGroup)
    };
    return objectToSend;
  }

  createAdminApprovalObject(): AdminApprovalObject {
    const objectToSend: AdminApprovalObject = {
      AdminApprovalDate: new Date(),
      AdminApprovalName: this.mainService.currentUser.Name,
      FormStatus: FormStatusValue.approved,
      Status:StatusValue.completed
    };
    return objectToSend;
  }

  createAdminReturnForReworkObject(returnForReworkReason: string): any {
    const objectToSend: any = {
      ReturnForReworkReason: returnForReworkReason,
      FormStatus: FormStatusValue.rejected,
      ReturnedByUser: this.mainService.currentUser.email,
      Status:StatusValue.returnedToRework
    };
    return objectToSend;
  }

  createRetryWorkflowObject(formStatus: string): RetryWorkflowObject {
    const objectToSend: RetryWorkflowObject = {
      FormStatus: formStatus,
      RetryWorkflow: true
    };
    return objectToSend;
  }

  createAppContext(autoSaveInterval: number, autoSaveEnabled: boolean,autoSaveSpinningWheelInterval:number): AppContext {
    const appContext: AppContext = {
      autoSaveInterval,
      autoSaveEnabled,
      autoSaveSpinningWheelInterval
    };
    return appContext;
  }

  /*createConcurrencyControlObject(executingSave: boolean, editingItem: boolean): ConcurrencyControl {
    const concurrencyControl: ConcurrencyControl = {
      ExecutingSave: executingSave,
      EditingItem: editingItem
    };
    return concurrencyControl;
  }*/

  createAttachmentsObject(responseAttachments:any,documentTypesValues?:any): Array<DocumentTypeItem> {
    const attachmentsObject: Array<DocumentTypeItem> = [];
    responseAttachments.forEach((attachment:any) => {
      if(attachment.DocumentType!=null && attachment.DocumentType!=undefined)
        {
        const documentTypeId = documentTypesValues.find((obj: { documentTypeTitle: any; }) => obj.documentTypeTitle===attachment.DocumentType)?.documentTypeItemId 
        const index = attachmentsObject.map(item => item.documentTypeId).indexOf(documentTypeId);
      const attachmentItem = new AttachmentItem();
      attachmentItem.attachmentName = attachment.FileName;
      if (index !== -1) {
        attachmentsObject[index].documentTypeAttachments.push(attachmentItem);
      } else {
        const documentTypeItem = new DocumentTypeItem();
        documentTypeItem.documentTypeId = documentTypeId;
        documentTypeItem.documentTypeAttachments = [];
        documentTypeItem.documentTypeAttachments.push(attachmentItem);
        attachmentsObject.push(documentTypeItem);
      }
    }
    });
    return attachmentsObject;
  }

 createMyDealObject(response:any): SharepointObjectMyDeal {
    const myDealObject: SharepointObjectMyDeal = {
      ClientName: response.ClientName,
      //ClientID: response.ClientID,
      EngagementName: response.EngagementName,
      StartDate: response.StartDate,
      EndDate: response.EndDate,
      IsRecurringWBS: response.IsRecurringWBS,
      DeloitteUSEntityId: response.DeloitteUSEntityId,
      IntegratedMarketOffering: response.IntegratedMarketOffering,
      SalesOffice: response.SalesOffice,
      EngagementIndustrySICCode: response.EngagementIndustrySICCode,
      IsInternationalEngagement: response.IsInternationalEngagement,
      AreResourcesWorkOutside: response.AreResourcesWorkOutside,
      MemberFirmTaxablePresence: response.MemberFirmTaxablePresence,
      OtherMemberFirmsParticipating: response.OtherMemberFirmsParticipating,
      WhereWorkMemberFirms: response.WhereWorkMemberFirms,
      BillingAddressSameClientAddress: response.BillingAddressSameClientAddress,
      BillingAddress: response.BillingAddress,
      PayerAddressSameClientAddress: response.PayerAddressSameClientAddress,
      PayerAddress: response.PayerAddress,
      MarketOffering: response.MarketOffering,
      IsServiceSameAsClientAddress: response.IsServiceSameAsClientAddress,
      ServiceAtAddress: response.ServiceAtAddress,
      Material: response.Material,
     // Series24Id: response.Series24Id,
      Series24: response.Series24,
      // participatingPartner: response.Series24Id
      WbsCode: response.WbsCode,
      PrdCode: response.PrdCode,
      CurrentAuditor: response.CurrentAuditor,
      PreviousAuditor: response.PreviousAuditor,
      OpportunityID: response.OpportunityID,
    };
    return myDealObject;
  }

  createDealWbsSetupObject(response:any): SharepointObjectDealWbsSetup {
    const dealWbsObject: SharepointObjectDealWbsSetup = {
      MemberFirm: response.MemberFirm,
      MemberFirmClientID : response.MemberFirmClientID,
      MemberFirmContract: response.MemberFirmContract,
      MemberFirmReferenceID: response.MemberFirmReferenceID,
      ModelAfterPriorEngagement: response.ModelAfterPriorEngagement,
      PriorEngagement: response.PriorEngagement,
      //MemberFirmIdBillingAddress: response.MemberFirmIdBillingAddress,
      BillingContact: response.BillingContact,
      EmailAddress: response.EmailAddress,
      IsSubcontractingArrangement: response.IsSubcontractingArrangement,
      PrintSecondBillingAddress: response.PrintSecondBillingAddress,
      InvoiceMedium: response.InvoiceMedium,
      PrintClientReferenceNumber: response.PrintClientReferenceNumber,
      AllowInvoiceConsolidation: response.AllowInvoiceConsolidation,
      BillingCurrency: response.BillingCurrency,
      InvoiceLayout: response.InvoiceLayout,
      PrintEngagementNumber: response.PrintEngagementNumber,
      PaymentTerms: response.PaymentTerms,
      FXRate: response.FXRate,
      FXRiskSharing: response.FXRiskSharing,
      BaselineFXRate: response.BaselineFXRate,
      //MasterEngagementID: response.MasterEngagementID,
      //FinancialPlanID: response.FinancialPlanID,
      //BillingPlanType: response.BillingPlanType,
      //BillingIntervals: response.BillingIntervals,
      //BillingIfMilestone: response.BillingIfMilestone,
      //BillingFrom: response.BillingFrom,
      //BillingTo: response.BillingTo,
      ContractType: response.ContractType,
      ResourceRateType: response.ResourceRateType,
      USIResourceRateType: response.USIResourceRateType,
      EventIndicator: response.EventIndicator,
      BudgetGroup: response.BudgetGroup,
      PlannedNetRevenue: response.PlannedNetRevenue,
      Discount: response.Discount,
      PlannedMargin: response.PlannedMargin,
      PlannedCost: response.PlannedCost,
      TotalPlannedHours: response.TotalPlannedHours,
      TimeRevenueCap: response.TimeRevenueCap,
      ParticipatingManager: response.ParticipatingManager,
      // ParticipatingPartnerId: response.ParticipatingPartnerId,
      DerivedAtAddressSameClientAddres: response.DerivedAtAddressSameClientAddres,
      DerivedAtAddress: response.DerivedAtAddress,
      //ConsultingMarket: response.ConsultingMarket,
      IsEngagementAudit: response.IsEngagementAudit,
      IsArchiveRequired: response.IsArchiveRequired,
      WasCMRPerformed: response.WasCMRPerformed,
      EngagementTypeName: response.EngagementTypeName
    };
    return dealWbsObject;
  }
  
}
function getStatusByFormStatus(formStatus: string,formActionGroup?:any) {
  switch(formStatus)
  {
    case FormStatusValue.approved:
      return StatusValue.completed
    case FormStatusValue.discontinued:
      return StatusValue.discontinued;
    case FormStatusValue.submitted:
      return StatusValue.AdminReviewpending
    case FormStatusValue.pending:
      return formActionGroup=="AdminReview" ? StatusValue.AdminReviewpending : StatusValue.returnedToRework
      case FormStatusValue.draft:
      return StatusValue.draft      
      case FormStatusValue.rejected:
        return StatusValue.returnedToRework
    default:
      return "";
  }
}
