<div id="signature-table-container">
    <div>
      <table id="signature-table">
      <thead>
        <tr>
          <th scope="col">
            <span>
              Signature Name
            </span>
          </th>
          <th scope="col">
            <span>
              Signature Date
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let signature of signatureData;let i = index " [ngClass]="{'hidden': i>=5 && !toggleClass}">
          <td>
            {{signature.SignatureName}}
          </td>
          <td>
            {{signature.SignatureDate}}
          </td>
        </tr>
      </tbody>
      </table>
    </div>
  </div>
  <div  style="float: right;"><a  id="arrow-comments-3" class='glyphicon comments-down-arrow comments-right-arrow  size' (click)="toggleClass = !toggleClass" [ngClass]="{' glyphicon-chevron-up ': !toggleClass,'glyphicon-chevron-down':toggleClass }"  ></a></div>
