<div *ngIf="engagementObject"  class="row third-party-container">
  <span class="third-party-title-section">Third Party Codes</span>
  <hr style="width: 98%;
  margin-left: 0% !important;">
  <div class="third-party-content-section">
    <div class="row">
      <span class="third-party-codes-title">Swift Code Information</span>
    </div>
    <div class="row">
      <div class="col-xs-2">
        <span>Swift Code ID:</span>
      </div>
      <div class="col-xs-10">
        <span>{{engagementObject.prdCode}}</span>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-2">
        <span>PRD Status:</span>
      </div>
      <div class="col-xs-10">
        <span>-</span>
      </div>
    </div> -->
    <div class="row">
      <span class="third-party-codes-title">Mercury Opportunity Information</span>
    </div>
    <div class="row">
      <div class="col-xs-2">
        <span>Mercury Opportunity:</span>
      </div>
      <div class="col-xs-10">
        <span>{{engagementObject.opportunityId}}</span>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-2">
          <span>Opportunity Phase:</span>
      </div>
      <div class="col-xs-10">
        <span>-</span>
      </div>
    </div> -->
    <div class="row">
      <span class="third-party-codes-title">Salesforce Code Information</span>
    </div>
    <div class="row">
      <div class="col-xs-2">
          <span>Salesforce Code:</span>
      </div>
      <div class="col-xs-10">
        <span>{{engagementObject.salesForceCode}}</span>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-2">
        <span>SF Project Stage:</span>
      </div>
      <div class="col-xs-10">
        <span>-</span>
      </div>
    </div> -->
    <div class="row">
      <span class="third-party-codes-title">WBS Code Information</span>
    </div>
    <div class="row">
      <div class="col-xs-2">
          <span>WBS Code:</span>
      </div>
      <div class="col-xs-10">
        <span>{{engagementObject.wbsCode}}</span>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-2">
          <span>WBS Status:</span>
      </div>
      <div class="col-xs-10">
        <span>-</span>
      </div>
    </div> -->
  </div>
</div>
