import { Injectable } from '@angular/core';
import { FileMetadataObject } from '../models/file-metadata-object';

@Injectable({
  providedIn: 'root'
})

export class FileServiceHelper {

  createFileMetadataObject(formId: number, documentTypeItemId: number, title: string): FileMetadataObject {
    const fileMetadataObject = {
      NewDealFormId: formId,
      DocumentTypeId: documentTypeItemId,
      Title: title
    };
    return fileMetadataObject;
  }
}
