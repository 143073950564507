<div id="new-deal" [formGroup]="newDealFormGroup" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span>New Deal</span>
    </div>
    <div id="new-deal-section" class="content-section">
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('clientRevenue')"
                    [class.edited-externally]="editedExternally('clientRevenue')"
                    [class.conflict-on-merge]="hasMergeConflict('clientRevenue')">Client Revenue ($M)</span>
                <span *ngIf="showAsterisk('clientRevenue')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-numeric
                    [type]="'decimal'"
                    [format]="'currency'"
                    [value]="newDealFormGroup.get('clientRevenue')?.value"
                    [readonly]="isReadOnly('clientRevenue')"
                    (selectedValue)="onClientRevenueSelection($event)">
                </app-numeric>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('ebitda')"
                    [class.edited-externally]="editedExternally('ebitda')"
                    [class.conflict-on-merge]="hasMergeConflict('ebitda')">EBITDA ($M)</span>
                <span *ngIf="showAsterisk('ebitda')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-numeric
                    [type]="'decimal'"
                    [format]="'currency'"
                    [value]="myDealFormGroup.get('ebitda')?.value"
                    [readonly]="isReadOnly('ebitda')"
                    (selectedValue)="onEbitdaSelection($event)">
                </app-numeric>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('statusNewDeal')"
                    [class.edited-externally]="editedExternally('statusNewDeal')"
                    [class.conflict-on-merge]="hasMergeConflict('statusNewDeal')">Status</span>
                <span *ngIf="showAsterisk('statusNewDeal')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                  [optionsList]="statusNewDealOptions"
                  [keySelected]="newDealFormGroup.get('statusNewDeal')?.value"
                  [readonly]="isReadOnly('statusNewDeal')"
                  (optionSelected)="onStatusSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('isCompetitivePitch')"
                    [class.edited-externally]="editedExternally('isCompetitivePitch')"
                    [class.conflict-on-merge]="hasMergeConflict('isCompetitivePitch')">Is this a Competitive Pitch?</span>
                <span *ngIf="showAsterisk('isCompetitivePitch')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-toggle-yes-no
                  [value]="myDealFormGroup.get('isCompetitivePitch')?.value"
                  [readonly]="isReadOnly('isCompetitivePitch')"
                  (handleToggleYesNo)="onIsCompetitivePitchSelection($event)">
                </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
              <span [class.highlight-warning]="highlightRequiredField('competitorsList') ||  highlightRequiredField('otherCompetitor')"
                [class.edited-externally]="editedExternally('competitorsList') || editedExternally('otherCompetitor')"
                [class.conflict-on-merge]="hasMergeConflict('competitorsList') || hasMergeConflict('otherCompetitor')">
                Competitor List
              </span>
              <span *ngIf="showAsterisk('competitorsList')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <!-- UAT Feedback change to free textbox -->
                <input
                  formControlName="competitorsList"
                  placeholder="Please specify competitors list"
                  class="input-data-text full-width section-input"
                  [class.readonly]="isReadOnly('competitorsList')"
                  type="text"
                  autocomplete="off"
                  maxlength="255">
             
              <!-- <app-select-dynamic-table *ngIf="competitorDynamicTableDropdownItemList.itemList"
                [dynamicTableDropdownItemListItem]="competitorDynamicTableDropdownItemList"
                [valuesList]="competitorsListValues"
                [readonly]="isReadOnly('competitorsList')"
                (selectedValue)="onCompetitorsTableChange($event)">
              </app-select-dynamic-table>

              <div *ngIf="newDealFormGroup.get('otherCompetitor').enabled || isReadOnly('otherCompetitor')" class="row">
                <input
                  formControlName="otherCompetitor"
                  placeholder="Please specify 'Other Competitor'"
                  class="input-data-text full-width section-input"
                  [class.readonly]="isReadOnly('otherCompetitor')"
                  type="text"
                  autocomplete="off"
                  maxlength="255">
              </div> -->
            </div>
          </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('businessDescription')"
                    [class.edited-externally]="editedExternally('businessDescription')"
                    [class.conflict-on-merge]="hasMergeConflict('businessDescription')">Business description</span>
                <span *ngIf="showAsterisk('businessDescription')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input
                  formControlName="businessDescription"
                  class="input-data-text full-width section-input"
                  [class.readonly]="isReadOnly('businessDescription')"
                  type="text"
                  autocomplete="off"
                  maxlength="255">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('subsector')"
                    [class.edited-externally]="editedExternally('subsector')"
                    [class.conflict-on-merge]="hasMergeConflict('subsector')">Subsector</span>
                <span *ngIf="showAsterisk('subsector')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
              <textarea
                [formControl]="myDealFormGroup.get('subsector')"
                type="text"
                rows="3"
                [class.readonly]="isReadOnly('subsector')"
                class="input-data-text text-area-style">
              </textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('publiclyTradedSecurities')"
                    [class.edited-externally]="editedExternally('publiclyTradedSecurities')"
                    [class.conflict-on-merge]="hasMergeConflict('publiclyTradedSecurities')">Publicly Traded Securities? (Public or Private)</span>
                <span *ngIf="showAsterisk('publiclyTradedSecurities')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown
                  [optionsList]="publiclyTradedSecuritiesOptions"
                  [keySelected]="newDealFormGroup.get('publiclyTradedSecurities')?.value"
                  [readonly]="isReadOnly('publiclyTradedSecurities')"
                  (optionSelected)="onPubliclyTradedSecuritiesSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('isPubliclyTradedEquity')"
                    [class.edited-externally]="editedExternally('isPubliclyTradedEquity')"
                    [class.conflict-on-merge]="hasMergeConflict('isPubliclyTradedEquity')">Publicly Traded Equity?</span>
                <span *ngIf="showAsterisk('isPubliclyTradedEquity')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-toggle-yes-no
                  [value]="myDealFormGroup.get('isPubliclyTradedEquity')?.value"
                  [readonly]="isReadOnly('isPubliclyTradedEquity')"
                  (handleToggleYesNo)="onIsPubliclyTradedEquitySelection($event)">
                </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('tickerCode')"
                    [class.edited-externally]="editedExternally('tickerCode')"
                    [class.conflict-on-merge]="hasMergeConflict('tickerCode')">Please enter ticker code</span>
                <span *ngIf="showAsterisk('tickerCode')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input
                  [formControl]="myDealFormGroup.get('tickerCode')"
                  class="input-data-text full-width section-input dependent-field-input"
                  [class.readonly]="isReadOnly('tickerCode')"
                  type="text"
                  autocomplete="off"
                  maxlength="255"
                  [placeholder]="myDealFormGroup.get('isPubliclyTradedEquity')?.value === 'YES' ? '' : 'N/A'">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('isPubliclyTradedDebt')"
                    [class.edited-externally]="editedExternally('isPubliclyTradedDebt')"
                    [class.conflict-on-merge]="hasMergeConflict('isPubliclyTradedDebt')">Publicly Traded Debt?</span>
                <span *ngIf="showAsterisk('isPubliclyTradedDebt')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-toggle-yes-no
                  [value]="newDealFormGroup.get('isPubliclyTradedDebt')?.value"
                  [readonly]="isReadOnly('isPubliclyTradedDebt')"
                  (handleToggleYesNo)="onIsPubliclyTradedDebtSelection($event)">
                </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('cusip')"
                    [class.edited-externally]="editedExternally('cusip')"
                    [class.conflict-on-merge]="hasMergeConflict('cusip')">Please enter CUSIP</span>
                <span *ngIf="showAsterisk('cusip')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <div class="dependent-field">
                    <app-numeric
                        [type]="'number'"
                        [value]="newDealFormGroup.get('cusip')?.value"
                        [disabled]="newDealFormGroup.get('cusip').disabled"
                        [placeholder]="newDealFormGroup.get('isPubliclyTradedDebt')?.value === 'YES' ? '' : 'N/A'"
                        [readonly]="isReadOnly('cusip')"
                        (selectedValue)="onCusipSelection($event)">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('firmType')"
                [class.edited-externally]="editedExternally('firmType')"
                [class.conflict-on-merge]="hasMergeConflict('firmType')">Firm Type</span>
                <span *ngIf="showAsterisk('firmType')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown [optionsList]="firmTypeOptions" [keySelected]="newDealFormGroup.get('firmType')?.value"
                    [readonly]="isReadOnly('firmType')" (optionSelected)="onFirmTypeSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('conflictCheckCompletion')"
                    [class.edited-externally]="editedExternally('conflictCheckCompletion')"
                    [class.conflict-on-merge]="hasMergeConflict('conflictCheckCompletion')">Conflict Check Completion Date</span>
                <span *ngIf="showAsterisk('conflictCheckCompletion')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-date-picker
                  [selectedDate]="newDealFormGroup.get('conflictCheckCompletion')?.value"
                  [readonly]="isReadOnly('conflictCheckCompletion')"
                  (dateSelected)="onConflictCheckCompletionSelection($event)">
                </app-date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4">
                <span [class.highlight-warning]="highlightRequiredField('obaPstPersonnelConflict')"
                    [class.edited-externally]="editedExternally('obaPstPersonnelConflict')"
                    [class.conflict-on-merge]="hasMergeConflict('obaPstPersonnelConflict')">OBA/PST Personnel Conflict Check Completed</span>
                <span *ngIf="showAsterisk('obaPstPersonnelConflict')" class="red-asterik"></span>
                <app-popover
                  [tooltipText]="'Teams must reach out to compliance to run a check to ensure there are no conflicts with the company and any DCF personnel'">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <app-toggle-yes-no
                  [value]="newDealFormGroup.get('obaPstPersonnelConflict')?.value"
                  [readonly]="isReadOnly('obaPstPersonnelConflict')"
                  (handleToggleYesNo)="onObaPstPersonnelConflictSelection($event)">
                </app-toggle-yes-no>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('obaPstPersonnelConflictDate')"
                     [class.edited-externally]="editedExternally('obaPstPersonnelConflictDate')"
                    [class.conflict-on-merge]="hasMergeConflict('obaPstPersonnelConflictDate')">Please enter date</span>
                <span *ngIf="showAsterisk('obaPstPersonnelConflictDate')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <div class="dependent-field datepicker-dependent-field">
                    <app-date-picker
                        [selectedDate]="newDealFormGroup.get('obaPstPersonnelConflictDate')?.value"
                        [disabled]="newDealFormGroup.get('obaPstPersonnelConflictDate')?.disabled"
                        [readonly]="isReadOnly('obaPstPersonnelConflictDate')"
                        (dateSelected)="onObaPstPersonnelConflictDateSelection($event)">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('backgroundCheckCompletion')"
                    [class.edited-externally]="editedExternally('backgroundCheckCompletion')"
                    [class.conflict-on-merge]="hasMergeConflict('backgroundCheckCompletion')">Background Check Completion Date</span>
                <span *ngIf="showAsterisk('backgroundCheckCompletion')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-date-picker
                  [selectedDate]="newDealFormGroup.get('backgroundCheckCompletion')?.value"
                  [readonly]="isReadOnly('backgroundCheckCompletion')"
                  (dateSelected)="onBackgroundCheckCompletionSelection($event)">
                </app-date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('ofacCompletion')"
                    [class.edited-externally]="editedExternally('ofacCompletion')"
                    [class.conflict-on-merge]="hasMergeConflict('ofacCompletion')">OFAC Completion Date</span>
                <span *ngIf="showAsterisk('ofacCompletion')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-date-picker
                    [selectedDate]="newDealFormGroup.get('ofacCompletion')?.value"
                    [readonly]="isReadOnly('ofacCompletion')"
                    (dateSelected)="onOfacCompletionSelection($event)">
                </app-date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('commentsNewDeal')"
                    [class.edited-externally]="editedExternally('commentsNewDeal')"
                    [class.conflict-on-merge]="hasMergeConflict('commentsNewDeal')">Comments to Deal Sheet</span>
                <span *ngIf="showAsterisk('commentsNewDeal')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <textarea
                    formControlName="commentsNewDeal"
                    type="text"
                    rows="3"
                    placeholder="You can add comments here"
                    [class.readonly]="isReadOnly('commentsNewDeal')"
                    class="input-data-text text-area-style">
                </textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('industry')"
                [class.edited-externally]="editedExternally('industry')"
                [class.conflict-on-merge]="hasMergeConflict('industry')">Industry</span>
                <span *ngIf="showAsterisk('industry')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown [optionsList]="industryOptions" [keySelected]="myDealFormGroup.get('industry')?.value"
                    [readonly]="isReadOnly('industry')" (optionSelected)="onIndustrySelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
              <span [class.highlight-warning]="highlightRequiredField('source')"
                [class.edited-externally]="editedExternally('source')"
                [class.conflict-on-merge]="hasMergeConflict('source')">Source (Referral if any)</span>
              <span *ngIf="showAsterisk('source')" class="red-asterik"></span>
              <app-popover
                [tooltipText]="getTooltipText('Source')">
              </app-popover>
            </div>
            <div class="col-xs-8">
              <app-dropdown
                [optionsList]="sourceOptions"
                [keySelected]="myDealFormGroup.get('source')?.value"
                [readonly]="isReadOnly('source')"
                (optionSelected)="onSourceSelection($event)">
              </app-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-4 section-label">
              <span [class.highlight-warning]="highlightRequiredField('sourceName')"
                [class.edited-externally]="editedExternally('sourceName')"
                [class.conflict-on-merge]="hasMergeConflict('sourceName')">Source Name</span>
              <span *ngIf="showAsterisk('sourceName')" class="red-asterik"></span>
              <app-popover
                [tooltipText]="getTooltipText('SourceName')">
              </app-popover>
            </div>
            <div class="col-xs-8">
              <input
                formControlName="sourceName"
                class="input-data-text full-width section-input"
                [class.readonly]="isReadOnly('sourceName')"
                type="text"
                autocomplete="off"
                maxlength="255">
            </div>
          </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('referralSourceName')"
                    [class.edited-externally]="editedExternally('referralSourceName')"
                    [class.conflict-on-merge]="hasMergeConflict('referralSourceName')">Referral Source Name</span>
                <span *ngIf="showAsterisk('referralSourceName')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input [formControl]="myDealFormGroup.get('referralSourceName')"
                    class="input-data-text full-width section-input"
                    [class.readonly]="isReadOnly('referralSourceName')"
                    type="text" 
                    autocomplete="off" 
                    maxlength="255">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
              <span [class.highlight-warning]="highlightRequiredField('transactionType')"
                [class.edited-externally]="editedExternally('transactionType')"
                [class.conflict-on-merge]="hasMergeConflict('transactionType')">Transaction Type</span>
              <span *ngIf="showAsterisk('transactionType')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
              <app-dropdown
                [optionsList]="transactionTypeOptions"
                [keySelected]="myDealFormGroup.get('transactionType')?.value"
                [readonly]="isReadOnly('transactionType')"
                (optionSelected)="onTransactionTypeSelection($event)">
              </app-dropdown>
            </div>
          </div>
    </div>
</div>
