import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AdminService } from "./admin.service";
 

@Injectable({
    providedIn: 'root'
  })
export class PeoplePickerService{
    constructor(private adminService:AdminService){}
    
    getPeoplePickerUsers(term:string):Observable<any>{
    return this.adminService.GetPeoplepicker(term);
    }
}