<div *ngIf="engagementHealthObject" class="sidebar-container">
  <div class="col-xs-12 sidebar-header">
    <span class="engagement-content-breakdown-title">Engagement Content Breakdown</span>
  </div>
  <div class="col-xs-12 sidebar-row">
    <div class="container-icon">
      <i class="fas fa-folder sidebar-icon"></i>
    </div>
    <div class="sidebar-name">
      <span
        class="label-link"
        (click)="reloadPage()">
        {{engagementHealthObject.engagementName}}
      </span><br>
      <span
        class="grey-label">
          ID: {{engagementHealthObject.newDealId}}
      </span><br>
      <span
        [ngStyle]="{'color': engagementHealthObject.engagementStatusColor}">
        {{engagementHealthObject.engagementStatus}}
      </span>
    </div>
  </div>
  <div *ngFor="let form of sidebarAvailableFormsList" class="col-xs-12 sidebar-row">
    <div>
      <i class="far fa-file sidebar-icon"></i>
    </div>
    <div class="form-column">
      <span class="label-link" style="box-sizing: content-box;"
        (click)="openNewTab(form.availableFormItemId, form.availableFormPageUrl, form.availableAltFormPageUrl, form.availableFormListName, form.availableFormSiteUrl)">
          {{form.availableFormTitle}} 
          <i class="fa fa-edit" style="font-size: 15px !important;"></i>
      </span>
      <br>
      <div *ngIf="form.availableFormTitle === 'New Deal Setup Form'">
        <span
          [ngStyle]="{'color': calculateStatusColor(form.formStatus)}">
          {{form.formStatus}}
        </span>
      </div>
      <div *ngIf="form.availableFormTitle !== 'New Deal Setup Form'" class="title-width">
        <span
          [ngStyle]="{'color': calculateStatusColor(form.formStatus)}">
          {{form.formStatus}}
        </span>
      </div>
    </div>
  </div>
  <div class="col-xs-12 sidebar-row">
    <div class="container-icon">
      <i class="fas fa-plus-circle sidebar-icon"></i>
    </div>
    <div class="add-new-form">
      <a (click)="onAddNewFormClicked()"><span>Add new Form</span></a>
    </div>
  </div>
  <div class="col-xs-12 sidebar-header">
    <span class="engagement-content-breakdown-title">View Related Attachments</span>
  </div>
  <div class="col-xs-12 sidebar-row">
    <div class="container-icon">
      <i class="fas fa-folder sidebar-icon"></i>
    </div>
    <div class="form-column">
      <span>New Deal Form</span>
    </div>
    <div *ngIf="attachmentListNewDeal.length">
      <span
        class="glyphicon attachment-arrow ie-icon-class"
        [ngClass]="showAttachmentsNewDeal ? 'glyphicon-chevron-up' : 'glyphicon-chevron-down'"
        (click)="toggleAttachmentsNewDeal()">
      </span>
    </div>
  </div>
  <div *ngIf="showAttachmentsNewDeal">
    <div
      class="col-xs-12 sidebar-row"
      *ngFor="let attachment of attachmentListNewDeal.slice(0, limitAttachmentToShowNewDeal)">
      <div class="container-icon">
        <i class="fas fa-paperclip sidebar-icon"></i>
      </div>
      <div class="form-column container-attch">
        <span style="cursor: pointer;" (click)="DownloadAttachment(attachment.attachmentUrl)">
          <span>
          {{attachment.attachmentName}}
          </span>
        </span><br>
        <span class="grey-label">New Deal Form</span>
      </div>
    </div>
  </div>
  <div *ngIf="showMoreButtonNewDeal()"
    class="col-xs-12 sidebar-row show-attachments">
    <button
      class="button-show-attachemnts"
      (click)="toggleShowAttachmentsButtonNewDeal()">
        Show More
    </button>
  </div>
  <div *ngIf="showLessButtonNewDeal()"
    class="col-xs-12 sidebar-row show-attachments">
    <button
      class="button-show-attachemnts"
      (click)="toggleShowAttachmentsButtonNewDeal()">
        Show Less
    </button>
  </div>
  <!-- <div class="col-xs-12 sidebar-row">
    <div class="container-icon">
      <i class="fas fa-folder sidebar-icon"></i>
    </div>
    <div class="form-column margin-arrow">
      <span>Cross Border Form</span>
    </div>
    <div *ngIf="attachmentListCrossBorder.length">
      <span
        class="glyphicon attachment-arrow ie-icon-class"
        [ngClass]="showAttachmentsCrossBorder ? 'glyphicon-chevron-up' : 'glyphicon-chevron-down'"
        (click)="toggleAttachmentsCrossborder()">
      </span>
    </div>
  </div>
  <div *ngIf="showAttachmentsCrossBorder">
    <div
      class="col-xs-12 sidebar-row"
      *ngFor="let attachment of attachmentListCrossBorder.slice(0,limitAttachmentToShowCrossBorder)">
      <div class="container-icon">
        <i class="fas fa-paperclip sidebar-icon"></i>
      </div>
      <div class="form-column container-attch">
        <a href="{{attachment.attachmentUrl}}" target="_blank">
          <span>
          {{attachment.attachmentName}}
          </span>
        </a><br>
        <span class="grey-label">Cross Border Form</span>
      </div>
    </div>
  </div>
  <div *ngIf="showMoreButtonCrossBorder()"
  class="col-xs-12 sidebar-row show-attachments">
  <button
    class="button-show-attachemnts"
    (click)="toggleShowAttachmentsButtonCrossBorder()">
      Show More
  </button>
  </div>
  <div *ngIf="showLessButtonCrossBorder()"
    class="col-xs-12 sidebar-row show-attachments">
    <button
      class="button-show-attachemnts"
      (click)="toggleShowAttachmentsButtonCrossBorder()">
        Show Less
    </button>
  </div>
  <div class="col-xs-12 sidebar-row">
    <div class="container-icon">
      <i class="fas fa-folder sidebar-icon"></i>
    </div>
    <div class="form-column margin-arrow">
      <span>CAF Form</span>
    </div>
    <div *ngIf="attachmentListCaf.length">
      <span
        class="glyphicon attachment-arrow ie-icon-class"
        [ngClass]="showAttachmentsCaf ? 'glyphicon-chevron-up' : 'glyphicon-chevron-down'"
        (click)="toggleAttachmentsCaf()">
      </span>
    </div>
  </div>
  <div *ngIf="showAttachmentsCaf">
    <div
      class="col-xs-12 sidebar-row"
      *ngFor="let attachment of attachmentListCaf.slice(0, limitAttachmentToShowCaf)">
      <div class="container-icon">
        <i class="fas fa-paperclip sidebar-icon"></i>
      </div>
      <div class="form-column container-attch">
        <a href="{{attachment.attachmentUrl}}" target="_blank">
          <span>
          {{attachment.attachmentName}}
          </span>
        </a><br>
        <span class="grey-label">Caf Form</span>
      </div>
    </div>
  </div>
  <div *ngIf="showMoreButtonCaf()"
  class="col-xs-12 sidebar-row show-attachments">
    <button
      class="button-show-attachemnts"
      (click)="toggleShowAttachmentsButtonCaf()">
        Show More
    </button>
  </div>
  <div *ngIf="showLessButtonCaf()"
    class="col-xs-12 sidebar-row show-attachments">
    <button
      class="button-show-attachemnts"
      (click)="toggleShowAttachmentsButtonCaf()">
        Show Less
    </button>
  </div> -->
  <div *ngIf="showEngagementArchive" class="col-xs-12 sidebar-header">
    <a (click)="reloadPage()"><span class="engagement-archive">View Engagement Archive</span></a>
  </div>
  <!-- <span class="glyphicon attachment-arrow externalSideBar glyphicon glyphicon-menu-hamburger" (click)="collapseExpandFn()">
  </span> -->
</div>
<app-modal #Modal>
  <ng-container header>
    <ng-container
        *ngTemplateOutlet="modalHeader">
    </ng-container>
  </ng-container>
  <ng-container body>
      <ng-container
          *ngTemplateOutlet="modalBody">
      </ng-container>
  </ng-container>
</app-modal>
<ng-template #modalHeader>
  <div [ngSwitch]="modalType">
    <div *ngSwitchCase="'add-new-form'" class="modal-title-header">
      Add new Form
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #modalBody>
  <div [ngSwitch]="modalType">
    <div *ngSwitchCase="'add-new-form'" class="add-new-form-modal">
      <div  *ngIf="availableForms">
        <span class="modal-span">Available Forms:</span>
        <ul>
          <li *ngFor="let availableForm of modalAvailableFormsList; let i = index"
            class="forms-list"
            (click)="addFormToEngagement(availableForm)">
              {{availableForm.availableFormTitle}}
          </li> 
        </ul>
      </div>
      <span class='modal-span' *ngIf="!availableForms">There are no more available Forms to add.</span>
    </div>
  </div>
</ng-template>

<app-eho-notify-popup (addEHOFormDetails)="addEHOFormDetails($event)" [availableForms]="availableForms" [modalAvailableFormsList]="modalAvailableFormsList"> </app-eho-notify-popup>