<div class="col-xs-12">
  <app-free-text-table #freeTextDTTL
                       *ngIf="dttlValues"
                       [firsSelectColumnItemList]="firstColumnVisible ? firstColumnVisible : null"
                       [secondSelectColumnItemList]="thirdColumnVisible ? thirdColumnVisible : null"
                       [valuesList]="dttlValues"
                       [readonly]="readonly"
                       (selectedRow)="onDTTLRowSelection($event)">

  </app-free-text-table>
</div>
