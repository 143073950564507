import { Injectable } from '@angular/core';
import { SelectDynamicTableItem } from 'src/shared/models/select-dynamic-table-item';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { Spcrud } from 'src/shared/services/spcrud';
import { AppSettings } from 'src/shared/app-settings';
import { MainServiceHelper } from 'src/shared/services/main-service-helper';
import { MainService } from 'src/shared/services/main.service';
import { NewDealSetupServiceHelper } from './new-deal-setup-service-helper';
import { RootSiteSpcrud } from 'src/shared/services/root-site-spcrud';
import { BaseService } from 'src/shared/services/base.service';
import { Observable, catchError, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { NewdealService } from 'src/app/services/newdeal.service';
import { AdminAssigned } from 'src/app/models/admin-assigned';
@Injectable({
  providedIn: 'root'
})
export class NewDealSetupService extends BaseService {
  
  constructor(
    spcrud: Spcrud,
    private myhttp:HttpClient,
    rootSiteSpcud: RootSiteSpcrud,
    private mainService: MainService,
    private mainServiceHelper: MainServiceHelper,
    private newDealSetupServiceHelper: NewDealSetupServiceHelper,        
    private newDealService: NewdealService,
    
    ) {
      super(spcrud, rootSiteSpcud);      
    }

  retrieveMyDeal(myDealId: number): Promise<any> {
    return this.mainService.getListItem(AppSettings.myDealFormList, myDealId);
  }

  getDealTeamUsers(listName: string, formId: number): Promise<Array<PeoplepickerDynamicTableItem>> | any {
    const spcrud = this.getSpcrud(listName);
    if (formId) {
      const optionsDealTeamTable = {
        filter: 'Form/Id eq ' + formId,
        select: `Id, FormId, Role/Role, Role/Id, PursuitRole/Role, PursuitRole/Id, SwiftRole/Role, SwiftRole/Id, Name/EMail, Name/Id, Name/Title, Name/Name`,
        expand: 'Role, PursuitRole,SwiftRole, Name'};
      return spcrud.read(listName, optionsDealTeamTable)
      .then( (response:any) => {
        const results = response.d.results;
        const dealTeamUsers = results.map((item:any) => {
          const peoplePickerUser = this.mainServiceHelper.createPeoplePickerUser(item.Name.Name, item.Name.Title, item.Name.EMail);
          return this.mainServiceHelper.createPeoplepickerDynamicTableItem(item.Name.Id, peoplePickerUser, item);
        });
        return dealTeamUsers;

      }).catch((error:any) => {
      });
    } else {
      return null;
    }
  }
  getCompetitorsList(listName: string, formId: number): Promise<Array<SelectDynamicTableItem>> {
    const spcrud = this.getSpcrud(listName);
    const optionsCompetitorsTable = {
      filter: 'Form/Id eq ' + formId,
      select: 'Id, FormId, Competitor/Title, Competitor/Id',
      expand: 'Competitor'};
    return spcrud.read(listName, optionsCompetitorsTable)
    .then( (response:any) => {
      const results = response.d.results;
      const competitorsList = results.map((item:any) => {
      const competitorSelector = this.mainServiceHelper.createDropdownItem(item.Competitor.Id, item.Competitor.Title);
      return this.mainServiceHelper.createSelectDynamicTableItem(competitorSelector, item.Id);
      });
      return competitorsList;
    }).catch((error:any) => {
    });
  }

  getDealTeamUserByEmail( formId: number, email: string): Promise<any> {
    // const listName = AppSettings.dealTeamTableList;
    // const spcrud = this.getSpcrud(listName);
    // const options = {
    //   filter: `Form/Id eq ${formId} and Name/EMail eq '${email}'`,
    //   select: 'Id, Name/EMail',
    //   expand: 'Name'
    // };
    // return spcrud.read(listName, options)
    // .then( (response:any) => {
    //   return response.d.results[0];
    // }).catch((error:any) => {
    // });
    return this.newDealService.GetDealTeamUserByEmail(formId,email)
      .then((results: any) => {
        return results;
      })
      .catch((err: any) => {
        console.log('Error getting form data:', err);
      });

  }

  isAssignedSeries24(listName: string, formId: number, userId: number): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: 'Id eq ' + formId + ' and Series24/Id eq ' + userId
     };
    return spcrud.read(listName, options)
    .then( (response:any) => {
      if (response.d.results.length > 0) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error:any) => {
    });
  }

  updateFormOnAdminReviewApproval(rangeKey:any,formId:any) {
    const objectToSend = this.newDealSetupServiceHelper.createAdminApprovalObject();
    return this.mainService.updateForm(AppSettings.formListName, formId, objectToSend,rangeKey,true);
  }

  updateFormOnDiscontinue(formStatus: string, discontinueReason: string,rangeKey:any,formId:any,formActionGroup:any) {
    const objectToSend = this.newDealSetupServiceHelper.createAdminDiscontinueObject(formStatus, discontinueReason,formActionGroup);
    return this.mainService.updateForm(AppSettings.formListName, formId, objectToSend,rangeKey,true);
  }

  updateFormOnReturnForRework(returnForReworkReason: string,rangeKey:any,formId:any) {
    const objectToSend = this.newDealSetupServiceHelper.createAdminReturnForReworkObject(returnForReworkReason);
    return this.mainService.updateForm(AppSettings.formListName, formId, objectToSend,rangeKey,true);
  }

  updateFormOnSubmit(adminDetails: AdminAssigned, submissionDate: string,rangeKey:any,formId:any) {
    const objectToSend = this.newDealSetupServiceHelper.createSubmitObject(adminDetails, submissionDate);
    return this.mainService.updateForm(AppSettings.formListName, formId, objectToSend,rangeKey,true);
  }

  updateFormOnRetryWorkflow(formStatus: string, formId: number,rangeKey:any) {
    const objectToSend = this.newDealSetupServiceHelper.createRetryWorkflowObject(formStatus);
    return this.mainService.updateForm(AppSettings.formListName, formId, objectToSend,rangeKey,true);
  }

  getAppContext(): Promise<any> {
    return Promise.all([
      this.getAutoSaveInterval(),
      this.getAutoSaveEnabled(),
      this.getExcludeFromAdminRotationCycle(),
      this.getAutoSaveSpinningWheelInterval()]).then(([autoSaveInterval, autoSaveEnabled, excludedFromAdminRotationCycle,autoSaveSpinningWheelInterval]) => {
        return this.newDealSetupServiceHelper.createAppContext(autoSaveInterval, autoSaveEnabled, excludedFromAdminRotationCycle,autoSaveSpinningWheelInterval);
      });
  }

  getAutoSaveInterval(): any {
    // return this.mainService.getAppConfigurationValue('AutoSaveIntervalInMilliseconds').then((autoSaveIntervalInMilliseconds:any) => {
    //     let value = parseFloat(autoSaveIntervalInMilliseconds);
    //     if (isNaN(value)) {
    //       value = AppSettings.autoSaveIntervalDefault;
    //     }
    //     return value;
    // });

  }

  getAutoSaveEnabled(): any{
    // return this.mainService.getAppConfigurationValue('EnableAutoSave').then((enableAutoSave:any) => {
    //   return (enableAutoSave === 'true');
    // });
  
  }

  getExcludeFromAdminRotationCycle(): any {
    // return this.mainService.getAppConfigurationValue('ExcludedFromAdminRotationCycle').then((excludedFromAdminRotationCycle:any) => {
    //     const value = excludedFromAdminRotationCycle;
    //     return value;
    // });
      
  }

  getAutoSaveSpinningWheelInterval():any{
    // return this.mainService.getAppConfigurationValue('AutoSaveSpinningWheelInterval').then((autoSaveSpinningWheelInterval:any)=>{
    //  let value = parseFloat(autoSaveSpinningWheelInterval);
    //  if(isNaN(value)){
    //    value=AppSettings.autoSaveSpinningWheelIntervalDefault;
    //  }
    //   return value;
    // })
    
  }
  getMarketOfferingValues(): Promise<Map<string, string>> {
    return new Promise((resolve, reject) => {
      const appendURL = this.newDealService.getAppendURL(AppSettings.marketOfferingMapValuesList);
  
      // Use the promise-based GetDropDownData method
      this.newDealService.GetDropDownData(appendURL)
        .then((responseArray: any) => {
          const valuesToMap = new Map<string, string>();
          responseArray.forEach((pair: any) => {
            valuesToMap.set(pair.Offering, pair.MarketOffering);
          });
          resolve(valuesToMap);
        })
        .catch((err: any) => {
          console.log('Error fetching Market Offering Values:', err);
          reject(err);
        });
    });
  }
  
  getDCFGroupMembers() {
    return this.mainService.getGroupUsers(AppSettings.dcfComplianceGroup);
  }

  getSeries24GroupMembers() {
    return this.mainService.getGroupUsers(AppSettings.series24Group);
  }

  getAdminReviewersGroupMembers() {
    return this.newDealService.GetGroupData(AppSettings.adminReviewersGroup)
    .then(response => {
       let results:any  = response;
      return results;
    });    
  }

  getAdminReviewRotationAssignment() {
     return this.newDealService.GetGroupUsers(AppSettings.adminReviewRotationAssignmentList)
    .then(response => {
      const results = response;
      const adminUsers = results.map((user:any) => {
        return this.newDealSetupServiceHelper.createAdminAssigned(user.Admin, user.LastAssignmentDate,user.Email);     
    });    
    return adminUsers;
    });
  }
}
