import { Component, OnInit, Input } from '@angular/core';
import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';
import { FormGroup } from '@angular/forms';
import { SecurityService } from 'src/shared/services/security.service';
import { FormValidations } from 'src/shared/utils/form-validations';
import { FormListFields } from '../shared/models/form-list-fields';

@Component({
  selector: 'app-marketing-and-communications',
  templateUrl: './marketing-and-communications.component.html',
  styleUrls: ['./marketing-and-communications.component.scss']
})
export class MarketingAndCommunicationsComponent implements OnInit {
  sharePointMapper:any = new ComplianceSharepointMapper();
  marketingAndCommunicationsFormGroup: any;

  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;

  constructor(private securityService: SecurityService) { }

  ngOnInit() {
    this.marketingAndCommunicationsFormGroup = this.formGroups[0];
  }

  // Public Standart Functions

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }


  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  // Custom functions for section

  onPitchBookSelection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.pitchBook.setValue(event);
    this.evaluatePitchBook(event);
  }

  evaluatePitchBook(value: string) {
    if (value === 'YES') {
      this.marketingAndCommunicationsFormGroup.controls.pitchBookSeries24.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.pitchBookSeries24));
    }
  }

  onPitchBookSeries24Selection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.pitchBookSeries24.setValue(event);
  }

  onTeaserSelection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.teaser.setValue(event);
    this.evaluateTeaser(event);
  }

  evaluateTeaser(value: string) {
    if (value === 'YES') {
      this.marketingAndCommunicationsFormGroup.controls.teaserSeries24.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.teaserSeries24));
    }
  }

  onTeaserSeries24Selection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.teaserSeries24.setValue(event);
  }

  onCimMemorandumPPMSelection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.cimMemorandumPPM.setValue(event);
    this.evaluateCimMemorandumPPM(event);
  }

  evaluateCimMemorandumPPM(value: string) {
    if (value === 'YES') {
      this.marketingAndCommunicationsFormGroup.controls.cimMemorandumPPMSeries24.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.cimMemorandumPPMSeries24));
    }
  }

  onCimMemorandumPPMSeries24Selection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.cimMemorandumPPMSeries24.setValue(event);
  }

  onTombstoneSelection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.tombstone.setValue(event);
    this.evaluateTombstone(event);
  }

  evaluateTombstone(value: string) {
    if (value === 'YES') {
      this.marketingAndCommunicationsFormGroup.controls.tombstoneSeries24.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.tombstoneSeries24));
    }
  }

  onTombstoneSeries24Selection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.tombstoneSeries24.setValue(event);
  }

  onCaseStudiesSelection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.caseStudies.setValue(event);
    this.evaluateCaseStudies(event);
  }

  evaluateCaseStudies(value: string) {
    if (value === 'YES') {
      this.marketingAndCommunicationsFormGroup.controls.caseStudiesSeries24.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.caseStudiesSeries24));
    }
  }

  onCaseStudiesSeries24Selection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.caseStudiesSeries24.setValue(event);
  }

  onPressReleaseSelection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.pressRelease.setValue(event);
    this.evaluatePressRelease(event);
  }

  evaluatePressRelease(value: string) {
    if (value === 'YES') {
      this.marketingAndCommunicationsFormGroup.controls.pressReleaseSeries24.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.pressReleaseSeries24));
    }
  }

  onPressReleaseSeries24Selection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.pressReleaseSeries24.setValue(event);
  }

  onLeagueTablesMarketingSelection(event:any) {
    this.marketingAndCommunicationsFormGroup.controls.leagueTablesMarketing.setValue(event);
  }

  // Private Standart Functions

  private _setAsDisabled(formControlName: string) {
    const controlName = this.marketingAndCommunicationsFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
}
