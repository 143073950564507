<div *ngIf="myDealAdminGroup" class="row third-party-container" id="audit-log">
  <span class="third-party-title-section">AuditLog</span>
  <hr style="width: 98%;
  margin-left: 0% !important;">

  <div class="tblcustom" >
    <table  style="width:99%">
      <tr>
        <th>Date</th>
        <th>Modified By</th> 
        <th>Action Taken</th>
        <th>Deal Team Member</th> 
        <th>Form Name</th>
      </tr>
      <tr *ngFor="let item of AuditLog;let i = index" [ngClass]="{'hidden': i>=5 && !toggleClass}" >
        <td>{{item.Created}}</td>
        <td>{{item.CreatedBy}}</td>
        <td>{{item.Action}}</td>
        <td>{{item.DealTeamMember}}</td>
        <td>{{item.FormName}}</td>
      </tr>
      
    </table>
    <div  style="float: right;"><a  id="arrow-comments-3" class='glyphicon comments-down-arrow comments-right-arrow  size' (click)="toggleClass = !toggleClass" [ngClass]="{' glyphicon-chevron-up ': !toggleClass,'glyphicon-chevron-down':toggleClass }"  ></a></div>
  </div>
</div>
