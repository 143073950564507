import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';
import { CounterpartiesTableComponent } from '../counterparties-table/counterparties-table.component';
import { DropdownItem } from '../../../shared/models/dropdown-item';
import { PeoplePickerUser } from '../../../shared/models/people-picker-user';
import { PeoplepickerDynamicTableItem } from '../../../shared/models/peoplepicker-dynamic-table-item';
import { FreeTextTableItem } from '../../../shared/models/free-text-table-item';
import { PeoplePickerSelectedRow } from '../../../shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { FreeTextSelectedRow } from '../../../shared/components/free-text-table/free-text-selected-row.interface';
import { DropdownService } from '../../../shared/services/dropwdown.service';
import { SecurityService } from '../../../shared/services/security.service';
import { PeoplepickerService } from '../../../shared/services/peoplepicker.service';
import { AppSettings } from '../../../shared/app-settings';
import { FormListFields } from '../shared/models/form-list-fields';
import { FormValidations } from '../../../shared/utils/form-validations';
import { CustomFormControl } from '../../../shared/custom/custom-form-control';
import { CommonHelperService} from 'src/app/commonHelper/common-helper.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { PreviousPpmdTableComponent } from '../previous-ppmd-table/previous-ppmd-table.component';
import { PreviousS24TableComponent } from '../previous-s24-table/previous-s24-table.component';
import {MainService} from '../../../shared/services/main.service';
 
@Component({
  selector: 'app-engagement-overview',
  templateUrl: './engagement-overview.component.html',
  styleUrls: ['./engagement-overview.component.scss']
})

export class EngagementOverviewComponent implements OnInit {
  sharePointMapper = new ComplianceSharepointMapper();
  dealTypeOptions: Array<DropdownItem> = [];
  currents24Options: Array<DropdownItem> = [];
  currentPPMDLead: Array<PeoplePickerUser> = [];
  series24LeftDCF: boolean;
  engagementOverviewFormGroup: FormGroup;
  myDealFormGroup: FormGroup;
  pS24Values: Array<PeoplepickerDynamicTableItem> = [];
  ppmdValues: Array<PeoplepickerDynamicTableItem> = [];
  counterpartiesValues: Array<FreeTextTableItem> = [];
  allowMultiple = false;

  updatedPS24 = true;
  updatedPpmd = true;
  updatedCounterParties = true;
  currentPPMDLeadActive = false;

  @Input() formSubmitted: boolean;
  @Input() formGroups: Array<FormGroup>;
  @Input() readonlyS24: boolean;
  @Input() readonlyPPMD: boolean;
  @Input() readOnly: boolean;

  @Input()
  notApplicableEnabled = true;

  @Input()
  S24DeletionActive?: boolean;
  @Input()
  PPMDDeletionActive?: boolean;

  @Output() pS24RowToStore = new EventEmitter<PeoplePickerSelectedRow>();
  @Output() dealTeamRowToDelete = new EventEmitter<PeoplepickerDynamicTableItem[]>();

  @Output() ppmdRowToStore = new EventEmitter<PeoplePickerSelectedRow>();
  @Output() ppmdRowToDelete = new EventEmitter<PeoplepickerDynamicTableItem[]>();

  @Output()
  dttlRowToStore: EventEmitter<FreeTextSelectedRow> = new EventEmitter<FreeTextSelectedRow>();

  @Output() dealTypeChange = new EventEmitter<string>();
  @ViewChild(PreviousS24TableComponent, { static: false })
  previousS24TableComponent: PreviousS24TableComponent;

  @ViewChild(PreviousPpmdTableComponent, { static: false })
  previousPPMDTableComponent: PreviousPpmdTableComponent;

  @ViewChild(CounterpartiesTableComponent, { static: false })
  counterpartiesTableComponent: CounterpartiesTableComponent;

  constructor(private dropdownService: DropdownService,
    private securityService: SecurityService,
    private peoplepickerService: PeoplepickerService,
    private commonHelperService :CommonHelperService,
    private newdealservice:NewdealService,
    private mainservice:MainService
  ) { }

  ngOnInit() {
    this.engagementOverviewFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
  //complianceDealTypeOptions
  this.dealTypeOptions = AppSettings.complianceDealTypeOptions;
      this.GetS24Users();
  }


  GetS24Users() {
    this.newdealservice.GetS24Users().subscribe(
      {
        next: (result: any) => {
           this.mainservice.myDealS24List=result;
          const s24dropdownItems = result.map((item: any) => {
            const dropdownItem = new DropdownItem();
            dropdownItem.key = item.Email;
            dropdownItem.value = item.LastName+', '+item.FirstName; 
            return dropdownItem;
          }).sort((a:any,b:any)=>a.value > b.value?1:-1);
          this.currents24Options = s24dropdownItems;  
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          //this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
        }
      })
  }

  // Standard functions

  setSharepointMapper(mapper: ComplianceSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if(formControlName=="currentPPMDLead"&&formGroup!=null){
      formGroup.get(formControlName)?.setErrors({required:true});
      return true;
    }
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if(formControlName=="series24"&&formGroup!=null){
      if(formGroup.get(formControlName)?.value==undefined){
      formGroup.get(formControlName)?.setErrors({required:true});
      }
    }
    if(formControlName=="currentPPMDLead"&&formGroup!=null){
      if (typeof(formGroup.get(formControlName)?.value)!='object' &&(formGroup.get(formControlName)?.value != undefined && 
      formGroup.get(formControlName)?.value != null && 
      formGroup.get(formControlName)?.value != "" )) {
      formGroup.get(formControlName)?.setErrors(null);
      }
      else{
        formGroup.get(formControlName)?.setErrors({required:true});
      }
    }
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  hasPermission(action: string): boolean {
    return !!this.securityService.hasPermission(action);
  }

  setItemPeoplePicker(itemId: number, index: number) {
    this.previousS24TableComponent.setItemId(itemId, index);
  }

  setItemPeoplePickerPPMD(itemId: number, index: number) {
    this.previousPPMDTableComponent.setItemId(itemId, index);
  }

  // Fields functions

  onDealTypeSelection(event : any ) {
    this.engagementOverviewFormGroup.controls.dealType.setValue(event.value);
    this.dealTypeChange.emit(event.value);
  }

  onDisableDealType(value: string) {
    this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.complianceDealType));
  }

  onCurrentSeries24Selection(event : any ) {
    this.myDealFormGroup.controls.series24.setValue(event.key);
  }

  onCurrentPPMDSelection(event : any ) {
    this.engagementOverviewFormGroup.controls.currentPPMDLead.setValue(event.key);
  }

  onRemoveFromBlacklist(event : any ) {
    this.engagementOverviewFormGroup.controls.removeFromBlackList.setValue(event);
  }

  onIsClientPublicEntity(event : any ) {
    this.engagementOverviewFormGroup.controls.isClientPublicEntity.setValue(event);
    this.evaluateIsThisClientPublicEntity(event);
  }

  evaluateIsThisClientPublicEntity(value: string) {
    if (value === 'YES') {
      this.engagementOverviewFormGroup.controls.symbol.enable();
      this.engagementOverviewFormGroup.controls.removeFromBlackList.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.symbol));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.removeFromBlackList));
    }
  }

  onDealTeamRowSelection(rowToStore: PeoplePickerSelectedRow) {
    this.pS24RowToStore.emit(rowToStore);
  }

  onPPMDRowSelection(rowToStore: PeoplePickerSelectedRow) {
    this.ppmdRowToStore.emit(rowToStore);
  }

  onWerePreviousSeries24(event : any ) {
    this.engagementOverviewFormGroup.controls.werePreviousSeries24.setValue(event);
    this.evaluateIsPreviousSeries24(event);
  }

  evaluateIsPreviousSeries24(value:any) {
    if (value === 'YES') {
      this.engagementOverviewFormGroup.controls.previousSeries24.enable();
      this.readonlyS24 = false;
      this.setSeries24ReadOnly(false);
    } else {
      this.setSeries24ReadOnly(true);
      this.engagementOverviewFormGroup.controls.previousSeries24.disable();
      this.readonlyS24 = true;
      if (this.pS24Values.length > 0) {
        this.updateExcludedUsersS24();
      }
      this._deleteSeries24();
    }
  }
  onPPMDDCFUser(event : any ) {
    this.engagementOverviewFormGroup.controls.pPMDDCFUser.setValue(event);
    this.evaluateIsonPPMDDCFUser(event);
  }

  evaluateIsonPPMDDCFUser(value: any) {
    if (value === 'YES' || value == null) {
      this.engagementOverviewFormGroup.controls.currentPPMDLead.enable();
      this.engagementOverviewFormGroup.controls.currentPPMDLead.setErrors({required:true});
      //this.engagementOverviewFormGroup.controls.pPMDNonDCFUser.disable();
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.pPMDNonDCFUser));
      this.currentPPMDLeadActive = true;

    } else {
      this.engagementOverviewFormGroup.controls.currentPPMDLead.setValue(null);
      //this.engagementOverviewFormGroup.controls.CurrentPPMDLeadDisplayNames.setValue(null);
      this.engagementOverviewFormGroup.controls.currentPPMDLead.disable();
      this.engagementOverviewFormGroup.controls.pPMDNonDCFUser.enable();
      this.currentPPMDLead = [];
      this.currentPPMDLeadActive = false;
      //this.engagementOverviewFormGroup.controls.currentPPMDLead.setValue([]);
    }
  }

  setSeries24ReadOnly(isReadOnly: boolean) {
    const elem = this.engagementOverviewFormGroup.controls.previousSeries24 as CustomFormControl;
    elem.readOnly = isReadOnly;
  }


  setUpdatedPS24(updateps24Value: any) {
    this.updatedPS24 = updateps24Value;
  }

  setUpdatePPMD(updatedppmdValue: any) {
    this.updatedPpmd = updatedppmdValue;
  }

  setUpdatedCounterParties(updatedCouterParties: any) {
    this.updatedCounterParties = updatedCouterParties;
  }

  getUpdatedPS24(): boolean {
    return this.updatedPS24;
  }

  getUpdatedPPMD(): boolean {
    return this.updatedPpmd;
  }

  getUpdatedCounterParties(): boolean {
    return this.updatedCounterParties;
  }

  onWerePreviousPPMD(event : any ) {
    this.engagementOverviewFormGroup.controls.werePreviousPPMD.setValue(event);
    this.evaluateIsPreviousPPMD(event);
  }

  evaluateIsPreviousPPMD(value: any) {
    if (value === 'YES') {
      this.engagementOverviewFormGroup.controls.previousPPMDLead.enable();
      this.readonlyPPMD = false;
      this.setPPMDReadOnly(false);
    } else {
      this.setPPMDReadOnly(true);
      this.engagementOverviewFormGroup.controls.previousPPMDLead.disable();
      this.readonlyPPMD = true;
      if (this.ppmdValues.length > 0) {
        this.updateExcludedUsersPPMD();
      }
      this._deletePPMD();
    }
  }

  setPPMDReadOnly(isReadOnly: boolean) {
    const elem = this.engagementOverviewFormGroup.controls.previousPPMDLead as CustomFormControl;
    elem.readOnly = isReadOnly;
  }



  onSelectedPeoplePickerUser(user: PeoplePickerUser, inputName: string) {
    const peoplepickerObject = this._getFormGroupControl(inputName);
    // this.peoplepickerService.getUserId(user.EntityData.Email, user.EntityData.AccountName)
    //   .then((response:any) => {
        const values = peoplepickerObject.value;
        if(values!=undefined)
          {
            peoplepickerObject.setValue({ results: [...values.results, user] });
          }
          else
          {
            peoplepickerObject.setValue({ results: [user] });
          }
        
     // });
  }

  onRemovedPeoplePickerUser(user: PeoplePickerUser, inputName: string) {
    const peoplepickerObject = this._getFormGroupControl(inputName);

    // this.peoplepickerService.getUserId(user.EntityData.Email, user.EntityData.AccountName)
    //   .then((response: any) => {
        const values = peoplepickerObject.value;
        peoplepickerObject.setValue({ results: values.results.filter((x:any) => x.displayName !== user.displayName) });
      //});
  }

  setCurrentPPMDLead(currentPPMDLead:any) {
    this.currentPPMDLead = currentPPMDLead;
  }

  // TODO, create a validation message for Previous PPMD when a User lefts the List.
  setSeries24WarningMessage(value:any) {
    this.series24LeftDCF = value;
  }

  // Table functions

  setItemFreeText(itemId: number, index: number) {
    this.counterpartiesTableComponent.setItemId(itemId, index);
  }

  onFreeTextRowSelection(rowToStore: FreeTextSelectedRow) {
    this.dttlRowToStore.emit(rowToStore);
  }

  setCounterpartiesValues(counterpartiesValues: FreeTextTableItem[]) {
    this.counterpartiesValues = counterpartiesValues;
  }

  setPPMDValues(ppmdValues: PeoplepickerDynamicTableItem[]) {
    this.ppmdValues = ppmdValues;
  }

  setDealTeamValues(pS24Values: PeoplepickerDynamicTableItem[]) {
    this.pS24Values = pS24Values;
  }


  private _getFormGroupControl(formControlName: string): any {
    const formGroup = this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
    return formGroup.get(formControlName);
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }

  private updateExcludedUsersS24() {
    const s24Values = this.pS24Values;
    s24Values.forEach((element, index) => {
      element.usersSelected.forEach((peoplepickerItem, index) => {
        this.previousS24TableComponent.updateExcludedUsersS24(peoplepickerItem, index);
      });
    });
  }

  private updateExcludedUsersPPMD() {
    const ppmdValues = this.ppmdValues;
    ppmdValues.forEach((element, index) => {
      element.usersSelected.forEach((peoplepickerItem, index) => {
        //this.previousPPMDTableComponent.updateExcludedUsersPPMD(peoplepickerItem, index);
      });
    });
  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.engagementOverviewFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _deleteSeries24() {
    if (this.pS24Values.length > 0) {
      this.dealTeamRowToDelete.emit(this.pS24Values);
      this.engagementOverviewFormGroup.get(this.sharePointMapper.getFormControlColumnName(FormListFields.previousSeries24))?.setValue([]);
      this.pS24Values = [];
    }
  }

  private _deletePPMD() {
    if (this.ppmdValues.length > 0) {
      this.ppmdRowToDelete.emit(this.ppmdValues);
      this.engagementOverviewFormGroup.get(this.sharePointMapper.getFormControlColumnName(FormListFields.previousPPMDLead))?.setValue([]);
      this.ppmdValues = [];
    }
  }
  getDealTeamRowsComplete(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.pS24Values) {
      this.pS24Values.forEach(row => {
        if (row && row.isComplete()) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }
  getDealTeamRowsCompleteppmd(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.ppmdValues) {
      this.ppmdValues.forEach(row => {
        if (row && row.isComplete()) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }
  getDealTeamRowsComplete_CCL(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.pS24Values) {
      this.pS24Values.forEach(row => {
        if (row) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }
  getDealTeamRowsCompleteppmd_CCL(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.ppmdValues) {
      this.ppmdValues.forEach(row => {
        if (row) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }

  getCounterPartiesRowsComplete(): Array<FreeTextTableItem> {
    const rowsCompleted: Array<FreeTextTableItem> = [];
    if (this.counterpartiesValues) {
      this.counterpartiesValues.forEach(row => {
        if (row && row.isComplete()) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }
}
