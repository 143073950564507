import { Component, OnInit, Input, Output ,EventEmitter} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormValidations } from 'src/shared/utils/form-validations';
import { FormListFields } from '../models/form-list-fields';
import { TooltipObject } from 'src/shared/models/tooltip-object';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss']
})
export class ClientInformationComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();

  @Input() formGroups: any;
  @Input() formSubmitted: boolean;
  @Output() isCrossBorder = new EventEmitter<boolean>();

  clientInformationFormGroup: FormGroup;
  myDealFormGroup : any;

  constructor(private tooltipHelperService: TooltipHelperService) { }

  ngOnInit() {
    this.clientInformationFormGroup = this.formGroups[0];
    this.myDealFormGroup = this.formGroups[1];
  }

  getControlName(formControlName: string):any {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return formGroup.get(formControlName);
    }
    return null;
  }

  isReadOnly(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.isReadOnly(formGroup, formControlName);
    }
    return false;
  }

  showAsterisk(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.showAsterisk(formGroup, formControlName);
    }
    return false;
  }

  highlightRequiredField(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.highlightRequiredField(this.formSubmitted, formGroup, formControlName);
    }
    return false;
  }

  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }

  hasMergeConflict(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.hasMergeConflict(formGroup, formControlName);
    }
    return false;
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }

  // onClientIDChange(event) {
  //   this.myDealFormGroup.controls.clientID.setValue(event);
  // }

  onMemberFirmReferenceIDChange(event:any) {
    this.clientInformationFormGroup.controls.memberFirmReferenceID.setValue(event);
  }

  onmemberFirmClientIDChange(event:any) {
    this.clientInformationFormGroup.controls.memberFirmClientID.setValue(event);
  }

  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameDealWbs(fieldName);
  }
  
  getTooltipText(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }

  onIsThisCrossBorderSelection(event:any) {
    this.clientInformationFormGroup.controls.isThisCrossBorder.setValue(event);
    this.evaluateIsThisCrossBorderDependency(event);
    if (event === 'YES')
      this.isCrossBorder.emit(true);
    else
      this.isCrossBorder.emit(false);
  }

  evaluateIsThisCrossBorderDependency(value:any) {
    if (value === 'YES') {
      this.clientInformationFormGroup.controls.memberFirm.enable();
      this.clientInformationFormGroup.controls.memberFirmContract.enable();
      this.clientInformationFormGroup.controls.memberFirmReferenceID.enable();
      this.clientInformationFormGroup.controls.memberFirmClientID.enable();
    } else {
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.memberFirm));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.memberFirmContract));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.memberFirmReferenceID));
      this._setAsDisabled(this.sharePointMapper.getFormControlColumnName(FormListFields.memberFirmClientID));
    }

  }

  private _setAsDisabled(formControlName: string) {
    const controlName = this.clientInformationFormGroup.get(formControlName);
    if (controlName) {
      controlName.disable();
      controlName.setValue(null);
    }
  }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
  
}
