import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SandboxImages {

  getUnderlineImage(): string {
    return 'url(https://fa.global.deloitteonline.com/sites/dcfdflow25/_catalogs/theme/Themed/F9B2998C/usersquiggle-BA0D81C7.themedpng?ctag)';
  }
}
