<div class="row">
  <div class="col-xs-6">
    <div class="headerManagement">Registration Termination CheckList</div>
  </div>
</div>
<form [formGroup]="terminationFormGroup" (ngSubmit)="onSubmit()"(keydown.enter)="$event.preventDefault()">
<div id="termination-form"
 class="container-fluid border container-border-color container-section">
 <button 
            type="button" 
            class="btn btn-success"
            name="AuditFormButton"
            id="AuditFormButton" (click)="getTerminationFormAuditDetials()" [disabled]="!isExportEnabled">Audit Details</button> 
  <div class="row bg-title">
    <span>Termination Form</span>
  </div>
  <div id="termination-form-section" class="content-section">
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('lastName')" >Last Name </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-6 input-width input-align">
        <input formControlName="lastName" 
          class="input-data-text full-width section-input" 
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
  
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('firstName')" >First Name </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-6 input-width input-align">
        <input formControlName="firstName" 
          class="input-data-text full-width section-input" 
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span  [class.highlight-warning]="highlightRequiredField('dateOfTermination')">Date of Termination </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-7 input-align" >
        <app-date-picker 
        [selectedDate]="terminationFormGroup.get('dateOfTermination')?.value"
        (dateSelected)="eventDateSelected($event,'dateOfTermination')">
        </app-date-picker>
        <span class="numeric-validation-msg"></span>
      </div>
    </div>
        <div class="row">
            <div class="col-xs-4">
                <span [class.highlight-warning]="highlightRequiredField('voluntaryTermination')
                || (terminationFormGroup.get('voluntaryTermination')?.value=='No' && highlightRequiredField('voluntaryTerminationReason') )">Voluntary Termination </span>
                <span style="color:red">*</span>
            </div>
            <div class="col-xs-4 radio-button-align">
                <input class="radioStyle"  type="radio" formControlName="voluntaryTermination"  value="Yes" (change)="onVoluntaryTerminationChange($event)"> Yes
                <input class="radioStyle"  type="radio" formControlName="voluntaryTermination"  value="No"  (change)="onVoluntaryTerminationChange($event)"> No
                <textarea cols="2" style="margin-left: 4%;" *ngIf="isVoluntaryTerminationReason"
                          type="text"
                          formControlName="voluntaryTerminationReason"
                          rows="3"
                          placeholder="Reason"
                          class="input-data-text text-area-style"
                          ></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4">
              <span [class.highlight-warning]="highlightRequiredField('StatusofIndividual') || (terminationFormGroup.get('StatusofIndividual')?.value=='Registered' && highlightRequiredField('RegisteredStatus'))
              ||(terminationFormGroup.get('StatusofIndividual')?.value=='NRF' && highlightRequiredField('NRFStatus'))
              || (terminationFormGroup.get('StatusofIndividual')?.value=='NRF' && terminationFormGroup.get('NRFStatus')?.value=='Other' && highlightRequiredField('otherNRFStatusReason')) ">Status of Individual </span>
              <span style="color:red">*</span>
            </div>
            <div class="col-xs-4 radio-button-align">
              <input class="radioStyle" type="radio" formControlName="StatusofIndividual" value="Registered" (change)="onStatusofIndividualChange($event)"> Registered
              <input class="radioStyle" type="radio" formControlName="StatusofIndividual" value="NRF" (change)="onStatusofIndividualChange($event)"> NRF
            </div>  
            <div class="col-xs-8" style="float: right;">
              <div class="col-xs-8" style="margin-left: 3.5%;" *ngIf="isRegisteredStatus">
                Supervisory <input class="radioStyle" type="radio" formControlName="RegisteredStatus"  value="Yes" > Yes
                <input class="radioStyle" type="radio" formControlName="RegisteredStatus"  value="No" > No
              </div> 

              <div class="col-xs-8" style="margin-left: 0.88%;" *ngIf="isNRFStatus">
                <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="Intern" (change)="onNRFStatusChange($event)"> Intern
                <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="USI" (change)="onNRFStatusChange($event)"> USI
                <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="BDA Contractor" (change)="onNRFStatusChange($event)"> BDA Contractor
                <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="Other" (change)="onNRFStatusChange($event)"> Other
              </div> 

              <!-- <div class="col-xs-8" *ngIf="isOtherNRFStatus">
                <input style="width: 60%;"
                  class="input-data-text section-input" 
                  type="text"
                  autocomplete="off"
                  maxlength="255">
              </div> -->
              <textarea style="width: 50%;margin-left: 3.5%;" cols="2" *ngIf="isOtherNRFStatus&& !isRegisteredStatus"
                  type="text"
                  rows="3"
                  formControlName="otherNRFStatusReason"
                  placeholder="Reason"
                  class="input-data-text text-area-style"
                  ></textarea>
          </div>     
          </div>
          <div class="row">
            <div class="col-xs-4 section-label">
              <span [class.highlight-warning]="highlightRequiredField('series24LicensedSupervisor')">Name of Series 24 Licensed Supervisor at the time of termination </span>
              <span style="color:red">*</span>
            </div>
            <div class="col-xs-6 input-width input-align">
              <app-dropdown
              [optionsList]="s24Options"
              [keySelected]="terminationFormGroup.get('series24LicensedSupervisor')?.value"
              [userLeftDCF]="series24LeftDCF"
              (optionSelected)="onSeries24Selection($event)">
            </app-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('TerminationReason')
            || ((terminationFormGroup.get('TerminationReason')?.value=='No'|| terminationFormGroup.get('TerminationReason')?.value=='Other') && highlightRequiredField('TerminationReasonComments') )">Does the reason for termination match the reason contained within Talent file </span>
            <span style="color:red">*</span>
            </div>
            <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="TerminationReason"  value="Yes" (change)="onTerminationMatchChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="TerminationReason"  value="No" (change)="onTerminationMatchChange($event)"> No
            <input class="radioStyle" type="radio" formControlName="TerminationReason"  value="Other" (change)="onTerminationMatchChange($event)"> Other
            <textarea cols="2"style="margin-left:4%" *ngIf="isTerminationReason"
                  type="text"
                  formControlName="TerminationReasonComments"
                  rows="3"
                  placeholder="Reason"
                  class="input-data-text text-area-style "></textarea>
            </div>
        </div>
        <div class="row">
          <div class="col-xs-4">
          <span [class.highlight-warning]="highlightRequiredField('ThreatTeamContact')
          || ((terminationFormGroup.get('ThreatTeamContact')?.value=='No'|| terminationFormGroup.get('ThreatTeamContact')?.value=='Other') && highlightRequiredField('threatTeamContactReason') )">The insider threat team was contacted to confirm there are no open cases for the Terminated Individual. </span>
          <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
          <input class="radioStyle" type="radio" formControlName="ThreatTeamContact"  value="Yes" (change)="onThreatTeamContanctChange($event)"> Yes
          <input class="radioStyle" type="radio" formControlName="ThreatTeamContact"  value="No" (change)="onThreatTeamContanctChange($event)"> No
          <input class="radioStyle" type="radio" formControlName="ThreatTeamContact"  value="Other" (change)="onThreatTeamContanctChange($event)"> Other
          <textarea style="margin-left: 4%;" cols="2" *ngIf="isThreatTeamContactReason"
                  type="text"
                  formControlName="threatTeamContactReason"
                  rows="3"
                  placeholder="Reason"
                  class="input-data-text text-area-style"></textarea>
          </div>
      </div>
      <div class="row">
        <div class="col-xs-4">
        <span  [class.highlight-warning]="highlightRequiredField('OpenInternalThreat')
        || ((terminationFormGroup.get('OpenInternalThreat')?.value=='Yes'|| terminationFormGroup.get('OpenInternalThreat')?.value=='Other') && highlightRequiredField('openInternalThreatReason') )">Were there any open internal threat cases for this Terminated Individual? </span>
        <span style="color:red">*</span>
        </div>
        <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="OpenInternalThreat"  value="Yes" (change)="onOpenInternalThreatChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="OpenInternalThreat"  value="No" (change)="onOpenInternalThreatChange($event)"> No
        <input class="radioStyle" type="radio" formControlName="OpenInternalThreat"  value="Other" (change)="onOpenInternalThreatChange($event)"> Other
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isOpenInternalThreatReason"
                  type="text"
                  formControlName="openInternalThreatReason"
                  rows="3"
                  placeholder="Reason"
                  class="input-data-text text-area-style"></textarea>
        </div>
    </div>
    <div class="row">

          <div class="col-xs-4 margin-top-5">
              <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRemoved')">Date the Terminated Individual was removed from Outlook: US National Corporate Finance </span>
              <span style="color:red">*</span>
          </div>
          <div class="col-xs-7 input-align" >
              <app-date-picker
                [selectedDate]="terminationFormGroup.get('dateTheEmployeeRemoved')?.value"
                (dateSelected)="eventDateSelected($event,'dateTheEmployeeRemoved')">
              </app-date-picker>
              <span class="numeric-validation-msg"></span>
          </div>
  </div>
  <div class="row">

    <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('jakiePolsonNotifiedDate')
        && highlightRequiredField('jakiePolsonNotifiedDateNA')">Date Jackie Polson (or team) was notified a PPMD or client facing PPMD was being terminated (if applicable) </span>
        <span style="color:red">*</span>
    </div>
    <div class="col-xs-7 input-align" >
        <app-date-picker
          [selectedDate]="terminationFormGroup.get('jakiePolsonNotifiedDate')?.value"
          (dateSelected)="eventDateSelected($event,'jakiePolsonNotifiedDate')">
        </app-date-picker>
        <input class="radioStyle" type="radio" formControlName="jakiePolsonNotifiedDateNA"  value="N/A – Not a PPMD or Client Facing PPMD"
        (change)="onjakiePolsonNotifiedDateChange($event)"> N/A – Not a PPMD or Client Facing PPMD
        <span class="numeric-validation-msg"></span>
    </div>
</div>

<div class="row">

  <div class="col-xs-4 margin-top-5">
      <span [class.highlight-warning]="highlightRequiredField('allowedToTermedDate')
      && highlightRequiredField('allowedToTermedDateNA')">Date the Terminated Individual's OBA(s) were removed from DCF's list of active OBA's. </span>
      <span style="color:red">*</span>
  </div>
  <div class="col-xs-7 input-align" >
      <app-date-picker
        [selectedDate]="terminationFormGroup.get('allowedToTermedDate')?.value"
        (dateSelected)="eventDateSelected($event,'allowedToTermedDate')">
      </app-date-picker>
      <input class="radioStyle" type="radio" formControlName="allowedToTermedDateNA"  value="N/A – No OBA(s)"
      (change)="onallowedToTermedDateChange($event)"> N/A – No OBA(s)
      <span class="numeric-validation-msg"></span>
  </div>
</div>
<div class="row">

  <div class="col-xs-4 margin-top-5">
      <span [class.highlight-warning]="highlightRequiredField('pstToTermedClosedDeniedDate') &&
      highlightRequiredField('pstToTermedClosedDeniedDateNA')">Date the Terminated Individual's PST(s) were removed from DCF's list of active PST's. </span>
      <span style="color:red">*</span>
  </div>
  <div class="col-xs-7 input-align" >
      <app-date-picker
        [selectedDate]="terminationFormGroup.get('pstToTermedClosedDeniedDate')?.value"
        (dateSelected)="eventDateSelected($event,'pstToTermedClosedDeniedDate')">
      </app-date-picker>
      <input class="radioStyle" type="radio" formControlName="pstToTermedClosedDeniedDateNA"  value="N/A – No PST(s)"
      (change)="onpstToTermedClosedDeniedDateChange($event)"> N/A – No PST(s)
      <span class="numeric-validation-msg"></span>
  </div>
</div>

  <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRemovedDCFKnowledge')">Date the Terminated Individual was removed from DCF Knowledge Center </span>
                <span style="color:red">*</span>
            </div>
            <div class="col-xs-7 input-align">
                <app-date-picker 
                  [selectedDate]="terminationFormGroup.get('dateTheEmployeeRemovedDCFKnowledge')?.value"
                  (dateSelected)="eventDateSelected($event,'dateTheEmployeeRemovedDCFKnowledge')">
                </app-date-picker>
                <span class="numeric-validation-msg"></span>
            </div>
    </div>
    
    <div class="row">

      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRemovedFlowPerms')
          || ((terminationFormGroup.get('dateTheEmployeeRemovedFlowPermsNA')?.value=='N/A') && highlightRequiredField('dateTheEmployeeRemovedFlowPermsReason'))"
          >Date the Terminated Individual was removed from the Dflow permissions spreadsheet. (if applicable) </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-7 input-align" >
          <app-date-picker
            [selectedDate]="terminationFormGroup.get('dateTheEmployeeRemovedFlowPerms')?.value"
            (dateSelected)="eventDateSelected($event,'dateTheEmployeeRemovedFlowPerms')">
          </app-date-picker>
          <span class="numeric-validation-msg"></span>
          <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemovedFlowPermsNA"  value="N/A"
          (change)="ondateTheEmployeeRemovedFlowPermsChange($event)"> N/A
      </div>
      <div class="col-xs-7" style="margin-left: 35%;">
        <textarea *ngIf="isdateTheEmployeeRemovedFlowPermsReason"
        type="text"
        rows="3"
        formControlName="dateTheEmployeeRemovedFlowPermsReason"
        placeholder="Reason"
        class="input-data-text text-area-style signMargin"></textarea>
    </div>
    </div>

    <div class="row">

      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('dateSupportTicketOpened')
          || ((terminationFormGroup.get('dateSupportTicketOpenedNA')?.value=='N/A') && highlightRequiredField('dateSupportTicketOpenedReason') )">Date a support ticket was opened with DFlow to remove the Terminated Individual from open sites (if applicable) </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-7 input-align" >
          <app-date-picker
            [selectedDate]="terminationFormGroup.get('dateSupportTicketOpened')?.value"
            (dateSelected)="eventDateSelected($event,'dateSupportTicketOpened')">
          </app-date-picker>
          <span class="numeric-validation-msg"></span>
          <input class="radioStyle" type="radio" formControlName="dateSupportTicketOpenedNA"  value="N/A"
          (change)="ondateSupportTicketOpenedChange($event)"> N/A
      </div>
      <div class="col-xs-7" style="margin-left: 35%;">
        <textarea *ngIf="isdateSupportTicketOpenedReason"
        type="text"
        rows="3"
        formControlName="dateSupportTicketOpenedReason"
        placeholder="Reason"
        class="input-data-text text-area-style signMargin"></textarea>
    </div>
    </div>
    
    <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRetired')">Date the Terminated Individual was “retired” in CSI </span>
                <span style="color:red">*</span>
            </div>
            <div class="col-xs-7 input-align">
                <app-date-picker 
                  [selectedDate]="terminationFormGroup.get('dateTheEmployeeRetired')?.value"
                  (dateSelected)="eventDateSelected($event,'dateTheEmployeeRetired')">
                </app-date-picker>
                <span class="numeric-validation-msg"></span>
            </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('SmarshEmail')
      || (terminationFormGroup.get('SmarshEmail')?.value=='No' && highlightRequiredField('SmarshEmailComment') )">Run Smarsh email spot check for the two weeks prior to termination date </span>
      <span style="color:red">*</span>
      </div>
      <div class="col-xs-7">
        <input class="radioStyle" type="radio" formControlName="SmarshEmail"  value="Yes" (change)="onSmarshEmailChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="SmarshEmail"  value="No" (change)="onSmarshEmailChange($event)"> No
        <textarea style="margin-left: 4%;width: 76%;" cols="2" *ngIf="isSmarshEmailComment"
                  type="text"
                  rows="3"
                  formControlName="SmarshEmailComment"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
      </div> 
  </div>
  <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRemovedSupervision')">
                  Date the Terminated Individual was removed from Supervision Group structure in Smarsh </span>
                  <span style="color:red">*</span>
                </div>
            <div class="col-xs-7 input-align">
                <app-date-picker
                  [selectedDate]="terminationFormGroup.get('dateTheEmployeeRemovedSupervision')?.value"
                  (dateSelected)="eventDateSelected($event,'dateTheEmployeeRemovedSupervision')">
                </app-date-picker>
                <span class="numeric-validation-msg"></span>
            </div>
    </div>
    <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeDeloitteMobile') &&
                highlightRequiredField('dateTheEmployeeDeloitteMobileNA')">
                  Date the Terminated Individual's Deloitte mobile phone was submitted for removal from Smarsh text message capture? </span>
                  <span style="color:red">*</span>
            </div>
            <div class="col-xs-7 input-align">
                <app-date-picker
                  [selectedDate]="terminationFormGroup.get('dateTheEmployeeDeloitteMobile')?.value"
                  (dateSelected)="eventDateSelected($event,'dateTheEmployeeDeloitteMobile')">
                </app-date-picker>
                <input class="radioStyle" type="radio" formControlName="dateTheEmployeeDeloitteMobileNA"  value="N/A - No Cell Phone to Term"
          (change)="ondateTheEmployeeDeloitteMobileChange($event)"> N/A - No Cell Phone to Term
                <span class="numeric-validation-msg"></span> 
            </div>
    </div>
    <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('DateTheEmployeeRemovedSupervisorOrganization')">Date the Terminated Individual was removed from Supervisory organizational chart </span>
                <span style="color:red">*</span>
            </div>
            <div class="col-xs-7 input-align">
                <app-date-picker 
                  [selectedDate]="terminationFormGroup.get('DateTheEmployeeRemovedSupervisorOrganization')?.value"
                  (dateSelected)="eventDateSelected($event,'DateTheEmployeeRemovedSupervisorOrganization')">
                </app-date-picker>
                <span class="numeric-validation-msg"></span>
            </div>
    </div>
    <div class="row">

      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('notifiedBrokerageFirmsDate') &&
          highlightRequiredField('notifiedBrokerageFirmsDateNA')">Notified brokerage firm(s) and/or Terminated Individual to discontinue duplicate statements, confirmations, and, where applicable, broker data feed(s). </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-7 input-align" >
          <app-date-picker
            [selectedDate]="terminationFormGroup.get('notifiedBrokerageFirmsDate')?.value"
            (dateSelected)="eventDateSelected($event,'notifiedBrokerageFirmsDate')">
          </app-date-picker>
          <input class="radioStyle" type="radio" formControlName="notifiedBrokerageFirmsDateNA"  value="No Accounts to Term"
          (change)="onnotifiedBrokerageFirmsDate($event)"> No Accounts to Term
          <span class="numeric-validation-msg"></span>
      </div>
    </div>
    
    <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('DateTheNewHiresTermedCompliance')
          || ((terminationFormGroup.get('DateTheNewHiresTermedComplianceNA')?.value=='N/A') && highlightRequiredField('DateTheNewHiresTermedComplianceReason') )">Information has been added to the Term tab of the New Hire & Termed spreadsheet in Associated Persons </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-7 input-align">
          <app-date-picker 
            [selectedDate]="terminationFormGroup.get('DateTheNewHiresTermedCompliance')?.value"
            (dateSelected)="eventDateSelected($event,'DateTheNewHiresTermedCompliance')">
          </app-date-picker>
          <span class="numeric-validation-msg"></span>
          <input class="radioStyle" type="radio" formControlName="DateTheNewHiresTermedComplianceNA"  value="N/A"
          (change)="onDateTheNewHiresTermedComplianceChange($event)"> N/A
      </div>
      <div class="col-xs-7" style="margin-left: 35%;">
        <textarea *ngIf="isDateTheNewHiresTermedComplianceReason"
        type="text"
        rows="3"
        formControlName="DateTheNewHiresTermedComplianceReason"
        placeholder="Reason"
        class="input-data-text text-area-style signMargin"></textarea>
    </div>      
</div>
<div class="row">
  <div class="col-xs-4 margin-top-5">
      <span [class.highlight-warning]="highlightRequiredField('terminatedIndividualRemovedEmployee')
      || ((terminationFormGroup.get('terminatedIndividualRemovedEmployeeNA')?.value=='N/A') && highlightRequiredField('terminatedIndividualRemovedEmployeeReason') )">Date the terminated individual was moved from the ‘Current Employees' folder to the ‘Terminated Employees’ folder in  'Associated Persons' </span>
      <span style="color:red">*</span>
    
  </div>
  <div class="col-xs-7 input-align">
      <app-date-picker 
        [selectedDate]="terminationFormGroup.get('terminatedIndividualRemovedEmployee')?.value"
        (dateSelected)="eventDateSelected($event,'terminatedIndividualRemovedEmployee')">
      </app-date-picker>
      <span class="numeric-validation-msg"></span>
      <input class="radioStyle" type="radio" formControlName="terminatedIndividualRemovedEmployeeNA"  value="N/A"
      (change)="onterminatedIndividualRemovedEmployeeChange($event)"> N/A
  </div>
  <div class="col-xs-7" style="margin-left: 35%;">
    <textarea *ngIf="isterminatedIndividualRemovedEmployeeReason"
    type="text"
    rows="3"
    formControlName="terminatedIndividualRemovedEmployeeReason"
    placeholder="Reason"
    class="input-data-text text-area-style signMargin"></textarea>
</div>
</div>

      <div class="row">
      <div class="col-xs-4">
        <span  [class.highlight-warning]="highlightRequiredField('SentSignedCopy')
        || (terminationFormGroup.get('SentSignedCopy')?.value=='No' && highlightRequiredField('NRF') )
        ||  (terminationFormGroup.get('SentSignedCopy')?.value=='No' && terminationFormGroup.get('NRF')?.value=='Other' && highlightRequiredField('SentSignedCopyOtherText'))" >Sent individual a signed copy of Termination Letter and Form U5 </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="SentSignedCopy"  value="Yes" (change)="onSentSignedCopyChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="SentSignedCopy"  value="No" (change)="onSentSignedCopyChange($event)"> No
        <br><div *ngIf="SentSignedCopy=='No'" style="padding-top:1.5%">
          <input class="radioStyle" type="radio" formControlName="NRF" value="NRF" (change)="onSentSignedCopyNRFChange($event)"> NRF
          <input class="radioStyle" type="radio" formControlName="NRF" value="Other" (change)="onSentSignedCopyNRFChange($event)"> Other
        </div>
      </div>
       
      <div class="col-xs-7" style="margin-left: 35.3%;padding-top: 10px !important;" *ngIf="SentSignedCopyNRF=='Other'">
        <textarea
        type="text"
        rows="3"
        formControlName="SentSignedCopyOtherText"
        placeholder="Reason"
        class="input-data-text text-area-style signMargin"></textarea>
        <!-- <app-date-picker 
          [selectedDate]="terminationFormGroup.get('SentSignedCopyDate')?.value"
          (dateSelected)="eventDateSelected($event,'SentSignedCopyDate')">
        </app-date-picker>
        <span class="numeric-validation-msg"></span> -->
    </div>

    </div>
    <div class="row">
      <div class="col-xs-4">
          <span>Additional Comments </span>
      </div>
      <div class="col-xs-4 radio-button-align">
          <textarea cols="2" style="margin-left: 4%;" 
                    type="text"
                    name="additionalComments"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="additionalComments"
                    rows="3"
                    placeholder="Comments"
                    class="input-data-text text-area-style"
                    ></textarea>
      </div>
  </div>
    <div *ngIf="ShowTrackingNumber" class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('trackingNumber') || highlightRequiredField('ShippingMethodUsed')">Tracking number of Termination letter and Form U5 </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-8">
        <div class="col-xs-3 section-label alignment">
          <span [class.highlight-warning]="highlightRequiredField('trackingNumber')">Tracking Number: </span>
          <span style="color:red">*</span>
        </div>
        <div class="col-xs-3">
          <input
          class="input-data-text full-width section-input" formControlName="trackingNumber"
          type="text"
          autocomplete="off"
          maxlength="255">
        </div> <br><br>
        <div class="col-xs-3 section-label alignment">
          <span [class.highlight-warning]="highlightRequiredField('ShippingMethodUsed')">Shipping Method Used: </span>
          <span style="color:red">*</span>
        </div>
        <div class="col-xs-3">
          <span><input
            class="input-data-text full-width section-input" formControlName="ShippingMethodUsed"
            type="text"
            autocomplete="off"
            maxlength="255"></span>
        </div>
        
      </div>
    </div>
    <!-- <div class="row" *ngIf="actionType=='Submit'">
      <div class="col-xs-4 margin-top-5">
          <span>Signature Date </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-8">
        <label class="marginTop alignment">{{signatureDate}}</label>
           <app-date-picker
            [selectedDate]="terminationFormGroup.get('SignatureDate')?.value"
            (dateSelected)="eventDateSelected($event,'SignatureDate')">
          </app-date-picker>
          <span class="numeric-validation-msg"></span>
      </div>
</div> -->
        <div class="row" *ngIf="actionType=='Submit'">
          <div class="col-xs-4 section-label">
            <span>Signature Data </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-7" style="margin-left: 2%;">
            <termination-form-signature-table [signatureData]="signatureData"></termination-form-signature-table>
          </div>
        </div>
  </div>
</div>
<div class="submit-buttons floatStyle">
  <button  *ngIf="actionType!='Submit'"
   type="submit"
   class="btn btn-primary submit-btn"(click)="onSubmit()">
   Submit
 </button>
 
 <button type="button" (click)="OnSaveForm($event)" name="save"
   class="btn btn-outline-primary save-rework-cancel-btn margin-left-10">
   Save
 </button>
</div>
</form>
<app-termination-notify-popup [formID]="formID"></app-termination-notify-popup>
