export class SharepointObjectMyDeal {
  ClientName: string;
  // ClientID: string;
  EngagementName: string;
  StartDate: any;
  EndDate: any;
  IsRecurringWBS: string;
  DeloitteUSEntityId: string;
  IntegratedMarketOffering: string;
  SalesOffice: string;
  EngagementIndustrySICCode: string;
  IsInternationalEngagement: string;
  AreResourcesWorkOutside: any;
  MemberFirmTaxablePresence: any;
  OtherMemberFirmsParticipating: any;
  WhereWorkMemberFirms: any;
  BillingAddressSameClientAddress: string;
  BillingAddress: any;
  PayerAddressSameClientAddress: any;
  PayerAddress: any;
  MarketOffering: string;
  IsServiceSameAsClientAddress: string;
  ServiceAtAddress: string;
  Material: string;
  Series24: number;
  PreviousAuditor: any;
  CurrentAuditor: any;
  WbsCode: string;
  PrdCode:string;
  OpportunityID: string;
  //participatingPartner: string;
}
