<div id="prd" [formGroup]="myDealFormGroup" class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
    <span>PRD</span>
  </div>
  <div id="prd-section" class="content-section">
    <div class="row">
        <div class="col-xs-4 margin-top-5">
          <span>Deal Team</span>
        </div>
        <div class="col-xs-8">
          <app-deal-team-table
            [readonly]="true"
            [dealTeamValues]="showDealTeamRowsComplete()"
            [firstColumnVisible]="false"
            [secondColumnVisible]="false">
          </app-deal-team-table>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('clientID')"
                [class.edited-externally]="editedExternally('clientID')"
                [class.conflict-on-merge]="hasMergeConflict('clientID')">Client #</span>
            <span *ngIf="showAsterisk('clientID')" class="red-asterik"></span>
            <app-popover
              [tooltipText]="getTooltipText('ClientID')">
            </app-popover>
        </div>
        <div class="col-xs-8">
            <input
            formControlName="clientID"
            class="input-data-text full-width section-input"
            [class.readonly]="isReadOnly('clientID')"
            type="text"
            autocomplete="off"
            maxlength="255">
            <!--UAT Feedback Change: Modify field type to normal text from numeric -->
            <!-- <app-numeric
              [type]="'number'"
              [value]="myDealFormGroup.get('clientID').value"
              [readonly]="isReadOnly('clientID')"
              (selectedValue)="onClientIDSelection($event)">
            </app-numeric> -->
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('engagementType')"
                [class.edited-externally]="editedExternally('engagementType')"
                [class.conflict-on-merge]="hasMergeConflict('engagementType')">Engagement Type</span>
            <span class="red-asterik" ></span> <!--TODO: convert this validation to dynamic in future if this is changed to editable field -->
            <app-popover
              [tooltipText]="getTooltipText('EngagementType')">
            </app-popover>
        </div>
        <div class="col-xs-8">
            <input
              formControlName="engagementType"
              class="input-data-text full-width section-input"
              [class.readonly]="isReadOnly('engagementType')"
              type="text"
              autocomplete="off"
              maxlength="255">
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('isRecurringWBS')"
                [class.edited-externally]="editedExternally('isRecurringWBS')"
                [class.conflict-on-merge]="hasMergeConflict('isRecurringWBS')">Is this a Recurring WBS?</span>
            <span *ngIf="showAsterisk('isRecurringWBS')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <app-toggle-yes-no  #yesNoRecurringWBS
              [value]="myDealFormGroup.get('isRecurringWBS')?.value"
              [readonly]="isReadOnly('isRecurringWBS')"
              [disabled]="myDealFormGroup.get('isRecurringWBS')?.disabled"
              (handleToggleYesNo)="onIsRecurringWBSSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('deloitteUSEntity')"
                [class.edited-externally]="editedExternally('deloitteUSEntity')"
                [class.conflict-on-merge]="hasMergeConflict('deloitteUSEntity')">Deloitte US Entity</span>
            <span *ngIf="showAsterisk('deloitteUSEntity')" class="red-asterik"></span>
            <app-popover
              [tooltipText]="getTooltipText('DeloitteUSEntityId')">
            </app-popover>
        </div>
        <div class="col-xs-8 typeahead-input">
            <app-typeahead
                [listName]="deloitteUSEntityCustomList"
                [keySelected]="myDealFormGroup.get('deloitteUSEntity').value"
                [readonly]="isReadOnly('deloitteUSEntity')"
                (selectedTypeaheadItem)="onDeloitteUSEntitySelection($event)">
            </app-typeahead>
        </div>
    </div> -->
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('salesOffice')"
                [class.edited-externally]="editedExternally('salesOffice')"
                [class.conflict-on-merge]="hasMergeConflict('salesOffice')">Sales Office</span>
            <span *ngIf="showAsterisk('salesOffice')" class="red-asterik"></span>
            <app-popover
              [tooltipText]="getTooltipText('SalesOffice')">
            </app-popover>
        </div>
        <div class="col-xs-8 typeahead-input">
            <app-typeahead
                [listName]="salesOfficeCustomList"
                [valueSelected]="myDealFormGroup.get('salesOffice')?.value"
                [readonly]="isReadOnly('salesOffice')"
                (selectedTypeaheadItem)="onSalesOfficeSelection($event)">
            </app-typeahead>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('isInternationalEngagement')"
                [class.edited-externally]="editedExternally('isInternationalEngagement')"
                [class.conflict-on-merge]="hasMergeConflict('isInternationalEngagement')">Is this an International Engagement?</span>
            <span *ngIf="showAsterisk('isInternationalEngagement')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <app-toggle-yes-no
                [value]="myDealFormGroup.get('isInternationalEngagement')?.value"
                [readonly]="isReadOnly('isInternationalEngagement')"
                (handleToggleYesNo)="onInternationalEngagementSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('areResourcesWorkOutside')"
                [class.edited-externally]="editedExternally('areResourcesWorkOutside')"
             [class.conflict-on-merge]="hasMergeConflict('areResourcesWorkOutside')">Are the local resources performing work outside the home country?</span>
            <span *ngIf="showAsterisk('areResourcesWorkOutside')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <app-toggle-yes-no
              [value]="myDealFormGroup.get('areResourcesWorkOutside')?.value"
              [disabled]="myDealFormGroup.get('areResourcesWorkOutside')?.disabled"
              [readonly]="isReadOnly('areResourcesWorkOutside')"
              (handleToggleYesNo)="onAreResourcesWorkOutsideSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('memberFirmTaxablePresence')"
                [class.edited-externally]="editedExternally('memberFirmTaxablePresence')"
                [class.conflict-on-merge]="hasMergeConflict('memberFirmTaxablePresence')">Does the member firm have a taxable presence in the involved countries?</span>
            <span *ngIf="showAsterisk('memberFirmTaxablePresence')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <app-toggle-yes-no
              [value]="myDealFormGroup.get('memberFirmTaxablePresence')?.value"
              [disabled]="myDealFormGroup.get('memberFirmTaxablePresence')?.disabled"
              [readonly]="isReadOnly('memberFirmTaxablePresence')"
              (handleToggleYesNo)="onMemberFirmTaxablePresenceSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('otherMemberFirmsParticipating')"
                [class.edited-externally]="editedExternally('otherMemberFirmsParticipating')"
                [class.conflict-on-merge]="hasMergeConflict('otherMemberFirmsParticipating')">Are any other member firms participating on this engagement?</span>
            <span *ngIf="showAsterisk('otherMemberFirmsParticipating')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <app-toggle-yes-no
              [value]="myDealFormGroup.get('otherMemberFirmsParticipating')?.value"
              [disabled]="myDealFormGroup.get('otherMemberFirmsParticipating')?.disabled"
              [readonly]="isReadOnly('otherMemberFirmsParticipating')"
              (handleToggleYesNo)="onOtherMemberFirmsParticipatingSelection($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('whereWorkMemberFirms')"
                [class.edited-externally]="editedExternally('whereWorkMemberFirms')"
                [class.conflict-on-merge]="hasMergeConflict('whereWorkMemberFirms')">Where are these member firm employees working from?</span>
            <span *ngIf="showAsterisk('whereWorkMemberFirms')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <app-dropdown
              [optionsList]="employessWorkingPlacesList"
              [keySelected]="myDealFormGroup.get('whereWorkMemberFirms')?.value"
              [disabled]="$any(myDealFormGroup).get('whereWorkMemberFirms')?.disabled"
              [readonly]="isReadOnly('whereWorkMemberFirms')"
              (optionSelected)="onEmployessWorkingPlacesSelection($event)">
            </app-dropdown>
        </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('marketOffering')"
            [class.edited-externally]="editedExternally('marketOffering')"
            [class.conflict-on-merge]="hasMergeConflict('marketOffering')">Market Offering</span>
        <span *ngIf="showAsterisk('marketOffering')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="marketOffering"
          class="input-data-text full-width section-input"
          [class.readonly]="true"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('material')"
            [class.edited-externally]="editedExternally('material')"
            [class.conflict-on-merge]="hasMergeConflict('material')">Material</span>
        <span *ngIf="showAsterisk('material')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="material"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('material')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
  </div>
</div>
