import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { FormValidations } from 'src/shared/utils/form-validations';

@Component({
  selector: 'app-master-engagement-details',
  templateUrl: './master-engagement-details.component.html',
  styleUrls: ['./master-engagement-details.component.scss']
})
export class MasterEngagementDetailsComponent implements OnInit {

  sharePointMapper = new DealWbsSetupSharepointMapper();

  @Input() formGroup: FormGroup;
  @Input() formSubmitted: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  isReadOnly(formControlName: string): boolean {
    return FormValidations.isReadOnly(this.formGroup, formControlName);
  }

  showAsterisk(formControlName: string): boolean {
    return FormValidations.showAsterisk(this.formGroup, formControlName);
  }

  highlightRequiredField(formControlName: string): boolean {
    return FormValidations.highlightRequiredField(this.formSubmitted, this.formGroup, formControlName);
  }

  editedExternally(formControlName: string): boolean {
    return FormValidations.editedExternally(this.formGroup, formControlName);
  }

  hasMergeConflict(formControlName: string): boolean {
    return FormValidations.hasMergeConflict(this.formGroup, formControlName);
  }

  setSharepointMapper(mapper: DealWbsSetupSharepointMapper) {
    this.sharePointMapper = mapper;
  }
}
