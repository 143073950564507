import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public idToken : string = ''   ;
  public accessToken : string = ''   ;

  constructor( private http:HttpClient) { }
 
  validateUser(): any {
    const url = environment.apiUrl +"/api/user/";
    const reqheaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.idToken}`
    })
    return this.http.get<any>(url, { headers: reqheaders })   
     
  } 
  getUserProfile(username:any): any { 
    const getUserProfileUrl = environment.apiUrl +"/api/ser/";   
    const reqheaders = new HttpHeaders({          
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("idToken")}`
    })    
    return this.http.get<any>(getUserProfileUrl+ username,{ headers: reqheaders })        
  } 
}
