<div id="mercury-opportunity" [formGroup]="mercuryOportunityFormGroup" class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
      <span>Mercury Opportunity</span>
  </div>
  <div id="merury-opportunity-section" class="content-section">
    <div class="row">
        <div class="col-xs-4 margin-top-5">
          <span>Deal Team</span>
        </div>
        <div class="col-xs-8">
          <app-deal-team-table
            [readonly]="true"
            [dealTeamValues]="showDealTeamRowsComplete()"
            [firstColumnVisible]="false"
            [thirdColumnVisible]="false">
          </app-deal-team-table>
        </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('maConfidentialSubsidiary')"
        [class.edited-externally]="editedExternally('maConfidentialSubsidiary')"
        [class.conflict-on-merge]="hasMergeConflict('maConfidentialSubsidiary')">M&A Confidential Subsidiary</span>
        <span *ngIf="showAsterisk('maConfidentialSubsidiary')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('M_AConfidentialSubsidiary')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="maConfidentialSubsidiaryOptions"
          [keySelected]="mercuryOportunityFormGroup.get('maConfidentialSubsidiary')?.value"
          [readonly]="isReadOnly('maConfidentialSubsidiary')"
          (optionSelected)="onMaConfidentialSubsidiarySelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('opportunityPhase')"
        [class.edited-externally]="editedExternally('opportunityPhase')"
        [class.conflict-on-merge]="hasMergeConflict('opportunityPhase')">Opportunity Phase</span>
        <span *ngIf="showAsterisk('opportunityPhase')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('OpportunityPhase')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="opportunityPhase"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('opportunityPhase')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('category')"
        [class.edited-externally]="editedExternally('category')"
        [class.conflict-on-merge]="hasMergeConflict('category')">Category</span>
        <span *ngIf="showAsterisk('category')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('Category')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="categoryOptions"
          [keySelected]="mercuryOportunityFormGroup.get('category')?.value"
          [readonly]="isReadOnly('category')"
          (optionSelected)="onCategorySelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('subCategory')"
        [class.edited-externally]="editedExternally('subCategory')"
        [class.conflict-on-merge]="hasMergeConflict('subCategory')">Sub-Category</span>
        <span *ngIf="showAsterisk('subCategory')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('SubCategory')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="subCategoryOptions"
          [keySelected]="mercuryOportunityFormGroup.get('subCategory')?.value"
          [readonly]="isReadOnly('subCategory')"
          (optionSelected)="onSubCategorySelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('offering')"
        [class.edited-externally]="editedExternally('offering')"
        [class.conflict-on-merge]="hasMergeConflict('offering')">Offering</span>
        <span *ngIf="showAsterisk('offering')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('Offering')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-dropdown [dropdownWidth]="'width:500px'"
          [optionsList]="offeringOptions"
          [keySelected]="mercuryOportunityFormGroup.get('offering')?.value"
          [readonly]="isReadOnly('offering')"
          (optionSelected)="onOfferingSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <!--totalValue label renamed to MARKET OFFERING VALUE-->
        <span [class.highlight-warning]="highlightRequiredField('totalValue')"
        [class.edited-externally]="editedExternally('totalValue')"
        [class.conflict-on-merge]="hasMergeConflict('totalValue')">Market Offering Value ($)</span> 
        <span *ngIf="showAsterisk('totalValue')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('TotalValue')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'decimal'"
          [format]="'currency'"
          [value]="mercuryOportunityFormGroup.get('totalValue')?.value"
          [readonly]="isReadOnly('totalValue')"
          (selectedValue)="ontotalValueChange($event)">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isIndiaInvolved')"
          [class.edited-externally]="editedExternally('isIndiaInvolved')"
          [class.conflict-on-merge]="hasMergeConflict('isIndiaInvolved')">Is India involved?</span>
        <span *ngIf="showAsterisk('isIndiaInvolved')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="mercuryOportunityFormGroup.get('isIndiaInvolved')?.value"
          [readonly]="isReadOnly('isIndiaInvolved')"
          (handleToggleYesNo)="onIsIndiaInvolvedSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('pursuitStartDate')"
          [class.edited-externally]="editedExternally('pursuitStartDate')"
          [class.conflict-on-merge]="hasMergeConflict('pursuitStartDate')">Pursuit Start Date</span>
          <span *ngIf="showAsterisk('pursuitStartDate')" class="red-asterik"></span>
          <app-popover
            [tooltipText]="getTooltipText('PursuitStartDate')">
          </app-popover>
        </div>
        <div class="col-xs-8">
          <app-date-picker
            [selectedDate]="mercuryOportunityFormGroup.get('pursuitStartDate')?.value"
            [readonly]="isReadOnly('pursuitStartDate')"
            (dateSelected)="eventDateSelected($event,'pursuitStartDate')">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('expectedCloseDate')"
          [class.edited-externally]="editedExternally('expectedCloseDate')"
          [class.conflict-on-merge]="hasMergeConflict('expectedCloseDate')">Expected Pursuit Close</span>
          <span *ngIf="showAsterisk('expectedCloseDate')" class="red-asterik"></span>
          <app-popover
            [tooltipText]="getTooltipText('ExpectedCloseDate')">
          </app-popover>
        </div>
        <div class="col-xs-8">
          <app-date-picker
            [selectedDate]="mercuryOportunityFormGroup.get('expectedCloseDate')?.value"
            [readonly]="isReadOnly('expectedCloseDate')"
            (dateSelected)="eventDateSelected($event,'expectedCloseDate')">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('projectStartDate')"
          [class.edited-externally]="editedExternally('projectStartDate')"
          [class.conflict-on-merge]="hasMergeConflict('projectStartDate')">Project Start Date</span>
          <span *ngIf="showAsterisk('projectStartDate')" class="red-asterik"></span>
          <app-popover
            [tooltipText]="getTooltipText('ProjectStartDate')">
          </app-popover>
        </div>
        <div class="col-xs-8">
          <app-date-picker
            [selectedDate]="myDealFormGroup.get('projectStartDate')?.value"
            [readonly]="isReadOnly('projectStartDate')"
            (dateSelected)="myDealeventDateSelected($event,'projectStartDate')">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('projectEndDate')"
          [class.edited-externally]="editedExternally('projectEndDate')"
          [class.conflict-on-merge]="hasMergeConflict('projectEndDate')">Estimated Project End Date</span>
          <span *ngIf="showAsterisk('projectEndDate')" class="red-asterik"></span>
          <app-popover
           [tooltipText]="getTooltipText('ProjectEndDate')">
          </app-popover>
        </div>
        <div class="col-xs-8">
          <app-date-picker
            [selectedDate]="myDealFormGroup.get('projectEndDate')?.value"
            [readonly]="isReadOnly('projectEndDate')"
            (dateSelected)="myDealeventDateSelected($event,'projectEndDate')">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isThisARecurringOpportunity')"
        [class.edited-externally]="editedExternally('isThisARecurringOpportunity')"
        [class.conflict-on-merge]="hasMergeConflict('isThisARecurringOpportunity')">Is this a recurring opportunity?</span>
        <span *ngIf="showAsterisk('isThisARecurringOpportunity')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('IsThisARecurringOpportunity')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="mercuryOportunityFormGroup.get('isThisARecurringOpportunity')?.value"
          [readonly]="isReadOnly('isThisARecurringOpportunity')"
          [disabled]="mercuryOportunityFormGroup.get('isThisARecurringOpportunity')?.disabled"
          (handleToggleYesNo)="onIsThisARecurringOpportunitySelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <!--Rename the name from Cross Business Offering (DCF M&A Transaction) to  STRATEGIC GROWTH OFFERING -->
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('crossBusinessOffering')"
          [class.edited-externally]="editedExternally('crossBusinessOffering')"
          [class.conflict-on-merge]="hasMergeConflict('crossBusinessOffering')">Strategic Growth Offering</span>
        <span *ngIf="showAsterisk('crossBusinessOffering')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="crossBusinessOffering"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('crossBusinessOffering')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('engagementIndustrySector')"
          [class.edited-externally]="editedExternally('engagementIndustrySector')"
          [class.conflict-on-merge]="hasMergeConflict('engagementIndustrySector')">Engagement Industry Sector</span>
        <span *ngIf="showAsterisk('engagementIndustrySector')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [dropdownWidth]="'width:350px'"
          [optionsList]="engagementIndustrySectorOptions"
          [keySelected]="mercuryOportunityFormGroup.get('engagementIndustrySector')?.value"
          [readonly]="isReadOnly('engagementIndustrySector')"
          (optionSelected)="onEngagementIndustrySectorSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isDeloitteAlumniInvolved')"
          [class.edited-externally]="editedExternally('isDeloitteAlumniInvolved')"
          [class.conflict-on-merge]="hasMergeConflict('isDeloitteAlumniInvolved')">Deloitte Alumni involved?</span>
        <span *ngIf="showAsterisk('isDeloitteAlumniInvolved')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="mercuryOportunityFormGroup.get('isDeloitteAlumniInvolved')?.value"
          [readonly]="isReadOnly('isDeloitteAlumniInvolved')"
          (handleToggleYesNo)="onIsAlumniInvolvedSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('deloitteAlumniName')"
          [class.edited-externally]="editedExternally('deloitteAlumniName')"
          [class.conflict-on-merge]="hasMergeConflict('deloitteAlumniName')">Name</span>
        <span *ngIf="showAsterisk('deloitteAlumniName')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="deloitteAlumniName"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('deloitteAlumniName')"
          type="text"
          autocomplete="off"
          maxlength="255"
          [placeholder]="mercuryOportunityFormGroup.get('isDeloitteAlumniInvolved')?.value === 'YES' ? '' : 'N/A'">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('deloitteAlumniRole')"
          [class.edited-externally]="editedExternally('deloitteAlumniRole')"
          [class.conflict-on-merge]="hasMergeConflict('deloitteAlumniRole')">Role</span>
        <span *ngIf="showAsterisk('deloitteAlumniRole')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="deloitteAlumniRole"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('deloitteAlumniRole')"
          type="text"
          autocomplete="off"
          maxlength="255"
          [placeholder]="mercuryOportunityFormGroup.get('isDeloitteAlumniInvolved')?.value === 'YES' ? '' : 'N/A'">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isThisAFollowOnOpportunity')"
        [class.edited-externally]="editedExternally('isThisAFollowOnOpportunity')"
        [class.conflict-on-merge]="hasMergeConflict('isThisAFollowOnOpportunity')">Is this a follow on opportunity?</span>
        <span *ngIf="showAsterisk('isThisAFollowOnOpportunity')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="mercuryOportunityFormGroup.get('isThisAFollowOnOpportunity')?.value"
          [readonly]="isReadOnly('isThisAFollowOnOpportunity')"
          [disabled]="mercuryOportunityFormGroup.get('isThisAFollowOnOpportunity')?.disabled"
          (handleToggleYesNo)="onIsThisAFollowOnOpportunitySelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('initiative')"
        [class.edited-externally]="editedExternally('initiative')"
        [class.conflict-on-merge]="hasMergeConflict('initiative')">Initiative</span>
        <span *ngIf="showAsterisk('initiative')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="initiative"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('initiative')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('feeType')"
        [class.edited-externally]="editedExternally('feeType')"
        [class.conflict-on-merge]="hasMergeConflict('feeType')">Fee Type</span>
        <span *ngIf="showAsterisk('feeType')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('FeeType')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="feeTypeOptions"
          [keySelected]="myDealFormGroup.get('feeType')?.value"
          [readonly]="isReadOnly('feeType')"
          (optionSelected)="onFeeTypeSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isThisAReproposal')"
        [class.edited-externally]="editedExternally('isThisAReproposal')"
        [class.conflict-on-merge]="hasMergeConflict('isThisAReproposal')">Is this a re-proposal?</span>
        <span *ngIf="showAsterisk('isThisAReproposal')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('IsThisAReproposal')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="mercuryOportunityFormGroup.get('isThisAReproposal').value"
          [readonly]="isReadOnly('isThisAReproposal')"
          (handleToggleYesNo)="onIsThisAReproposalSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-xs-4 section-label">
        <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('annualOpportunity')"
        [class.edited-externally]="editedExternally('annualOpportunity')"
        [class.conflict-on-merge]="hasMergeConflict('annualOpportunity')">Annual Opportunity</span>
        <span *ngIf="showAsterisk('annualOpportunity')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="annualOpportunity"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('annualOpportunity')"
          type="text"
          autocomplete="off"
          maxlength="255"
          [placeholder]="mercuryOportunityFormGroup.get('isThisARecurringOpportunity').value === 'YES' ? '' : 'N/A'">
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isThisAPullThroughOpportunity')"
        [class.edited-externally]="editedExternally('isThisAPullThroughOpportunity')"
        [class.conflict-on-merge]="hasMergeConflict('isThisAPullThroughOpportunity')">Is this a pull through opportunity?</span>
        <span *ngIf="showAsterisk('isThisAPullThroughOpportunity')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('IsThisAPullThroughOpportunity')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="mercuryOportunityFormGroup.get('isThisAPullThroughOpportunity').value"
          [readonly]="isReadOnly('isThisAPullThroughOpportunity')"
          (handleToggleYesNo)="onIsThisAPullThroughOpportunitySelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('originalOpportunityID')"
        [class.edited-externally]="editedExternally('originalOpportunityID')"
        [class.conflict-on-merge]="hasMergeConflict('originalOpportunityID')">Original Opportunity ID #</span>
        <span *ngIf="showAsterisk('originalOpportunityID')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <div class="dependent-field">
          <app-numeric
            [type]="'number'"
            [value]="mercuryOportunityFormGroup.get('originalOpportunityID').value"
            [disabled]="mercuryOportunityFormGroup.get('originalOpportunityID').disabled"
            [placeholder]="mercuryOportunityFormGroup.get('isThisAPullThroughOpportunity').value === 'YES' ? '' : 'N/A'"
            [readonly]="isReadOnly('originalOpportunityID')"
            (selectedValue)="onOriginalOpportunityIDChange($event)">
          </app-numeric>
        </div>
      </div>
    </div> -->
    
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('covid19RelatedNewOpportunity')"
        [class.edited-externally]="editedExternally('covid19RelatedNewOpportunity')"
        [class.conflict-on-merge]="hasMergeConflict('covid19RelatedNewOpportunity')">COVID19 Related New Opportunity?</span>
        <span *ngIf="showAsterisk('covid19RelatedNewOpportunity')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="mercuryOportunityFormGroup.get('covid19RelatedNewOpportunity')?.value"
          [readonly]="isReadOnly('covid19RelatedNewOpportunity')"
          (handleToggleYesNo)="oncovid19RelatedNewOpportunity($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('subcontractor')"
        [class.edited-externally]="editedExternally('subcontractor')"
        [class.conflict-on-merge]="hasMergeConflict('subcontractor')">Subcontractor</span>
        <span *ngIf="showAsterisk('subcontractor')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('Subcontractor')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="subcontractor"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('subcontractor')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('allianceProduct')"
        [class.edited-externally]="editedExternally('allianceProduct')"
        [class.conflict-on-merge]="hasMergeConflict('allianceProduct')">Alliance Product</span>
        <span *ngIf="showAsterisk('allianceProduct')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('AllianceProduct')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="allianceProduct"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('allianceProduct')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    
    <!-- <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('buyerName')"
        [class.edited-externally]="editedExternally('buyerName')"
        [class.conflict-on-merge]="hasMergeConflict('buyerName')">Buyer Name</span>
        <span *ngIf="showAsterisk('buyerName')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('BuyerName')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
        [formControl]="myDealFormGroup.get('buyerName')"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('buyerName')"
          (change)="onBuyerNameChange($event)"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('buyerRole')"
        [class.edited-externally]="editedExternally('buyerRole')"
        [class.conflict-on-merge]="hasMergeConflict('buyerRole')">Buyer Role</span>
        <span *ngIf="showAsterisk('buyerRole')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="buyerRoleOptions"
          [keySelected]="mercuryOportunityFormGroup.get('buyerRole').value"
          [readonly]="isReadOnly('buyerRole')"
          (optionSelected)="onBuyerRoleSelection($event)">
        </app-dropdown>
      </div>
    </div> -->
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('memberFirmRecognizingRevenue')"
        [class.edited-externally]="editedExternally('memberFirmRecognizingRevenue')"
        [class.conflict-on-merge]="hasMergeConflict('memberFirmRecognizingRevenue')">Member Firm Recognizing Revenue</span>
        <span *ngIf="showAsterisk('memberFirmRecognizingRevenue')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('MemberFirmRecognizingRevenue')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="memberFirmRecognizingRevenueOptions"
          [keySelected]="mercuryOportunityFormGroup.get('memberFirmRecognizingRevenue')?.value"
          [readonly]="isReadOnly('memberFirmRecognizingRevenue')"
          (optionSelected)="onMemberFirmRecognizingRevenueSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isPriorDESattendee')"
          [class.edited-externally]="editedExternally('isPriorDESattendee')"
          [class.conflict-on-merge]="hasMergeConflict('isPriorDESattendee')">Prior DES attendee?</span>
        <span *ngIf="showAsterisk('isPriorDESattendee')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('IsPriorDESattendee')"
          [keepOpen]="true">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="mercuryOportunityFormGroup.get('isPriorDESattendee')?.value"
          [readonly]="isReadOnly('isPriorDESattendee')"
          (handleToggleYesNo)="onIsPriorDESattendeeSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
  </div>
</div>
