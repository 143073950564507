import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppSettings } from 'src/shared/app-settings';

@Injectable({
  providedIn: 'root'
})
export class EngagementHealthObjectServiceService {

  constructor(private myhttp: HttpClient) { }
  getAppendURL(dropdownName: string) {
    let url: any = "";
    switch (dropdownName) {
      case "NewDealSetupForm":
        return url = "api/NewDealAttachments";
        break;
      case "CrossBorderForm":
        return url = "api/CrossBorderAttachments";
        break;
      case "MyDealSwiftRoles":
        return url = "api/MyDealSwiftRoles";
        break;
      case "MyDealDeloitteUSEntity":
        return url = "api/MyDealDeloitteUSEntity";
        break;
        case "DealTeamAuditLog": 
        return url = "api/DealTeamAuditLog";
            break;  
        case "DocumentManager":
        return url = "api/DocumentManager";
        break; 
        case "MyDealDealTeam":
        return url = "api/MyDealDealTeam";
        break; 
      default:
        return "";
        break;
    }
  }
  GetAvailableForms():Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.EngagementDashboard)
    const url = envURL +'/api/EngagementAvailableForms/GetList';
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})});
    }
    GetEHOObjects(formId:any="",mydealId:any=""):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.EngagementDashboard)
      var apiUrl = '/api/EngagementHealthObject/GetList?'
      if(formId=="" ||formId==null||formId==undefined)
      {
        apiUrl = apiUrl+'myDealID='+mydealId;
      }
      else
      {
        apiUrl = apiUrl+'formID='+formId;
      }      
      const url = envURL +apiUrl
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})});
      }
      UpdateEHODetails(data:any):Observable<any>{
        var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.EngagementDashboard)
        const url = envURL+'/api/EngagementHealthObject/Update'; 
        return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
        ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});
      }
      GetFORMEHOObjects(appendUrl:any,formId:any,listName:any):Observable<any>{
        var envURL="";
        if(listName=="CrossBorderForm"){  envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorder)}
        else if(listName=="DealWbsSetupForm"){  envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)}
        else if(listName=="ClosingCheckListForm"){  envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)}
        const url = envURL +"/"+appendUrl+'/GetEHODetails?myDealID='+formId;
        return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization' : "Bearer "+localStorage.getItem("idToken")})});
        }
        GetUserRoleGroupsEHO(userId:any):Observable<any>{
          var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
          const url = envURL +'/api/UserGroups/GetUserGroupsByEmail?email='+userId;
          return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
          'Authorization' : "Bearer "+localStorage.getItem("idToken")})});
        }
        GetFormAttachments(appendedURL: any,formID:any,listName:any):Observable<any>{ 
          var envURL="";
          if(listName=="NewDealSetupForm"){
             envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
          }
          else if(listName=="CrossBorderForm"){
             envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorder)
          }  
          const url = envURL + '/' + appendedURL + '/GetList?formID=' + formID;      
             return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
          'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
        } 
        getDealTeamAuditLogData(formName: any, formId: any): Observable<any> {
          const appendedURL = this.getAppendURL(formName);
          var envURL = AppSettings.GetEnvironmentURL(environment.environmentType, environment.Newdealform)
          const url = envURL + '/' + appendedURL + '/GetList?formId=' + formId;
          return this.myhttp.get(url, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("idToken")
            })
          });
        }
        DealTeamAuditLog(formName: any, formId: any): Observable<any> {
          const appendedURL = this.getAppendURL(formName);
          var envURL = AppSettings.GetEnvironmentURL(environment.environmentType, environment.Newdealform)
          const url = envURL + '/' + appendedURL + '/GetList?formId=' + formId;
          // const url='https://localhost:7093/api/DealTeamAuditLog/GetList?formId=475'        
          return this.myhttp.get(url, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("idToken")
            })
          });
        }
        downloadAttachments(formId: any,fileName: any,attachEnviroment: any,list:any):Observable<any>
        {
          const appendedURL = this.getAppendURL(AppSettings.DocumentManager);
          var data = JSON.stringify({FormID: formId, FileName: fileName,AttachEnviroment:attachEnviroment,PageSource:list})
          var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DocumentManager)
          const url = envURL + '/' + appendedURL + '/DownloadFile';   
           return this.myhttp.post(url, data, {
            headers: new HttpHeaders({
               'Content-Type': 'application/json'
              , 'Authorization': "Bearer " + localStorage.getItem("idToken")
            })
          })
        }
        GetNewDealDetails(env:any,formID?:any):Observable<any>{
          let url:any;
          var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
          if (formID != null || formID != undefined) {
             url = envURL+'/api/NewDealSetupForm/GetList?formID='+"EHO"+'&myDealID='+formID; 
          }
          
          return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
          'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
        }
        GetFormDetails(formId:any):Observable<any>{
          var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorder)
          const url = envURL +'/api/CrossBorderForm/GetFormDetails?formID='+formId;
          return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
          'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
      }
}
