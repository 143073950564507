<div id="international-work-indicators"
  [formGroup]="intWorkIndicatorsFormGroup"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
    <span>International Work Indicators</span>
  </div>
  <div id="international-work-indicators-section" class="content-section">
    <div class="row">
      <div class="col-xs-4">
        <span [class.highlight-warning]="highlightRequiredField('isInternationalEngagement')"
          [class.edited-externally]="editedExternally('isInternationalEngagement')"
          [class.conflict-on-merge]="hasMergeConflict('isInternationalEngagement')">
            Is this an International Engagement?
        </span>
        <span *ngIf="showAsterisk('isInternationalEngagement')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('IsInternationalEngagement')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('isInternationalEngagement')?.value"
          [readonly]="isReadOnly('isInternationalEngagement')"
          (handleToggleYesNo)="onInternationalEngagementSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <div class="container-label-input">
          <span [class.highlight-warning]="highlightRequiredField('areResourcesWorkOutside')"
            [class.edited-externally]="editedExternally('areResourcesWorkOutside')"
            [class.conflict-on-merge]="hasMergeConflict('areResourcesWorkOutside')">
              Are the local resources performing work outside the home country?
            </span>
          <span *ngIf="showAsterisk('areResourcesWorkOutside')" class="red-asterik"></span>
          <app-popover
            [tooltipText]="getTooltipText('AreResourcesWorkOutside')">
          </app-popover>
        </div>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('areResourcesWorkOutside')?.value"
          [disabled]="getControlName('areResourcesWorkOutside')?.disabled"
          [readonly]="isReadOnly('areResourcesWorkOutside')"
          (handleToggleYesNo)="onAreResourcesWorkOutsideSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <div class="container-label-input">
          <span [class.highlight-warning]="highlightRequiredField('memberFirmTaxablePresence')"
            [class.edited-externally]="editedExternally('memberFirmTaxablePresence')"
            [class.conflict-on-merge]="hasMergeConflict('memberFirmTaxablePresence')">
              Does the member firm have a taxable presence in the involved countries?
          </span>
          <span *ngIf="showAsterisk('memberFirmTaxablePresence')" class="red-asterik"></span>
          <app-popover
            [tooltipText]="getTooltipText('MemberFirmTaxablePresence')">
          </app-popover>
        </div>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('memberFirmTaxablePresence')?.value"
          [disabled]="getControlName('memberFirmTaxablePresence')?.disabled"
          [readonly]="isReadOnly('memberFirmTaxablePresence')"
          (handleToggleYesNo)="onMemberFirmTaxablePresenceSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <div class="container-label-input">
          <span [class.highlight-warning]="highlightRequiredField('otherMemberFirmsParticipating')"
            [class.edited-externally]="editedExternally('otherMemberFirmsParticipating')"
            [class.conflict-on-merge]="hasMergeConflict('otherMemberFirmsParticipating')">
              Are any other member firms participating on this engagement?
          </span>
          <span *ngIf="showAsterisk('otherMemberFirmsParticipating')" class="red-asterik"></span>
          <app-popover
            [tooltipText]="getTooltipText('OtherMemberFirmsParticipating')">
          </app-popover>
        </div>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('otherMemberFirmsParticipating')?.value"
          [disabled]="getControlName('otherMemberFirmsParticipating')?.disabled"
          [readonly]="isReadOnly('otherMemberFirmsParticipating')"
          (handleToggleYesNo)="onOtherMemberFirmsParticipatingSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <div class="container-label-input">
          <span [class.highlight-warning]="highlightRequiredField('whereWorkMemberFirms')"
            [class.edited-externally]="editedExternally('whereWorkMemberFirms')"
            [class.conflict-on-merge]="hasMergeConflict('whereWorkMemberFirms')">
              Where are these member firm employees working from?
            </span>
          <span *ngIf="showAsterisk('whereWorkMemberFirms')" class="red-asterik"></span>
        </div>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="employessWorkingPlacesList"
          [keySelected]="getControlName('whereWorkMemberFirms')?.value"
          [disabled]="getControlName('whereWorkMemberFirms')?.disabled"
          [readonly]="isReadOnly('whereWorkMemberFirms')"
          (optionSelected)="onEmployessWorkingPlacesSelection($event)">
        </app-dropdown>
      </div>
    </div>
  </div>
</div>
