import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
//Created this Service to store and access the Values Globally
export class GlobalHelperService {
  constructor() { }
  currentAccessPage:string="";
  formID:string="";
}
