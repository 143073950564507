<div class="row" id="select-dynamic-table">
  <div class="col-xs-12">
      <div>
          <div class="row">
              <div class="col-xs-11 table-header"><span>{{dynamicTableDropdownItemListItem.header.header}}</span></div>
              <div class="col-xs-1 table-header"></div>
          </div>
          <div class="row" *ngFor="let option of valuesList; let i = index">
            <div class="col-xs-11 table-col height-31">
              <app-dropdown
                [optionsList]="dropdownOptions"
                [excludedOptionsList]="excludedOptions"
                [keySelected]="option.competitorItem.key"
                [readonly]="readonly"
                (optionSelected)="onDropdownSelection($event, i)"
                (click)="refreshDropdownOptions()">
              </app-dropdown>
            </div>
              <div class="col-xs-1 table-col height-31">
                <span class="glyphicon glyphicon-remove margin-top-5" (click)="deleteRow(i)"></span>
            </div>
          </div>
          <div class="row last-row">
              <div class="col-xs-12 table-col btn-add">
                  <span 
                    class="table-watermark" 
                    [class.readonly]="readonly" 
                    (click)="addRow()">
                    Add new {{dynamicTableDropdownItemListItem.header.header}} here...
                  </span>
              </div>
          </div>
      </div>
  </div>
</div>
