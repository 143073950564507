import { DropdownItem } from './dropdown-item';
import { PeoplePickerUser } from './people-picker-user';

export class PeoplepickerDynamicTableItem {
  idUsersSelected: Array<number> = [];
  usersSelected: Array<PeoplePickerUser> = [];
  optionsSelectedFirstColumn: DropdownItem = new DropdownItem();
  optionsSelectedSecondColumn: DropdownItem = new DropdownItem();
  optionsSelectedThirdColumn: DropdownItem = new DropdownItem();
  index: number;
  itemId: number;

  constructor() { }

  isComplete(): boolean {
    if (this.idUsersSelected.length > 0 &&
      !!this.optionsSelectedFirstColumn.value &&
      !!this.optionsSelectedSecondColumn.value &&
      !!this.optionsSelectedThirdColumn.value) {
        return true;
      }
    return false;
  }
  isComplete_DealWbs(): boolean {
    if (this.usersSelected.length > 0 &&
      !!this.optionsSelectedFirstColumn.value &&
      !!this.optionsSelectedSecondColumn.value &&
      !!this.optionsSelectedThirdColumn.value) {
        return true;
      }
    return false;
  }
  
  getUsersSelected(): Array<PeoplePickerUser> {
    return this.usersSelected;
  }
  equals(other: PeoplepickerDynamicTableItem) {
    if (this.isUserIdSelectedEquals(other.idUsersSelected) &&
        this.isFirstColumnEquals(other.optionsSelectedFirstColumn) &&
        this.isSecondColumnEquals(other.optionsSelectedSecondColumn) &&
        this.isThirdColumnEquals(other.optionsSelectedThirdColumn)) {
          return true;
    }
    return false;
  }
  isFirstColumnEquals(otherDropdown: DropdownItem): boolean {
    return this.areDropdownEquals(this.optionsSelectedFirstColumn, otherDropdown);
  }

  isSecondColumnEquals(otherDropdown: DropdownItem): boolean {
    return this.areDropdownEquals(this.optionsSelectedSecondColumn, otherDropdown);
  }

  isThirdColumnEquals(otherDropdown: DropdownItem): boolean {
    return this.areDropdownEquals(this.optionsSelectedThirdColumn, otherDropdown);
  }

  areDropdownEquals(currentDropdown:any, otherDropdown:any): boolean {
    if (currentDropdown && otherDropdown) {
      return currentDropdown.key === otherDropdown.key && currentDropdown.value === otherDropdown.value;
    }
    return true;
  }

  isUserIdSelectedEquals(otherUserIdSelected: Array<number>): boolean {
    let isEqual = true;
    this.idUsersSelected.forEach((item, index) => {
      if (item !== otherUserIdSelected[index]) {
        isEqual = false;
      }
    });
    return isEqual;
  }
  addUserSelected(user: PeoplePickerUser) {
    this.usersSelected.push(user);
  }

  deleteUserSelected(user: PeoplePickerUser) {
    this.usersSelected = this.usersSelected.filter(u => u.Key !== user.Key);
  }

  getUsersSelectedList(): Array<PeoplePickerUser> {
    return this.usersSelected;
  }

  setOptionFirstColumn(item: DropdownItem) {
    this.optionsSelectedFirstColumn = item;
  }

  setOptionSecondColumn(item: DropdownItem) {
    this.optionsSelectedSecondColumn = item;
  }

  setOptionThirdColumn(item: DropdownItem) {
    this.optionsSelectedThirdColumn = item;
  }

  setIndex(index:any) {
    this.index = index;
  }

  getIndex() {
    return this.index;
  }

  setItemId(itemId:any) {
    this.itemId = itemId;
  }

  getItemId() {
    return this.itemId;
  }
}
