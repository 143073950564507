<div class="search-input-container">
  <input
    class="search-input form-control ml-2"
    type="text"
    name="searchTerm"
    placeholder="Search"
    (keyup)="onsearch($event)"
    [(ngModel)]="commonHelperService.searchTerm"/>
  <div *ngIf="commonHelperService.loading$ | async" class="search-spinner"></div>
</div>
