<div class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog " role="document">
        <div class="modal-content custom-content" style="background-color: white;" [ngStyle]="getModalStyles()" >

            <div class="form-group">
                <div class="row">
                    <div class="col-md-5 header">
                        <div  class="modal-title-header"><label>{{headerMsg}}</label></div>
                    </div>

                </div>
                <div class="row">
                    <div class="row">
                            <div style="margin: 0% 4%; font-size: 15px; text-align: center;color: #0072C6"><label>{{Msg}}</label></div>
                        <div class="row" style="margin-left: -4%;">
                            <div class="row">
                                <div *ngIf="isUserDelete" class="col-md-2 column no-Style" >
                                    <button type="button" class="btn custom-cancel mr" (click)="ConfirmToCancel()">
                                    {{UserDeleteCancelText}}
                                    </button>
                                </div>                            
                                <div *ngIf="isUserDelete" class="col-md-2 column yes-Style" >
                                    <button type="button" class="btn custom-add mr" (click)="ConfirmToDelete()">
                                        {{UserDeleteOkText}}
                                    </button>
                                </div>
                                </div>
                                <div *ngIf="!isUserDelete" class="col-md-2 column ok-Style">
                                    <button type="button" class="btn custom-add mr" (click)="closePopup()">
                                        {{cancelText}} 
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
