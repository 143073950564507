import { Component, OnInit } from '@angular/core';
import { DealTeamItem } from 'src/shared/models/deal-team-item';
import { AppSettings } from 'src/shared/app-settings';

@Component({
  selector: 'app-deal-team-details',
  templateUrl: './deal-team-details.component.html',
  styleUrls: ['./deal-team-details.component.scss']
})
export class DealTeamDetailsComponent implements OnInit {
  dealTeamList: Array<DealTeamItem> = [];

  constructor() { }

  ngOnInit() {
  }

  setDealTeamDetails(dealTeamObject: Array<DealTeamItem>) {
    this.dealTeamList = dealTeamObject;
  }

  openNewTab(url: string) {
    window.open(url);
  }

  getDpnProfileLink(loginName: string): string {
    return AppSettings.dpnProfileLink.replace('{userId}', loginName);
  }
}
