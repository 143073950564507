import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FreeTextTableDropdownItemList } from 'src/shared/models/free-text-table-dropdown-item-list'; 
import { FreeTextTableItem } from 'src/shared/models/free-text-table-item'; 
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action'; 
import { FreeTextSelectedRow } from './free-text-selected-row.interface';
import { FreeTextItem } from 'src/shared/models/free-text-item'; 
import { TooltipObject } from 'src/shared/models/tooltip-object'; 
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service'; 

@Component({
  selector: 'app-free-text-table',
  templateUrl: './free-text-table.component.html',
  styleUrls: ['./free-text-table.component.scss']
})
export class FreeTextTableComponent implements OnChanges {
  @Input()
  sharePointGroupId: '0';

  @Input()
  valuesList: Array<FreeTextTableItem> = [];

  @Input()
  freeTextInputOne: FreeTextItem;

  @Input()
  freeTextInputTwo: FreeTextItem;

  @Input()
  firsSelectColumnItemList: any = new FreeTextTableDropdownItemList();

  @Input()
  secondSelectColumnItemList: any = new FreeTextTableDropdownItemList();

  @Input()
  readonly: boolean;

  @Input()
  editable = false;

  @Input()
  placeHolderForLastTable: any;

  @Output()
  selectedRow: EventEmitter<FreeTextSelectedRow> = new EventEmitter<FreeTextSelectedRow>();

  hasError: boolean;

  headerFreeTextOne = 'Name';
  headerSelectOne = 'Publicly traded entity?';
  headerFreeTextTwo = 'Ticker Symbol';
  headerSelectTwo = 'Restricted Entity?';
  //restrictedEntityTooltip = 'Meaning an attest client of Deloitte & Touche LLP';

  constructor(private tooltipHelperService: TooltipHelperService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.valuesList) {
      if (this.valuesList) {
        this.valuesList.forEach(row => {
        });
      }
    }
  }

  emitValue(item:any, actionType:any) {
    const objectToSend = {
      item,
      actionType
    };
    this.selectedRow.emit(objectToSend);
  }

  onSelectedOptionFirstColumn(event:any, index:any) {
    this.valuesList[index].setOptionFirstColumn(event);
    this._emitValueWhenRowIsCompleted(index);
  }

  onSelectedOptionSecondColumn(event:any, index:any) {
    this.valuesList[index].setOptionSecondColumn(event);
    this._emitValueWhenRowIsCompleted(index);
  }

  addRow() {
    if (!this.readonly) {
      this.valuesList.push(new FreeTextTableItem());
      const index = this.valuesList.length - 1;
      this.valuesList[index].setIndex(index);
    }
  }

  deleteRow(index:any) {
    if (!this.readonly) {
      if (this.valuesList[index].itemId) {
        this.emitValue(this.valuesList[index], DynamicTableAction.Delete);
      }
      this.valuesList.splice(index, 1);

      this._updateTableIndex();
    }
  }

  onFreeTextChange(event:any, index:any) {
    this.valuesList[index].freeTextOptionOne.name = event.target.value;
    this._emitValueWhenRowIsCompleted(index);
  }

  onFreeTextChangeTwo(event:any, index:any) {
    this.valuesList[index].freeTextOptionTwo.name = event.target.value;
    this._emitValueWhenRowIsCompleted(index);
  }

  setItemId(itemId: number, index: number) {
    this.valuesList[index].setItemId(itemId);
  }

  showError() {
    this.hasError = true;
  }

  rowIndex(user: FreeTextItem): number {
    return this.valuesList.findIndex((row:any) => row.freeTextOptionOne[0] && row.freeTextOptionOne[0].key === user.key);
  }
  

  private _isRowCompleteFullTable(index:any): boolean {
    return this._isFreeTextFirstInputCompleted(index)
    && this._isFirstColumnSelected(index) &&
    this._isSecondColumnSelected(index) &&
    this._isFreeTextSecondInputCompleted(index);
  }

  private _isRowCompleteFreeText(index:any): boolean {
    return this._isFreeTextFirstInputCompleted(index);
  }

  private _isFreeTextFirstInputCompleted(index:any): boolean {
    if (this.valuesList[index].freeTextOptionOne.name) {
      return true;
    }
    return false;
  }

  private _isFirstColumnSelected(index:any): boolean {
    if (this.firsSelectColumnItemList && !this.valuesList[index].optionsSelectedFirstColumn.value) {
        return false;
    }
    return true;
  }

  private _isSecondColumnSelected(index:any): boolean {
    if (this.secondSelectColumnItemList && !this.valuesList[index].optionsSelectedSecondColumn.value) {
        return false;
    }
    return true;
  }

  private _isFreeTextSecondInputCompleted(index:any): boolean {
    if (this.valuesList[index].freeTextOptionTwo.name) {
      return true;
    }
    return false;
  }

  private _emitValueWhenRowIsCompleted(index:any) {
    // hide error
    this.hasError = false;

    if (this._isRowCompleteFullTable(index)) {
      this.emitValue(this.valuesList[index], DynamicTableAction.Add);
    } else if (this._isRowCompleteFreeText(index) && !this.firsSelectColumnItemList && !this.secondSelectColumnItemList) {
      this.emitValue(this.valuesList[index], DynamicTableAction.Add);
    }
  }

  private _updateTableIndex() {
    this.valuesList.forEach((row, i) => {
      row.index = i;
    });
  }
  getTooltip(fieldName: string): TooltipObject {
    return this.tooltipHelperService.getTooltipByFieldNameCCL(fieldName);
  }

  getTooltipText(fieldName:string):string{
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipText() : '';
  }
  getTooltipTitle(fieldName: string): string {
    const tooltip = this.getTooltip(fieldName);
    return tooltip ? tooltip.getTooltipTitle() : '';
  }

}
