import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DealWbsSetupComponent } from './deal-wbs-setup/deal-wbs-setup.component';
import { SharedModule } from 'src/shared/SharedModule';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { ClientInformationComponent } from './client-information/client-information.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { EngagementInformationComponent } from './engagement-information/engagement-information.component';
import { InternationalWorkIndicatorsComponent } from './international-work-indicators/international-work-indicators.component';
import { EngagementTeamComponent } from './engagement-team/engagement-team.component';
import { DealTeamTableComponent } from './deal-team-table/deal-team-table.component';
import { WBSElementClassificationComponent } from './wbs-element-classification/wbs-element-classification.component';
import { InvoiceDetailsAndAddressesComponent } from './invoice-details-and-addresses/invoice-details-and-addresses.component';
import { MasterEngagementDetailsComponent } from './master-engagement-details/master-engagement-details.component';
import { BillingScheduleComponent } from './billing-schedule/billing-schedule.component';
import { ArchiveCmrComponent } from './archive-cmr/archive-cmr.component';
import { PricingDetailsComponent } from './pricing-details/pricing-details.component';
import { ParticipationComponent } from './participation/participation.component';
import { AdminReviewComponent } from './admin-review/admin-review.component';
import { ActiveDealWbsComponent } from './active-deal-wbs/active-deal-wbs.component';
import { MainService } from 'src/shared/services/main.service';
import { DealWbsSetupService } from './shared/services/deal-wbs-setup.service';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { FilesService } from 'src/shared/services/files.service';
import { PeoplePickerService } from '../services/peoplepicker.service';
import { SecurityService } from 'src/shared/services/security.service';
import { SecurityMatrix } from 'src/shared/utils/security-matrix';
import { NumericComponent } from 'src/shared/components/numeric/numeric.component';
import { AlertModule } from '../_alert';
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule
  ],
  declarations: [
    ClientInformationComponent,
    SidebarComponent,
    EngagementInformationComponent,
    InternationalWorkIndicatorsComponent,
    EngagementTeamComponent,
    DealTeamTableComponent,
    WBSElementClassificationComponent,
    InvoiceDetailsAndAddressesComponent,
    MasterEngagementDetailsComponent,
    BillingScheduleComponent,
    ArchiveCmrComponent,
    PricingDetailsComponent,
    ParticipationComponent,
    AdminReviewComponent,
    ActiveDealWbsComponent,
    DealWbsSetupComponent
  ],
  exports: [
    ClientInformationComponent,
    //SidebarComponent,Commented due to app confusing with the header component main and this 
    EngagementInformationComponent,
    InternationalWorkIndicatorsComponent,
    EngagementTeamComponent,
    //DealTeamTableComponent,Commented due to app confusing with the header component main and this 
    WBSElementClassificationComponent,
    InvoiceDetailsAndAddressesComponent,
    MasterEngagementDetailsComponent,
    BillingScheduleComponent,
    ArchiveCmrComponent,
    PricingDetailsComponent,
    ParticipationComponent,
   // AdminReviewComponent,Commented due to app confusing with the header component main and this 
    ActiveDealWbsComponent,
    DealWbsSetupComponent
  ],
  providers: [
    DatePipe,
    DateFormatterService,
    MainService,
    DealWbsSetupService,
    DropdownService,
    FilesService,
    PeoplePickerService,
    SecurityService

  ]
})
export class DealWbsSetupModule { }
